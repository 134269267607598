import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import { withStateMachine } from 'react-automata';

import { statechart, EVENT, STATE } from './statechart';

/**
 * @param {*} props
 * @returns {string}
 */
function PasswordRuleIndicator(props) {
    const rootClassNames = buildClassNames({
        ['password-rule']: true,
        ['password-rule_success']: props.valid,
    });

    return (
        <li className={rootClassNames}>
            <svg
                width="14px"
                height="14px"
                className="password-rule-indicator"
                role="img"
                aria-label="Checkmark"
                focusable="false"
            >
                <use xlinkHref="#icon-check" />
            </svg>
            <span className="password-rule-txt">{props.label}</span>
        </li>
    );
}

class PasswordValidationPopup extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        passwordIsValid: PropTypes.bool.isRequired,
        indicators: PropTypes.shape({
            length: PropTypes.bool,
            digit: PropTypes.bool,
            lowercase: PropTypes.bool,
            uppercase: PropTypes.bool,
            specialChar: PropTypes.bool,
        }).isRequired,
    };

    get isPasswordValid() {
        return this.props.passwordIsValid;
    }

    get toggled() {
        return this.props.machineState.value === STATE.ON;
    }

    /**
     * @param {Event} ev
     * @private
     */
    togglePopup = ev => {
        this.props.transition(EVENT.TOGGLE_POPUP);
    };

    render() {
        return (
            <div className="password">
                <div className="password-fields">
                    {this.props.children({ togglePopup: this.togglePopup })}
                </div>
                {this.toggled && (
                    <div className="password-popup">
                        <p className="txt m-txt_md m-txt_bold m-txt_colorPrimaryS2 u-vr3x">
                            {'Password Requirements'}
                        </p>
                        <ul>
                            {!this.isPasswordValid ? (
                                <Fragment>
                                    <PasswordRuleIndicator
                                        label="Must be at least 8 characters"
                                        valid={this.props.indicators.length}
                                    />
                                    <li className="password-rule password-rule_msg">
                                        {
                                            'Must contain at least 3 out of 4 of these:'
                                        }
                                    </li>
                                    <PasswordRuleIndicator
                                        label="Capital letters"
                                        valid={this.props.indicators.uppercase}
                                    />
                                    <PasswordRuleIndicator
                                        label="Lowercase letters"
                                        valid={this.props.indicators.lowercase}
                                    />
                                    <PasswordRuleIndicator
                                        label="Special characters"
                                        valid={
                                            this.props.indicators.specialChar
                                        }
                                    />
                                    <PasswordRuleIndicator
                                        label="Numbers"
                                        valid={this.props.indicators.digit}
                                    />
                                </Fragment>
                            ) : (
                                <PasswordRuleIndicator
                                    label="Valid!"
                                    valid={this.props.indicators.digit}
                                />
                            )}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}

export default withStateMachine(statechart)(PasswordValidationPopup);
