import StateController from '../../../core/scripts/lib/wirey/state/StateController';
import { Config } from '../../../core/scripts/lib/Config';

import _union from 'lodash/union';

class FavoritesStateController extends StateController {
    get defaultState() {
        return {
            favorites: Config.page.favorite_pets || [],
        };
    }

    /**
     * @returns {Array}
     */
    get favorites() {
        return this.state.favorites;
    }

    addFavorite(animalId) {
        if (typeof animalId !== 'number') {
            return;
        }

        const favorites = this.favorites;
        favorites.push(animalId);
        this.patchState({
            favorites,
        });
    }

    addFavorites(animalIdArray) {
        if (!Array.isArray(animalIdArray)) {
            return;
        }

        const favorites = _union(this.favorites, animalIdArray);
        this.patchState({
            favorites,
        });
    }

    removeFavorite(animalId) {
        if (typeof animalId !== 'number') {
            return;
        }

        const favorites = this.favorites;
        const index = this.favorites.indexOf(animalId);
        favorites.splice(index, 1);

        this.patchState({
            favorites,
        });
    }

    isFavorite(animalId) {
        return this.favorites.includes(animalId);
    }
}

export default new FavoritesStateController();
