import { html } from '../../../core/scripts/util/util';

const template = vm => {
    return html`
        <div class="vrArray m-vrArray_2x">
            <div class="hrArray m-hrArray_2x m-hrArray_right">
                <button class="fieldBtn" data-id="previousPageLink" type="button">
                    <span class="fieldBtn-icon">
                        <span class="icon icon_sm m-icon_colorPrimary">
                            <svg role="img">
                                <use xlink:href="#icon-chevronLeft"/>
                            </svg>
                        </span>
                    </span>
                    <span class="fieldBtn-label">Prev</span>
                </button>
                <button class="fieldBtn" data-id="nextPageLink" type="button">
                    <span class="fieldBtn-label">Next</span>
                    <span class="fieldBtn-icon">
                        <span class="icon icon_sm m-icon_colorPrimary">
                            <svg role="img">
                                <use xlink:href="#icon-chevronRight"/>
                            </svg>
                        </span>
                    </span>
                </button>
            </div>
        </div>
        <div class="hrArray m-hrArray_2x m-hrArray_right">
            <div class="pagination-text">

            Page <span data-id="currentPage">
                X
                </span> of
                <span data-id="numPages">
                    X
                </span>
            </div>
            <div class="pagination-text">Showing <span data-id="startItemNum">X</span> - <span data-id="endItemNum">X</span></div>
        </div>
    `;
};

export default template;
