import t from 'tcomb-form';

import { Password } from '../../../../../core/scripts/util/tcomb';

/**
 * @type {Object}
 */
export const FIELDS = {
    FIRST_NAME: 'user_registration[firstName]',
    LAST_NAME: 'user_registration[lastName]',
    COUNTRY: 'user_registration[address][country]',
    POSTAL_CODE: 'user_registration[address][postalCode]',
    EMAIL: 'user_registration[email]',
    PASSWORD_FIRST: 'user_registration[password][first]',
    PASSWORD_SECOND: 'user_registration[password][second]',
    NEXT: 'next',
    NEXT_PARAMS: 'next_params',

    // Optin field names
    OPTIN_PETFINDER: 'user_registration[emailOptIn][petfinder]',
    OPTIN_SPONSOR: 'user_registration[emailOptIn][sponsor]',

    // Optional initiating source
    SOURCE: 'user_registration[source]',
};

/**
 * @type {Object}
 */
export default t.struct({
    [FIELDS.FIRST_NAME]: t.String,
    [FIELDS.LAST_NAME]: t.String,
    [FIELDS.POSTAL_CODE]: t.String,
    [FIELDS.EMAIL]: t.String,
    [FIELDS.PASSWORD_FIRST]: Password,
    [FIELDS.PASSWORD_SECOND]: t.String,
    [FIELDS.NEXT]: t.maybe(t.String),
    [FIELDS.NEXT_PARAMS]: t.maybe(t.String),
    [FIELDS.SOURCE]: t.maybe(t.String),
});
