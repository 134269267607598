/**
 * # Session Repository
 * Returns data from API related to session management.
 */

import Repository from '../repository/repository';
import { API_WEB_FRONT } from '../../constants/api';

/**
 * @class SessionRepository
 * @extends Repository
 */
export default class SessionRepository extends Repository {

    /**
     * Get session Time to Live
     *
     * @public
     * @async
     * @method getSessionTTL
     * @return {Promise.<response>}
     */
    async getSessionTTL() {
        const data = await this.get(
            '/user/session/timeout'
        );

        return data;
    }

    /**
     * Refresh the session, which updates the TTL
     *
     * @public
     * @async
     * @method refreshSession
     * @return {Promise.<response>}
     */
    async refreshSession() {
        const csrfToken = {
            csrfToken: this.globalCsrfToken
        };

        const data = await this.post(
            '/user/session/timeout',
            {},
            {
                csrfToken,
                method: 'POST',
                api: API_WEB_FRONT
            }
        );

        return data;
    }
}
