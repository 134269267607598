import contextContainer from '../../context';
import { scrollLockSingleton } from '../../lib/ScrollLock';
import {
    getPseudoContent,
    parseJsonAttribute,
    buildCustomEvent,
} from '../../util/dom';
import { CLASS_IS_ACTIVE } from '../../constants/classes';
import { EV_DISABLE, EV_ENABLE } from '../../constants/events';

import { scheduleMicrotask } from '../../util/util';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function endOfEventLoop() {
    return sleep(0);
}

/**
 * # `pf-element` Element
 *
 * <pf-element></pf-element>
 *
 * The generic abstact element provides shared functionality for specific elements. It must be
 * extended by custom element.
 *
 * @class PFElement
 * @extends HTMLElement
 */
export class PFElement extends HTMLElement {
    /**
     * ## Lifecycle
     */

    get initialization() {
        return this._initialization;
    }

    get isInitialized() {
        return this._isInitialized;
    }

    get isDisabled() {
        return this._isDisabled;
    }

    set isDisabled(val) {
        this._isDisabled = val;
    }

    get shouldAlertTextContent() {
        return this.hasAttribute('alert-text-content');
    }

    /**
     * @constructor
     * @extends HTMLElement
     */
    constructor() {
        super();
        this._context = contextContainer;
        this._uiGroup = Symbol(this.tagName);
        this.uid = Symbol('uid');
        this.isCustom = true;

        // TODO: delete?
        this.trapFocusWithinWhenActive = false;
        this.lockPageScrollWhenActive = false;
        this.lockFocusWithin = null;
        this.trapTabFocusWithin = null;
        this.focusFirstFocusable = false;
        this.refocusTriggerOnDeactivate = false;
        this.onUpdatedHandler = this.onUpdated.bind(this);
        this.onUpdatedPrivateHandler = this.onUpdatedPrivate.bind(this);
        this._isDisabled = this.hasAttribute('is-disabled');

        this._isInitialized = false;
    }

    connectedCallback() {
        this._initialization = this.init();
    }

    /**
     * Private init method, run at the end of a tick of the event loop to ensure
     * element's content's are parsed.
     *
     * @method init
     * @private
     */
    async init() {
        await endOfEventLoop();
        this.eventEmitter.addListener('update', this.onUpdatedHandler);
        this.eventEmitter.addListener('update', this.onUpdatedPrivateHandler);

        if (this.clickAction) {
            this.addEventListener('click', this.onEvented);
        }

        if (this.changeAction) {
            this.addEventListener('change', this.onEvented);
        }

        if (this.inputAction) {
            this.addEventListener('input', this.onEvented);
        }

        if (this.keydownAction) {
            this.addEventListener('keydown', this.onEvented, true);
        }

        if (this.touchStartAction) {
            this.addEventListener('touchstart', this.onEvented);
        }

        // Adds a setTimeout of 0 to the resources menu on the homepage to
        // ensure the menu is shown on page load.
        if (this.loadAction) {
            if (this.hasAttribute('delay-load-event')) {
                setTimeout(() => this.dispatchAction(this.loadAction), 0);
            } else {
                this.dispatchAction(this.loadAction);
            }
        }
        await this.onInit();

        this._isInitialized = true;
    }

    /**
     * Init method intended to be overwritten by subclasses
     *
     * @method onInit
     * @public
     * @noop
     */
    async onInit() {
        // noop
    }

    /**
     * This callback is invoked when the element is removed from the dom.
     *
     * @callback
     * @method disconnectedCallback
     */
    disconnectedCallback() {
        this.eventEmitter.removeListener('update', this.onUpdatedHandler);
        this.eventEmitter.removeListener(
            'update',
            this.onUpdatedPrivateHandler
        );
    }

    stopListeningForUpdates() {
        this.eventEmitter.off('update');
    }

    /**
     * Get the current breakpoint as defined in /constants/breakpoints.js
     *
     * @return {String} A string denoting the current breakpoint
     */
    getCurrentBreakpoint() {
        return getPseudoContent(document.body, ':after');
    }

    /**
     * ## Getters/Setters
     */

    get activeClass() {
        return this.dataset.activeClass || CLASS_IS_ACTIVE;
    }

    set activeClass(val) {
        return val;
    }

    get inactiveClass() {
        return this.dataset.inactiveClass || null;
    }

    get deactivateOn() {
        return this.getAttribute('deactivate-on');
    }

    get activateOn() {
        return this.getAttribute('activate-on');
    }

    /**
     * UI group for managing mutually exclusive ui component activations.
     * @type {Array|String}
     */
    get uiGroup() {
        return this.dataset.uiGroup || this._uiGroup;
    }

    /**
     * Attribute for dispatching actions.
     * @type {Array|String}
     */
    get clickAction() {
        return (
            parseJsonAttribute(this, 'on-click') ||
            parseJsonAttribute(this, 'data-click')
        );
    }

    /**
     * Attribute for dispatching actions.
     * @type {Array|String}
     */
    get changeAction() {
        return (
            parseJsonAttribute(this, 'on-change') ||
            parseJsonAttribute(this, 'data-change')
        );
    }

    /**
     * Attribute for dispatching actions.
     * @type {Array|String}
     */
    get inputAction() {
        return parseJsonAttribute(this, 'on-input');
    }

    /**
     * Attribute for dispatching actions.
     * @type {Array|String}
     */
    get keydownAction() {
        const keyDownActions = Array.from(this.attributes).reduce(
            (result, attr) => {
                if (attr.name.startsWith('on-keydown')) {
                    const key = attr.name.split('-')[2];
                    const keyf = `${key.charAt(0).toUpperCase()}${key.slice(
                        1
                    )}`;
                    result.push({
                        key: keyf,
                        value: parseJsonAttribute(this, attr.name),
                    });
                }
                return result;
            },
            []
        );
        if (keyDownActions.length) {
            return keyDownActions;
        }
        return false;
    }

    /**
     * Attribute for dispatching actions.
     * @type {Array|String}
     */
    get loadAction() {
        return (
            parseJsonAttribute(this, 'on-load') ||
            parseJsonAttribute(this, 'data-load')
        );
    }

    /**
     * Attribute for dispatching actions.
     * @type {Array|String}
     */
    get touchStartAction() {
        return parseJsonAttribute(this, 'on-touchstart');
    }

    /**
     * Attribute of event subscriptions that trigger the change() method.
     * @type {Array|String}
     * @optional
     */
    get changeOn() {
        const attrs = parseJsonAttribute(this, 'change-on');
        return Array.isArray(attrs) ? attrs : [attrs];
    }

    /**
     * A object of global config specific to the current page
     * @type {Object}
     */
    get config() {
        return this.context.config;
    }

    /**
     * A class which manages browser focus as the user interacts with the app
     * @type {Class}
     */
    get focusManager() {
        return this.context.focusManager;
    }

    /**
     * A class which provides a set on ui animations
     * @type {Class}
     */
    get animationEngine() {
        return this.context.animationEngine;
    }

    /**
     * A class which locks the page scroll
     * @type {Class}
     */
    get scrollLock() {
        return scrollLockSingleton;
    }

    /**
     * A class which manages the data store
     * @type {Class}
     */
    get store() {
        return this.context.store;
    }

    get scrollLockContextElement() {
        return document.querySelector(
            this.getAttribute('scroll-lock-context-element')
        );
    }

    /**
     * A class which provides and event bus
     * @type {Class}
     */
    get eventEmitter() {
        return this.context.eventEmitter;
    }

    /**
     * An object of templates
     * @type {Object}
     */
    get templates() {
        return this.context.templates;
    }

    get countryRepository() {
        return this.context.countryRepository;
    }

    get animalRepository() {
        return this.context.animalRepository;
    }

    get organizationRepository() {
        return this.context.organizationRepository;
    }

    get searchRepository() {
        return this.context.searchRepository;
    }

    get sessionRepository() {
        return this.context.sessionRepository;
    }

    get mediaRepository() {
        return this.context.mediaRepository;
    }

    get userRepository() {
        return this.context.userRepository;
    }

    get userSavedSearchRepository() {
        return this.context.userSavedSearchRepository;
    }

    get wordpressRepository() {
        return this.context.wordpressRepository;
    }

    get eventsRepository() {
        return this.context.eventsRepository;
    }

    get translations() {
        return this.context.translations;
    }

    get target() {
        return this.dataset.target;
    }

    isTarget(target) {
        return Array.from(document.querySelectorAll(target)).includes(this);
    }

    /**
     * An object serving as a service locator
     * @type {Object|undefined}
     * @async
     */
    get context() {
        let node = this;
        while (node) {
            if (node.hasOwnProperty('_context')) {
                return node._context;
            }
            node = node.parentNode;
        }
    }

    get isDynamic() {
        return this.hasAttribute('is-dynamic');
    }

    set context(val) {
        this._context = val;
    }

    /**
     * ## Methods
     */

    /**
     * When hearing an event, dispatches the attached data-`event` values to bubble up to
     * app and passes along this element for processing by listeners.
     * Listening elements may parse any other data attributes.
     *
     * @method dispatchActions
     * @private
     * @param {String} type of action
     * @param {Object} payload to send along with
     * @param {Element} element
     * @param {Object} dispatcher
     */
    dispatchAction(type, payload = {}, element = this, dispatcher = this) {
        // TODO: cleaner arguments
        if (!Array.isArray(type)) {
            type = [type]; // eslint-disable-line no-param-reassign
        }
        type.forEach(action => {
            const detail = Object.assign({}, payload, {
                type: action,
                trigger: element,
            });
            dispatcher.dispatchEvent(buildCustomEvent('action', detail));
        });
    }

    /**
     * TODO: [activate description]
     *
     * @async
     */
    async activate() {
        this.classList.add(this.activeClass);

        if (this.shouldAlertTextContent) {
            scheduleMicrotask(() => {
                this.dispatchAction('', { statusText: this.textContent });
            });
        }

        if (this.inactiveClass !== null) {
            this.classList.remove(this.inactiveClass);
        }
    }

    /**
     * TODO: [deactivate description]
     *
     * @async
     */
    async deactivate() {
        this.classList.remove(this.activeClass);

        if (this.inactiveClass !== null) {
            this.classList.add(this.inactiveClass);
        }
    }

    /**
     * Change the dates on a component if relevant
     * (used on things date dependent like charts)
     *
     * @method changeDates
     * @async
     */
    async changeDates() {}

    /**
     * Called on updates the component is subscribed to
     * via the `change-on` attribute.
     * Extended components provide their own implementation of this method.
     *
     * @method change
     * @noop
     */
    change() {
        // noop
    }

    /**
     * Locks scroll within the scrollLockContextElement if one exists.
     *
     * @method lockScroll
     * @public
     */
    lockScroll() {
        if (this.scrollLockContextElement) {
            this.scrollLock.lockScroll(this.scrollLockContextElement);
        } else {
            this.scrollLock.lockScroll();
        }
    }

    /**
     * Unlocks scroll within the scrollLockContextElement if one exists.
     *
     * @method unlockScroll
     * @public
     */
    unlockScroll() {
        if (this.scrollLockContextElement) {
            this.scrollLock.unlockScroll(this.scrollLockContextElement);
        } else {
            this.scrollLock.unlockScroll();
        }
    }

    /**
     * ## Handlers
     */

    /**
     * When hearing an event, dispatches the attached data-`event` values to bubble up to
     * app and passes along this element for processing by listeners.
     * Listening elements may parse any other data attributes.
     *
     * @method onEvented
     * @private
     * @param {Event} ev
     */
    onEvented(ev) {
        if (!this[`${ev.type}Action`] || this.isDisabled) {
            return;
        }
        if (!this.hasAttribute('allow-event-propagation')) {
            ev.stopPropagation();
        }
        if (ev.type === 'click' && !this.hasAttribute('allow-event-default')) {
            ev.preventDefault();
        }
        if (ev.type === 'keydown') {
            this[`${ev.type}Action`].forEach(action => {
                if (action.key === ev.key) {
                    ev.preventDefault();
                    this.dispatchAction(action.value, ev);
                }
            });
            return;
        }
        this.dispatchAction(this[`${ev.type}Action`], { srcEvent: ev });
    }

    /**
     * All elements listen for the update event. Should be overwritten in component.
     *
     * @method onUpdated
     * @noop
     */
    onUpdated() {
        // noop
    }

    /**
     * This is for implementation details of common PFElement level updates
     * so extended classes don't need to worry about calling super in onUpdated.
     *
     * @method onUpdatedPrivate
     * @private
     * @param {Event} ev
     */
    onUpdatedPrivate(ev) {
        if (ev === undefined) {
            return;
        }
        const type = ev.detail.type;

        /* Call change whenever a provided changeOn event is heard. */
        if (this.changeOn.includes(ev.detail.type)) {
            this.change(ev);
        }
        if (type === EV_ENABLE && this.isTarget(ev.detail.target)) {
            this.isDisabled = false;
        }
        if (type === EV_DISABLE && this.isTarget(ev.detail.target)) {
            this.isDisabled = true;
        }
        if (type === this.activateOn) {
            this.activate();
        }
        if (type === this.deactivateOn) {
            this.deactivate();
        }
    }
}

export default PFElement;
