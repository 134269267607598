import Mixins from '../../../../core/scripts/lib/wirey/Mixins';
import MixinBase from '../../../../core/scripts/lib/wirey/mixins/MixinBase';
import Utils from '../../../../core/scripts/lib/wirey/Utils';

export default class StateValueMixin extends MixinBase {
    static _attributeName = 'mix-state-value';

    constructor(ele, value) {
        super(ele, value);

        let ref = this._ele;
        do {
            if (!ref.observedState) {
                continue;
            }

            const dereferenced = Utils.ObjectUtils.dereference(
                value,
                ref.observedState
            );
            if (!dereferenced.success) {
                continue;
            }

            let content;
            if (typeof dereferenced.result === 'object') {
                content = JSON.stringify(dereferenced.result);
            } else {
                content = dereferenced.result;
            }

            this._ele.innerHTML = content;
            return;
        } while ((ref = ref.parentElement));
    }
}

Mixins.add(StateValueMixin);
