import { trackEvent } from '../../../core/scripts/analytics';
import _get from 'lodash/get';
import AnimalBreedsEventModel from '../../../core/scripts/analytics/models/animal-breeds/AnimalBreedsEventModel';
import DogBreedEventModel from '../../../core/scripts/analytics/models/animal-breeds/dog/DogBreedEventModel';
import CatBreedEventModel from '../../../core/scripts/analytics/models/animal-breeds/cat/CatBreedEventModel';

const BREED_METRIC = { 4: 1 };
const BREED_FILTER_METRIC = { 3: 1 };

/**
 * _buildAnimalBreedAnalyticsByAnimalType
 * Determines what analytic event model to use based on animalType
 *
 * @param  {string} animalType [animalType description]
 * @param  {Object} filters
 *
 * @return {AnimalBreedsEventModel|DogBreedEventModel|CatBreedEventModel} AnimalBreedsEventModel or extended class
 */
function _buildAnimalBreedAnalyticsByAnimalType(animalType, filters = {}) {
    let analytics;
    switch (animalType) {
        case 'dog':
            analytics = DogBreedEventModel.fromAnimalBreedFilters(filters, {
                filterOutNullText: false,
            });
            break;
        case 'cat':
            analytics = CatBreedEventModel.fromAnimalBreedFilters(filters, {
                filterOutNullText: false,
            });
            break;
        default:
            analytics = AnimalBreedsEventModel.fromAnimalBreedFilters(filters, {
                filterOutNullText: false,
            });
    }

    return analytics;
}

/**
 * Takes the filter values and optional keys to filter as an array, returns a string
 * like this 'size:medium, size:large, size:small, energyLevel:moderate'
 * @param {Object} filterValues
 * @param {Array} filterKeys
 * @returns {string}
 */
function formatFilterValues(filterValues, filterKeys = []) {
    const filterParts = [];
    const selectedValues = {};

    Object.keys(filterValues)
        .filter(key => !filterKeys.includes(key))
        .forEach(key => {
            const value = filterValues[key];
            if (value === null) {
                return;
            }
            if (typeof value === 'object') {
                const values = Object.keys(value);
                if (values.length < 1) {
                    return;
                }

                const valueStrings = values
                    .filter(valueKey => value[valueKey])
                    .map(valueKey => {
                        return `${key}:${valueKey}`;
                    });

                if (valueStrings.length > 0) {
                    filterParts.push(valueStrings.join(', '));
                }
            } else {
                filterParts.push(`${key}:${value}`);
            }
            selectedValues[key] = value;
        });

    return filterParts.length > 0 ? filterParts.join(', ') : '';
}

/**
 * EVENTS
 *
 * Each function should call the trackEvent method with an object param containing the following
 * keys with your provided values:
 * trackEvent({
 *      category: '',
 *      action: '',
 *      label: '',
 *      dimensions: {}, // dimensions is an object with numbered keys at time of writing
 *      additional: {}, // additional is an object that will be set as keys in the event object
 *
 *  });
 */

/**
 * Breed landing hero, clicked left button
 * @public
 * @event breed003
 * @event breed008
 * @param {Object} params
 */
export function analyticsBreed003(params) {
    const animalType = params.category;

    trackEvent({
        category: `${animalType} breed search`,
        action: 'by collection',
    });
}

/**
 *  * Breed landing hero, clicked right button
 * @public
 * @event breed004
 * @event breed009
 * @param {Object} params
 */
export function analyticsBreed004(params) {
    const animalType = params.category;

    trackEvent({
        category: `${animalType} breed search`,
        action: 'by filter',
    });
}

/**
 * Breed collection slider selection
 * @public
 * @event breed005
 * @event breed010
 * @param {Object} params
 */
export function analyticsBreed005(params) {
    const animalType = params.category;
    const optionSelected = params.actions;
    const position = params.label;

    trackEvent({
        category: `${animalType} breed carousel`,
        action: optionSelected,
        label: position,
    });
}

/**
 * Collection slider tooltip show
 * @public
 * @event breed006
 * @event breed011
 * @param {string} animalTypeName
 * @param {string} articleName
 */
export function analyticsBreed006(animalTypeName, articleName) {
    trackEvent({
        category: `${animalTypeName} breed carousel`,
        action: `tooltip: ${articleName.toLowerCase()}`,
    });
}

/**
 * Clicked breed in autosuggest search component
 * @public
 * @event breed007
 * @event breed002
 * @param {string} animalTypeName
 * @param {string} animalBreedName
 * @param {string} searchString
 */
export function analyticsBreed007(
    animalTypeName,
    animalBreedName,
    searchString = ''
) {
    trackEvent({
        category: `${animalTypeName} breed search`,
        action: animalBreedName,
        label: searchString,
    });
}

/**
 * Expand and collapse breed filters
 * @public
 * @event breed012
 * @event breed027
 * @event breed085
 * @event breed086
 * @param {string} animalTypeName
 * @param {boolean} viewAllFilterActive
 */
export function analyticsBreed012(animalTypeName, viewAllFilterActive) {
    if (viewAllFilterActive) {
        trackEvent({
            category: `${animalTypeName} breed filter`,
            action: 'view all filters',
        });
    } else {
        trackEvent({
            category: `${animalTypeName} breed filter`,
            action: 'close all filters',
        });
    }
}

/**
 * Resetting breed filters
 * @public
 * @event breed013
 * @event breed029
 * @param {string} animalTypeName
 * @param {Object} filterValues
 */
export function analyticsBreed013(animalTypeName, filterValues) {
    // TODO: refactor this event to separate events 13 and 29
    const analytics = _buildAnimalBreedAnalyticsByAnimalType(
        animalTypeName,
        filterValues
    );
    trackEvent({
        category: `${animalTypeName} breed filter`,
        action: 'reset filters',
        event: 'analyticsBreed013 - Breeds',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
        metrics: BREED_FILTER_METRIC,
    });
}

/**
 * Changing a breed filter (adding and removing)
 * @public
 * @event breed014
 * @event breed018
 * @event breed028
 * @event breed033
 * @param {string} animalTypeName
 * @param {Array} filterChanged
 * @param {Object} prevValues of filters
 * @param {Object} currValues of filters
 */
export function analyticsBreed014(
    animalTypeName,
    filterChanged,
    prevValues,
    currValues
) {
    // TODO: This appears to be a combination of a few of events not just Breeds014.  A filter function should be added and include missing event functions that are mashed here. - this is sort of happening with analyticsBreedFilterChange already
    const filterLabel = filterChanged[0];
    const filterValue = filterChanged[1];
    const wasFilterRemoved = !currValues[filterLabel][filterValue];
    const analytics = _buildAnimalBreedAnalyticsByAnimalType(
        animalTypeName,
        currValues
    );
    let action = `filter: ${filterLabel}`;
    let label = filterValue;
    if (wasFilterRemoved) {
        action = 'filter removed';
        label = `${filterLabel}:${filterValue}`;
    }
    trackEvent({
        category: `${animalTypeName} breed filter`,
        action,
        label,
        event: 'analyticsBreed014 - Breeds',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
        metrics: BREED_FILTER_METRIC,
    });
}

/**
 * No matches found after filters
 * @public
 * @event breed015
 * @event breed030
 * @param {string} animalTypeName
 */
export function analyticsBreed015(animalTypeName) {
    trackEvent({
        category: `${animalTypeName} breed filter`,
        action: 'no match found',
    });
}

/**
 * Toggle of "Show only breeds available for adoption near me"
 *
 * Applies to breed landing page
 *
 * @public
 * @event breed016
 * @event breed031
 * @param {string} animalTypeName
 * @param {Object} filterValues
 */
export function analyticsBreed016(animalTypeName, filterValues) {
    // TODO: Separate breed functionality for events 016 and 031
    const checked = filterValues.scope.onlyNearMe;
    let dimensions = {};
    let additional = {};
    // only supply filters if the box is now checked
    if (checked) {
        const analytics = _buildAnimalBreedAnalyticsByAnimalType(
            animalTypeName,
            filterValues
        );
        dimensions = analytics.dimensions;
        additional = analytics.dataLayer;
    }

    trackEvent({
        category: `${animalTypeName} breed filter`,
        action: 'select available for adoption',
        label: checked ? 'check' : 'uncheck',
        event: 'analyticsBreed016 - Breeds',
        dimensions,
        additional,
        metrics: BREED_METRIC,
    });
}

/**
 * Breed landing filters tooltip show
 * @public
 * @event breed017
 * @event breed032
 * @param {string} animalTypeName
 * @param {string} filterName
 */
export function analyticsBreed017(animalTypeName, filterName) {
    trackEvent({
        category: `${animalTypeName} breed filter`,
        action: `tooltip: ${filterName.toLowerCase()}`,
    });
}

/**
 * Click breed card body
 * @public
 * @event breed019
 * @event breed034
 * @param {string} animalTypeName
 * @param {string} breed
 * @param {Object} filterValues
 */
export function analyticsBreed019(animalTypeName, breed, filterValues) {
    // TODO: Breeds 019 and 034 events into separate functions
    const analytics = _buildAnimalBreedAnalyticsByAnimalType(
        animalTypeName,
        filterValues
    );
    trackEvent({
        category: `${animalTypeName} breed card`,
        action: `card: ${breed.toLowerCase()}`,
        label: 'breed detail page',
        event: 'analyticsBreed019 - Breeds',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
        metrics: BREED_METRIC,
    });
}

/**
 * Click breed card footer
 * @public
 * @event breed020
 * @event breed035
 * @param {string} animalTypeName
 * @param {string} breed
 * @param {Object} filterValues
 */
export function analyticsBreed020(animalTypeName, breed, filterValues) {
    // TODO: 020 and 035 into separate analytic events
    const analytics = _buildAnimalBreedAnalyticsByAnimalType(
        animalTypeName,
        filterValues
    );
    trackEvent({
        category: `${animalTypeName} breed card`,
        action: `card: ${breed.toLowerCase()}`,
        label: 'adoptable',
        event: 'analyticsBreed020 - Breeds',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
        metrics: BREED_METRIC,
    });
}

/**
 * Triggered when the share slideout on a breed result page is opened
 * @public
 * @event breed021
 * @event breed036
 * @param {string} animalTypeName
 * @param {Object} filterValues
 */
export function analyticsBreed021(animalTypeName, filterValues) {
    const eventLabel = formatFilterValues(filterValues, [
        'page',
        'viewAllFilters',
        'scope',
    ]);

    trackEvent({
        category: `${animalTypeName} breed filter`,
        action: 'clicks to share',
        label: eventLabel,
    });
}

/**
 * Click to share email from slideout
 * @public
 * @event breed022
 * @event breed023
 * @event breed037
 * @event breed038
 * @param {string} shareType
 * @param {string} animalTypeName
 * @param {Object} filterValues
 */
export function analyticsBreed022(shareType, animalTypeName, filterValues) {
    const eventLabel = formatFilterValues(filterValues, [
        'page',
        'viewAllFilters',
        'scope',
    ]);

    trackEvent({
        category: `${shareType} share`,
        action: `${animalTypeName} breed filter`,
        label: eventLabel,
    });
}

/**
 * Breeds filterable list pagination
 * @public
 * @event breed024
 * @event breed025
 * @event breed026
 * @event breed039
 * @event breed040
 * @event breed041
 * @event breed054
 * @event breed055
 * @event breed056
 * @param {string} id
 * @param {string|number} paginationDirection
 */
export function analyticsBreed024(id, paginationDirection) {
    let action = 'results pagination';
    let category = id;
    let direction = paginationDirection;

    if (typeof paginationDirection === 'number') {
        action = 'results pagination - select page';
    }

    if (direction === 'prev') {
        direction = 'previous';
    }

    switch (id) {
        case 'collection':
            category = `breed ${id}`;
            break;
        case 'dog':
        case 'cat':
            category = `${id} breed card`;
            break;
        default:
            break;
    }

    trackEvent({
        category,
        action,
        label: `${direction}`,
    });
}

/**
 * View all breed filters
 * @public
 * @event breed027
 * @param {string} animalTypeName
 */
export function analyticsBreed027(animalTypeName) {
    trackEvent({
        category: `${animalTypeName} breed filter`,
        action: 'view all filters',
    });
}

/**
 * Click "Back to All Breeds" from Collection Page
 * @public
 * @event breed042
 * @param {Object} params
 */
export function analyticsBreed042(params) {
    const backLinkText = params.label.toLowerCase();

    trackEvent({
        category: 'breed collection',
        action: 'previous page',
        label: backLinkText,
    });
}

/**
 * Available for adoption link (near header)
 * @public
 * @event breed043
 * @event breed058
 * @param {Object} params
 */
export function analyticsBreed043(params) {
    const pageName = params.category;

    trackEvent({
        category: pageName,
        action: 'adoptable',
    });
}

/**
 * Open save search modal
 * @public
 * @event breed044
 */
export function analyticsBreed044() {
    trackEvent({
        category: 'search alerts',
        action: 'open',
    });
}

/**
 * Saved search successful
 * @public
 * @event breed045
 * @param {Object} response
 */
export function analyticsBreed045(response) {
    const searchName = _get(response, 'data.name', '');

    trackEvent({
        category: 'search alerts',
        action: 'save alert: successful',
        label: searchName,
    });
}

/**
 * Saved search unsuccessfull/errors
 * @public
 * @event breed046
 * @param {Object} errors
 */
export function analyticsBreed046(errors) {
    const errorStr = formatFormErrors(errors);

    trackEvent({
        category: 'search alerts',
        action: 'save alert: unsuccessful',
        label: errorStr,
    });
}

/**
 * Format errors for #046
 * @public
 * @param {Object} errors
 * @returns {string}
 */
function formatFormErrors(errors) {
    const errorArray = [];
    const fieldErrors = _get(errors, 'fields', {});
    const globalErrors = _get(errors, 'global', []);
    const fieldErrorKeys = Object.keys(fieldErrors);

    if (fieldErrorKeys.length > 0) {
        fieldErrorKeys.forEach(key => {
            errorArray.push(fieldErrors[key]);
        });
    }
    if (globalErrors.length > 0) {
        errorArray.push(errors.global);
    }

    return errorArray.join(', ');
}

/**
 * Expand 'Read More' accordian
 * @public
 * @event breed047
 * @param {Object} params
 */
export function analyticsBreed047(params) {
    const pageName = params.category;
    const accordianLabel = params.action;

    trackEvent({
        category: pageName,
        action: accordianLabel,
    });
}

/**
 * Click to share
 * @public
 * @event breed048
 * @event breed067
 * @param {string} pageName
 * @param {string} label
 */
export function analyticsBreed048(pageName, label = 'primary') {
    trackEvent({
        category: pageName,
        action: 'clicks to share',
        label,
    });
}

/**
 * Click to share email
 * @public
 * @event breed049
 * @event breed068
 * @param {string} pageName
 * @param {string} breedOrCollectionName
 */
export function analyticsBreed049(pageName, breedOrCollectionName) {
    trackEvent({
        category: 'email share',
        action: pageName,
        label: breedOrCollectionName,
    });
}

/**
 * Click to share link
 * @public
 * @event breed050
 * @event breed069
 * @param {string} pageName
 * @param {string} breedOrCollectionName
 */
export function analyticsBreed050(pageName, breedOrCollectionName) {
    trackEvent({
        category: 'link share',
        action: pageName,
        label: breedOrCollectionName,
    });
}

/**
 * BreedPages051
 * Page: Breed Collection
 * Action: Click checkbox to select breeds available for adoption
 *
 * @public
 * @param  {string} animalTypeName
 * @param  {Object} filterValues
 * @event BreedPages051
 */
export function analyticsBreed051(animalTypeName, filterValues) {
    const checked = filterValues.scope.onlyNearMe;
    let dimensions = {};
    let additional = {};
    // only supply filters if the box is now checked
    if (checked) {
        const analytics = _buildAnimalBreedAnalyticsByAnimalType(
            animalTypeName,
            filterValues
        );
        dimensions = analytics.dimensions;
        additional = analytics.dataLayer;
    }

    trackEvent({
        category: 'breed collection',
        action: 'adoptable',
        label: checked ? 'check' : 'uncheck',
        event: 'Breed Pages 051 - Breeds',
        dimensions,
        additional,
    });
}

/**
 * Click breed collection card body
 * @public
 * @event breed052
 * @param {string} breed
 */
export function analyticsBreed052(breed) {
    trackEvent({
        category: 'breed collection',
        action: `card: ${breed.toLowerCase()}`,
        label: 'breed detail page',
    });
}

/**
 * Click breed collection card footer
 * @public
 * @event breed053
 * @param {string} breed
 */
export function analyticsBreed053(breed) {
    trackEvent({
        category: 'breed collection',
        action: `card: ${breed.toLowerCase()}`,
        label: 'adoptable',
    });
}

/** Click back from breeds page
 * @public
 * @event breed057
 * @param {Object} params
 */
export function analyticsBreed057(params) {
    const backPage = params.label.toLowerCase();

    trackEvent({
        category: 'breed detail',
        action: 'previous page',
        label: backPage,
    });
}

/**
 * Click to anchor
 * @public
 * @event breed060
 * @event breed061
 * @param {string} anchorTag
 * @param {string} anchorLabel
 */
export function analyticsBreed060(anchorTag, anchorLabel) {
    trackEvent({
        category: 'breed detail',
        action: anchorTag,
        label: anchorLabel,
    });
}

/**
 * Organization list: click 'see pets'
 * @public
 * @event breed065
 * @event breed066
 * @param {Object} params
 */
export function analyticsBreed065(params) {
    const eventLabel = params.label;

    trackEvent({
        category: 'breed detail',
        action: 'organizations with breed',
        label: eventLabel,
    });
}

/**
 * Click related breed slide
 * @public
 * @event breed070
 * @param {string} selectedOption
 * @param {string} slideIndex
 */
export function analyticsBreed070(selectedOption, slideIndex) {
    trackEvent({
        category: 'similar breeds carousel',
        action: selectedOption.toLowerCase(),
        label: `${slideIndex + 1}`,
    });
}

/**
 * Click on any recommended pet
 * @public
 * @event breed071
 * @event breed072
 * @param {Object} params
 */
export function analyticsBreed071(params) {
    const recommendedPetHeadline = params.heading;
    const petId = `/petdetail/${params.id}`;
    const placementId = params.placementId;

    trackEvent({
        category: 'recommended pet',
        action: recommendedPetHeadline,
        label: petId,
    });

    trackEvent({
        category: 'recommended pet',
        action: placementId,
        label: petId,
    });
}

/** Click 'view more' on recommended pet
 * @public
 * @event breed073
 * @param {Object} params
 */
export function analyticsBreed073(params) {
    const headline = params.action;

    trackEvent({
        category: 'recommended pet',
        action: headline,
        label: 'clicks to view more',
    });
}

/** Click 'view more' on recommended pet
 * @public
 * @event breed074
 * @param {Object} params
 */
export function analyticsBreed074(params) {
    const id = params.action;

    trackEvent({
        category: 'recommended pet',
        action: id,
        label: 'clicks to view more',
    });
}

/** Click on related content
 * @public
 * @event breed075
 * @param {Object} params
 */
export function analyticsBreed075(params) {
    const article = params.label;

    trackEvent({
        category: 'featured content',
        action: 'read more',
        label: article,
    });
}

/** Vote on user poll
 * @public
 * @event breed076
 * @param {Object} params
 */
export function analyticsBreed076(params) {
    const pollHeading = params.action;
    const vote = params.label;

    trackEvent({
        category: 'poll',
        action: pollHeading,
        label: vote,
    });
}

/**
 * Click breeds global nav on desktop
 * @public
 * @event breed077
 * @event breed078
 * @param {boolean} isMenuOpen
 */
export function analyticsBreed077(isMenuOpen) {
    const openOrClose = isMenuOpen ? 'open' : 'close';

    trackEvent({
        category: 'global-nav',
        action: 'breeds',
        label: openOrClose,
    });
}

/**
 * Triggered when the share slideout on a collection page is opened
 * @public
 * @event breed081
 */
export function analyticsBreed081() {
    trackEvent({
        category: 'breed collection',
        action: 'clicks to share',
        label: 'secondary',
    });
}

/**
 * Triggered when the share button on the breed sticky nav is activated
 * @public
 * @event breed082
 */
export function analyticsBreed082() {
    trackEvent({
        category: 'breed detail',
        action: 'clicks to share',
        label: 'secondary',
    });
}

/** Recommended pet appears in view
 * @public
 * @event breed083
 * @param {Object} params
 */
export function analyticsBreed083(params) {
    const headline = params.heading;

    trackEvent({
        category: 'recommended pet',
        action: headline,
        label: 'impression',
    });
}

/** Recommended pet appears in view
 * @public
 * @event breed084
 * @param {Object} params
 */
export function analyticsBreed084(params) {
    const id = params.id;

    trackEvent({
        category: 'recommended pet',
        action: id,
        label: 'impression',
    });
}

/** Click CTA on the "no pets available near you for adoption" module
 * @public
 * @event breed088
 * @param {Object} params
 * @param params.context single analytics event is triggered in two contexts - context is a string
 *     used for disambiguation in order to send the correct label
 */
export function analyticsBreed088(params) {
    const context = params.context;

    trackEvent({
        category: 'recommended pet',
        action: 'no pets nearby module',
        label:
            context === 'SET_ALERT'
                ? 'click to set alert'
                : 'click to search all locations',
    });
}

/**
 * Changing a breed filter (adding and removing)
 * @public
 * @event breed014
 * @event breed018
 * @event breed028
 * @event breed033
 * @param {string} animalTypeName
 * @param {Array} filterChanged
 * @param {Object} prevValues of filters
 * @param {Object} filterValues
 * @param {string} pageName
 */
export function analyticsBreedFilterChange(
    animalTypeName,
    filterChanged,
    prevValues,
    filterValues,
    pageName
) {
    // TODO: This is a collection, not really an analytic filter.  Would suggest moving this to a collection function and adding the analytic filter discussed in analyticsBreed014

    // fire off change event
    analyticsBreed014(animalTypeName, filterChanged, prevValues, filterValues);

    // "Show only breeds available for adoption near me" checkbox click
    if (filterChanged[1] === 'onlyNearMe') {
        if (pageName === 'breed collection') {
            analyticsBreed051(animalTypeName, filterValues);
        } else {
            analyticsBreed016(animalTypeName, filterValues);
        }
    }
}
