import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { getCurrentBreakpoint } from '../../../../core/scripts/util/dom';
import { EV_FORM_STATUS_UPDATE } from '../../constants/events';
import {
    STATUS_PASSWORD_VALID,
    STATUS_PASSWORD_INVALID,
} from '../../constants/statuses';

/**
 * Wraps the password creation field and password requirements markup. Checks the password fields
 * validity against rules set below and visually marks the corresponding indicator.
 *
 * @extends PFElement
 */
export class PFDCPasswordValidatorElement extends PFElement {
    /**
     * If persistent, popup validator will display to the right of the inputs,
     * rather than appearing on click.
     */
    get persistent() {
        return this.hasAttribute('persistent');
    }

    /**
     * Initialize this component
     */
    onInit() {
        /**
         * String representation of the selector for the password validator element.
         *
         * @property elementSelector
         * @type {string}
         */
        this.elementSelector = 'pf-password-validator';

        /**
         * String representation of the selector for the password input element.
         *
         * @property passwordFieldSelector
         * @type {string}
         */
        this.passwordFieldSelector = `${this.elementSelector}-input`;

        /**
         * Holds reference to the password input element.
         *
         * @property passwordField
         * @type {HTMLElement}
         */
        this.passwordField = null;

        /**
         * Holds reference to the password inputs current value.
         *
         * @property passwordFieldValue
         * @type {string}
         */
        this.passwordFieldValue = '';

        /**
         * An array of the invalid rules.
         *
         * @property invalidRules
         * @type {Array}
         */
        this.invalidRules = [];

        /**
         * String class name to place on indicators that pass rule checks.
         *
         * @property ruleIndicatorValidClassName
         * @type {string}
         */
        this.ruleIndicatorValidClassName = 'password-rule_success';

        /**
         * String selector for the rules popup container
         * @type {string}
         */
        this.rulesPopupSelector = `${this.elementSelector}-popup`;

        /**
         * String representation of the container holding all the validation rules.
         * @type {string}
         */
        this.ruleContainerSelector = `${this.elementSelector}-rules`;

        /**
         * String representation of the container holding the 'valid' message.
         * @type {[type]}
         */
        this.passwordValidMsgSelector = `${this.elementSelector}-valid-msg`;

        /**
         * A string representation of the selector for the isHidden utility.
         *
         * @property isHiddenSelector
         * @type {string} The class name to hide page elements.
         */
        this.isHiddenSelector = 'u-isHidden';

        /**
         * The minimum length requirement for password input value.
         *
         * @property minLength
         * @type {int}
         */
        this.minLength = 8;

        /**
         * String representation of the selector for the minLengthIndicator.
         *
         * @property minLengthIndicatorSelector
         * @type {string}
         */
        this.minLengthIndicatorSelector = `${
            this.elementSelector
        }-rule-min-length`;

        /**
         * Holds reference to the minLengthIndicator element.
         *
         * @property passwordFieldSelector
         * @type {HTMLElement}
         */
        this.minLengthIndicator = null;

        /**
         * Validation rule objects. Each rule contains the necessary regex, and references to
         * the elementSelector for the indicators and indicator itself.
         *
         * @property validationRules
         * @type {Object}
         */
        this.validationRules = {
            digit: {
                regex: /.*\d/,
                elementSelector: `${this.elementSelector}-rule-numbers`,
                element: null,
            },
            lowercase: {
                regex: /.*[a-z]/,
                elementSelector: `${this.elementSelector}-rule-lowercase`,
                element: null,
            },
            uppercase: {
                regex: /.*[A-Z]/,
                elementSelector: `${this.elementSelector}-rule-uppercase`,
                element: null,
            },
            specialChar: {
                // eslint-disable-next-line no-useless-escape
                regex: /.*[-`~!@#$%^&*()_=+{}\[\]|\;:'\",.<>\\/?]/,
                elementSelector: `${this.elementSelector}-rule-special-chars`,
                element: null,
            },
        };

        this.popup = this.querySelector(`[${this.rulesPopupSelector}]`);
        this.passwordField = this.querySelector(
            `[${this.passwordFieldSelector}]`
        );
        this.minLengthIndicator = this.querySelector(
            `[${this.minLengthIndicatorSelector}]`
        );

        this.ruleContainer = this.querySelector(
            `[${this.ruleContainerSelector}]`
        );
        this.passwordValidMsg = this.querySelector(
            `[${this.passwordValidMsgSelector}]`
        );

        Object.keys(this.validationRules).forEach(rule => {
            this.validationRules[rule].element = this.querySelector(
                `[${this.validationRules[rule].elementSelector}]`
            );
        });

        this.numValidityChecks = 0;
        this.checkFieldValidity();

        if (!this.persistent) {
            this.hidePopup();
        }

        this.addEventListener('focus', this.onFocused, true);
        this.addEventListener('blur', this.onBlurred, true);
        this.addEventListener('input', this.onInput);
    }

    /**
     * Sets a rule indicator to valid by changing a class.
     * @param {element} el element to apply the class to, rule indicators
     */
    setRuleIndicatorValid(el) {
        el.classList.add(this.ruleIndicatorValidClassName);
    }

    /**
     * Sets a rule indicator to invalid by changing a class.
     * @param {element} el element to remove the class from, rule indicators
     */
    setRuleIndicatorInvalid(el) {
        el.classList.remove(this.ruleIndicatorValidClassName);
    }

    /**
     * Register/add a rule to the invalidRules array
     * @param {string} rule to the rule that is invalid
     */
    registerInvalidRule(rule) {
        if (this.invalidRules.indexOf(rule) === -1) {
            this.invalidRules.push(rule);
        }
    }

    /**
     * Un-Register/remove a rule from the invalidRules array
     * @param {string} rule to the rule that is now valid and needs to be removed from the array
     */
    unregisterInvalidRule(rule) {
        if (this.invalidRules.indexOf(rule) !== -1) {
            this.invalidRules.splice(this.invalidRules.indexOf(rule), 1);
        }
    }

    /**
     * Tests the current password input value length agains the min accepted length.
     * @return {boolean}
     */
    isLengthValid() {
        return this.passwordFieldValue.length >= this.minLength;
    }

    /**
     * Runs the regex expression for the passed rule.
     * @param {string} rule the rule that validity should be tested on
     * @return {boolean}
     */
    checkRuleValidity(rule) {
        return (
            this.validationRules[rule].regex.exec(this.passwordFieldValue) !==
            null
        );
    }

    /**
     * Performs all the validity checking by utilizing the above methods and sets the rule
     * indicators accordingly.
     * @return {boolean}
     */
    checkFieldValidity() {
        this.passwordFieldValue = this.passwordField.value;

        Object.keys(this.validationRules).forEach(rule => {
            if (this.checkRuleValidity(rule)) {
                this.unregisterInvalidRule(rule);
                this.setRuleIndicatorValid(this.validationRules[rule].element);
            } else {
                this.registerInvalidRule(rule);
                this.setRuleIndicatorInvalid(
                    this.validationRules[rule].element
                );
            }
        });

        if (!this.isLengthValid()) {
            this.setRuleIndicatorInvalid(this.minLengthIndicator);
            this.valid = false;
        } else {
            this.setRuleIndicatorValid(this.minLengthIndicator);
            this.valid = true;

            if (this.invalidRules.length > 1) {
                this.valid = false;
            }
        }

        if (this.valid) {
            if (this.numValidityChecks >= 1) {
                // this.dispatchAction('', { statusText: STATUS_PASSWORD_VALID });
                this.dispatchAction(EV_FORM_STATUS_UPDATE, {
                    statusText: STATUS_PASSWORD_VALID,
                });
            }
        } else {
            if (this.numValidityChecks >= 1) {
                // this.dispatchAction('', { statusText: STATUS_PASSWORD_INVALID });
                this.dispatchAction(EV_FORM_STATUS_UPDATE, {
                    statusText: STATUS_PASSWORD_INVALID,
                });
            }
        }

        this.numValidityChecks++;

        // Show 'Valid' message when rules are met and hide rules
        this.ruleContainer.classList.toggle(this.isHiddenSelector, this.valid);
        this.passwordValidMsg.classList.toggle(
            this.isHiddenSelector,
            !this.valid
        );

        return this.valid;
    }

    /**
     * Show the valid password indicator
     */
    showPopup() {
        this.popup.classList.remove('u-isVisuallyHidden');
    }

    /**
     * Hide the valid password indicator
     */
    hidePopup() {
        this.popup.classList.add('u-isVisuallyHidden');
    }

    /**
     * Handles focus events.
     * @param {Object} ev event object
     */
    onFocused(ev) {
        if (ev.target.hasAttribute(this.passwordFieldSelector)) {
            this.checkFieldValidity();
        }
        if (!this.persistent) {
            this.showPopup();
        }
    }

    /**
     * Handles blur events.
     * @param {Object} ev event object
     */
    onBlurred(ev) {
        if (ev.target.hasAttribute(this.passwordFieldSelector)) {
            this.checkFieldValidity();
        }
        if (!this.persistent) {
            this.hidePopup();
        }
    }

    /**
     * Handles input events.
     * @param {Object} ev event object
     */
    onInput() {
        this.checkFieldValidity();
    }
}

export default PFDCPasswordValidatorElement;
