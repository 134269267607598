import fastClone from 'fast-clone';
import isFocusable from 'ally.js/is/focusable';
import _get from 'lodash/get';
import _set from 'lodash/set';

const COMMA_SPLIT_REGEX = / *, */;

export class StringUtils {
    /**
     * Searches str for searchKeyRegex, replacing with values found within
     * valueObj keyed by the regex match array item at keyPosition.
     *
     * @param {string} str
     * @param {Regexp} searchKeyRegex
     * @param {Number} keyPosition
     * @param {Object} valueObj
     *
     * @return {string}
     */
    static keyedReplace(str, searchKeyRegex, keyPosition, valueObj) {
        // var str = 'Page ::currentPage::/::totalPages::';
        // var searchRegex = /::([a-zA-Z0-9.]+)::/g;

        const replacedStr = str.replace(searchKeyRegex, (...matchInfo) => {
            // get the key/address to use to find the value
            const valueKeyOrAddress = matchInfo[keyPosition];

            // find the value
            const dataValue = ObjectUtils.access(valueKeyOrAddress, valueObj);

            return dataValue;
        });

        return replacedStr;
    }

    /**
     * Parses attribute key/value string to object.
     *
     * "a: 1, b: 2"  ->  {a: 1, b: 2}
     *
     * @method parseAttributeValue
     * @param {String} str
     * @return {Object}
     * @static
     */
    static parseAttributeValue(str) {
        return str
            .split(COMMA_SPLIT_REGEX)
            .map(x => x.split(':'))
            .reduce((accumulated, item) => {
                accumulated[item[0].trim()] = item[1].trim();
                return accumulated;
            }, {});
    }

    /**
     * @method replaceAt
     * @param {String} string
     * @param {Number} index
     * @param {String} replacement
     * @return {String}
     */
    static replaceAt(string, index, replacement) {
        return (
            string.substring(0, index) +
            replacement +
            string.substring(index + 1)
        );
    }

    static repeat(string, times) {
        let ret = '';
        for (let i = 0; i < times; i++) {
            ret += string;
        }

        return ret;
    }
}

export class HTMLElementUtils {
    /**
     * @param {HTMLElement} ele
     * @param {Number} length
     *
     * @returns {String}
     */
    static getElementShortHtml(ele, length = 50) {
        return `${ele.outerHTML.substr(0, length)}(...)`;
    }

    static findElementMatchingCondition(startElement, testFn) {
        if (startElement == null) {
            return null;
        }
        let current = startElement;
        do {
            if (testFn(current)) {
                return current;
            }
        } while ((current = current.parentElement));
        return null;
    }

    static findElementContainingAddress(
        address,
        startElement,
        includeValue = false
    ) {
        if (startElement == null) {
            return null;
        }

        let current = startElement;

        do {
            const dereferenced = ObjectUtils.dereference(address, current);
            if (dereferenced.success) {
                return includeValue
                    ? { element: current, value: dereferenced.result }
                    : current;
            }
        } while ((current = current.parentElement));

        return includeValue ? { element: null, value: void 0 } : null;
    }

    static findAncestorContainingAddress(
        address,
        startElement,
        includeValue = false
    ) {
        return this.findElementContainingAddress(
            address,
            startElement.parentElement,
            includeValue
        );
    }

    static offset(element, relativeToElement) {
        let offset = 0;

        let ref = element;
        do {
            offset += ref.offsetTop;
        } while (
            !ref.offsetParent.contains(relativeToElement) &&
            (ref = ref.offsetParent)
        );

        return offset;
    }

    static findFirstFocusable(origin) {
        return origin.querySelector('button, a, [role="button"]');
    }

    static isDescendantOf(descendant, ancestor) {
        let ele = descendant;
        while (ele) {
            if (ele === ancestor) {
                return true;
            }

            ele = descendant.parentElement;
        }

        return false;
    }

    static getNextTabbable(startEle) {
        if (!startEle) {
            throw new Error(
                `Invalid argument passed to findNextFocusable(): ${startEle}`
            );
        }

        let cur = startEle;
        let nextParent = startEle.parentElement;

        while (nextParent) {
            // TODO: could optimize here; if the element is display: block, etc. (what else?) we could skip

            const foundEle = this.getTabbableWithin(nextParent, cur);

            if (foundEle) {
                return foundEle;
            }

            cur = nextParent;
            nextParent = nextParent.parentElement;
        }

        return null;
    }

    static getTabbableWithin(ele, startFromEle = null) {
        let currentElement = startFromEle
            ? startFromEle.nextElementSibling
            : ele.firstElementChild;

        if (!currentElement) {
            return null;
        }

        do {
            // TODO: could optimize here; (and maybe "there"?) by slipping the focusable check and recursion under certain circumstances (display == block, what else?)
            if (isFocusable(currentElement)) {
                return currentElement;
            }

            // TODO: there = here

            const focusable = this.getTabbableWithin(currentElement);
            if (focusable) {
                return focusable;
            }
        } while ((currentElement = currentElement.nextElementSibling));

        return null;
    }

    /**
     *
     * @param {HTMLElement} startEle
     */
    static getNextDomElement(startEle) {
        if (startEle.nextElementSibling) {
            return startEle.nextElementSibling;
        }

        if (startEle.parentElement) {
            return this.getNextDomElement(startEle.parentElement);
        }

        return null;
    }

    static getSiblingFromOffset(startEle, offset) {
        const childrenArr = Array.from(startEle.parentElement.children);
        return childrenArr[childrenArr.indexOf(startEle) + offset] || null;
    }
}

export class ArrayUtils {
    static isArray(obj) {
        if (!obj) {
            return false;
        }

        return Object.getPrototypeOf(obj).constructor.name === 'Array';
    }

    static get(arr, n) {
        let index = n;

        if (n < 0) {
            // allow negative referencing, but only wrapping around once
            if (Math.abs(n) > arr.length) {
                return void 0;
            }

            index += arr.length;
        }

        return arr[index];
    }
}

export class ObjectUtils {
    static clone(obj, deep = true) {
        return deep ? this.cloneDeep(obj) : this.cloneShallow(obj);
    }

    static cloneShallow(obj) {
        return Object.assign({}, obj);
    }

    static cloneDeep(obj) {
        return fastClone(obj);
    }

    static dereference(pathOrPieces, obj) {
        const parsedAddress = this.parseObjectAddress(pathOrPieces);

        let fullyTraversed = true;

        let ref = obj;
        const dereferencedPieces = parsedAddress.pieces.map(piece => {
            if (!ref) {
                return void 0;
            }

            if (piece in ref === false) {
                fullyTraversed = false;
                return void 0;
            }

            ref = ref[piece];
            return ref;
        });

        // add a reference to the original object reference to the beginning of the pieces array
        dereferencedPieces.unshift(obj);

        const lastItem = dereferencedPieces[dereferencedPieces.length - 1];

        const result = dereferencedPieces[dereferencedPieces.length - 1];

        return {
            success: fullyTraversed,
            result: parsedAddress.invert ? !result : result,
            length: dereferencedPieces.length,
            pieces: parsedAddress.pieces,
            dereferencedPieces,

            get resultFn() {
                if (typeof this.result !== 'function') {
                    return false;
                }

                return ArrayUtils.get(this.dereferencedPieces, -1).bind(
                    ArrayUtils.get(this.dereferencedPieces, -2)
                );
            },
        };
    }

    static flatten(obj, address = '', retObj = {}) {
        for (const key in obj) {
            const subAddress = address ? `${address}.${key}` : key;
            const val = obj[key];

            retObj[subAddress] = val;

            if (typeof val === 'object') {
                this.flatten(val, subAddress, retObj);
            }
        }

        return retObj;
    }

    static copy(source, destination) {
        const destinationClone = this.cloneDeep(destination);

        if (destination == null) {
            return this.cloneDeep(source);
        }

        for (const key in source) {
            const val = source[key];

            if (
                typeof val === 'object' &&
                typeof destination[key] === 'object'
            ) {
                destinationClone[key] = this.copy(val, destination[key]);
            } else {
                destinationClone[key] = val;
            }
        }

        return destinationClone;
    }

    /**
     * Adds structure leading up to and ending at val
     *
     * Example:
     *     address = 'a.b';
     *     val = { c: { d: 1 } };
     *     prependStructureWithAddress(address, val) => { a: { b: { c: { d: 1 } } } }
     *
     * @param {string} address Object path defining structure to add
     * @param {mixed} val The leaf node to add structure to
     * @returns {object}
     */
    static prependStructureWithAddress(address, val) {
        const addressPieces = address.split('.');
        let ref = this.cloneDeep(val);

        let item;
        while ((item = addressPieces.pop())) {
            ref = {
                [item]: ref,
            };
        }

        return ref;
    }

    /**
     * Copies values from source to dest based on map.
     *
     * By default, accesses source with the key from map, and assigns to the
     * dest object with the key found in the value in the map given the key.
     * Inverse reverses this.
     *
     * If a dest object is provided, this function is destructive.
     *
     * @param {object} source
     * @param {object} dest
     * @param {object} map
     * @param {bool} inverse
     * @param {Array} ignoreKeys
     * @destructive
     * @returns {object}
     */
    static mapTransform(
        source,
        dest = {},
        map,
        inverse = false,
        ignoreKeys = []
    ) {
        for (const tmpKey in map) {
            let sourceKey;
            let destKey;

            if (!inverse) {
                sourceKey = tmpKey;
                destKey = map[tmpKey];
            } else {
                sourceKey = map[tmpKey];
                destKey = tmpKey;
            }

            if (sourceKey in source === false) {
                continue;
            }

            if (ignoreKeys.indexOf(sourceKey) !== -1) {
                continue;
            }

            dest[destKey] = source[sourceKey]; // eslint-disable-line no-param-reassign
        }

        return dest;
    }
    /**
     * Access nested value within obj at the address provided.
     * @param {string} address A period separated address of where within the object should be accessed
     * @param {object} obj
     * @returns {mixed}
     */
    static access(address, obj) {
        return _get(obj, address);
    }

    /**
     * @param {string} addressOrPieces
     */
    static parseObjectAddress(addressOrPieces) {
        if (Array.isArray(addressOrPieces)) {
            return this.parseObjectAddressArray(addressOrPieces);
        } else {
            return this.parseObjectAddressString(addressOrPieces);
        }
    }

    /**
     * @param {Array} pieces
     * @returns {object}
     */
    static parseObjectAddressArray(pieces) {
        return {
            address: pieces.join('.'),
            pieces,
            // TODO: somehow allow for inversion if this comes in as arr?
            invert: false,
        };
    }

    /**
     * @param {string} addressString
     * @returns {object}
     */
    static parseObjectAddressString(addressString) {
        let parsedAddressString = addressString;

        let invert = false;
        if (parsedAddressString.charAt(0) === '!') {
            // TODO: only allows for single !, expand in future?
            invert = true;
            parsedAddressString = parsedAddressString.substr(1);
        }

        return {
            address: addressString,
            parsedAddress: parsedAddressString,
            pieces: parsedAddressString.split('.'),
            invert,
        };
    }

    /**
     * Sets `value` onto `subject` at `address`
     *
     * Example:
     *     subject = { a: { b: { c: 1 } } }
     *     address = 'a.b'
     *     value = { c: 2 }
     *     setAtPath(subject, address, value) => { a: { b: { c: 2 } } }
     *
     * @param {object} subject
     * @param {string} address
     * @param {mixed} value
     */
    static setAtPath(subject, address, value) {
        _set(subject, address, value);

        return subject;
    }

    static getAllPropertyNames(obj) {
        const props = [];

        let ref = obj;
        do {
            const ownProps = Object.getOwnPropertyNames(ref);
            for (let i = 0; i < ownProps.length; i++) {
                if (props.indexOf(ownProps[i]) === -1) {
                    props.push(ownProps[i]);
                }
            }
        } while ((ref = Object.getPrototypeOf(ref)));

        return props;
    }
}

export class MathUtils {
    static mod(num, mod) {
        return ((num % mod) + mod) % mod;
    }
}

export default {
    StringUtils,
    HTMLElementUtils,
    ArrayUtils,
    ObjectUtils,
    MathUtils,
};
