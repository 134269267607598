import Repository from '../repository/repository';
import { ERROR_API } from '../../constants/errors';

/**
 * Wordpress Repository
 * @extends Repository
 */
export default class EventsRepository extends Repository {
    /**
     * Get content touts from wordpress api
     * @param {Object} params to pass to the content tout api
     * @return {Promise}
     */
    async getEvents(params) {
        const apiUrl =  '/svcs/event';
        const url = this.buildUrlWithQuery(apiUrl, params);

        const response = await fetch(
            url,
            {
                method: 'GET',
                headers: {
                    // Need this content-type here
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'same-origin',
            }
        );

        if (response.status === 200) {
            return await response.json();
        } else {
            throw new Error(`The request failed, response code was ${response.status}`);
        }
    }
}
