/* eslint-disable indent */
import { html } from '../../../core/scripts/util/util.js';
import { helpers } from '../../../core/scripts/util/helpers';

export default vm => {
    return html`
        <div data-test="AIF_Optin_${vm.countryCode}">
            ${vm.optinRules.map(
                (item, index) => html`
                    <label
                        for="$${vm.checkboxIds ? vm.checkboxIds[index] : vm.checkboxNames[index]}"
                        class="checkbox checkbox_round ${vm.errors && vm.errors[index] ? '' : 'u-vr6x'} ${item[
                            'display_copy'
                        ] === ''
                            ? 'u-isHidden'
                            : ''} ${item['display_checkbox'] ? '' : 'm-checkbox_hidden'}"
                    >
                        ${item['display_checkbox']
                            ? html`
                                  <input
                                      class="checkbox-input"
                                      id="$${vm.checkboxIds ? vm.checkboxIds[index] : vm.checkboxNames[index]}"
                                      name="$${vm.checkboxNames[index]}"
                                      type="checkbox"
                                      ${vm.overrideChecked
                                          ? `${vm.overrideChecked[index] === '1' ? 'checked' : ''}`
                                          : `${item['pre_check_checkbox'] ? 'checked' : ''}`}
                                      data-test="${vm.dataTestValues ? vm.dataTestValues[index] : ''}"
                                  />
                              `
                            : ''}
                        <span class="checkbox-btn"></span>
                        <span class="checkbox-label" ${item['display_checkbox'] ? '' : 'tabindex="0"'}>
                            ${item['display_copy']}
                        </span>
                    </label>
                    <div class="txt txt_error m-txt_alignRight u-isHidden" id="error_${vm.checkboxNames[index]}">
                        You must read and agree to the Notice at collection, Privacy Policy, and Terms and Conditions,
                        and to receiving email and other marketing communications from Petfinder and Purina and its
                        brands.
                    </div>
                    <div
                        class="txt txt_error m-txt_alignRight ${vm.errors && vm.errors[index] ? '' : 'u-isHidden'}"
                        id="server-error_${vm.checkboxNames[index]}"
                    >
                        ${vm.errors && vm.errors[index]
                            ? vm.errors[index]
                            : 'You must read and agree to the Notice at collection, Privacy Policy, and Terms and Conditions, and to receiving email and other marketing communications from Petfinder and Purina and its brands.'}
                    </div>
                    <div class="u-vr6x ${vm.errors && vm.errors[index] ? '' : 'u-isHidden'}"></div>
                `
            )}
        </div>
    `;
};
