import React, { Fragment } from 'react';
import defaultTemplates from 'tcomb-form-templates-bootstrap';
import t from 'tcomb-form';
import buildClassNames from 'classnames';
import _get from 'lodash/get';

import {
    getFieldUiSchemaFromLocals,
    buildFieldIdFromTcombLocals,
    buildDescribedByIdsFromTcombLocals,
    processLabel,
} from './util';

import Checkbox from '../generics/Checkbox';
import Radio from '../generics/Radio';
import Field from '../generics/Field';

/**
 * Composes a Field component with tcomb locals and props
 * @param {Object} locals
 * @param {Object} extendedProps
 * @param {Object} extendedAttributes
 * @returns {function}
 */
export function buildTcombField(
    locals,
    extendedProps = {},
    extendedAttributes = { ariaDescribedBy: [] }
) {
    const { labelTooltip } = getFieldUiSchemaFromLocals(locals);
    const ariaDescribedByIds = buildDescribedByIdsFromTcombLocals(
        locals
    ).concat(extendedAttributes.ariaDescribedBy);

    return (
        <Field
            hasError={locals.hasError}
            error={locals.error}
            type={locals.type}
            {...locals.attrs}
            id={buildFieldIdFromTcombLocals(locals)}
            help={locals.help}
            label={processLabel(locals)}
            labelTooltip={labelTooltip}
            required={!locals.typeInfo.isMaybe}
            aria-invalid={locals.hasError}
            aria-describedby={
                ariaDescribedByIds.length ? ariaDescribedByIds.join(' ') : null
            }
            value={String(locals.value)}
            options={locals.options}
            onChange={locals.onChange}
            {...extendedProps}
        />
    );
}

/**
 * Composes a checkbox component with tcomb locals and props
 * @param {Object} locals
 * @returns {function}
 */
export function buildTcombCheckbox(locals) {
    // Check if context contains required
    const contextRequired = _get(locals, 'context.required', null);
    let required = !locals.typeInfo.isMaybe;

    // If contextRequired is boolean value use this for required prop
    if (typeof contextRequired === 'boolean') {
        required = contextRequired;
    }

    return (
        <Checkbox
            {...locals.attrs}
            isChecked={locals.value}
            label={processLabel(locals)}
            id={buildFieldIdFromTcombLocals(locals)}
            required={required}
            onChange={locals.onChange}
        />
    );
}

/**
 * Clones tcomb struct component and overwrites render functions for custom templating
 * @param {Object} config
 * @returns {Object}
 */
export function buildBasicTcombCheckboxFieldset(
    config = {
        extensionClassNames: {},
    }
) {
    const rootClassNames = buildClassNames({
        ['fieldLayout']: true,
        ...config.extensionClassNames,
    });

    // Use base tcomb struct template but overwrite with custom markup
    return t.form.Form.templates.struct.clone({
        renderError: locals => {
            return (
                locals.error && (
                    <div className="fieldLayout-error">
                        <div className="txt txt_error">{locals.error}</div>
                    </div>
                )
            );
        },
        renderFieldset: (children, locals) => {
            // Need to add attrs object with name below for buildFieldIdFromTcombLocals because
            // the struct locals param does not get that here for some reason
            const id = buildFieldIdFromTcombLocals(
                Object.assign(
                    {},
                    { ...locals, attrs: { name: locals.path[0] } }
                )
            );

            // Filter children array into checkboxes and extras, checking for type div
            // seemed to be the best way to do this because the checkboxes will be components
            const checkboxes = React.Children.toArray(children).filter(
                child => child.type !== 'div'
            );
            const extras = React.Children.toArray(children).filter(
                child => child.type === 'div'
            );
            const help = extras[0];
            const error = extras[1];

            return (
                <fieldset className={rootClassNames}>
                    {locals.label && (
                        <legend className="fieldLayout-legend">
                            {`${locals.label}${
                                locals.context.jsonSchema &&
                                locals.typeInfo.isMaybe
                                    ? t.form.Form.i18n.optional
                                    : ''
                            }`}{' '}
                            {locals.help && (
                                <span className="u-isVisuallyHidden">
                                    {locals.help}
                                </span>
                            )}
                        </legend>
                    )}
                    <div id={id} className="fieldLayout-list" tabIndex="-1">
                        {help && (
                            <div className="fieldLayout-list-item m-fieldLayout-list-item_full">
                                {help}
                            </div>
                        )}
                        {React.Children.map(checkboxes, checkbox => {
                            // Clone checkbox components, apply extra props to ctx (context) so we
                            // can make a fieldset/list of checkboxes not all be required.
                            const child = React.cloneElement(checkbox, {
                                ctx: {
                                    ...checkbox.props.ctx,
                                    context: {
                                        ...checkbox.props.ctx.context,
                                        required: false,
                                    },
                                },
                            });

                            return (
                                <div
                                    className="fieldLayout-list-item"
                                    key={child.key}
                                >
                                    {child}
                                </div>
                            );
                        })}
                    </div>
                    {error}
                </fieldset>
            );
        },
    });
}

/**
 * Clones tcomb radio component and overwrites render functions for custom templating
 * @param {Object} config
 * @returns {Object}
 */
export function buildBasicTcombRadioFieldset(
    config = {
        extensionClassNames: {},
        help: {},
    }
) {
    const rootClassNames = buildClassNames({
        ['fieldLayout']: true,
        ...config.extensionClassNames,
    });

    // Use base tcomb radio template but overwrite with custom markup
    return t.form.Form.templates.radio.clone({
        renderFormGroup: children => {
            return (
                <fieldset className={rootClassNames}>
                    {React.Children.map(children, child => {
                        if (child === null) {
                            return;
                        }

                        return child;
                    })}
                </fieldset>
            );
        },
        renderLabel: locals => {
            return (
                locals.label && (
                    <legend className="fieldLayout-legend">
                        {locals.label}
                    </legend>
                )
            );
        },
        renderError: locals => {
            return (
                locals.error && (
                    <div className="fieldLayout-error">
                        <div className="txt txt_error">{locals.error}</div>
                    </div>
                )
            );
        },
        renderRadios: locals => {
            const id = buildFieldIdFromTcombLocals(locals);

            return (
                <div id={id} className="fieldLayout-list" tabIndex="-1">
                    {locals.options.map(option => {
                        let label = `${option.text}${
                            locals.context.jsonSchema && locals.typeInfo.isMaybe
                                ? t.form.Form.i18n.optional
                                : ''
                        }`;
                        let ariaLabel = label;

                        // Create label node with help value if option config.help is passed along...
                        if (config.help && config.help[option.value]) {
                            label = (
                                <Fragment>
                                    <span className="u-block u-vr1x">
                                        {label}
                                    </span>
                                    <span className="u-block u-italic">
                                        {config.help[option.value]}
                                    </span>
                                </Fragment>
                            );
                            ariaLabel = `${ariaLabel}, ${
                                config.help[option.value]
                            }`;
                        }

                        return (
                            <div
                                className="fieldLayout-list-item"
                                key={option.value}
                            >
                                <Radio
                                    {...locals.attrs}
                                    isChecked={option.value === locals.value}
                                    label={label}
                                    aria-label={ariaLabel}
                                    value={option.value}
                                    id={`${id}-${option.value}`}
                                    required={!locals.typeInfo.isMaybe}
                                    onChange={locals.onChange}
                                />
                            </div>
                        );
                    })}
                </div>
            );
        },
    });
}

/**
 * Overwrites default tcomb templates with custom templates to establish defaults
 * @type {Object}
 */
export default Object.assign({}, defaultTemplates, {
    checkbox: buildTcombCheckbox,
    radio: buildBasicTcombRadioFieldset(),
    select: buildTcombField,
    struct: buildBasicTcombCheckboxFieldset(),
    textbox: buildTcombField,
});
