/**
 * # Geography Related Constants
 */

const translations = require('../../../core/scripts/data/i18nJSON.json');

/**
 * @type {string}
 */
export const COUNTRY_CHOOSE_LABEL =
    translations['user.form.label.empty_country'];

/**
 * @type {string}
 */
export const STATE_CHOOSE_LABEL = 'Choose a state / province';

/**
 * @type {string}
 */
export const COUNTRY_NOT_LISTED =
    translations['user.form.country.not_listed.label'];

/**
 * @type {string}
 */
export const COUNTRY_SPACER_VALUE = 'spacer';

/**
 * @type {string}
 */
export const COUNTRY_NOT_LISTED_VALUE = 'not-listed';

/**
 * @type {Array}
 */
export const INVALID_COUNTRY_VALUES = [
    COUNTRY_SPACER_VALUE,
    COUNTRY_NOT_LISTED_VALUE,
];

/**
 * @type {string}
 */
// eslint-disable-next-line
export const COUNTRY_NOT_LISTED_HEADER =
    'Petfinder Is Available Only In Specific Regions';

/**
 * @type {string}
 */
// eslint-disable-next-line
export const COUNTRY_NOT_LISTED_BODY =
    'Petfinder currently includes pets and adoption organizations from the regions listed above. Please check back in the future for any potential expansion.';

/**
 * @type {string}
 */
export const COUNTRY_NOT_LISTED_ARIA_ALERT = `${COUNTRY_NOT_LISTED_HEADER}. ${COUNTRY_NOT_LISTED_BODY}`;

/**
 * Setup label for first option of state select
 * @type {Object}
 */
export const STATE_TYPE_ENUM = {
    DEFAULT: 1,
    STATE: 2,
    PROVINCE: 3,
    values: {
        1: {
            stateLabel: translations['form.label.address.state'],
            stateOptionText: translations['form.placeholder.address.state'],
            postalCodeLabel: translations['form.label.address.postal_code'],
        },
        2: {
            stateLabel: translations['form.label.address.state.us'],
            stateOptionText: translations['form.placeholder.address.state.us'],
            postalCodeLabel: translations['form.label.address.postal_code.us'],
        },
        3: {
            stateLabel: translations['form.label.address.state.ca'],
            stateOptionText: translations['form.placeholder.address.state.ca'],
            postalCodeLabel: translations['form.label.address.postal_code'],
        },
    },
};

/**
 * @type {Array}
 */
export const USE_LABEL_STATE = ['US'];

/**
 * @type {Array}
 */
export const USE_LABEL_PROVINCE = ['CA'];

/**
 * @type {Array}
 */
export const GEOGRAPHY_COUNTRY_REQUIRES_POSTAL_CODE = [
    'GB',
    'JE',
    'GG',
    'IM',
    'US',
    'us',
    'CA',
    'ca',
    'DE',
    'JP',
    'FR',
    'AU',
    'IT',
    'CH',
    'AT',
    'ES',
    'NL',
    'BE',
    'DK',
    'SE',
    'NO',
    'BR',
    'PT',
    'FI',
    'AX',
    'KR',
    'CN',
    'TW',
    'SG',
    'DZ',
    'AD',
    'AR',
    'AM',
    'AZ',
    'BH',
    'BD',
    'BB',
    'BY',
    'BM',
    'BA',
    'IO',
    'BN',
    'BG',
    'KH',
    'CV',
    'CL',
    'CR',
    'HR',
    'CY',
    'CZ',
    'DO',
    'EC',
    'EG',
    'EE',
    'FO',
    'GE',
    'GR',
    'GL',
    'GT',
    'HT',
    'HN',
    'HU',
    'IS',
    'IN',
    'ID',
    'IL',
    'JO',
    'KZ',
    'KE',
    'KW',
    'LA',
    'LV',
    'LB',
    'LI',
    'LT',
    'LU',
    'MK',
    'MY',
    'MV',
    'MT',
    'MU',
    'MX',
    'MD',
    'MC',
    'MA',
    'NP',
    'NZ',
    'NI',
    'NG',
    'OM',
    'PK',
    'PY',
    'PH',
    'PL',
    'PR',
    'RO',
    'RU',
    'SM',
    'SA',
    'SN',
    'SK',
    'SI',
    'ZA',
    'LK',
    'TJ',
    'TH',
    'TN',
    'TR',
    'TM',
    'UA',
    'UY',
    'UZ',
    'VA',
    'VE',
    'ZM',
    'AS',
    'CC',
    'CK',
    'RS',
    'ME',
    'CS',
    'YU',
    'CX',
    'ET',
    'FK',
    'NF',
    'FM',
    'GF',
    'GN',
    'GP',
    'GS',
    'GU',
    'GW',
    'HM',
    'IQ',
    'KG',
    'LR',
    'LS',
    'MG',
    'MH',
    'MN',
    'MP',
    'MQ',
    'NC',
    'NE',
    'VI',
    'PF',
    'PG',
    'PM',
    'PN',
    'PW',
    'RE',
    'SH',
    'SJ',
    'SO',
    'SZ',
    'TC',
    'WF',
    'XK',
    'YT',
];
