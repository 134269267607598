import React from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';

import { FULL_COLOR_LIST_HEX } from '../../../constants/colors';

/**
 * Renders a loader svg icon with the passed props
 * @returns {string}
 */
const Loader = ({ color, extensionClassNames }) => {
    const classNames = buildClassNames({
        ['loader']: true,
        ['u-isActive']: true,
        ...extensionClassNames,
    });
    return (
        <svg
            className={classNames}
            role="img"
            focusable="false"
            viewBox="25 25 50 50"
        >
            <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="5"
                stroke={FULL_COLOR_LIST_HEX[color]}
                strokeDasharray="39.25, 117.75"
            />
        </svg>
    );
};

Loader.propTypes = {
    color: PropTypes.oneOf(Object.keys(FULL_COLOR_LIST_HEX)),
    extensionClassNames: PropTypes.object,
};

Loader.defaultProps = {
    color: 'white',
    extensionClassNames: {},
};

export default Loader;
