import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _noop from 'lodash/noop';
import _get from 'lodash/get';
import _uniquedId from 'lodash/uniqueId';

/**
 * Checkbox component for generic checkbox input
 * @class Checkbox
 * @extends React.Component
 */
class Checkbox extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        id: PropTypes.string.isRequired,
        isChecked: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
            .isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        required: PropTypes.bool,
        onChange: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        isChecked: false,
        value: '',
        required: null,
        onChange: _noop,
    };

    get ariaLabel() {
        const { label, name } = this.props;
        const ariaLabel = _get(this.props, 'aria-label', false);

        if (ariaLabel && typeof ariaLabel === 'string') {
            return ariaLabel;
        }

        if (typeof label === 'object') {
            console.warn(
                `You tried to supply a object as the label for the "${name}" named checkbox component. If you want an aria-label please add to the attrs field option.`
            );
            return null;
        }

        return label;
    }

    get classNames() {
        return buildClassNames({
            ['checkbox']: true,
        });
    }

    /**
     * @param {Event} ev
     * @private
     */
    handleChange = ev => {
        const value = ev.target.checked;
        this.props.onChange(value, this.props.name);
    };

    /**
     * @returns {string}
     */
    render() {
        const { id, isChecked, label, name, required, value } = this.props;

        return (
            <label className={this.classNames} htmlFor={id} data-test={id}>
                <input
                    id={id}
                    className="checkbox-input"
                    type="checkbox"
                    name={name}
                    checked={isChecked}
                    value={value}
                    onChange={this.handleChange}
                    required={required}
                    aria-label={this.ariaLabel}
                />
                <span className="checkbox-btn" />
                <span className="checkbox-label">{label}</span>
            </label>
        );
    }
}

export default Checkbox;
