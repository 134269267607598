import EventActionMixinBase from './EventActionMixinBase';
import ActionParser from '../ActionParser';

export default class SubmitMixin extends EventActionMixinBase {
    static _attributeName = 'mix-submit';

    onSubmitEvent(e) {
        ActionParser.executeActions(this._actions, { $mixin: this, $element: this._ele, $event: e }, e);
    }
}
