import React from 'react';
import PropTypes from 'prop-types';
import siteStateController from '../../../state-controllers/siteStateController';

// TODO: Documentation
class StateController extends React.Component {
    _unsubscribeFn = null;

    state = this._composeState();

    static propTypes = {
        stateLocation: PropTypes.string,
        onStateUpdated: PropTypes.func,
    };

    _composeState() {
        return {
            value: siteStateController.stateAt(this.props.stateLocation),
        };
    }

    componentDidMount() {
        this._unsubscribeFn = siteStateController.subscribe(
            this._onStateControllerStateChange,
            this.props.stateLocation
        );
    }

    componentWillUnmount() {
        this._unsubscribeFn();
    }

    _onStateControllerStateChange = () => {
        this.setState(this._composeState());
    };

    render() {
        return this.props.onStateUpdated(this.state.value);
    }
}

export default StateController;
