import DotComTrackEvent from './ensighten-dotcom';
import { trackEvent } from '../../../analytics';

class TrackEvent extends DotComTrackEvent {
    constructor() {
        super();
        this.setGlobalDimensions();
        this.prevVals = {
            searchFilters: {},
        };
    }

    async setGlobalDimensions() {
        // TODO: reenable release tracking
        // try {
        //     await pollUntilConditionMet(() => {
        //         return typeof window.ga === 'function' &&
        //             typeof window.ga.getByName === 'function' &&
        //             window.ga.getByName('pri');
        //     }, 1000, 20);
        //     window.ga('pri.set', 'dimension67', '2018.7');
        // } catch (ex) {
        //     debugger;
        //     // NOTE: this hasn't yet been observed, but this was included just in case
        //     console.warn('window.ga(\'pri.set\', \'dimension67\', \'2018.7\') errored', ex);
        // }
    }

    event003() {
        trackEvent({
            category: 'dashboard',
            action: 'button click',
            label: 'all pet listings',
        });
    }

    event004() {
        trackEvent({
            category: 'dashboard',
            action: 'button click',
            label: 'add a pet listing',
        });
    }

    event007(detail) {
        const input = document.querySelector(
            detail.trigger.getAttribute('input-selector')
        );
        trackEvent({
            category: 'pet listings - main',
            action: 'search',
            label: input.value,
        });
    }

    event008() {
        trackEvent({
            category: 'pet listings - main',
            action: 'generate link - single',
        });
    }

    event009() {
        trackEvent({
            category: 'pet listings - main',
            action: 'filter - open options',
        });
    }

    event010(detail) {
        const { trigger } = detail;
        const input = document.querySelector(
            detail.trigger.getAttribute('input-selector')
        );
        if (input.checked) {
            trackEvent({
                category: 'pet listings - main',
                action: 'select all listings',
            });
            return;
        }
        trackEvent({
            category: 'pet listings - main',
            action: 'deselect all listings',
        });
    }

    event011(detail) {
        const { srcEvent } = detail;
        const val = srcEvent.target.value;
        const optionLabel = srcEvent.target.querySelector(
            `option[value="${val}"]`
        ).innerText;
        trackEvent({
            category: 'pet listings - main',
            action: 'number of listings per screen',
            label: optionLabel,
        });
    }

    event012(detail) {
        const { srcEvent } = detail;
        const { target } = srcEvent;
        const optDoms = Array.from(target.querySelectorAll('option'));
        const prevSelectedOption = optDoms.filter(dom => dom.defaultSelected);

        let prevOptionLabel;
        if (prevSelectedOption.length) {
            prevOptionLabel = prevSelectedOption[0].innerText;
        } else {
            prevOptionLabel = optDoms[0].innerText;
        }

        const currSelectedOption = optDoms.filter(dom => dom.selected)[0];
        const currOptionLabel = currSelectedOption.innerText;
        trackEvent({
            category: 'pet listings - main',
            action: 'change sort by',
            label: `from:${prevOptionLabel} | to:${currOptionLabel}`,
        });
    }

    event013(detail) {
        const { srcEvent } = detail;
        const { target } = srcEvent;
        if (target.tagName === 'BUTTON' || target.tagName === 'A') {
            trackEvent({
                category: 'pet listings - main',
                action: 'view more listings',
                label: target.innerText.toLowerCase().trim(),
            });
        }
    }

    event014() {
        trackEvent({
            category: 'pet listings - main',
            action: 'edit pet detail',
            label: 'edit initiated',
        });
    }

    event016() {
        trackEvent({
            category: 'pet listings - main',
            action: 'launch share overlay',
            label: 'single pet',
        });
    }

    event017() {
        trackEvent({
            category: 'pet listings - main',
            action: 'create template',
        });
    }

    event018(detail) {
        const { trigger } = detail;
        const input = trigger.querySelector(
            trigger.getAttribute('input-selector')
        );
        const defaultOpt = trigger.getAttribute('data-pet-adoption-status');
        const prevVal = this.prevVals[detail.eventId] || defaultOpt;
        const currVal = input.options[input.selectedIndex].label.toLowerCase();
        this.prevVals[detail.eventId] = currVal;
        trackEvent({
            category: 'pet listings - main',
            action: 'change status - single',
            label: `from:${prevVal} | to:${currVal}`,
        });
    }

    event021(detail) {
        const { trigger } = detail;
        const input = trigger.querySelector(
            trigger.getAttribute('input-selector')
        );
        const val = input.options[input.selectedIndex].label.toLowerCase();
        trackEvent({
            category: 'pet listings - main',
            action: 'change status - bulk',
            label: `to: ${val}`,
        });
    }

    event023(detail) {
        const { trigger } = detail;
        const input = trigger.querySelector(
            trigger.getAttribute('input-selector')
        );
        const defaultOpt = trigger.getAttribute('data-pet-adoption-status');
        const prevVal = this.prevVals[detail.eventId] || defaultOpt;
        const currVal = input.options[input.selectedIndex].label.toLowerCase();
        this.prevVals[detail.eventId] = currVal;
        trackEvent({
            category: 'pet profile',
            action: 'change status - single',
            label: `from:${prevVal} | to:${currVal}`,
        });
    }

    event024() {
        trackEvent({
            category: 'pet profile',
            action: 'edit pet detail',
            label: 'edit initiated',
        });
    }

    event026() {
        trackEvent({
            category: 'pet profile',
            action: 'create template',
        });
    }

    event028(detail) {
        const { trigger } = detail;
        trackEvent({
            category: 'add pet listing',
            action: 'step 1: add from template',
            label: trigger.dataset.animalType.toLowerCase(),
            dimensions: {
                36: 'step 1: add from template',
            },
        });
    }

    event029(detail) {
        const { trigger } = detail;
        trackEvent({
            category: 'add pet listing',
            action: 'step 1: new listing',
            label: trigger.dataset.animalType.toLowerCase(),
            dimensions: {
                36: 'step 1: new listing',
            },
        });
    }

    event030(detail) {
        const { trigger } = detail;
        trackEvent({
            category: 'add pet listing',
            action: `step ${trigger.dataset.step} (mobile): ${trigger.dataset.stepName}`,
            dimensions: {
                36: `step ${trigger.dataset.step} (mobile): ${trigger.dataset.stepName}`,
            },
        });
    }

    event031() {
        trackEvent({
            category: 'add pet listing',
            action: 'step 2 (desktop)',
            dimensions: {
                36: 'step 2 (desktop)',
            },
        });
    }

    event032() {
        trackEvent({
            category: 'add pet listing',
            action: 'image uploaded',
        });
    }

    event033() {
        trackEvent({
            category: 'add pet listing',
            action: 'video embedded',
        });
    }

    event034(detail) {
        trackEvent({
            category: 'add pet listing',
            action: 'error: image upload',
            label: detail.description,
            dimensions: {
                32: `add pet listing:image upload:${detail.description}`,
            },
            metrics: {
                2: 1,
            },
        });
    }

    event035(detail) {
        trackEvent({
            category: 'add pet listing',
            action: 'error: video embedding',
            label: detail.description,
            dimensions: {
                32: `add pet listing:image upload:${detail.description}`,
            },
            metrics: {
                2: 1,
            },
        });
    }

    event037() {
        trackEvent({
            category: 'add pet listing',
            action: 'finish and publish',
            dimensions: {
                36: 'finish and publish',
            },
        });
    }

    event040() {
        trackEvent({
            category: 'add pet listing',
            action: 'add listing completed',
            label: 'click: add another listing',
        });
    }

    event041() {
        trackEvent({
            category: 'add pet listing',
            action: 'add listing completed',
            label: 'click: save as template',
        });
    }

    event042() {
        trackEvent({
            category: 'add pet listing',
            action: 'add listing completed',
            label: 'click: return to pet listings',
        });
    }

    event043() {
        trackEvent({
            category: 'add pet listing',
            action: 'add listing completed',
            label: 'click: return to dashboard',
        });
    }

    event045() {
        trackEvent({
            category: 'templates - main',
            action: 'filter - open options',
        });
    }

    event046(detail) {
        const { trigger } = detail;
        const input = trigger.querySelector(
            trigger.getAttribute('input-selector')
        );
        const defaultOpt = Array.from(input.options)
            .filter(opt => opt.defaultSelected)[0]
            .label.toLowerCase();
        const prevVal = this.prevVals[detail.eventId] || defaultOpt;
        const currVal = input.options[input.selectedIndex].label.toLowerCase();
        this.prevVals[detail.eventId] = currVal;
        trackEvent({
            category: 'templates - main',
            action: 'change sort by',
            label: `from:${prevVal} | to:${currVal}`,
        });
    }

    event047() {
        trackEvent({
            category: 'templates - main',
            action: 'expand template',
        });
    }

    event048(ev) {
        const { trigger } = ev;
        trackEvent({
            category: 'templates - main',
            action: 'context menu',
            label: `${trigger.getAttribute('label')}`,
        });
    }

    event049(detail) {
        const { trigger } = detail;
        trackEvent({
            category: 'templates - main',
            action: 'use template',
            label: `${trigger.getAttribute(
                'template-id'
            )}:${trigger.getAttribute('template-name')}`,
        });
    }

    event050() {
        trackEvent({
            category: 'templates - main',
            action: 'create new template',
        });
    }

    event051(detail) {
        const target = detail.srcEvent.target;
        if (
            target.tagName === 'BUTTON' ||
            target.tagName === 'A' ||
            target.tagName === 'SPAN'
        ) {
            let eventLabel = target.innerText.toLowerCase();

            // The pagination ellipsis ... is inside a span, we need to step out to get a label.
            if (target.tagName === 'SPAN') {
                eventLabel = target.parentElement.innerText.toLowerCase();
            }

            trackEvent({
                category: 'templates - main',
                action: 'view more listings',
                label: eventLabel,
            });
        }
    }

    event059(detail) {
        const exportType = detail.exportType;
        trackEvent({
            category: 'statistics',
            action: 'daily report emailed',
            label: `attachment type: ${exportType}`,
        });
    }

    event060(detail) {
        const exportType = detail.trigger.getAttribute('export-type');
        trackEvent({
            category: 'statistics',
            action: 'daily report exported',
            label: `export type: ${exportType}`,
        });
    }

    event061(detail) {
        trackEvent({
            category: 'statistics',
            action: 'navigate through profiles',
            label: `${detail.direction}:${detail.count}`,
        });
    }

    event063(detail) {
        const { srcEvent } = detail;
        const { target } = srcEvent;
        trackEvent({
            category: 'events - main',
            action: 'filter',
            label: `${target.options[
                target.selectedIndex
            ].label.toLowerCase()}`,
        });
    }

    event064() {
        trackEvent({
            category: 'events - main',
            action: 'expand event',
        });
    }

    event065() {
        trackEvent({
            category: 'events - main',
            action: 'edit event',
        });
    }

    event066(detail) {
        const { trigger } = detail;
        trackEvent({
            category: 'events - main',
            action: 'context menu',
            label: `${trigger.getAttribute('label')}`,
        });
    }

    event067() {
        trackEvent({
            category: 'events - main',
            action: 'create new event',
        });
    }

    event072() {
        trackEvent({
            category: 'settings: organization',
            action: 'view home page',
        });
    }

    event076() {
        trackEvent({
            category: 'settings: contacts and access',
            action: 'expand contact',
        });
    }

    event077() {
        trackEvent({
            category: 'settings: contacts and access',
            action: 'edit contact',
        });
    }

    event078(detail) {
        const { trigger } = detail;
        trackEvent({
            category: 'settings: contacts and access',
            action: 'context menu',
            label: `${trigger.getAttribute('label')}`,
        });
    }

    event082() {
        trackEvent({
            category: 'settings: contacts and access',
            action: 'create new contact',
        });
    }

    event088() {
        trackEvent({
            category: 'settings: locations list',
            action: 'expand location',
        });
    }

    event089() {
        trackEvent({
            category: 'settings: locations list',
            action: 'edit location',
        });
    }

    event090(detail) {
        const { trigger } = detail;
        const locationId = trigger.dataset.locationId;
        const locationName = trigger.dataset.locationName;
        trackEvent({
            category: 'settings: locations list',
            action: 'location deleted',
            label: `${locationId}:${locationName}`,
        });
    }

    event093() {
        trackEvent({
            category: 'settings: locations list',
            action: 'add a new location',
        });
    }

    event098() {
        trackEvent({
            category: 'settings: account',
            action: 'view home page',
        });
    }

    event108() {
        trackEvent({
            category: 'my account',
            action: 'log out',
        });
    }

    event121() {
        trackEvent({
            category: 'global',
            action: 'session timeout warning',
        });
    }

    event123(detail) {
        let title = detail.trigger.dataset.title;
        if (detail.trigger.dataset.title === 'pro.resource.drawer.view_all') {
            title = 'view all';
        }
        trackEvent({
            category: 'global',
            action: 'resource clicked',
            label: title,
        });
    }

    event124(detail) {
        let title =
            detail.trigger.dataset.title ||
            detail.srcEvent.target.dataset.label;
        if (title === 'pro.help.drawer.view_all') {
            title = 'view all';
        }
        trackEvent({
            category: 'global',
            action: 'help topic clicked',
            label: title,
        });
    }

    event125(detail) {
        const eventLabel =
            detail.trigger.dataset.label ||
            detail.srcEvent.target.dataset.label;
        trackEvent({
            category: 'global',
            action: 'global header click',
            label: eventLabel,
        });
    }

    event129() {
        trackEvent({
            category: 'add pet listing',
            action: 'video uploaded',
        });
    }

    event130(detail) {
        trackEvent({
            category: 'add pet listing',
            action: 'error: video upload',
            label: detail.description,
            dimensions: {
                32: `add pet listing:video upload:${detail.description}`,
            },
            metrics: {
                2: 1,
            },
        });
    }

    event131(detail) {
        const exportType = detail.exportType;
        trackEvent({
            category: 'statistics',
            action: 'animal report emailed',
            label: `attachment type: ${exportType}`,
        });
    }

    event132(detail) {
        const exportType = detail.trigger.getAttribute('export-type');
        trackEvent({
            category: 'statistics',
            action: 'animal report exported',
            label: `export type: ${exportType}`,
        });
    }

    event146() {
        trackEvent({
            category: 'pet listings - main',
            action: 'click on pet listing',
        });
    }

    event147() {
        trackEvent({
            category: 'global nav',
            action: 'pet listings',
            label: 'click view all pets',
        });
    }

    event148() {
        trackEvent({
            category: 'global nav',
            action: 'pet listings',
            label: 'click view all templates',
        });
    }

    event149() {
        trackEvent({
            category: 'global nav',
            action: 'pet listings',
            label: 'click add a pet',
        });
    }

    event150() {
        trackEvent({
            category: 'global nav',
            action: 'statistics',
        });
    }

    event151() {
        trackEvent({
            category: 'global nav',
            action: 'events',
            label: 'click all events',
        });
    }

    event152() {
        trackEvent({
            category: 'global nav',
            action: 'events',
            label: 'click add an event',
        });
    }

    event153() {
        trackEvent({
            category: 'global nav',
            action: 'settings',
            label: 'click on profile',
        });
    }

    event154() {
        trackEvent({
            category: 'global nav',
            action: 'settings',
            label: 'click on home page editor',
        });
    }

    event155() {
        trackEvent({
            category: 'global nav',
            action: 'settings',
            label: 'click on contacts & access',
        });
    }

    event156() {
        trackEvent({
            category: 'global nav',
            action: 'settings',
            label: 'click on locations',
        });
    }

    event157() {
        trackEvent({
            category: 'global nav',
            action: 'settings',
            label: 'click on account',
        });
    }

    event158() {
        trackEvent({
            category: 'my account',
            action: 'settings',
        });
    }

    event159() {
        trackEvent({
            category: 'pet listings - main',
            action: 'launch share overlay',
            label: 'share to Facebook',
        });
    }

    event160() {
        trackEvent({
            category: 'pet listings - main',
            action: 'launch share overlay',
            label: 'share to Twitter',
        });
    }

    event161(detail) {
        const orgName = detail.srcEvent.target.dataset.orgName;
        trackEvent({
            category: 'my account',
            action: 'click alternate org',
            label: orgName,
        });
    }

    event162() {
        trackEvent({
            category: 'settings: contacts and access',
            action: 'contact invited',
        });
    }
}

export const analyticsTrackEvent = new TrackEvent();

export default analyticsTrackEvent;
