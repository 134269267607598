import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { analyticsConsumer014 } from '../../../../analytics/dotcom';

class SocialGenericErrorLayout extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        onBackToLogin: PropTypes.func,
    };

    /**
     * @param {Event} ev
     */
    handleLoginClick = ev => {
        ev.preventDefault();
        this.props.onBackToLogin(analyticsConsumer014);
    };

    render() {
        return (
            <div className="loginModal">
                <div className="loginModal-section">
                    <h2 className="loginModal-section-hdg u-vr6x" tabIndex="-1">
                        {'Something went wrong'}
                    </h2>
                    <div className="loginModal-section">
                        <p className="txt m-txt_lg">
                            If the problem persists, you can try another login
                            option or contact{' '}
                            <a
                                className="txt txt_link m-txt_lg m-txt_underline"
                                href="/contact/"
                            >
                                Petfinder support
                            </a>
                            .
                        </p>
                    </div>
                </div>
                <div className="loginModal-section loginModal-section_footer">
                    <button
                        type="button"
                        className="btn m-btn_full"
                        onClick={this.handleLoginClick}
                    >
                        {'Back to login'}
                    </button>
                </div>
            </div>
        );
    }
}

export default SocialGenericErrorLayout;
