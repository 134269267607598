import { PFElement } from '../../../../core/scripts/elements/pf-element/element';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-footer';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    BTN: `[${ELEMENT_TAG_NAME}-btn]`,
    MENU: `[${ELEMENT_TAG_NAME}-menu]`,
};

/**
 * Stores reference string classes for state changes
 * @const
 * @type {Object}
 */
const CLASSES = {
    BTN_OPEN: 'footer-inner-nav-item-btn_isOpen',
    MENU_OPEN: 'footer-inner-nav-item-expanded_isOpen',
};

/**
 * @class PFDCFooterElement
 * @extends PFElement
 */
export class PFDCFooterElement extends PFElement {
    onInit() {
        /**
         * Reference to the button that expands menu
         * @type {Array}
         */
        this.btns = Array.from(this.querySelectorAll(ELEMENT_SELECTORS.BTN));

        /**
         * Reference to the expandable menu list
         * @type {Array}
         */
        this.menus = Array.from(this.querySelectorAll(ELEMENT_SELECTORS.MENU));

        /**
         * Reference to all tabbable elements within each menu
         * @type {Array}
         */
        this.tabbable = [];
        for (let i = 0; i < this.menus.length; i++) {
            this.tabbable.push(Array.from(this.menus[i].querySelectorAll('a')));
        }

        this.setupFocusHandlers();
        this.addEventListener('click', this.onClicked);
    }

    /**
     * Sets up some focus in and out handlers on this component
     */
    setupFocusHandlers() {
        this.addEventListener('focusin', ev => {
            for (let i = 0; i < this.tabbable.length; i++) {
                if (this.tabbable[i].includes(ev.target)) {
                    this.expandMenu(this.btns[i], this.menus[i]);
                }
            }
        });
    }

    /**
     * Expands menu by applying active class to <ul>
     * @param {HTMLElement} button element
     * @param {HTMLElement} menu element
     */
    expandMenu(button, menu) {
        for (let i = 0; i < this.menus.length; i++) {
            this.menus[i].classList.remove(CLASSES.MENU_OPEN);
            this.btns[i].classList.remove(CLASSES.BTN_OPEN);
        }
        menu.classList.add(CLASSES.MENU_OPEN);
        button.classList.add(CLASSES.BTN_OPEN);
    }

    /**
     * Toggles menu
     * @param {HTMLElement} button element
     * @param {HTMLElement} menu element
     */
    toggleMenu(button, menu) {
        if (menu.classList.contains(CLASSES.MENU_OPEN)) {
            menu.classList.remove(CLASSES.MENU_OPEN);
            button.classList.remove(CLASSES.BTN_OPEN);
        } else {
            menu.classList.add(CLASSES.MENU_OPEN);
            button.classList.add(CLASSES.BTN_OPEN);
            this.focusManager.focusFirstFocusable(menu);
        }
    }

    /**
     * Handles click events
     * @param  {Object} ev event object
     */
    onClicked(ev) {
        switch (true) {
            case this.btns.includes(ev.target):
                this.toggleMenu(ev.target, ev.target.nextElementSibling);
                break;
            default:
                break;
        }
    }
}

export default PFDCFooterElement;
