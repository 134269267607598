/**
 * @type {Object}
 */
export const STATE = {
    OFF: 'off',
    ON: 'on',
};

/**
 * @type {Object}
 */
export const EVENT = {
    TOGGLE_POPUP: 'togglePopup',
};

/**
 * @type {Object}
 */
export const statechart = {
    initial: STATE.OFF,
    states: {
        [STATE.ON]: {
            on: {
                [EVENT.TOGGLE_POPUP]: STATE.OFF,
            },
        },
        [STATE.OFF]: {
            on: {
                [EVENT.TOGGLE_POPUP]: STATE.ON,
            },
        },
    },
};
