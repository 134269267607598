import { ADS } from '../../constants/ads';

// Each ad slot should be defined in the ads object.  The key must equal the ID of the
// custom pf-ad HTMLElement and must be unique so the element knows which ad unit to grab.
// Defining ad specific campaign in an ad overrides the default campaign, e.g.:
//     `Admin_Dashboard: {
//         campaign: 'Petfinder_Pro_Test',
//         ...
//     }`
// In the responsive sizes array, min width is the minimum width of the browser where the named
// size of the ad should show.  For example:
//     `{
//         min_width: 728,
//         size: AD_SIZES.LEADERBOARD
//     `}
// means that at browser width <= 728 the leaderboard (728x90) ad will show. If not included, the
// assumed width is 0.  Note that the order of the responsive_sizes array is important.
// See: https://support.google.com/dfp_premium/answer/3423562?hl=en
//
// Refreshes at breakpoints indicates what defined breakpoints the ad where the ad should refresh.
// For example:
//     `refreshesAtBreakpoints: [sm_BREAKPOINT, md_BREAKPOINT],`
// indicates that when changing to the small or medium breakpoint, the ad should change.  In this
// case, the same ad displays at md and lg, so no refresh is necesarry in the transition to
// lg.

import { BREAKPOINT_WIDTHS } from '../../constants/breakpoints';

export const AD_CONFIG = {
    Home1: {
        id: 'Homepage',
        size: ADS.MOBILE_LEADERBOARD,
        responsiveSizes: {
            sm: ADS.LARGE_MOBILE_BANNER,
            md: ADS.LARGE_MOBILE_BANNER,
            mdlg: ADS.LEADERBOARD,
            lg: ADS.LEADERBOARD,
            xl: ADS.LEADERBOARD,
        },
        positionTargets: {
            sm: 'homepage_top_320x100',
            md: 'homepage_top_320x100',
            mdlg: 'homepage_top_728x90',
            lg: 'homepage_top_728x90',
            xl: 'homepage_top_728x90',
        },
    },
    Home2: {
        id: 'Homepage',
        size: ADS.MEDIUM_RECTANGLE,
        responsiveSizes: {
            sm: ADS.MEDIUM_RECTANGLE,
            md: ADS.MEDIUM_RECTANGLE,
            mdlg: ADS.MEDIUM_RECTANGLE,
            lg: ADS.MEDIUM_RECTANGLE,
            xl: ADS.MEDIUM_RECTANGLE,
        },
        positionTargets: {
            sm: 'homepage_left_300x250',
            md: 'homepage_left_300x250',
            mdlg: 'homepage_left_300x250',
            lg: 'homepage_left_300x250',
            xl: 'homepage_left_300x250',
        },
    },
    Home3: {
        id: 'Homepage',
        size: ADS.MEDIUM_RECTANGLE,
        responsiveSizes: {
            sm: ADS.MEDIUM_RECTANGLE,
            md: ADS.MEDIUM_RECTANGLE,
            mdlg: ADS.MEDIUM_RECTANGLE,
            lg: ADS.MEDIUM_RECTANGLE,
            xl: ADS.MEDIUM_RECTANGLE,
        },
        positionTargets: {
            sm: 'homepage_right_300x250',
            md: 'homepage_right_300x250',
            mdlg: 'homepage_right_300x250',
            lg: 'homepage_right_300x250',
            xl: 'homepage_right_300x250',
        },
    },
    Home4: {
        id: 'Homepage',
        size: ADS.MEDIUM_RECTANGLE,
        responsiveSizes: {
            sm: ADS.LARGE_MOBILE_BANNER,
            md: ADS.LARGE_MOBILE_BANNER,
            mdlg: ADS.LEADERBOARD,
            lg: ADS.LEADERBOARD,
            xl: ADS.LEADERBOARD,
        },
        positionTargets: {
            sm: 'homepage_bottom_320x100',
            md: 'homepage_bottom_320x100',
            mdlg: 'homepage_bottom_728x90',
            lg: 'homepage_bottom_728x90',
            xl: 'homepage_bottom_728x90',
        },
    },
    SearchResults1: {
        id: 'Pet_Search',
        size: ADS.LARGE_MOBILE_BANNER,
        responsiveSizes: {
            sm: ADS.LARGE_MOBILE_BANNER,
            md: ADS.LARGE_MOBILE_BANNER,
            mdlg: ADS.LARGE_MOBILE_BANNER,
            lg: ADS.LARGE_MOBILE_BANNER,
            xl: ADS.LEADERBOARD,
        },
        positionTargets: {
            sm: 'petsearch_top_320x100',
            md: 'petsearch_top_320x100',
            mdlg: 'petsearch_top_320x100',
            lg: 'petsearch_top_320x100',
            xl: 'petsearch_top_728x90',
        },
    },
    SearchResults2: {
        id: 'Pet_Search',
        size: ADS.MEDIUM_RECTANGLE,
        responsiveSizes: {
            sm: ADS.MEDIUM_RECTANGLE,
            md: ADS.SKYSCRAPER,
            mdlg: ADS.SKYSCRAPER,
            lg: ADS.SKYSCRAPER,
            xl: ADS.SKYSCRAPER,
        },
        positionTargets: {
            sm: 'petsearch_right_300x250',
            md: 'petsearch_right_160x600',
            mdlg: 'petsearch_right_160x600',
            lg: 'petsearch_right_160x600',
            xl: 'petsearch_right_160x600',
        },
    },
    SearchResults3: {
        id: 'Pet_Search',
        size: ADS.LEADERBOARD,
        responsiveSizes: {
            sm: ADS.LARGE_MOBILE_BANNER,
            md: ADS.LARGE_MOBILE_BANNER,
            mdlg: ADS.LEADERBOARD,
            lg: ADS.LEADERBOARD,
            xl: ADS.LEADERBOARD,
        },
        positionTargets: {
            sm: 'petsearch_bottom_320x100',
            md: 'petsearch_bottom_320x100',
            mdlg: 'petsearch_bottom_728x90',
            lg: 'petsearch_bottom_728x90',
            xl: 'petsearch_bottom_728x90',
        },
    },
    SearchResults4: {
        id: 'Pet_Search',
        size: ADS.LEADERBOARD,
        responsiveSizes: {
            sm: ADS.LARGE_MOBILE_BANNER,
            md: ADS.LARGE_MOBILE_BANNER,
            mdlg: ADS.LARGE_MOBILE_BANNER,
            lg: ADS.LARGE_MOBILE_BANNER,
            xl: ADS.LEADERBOARD,
        },
        positionTargets: {
            sm: 'petsearch_partial_middle_320x100',
            md: 'petsearch_partial_middle_320x100',
            mdlg: 'petsearch_partial_middle_320x100',
            lg: 'petsearch_partial_middle_320x100',
            xl: 'petsearch_partial_middle_728x90',
        },
    },
    PetDetail1: {
        id: 'Pet_Details',
        size: ADS.MEDIUM_RECTANGLE,
        responsiveSizes: {
            sm: ADS.MEDIUM_RECTANGLE,
            md: ADS.MEDIUM_RECTANGLE,
            mdlg: ADS.MEDIUM_RECTANGLE,
            lg: ADS.MEDIUM_RECTANGLE,
            xl:  ADS.MEDIUM_RECTANGLE,
        },
        positionTargets: {
            sm: 'petdetails_middle_300x250',
            md: 'petdetails_middle_300x250',
            mdlg: 'petdetails_middle_300x250',
            lg: 'petdetails_top_300x250',
            xl: 'petdetails_top_300x250',
        },
    },
    PetDetail2: {
        id: 'Pet_Details',
        size: ADS.LEADERBOARD,
        responsiveSizes: {
            lg: ADS.LEADERBOARD,
            xl: ADS.LEADERBOARD,
        },
        positionTargets: {
            lg: 'petdetails_middle_728x90',
            xl: 'petdetails_middle_728x90',
        },
    },
    PetDetail3: {
        id: 'Pet_Details',
        size: ADS.LARGE_MOBILE_BANNER,
        responsiveSizes: {
            sm: ADS.LARGE_MOBILE_BANNER,
            md: ADS.LARGE_MOBILE_BANNER,
            mdlg: ADS.LEADERBOARD,
        },
        positionTargets: {
            sm: 'petdetails_bottom_320x100',
            md: 'petdetails_bottom_320x100',
            mdlg: 'petdetails_bottom_728x90',
        },
    },
    PetDetail4: {
        id: 'Pet_Details',
        size: ADS.MEDIUM_RECTANGLE,
        responsiveSizes: {
            sm: ADS.MEDIUM_RECTANGLE,
            md: ADS.MEDIUM_RECTANGLE,
            mdlg: ADS.MEDIUM_RECTANGLE,
        },
        positionTargets: {
            sm: 'petdetails_top_300x250',
            md: 'petdetails_top_300x250',
            mdlg: 'petdetails_top_300x250',
        },
    },
    PetDetail5: {
        id: 'Pet_Details',
        size: ADS.LARGE_MOBILE_BANNER,
        responsiveSizes: {
            sm: ADS.LARGE_MOBILE_BANNER,
            md: ADS.LARGE_MOBILE_BANNER,
            mdlg: ADS.LARGE_MOBILE_BANNER,
            lg: ADS.LEADERBOARD,
            xl: ADS.LEADERBOARD,
        },
        positionTargets: {
            sm: 'petdetails_bottom_320x100',
            md: 'petdetails_bottom_320x100',
            mdlg: 'petdetails_bottom_320x100',
            lg: 'petdetails_bottom_728x90',
            xl: 'petdetails_bottom_728x90',
        },
    },

};
