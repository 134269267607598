import { trackEvent } from '../../../core/scripts/analytics';
import { ANALYTIC_STATE_LABELS, ANALYTIC_FLOW_LABELS } from '../react-components/specifics/LoginLauncher/statechart';
import {
    CONTEXT,
    CONTEXT_NAMES_FOR_ANALYTICS,
    CONTEXT_TRIGGER_ELEMENTS,
    ERROR_TYPES,
    ERROR_TYPES_MESSAGE_DICTIONARY,
    TRIGGER_ELEMENTS,
} from '../react-components/specifics/LoginLauncher/utilities/copyMap';
import animalSearchStateController from '../../scripts/state-controllers/animal-search/animalSearchStateController';
import AnimalSearchEventModel from '../../../core/scripts/analytics/models/animal-search/AnimalSearchEventModel';
import UserLoginRegistrationEventModel from '../../../core/scripts/analytics/models/user-login-registration/UserLoginRegistrationEventModel';
import _get from 'lodash/get';
/**
 * Will be set to string identifer for the trigger of the authentication modal
 * @type {string}
 */
// TODO: Investigate if this is working as intended - this doesn't work as intended in exported functions below
let initiatingUIElement;

/**
 * Check an error object and return type string for easier message mapping
 * @param {Object} errors
 * @returns {string}
 */
function getErrorType(errors) {
    // eslint-disable-next-line no-undefined
    if (errors === undefined) {
        return ERROR_TYPES.GLOBAL;
    }
    if (typeof errors === 'object') {
        if (errors.errors.global && errors.errors.global.length >= 1) {
            return errors.errors.global[0];
        } else {
            return ERROR_TYPES.VALIDATION;
        }
    }
}

/**
 * @public
 * @event consumer015
 */
export function analyticsConsumer015() {
    trackEvent({
        category: 'sign in',
        action: 'profile button',
    });
}

/**
 * @public
 * @event consumer016
 */
export function analyticsConsumer016() {
    trackEvent({
        category: 'sign in',
        action: 'access account',
    });
}

/**
 * @public
 * @event consumer017
 */
export function analyticsConsumer017() {
    trackEvent({
        category: 'sign out',
        action: 'log out of site',
    });
}

/**
 * Petfinder Consumer 018
 * Page: Login Modal
 * Action: Login in to Site
 *
 * @public
 * @param {Object} params
 * @event PetfinderConsumer018
 */
export function analyticsConsumer018(params) {
    const { authenticated } = params;
    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: authenticated ? 'logged in' : 'not logged in',
    });

    trackEvent({
        category: 'sign in',
        action: 'login to site',
        event: 'Petfinder Consumer 018',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * @public
 * @event consumer020
 */
export function analyticsConsumer020() {
    trackEvent({
        category: 'sign in',
        action: 'forgot password',
    });
}

/**
 * @public
 * @event consumer022
 */
export function analyticsConsumer022() {
    trackEvent({
        category: 'registration',
        action: 'start registration',
    });
}

/**
 * Petfinder Consumer 023
 * Page: Login Modal
 * Action: Login in to Site
 *
 * @public
 * @param {Object} params
 * @event PetfinderConsumer023
 */
export function analyticsConsumer023(params) {
    const { authenticated } = params;
    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: authenticated ? 'registered' : 'not registered',
    });

    trackEvent({
        category: 'registration',
        action: 'submit registration',
        event: 'Petfinder Consumer 023',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * @public
 * @event consumer016
 */
export function analyticsConsumer279() {
    trackEvent({
        category: 'sign in',
        action: 'manage alerts',
    });
}

/**
 * Global Nav
 */

// /**
//  * @public
//  * @event consumer1.1, consumer1.2, consumer2.1, consumer2.2
//  * @param {string} detail
//  * User triggers the authentication modal
//  */
// export function analyticsConsumerA(detail) {
//     const trigger = detail.trigger;
//     const initiatingFeature = trigger.getAttribute('launch-origin'); // aif, global-nav
//     const initialViewAttribute = trigger.getAttribute('launch-display'); // sign up, log in

// initiatingUIElement = trigger;
//     trackEvent({
//         category: initiatingFeature,
//         action: initialViewAttribute, // should map to 'sign up' or 'log in'
//     });
// }

/**
 * Global nav signup
 * @public
 * @event consumer015B
 * @event 1.1, 2.1
 */
export function analyticsConsumer015B({ trigger }) {
    initiatingUIElement = TRIGGER_ELEMENTS[trigger.dataset.key];

    trackEvent({
        category: 'global nav',
        action: 'sign up',
    });
}

/**
 * Global nav login
 * @public
 * @event consumer016B
 * @event 1.2, 2.2
 */
export function analyticsConsumer016B({ trigger }) {
    initiatingUIElement = TRIGGER_ELEMENTS[trigger.dataset.key];

    trackEvent({
        category: 'global nav',
        action: 'log in',
    });
}

/**
 * AIF Sign In Link
 * @public
 * @event consumer301B
 * @event 3.1
 */
export function analyticsConsumer301B({ trigger }) {
    initiatingUIElement = TRIGGER_ELEMENTS[trigger.dataset.key];

    trackEvent({
        category: 'adopt me',
        action: 'sign in link',
    });
}

/**
 * Track initiatingUIElement
 * @public
 * @event trigger
 * @event 3.1
 */
export function analyticsTrigger({ trigger }) {
    const triggerKey = trigger.dataset ? trigger.dataset.key : trigger;
    initiatingUIElement = TRIGGER_ELEMENTS[triggerKey];
}

/**
 * Initiate authentication process
 * @public
 * @event consumer002, consumer006
 * @event 6.1, 6.2,6.3, 7.1,7.3,7.4, 8.4, 10.2, 10.3, 10.4
 * @param {string} params
 */
// DEV NOTE: This function is deprecated use event analyticsFilterSocialAuthentication on social-login.js
// TODO: Replace all Consumer002 with analyticsFilterSocialAuthentication calls data-analytics-key='social-login'
export function analyticsConsumer002(params) {
    const { provider, view, context } = params;

    // if label isn't present, pass in value directly. Useful for states not captured in statechart (ie: registration success)
    const flowLabel = ANALYTIC_FLOW_LABELS[view] || '';
    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: 'not logged in',
        loginProvider: provider, // 'authenticationProvider'
        initiatingSite: CONTEXT_NAMES_FOR_ANALYTICS[context],
        // initiatingUIElement is set by analyticsTrigger function above which is called by SocialLoginStateController.openLoginModal
        initiatingUI: initiatingUIElement,
        currentScreen: `${ANALYTIC_STATE_LABELS[view] || view} modal`,
    });

    trackEvent({
        category: 'authentication',
        action: flowLabel,
        label: 'initiate',
        event: 'Petfinder Consumer 002',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

// used with inline social buttons
// DEV NOTE: This function is deprecated use event analyticsFilterSocialAuthenticationInline on social-login.js
// TODO: Replace all Consumer002inline with analyticsFilterSocialAuthenticationInline calls data-analytics-key='social-login'
export function analyticsConsumer002inline(src) {
    const { provider, feature, flowlabel, uielement } = src.trigger.dataset;
    initiatingUIElement = uielement;
    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: 'not logged in',
        loginProvider: provider,
        initiatingSite: feature,
        initiatingUI: initiatingUIElement,
    });

    trackEvent({
        category: 'authentication',
        action: flowlabel,
        label: 'initiate',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * @public
 * @event consumer018A
 * @event 9.1
 * @param {string} params
 * Reprompt for FB email
 */
export function analyticsConsumer018A(params) {
    const { provider, view, context, trigger } = params;
    const flowLabel = ANALYTIC_FLOW_LABELS[view] || '';
    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: 'not logged in',
        loginProvider: provider,
        initiatingSite: CONTEXT_NAMES_FOR_ANALYTICS[context],
        initiatingUI: initiatingUIElement,
        currentScreen: `${ANALYTIC_STATE_LABELS[view] || view} modal`,
    });

    trackEvent({
        category: 'authentication',
        action: flowLabel,
        label: 'provide email',
        event: 'analyticsConsumer018a',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Click to go to 'log in' view
 * @public
 * @event consumer014
 * @event 6.4, 13.1
 * @param {string} params
 */
export function analyticsConsumer014(params) {
    const { provider, view, context } = params;
    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: 'not logged in',
        loginProvider: provider || 'petfinder',
        initiatingSite: CONTEXT_NAMES_FOR_ANALYTICS[context],
        initiatingUI: initiatingUIElement,
        currentScreen: `${ANALYTIC_STATE_LABELS[view] || view} modal`,
    });

    trackEvent({
        category: 'authentication',
        action: 'log in to current account',
        event: 'analyticsConsumer014',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Click to go to 'sign up' view
 * @public
 * @event consumer019
 * @event 7.5
 * @param {string} params
 */
export function analyticsConsumer019(params) {
    const { provider, view, context } = params;
    let eventParams;

    if (!view) {
        // used on /user/login
        eventParams = {
            authenticationStatus: 'not logged in',
        };
    } else {
        eventParams = {
            authenticationStatus: 'not logged in',
            loginProvider: provider,
            initiatingSite: CONTEXT_NAMES_FOR_ANALYTICS[context],
            initiatingUI: initiatingUIElement,
            currentScreen: `${ANALYTIC_STATE_LABELS[view]} modal`,
        };
    }

    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        eventParams,
    });

    trackEvent({
        category: 'authentication',
        action: "don't have an account",
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * User quits authentication flow
 * unload event tracking??
 * @public
 * @event consumer005, consumer009
 * @event 6.5, 7.7, 8.6, 9.2, 10.5, 11.4, 12.3, 13.2
 * @param {string} params
 */
export function analyticsConsumer005(params) {
    const { provider, view, context } = params;
    const flowLabel = ANALYTIC_FLOW_LABELS[view] || '';
    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: 'not logged in',
        loginProvider: provider,
        initiatingSite: CONTEXT_NAMES_FOR_ANALYTICS[context],
        initiatingUI: initiatingUIElement,
        pointOfAbandonment: `${ANALYTIC_STATE_LABELS[view]} modal`,
        currentScreen: `${ANALYTIC_STATE_LABELS[view]} modal`,
    });

    trackEvent({
        category: 'authentication',
        action: flowLabel,
        label: 'abandon',
        event: 'analyticsConsumer005',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Social Login 010
 * Page: Email - Log in Modal
 * Action: User Selects "Forgot Password" from Login Module
 *
 * @public
 * @param {Object} params
 * @event SocialLogin010
 */
export function analyticsConsumer010(params) {
    const { view, context } = params;
    let eventParams;

    if (view) {
        eventParams = {
            currentScreen: `${ANALYTIC_STATE_LABELS[view]} modal`,
        };
    } else {
        eventParams = {};
    }

    const analytics = UserLoginRegistrationEventModel.prepareEvent(eventParams);

    trackEvent({
        category: 'authentication',
        action: 'forgot password',
        label: 'click on link',
        event: 'Social Login 010',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * User experiences error
 * @public
 * @event consumer008, consumer004
 * @event 7.6, 8.3, 11.5, 12.4,13.3
 * @param {string} params
 */
export function analyticsConsumer008(params) {
    const { provider, view, context, errors, customError } = params;
    const initiatingFeature = CONTEXT_NAMES_FOR_ANALYTICS[context];
    const authenticationStep = ANALYTIC_STATE_LABELS[view];
    let errorType;
    if (customError) {
        errorType = customError;
    } else {
        const error = getErrorType(errors);
        errorType = ERROR_TYPES_MESSAGE_DICTIONARY[error] || error;
    }
    let flowLabel = ANALYTIC_FLOW_LABELS[view] || '';

    let eventParams;
    if (!view) {
        const trigger = params.trigger.innerText;
        const flow = _get(params, 'trigger.dataset.flow', '');
        flowLabel = ANALYTIC_FLOW_LABELS[flow];
        // used on /user/login and /user/registration
        eventParams = {
            authenticationStatus: 'not logged in',
            errorMessage: trigger,
        };
    } else {
        eventParams = {
            authenticationStatus: 'not logged in',
            loginProvider: provider, // 'authenticationProvider'
            initiatingSite: initiatingFeature, // context
            initiatingUI: initiatingUIElement,
            errorMessage: errorType,
            currentScreen: `${authenticationStep} modal`,
        };
    }

    const analytics = UserLoginRegistrationEventModel.prepareEvent(eventParams);

    trackEvent({
        category: 'authentication',
        action: flowLabel,
        label: 'error',
        event: 'analyticsConsumer008',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * User successfully logs in or signs up
 * @public
 * @event consumer007, consumer003, consumer017
 * @event 7.8, 8.5, 11.2, 12.1
 * @param {string} params // provider, context
 */
export function analyticsConsumer007(params) {
    const { provider, context, view, flow } = params;
    const authenticationStep = ANALYTIC_STATE_LABELS[view];
    const flowLabel = flow || authenticationStep;

    let modalName;
    if (authenticationStep) {
        modalName = `${authenticationStep} modal`;
    } else {
        modalName = '';
    }

    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: 'logged in',
        loginProvider: provider,
        initiatingSite: CONTEXT_NAMES_FOR_ANALYTICS[context],
        initiatingUI: initiatingUIElement,
        currentScreen: modalName,
    });
    trackEvent({
        category: 'authentication',
        action: flowLabel,
        label: 'complete',
        event: 'analyticsConsumer007',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * User successfully logs in or signs up from /user/login/ or /user/register/
 * @public
 * @event consumer003static
 * @param {Object} srcData trigger information: indicates page src
 */
export function analyticsConsumer003static(srcData) {
    const flow = _get(srcData, 'trigger.dataset.flow', ''); // 'sign up' or 'log in'
    const contextName = _get(srcData, 'trigger.dataset.feature', '');
    const contextTriggerElement = _get(srcData, 'trigger.dataset.uielement', '');

    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: 'logged in',
        loginProvider: 'petfinder',
        initiatingSite: contextName ? contextName : `${flow} page`,
        initiatingUI: contextTriggerElement ? contextTriggerElement : `${flow} button`,
    });

    trackEvent({
        category: 'authentication',
        action: flow,
        label: 'complete',
        event: 'analyticsConsumer003static',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Social Login 011
 * Page: Login Modal
 * Action: Login in to Site
 *
 * @public
 * @param {Object} params
 * @event SocialLogin011
 */
export function analyticsConsumer011(params) {
    const { provider, view, context } = params;
    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: 'not logged in',
        loginProvider: provider,
        initiatingSite: CONTEXT_NAMES_FOR_ANALYTICS[context],
        initiatingUI: initiatingUIElement,
        currentScreen: `${ANALYTIC_STATE_LABELS[view] || view} modal`,
    });

    trackEvent({
        category: 'authentication',
        action: 'switch providers',
        label: provider,
        event: 'Social Login 011',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

// used with inline social buttons
export function analyticsConsumer011inline(src) {
    const { provider, feature, uielement } = src.trigger.dataset;
    initiatingUIElement = uielement;

    const analytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus: 'not logged in',
        loginProvider: provider,
        initiatingSite: feature,
        initiatingUI: initiatingUIElement,
    });

    trackEvent({
        category: 'authentication',
        action: 'switch providers',
        label: provider,
        event: 'Social Login 011',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * MEMBER REGISTRATION LANDING PAGE EVENTS
 * These events are running/triggered thru pf-ensighten tags on the member registaration
 * landing page wordpress templates
 */

/**
 * MemberRegistration002
 * Page: Member Registration - Main Page
 * Action: User clicks "Login" in Main Nav
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration002
 */
export function analyticsMemberRegistration002(detail) {
    trackEvent({
        category: 'global-nav-pro',
        action: 'access dashboard',
        label: 'sign in',
        event: 'Member Registration 002 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

/**
 * MemberRegistration003
 * Page: Member Registration - Main Page
 * Action: User clicks "Petfinder Pro" logo
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration003
 */
export function analyticsMemberRegistration003(detail) {
    trackEvent({
        category: 'global-nav-pro',
        action: 'pro logo',
        event: 'Member Registration 003 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

/**
 * MemberRegistration004
 * Page: Member Registration - Main Page
 * Action: User clicks "Terms & Conditions"
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration004
 */
export function analyticsMemberRegistration004(detail) {
    trackEvent({
        category: 'member registration',
        action: 'terms and conditions link',
        label: 'pre application',
        event: 'Member Registration 004 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

/**
 * MemberRegistration006
 * Page: Member Registration - Main Page
 * Action: User clicks "Download Vet Form" (prior to filling out application and after)
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration006
 */
export function analyticsMemberRegistration006(detail) {
    trackEvent({
        category: 'member registration',
        action: 'vet form',
        label: 'download: pre application',
        event: 'Member Registration 006 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

/**
 * MemberRegistration007
 * Page: Member Registration - Main Page
 * Action: User clicks, "Apply Now"
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration007
 */
export function analyticsMemberRegistration007(detail) {
    trackEvent({
        category: 'member registration',
        action: 'start application',
        label: 'primary apply',
        event: 'Member Registration 007 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

/**
 * MemberRegistration009
 * Page: Member Registration - Main Page
 * Action: User clicks Outreach email link
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration009
 */
export function analyticsMemberRegistration009(detail) {
    trackEvent({
        category: 'member registration',
        action: 'outreach email',
        label: 'email vet form pre apply: directions',
        event: 'Member Registration 009 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

/**
 * MemberRegistration010
 * Page: Member Registration - Main Page
 * Action: User clicks Outreach email link
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration010
 */
export function analyticsMemberRegistration010(detail) {
    trackEvent({
        category: 'member registration',
        action: 'outreach email',
        label: 'email vet form pre apply: download',
        event: 'Member Registration 010 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

/**
 * MemberRegistration085
 * Page: Member Registration - Main Page
 * Action: User clicks Outreach email link from yellow box below #2
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration085
 */
export function analyticsMemberRegistration085(detail) {
    trackEvent({
        category: 'member registration',
        action: 'outreach email',
        label: 'email vet form yellow box pre apply: download',
        event: 'Member Registration 085 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

/**
 * MemberRegistration086
 * Page: Member Registration - Main Page
 * Action: User clicks Outreach email link at bottom of page below the Apply button
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration086
 */
export function analyticsMemberRegistration086(detail) {
    trackEvent({
        category: 'member registration',
        action: 'outreach email',
        label: 'pre apply',
        event: 'Member Registration 086 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

/**
 * MemberRegistration087
 * Page: Member Registration - Main Page
 * Action: User clicks the Apply button at the top of the page
 *
 * @public
 * @param {Object} detail
 * @event MemberRegistration087
 */
export function analyticsMemberRegistration087(detail) {
    trackEvent({
        category: 'member registration',
        action: 'start application',
        label: 'apply to join top nav',
        event: 'Member Registration 087 - Member Registration',
        dimensions: {},
        additional: {},
    });
}

// PFPROD-4238 - GA4 Analytics - Search Page Saved Search
export function eventClickSavedSearch({ isLoggedIn }) {
    const searchEventModelData = AnimalSearchEventModel.mapAnimalSearchFilters(animalSearchStateController.filters);

    // lowercase and joined array values
    Object.keys(searchEventModelData).forEach(key => {
        if (Array.isArray(searchEventModelData[key])) {
            searchEventModelData[key] = searchEventModelData[key]
                .sort()
                .join(', ')
                .toLowerCase();
        } else if (typeof searchEventModelData[key] === 'string') {
            searchEventModelData[key] = searchEventModelData[key].toLowerCase();
        }
    });

    /* eslint-disable camelcase, no-undefined */
    window.dataLayer.push(
        {
            event: 'save_search',
            ga4: true,
            event_params: {
                shelter_id: searchEventModelData.shelter,
                login_status: isLoggedIn ? 'logged in' : 'guest',
                pet_species: searchEventModelData.animalType,
                pet_age: searchEventModelData.age,
                pet_breed_1: searchEventModelData.breed,
                pet_gender: searchEventModelData.gender,
                pet_distance: searchEventModelData.distance,
                pet_size: searchEventModelData.size,
                pet_color: searchEventModelData.color,
                pet_household: searchEventModelData.household,
                pet_coat_length: searchEventModelData.coatLength,
                pet_health_behavior: searchEventModelData.attribute,
                pet_name: searchEventModelData.animalName ? 'yes' : 'no',
                pet_transport: searchEventModelData.transportable,
                pet_days_on_pf: searchEventModelData.daysOnPetfinder,
            },
        },
        { event_params: undefined }
    );
}

/**
 * Saved Search Event #336
 * User clicks, "Email me daily if there are new matches"
 * @public
 * @event Consumer336
 */
export function analyticsConsumer336() {
    trackEvent({
        category: 'search alerts',
        action: 'search saved',
        label: 'email me daily',
    });
}

/**
 * Saved Search Event #337
 * User clicks "done" from successful saved search module
 * @public
 * @event Consumer337
 */
export function analyticsConsumer337() {
    // Dimensional data was omitted by request of client
    const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
        filterOutNullText: false,
    });
    trackEvent({
        category: 'search alerts',
        action: 'search saved',
        label: 'done',
        event: 'Petfinder Consumer 337 - Saved Search',
        // dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Saved Search Event #338
 * User clicks, "Done" from guest saved search
 * @public
 * @event Consumer338
 */
export function analyticsConsumer338() {
    // Dimensional data was omitted by request of client
    const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
        filterOutNullText: false,
    });
    trackEvent({
        category: 'search alerts',
        action: 'search saved',
        label: 'guest done',
        event: 'Petfinder Consumer 338 - Saved Search',
        // dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 340
 * Page: Nav
 * Action: User clicks 'Save Search' from search filter nav
 *
 * @public
 * @event Consumer340
 */
export function analyticsConsumer340() {
    trackEvent({
        category: 'search alerts',
        action: 'save search',
        label: 'save search nav',
        event: 'Petfinder Consumer 340',
        dimensions: {},
        additional: {},
    });
}

/**
 * Saved Search Event #341
 * View Previous Searches
 *
 * Saved Search Event #342
 * Hide Previous Searches
 * @public
 * @event Consumer341_342
 * @param {*} isVisible
 */
export function analyticsConsumer341_342(isVisible) {
    trackEvent({
        category: 'previous searches nav',
        action: isVisible ? 'expand' : 'hide',
    });
}

/**
 * Saved Search Event #343
 * Select Results from a Previous Search
 *
 * @public
 * @event Consumer343
 * @param {*} searchName
 */
export function analyticsConsumer343(searchName) {
    trackEvent({
        category: 'previous searches nav',
        action: 'view results',
        label: searchName,
    });
}

/**
 * Saved Search Event #344
 * View All Results from a Previous Search/Click Manage Searches
 *
 * @public
 * @event Consumer344
 */
export function analyticsConsumer344() {
    trackEvent({
        category: 'previous searches nav',
        action: 'view all',
    });
}

/**
 * Saved Search Event #438
 * User clicks, 'Manage Saved Searches' from search saved module
 * @public
 * @event Consumer438
 */
export function analyticsConsumer438() {
    trackEvent({
        category: 'search alerts',
        action: 'search saved',
        label: 'done: manage saved searches',
    });
}

/**
 * Location Enhancements Event #444
 * Interceptor modal launches on Search page due to missing parameters
 *
 * @public
 * @event Consumer444
 * @param {array} missingParams
 */
export function analyticsConsumer444(missingParams) {
    const missingParamsString = missingParams.join(' and ');

    trackEvent({
        category: 'search criteria',
        action: 'search info modal',
        event: 'Petfinder Consumer 444 - Location Enhancements',
        label: missingParamsString,
    });
}

/**
 * Location Enhancements Event #445
 * User taps "use current location" button in location search field
 *
 * @public
 * @event Consumer445
 * @param {string} initiatingFeature
 */
export function analyticsConsumer445(initiatingFeature = '') {
    trackEvent({
        category: 'search criteria',
        action: 'use current location',
        label: 'initiate to allow location',
        event: 'Petfinder Consumer 445 - Location Enhancements',
        dimensions: { 79: initiatingFeature },
    });
}

/**
 * Location Enhancements Event #446
 * User receives error when unable to detect location
 *
 * @public
 * @event Consumer446
 * @param {string} initiatingFeature
 */
export function analyticsConsumer446(initiatingFeature = '') {
    trackEvent({
        category: 'search criteria',
        action: 'use current location',
        label: 'location not found',
        event: 'Petfinder Consumer 446 - Location Enhancements',
        dimensions: { 79: initiatingFeature },
    });
}
