/**
 * Exports class constants which are shared strings.
 */

export const CLASS_IS_ACTIVE = 'u-isActive';
export const CLASS_IS_VISIBLE = 'u-isVisible';
export const CLASS_BLOCK = 'u-block';
export const CLASS_IS_CHECKED = 'u-isChecked';
export const CLASS_IS_CURRENT = 'u-isCurrent';
export const CLASS_IS_OPEN = 'u-isOpen';
export const CLASS_IS_SELECTED = 'u-isSelected';
export const CLASS_IS_FOCUSED = 'u-isFocused';
export const CLASS_IS_DISABLED = 'u-isDisabled';
export const CLASS_IS_LOADING = 'u-isLoading';

// TODO: CLASS_IS_LOADED and CLASS_IS_NO_RESULTS not used; check others and clean up this file
export const CLASS_IS_LOADED = 'u-isLoaded';
export const CLASS_IS_NO_RESULTS = 'u-noResults';

export const CLASS_IS_TRANSITIONING = 'u-isTransitioning';
export const CLASS_IS_STICKY = 'u-isSticky';
export const CLASS_CHILD_IS_FOCUSED = 'u-childIsFocused';
export const CLASS_HAS_INPUT = 'u-hasInput';
export const CLASS_IS_HIDDEN = 'u-isHidden';
export const CLASS_IS_VISUALLY_HIDDEN = 'u-isVisuallyHidden';
export const CLASS_IS_ERRORED = 'u-isErrored';
export const CLASS_CHECKBOX_HIDE_BTN = 'm-checkbox_hideBtn';
export const CLASS_FIELD_IS_ERRORED = 'm-field_error';
export const CLASS_SELECT_FONT_SIZE_LG = 'm-selectGroup-field_fontSizeLg';

export const CLASS_NOT_OBSERVED = 's-notObserved';
