import get from 'lodash/get';
import userMeAPIService from '../services/userMeAPIService';

/**
 * Setups up a shared global config object for use amongst components. This data is grabbed
 * from a config object placed on the page by the backend or by AJAX requests, probably made on
 * page load.
 * Has info such as the location of the user.
 * Data loaded from AJAX requests are asynchronous.  To get these values, use the Promise
 * wrapped getters,
 * like:
 *   async foo() {
 *      const lat = await this.usersLatitude;
 *      // do stuff with latitude
 *   }
 *
 * @class Config
 */
export class Config {
    /**
     * @public
     * @type {Object} userMe data
     * @returns {Promise}
     */
    static async getUserMe() {
        const userMe = await userMeAPIService.getUserMe();
        return userMe.dataFromServer;
    }

    /**
     * Fetch data about the user and add to the global config object for
     * consumption by other modules.
     *
     * @private
     * @method _getUserMeData
     * @return {Promise.<Object>} me object wrapped in a promise
     */
    static async _getUserMeData() {
        return await this.userMe;
    }

    /**
     * @type {Promise.<string>}
     */
    static get usersLatitude() {
        return this._getFromUserMeData('location.lat');
    }

    /**
     * @type {Promise.<string>}
     */
    static get usersLongitude() {
        return this._getFromUserMeData('location.lng');
    }

    /**
     * @type {Promise.<string>}
     */
    static get userCountryCode() {
        return this._getFromUserMeData('location.country.code');
    }

    /**
     * @type {Promise.<string>}
     */
    static get userLocationName() {
        return this._getFromUserMeData('location.name');
    }

    /**
     * @type {Promise.<string>}
     */
    static get userStateCode() {
        return this._getFromUserMeData('location.state.code');
    }

    /**
     * @type {Promise.<string>}
     */
    static get userLocationDisplayName() {
        return this._getFromUserMeData('location.display_name');
    }

    /**
     * @type {Promise.<string>}
     */
    static get userLocationSlug() {
        return this._getFromUserMeData('location.slug');
    }

    /**
     * @type {Promise.<string>}
     */
    static get userLocationSource() {
        return this._getFromUserMeData('location.source');
    }

    /**
     * Get the user object from user me
     * @return {string}
     */
    static get user() {
        return this._getFromUserMeData('user');
    }

    /**
     * Get the user me token
     * @return {string}
     * @type {Promise.<string>}
     */
    static get userToken() {
        return this._getFromUserMeData('token');
    }

    /**
     * Get the user me saved search token, this is a different token
     * @type {Promise.<string>}
     */
    static get userSavedSearchToken() {
        return this._getFromUserMeData('saved_search_token');
    }

    /**
     * @type {Promise.<string>}
     */
    static get dyaData() {
        return this._getFromUserMeData('did_you_adopt_details');
    }

    /**
     * @type {Promise.<string>}
     */
    static get savedSearches() {
        return this._getFromUserMeData('saved_searches');
    }

    /**
     * @return {Boolean}
     */
    static get userAuthed() {
        return this.page.user_auth;
    }

    /**
     * Returns the consumer url for the current environment
     * @return {string}
     */
    static get wwwUrl() {
        return this.page.www_url;
    }

    /**
     * Returns the pro url for the current environment
     * @return {string}
     */
    static get proUrl() {
        return this.page.pro_url;
    }

    /**
     * Get the WordPress ajax url
     * @return {string}
     */
    static get wpAjaxUrl() {
        return this.page.ajax_url;
    }

    /**
     * Get Page Config object written on page by backend.
     * @return {Object}
     */
    static get page() {
        window.PF = window.PF || {};
        window.PF.pageConfig = window.PF.pageConfig || {};

        return window.PF.pageConfig;
    }

    /**
     * Triggers a refresh of the user/me API data
     */
    static async refreshUserMe() {
        const userMe = await userMeAPIService.refreshUserMe();
        return userMe.dataFromServer;
    }

    /**
     * @method _getFromUserMeData
     * @param {string} key corresponding to the location of the value in the userMe data
     * @return {*} value from object
     */
    static async _getFromUserMeData(key) {
        const userMeData = await this.getUserMe();
        return get(userMeData, key);
    }
}

export default Config;
