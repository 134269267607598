// TODO: this file is to be deprecated (last bit using this is for saved search deletion and notification update on /user/search-alerts)

import Repository from './repository/repository';
import { ERROR_API } from '../constants/errors';
import UserMeRepository from './UserMeRepository';

/**
 * UserSavedSearch Repository
 * @extends Repository
 */
export default class UserSavedSearchRepository extends Repository {
    /**
     * Creates a saved search
     * @param {object} params form data object
     * @return {Promise}
     */
    async createSavedSearch(params) {
        const formDataParams = {};

        if (params.name) {
            formDataParams['saved_search[name]'] = params.name;
        }

        if (params.token) {
            formDataParams['saved_search[token]'] = params.token;
        }

        if (params.searchUrl) {
            formDataParams['saved_search[search_url]'] = params.searchUrl;
        }

        if (params.email) {
            formDataParams['saved_search[email]'] = params.email;
        }

        if (params.locationSlug) {
            formDataParams['saved_search[location_slug]'] = params.locationSlug;
        }

        // selectively include parameters depending on whether or not the user is logged in
        const isLoggedIn = await UserMeRepository.isLoggedIn;
        if (!isLoggedIn) {
            if (params.country) {
                formDataParams['saved_search[country]'] = params.country;
            }

            if (params.optins) {
                // TODO: ideally the backend would accept booleans for these...
                // something to improve in the future
                const transformedOptins = {};
                // eslint-disable-next-line
                for (const key in params.optins) {
                    if (!params.optins[key]) {
                        continue;
                    }

                    transformedOptins[key] = 'on';
                }

                Object.assign(formDataParams, transformedOptins);
            }
        }

        const response = await fetch(
            '/user/search-alerts/',
            {
                method: 'POST',
                headers: {
                    // Need this content-type here
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'same-origin',
                body: Repository.formDataFromObject(formDataParams),
            }
        );

        if (response.status === 400) {
            // For parsing error response and getting redirect
            return Promise.reject(await this.readResponse(response));
        }

        if (this.wasRequestSuccessful(response)) {
            return Promise.resolve(await this.readResponse(response));
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }

    /**
     * Updates a saved search item
     * @param {string} uuid saved search id
     * @param {string} formAction
     * @param {FormData} params form data object from the change form
     * @return {Promise}
     */
    async update(uuid, formAction, params) {
        const response = await fetch(
            `/user/search-alerts/${uuid}/edit/`,
            {
                method: 'POST',
                headers: {
                    // Need this content-type here
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'same-origin',
                body: params,
            }
        );

        if (response.status === 400) {
            // For parsing error response and getting redirect
            return Promise.reject(await this.readResponse(response));
        }

        if (this.wasRequestSuccessful(response)) {
            return Promise.resolve(await this.readResponse(response));
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }

    /**
     * Deletes a saved search item
     * @param {string} uuid saved search id
     * @param {string} formAction
     * @param {FormData} params form data object from the delete form
     * @return {Promise}
     */
    async delete(uuid, formAction, params) {
        const response = await fetch(
            formAction,
            {
                method: 'POST',
                headers: {
                    // Need this content-type here
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'same-origin',
                body: params,
            }
        );

        if (response.status === 400) {
            // For parsing error response and getting redirect
            return Promise.reject(await this.readResponse(response));
        }

        if (this.wasRequestSuccessful(response)) {
            return Promise.resolve(await this.readResponse(response));
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }
}

export const userSavedSearchRepository = new UserSavedSearchRepository();
