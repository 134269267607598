import axios from 'axios';
import { stringify } from 'query-string';

/**
 * Take object of key values and return them as a form data object
 * @param {Object} value
 * @returns {FormData}
 */
export function buildFormDataFromKeyValues(value) {
    const formData = new FormData();

    Object.keys(value).forEach(fieldKey => {
        let fieldValue = value[fieldKey];

        // WARNING
        // Turn null value into empty string
        if (fieldValue === null) {
            fieldValue = '';
        }

        // Handle multi choice value aka checkboxes
        if (typeof fieldValue === 'object') {
            Object.keys(fieldValue).forEach(fieldValueKey => {
                if (fieldValue[fieldValueKey]) {
                    formData.append(
                        `${fieldKey}[]`,
                        fieldValueKey
                    );
                }
            });
        } else {
            // Or just append the field and value
            formData.append(fieldKey, fieldValue);
        }
    });

    return formData;
}

// TODO: Deprecate with axios refactor?
// TODO: Need BE to alter responses to remove props key?
/**
 * Do some checks on response data object to place its properties under a props key
 * @param {Object} data
 * @returns {Object}
 */
function restructureToPropsResponse(data) {
    if (data.props) {
        return data;
    }

    if (data.success === false && !data.errors) {
        return {
            props: {
                ...data,
                errors: {
                    global: [],
                    fields: {},
                },
            },
        };
    }

    return { props: { ...data } };
}

/**
 * @param {Object} response from axios
 * @returns {Promise}
 */
function handleSuccessResponse(response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(restructureToPropsResponse(response.data));
    }
}

/**
 * @param {Object} errorResponse from axios
 * @returns {Promise}
 */
function handleErrorResponse(errorResponse) {
    if (errorResponse.response) {
        const { response } = errorResponse;

        if (response.status === 400 || response.status === 401) {
            return Promise.reject(restructureToPropsResponse(response.data));
        }
    }
}

/**
 * Makes basic get request
 * @param {string} endpoint
 * @returns {Promise}
 */
export function basicGet(endpoint = '') {
    return axios({
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        url: endpoint,
    })
        .then(handleSuccessResponse)
        .catch(handleErrorResponse);
}

/**
 * Makes get request with serialized search params create from value to endpoint
 * @param {string} endpoint
 * @param {Object} value
 * @returns {Promise}
 */
export function getWithSearchParams(endpoint = '', value) {
    return axios({
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        url: endpoint,
        params: value,
        paramsSerializer: params =>
            stringify(params, {
                arrayFormat: 'bracket',
            }),
    })
        .then(handleSuccessResponse)
        .catch(handleErrorResponse);
}

/**
 * Makes get post to url
 * @param {string} endpoint
 * @param {Object} value
 * @returns {Promise}
 */
export function postFormDataFromFormValue(endpoint = '', value) {
    return axios({
        method: 'post',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        url: endpoint,
        data: buildFormDataFromKeyValues(value),
    })
        .then(handleSuccessResponse)
        .catch(handleErrorResponse);
}
