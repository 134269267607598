import StateController from '../../../core/scripts/lib/wirey/state/StateController';
import animalSearchStateController from './animal-search/animalSearchStateController';
import uiStateController from './uiStateController';

import ContextContainer from '../../../core/scripts/context';
import { getCurrentBreakpoint } from '../../../core/scripts/util/dom';
import { EV_RESIZE_BREAKPOINT_CHANGE } from '../../../core/scripts/constants/events';
import userMeStateController from './userMeStateController';

const userAuth =
    typeof window !== 'undefined' &&
    window.PF &&
    window.PF.pageConfig &&
    window.PF.pageConfig.user_auth
        ? window.PF.pageConfig.user_auth
        : '';
const DEFAULT_STATE = {
    currentBreakpoint: getCurrentBreakpoint(),
    userAuth,
    user: {
        country: '',
        firstName: '',
        lastName: '',
    },
};

/**
 * Site wide state controller
 */
export class SiteStateController extends StateController {
    // substates
    animalSearch = animalSearchStateController;
    ui = uiStateController;
    userMe = userMeStateController;

    eventEmitter = ContextContainer.eventEmitter;
    onUpdatedHandler = this.onUpdated.bind(this);

    get defaultState() {
        return DEFAULT_STATE;
    }

    constructor() {
        super();
        this.addSubstate('animalSearch', this.animalSearch);
        this.addSubstate('ui', this.ui);

        this.eventEmitter.addListener('update', this.onUpdatedHandler);
    }

    disconnectedCallback() {
        this.eventEmitter.removeListener('update', this.onUpdatedHandler);
    }

    /**
     * Handles update events from pf-app
     * Here we're getting the current breakpoint.
     *
     * @param {Object} ev - event
     */
    onUpdated(ev) {
        const { detail } = ev;
        if (detail.type === EV_RESIZE_BREAKPOINT_CHANGE) {
            this.setState({
                currentBreakpoint: detail.currentBreakpoint,
            });
        }
    }
}

const siteStateController = new SiteStateController();

export default siteStateController;
