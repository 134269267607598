import StateController from '../../../../core/scripts/lib/wirey/state/StateController';

import Utils from '../../../../core/scripts/lib/wirey/Utils';

const DEFAULT_STATE = {
    country: {
        options: [],
        value: [],
    },
    optinRules: [],
    optinValues: [],
    pristine: true,
    createSearchFormOpen: false,
    createSearchSlideoutOpen: true,
    searchSet: false,
    searchSetSlideoutOpen: false,
    searchSetAlertText: '',
    emailInputValue: '',
    isSponsoredCountry: false,
    errors: {
        global: '',
        country: '',
        email: '',
        // TODO: remove below
        fields: {
            'saved_search[country]': [],
            'saved_search[email]': [],
        },
        // global: [],
    },
    formPosting: false,
    teaseSlideout: false,
};

export class SavedSearchStateController extends StateController {
    get defaultState() {
        return DEFAULT_STATE;
    }

    get state() {
        const state = super.state;
        const values = ['US', 'us'];
        // NOTE: at this time, we're only dealing with a single country; do check based on the first array element
        const isSponsoredCountry = values.includes(state.country.value[0]);

        return {
            ...state,
            isSponsoredCountry,
        };
    }

    get country() {
        return this.state.country.value[0];
    }

    set country(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }

        return this.setStateAtAddress(value, 'country.value');
    }

    // overriding the default setDefaultState function to prevent the clearing of countries

    // this is due to something resetting this state controller to default part
    // of the way through the loading process...
    // TODO: look into why that's occurring and determine if it's necessary
    setDefaultState() {
        const countries = this._state ? this._state.country : null;

        const optinRules = this._state ? this._state.optinRules : null;

        const optinValues = this._state ? this._state.optinValues : null;

        this._state = Utils.ObjectUtils.cloneDeep(this.defaultState);

        if (countries) {
            this._state.country = countries;
        }

        if (optinRules) {
            this._state.optinRules = optinRules;
        }

        if (optinValues) {
            this._state.optinValues = optinValues;
        }

        this._afterStateUpdated();
    }
}

export default new SavedSearchStateController();
