import { getCurrentBreakpoint } from '../../../../../core/scripts/util/dom';
import {
    SM_BREAKPOINT,
    MD_BREAKPOINT,
    LG_BREAKPOINT,
    XL_BREAKPOINT,
} from '../../../constants/breakpoints';

import { MIN_QUERY_VALUE_LENGTH } from './constants';

// More about xstate guards here
// http://davidkpiano.github.io/xstate/docs/#/guides/guards

const breakpointGuard = breakpoints => {
    return () => {
        return Array.from(breakpoints).some(
            breakpoint => breakpoint === getCurrentBreakpoint()
        );
    };
};

const queryGuard = () => {
    return (extState, eventObj) => {
        return (
            extState.locationInputValue &&
            extState.locationInputValue.length >= MIN_QUERY_VALUE_LENGTH
        );
    };
};

const searchGuard = (extState, eventObj) => {
    return (extState, eventObj) => {
        return extState.petTypeSlug && extState.locationSlug;
    };
};

const hasLocationsGuard = () => {
    return (extState, eventObj) => {
        return extState.locations && extState.locations.length > 0;
    };
};

const mobileStateChart = {
    parallel: true,
    states: {
        isHandlingSearch: {
            initial: 'idle',
            states: {
                idle: {
                    onEntry: ['handleResizeToMobile'],
                    on: {
                        PET_TYPE_CLICK: {
                            location: {
                                actions: ['triggerPetTypeClickAnalytics'],
                            },
                        },
                        OTHER_PETS_CLICK: 'otherPets',
                    },
                },
                otherPets: {
                    on: {
                        PET_TYPE_CLICK: {
                            location: {
                                actions: ['triggerPetTypeClickAnalytics'],
                            },
                        },
                        CLOSE: 'idle',
                    },
                },
                location: {
                    onEntry: ['fetchSavedSearches'],
                    on: {
                        RESULT_CLICK: {
                            performSearch: {
                                cond: searchGuard(),
                            },
                        },
                        QUERY: 'initLoading',
                        USER_SAVED_SEARCHES_SUCCESS: 'location',
                        USER_SAVED_SEARCHES_ERROR: 'location',
                        CLOSE: 'idle',
                        INPUT_FOCUS: {
                            results: {
                                cond: hasLocationsGuard(),
                            },
                        },
                    },
                },
                initLoading: {
                    on: {
                        '': [
                            {
                                target: 'loading',
                                cond: queryGuard(),
                            },
                            {
                                target: 'location',
                            },
                        ],
                    },
                },
                loading: {
                    onEntry: ['debouncedFetchLocations'],
                    on: {
                        QUERY: 'initLoading',
                        LOCATIONS_SUCCESS: 'results',
                        LOCATIONS_NO_RESULTS: 'noResults',
                        LOCATIONS_FAILURE: 'locationNetworkError',
                    },
                },
                results: {
                    on: {
                        RESULT_CLICK: {
                            performSearch: {
                                cond: searchGuard(),
                                actions: [
                                    'triggerLocationSuggestionClickAnalytics',
                                ],
                            },
                        },
                        QUERY: 'initLoading',
                        CLOSE: 'idle',
                        FOCUS_OUT: 'location',
                        KEY_ESCAPE: 'location',
                    },
                },
                noResults: {
                    on: {
                        QUERY: 'initLoading',
                        CLOSE: 'idle',
                        FOCUS_OUT: 'location',
                        KEY_ESCAPE: 'location',
                    },
                },
                locationNetworkError: {
                    onEntry: ['handleLocationNetworkError'],
                    on: {
                        QUERY: 'initLoading',
                        CLOSE: 'idle',
                    },
                },
                performSearch: {
                    onEntry: ['triggerPerformSearchAnalytics', 'performSearch'],
                },
            },
        },
        isCheckingLocation: {
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        FOCUS: 'active',
                        AUTH_DENIED: 'geoDisabled',
                    },
                },
                active: {
                    initial: 'ready',
                    on: {
                        CLOSE: 'idle',
                        FOCUS_OUT: 'idle',
                        LOCATION_OBTAINED: {
                            hasLocation: {
                                actions: [
                                    'performSearch',
                                    'triggerLocationObtainedAnalytics',
                                ],
                            },
                        },
                        GEO_DISABLED: {
                            geoDisabledMessage: {
                                actions: ['focusGeoLocation'],
                            },
                        },
                        COORDINATES_DENIED: {
                            geoDenied: {
                                actions: ['focusGeoLocation'],
                            },
                        },
                    },
                    states: {
                        ready: {
                            on: {
                                LOCATION_SELECTED: 'locationSelected',
                                CHECK_USER_LOCATION: 'checkingAuth',
                            },
                        },
                        checkingAuth: {
                            onEntry: ['checkIfGeoEnabled'],
                            on: {
                                GEO_ENABLED: 'gettingCoordinates',
                            },
                        },
                        gettingCoordinates: {
                            onEntry: ['getGeoCoordinates'],
                            on: {
                                COORDINATES_OBTAINED: 'gettingLocation',
                                COORDINATES_ERRORED: {
                                    geoErrored: {
                                        actions: ['focusGeoLocation'],
                                    },
                                },
                            },
                        },
                        gettingLocation: {
                            onEntry: ['matchGeoLocation'],
                            on: {
                                LOCATION_ERROR: {
                                    locationErrored: {
                                        actions: ['focusGeoLocation'],
                                    },
                                },
                            },
                        },
                        geoErrored: {
                            on: {
                                CHECK_USER_LOCATION: 'checkingAuth',
                            },
                        },
                        locationErrored: {},
                        geoDisabledMessage: {
                            onEntry: 'geoDisabledMessage',
                        },
                    },
                },
                hasLocation: {
                    on: {
                        LOCATION_SELECTED: 'locationSelected',
                        FOCUS_OUT: 'hasLocationHidden',
                        CLOSE: 'hasLocationHidden',
                    },
                },
                hasLocationHidden: {
                    on: {
                        FOCUS: 'hasLocation',
                    },
                },
                locationSelected: {},
                geoDisabled: {},
                geoDenied: {
                    on: {
                        FOCUS_OUT: 'geoDisabled',
                        CLOSE: 'geoDisabled',
                    },
                },
            },
        },
    },
};

const desktopStateChart = {
    initial: 'init',
    states: {
        init: {
            on: {
                USER_LOCATION_SUCCESS: 'idle',
                PET_TYPE_CLICK: 'performSearch',
            },
        },
        idle: {
            on: {
                PET_TYPE_CLICK: 'performSearch',
            },
        },
        resultsPetTypeClick: {
            on: {
                RESULT_CLICK: 'performSearch',
            },
        },
        performSearch: {
            onEntry: [
                'triggerPerformSearchAnalytics',
                'triggerLocationSuggestionClickAnalytics',
                'performSearch',
            ],
        },
    },
};

const breakpointsChart = {
    initial: 'idle',
    states: {
        idle: {
            onEntry: ['setInitBreakpoint'],
            on: {
                RESIZE: 'transientResize',
            },
        },
        transientResize: {
            on: {
                // Transient resize transition
                // https://github.com/davidkpiano/xstate/issues/43
                '': [
                    {
                        target: 'mobile',
                        cond: breakpointGuard([SM_BREAKPOINT, MD_BREAKPOINT]),
                    },
                    {
                        target: 'desktop',
                        cond: breakpointGuard([LG_BREAKPOINT, XL_BREAKPOINT]),
                    },
                ],
            },
        },
        mobile: {
            on: {
                RESIZE: {
                    desktop: {
                        actions: ['removeModal'],
                        cond: breakpointGuard([LG_BREAKPOINT, XL_BREAKPOINT]),
                    },
                },
            },
            ...mobileStateChart,
        },
        desktop: {
            on: {
                RESIZE: {
                    mobile: {
                        cond: breakpointGuard([SM_BREAKPOINT, MD_BREAKPOINT]),
                    },
                },
            },
            ...desktopStateChart,
        },
    },
};

const statechart = {
    parallel: true,
    states: {
        otherPets: {
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        OTHER_PETS_CLICK: {
                            show: {
                                cond: breakpointGuard([
                                    LG_BREAKPOINT,
                                    XL_BREAKPOINT,
                                ]),
                            },
                        },
                    },
                },
                show: {
                    onEntry: ['showOtherPetsDropdown'],
                    on: {
                        OTHER_PETS_CLICK: {
                            idle: {
                                actions: [
                                    'hideOtherPetsDropdown',
                                    'returnFocusOtherPetsBtn',
                                ],
                            },
                        },
                        FOCUS_OUT: {
                            idle: {
                                actions: ['hideOtherPetsDropdown'],
                            },
                        },
                        KEY_ESCAPE: {
                            idle: {
                                actions: [
                                    'hideOtherPetsDropdown',
                                    'returnFocusOtherPetsBtn',
                                ],
                            },
                        },
                        RESIZE: {
                            idle: {
                                actions: [
                                    'hideOtherPetsDropdown',
                                    'returnFocusOtherPetsBtn',
                                ],
                            },
                        },
                    },
                },
            },
        },
        main: {
            ...breakpointsChart,
        },
    },
};

export default statechart;
