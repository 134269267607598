import BaseAnalyticPropertiesModel from '../BaseAnalyticPropertiesModel';

export default class UserInfoPropertiesModel extends BaseAnalyticPropertiesModel {
    get defaultData() {
        return {
            id: '',
            city: '',
            state: '',
            country: '',
            postalCode: '',
            petType: '',
            petAge: '',
            petSize: '',
            petGender: '',
            petBreed: '',
            petsInHome: '',
            petNumber: '',
            petHouseholdNumber: '',
            petHouseholdDogNumber: '',
            petHouseholdCatNumber: '',
            petDietaryNeeds: '',
            petName: '',
            petSecondaryBreed: '',
            petShelterName: '',
            petShelterId: '',
        };
    }

    /**
     * Build user info property for id
     *
     * @returns {Object}
     */
    id() {
        return this._buildProperty(
            'userID',
            false,
            this._data.id || this.nullText
        );
    }

    /**
     * Build user info property for city
     *
     * @returns {Object}
     */
    city() {
        return this._buildProperty(
            'userCity',
            false,
            this._data.city || this.nullText
        );
    }

    /**
     * Build user info property for state
     *
     * @returns {Object}
     */
    state() {
        return this._buildProperty(
            'userState',
            false,
            this._data.state || this.nullText
        );
    }

    /**
     * Build user info property for country
     *
     * @returns {Object}
     */
    country() {
        return this._buildProperty(
            'userCountry',
            false,
            this._data.country || this.nullText
        );
    }

    /**
     * Build user info property for postal code
     *
     * @returns {Object}
     */
    postalCode() {
        return this._buildProperty(
            'userPostalCode',
            false,
            this._data.postalCode || this.nullText
        );
    }

    /**
     * Build user info property for pet type
     *
     * @returns {Object}
     */
    petType() {
        return this._buildProperty(
            'userPetType',
            87,
            this._data.petType || this.nullText
        );
    }

    /**
     * Build user info property for pet age
     *
     * @returns {Object}
     */
    petAge() {
        return this._buildProperty(
            'userPetAge',
            false,
            this._data.petAge || this.nullText
        );
    }

    /**
     * Build user info property for pet size
     *
     * @returns {Object}
     */
    petSize() {
        return this._buildProperty(
            'userPetSize',
            false,
            this._data.petSize || this.nullText
        );
    }

    /**
     * Build user info property for pet gender
     *
     * @returns {Object}
     */
    petGender() {
        return this._buildProperty(
            'userPetGender',
            false,
            this._data.petGender || this.nullText
        );
    }

    /**
     * Build user info property for pet breed
     *
     * @returns {Object}
     */
    petBreed() {
        return this._buildProperty(
            'userPetBreed',
            88,
            this._data.petBreed || this.nullText
        );
    }

    /**
     * Build user info property for pets in home
     *
     * @returns {Object}
     */
    petsInHome() {
        // DEV NOTE: petsInHome, petNumber, and petHouseholdNumber seem to be the same context
        return this._buildProperty(
            'userPetInHome',
            17,
            this._data.petsInHome || this.nullText
        );
    }

    /**
     * Build user info property for pet number
     *
     * @returns {Object}
     */
    petNumber() {
        // DEV NOTE: petsInHome, petNumber, and petHouseholdNumber seem to be the same context
        return this._buildProperty(
            'userPetNumber',
            38,
            this._data.petNumber || this.nullText
        );
    }

    petHouseholdNumber() {
        // DEV NOTE: petsInHome, petNumber, and petHouseholdNumber seem to be the same context
        return this._buildProperty(
            false,
            84,
            this._data.petHouseholdNumber || 0
        );
    }

    petHouseholdDogNumber() {
        return this._buildProperty(
            false,
            85,
            this._data.petHouseholdDogNumber || 0
        );
    }

    petHouseholdCatNumber() {
        return this._buildProperty(
            false,
            86,
            this._data.petHouseholdCatNumber || 0
        );
    }

    /**
     * Build user info property for pet dietary needs
     *
     * @returns {Object}
     */
    petDietaryNeeds() {
        return this._buildProperty(
            'userPetDietaryNeeds',
            false,
            this._data.petDietaryNeeds || this.nullText
        );
    }

    /**
     * Build user info property for pet name
     *
     * @returns {Object}
     */
    petName() {
        return this._buildProperty(
            'userPetName',
            99,
            this._data.petName || this.nullText
        );
    }

    /**
     * Build user info property for pet secondary breed
     *
     * @returns {Object}
     */
    petSecondaryBreed() {
        return this._buildProperty(
            'userPetSecondaryBreed',
            100,
            this._data.petSecondaryBreed || this.nullText
        );
    }

    /**
     * Build user info property for pet shelter name
     *
     * @returns {Object}
     */
    petShelterName() {
        return this._buildProperty(
            'userPetShelterName',
            101,
            this._data.petShelterName || this.nullText
        );
    }

    /**
     * Build user info property for pet shelter ID
     *
     * @returns {Object}
     */
    petShelterId() {
        return this._buildProperty(
            'userPetShelterID',
            102,
            this._data.petShelterId || this.nullText
        );
    }
}
