import React from 'react';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';

import { buildFieldId } from '../../util';

/**
 * Default form error summary list that renders a list of links that anchor to the fields
 * in a tcomb form
 * @param {Object} props
 * @returns {string}
 */
function FormErrorSummary(props) {
    const { errors, options, jsonSchema, iconConfig } = props;
    const formName = _get(jsonSchema, 'title', '');

    // TODO: Move this logic to the form factory and have it build these props for this component
    function _renderErrorItems(fieldErrors, fieldOptions = options.fields, path = []) {
        let items = [];

        Object.keys(fieldErrors).forEach(key => {
            const value = fieldErrors[key];

            if (Array.isArray(value)) {
                const pathWithCurrentKey = path.concat([key]);
                const fieldName = pathWithCurrentKey
                    .map((key, index) => index === 0 ? key : `[${key}]`)
                    .join('');
                const fieldLabel = _get(fieldOptions[key], 'label', _capitalize(key));

                const item = (
                    <li key={key}>
                        <a
                            href={`#${buildFieldId(
                                formName,
                                fieldName
                            )}`}
                            className="txt m-txt_colorError"
                        >
                            <span className="txt m-txt_underline m-txt_colorError">
                                {fieldLabel}
                                {':'}
                            </span>{' '}
                            {value.join(' ')}
                        </a>
                    </li>
                );

                items.push(item);
            } else if (typeof value === 'object') {
                items = items.concat(_renderErrorItems(
                    value,
                    fieldOptions[key].fields,
                    path.concat([key])
                ));
            }
        });

        return items;
    }

    return (
        <div className="media m-media_gutterSm">
            <div className="media-img">
                <span className="icon icon_18 m-icon_block m-icon_colorError">
                    <svg
                        role="presentation"
                        focusable="false"
                        aria-hidden="true"
                    >
                        <use xlinkHref={`#${iconConfig.xlinkHref}`} />
                    </svg>
                </span>
            </div>
            <div className="media-bd">
                {errors.global && Array.isArray(errors.global) && errors.global.length > 0 && (
                    <div className="txt m-txt_colorError">
                        <h3 tabIndex="-1">{errors.global.join(' ')}</h3>
                    </div>
                )}
                <ul>
                    {_renderErrorItems(errors.fields)}
                </ul>
            </div>
        </div>
    );
}

FormErrorSummary.propTypes = {
    errors: PropTypes.shape({
        global: PropTypes.array,
        fields: PropTypes.object,
    }).isRequired,
    options: PropTypes.object.isRequired,
    jsonSchema: PropTypes.object,
    iconConfig: PropTypes.object,
};

FormErrorSummary.defaultProps = {
    iconConfig: {
        xlinkHref: 'icon-circleAlert',
    },
};

export default FormErrorSummary;
