import BaseModel from './BaseModel';
import Utils from '../../../core/scripts/lib/wirey/Utils';

export default class SavedSearchModel extends BaseModel {
    /**
     * map of filter names
     *     left:  the naming format from the server
     *     right: our local (frontend) naming pattern
     */

    static SERVER_SAVED_SEARCH_FILTER_NAMES_TO_LOCAL_NAMES_MAP = {
        ages: 'age',
        attributes: 'attribute',
        behaviors: 'household',
        breeds: 'breed',
        coatLengths: 'coatLength',
        colors: 'color',
        daysOnPetfinder: 'daysOnPetfinder',
        distance: 'distance',
        genders: 'gender',
        location: 'location',
        name: 'animalName',
        numShelters: 'numShelters',
        sizes: 'size',
        species: 'species',
        type: 'animalType',
    };

    get defaultData() {
        return {
            id: '',
            name: '',
            filters: {},
            url: '',
        };
    }

    static fromSavedSearchAPIData(rawSavedSearch) {
        const model = new SavedSearchModel({
            id: rawSavedSearch.id,
            name: rawSavedSearch.name,
            filters: this._formatFilters(rawSavedSearch.parameters),
            url: rawSavedSearch.url,
        });

        return model;
    }

    static _formatFilters(rawFilters) {
        const mappedFilters = Utils.ObjectUtils.mapTransform(
            rawFilters,
            {},
            SavedSearchModel.SERVER_SAVED_SEARCH_FILTER_NAMES_TO_LOCAL_NAMES_MAP
        );

        // NOTE: casting things to their proper data type as we're being given strings for numbers
        mappedFilters.numShelters = Number(mappedFilters.numShelters);

        // additional formatting
        this._formatDistanceFilter(mappedFilters);
        this._formatDaysOnPetfinderFilter(mappedFilters);

        return mappedFilters;
    }

    static _formatDistanceFilter(filterObj) {
        const value = filterObj.distance;

        // if not present, no formatting necessary
        if (value == null) {
            return;
        }

        // if value is 'Anywhere', no formatting necessary
        if (value === 'Anywhere') {
            return;
        }

        // finally, we can assume we have a number (in string format) that we need to manipulate however we wish
        filterObj.distance = `${value} miles`;
    }

    /**
     * If the `daysOnPetfinder` key has value, append 'days on Petfinder` to the value.
     * Ex: results in a string like '7 days on Petfinder'.
     *
     * @static
     * @param {object} filterObj
     */
    static _formatDaysOnPetfinderFilter(filterObj) {
        const value = filterObj.daysOnPetfinder;

        if (value) {
            filterObj.daysOnPetfinder = `${value} days on Petfinder`;
        }
    }

    /**
     * @type {{ id: String, name: String, filters: { age: Array<String>, attribute: Array<String>, household: Array<String>, breed: Array<String>, coatLength: Array<String>, color: Array<String>, daysOnPetfinder: Number, distance: Number, gender: Array<String>, location: String, name: String, numShelters: Number, size:  Array<String>, species: String, type: String }, url: String, filterString: String }}
     */
    get raw() {
        const retObj = super.raw;

        retObj.filterString = this._getWeightedFiltersString(this._data.filters);

        return retObj;
    }

    /**
     * Generates a string of filters provided an input object formatted as documented
     *
     * @param {Object} filterLabelsObj
     */
    _getWeightedFiltersString(filterLabelsObj) {
        const arr = [];

        for (let i = 0; i < this._filterStringDisplayOrder.length; i++) {
            const filterName = this._filterStringDisplayOrder[i];
            const filterValue = filterLabelsObj[filterName];

            // NOTE: intentional use of `== null` here to capture null and undefined
            if (filterValue == null) {
                continue;
            }

            if (filterValue instanceof Array) {
                arr.push(...filterValue);
            } else if (filterName === 'numShelters') {
                if (filterValue === 0) {
                    continue;
                }

                arr.push(
                    filterValue === 1
                        ? `${filterValue} shelter`
                        : `${filterValue} shelters`
                );
            } else if (filterValue != null) {
                arr.push(filterValue);
            }
        }

        return arr.join(', ');
    }

    /**
     * This is the current, business approved, weighted order of all the search parameters
     */
    _filterStringDisplayOrder = [
        'species',
        'animalType',
        'breed',
        'location',
        'distance',
        'age',
        'size',
        'gender',
        'household',
        'coatLength',
        'color',
        'attribute',
        'daysOnPetfinder',
        'numShelters',
        'name',
    ];
}
