import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';

import {
    COUNTRY_CHOOSE_LABEL,
    COUNTRY_NOT_LISTED,
    COUNTRY_NOT_LISTED_VALUE,
} from '../../../../core/scripts/constants/geography';

import Config from '../../../../core/scripts/lib/Config';

/**
 * Initial country select options that should show before the loaded options
 */
const PREPEND_OPTIONS = [
    {
        name: COUNTRY_CHOOSE_LABEL,
        code: '',
    },
];

/**
 * Initial country select options that should show before the loaded options
 */
const APPEND_OPTIONS = [
    // Divider option
    {
        name: '-------------------',
        code: '',
        disabled: true,
    },
    {
        name: COUNTRY_NOT_LISTED,
        code: COUNTRY_NOT_LISTED_VALUE,
    },
];

/**
 * Custom element select wrapper that loads country options from the api on init.
 *
 * @extends PFElement
 */
export class PFDCCountrySelectElement extends PFElement {
    get value() {
        return this.countrySelect.value;
    }

    /**
     * Gets the value of data-form-error and converts to boolean
     *
     * @private
     * @returns {boolean}
     */
    get _isFormErrored() {
        return this.dataset.formError === 'true';
    }

    async onInit() {
        this.countrySelect = this.querySelector('[pf-select-select]');
        await this.getCountries();

        // only change to user country if there are NO form errors
        if (!this._isFormErrored) {
            this.setToUserCountry();
        }
    }

    /**
     * Get countries from api
     * @returns {Promise}
     */
    async getCountries() {
        if (this.countrySelect.selectedIndex < 0) {
            return;
        }

        const formConfig = {
            form: this.closest('form'),
            asyncComponent: this,
        };

        try {
            this.dispatchAction('form.async.component.start', formConfig);

            const response = await this.countryRepository.getCountries();
            const countries = response.countries;

            this.renderCountryOptions(countries);
        } catch (err) {
            throw new Error(`Could not get countries, ${err}`);
        } finally {
            this.dispatchAction('form.async.component.end', formConfig);
            return Promise.resolve();
        }
    }

    /**
     * Render country options into the country select
     * @param {array} countries
     */
    renderCountryOptions(countries) {
        this.countrySelect.disabled = true;

        const newCountryOptions = PREPEND_OPTIONS.concat(countries)
            .concat(APPEND_OPTIONS)
            .map(option => this.renderOption(option));

        // only update input if there are NO form errors
        if (!this._isFormErrored) {
            this.countrySelect.innerHTML = newCountryOptions.join('\n');
        }

        this.countrySelect.disabled = false;
    }

    /**
     * Handles rendering of single option
     * @param {object} option
     * @returns {string}
     */
    renderOption(option) {
        const optionTemplate = `
            <option
                value="${option.code}"
                ${option.disabled ? 'disabled' : ''}
            >
                ${option.name}
            </option>
        `;
        return optionTemplate;
    }

    /**
     * Sets the country select to the users user me api country if it exists
     */
    async setToUserCountry() {
        const userCountry = await Config.userCountryCode;

        if (!userCountry) {
            return;
        }

        const userCountryIndex = Array.from(
            this.countrySelect.options
        ).findIndex(option => option.value === userCountry);

        if (!userCountryIndex) {
            return;
        }

        this.countrySelect.selectedIndex = userCountryIndex;

        // Initial change event fired to trigger other components (optins)
        scheduleMicrotask(
            this.dispatchAction(this.getAttribute('on-change'), {
                srcEvent: {
                    target: this.countrySelect,
                },
            })
        );
    }
}

export default PFDCCountrySelectElement;
