import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { parseJsonAttribute } from '../../../../core/scripts/util/dom';

export const onEvented = Symbol('onEvented');
const dispatchAction = Symbol('dispatchAction');

const dispatcherMixin = Base =>
    class extends Base {
        events = []; // TODO: parseJSONAttribute
        get isDisabled() {
            return this.hasAttribute('disabled');
        }
        get dispatchOnLoad() {
            return this.hasAttribute('dispatch-on-load');
        }
        get actions() {
            return parseJsonAttribute(this, 'actions');
        }
        onInit() {
            if (super.onInit) {
                super.onInit(...arguments);
            }
            this.events.forEach(event =>
                this.addEventListener(event, this[onEvented])
            );
        }
        // [dispatchAction](action, event, element = null) {
        //     const detail = Object.assign({},
        //         {
        //             type: action,
        //             trigger: element || this,
        //             srcEvent: event,
        //         },
        //     );
        //     this.dispatchEvent(buildCustomEvent('action', detail));
        // }
        [onEvented](event) {
            if (this.isDisabled) {
                return;
            }
            // TODO: support single value
            this.actions.forEach(action => this.dispatchAction(action, event));
        }
    };

export default dispatcherMixin;

export class PFDCDispatcherElement extends dispatcherMixin(PFElement) {}

function getSelectValue(select) {
    return select.options[select.selectedIndex].value;
}

export class PFDCDispatcherSelectElement extends dispatcherMixin(PFElement) {
    events = ['change'];
    get dispatchOnValue() {
        return parseJsonAttribute(this, 'dispatch-on-value');
    }
    get nonValueActions() {
        return parseJsonAttribute(this, 'non-value-actions');
    }
    [onEvented](event) {
        // TODO: parse value from select
        //       check against value array
        //       dispatch if necesarry
        //       safeguard againsr null values and non array values
        // TODO: statemachine?
        if (this.disabled) {
            return;
        }
        if (this.hasAttribute('dispatch-on-value')) {
            const selectValue = getSelectValue(event.target);
            if (
                this.dispatchOnValue.some(
                    attributeValue => attributeValue === selectValue
                )
            ) {
                this.actions.forEach(action =>
                    this.dispatchAction(action, event)
                );
            } else {
                this.nonValueActions.forEach(action =>
                    this.dispatchAction(action, event)
                );
            }
        } else {
            this.actions.forEach(action => this.dispatchAction(action, event));
        }
    }
}

export class PFDCDispatcherInputElement extends dispatcherMixin(PFElement) {
    get dispatchOnValue() {
        return parseJsonAttribute(this, 'dispatch-on-value');
    }
    get nonValueActions() {
        return parseJsonAttribute(this, 'non-value-actions');
    }
    onInit() {
        super.onInit();
        // TODO: solve useCapture configuration
        this.addEventListener('blur', this[onEvented], true);
    }
    [onEvented](event) {
        if (this.disabled) {
            return;
        }
        // TODO: refactor
        if (this.hasAttribute('dispatch-on-empty')) {
            const value = event.target.value;
            if (value === '') {
                this.actions.forEach(action =>
                    this.dispatchAction(action, event)
                );
            } else {
                this.nonValueActions.forEach(action =>
                    this.dispatchAction(action, event)
                );
            }
        } else {
            if (this.hasAttribute('dispatch-on-value')) {
                const value = event.target.value;
                if (
                    this.dispatchOnValue.some(
                        attributeValue => attributeValue === value
                    )
                ) {
                    this.actions.forEach(action =>
                        this.dispatchAction(action, event)
                    );
                } else {
                    this.nonValueActions.forEach(action =>
                        this.dispatchAction(action, event)
                    );
                }
            } else {
                this.actions.forEach(action =>
                    this.dispatchAction(action, event)
                );
            }
        }
    }
}
