export default class LogicParser {
    static _loggingEnabled = false;

    static log(...stuff) {
        if (!this._loggingEnabled) {
            return;
        }

        console.log(...stuff);
    }

    static _handlers = [];

    static addHandler(handler) {
        if ('split' in handler === false || 'evaluate' in handler === false) {
            throw new Error(
                'Invalid handler; expected object with "split" and "evaluate" functions'
            );
        }

        this._handlers.push(handler);
    }

    static evaluateLogicString(str, customResolvers = null, handlers = null) {
        const arr = this._extractParentheses(str);

        this.log('De-parenthesized: ', arr);

        return this._evaluatePieces(
            arr,
            customResolvers,
            handlers || this._handlers
        );
    }

    static _extractParentheses(str, arr = [], counter = 0) {
        let workingStr = str;

        let wasMatch = false;
        workingStr = str.replace(
            /\([^()]+\)/g,
            (matchedStr, index, sourceString) => {
                wasMatch = true;
                arr.push(matchedStr);
                return this._getKey(counter++);
            }
        );

        // if a match was found, recurse and look for more
        if (wasMatch) {
            return this._extractParentheses(workingStr, arr, counter);
        }

        // no match; add updated full string to the end to finalize and return
        arr.push(workingStr);
        return arr;
    }

    static _evaluatePieces(arr, customResolvers, handlers) {
        const evaluated = [];

        for (let i = 0; i < arr.length; i++) {
            let item = arr[i];

            // sub in previous keys
            for (let j = 0; j < i; j++) {
                const key = this._getKey(j);
                item = item.replace(key, evaluated[j]);
            }

            this.log('=========================');
            this.log('Evaluating: ', arr[i]);
            evaluated[i] = this._evaluatePiece(
                item,
                evaluated,
                customResolvers,
                handlers
            );
            this.log(`Evaluated #${i}: ${evaluated[i]}`);
            this.log('=========================');
        }

        return evaluated[evaluated.length - 1];
    }

    static _getKey(index) {
        return `#${index}#`;
    }

    static _evaluatePiece(str, evaluated, customResolvers, handlers) {
        this.log('    -> Evaluating piece: ', str);

        // strip parentheses
        str = str.replace(/[()]/g, '');

        // strip spaces
        str = str.replace(/ /g, '');

        this.log('    -> Processed down: ', str);

        const result = this._executeHandlers(str, customResolvers, handlers);

        return result;
    }

    static _executeHandlers(
        input,
        customResolvers,
        handlers,
        handlerIndex = 0
    ) {
        if (handlerIndex > handlers.length - 1) {
            return null;
        }

        this.log(
            '        -> _executeHandlers: ',
            input,
            handlers[handlerIndex]
        );

        const inArr = Array.isArray(input) ? input : [input];

        // split
        const split = handlers[handlerIndex].split(input);

        let zzz;
        if (handlerIndex < handlers.length - 1) {
            this.log('            -> Recursing...');
            // recurse
            zzz = split.map(item =>
                this._executeHandlers(
                    item,
                    customResolvers,
                    handlers,
                    handlerIndex + 1
                )
            );
        } else {
            // TODO: pass in resolution function or something
            // end of handlers; find value
            zzz = split.map(item => Resolver.resolve(item, customResolvers));

            this.log('            -> Resolved values for ', split, ': ', zzz);
        }

        // evaluated
        const evaluated = handlers[handlerIndex].evaluate(zzz);

        this.log('            -> Evaluated: ', evaluated);

        return evaluated;
    }
}

// TODO: should these be moved out?

const exponentiationHandler = {
    split(str) {
        return str.split(/^/g);
    },
    evaluate(items) {
        return items.reduce((previous, current) => previous ** current);
    },
};

const multiplicationHandler = {
    split(str) {
        return str.split(/\*/g);
    },
    evaluate(items) {
        return items.reduce((previous, current) => previous * current);
    },
};

const divisionHandler = {
    split(str) {
        return str.split(/\//g);
    },
    evaluate(items) {
        return items.reduce((previous, current) => previous / current);
    },
};

const additionHandler = {
    split(str) {
        return str.split(/\+/g);
    },
    evaluate(items) {
        return items.reduce((previous, current) => previous + current);
    },
};

const subtractionHandler = {
    split(str) {
        return str.split(/[^0-9]-/g);
    },
    evaluate(items) {
        return items.reduce((previous, current) => previous - current);
    },
};

const andHandler = {
    split(str) {
        return str.split(/&&/g);
    },
    evaluate(items) {
        return items.reduce((previous, current) => previous && current);
    },
};

const orHandler = {
    split(str) {
        return str.split(/\|\|/g);
    },
    evaluate(items) {
        return items.reduce((previous, current) => previous || current);
    },
};

const strictEqualityHandler = {
    split(str) {
        return str.split(/===/g);
    },
    evaluate(items) {
        return items.reduce((previous, current) => previous === current);
    },
};

const strictInequalityHandler = {
    split(str) {
        return str.split(/!==/g);
    },
    evaluate(items) {
        return items.reduce((previous, current) => previous !== current);
    },
};

const equalityHandler = {
    split(str) {
        return str.split(/==/g);
    },
    evaluate(items) {
        // eslint-disable-next-line
        return items.reduce((previous, current) => previous == current);
    },
};

const inequalityHandler = {
    split(str) {
        return str.split(/!=/g);
    },
    evaluate(items) {
        // eslint-disable-next-line
        return items.reduce((previous, current) => previous != current);
    },
};

const lessThanOrEqual = {
    split(str) {
        return str.split(/<=/g);
    },
    evaluate(items) {
        // eslint-disable-next-line
        return items.reduce((previous, current) => previous <= current);
    },
};

const greaterThanOrEqual = {
    split(str) {
        return str.split(/>=/g);
    },
    evaluate(items) {
        // eslint-disable-next-line
        return items.reduce((previous, current) => previous >= current);
    },
};

const lessThan = {
    split(str) {
        return str.split(/</g);
    },
    evaluate(items) {
        // eslint-disable-next-line
        return items.reduce((previous, current) => previous < current);
    },
};

const greaterThan = {
    split(str) {
        return str.split(/>/g);
    },
    evaluate(items) {
        // eslint-disable-next-line
        return items.reduce((previous, current) => previous > current);
    },
};

// add handlers; order added determines how things are processed
LogicParser.addHandler(strictEqualityHandler);
LogicParser.addHandler(strictInequalityHandler);
LogicParser.addHandler(equalityHandler);
LogicParser.addHandler(inequalityHandler);
LogicParser.addHandler(lessThanOrEqual);
LogicParser.addHandler(greaterThanOrEqual);
LogicParser.addHandler(lessThan);
LogicParser.addHandler(greaterThan);

LogicParser.addHandler(exponentiationHandler);
LogicParser.addHandler(multiplicationHandler);
LogicParser.addHandler(divisionHandler);
LogicParser.addHandler(additionHandler);
LogicParser.addHandler(subtractionHandler);
LogicParser.addHandler(andHandler);
LogicParser.addHandler(orHandler);

// TODO: move to own file

class Resolver {
    static resolve(thing, customResolvers = null) {
        // TODO: can this every happen?
        if (typeof thing !== 'string') {
            console.info('********** Happened! **********');
            return thing;
        }

        switch (thing) {
            case true:
            case 'true':
                return true;
            case false:
            case 'false':
                return false;
            case null:
            case 'null':
                return null;
            case void 0:
            case 'undefined':
                return void 0;
        }

        // hereafter, it's expected that "thing" is a string of some sort

        // attempt number parse
        const n = parseFloat(thing);
        if (typeof n === 'number' && isFinite(n) && `${n}` === thing) {
            return n;
        }

        // attempt detection of string
        // TODO: extract regex
        if (/(['"])[^'"]*\1/.test(thing)) {
            return thing.substr(1, thing.length - 2);
        }

        // attempt external resolution
        return this._resolveUnknownStringOverloaded(thing, customResolvers);
    }

    static _resolveUnknownStringOverloaded(thing, customResolvers) {
        if (!customResolvers) {
            return void 0;
        }

        if (Array.isArray(customResolvers)) {
            return this._resolveUnknownStringArr(thing, customResolvers);
        }

        return this._resolveUnknownString(thing, customResolvers);
    }

    static _resolveUnknownStringArr(thing, customResolvers) {
        for (let i = 0; i < customResolvers.length; i++) {
            const resolvedValue = customResolvers[i](thing);

            if (resolvedValue !== void 0) {
                return resolvedValue;
            }
        }

        return void 0;
    }

    static _resolveUnknownString(thing, customResolver) {
        return customResolver(thing);
    }
}
