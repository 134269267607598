import { PFDCFormElement } from '../pfdc-form/element';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-login-form';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    // INPUT: `${ELEMENT_TAG_NAME}-input`,
};

/**
 * Stores reference to the global error string for this form
 * @type {string}
 * TODO: refactor to template
 */
const GLOBAL_ERROR_TEMPLATE = `There was an error logging you into your account. Please try again.
    If you continue to have trouble logging in, your account may be locked.
    Your account is locked for 30 minutes after 5 incorrect login attempts.
    <a href="/user/forgotten-password" class="txt m-txt_colorError m-txt_underline">
        Recover your password.</a>`;

/**
 * Custom element extension of the pfdc-form to handle the login of users.
 *
 * @extends PFDCFormElement
 */
export class PFDCLoginFormElement extends PFDCFormElement {
    /**
     * Initialize this component
     */
    onInit() {
        super.onInit();
        /**
         * Repository method to use for the request to the api
         * @param {FormData} formData
         * @return {Promise}
         */
        this.submitToEndpoint = async formData =>
            await this.userRepository.loginCheck(formData);

        // Manually set the next url field to the current url
        this.setNextUrlToCurrentUrl();
    }

    /**
     * Manually set the next url field to the current url
     */
    setNextUrlToCurrentUrl() {
        const nextField = this.form.querySelector('[name="next"]');
        nextField.value = window.location.href;
    }

    /**
     * Updates the screen reader status text
     * @param {string} txt
     */
    updateStatus(txt) {
        // noop
    }

    /**
     * Reset the form
     */
    resetForm() {
        super.resetForm();
        super.removeGlobalError();
    }

    /**
     * Handles an error response
     * @param {Object} response
     */
    handleSubmissionError(response) {
        this.dispatchAction('analytics', {
            eventId: 'Consumer018',
            authenticated: false,
        });
        if (response.redirect) {
            window.location = response.redirect;
            return;
        }

        // super.handleSubmissionError(response);
        super.displayGlobalError(GLOBAL_ERROR_TEMPLATE);

        // Focus errors
        scheduleMicrotask(() => {
            this.focusManager.focusFirstFocusable(this, false);
        });
    }

    handleSubmissionSuccess(response) {
        this.dispatchAction('analytics', {
            eventId: 'Consumer018',
            authenticated: true,
        });
        super.handleSubmissionSuccess(response);
    }

    /**
     * Handles the submission of the form
     * @param {Object} ev event object
     */
    async onSubmit(ev) {
        ev.preventDefault();

        if (typeof this.submitToEndpoint !== 'function') {
            throw new Error(
                'pfdc-form.onSubmit: submitToEndpoint needs to be a defined function'
            );
        }

        // Check if there are unfilled fields
        if (
            this.controls.some(
                control => control.required === true && control.value === ''
            )
        ) {
            this.dispatchAction('analytics', {
                eventId: 'Consumer018',
                authenticated: false,
            });
            super.displayGlobalError(GLOBAL_ERROR_TEMPLATE);
            super.displayFieldErrors(null);

            // Focus errors
            scheduleMicrotask(() => {
                this.focusManager.focusFirstFocusable(this, false);
            });
            return;
        }

        super.beforeSubmission();

        try {
            const response = await this.submitToEndpoint(this.formData);
            this.handleSubmissionSuccess(response);
        } catch (error) {
            this.handleSubmissionError(error);
        }

        super.afterSubmission();
    }
}

export default PFDCLoginFormElement;
