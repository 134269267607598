import { PFElement } from '../../../../core/scripts/elements/pf-element/element';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-header';

// Stores reference string selectors for various queryable items in this element
const SEL_OPEN_SEARCH_BTN = `${ELEMENT_TAG_NAME}-openSearchBtn`;
const SEL_CLOSE_SEARCH_BTN = `${ELEMENT_TAG_NAME}-closeSearchBtn`;
const SEL_SEARCH_BTN = `${ELEMENT_TAG_NAME}-searchBtn`;
const SEL_SEARCH_INPUT = `${ELEMENT_TAG_NAME}-searchInput`;
const SEL_SEARCH_FORM_CONTAINER = `${ELEMENT_TAG_NAME}-searchForm`;

// CSS class for search input
const CLASS_IS_SEARCHING = 'header_isSearching';

/**
 *
 * @extends PFElement
 */
export class PFDCHeaderElement extends PFElement {
    /**
     * Static reference to this elements' tag name
     * @type {string}
     */
    static get is() {
        return ELEMENT_TAG_NAME;
    }

    /**
     * An instance of the element is created or upgraded. Useful for initializing state,
     * settings up event listeners, or creating shadow dom.
     */
    constructor() {
        super();

        /**
         * Should this element close when a click event outside of it happens at the app level.
         *
         * @property deactivateOnClickOutside
         * @type {boolean}
         */
        this.deactivateOnClickOutside = true;
    }

    /**
     * Called every time the element is inserted into the DOM. Useful for running setup code,
     * such as fetching resources or rendering. Generally, you should try to delay work until
     * this time.
     */
    onInit() {
        /**
         * Reference to the search element
         * @type {HTMLElement}
         */
        this.openSearchBtn = this.querySelector(`[${SEL_OPEN_SEARCH_BTN}]`);
        this.closeSearchBtn = this.querySelector(`[${SEL_CLOSE_SEARCH_BTN}]`);
        this.searchBtn = this.querySelector(`[${SEL_SEARCH_BTN}]`);
        this.searchInput = this.querySelector(`[${SEL_SEARCH_INPUT}]`);
        this.searchForm = this.querySelector(`[${SEL_SEARCH_FORM_CONTAINER}]`);

        this.addEventListener('click', this.onClicked);
        this.addEventListener('blur', this.onBlurred, true);
    }

    /**
     * Handles logic on search input blur
     */
    searchBlurred() {
        if (!this.searchInput.value.length) {
            this.closeSearch();
        }
    }

    /**
     * Opens search input panel
     */
    openSearch() {
        this.searchForm.removeAttribute('aria-hidden');
        this.searchBtn.removeAttribute('tabindex');
        this.closeSearchBtn.removeAttribute('tabindex');
        this.searchInput.removeAttribute('tabindex');
        this.classList.add(CLASS_IS_SEARCHING);

        setTimeout(() => {
            this.focusManager.focusElement(this.searchInput);
        }, 0);
    }

    /**
     * Closes search input panel
     */
    closeSearch() {
        this.searchForm.setAttribute('aria-hidden', 'true');
        this.classList.remove(CLASS_IS_SEARCHING);
        this.searchBtn.setAttribute('tabindex', '-1');
        this.closeSearchBtn.setAttribute('tabindex', '-1');
        this.searchInput.setAttribute('tabindex', '-1');

        setTimeout(() => {
            this.focusManager.focusElement(this.openSearchBtn);
        }, 0);
    }

    /**
     * Handles focus events
     * @param  {Object} ev event object
     */
    onClicked(ev) {
        if (this.openSearchBtn === null) {
            return;
        }
        if (this.openSearchBtn.contains(ev.target)) {
            this.openSearch();
            return;
        }
        if (this.closeSearchBtn.contains(ev.target)) {
            this.closeSearch();
            return;
        }
    }

    /**
     * Handles blur events
     * @param  {Object} ev event object
     */
    onBlurred(ev) {
        if (ev.target === this.searchInput) {
            this.searchBlurred();
        }
    }
}

export default PFDCHeaderElement;
