import Repository from '../repository/repository';
import { ERROR_API } from '../../constants/errors';

import { postFormDataFromFormValue } from '../index';

/**
 * @type {Object}
 */
const USER_ENDPOINT_URLS = {
    LOGIN_CHECK: '/user/login_check/',
    REGISTER: '/user/register/',
    SOCIAL_LOGIN_CHECK: '/user/token_check/',
    SOCIAL_REGISTER: '/user/register/token/',
    SOCIAL_LINK: '/user/social-login/',
};

/**
 * Log user in
 * @param {Object} value
 * @returns {Object}
 */
export async function login(value) {
    return await postFormDataFromFormValue(
        USER_ENDPOINT_URLS.LOGIN_CHECK,
        value
    );
}

/**
 * Log social user in
 * @param {Object} value
 * @returns {Object}
 */
export async function socialLogin(value) {
    return await postFormDataFromFormValue(
        USER_ENDPOINT_URLS.SOCIAL_LOGIN_CHECK,
        value
    );
}

/**
 * Link social user with existing PF account
 * @param {Object} value
 * @returns {Object}
 */
export async function socialLoginLink(value) {
    return await postFormDataFromFormValue(
        USER_ENDPOINT_URLS.SOCIAL_LINK,
        value
    );
}

/**
 * Register new user
 * @param {Object} value
 * @returns {Object}
 */
export async function register(value) {
    return await postFormDataFromFormValue(USER_ENDPOINT_URLS.REGISTER, value);
}

/**
 * Register new user
 * @param {Object} value
 * @returns {Object}
 */
export async function socialRegister(value) {
    return await postFormDataFromFormValue(
        USER_ENDPOINT_URLS.SOCIAL_REGISTER,
        value
    );
}

/**
 * User Repository
 * @extends Repository
 */
export default class UserRepository extends Repository {
    /**
     * Register new users via ajax
     * @param {FormData} params
     * @return {Promise}
     */
    async register(params) {
        const response = await fetch('/user/register/', {
            method: 'POST',
            headers: {
                // Need this content-type here
                'X-Requested-With': 'XMLHttpRequest',
            },
            credentials: 'same-origin',
            body: params,
        });

        if (response.status === 400) {
            // For parsing error response and getting redirect
            return Promise.reject(await this.readResponse(response));
        }

        if (this.wasRequestSuccessful(response)) {
            return Promise.resolve(await this.readResponse(response));
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }

    /**
     * Login users via ajax
     * @param {FormData} params
     * @return {Promise}
     */
    async loginCheck(params) {
        const response = await fetch('/user/login_check/', {
            method: 'POST',
            headers: {
                // Need this content-type here
                'X-Requested-With': 'XMLHttpRequest',
            },
            credentials: 'same-origin',
            body: params,
        });

        if (response.status === 401) {
            // For parsing error response and getting redirect
            return Promise.reject(await this.readResponse(response));
        }

        if (this.wasRequestSuccessful(response)) {
            return Promise.resolve(await this.readResponse(response));
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }
}
