import React, { Component } from 'react';

class SocialCompleteRegistrationLayout extends Component {
    render() {
        return (
            <div className="loginModal">
                <div className="loginModal-section">
                    <h2
                        className="loginModal-section-hdg u-vr6x u-textCenter"
                        tabIndex="-1"
                    >
                        {'Complete your profile'}
                    </h2>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default SocialCompleteRegistrationLayout;
