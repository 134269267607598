import BaseAnalyticEventModel from '../BaseAnalyticEventModel';
import UserInfoPropertiesModel from './UserInfoPropertiesModel';

export default class UserInfoEventModel extends BaseAnalyticEventModel {
    get defaultAnalyticProperties() {
        return {
            id: true,
            city: true,
            state: true,
            country: true,
            postalCode: true,
            petType: true,
            petAge: true,
            petSize: true,
            petGender: true,
            petBreed: true,
            petsInHome: true,
            petNumber: true,
            petHouseholdNumber: true,
            petHouseholdDogNumber: true,
            petHouseholdCatNumber: true,
            petDietaryNeeds: true,
            petName: true,
            petSecondaryBreed: true,
            petShelterName: true,
            petShelterId: true,
        };
    }

    /**
     * prepareEvent
     *
     * @static
     * @param {Object} eventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {UserInfoEventModel}
     */
    static prepareEvent(
        eventData,
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const model = new UserInfoEventModel(
            this._mapEventData(eventData),
            analyticProperties,
            overrideAnalyticProperties
        );
        model.analyticObjects = eventData;

        return model;
    }

    _buildProperties(analytics, nullText) {
        return new UserInfoPropertiesModel(analytics, nullText);
    }
}
