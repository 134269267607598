import UserMeModel from '../models/UserMeModel';
import APIService from '../services/APIService';

class UserMeAPIService extends APIService {
    static url = '/user/me/';

    _cachedUserMe = null;

    /**
     * @returns {Promise<UserMeModel>}
     */
    async getUserMe() {
        if (this._cachedUserMe) {
            return this._cachedUserMe;
        }

        const options = {
            method: 'GET',
        };

        this._cachedUserMe = this.queryAndProcess(options).then(apiResponse => {
            const userMeModel = UserMeModel.fromServer(apiResponse.data);

            // Whenever we get a new Ansira UUID, set it in localStorage
            if (userMeModel.ansiraUUID !== null) {
                window.localStorage.setItem(
                    'profiles_uuid',
                    userMeModel.ansiraUUID
                );
            }

            return userMeModel;
        });

        return this._cachedUserMe;
    }

    /**
     * @returns {Promise<UserMeModel>}
     */
    refreshUserMe() {
        this._cachedUserMe = null;
        return this.getUserMe();
    }

    /**
     * Returns an "awaitable" value for whether or not the user is logged in
     * @returns {Promise<boolean>}
     */
    get isLoggedIn() {
        return this.getUserMe().then(userMeModel => {
            return userMeModel.isLoggedIn;
        });
    }
}

const userMeAPIService = new UserMeAPIService();
export default userMeAPIService;
