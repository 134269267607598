import detectFeatures from '../../../../dotcom/scripts/polyfills/detectFeatures';

export default async function initLegacyPolyfills() {
    if (!detectFeatures) {
        return import(
            /* webpackChunkName: "manual-chunk-polyfills" */
            '../../polyfills/polyfills-legacy'
        );
    }
}
