import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _noop from 'lodash/noop';

import {
    focusFirstFocusable,
    trapFocus,
    forceNVDADomUpdate,
} from '../../../../../core/scripts/lib/focusManager/index';

import {
    KEY_ESC,
    KEY_ESCAPE,
} from '../../../../../core/scripts/constants/keyboard';

/**
 * Sets up a portal that takes children props
 * @class Modal
 * @extends React.Component
 */
class Modal extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        extensionClasses: PropTypes.object,
        labelledbyId: PropTypes.string,
        onEscapeKeyDown: PropTypes.func,
        dataTestValue: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        extensionClasses: {},
        labelledbyId: null,
        onEscapeKeyDown: _noop,
        dataTestValue: '',
    };

    /**
     * @type {Object}
     */
    modalComponent = React.createRef();

    constructor(props) {
        super(props);

        this.appModalContainer = document.getElementById('modal-container');
        this.localModalContainer = document.createElement('div');
    }

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        this.appModalContainer.appendChild(this.localModalContainer);

        const modalContainer = this.modalComponent.current;

        requestAnimationFrame(() => {
            // Trap focus and get ref to a function to untrap focus
            this.untrapFocusHandle = trapFocus(modalContainer);

            setTimeout(() => {
                // Focus the first focusable element
                focusFirstFocusable(modalContainer);
            }, 500);
        });
    }

    componentWillUnmount() {
        // Untrap focus
        if (this.untrapFocusHandle) {
            this.untrapFocusHandle();
        }

        this.appModalContainer.removeChild(this.localModalContainer);

        forceNVDADomUpdate();
    }

    /**
     * Build classnames for modal
     * @method getClassNames
     * @private
     * @returns {string}
     */
    getClassNames() {
        const { extensionClasses } = this.props;

        return buildClassNames({
            modal: true,
            ...extensionClasses,
        });
    }

    /**
     * @private
     * @param {Event} ev
     */
    handleKeyDown = ev => {
        if ([KEY_ESC, KEY_ESCAPE].includes(ev.key)) {
            this.props.onEscapeKeyDown();
        }
    };

    render() {
        const { children, labelledbyId, dataTestValue } = this.props;

        const modal = (
            <div
                ref={this.modalComponent}
                className={this.getClassNames()}
                role="dialog"
                onKeyDown={this.handleKeyDown}
                aria-labelledby={labelledbyId}
                data-test={dataTestValue}
            >
                {children}
            </div>
        );

        return ReactDOM.createPortal(modal, this.localModalContainer);
    }
}

export default Modal;
