import BaseAnalyticPropertiesModel from '../BaseAnalyticPropertiesModel';

export default class SearchAndMatchPropertiesModel extends BaseAnalyticPropertiesModel {
    get defaultData() {
        return {
            step: '',
        };
    }

    /**
     * Build user info property for step
     *
     * @returns {Object}
     */
    step() {
        return this._buildProperty(false, 92, this._data.step || this.nullText);
    }
}
