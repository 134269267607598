import DATA_TYPES from './DataTypes';
import Utils from './Utils';

import IdMixin from './mixins/IdMixin';

class ActionParser {
    // TODO: cache regexes

    static debug = false;

    static log(...args) {
        if (!this.debug) {
            return;
        }

        console.log('(iii): ', ...args);
    }

    /**
     * A set of tests to run to parse parameters, run in the sequence they're defiend.
     *
     * Each test should return falsy if the test fails, or an object structured as follows:
     * {
     *     param: {
     *         type: DATA_TYPES.STRING,
     *         value: 'the parameter',
     *     },
     *     remaining: 'the rest of the string',
     * }
     *
     * NOTE: each test should look for leading white space as well as trailing
     * white space followed by an optional comma and more trailing whitespace
     */
    static paramTests = [
        /**
         * Number parameter checking
         */
        {
            test(inputString) {
                const regex = /^ *(\d+(\.\d+)?) *,? */;
                const result = ActionParser.getChunkAndSeek(inputString, regex, 1);
                if (!result.matchFound) {
                    return false;
                }

                return {
                    param: {
                        type: DATA_TYPES.NUMBER,
                        value: parseFloat(result.chunk),
                    },
                    remaining: result.remaining,
                };
            },
        },

        /**
         * String parameter checking
         */
        {
            test(inputString) {
                const regex = /^ *(["'])((?:[^\1\\]|\\.)*?)\1 *,? */;
                const result = ActionParser.getChunkAndSeek(inputString, regex, 2);
                if (!result.matchFound) {
                    return false;
                }

                return {
                    param: {
                        type: DATA_TYPES.STRING,
                        value: result.chunk,
                    },
                    remaining: result.remaining,
                };
            },
        },

        /**
         * JSON tester
         * TODO: WIP
         */
        // {
        //     test(inputString) {
        //         // const regex = /^("(((?=\\)\\(["\\\/bfnrt]|u[0-9a-fA-F]{4}))|[^"\\\0-\x1F\x7F]+)*")$/;
        //         const regex = /^("(((?=\\)\\(["\\\/bfnrt]|u[0-9a-fA-F]{4}))|[^"\\\0-\x1F\x7F]+)*")/;
        //         console.log('testing: ', inputString, '\t\t>>>>> ', inputString.match(regex));
        //         return false;
        //     }
        // },

        /**
         * Unquoted (null, undefined, or "magic" var that needs to be dereferenced from another object)
         */
        {
            test(inputString) {
                const regex = /^ *([a-zA-Z0-9_\-.$]+) *,? */;
                const result = ActionParser.getChunkAndSeek(inputString, regex, 1);
                if (!result.matchFound) {
                    return false;
                }

                return {
                    param: this.parseParam(result.chunk),
                    remaining: result.remaining,
                };
            },

            parseParam(param) {
                const retVal = {
                    type: DATA_TYPES.UNKNOWN,
                    value: null,
                };

                switch (param) {
                    case 'true':
                        retVal.type = DATA_TYPES.BOOL;
                        retVal.value = true;
                        break;
                    case 'false':
                        retVal.type = DATA_TYPES.BOOL;
                        retVal.value = false;
                        break;
                    case 'null':
                        retVal.type = DATA_TYPES.NULL;
                        retVal.value = null;
                        break;
                    case 'undefined':
                        retVal.type = DATA_TYPES.UNDEFINED;
                        retVal.value = void (0);
                        break;
                    case 'NaN':
                        retVal.type = DATA_TYPES.NAN;
                        retVal.value = NaN;
                        break;
                    default:
                        retVal.type = DATA_TYPES.UNKNOWN;
                        retVal.value = param;
                }

                return retVal;
            },
        },
    ];

    static getChunkAndSeek(inputString, regex, matchIndexForChunk = 0, matchIndexForLength = 0) {
        const match = inputString.match(regex);
        let chunk = '';
        let remaining = inputString;
        const matchFound = match != null;;

        if (matchFound) {
            chunk = match[matchIndexForChunk];
            remaining = inputString.substr(match[matchIndexForLength].length);
        }

        return { matchFound, match, chunk, remaining };
    }

    static _getParams(inputString) {
        const params = [];
        let param;
        let result;
        let remaining = inputString;

        // TODO: fix this
        let infinteLoopSafety = 100;

        do {
            // check for closing paren (end of params)
            // TODO:find a better way to determine when we've found the last param
            if (remaining.charAt(0) === ')') {
                break;
            }

            // get params
            result = this._getParam(remaining);

            this.log(`Param parse result: \`${remaining}\``, result);

            if (result) {
                remaining = result.remaining;
                params.push(result.param);
            }
        } while (result && infinteLoopSafety-- > 0);

        return { params, remaining };
    }

    static _getParam(inputString) {
        for (let i = 0; i < this.paramTests.length; i++) {
            const result = this.paramTests[i].test(inputString);

            this.log('Param test result: => ', result);

            if (result) {
                return result;
            }
        }

        return false;
    }

    static evalActionString(str) {
        this.log('Evaluating ', str);

        const commands = [];

        let chunk;
        let params;
        let commandString;
        let remaining = str;
        let matchFound;
        let match;

        let command;

        let infinteLoopSafety = 100;
        while (remaining.length > 0 && infinteLoopSafety-- > 0) {
            // reset commandString for debugging purposes
            commandString = remaining;
            command = {};

            this.log('Looking for function address...');

            // get fn path
            ({ matchFound, chunk, remaining } = this.getChunkAndSeek(
                remaining,
                / *([a-zA-Z0-9_\-.$]+) *\(/,
                1
            ));

            if (!matchFound) {
                throw new Error(`Parser.evalActionString(${str}) failed; invalid or malformed function address`);
            }

            command.fnPath = chunk;
            this.log(`Function address found... (${chunk})`);

            this.log('Looking for function params...');
            ({ params, remaining } = this._getParams(remaining));

            this.log('Params found: ', params);
            this.log('Remaining string: ', remaining);

            command.params = params;

            // getParams() returns once it can no longer find a param it knows how to handle at
            // this point, we should find at least a closing )... otherwise, something isn't right

            // ensure a closing ), and look for additional commands
            ({ matchFound, remaining } = this.getChunkAndSeek(remaining, / *\) *;? */));

            if (!matchFound) {
                throw new Error(`Malformed command provided: ${commandString}`);
            }


            // add to commands arr
            commands.push(command);
        }

        return commands;
    }

    /**
     *
     * @param {Array<string>} actions Array of actions to execute
     * @param {Object} sourceContexts Object of contexts; Example:
     *     {
     *         $mixin: mixin, // reference to the mixin from which this action originated, if applicable
     *         $element: element // a reference to the DOM element from which this action originated, if applicable
     *     }
     * @param {Event} eventObj The event object
     */
    static executeActions(actions, sourceContexts, eventObj) {
        const magicParams = {
            $event: eventObj,
        };

        for (let i = 0; i < actions.length; i++) {
            this._executeAction(actions[i], sourceContexts, magicParams);
        }
    }

    static _executeAction(actionDef, sourceContexts, magicParams) {
        // attempt to handle via magic actions
        const handled = this._processMagicActions(actionDef.fnPath, sourceContexts, magicParams);
        if (handled) {
            return;
        }

        const result = this.getChunkAndSeek(actionDef.fnPath, /([^.]+)\./, 1);
        const executionTarget = result.chunk;
        const subpath = result.remaining;

        if (!executionTarget) {
            throw new Error('No execution target found; are you missing a target? "target.function()"');
        }

        // event wasn't handled above; attempt to find objects for execution
        const objectsForExecution = this._getObjectsForExecution(executionTarget, sourceContexts, subpath);

        if (!objectsForExecution.length) {
            throw new Error(`Unable to call function \`${actionDef.fnPath}\`: subpath "${subpath}" not found on "${executionTarget}"`);
        }

        // organize params
        const evaluatedParams = this._getParamsForAction(actionDef, sourceContexts, magicParams);
        this._executeActionOnElements(actionDef, objectsForExecution, evaluatedParams, sourceContexts);
    }

    static _processMagicActions(fullFnPath, sourceContexts, magicParams) {
        const magicActions = {
            $event: {
                preventDefault() {
                    magicParams.$event.preventDefault();
                },
                stopPropagation() {
                    magicParams.$event.stopPropagation();
                },
            },
        };

        const fn = Utils.ObjectUtils.access(fullFnPath, magicActions);
        if (!fn) {
            return false;
        }

        fn();

        return true;
    }

    static _getObjectsForExecution(elementDescriptor, sourceContexts, subpath) {
        const magicTargets = this._findMagicTargets(elementDescriptor, sourceContexts, subpath);
        if (magicTargets.length) {
            return magicTargets;
        }

        // default
        return IdMixin.get(elementDescriptor);
    }

    static _findMagicTargets(magicTarget, sourceContexts, subpath) {
        if (magicTarget === '$element') {
            const dereferenced = Utils.ObjectUtils.dereference(subpath, sourceContexts.$element);

            return dereferenced.success
                ? [sourceContexts.$element]
                : [];
        } else if (magicTarget === '$closest') {
            return this._getClosestElementWithSubpath(sourceContexts.$element, subpath);
        } else if (magicTarget === 'window') {
            return [window];
        }

        return [];
    }

    static _getClosestElementWithSubpath(origin, subpath) {
        const retArr = [];

        const result = Utils.HTMLElementUtils.findAncestorContainingAddress(subpath, origin);
        if (result) {
            retArr.push(result);
        }

        return retArr;
    }

    static _getParamsForAction(actionDef, localContexts, magicParams) {
        return actionDef.params.map(paramItem => {
            // TODO: better name for 'unknown' ?
            if (paramItem.type === DATA_TYPES.UNKNOWN) {
                // look for param within magicParams
                if (paramItem.value in magicParams) {
                    return magicParams[paramItem.value];
                }

                // attempt to resolve on magicParams
                const magicParamsDereferenced = Utils.ObjectUtils.dereference(paramItem.value, magicParams);
                if (magicParamsDereferenced.success) {
                    return magicParamsDereferenced.result;
                }

                // attempt to resolve on the local contexts
                const localContextDereferenced = Utils.ObjectUtils.dereference(paramItem.value, localContexts);
                if (localContextDereferenced.success) {
                    return localContextDereferenced.result;
                }

                // finally, attempt to dereference value on element found within IdMixin
                return IdMixin.dereference(paramItem.value);
            }

            return paramItem.value;
        });
    }

    static _executeActionOnElements(actionDef, objectsForExecution, evaluatedParams, sourceContexts) {
        const result = this.getChunkAndSeek(actionDef.fnPath, /([^.]+)./);
        const subFnPath = result.remaining;

        for (let i = 0; i < objectsForExecution.length; i++) {
            const dereferenced = Utils.ObjectUtils.dereference(subFnPath, objectsForExecution[i]);

            const resultFn = dereferenced.resultFn;
            if (!resultFn) {
                const executionTargetDescriptor = ('outerHTML' in objectsForExecution[i])
                    ? Utils.HTMLElementUtils.getElementShortHtml(objectsForExecution[i])
                    : objectsForExecution[i];
                throw new Error(`Unable to call function \`${actionDef.fnPath}\` on execution target \`${executionTargetDescriptor}\`: item in path does not exist`);
            }

            const callArgs = evaluatedParams;
            if (this._shouldAppendSourceContexts(subFnPath)) {
                callArgs.push(sourceContexts);
            }

            resultFn(...callArgs);
        }
    }

    static FUNCTION_PATHS_TO_IGNORE = [
        'classList.toggle',
    ];

    static _shouldAppendSourceContexts(subFnPath) {
        return this.FUNCTION_PATHS_TO_IGNORE.indexOf(subFnPath) === -1;
    }
}

export default ActionParser;
