const lazyLoadState = {
    key: 'lazyLoadState',
    initial: 'idle',
    states: {
        idle: {
            on: {
                ENABLED: 'lazyLoadEnabled',
                DISABLED: 'lazyLoadDisabled',
            },
        },
        lazyLoadEnabled: {
            initial: 'checkingIfShouldLoad',
            on: {
                DISABLED: 'lazyLoadDisabled',
            },
            states: {
                checkingIfShouldLoad: {
                    onEntry: 'checkIfShouldLoad',
                    on: {
                        LAZY_LOADING_CRITERIA_NOT_MET: 'notLoading',
                        LAZY_LOADING_CRITERIA_MET: 'lazyLoading',
                    },
                },
                notLoading: {
                    on: {
                        UPDATED: 'checkingIfShouldLoad',
                    },
                },
                lazyLoading: {
                    onEntry: ['fetchData'],
                    on: {
                        DATA_LOADED: 'dataHasLoaded',
                        DATA_ERRORED: 'dataFailedToLoad',
                    },
                },
                dataHasLoaded: {
                    onEntry: ['fadeInContent', 'disabled'],
                },
                dataFailedToLoad: {
                    onEntry: 'errorHandling',
                },
                networkErrored: {
                    on: {
                        RETRY: 'lazyLoading',
                    },
                },
                missingData: {
                    on: {
                        RETRY: 'lazyLoading',
                    },
                },
            },
        },
        lazyLoadDisabled: {
            onEntry: ['disableComponent'],
        },
    },
};

export default lazyLoadState;
