/**
 * # Focus Manager
 *
 * `FocusManager` provides an interface for managing focus in the application.
 * It uses ally.js under the hood.
 *
 * Its main functions are:
 *
 * - find and focus the first focusable element in a DOM subtree
 * - trap focus within one or multiple subtrees and release when finished
 * - monitor focus and reset if focus becomes applied to an unfocusable selector
 *
 * Public Methods:
 * - trapFocus: Focus is restricted to be within an element or set of elements.
 * - untrapFocus: Allow the entire app to be focusable again
 * - focusFirstFocusable: Find the first focusable element within a DOM subtree and focus it
 */

import queryFocusable from 'ally.js/query/focusable';
import queryTabbable from 'ally.js/query/tabbable';
import maintainDisabled from 'ally.js/maintain/disabled';
import maintainHidden from 'ally.js/maintain/hidden';
import maintainTabFocus from 'ally.js/maintain/tab-focus';
import isFocusable from 'ally.js/is/focusable';
import isTabbable from 'ally.js/is/tabbable';
import getActiveElement from 'ally.js/get/active-element';
import getFocusTarget from 'ally.js/get/focus-target';

import platform from 'platform';

import assert from './assert';
import { isElement } from '../util/dom';

/**
 * Test for low performance device/platform
 * @returns {bool}
 */
const isLowPerformancePlatform = () => {
    const osStr = platform.os.toString();

    return (
        (platform.name === 'IE' && Number(platform.version) < 12) ||
        osStr.includes('iOS')
    );
};

export default class FocusManager {
    constructor() {
        /**
         * Reference to a status message container for the app, using this for app level aria
         * notifications
         * @type {HTMLElement}
         */
        this.appStatusMessageContainer = document.querySelector(
            '[pf-app-status-message]'
        );

        /**
         * Ally handle for tabFocus
         * @type {}
         */
        this.maintainTabFocusHandle = null;

        /**
         * Trapped elements for IE11
         * @type {Array}
         */
        this.trappedElements = [];
    }

    /**
     * Alternative strategy for IE due to performance.
     *
     * @method trapFocusIEIOS
     * @param {Array} filterElements
     */
    trapFocusIEIOS(filterElements) {
        const tabbables = filterElements.map(element => {
            return queryFocusable({
                context: element,
                includeContext: false,
                strategy: 'quick',
            });
        });

        const svgs = Array.from(document.querySelectorAll('svg'));

        this.trappedElements = queryFocusable({
            context: 'body',
            includeContext: false,
            strategy: 'quick',
        })
            .concat(svgs)
            .map(element => {
                element._tabindex = element.getAttribute('tabindex');
                element.setAttribute('tabindex', '-1');
                if (element.tagName === 'SVG' || element.tagName === 'svg') {
                    if (
                        element.hasAttribute('focusable') &&
                        element.getAttribute('focusable') === 'false'
                    ) {
                        element._focusableFalse = true;
                    } else {
                        element.setAttribute('focusable', 'false');
                    }
                }
                return element;
            });

        tabbables.forEach(tabbable => {
            tabbable.forEach(element => {
                if (element._tabindex) {
                    element.setAttribute('tabindex', element._tabindex);
                }
                element.setAttribute('tabindex', '0');
            });
        });
    }

    untrapFocusIEIOS() {
        // Check if trapped elements exists
        if (!this.trappedElements.length) {
            return;
        }

        const trappedElements = this.trappedElements;
        if (trappedElements.length === 0) {
            return;
        }

        for (let i = 0; i < trappedElements.length; i++) {
            const element = trappedElements[i];
            if (element._tabindex) {
                element.setAttribute('tabindex', element._tabindex);
            } else {
                element.removeAttribute('tabindex');
            }
            if (element.tagName === 'SVG' || element.tagName === 'svg') {
                if (!element._focusableFalse) {
                    element.removeAttribute('focusable');
                }
            }
        }

        this.trappedElements = [];
    }

    /**
     * Focus is restricted to be within an element or set of elements.
     * @method trapFocus
     * @param {HTMLElement|Array} filterElements - elements to trap focus wihin
     * @public
     */
    trapFocus(filterElements) {
        if (!filterElements) {
            return;
        }

        if (Array.isArray(filterElements)) {
            filterElements
                .filter(el => el)
                .forEach(el => {
                    assert.type(
                        isElement(el) || el instanceof NodeList,
                        'Expected element to be an HTMLElement or NodeList'
                    );
                });
        } else {
            assert.type(
                isElement(filterElements) || filterElements instanceof NodeList,
                'Expected element to be an HTMLElement or NodeList'
            );
        }

        this.filterElementsArray = Array.isArray(filterElements)
            ? filterElements
            : [filterElements];

        // !!! IMPORTANT: DO NOT TRAP FOCUS IN APP STATUS CONTAINER !!!
        // Because aria-hidden will be applied to it and event status msg's will not be read
        this.filterElementsArray.push(this.appStatusMessageContainer);

        if (isLowPerformancePlatform()) {
            setTimeout(() => this.trapFocusIEIOS(this.filterElementsArray), 0);
            return;
        }

        this.untrapFocus();

        // Disable all unfiltered elements, ie tab focusable
        this.trapFocusHandle = maintainDisabled({
            filter: this.filterElementsArray,
        });

        // Add aria-hidden to all unfiltered elements, ie screen readers
        this.maintainHiddenHandle = maintainHidden({
            filter: this.filterElementsArray,
        });
    }

    /**
     * Traps tab focus in the tabsequence.
     * Prevents the browser from shifting focus to its UI.
     * Untrap with untrapFocus().
     *
     * @method trapTabFocus
     * @param {HTMLElement} context
     * @public
     */
    trapTabFocus(context) {
        if (isLowPerformancePlatform()) {
            setTimeout(() => this.trapFocusIEIOS([context]), 0);
            return;
        }

        this.untrapFocus();

        this.maintainTabFocusHandle = maintainTabFocus({
            context,
        });
    }

    /**
     * Release any trapped focus.
     * Including trapTabFocus, since we only ever want to use one or the other.
     *
     * @method untrapFocus
     * @public
     * @return {array} previously trapped elements in order to retrap if necesarry
     */
    untrapFocus() {
        if (!isLowPerformancePlatform()) {
            if (this.trapFocusHandle !== undefined) {
                this.trapFocusHandle.disengage();
            }

            if (this.maintainHiddenHandle !== undefined) {
                this.maintainHiddenHandle.disengage();
            }

            if (this.maintainTabFocusHandle) {
                this.maintainTabFocusHandle.disengage();
            }
        } else {
            this.untrapFocusIEIOS();
        }

        return this.filterElementsArray;
    }

    /**
     * Finds the first focusable element within a Node and focuses it
     *
     * @method focusFirstFocusable
     * @param {Node}
     * @public
     */
    focusFirstFocusable(element, includeContext = true) {
        assert.type(
            isElement(element),
            'Expected the argument to be an HTMLElement.'
        );

        const focusableElements = queryFocusable({
            includeContext,
            context: element,
            strategy: 'quick',
        });

        if (focusableElements.length) {
            focusableElements[0].focus();
        }

        return focusableElements;
    }

    /**
     * Finds the first focusable element within a Node but does not focus it
     *
     * @method getFirstFocusable
     * @param {Node}
     * @public
     */
    getFirstFocusable(element, includeContext = true) {
        assert.type(
            isElement(element),
            'Expected the argument to be an HTMLElement.'
        );

        const focusableElements = queryFocusable({
            includeContext,
            context: element,
            strategy: 'quick',
        });

        return focusableElements[0];
    }

    /**
     * Finds the next focusable element based off another node
     *
     * @method focusFocusable
     * @param {Node} element
     * @public
     */
    focusFirstFocusableDirection(element, direction = 'after') {
        assert.type(
            isElement(element),
            'Expected the argument to be an HTMLElement.'
        );

        let documentPosition;
        switch (direction) {
            case 'before':
                documentPosition = Node.DOCUMENT_POSITION_FOLLOWING;
                break;
            case 'after':
            default:
                documentPosition = Node.DOCUMENT_POSITION_FOLLOWING;
                break;
        }

        const focusableElements = queryFocusable({
            context: document.documentElement,
            strategy: 'quick',
        });

        const filteredElements = focusableElements.filter(
            item => element.compareDocumentPosition(item) === documentPosition
        );

        if (filteredElements.length) {
            filteredElements[0].focus();
        }

        return filteredElements;
    }

    /**
     * Focuses the passed element.
     *
     * @method focusElement
     * @param {Node}
     * @public
     */
    focusElement(el) {
        if (isFocusable(el)) {
            el.focus();
        }
    }

    /**
     * Returns if the passed element is focusable.
     *
     * @method isElementFocusable
     * @param {Node}
     * @public
     */
    isElementFocusable(el) {
        return isFocusable(el);
    }

    /**
     * Returns if the passed element is tabbable.
     *
     * @method isElementTabbable
     * @param {Node}
     * @public
     */
    isElementTabbable(el) {
        return isTabbable(el);
    }

    /**
     * Gets the currently active element.
     *
     * @method getActiveElement
     * @param {HTMLElement} context element to find the active element within
     * @public
     */
    getActiveElement(context = document) {
        return getActiveElement({
            context,
        });
    }

    /**
     * Identifies the element that would get focus upon click.
     *
     * @method getFocusTarget
     * @param {HTMLElement} The element to start searching from.
     * @public
     */
    getFocusTarget(context) {
        return getFocusTarget({
            context,
        });
    }

    /**
     * Returns the tabbable elements within a given context element.
     *
     * @method queryTabbable
     * @param {HTMLElement} context element to query within for tabbable elements
     * @public
     */
    queryTabbable(context) {
        return queryTabbable({
            context,
            includeContext: false,
            strategy: 'quick',
        });
    }
}
