import { socialLogin } from '../../../../../../core/scripts/repositories/users/users';

import Config from '../../../../../../core/scripts/lib/Config';

import socialLoginStateController from '../controller/SocialLoginStateController';
import GoogleAPIService from './GoogleAPIService';
import FacebookAPIService from './FacebookAPIService';

class SocialLogin {
    /**
     * @type {Function}
     * On successful social sign in, attempt to login to PF
     * @param {string} token
     * @param {Object} profile
     * @param {string} provider
     */
    signInToPF = async (token, profile, provider) => {
        socialLoginStateController.patchState({
            token,
        });

        try {
            const response = await socialLogin({
                provider,
                token,
            });

            if (response.props.success === true) {
                this._handleSignInSuccess(response);
                return;
            }

            return response.props;
        } catch (e) {
            const response = e.props;

            if (response.type === 'invalid_user') {
                // if not logged in and not matching an existing user
                this._handleCompleteRegistration(token, profile, provider);
            } else if (response.type === 'invalid_credentials') {
                // if not logged in and matching an existing user
                this._handleLinkAccount(token, profile, provider);
            } else {
                // if the token could not be validated or anything else goes wrong
                // eslint-disable-next-line quotes
                this._handleGenericError("couldn't sign in to PF");
            }
        }
    };

    /**
     * @type {Function}
     * @param {string} response
     * Triggered when successfully logged in to PF
     */
    _handleSignInSuccess = response => {
        socialLoginStateController.authStatus = {
            isLoggedIn: true,
            redirect: response.props.redirect,
        };
    };

    /**
     * @type {Function}
     * Triggered when something non-specific goes wrong with the APIs
     * @param {string} error
     */
    _handleGenericError = error => {
        socialLoginStateController.patchState({
            step: 'error',
            error,
        });
    };

    /**
     * @type {Function}
     * Pass data to link user flow
     * @param {string} token
     * @param {Object} user
     * @param {Object} provider
     */
    _handleLinkAccount = (token, user, provider) => {
        socialLoginStateController.patchState({
            step: 'link',
            user: {
                user,
                token,
                provider,
            },
        });
    };

    /**
     * @type {Function}
     * Pass data to complete registration flow
     * @param {string} token
     * @param {Object} profile
     *  @param {Object} provider
     */
    _handleCompleteRegistration = (token, profile, provider) => {
        const userDetails = {
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.email,
            image: profile.image,
        };

        socialLoginStateController.patchState({
            step: 'register',
            user: {
                user: userDetails,
                token,
                provider,
            },
        });
    };
}

export const AVAILABLE_PROVIDERS = ['facebook', 'google'];

let socialLoginService;
if (typeof window !== 'undefined' && window.PF) {
    if (!window.PF.socialLoginService) {
        socialLoginService = new SocialLogin();
        if (!Config.userAuthed) {
            /**
             * Kick off the authorization events if not logged in.
             */
            GoogleAPIService._getGoogleAuth();

            FacebookAPIService._getFacebookAuth();
        }

        window.PF.socialLoginService = socialLoginService;
    } else {
        socialLoginService = window.PF.socialLoginService;
    }
}

export default socialLoginService;
