/**
 * The GlobalCssHelpers class provides access to a variety of helpers to
 * aid in setting custom CSS properties (native vars) on the document element
 * @class GlobalCssHelpers
 */
export default class GlobalCssHelpers {
    /**
     * Remove a previously set custom property
     * @method removeProp
     * @param {String} propName
     */
    static removeProp(propName) {
        document.documentElement.style.removeProperty(`--${propName}`);
    }

    /**
     * Set a custom property
     * @method setprop
     * @param {String} propName
     * @param {String} propValue
     */
    static setprop(propName, propValue) {
        document.documentElement.style.setProperty(`--${propName}`, propValue);
    }

    /**
     * Set the current scroll position as a custom property
     * @method setScrollTop
     * @param {String} propName
     */
    static setScrollTop(propName = 'scrollTop') {
        document.documentElement.style.setProperty(
            `--${propName}`,
            `${window.pageYOffset}px`
        );
    }
}
