import Cookies from 'js-cookie';

/**
 * The name of the location cookie
 * @const
 * @type {string}
 */
const COOKIE_NAME = 'user_location_slug';

/**
 * Duration in days before the cookie expires
 * @const
 * @type {number}
 */
const COOKIE_EXPIRATION = 7;

class LocationCookieService {
    /**
     * Set location cookie with location slug
     * @param {string} locationSlug
     */
    setCookie(locationSlug) {
        Cookies.set(COOKIE_NAME, locationSlug, {
            expires: COOKIE_EXPIRATION,
            path: '/',
        });
    }
};

const locationCookieService = new LocationCookieService();

export default locationCookieService;
