import { PFElement } from '../../../../core/scripts/elements/pf-element/element';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-select-nav';

/**
 * # `pfdc-select-nav` Element
 *
 * The select nav allows for custom-select, dropdown navigation.
 * Clicking on an option sends you to the page passed in through
 * the option value (see markup example).
 *
 * MARKUP EXAMPLE
 * ---
 *<pfdc-select-nav class="select" current-location-placeholder hidden-label>
 *      <label pf-select-nav-label for="Select_1" class="select-label"></label>
 *      <select pf-select-nav-select id="Select_1" class="select-select">
 *          <option value="/user/about-me/">About Me</option>
 *          <option value="/user/account-settings/">Account Settings</option>
 *          <option value="/user/email-preferences/">Email Preferences</option>
 *          <option value="/user/saved-searches/">Saved Searches</option>
 *      </select>
 *</pfdc-select-nav>
 * ---
 *
 * @class PFDCSelectNavElement
 * @extends PFElement
 */
export class PFDCSelectNavElement extends PFElement {
    onInit() {
        this.label = this.querySelector(`[${ELEMENT_TAG_NAME}-label]`);
        this.select = this.querySelector(`[${ELEMENT_TAG_NAME}-select]`);
        this.addEventListener('change', this.onChanged);
    }

    /**
     * Handle change events in this component
     * @method onChanged
     * @param {object} ev
     */
    onChanged(ev) {
        if (ev.target === this.select) {
            window.location.assign(ev.target.value);
        }
    }
}

export default PFDCSelectNavElement;
