import BaseAnalyticEventModel from '../BaseAnalyticEventModel';
import UserLoginRegistrationPropertiesModel from './UserLoginRegistrationPropertiesModel';

// TODO: Test with working analytic events
export default class UserLoginRegistrationEventModel extends BaseAnalyticEventModel {
    get defaultAnalyticProperties() {
        return {
            authenticationStatus: true,
            loginProvider: true,
            initiatingSite: true,
            initiatingUI: true,
            pointOfAbandonment: true,
            errorMessage: true,
            currentScreen: true,
        };
    }

    /**
     * prepareEvent
     *
     * @static
     * @param {Object} eventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {UserLoginRegistrationEventModel}
     */
    static prepareEvent(
        eventData,
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const model = new UserLoginRegistrationEventModel(
            this._mapEventData(eventData),
            analyticProperties,
            overrideAnalyticProperties
        );
        model.analyticObjects = eventData;

        return model;
    }

    _buildProperties(analytics, nullText) {
        return new UserLoginRegistrationPropertiesModel(analytics, nullText);
    }
}
