import BaseAnalyticPropertiesModel from '../BaseAnalyticPropertiesModel';

export default class AnimalPropertiesModel extends BaseAnalyticPropertiesModel {
    get defaultData() {
        return {
            organizationDisplayId: '',
            organizationName: '',
            arrivalDate: '',
            type: '',
            secondaryBreed: '',
            attributes: '',
            postalCode: '',
            photoCount: '',
            videoService: '',
            species: '',
            primaryBreed: '',
            age: '',
            sex: '',
            size: '',
            id: '',
            name: '',
            primaryColor: '',
            adoptionStatus: '',
            coatLength: '',
            adoptionFee: '',
            goodWith: '',
            mixedBreed: '',
            videoPresent: '',
            petMatchType: '',
        };
    }

    /**
     * Build animal property data for organization display ID
     *
     * @returns {Object}
     */
    organizationDisplayId() {
        return this._buildProperty(
            'petProfileShelterID',
            1,
            this._data.organizationDisplayId || this.nullText
        );
    }

    /**
     * Build animal property data for organization name
     *
     * @returns {Object}
     */
    organizationName() {
        return this._buildProperty(
            'petProfileShelterName',
            2,
            this._data.organizationName || this.nullText
        );
    }

    /**
     * Build animal property data for arrival date
     *
     * @returns {Object}
     */
    arrivalDate() {
        return this._buildProperty(
            'petProfileArrivalDate',
            3,
            this._data.arrivalDate || this.nullText
        );
    }

    /**
     * Build animal property data for type
     *
     * @returns {Object}
     */
    type() {
        return this._buildProperty(
            'petProfileAnimalType',
            4,
            this._data.type || this.nullText,
            'pagePetType'
        );
    }

    /**
     * Build animal property data for secondary breed
     *
     * @returns {Object}
     */
    secondaryBreed() {
        return this._buildProperty(
            'petProfileSecondaryBreed',
            5,
            this._data.secondaryBreed || this.nullText
        );
    }

    /**
     * Build animal property data for attributes
     *
     * @returns {Object}
     */
    attributes() {
        return this._buildProperty(
            'petProfilePetCharacteristics',
            6,
            this._data.attributes || this.nullText
        );
    }

    /**
     * Build animal property data for postal code
     *
     * @returns {Object}
     */
    postalCode() {
        return this._buildProperty(
            'petProfilePetZipCode',
            7,
            this._data.postalCode || this.nullText
        );
    }

    /**
     * Build animal property data for photo count
     *
     * @returns {Object}
     */
    photoCount() {
        return this._buildProperty(
            'petProfilePhotosInProfile',
            8,
            this._data.photoCount
                ? this._data.photoCount.toString()
                : this.nullText
        );
    }

    /**
     * Build animal property data for video service
     *
     * @returns {Object}
     */
    videoService() {
        return this._buildProperty(
            'petProfileVideoService',
            9,
            this._data.videoService || 'None'
        );
    }

    /**
     * Build animal property data for species
     *
     * @returns {Object}
     */
    species() {
        return this._buildProperty(
            'petProfileSpecies',
            12,
            this._data.species || this.nullText
        );
    }

    /**
     * Build animal property data for primary breed
     *
     * @returns {Object}
     */
    primaryBreed() {
        return this._buildProperty(
            'petProfilePrimaryBreed',
            13,
            this._data.primaryBreed || this.nullText
        );
    }

    /**
     * Build animal property data for age
     *
     * @returns {Object}
     */
    age() {
        return this._buildProperty(
            'petProfileAge',
            14,
            this._data.age || this.nullText,
            'pagePetAge'
        );
    }

    /**
     * Build animal property data for sex
     *
     * @returns {Object}
     */
    sex() {
        return this._buildProperty(
            'petProfileGender',
            15,
            this._data.sex || this.nullText
        );
    }

    /**
     * Build animal property data for size
     *
     * @returns {Object}
     */
    size() {
        return this._buildProperty(
            'petProfileSize',
            16,
            this._data.size || this.nullText
        );
    }

    /**
     * Build animal property data for ID
     *
     * @returns {Object}
     */
    id() {
        return this._buildProperty(
            'petProfilePetID',
            25,
            this._data.id ? this._data.id.toString() : this.nullText
        );
    }

    /**
     * Build animal property data for name
     *
     * @returns {Object}
     */
    name() {
        return this._buildProperty(
            'petProfilePetName',
            26,
            this._data.name || this.nullText
        );
    }

    /**
     * Build animal property data for primary color
     *
     * @returns {Object}
     */
    primaryColor() {
        return this._buildProperty(
            'petProfilePrimaryColor',
            27,
            this._data.primaryColor || this.nullText
        );
    }

    /**
     * Build animal property data for adoption status
     *
     * @returns {Object}
     */
    adoptionStatus() {
        return this._buildProperty(
            'petProfilePetStatus',
            28,
            this._data.adoptionStatus || this.nullText
        );
    }

    /**
     * Build animal property data for coat length
     *
     * @returns {Object}
     */
    coatLength() {
        return this._buildProperty(
            'petProfileCoatLength',
            29,
            this._data.coatLength || this.nullText
        );
    }

    /**
     * Build animal property data for adoption fee
     *
     * @returns {Object}
     */
    adoptionFee() {
        return this._buildProperty(
            'petProfileAdoptionFee',
            false,
            this._data.adoptionFee
                ? this._data.adoptionFee.toString()
                : this.nullText
        );
    }

    /**
     * Build animal property data for good with
     *
     * @returns {Object}
     */
    goodWith() {
        return this._buildProperty(
            'petProfileGoodWith',
            false,
            this._data.goodWith || this.nullText
        );
    }

    /**
     * Build animal property data for mixed breed
     *
     * @returns {Object}
     */
    mixedBreed() {
        return this._buildProperty(
            'petProfileMixedBreed',
            false,
            this._data.mixedBreed
                ? this._data.mixedBreed.toString()
                : this.nullText
        );
    }

    /**
     * Build animal property data for video present
     * @method videoPresent
     *
     * @returns {Object}
     */
    videoPresent() {
        return this._buildProperty(
            'petProfileVideoPresent',
            false,
            this._data.videoPresent ? 'true' : 'false'
        );
    }

    /**
     * Build animal property data for pet match type
     *
     * @returns {Object}
     */
    petMatchType() {
        return this._buildProperty(
            'petMatchType',
            115,
            this._data.petMatchType
                ? this._data.petMatchType.toString()
                : this.nullText
        );
    }
}
