import t from 'tcomb-form';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';

/**
 * @type {Object}
 */
const JSON_SCHEMA_TO_TCOMB_FIELD_OPTIONS_MAP = {
    title: 'label',
};

/**
 * @type {Object}
 */
const UI_SCHEMA_TO_TCOMB_FIELD_OPTIONS_MAP = {
    widget: 'type',
    help: 'help',
};

/**
 * @type {Object}
 */
export const DESCRIBED_BY_ID_ENUM = {
    ERRORS: 'errors',
    HELP: 'help',
};

/**
 * Helps map various schema properties to tcomb field form options
 * @param {Object} jsonSchema
 * @param {Object} uiSchema
 * @returns {Object}
 */
export function buildFieldOptionsFromSchemas(
    jsonSchema = { properties: {} },
    uiSchema = {}
) {
    const fieldOptions = {};

    Object.keys(jsonSchema.properties).forEach(key => {
        const properties = jsonSchema.properties[key];

        Object.keys(JSON_SCHEMA_TO_TCOMB_FIELD_OPTIONS_MAP).forEach(propKey => {
            _set(
                fieldOptions,
                `${key}.${JSON_SCHEMA_TO_TCOMB_FIELD_OPTIONS_MAP[propKey]}`,
                properties[propKey]
            );
        });
    });

    Object.keys(uiSchema).forEach(key => {
        const properties = uiSchema[key];

        Object.keys(properties).forEach(propKey => {
            _set(
                fieldOptions,
                `${key}.${UI_SCHEMA_TO_TCOMB_FIELD_OPTIONS_MAP[propKey]}`,
                properties[propKey]
            );
        });
    });

    return fieldOptions;
}

/**
 * Helps get title from json schema by key
 * @param {Object} jsonSchema
 * @param {string} key
 * @returns {string}
 */
export function getLabelFromJsonSchemaTitleByKey(jsonSchema, key) {
    return jsonSchema.properties[key].title;
}

/**
 * Helps get enum label from json schema by key and value
 * @param {Object} jsonSchema
 * @param {string} key
 * @param {string} value
 * @returns {string}
 */
export function getEnumLabelFromJsonSchemaTitleByKeyValue(
    jsonSchema,
    key,
    value
) {
    const index = jsonSchema.properties[key].enum.indexOf(value);
    return jsonSchema.properties[key].enum_titles[index];
}

/**
 * @param {Object} locals
 * @returns {Object}
 */
export function getFieldUiSchemaFromLocals(locals) {
    const fieldKey = locals.attrs.name;
    return _get(locals, `context.uiSchema.${fieldKey}`, {});
}

/**
 * Remove [foo][bar] from a string and replace it with something
 * @param {string} str
 * @param {string} replaceWith
 * @returns {string}
 */
export function replaceBracketStringWith(str, replaceWith = '_') {
    return str.replace(/\[/g, replaceWith).replace(/\]/g, '');
}

/**
 * @param {string} formName
 * @param {string} fieldName
 * @returns {string}
 */
export function buildFieldId(formName, fieldName) {
    const processedFieldName = replaceBracketStringWith(fieldName);
    return formName.length > 0
        ? `${formName}_${processedFieldName}`
        : processedFieldName;
}

/**
 * Builds a field id with form id and tcomb field name instead of using default tcomb id
 * @param {Object} locals
 * @returns {string}
 */
export function buildFieldIdFromTcombLocals(locals) {
    const formName = _get(locals.context, 'jsonSchema.title', '');
    const fieldName = locals.attrs.name;
    return buildFieldId(formName, fieldName);
}

/**
 * Build describedby id from fieldId and an identifier
 * @param {string} fieldId
 * @param {string} identifier
 * @returns {string}
 */
export function buildDescribedById(fieldId, identifier) {
    return `${fieldId}-${identifier}`;
}

/**
 * Build full list of describedby id strings from tcomb locals
 * @param {Object} locals
 * @returns {Array}
 */
export function buildDescribedByIdsFromTcombLocals(locals) {
    const fieldId = buildFieldIdFromTcombLocals(locals);
    const ids = [];

    if (locals.hasError) {
        ids.push(buildDescribedById(fieldId, DESCRIBED_BY_ID_ENUM.ERRORS));
    }

    if (locals.help) {
        ids.push(buildDescribedById(fieldId, DESCRIBED_BY_ID_ENUM.HELP));
    }

    if (locals.attrs['aria-describedby']) {
        ids.push(locals.attrs['aria-describedby']);
    }

    return ids;
}

/**
 * Helper that checks tcomb local props label, if the optional label isn't applied by tcomb
 * we try to do it again, this is mostly for safety
 * @param {Object} locals
 * @returns {string|Object}
 */
export function processLabel(locals) {
    if (typeof locals.label === 'string' && locals.typeInfo.isMaybe) {
        const optionalLabel = t.form.Form.i18n.optional;

        if (locals.label.includes(optionalLabel)) {
            return locals.label;
        }

        return `${locals.label}${optionalLabel}`;
    }

    return locals.label;
}

/**
 * Helper that all json schema will likely be run thru to smooth out various types
 * that seem to be different when coming from symfony and its json schema delivery
 * @param {Object} jsonSchema
 * @returns {Object}
 */
export function processJsonSchema(jsonSchema) {
    const newJsonSchema = _cloneDeep(jsonSchema);
    const properties = newJsonSchema.properties;

    // Repopulate the enum property with a key value object if enum_titles exists
    Object.keys(properties).forEach(key => {
        const property = properties[key];
        if (
            property.hasOwnProperty('enum') &&
            property.hasOwnProperty('enum_titles')
        ) {
            const currentEnum = property.enum;
            const currentEnumTitles = property.enum_titles;
            const newEnum = {};
            currentEnum.forEach((enumKey, index) => {
                newEnum[enumKey] = currentEnumTitles[index];
            });
            property.enum = newEnum;
        }
    });

    return newJsonSchema;
}

/**
 * Returns a new json schema that is updated with states for the selected country
 * Also updates the json schema required property for state if no states
 * @param {Object} jsonSchema
 * @param {Object} value
 * @param {Object} stateChoicesByCountry
 * @param {string} countrySchemaKey
 * @param {string} stateSchemaKey
 * @returns {Object}
 */
export function updateJsonSchemaBasedOnCountryValue(
    jsonSchema,
    value,
    stateChoicesByCountry,
    countrySchemaKey,
    stateSchemaKey
) {
    const newJsonSchema = _cloneDeep(jsonSchema);
    const newJsonSchemaFieldProperties = newJsonSchema.properties;

    if (value && value[countrySchemaKey]) {
        const countryCodeValue = value[countrySchemaKey];
        const states = stateChoicesByCountry[countryCodeValue];

        // Change states by updating the state enum
        newJsonSchemaFieldProperties[stateSchemaKey].enum = states;

        // Check if states is array, if so it is not an enumeration of states
        // and therefore no states exist
        if (Array.isArray(states)) {
            const index = newJsonSchema.required.indexOf(stateSchemaKey);
            newJsonSchema.required.splice(index, 1);
        }
    }

    return newJsonSchema;
}
