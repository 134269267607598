import MixinBase from './MixinBase';
import ActionParser from '../ActionParser';

const COLON_SPLIT_REGEX = / *: */;
const COMMA_SPLIT_REGEX = / *, */;

export default class DelayedCallMixin extends MixinBase {
    static _attributeName = 'mix-delayed-call';

    constructor(ele, value) {
        super(ele, value);
        const declarations = value.split(COMMA_SPLIT_REGEX);
        for (let i = 0; i < declarations.length; i++) {
            const splitValue = declarations[i].split(COLON_SPLIT_REGEX);
            const time = splitValue[0];
            const func = splitValue[1];
            const actions = ActionParser.evalActionString(func);

            setTimeout(() => {
                ActionParser.executeActions(actions, { $mixin: this, $element: ele });
            }, time);
        }
    }
}
