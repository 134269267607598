/**
 * @type {Object}
 */
export const STATE = {
    SIGN_UP: 'signUp',
    LOGIN: 'login',
    REGISTER: 'register',
    RETURNING: 'returning',
    SOCIAL: 'social',
    LOADING: 'loading',
    LINK: 'link',
    REAUTHENTICATE: 'reauthenticate',
    REDIRECT: 'redirect',
    COMPLETE_REGISTRATION: 'completeRegistration',
    IDLE: 'idle',
    SIGNED_IN: 'signedIn',
    GENERIC_ERROR: 'genericError',
};

/**
 * @type {Object}
 */
export const EVENT = {
    SIGN_UP: 'signUp',
    LOG_IN: 'login',
    SOCIAL: 'social',
    REGISTER: 'register',
    RETURNING: 'returning',
    SOCIAL_SUCCESS: 'success',
    REAUTHENTICATE: 'reauthenticate',
    REDIRECT: 'redirect',
    LINK: 'link',
    COMPLETE_REGISTRATION: 'completeRegistration',
    SIGNED_IN: 'signedIn',
    LOADING: 'loading',
    SOCIAL_CANCEL: 'socialCancel',
    GENERIC_ERROR: 'genericError',
};

/**
 * @type {Object}
 */
export const ACTION = {
    FOCUS_FIRST: ['focusFirst'],
    TOGGLE_NO_CLOSE: ['toggleNoClose'],
};

/**
 * @type {Object}
 */
export const statechart = {
    initial: STATE.IDLE,
    on: {
        [EVENT.SOCIAL]: {
            [STATE.SOCIAL]: {
                actions: ACTION.FOCUS_FIRST,
            },
        },
        [EVENT.SIGN_UP]: STATE.SIGN_UP,
        [EVENT.LOG_IN]: STATE.LOGIN,
        [EVENT.RETURNING]: STATE.RETURNING,
        [EVENT.GENERIC_ERROR]: {
            [STATE.GENERIC_ERROR]: {
                actions: ACTION.FOCUS_FIRST,
            },
        },
        [EVENT.REAUTHENTICATE]: {
            [STATE.REAUTHENTICATE]: {
                actions: ACTION.FOCUS_FIRST,
            },
        },
        [EVENT.REDIRECT]: {
            [STATE.REDIRECT]: {
                actions: ACTION.FOCUS_FIRST,
            },
        },
        [EVENT.SOCIAL_CANCEL]: {
            [STATE.SIGN_UP]: {
                actions: ACTION.FOCUS_FIRST,
            },
        },
    },
    states: {
        [STATE.IDLE]: {
            on: {
                [EVENT.REGISTER]: {
                    [STATE.REGISTER]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
                [EVENT.SOCIAL]: {
                    [STATE.SOCIAL]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
            },
        },
        [STATE.SIGN_UP]: {
            on: {
                [EVENT.LOGIN]: {
                    [STATE.LOGIN]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
                [EVENT.SOCIAL]: {
                    [STATE.SOCIAL]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
                [EVENT.REGISTER]: {
                    [STATE.REGISTER]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
            },
        },
        [STATE.RETURNING]: {
            [EVENT.LOG_IN]: {
                [STATE.LOGIN]: {
                    actions: ACTION.FOCUS_FIRST,
                },
            },
        },
        [STATE.LOGIN]: {
            on: {
                [EVENT.SIGN_UP]: {
                    [STATE.SIGN_UP]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
                [EVENT.SOCIAL]: {
                    [STATE.SOCIAL]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
                [EVENT.REGISTER]: {
                    [STATE.REGISTER]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
            },
        },
        [STATE.REGISTER]: {
            [EVENT.SOCIAL]: {
                [STATE.SOCIAL]: {
                    actions: ACTION.FOCUS_FIRST,
                },
            },
        },
        [STATE.REAUTHENTICATE]: {},
        [STATE.REDIRECT]: {
            on: {
                [EVENT.SOCIAL]: {
                    [STATE.SOCIAL]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
                [EVENT.REGISTER]: {
                    [STATE.REGISTER]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
            },
        },
        [STATE.GENERIC_ERROR]: {},
        [STATE.SOCIAL]: {
            initial: STATE.LOADING,
            on: {
                [EVENT.REGISTER]: {
                    [STATE.REGISTER]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
                [EVENT.REAUTHENTICATE]: {
                    [STATE.REAUTHENTICATE]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
                [EVENT.REDIRECT]: {
                    [STATE.REDIRECT]: {
                        actions: ACTION.FOCUS_FIRST,
                    },
                },
            },
            states: {
                [STATE.LOADING]: {
                    onEntry: ACTION.TOGGLE_NO_CLOSE,
                    onExit: ACTION.TOGGLE_NO_CLOSE,
                    on: {
                        [EVENT.COMPLETE_REGISTRATION]: {
                            [STATE.COMPLETE_REGISTRATION]: {
                                actions: ACTION.FOCUS_FIRST,
                            },
                        },
                        [EVENT.SIGNED_IN]: {
                            [STATE.SIGNED_IN]: {
                                actions: ACTION.FOCUS_FIRST,
                            },
                        },
                        [EVENT.LINK]: {
                            [STATE.LINK]: {
                                actions: ACTION.FOCUS_FIRST,
                            },
                        },
                    },
                },
                [STATE.SIGNED_IN]: {},
                [STATE.LINK]: {},
                [STATE.COMPLETE_REGISTRATION]: {
                    on: {
                        [EVENT.SUBMISSION_SUCCESS]: {
                            [STATE.SIGNED_IN]: {
                                actions: ACTION.FOCUS_FIRST,
                            },
                        },
                    },
                },
            },
        },
    },
};

/**
 * @type {Object}
 * State paths for React Automata to target
 */
export const STATE_PATHS = {
    SIGN_UP: STATE.SIGN_UP,
    LOGIN: STATE.LOGIN,
    REGISTER: STATE.REGISTER,
    RETURNING: STATE.RETURNING,
    GENERIC_ERROR: STATE.GENERIC_ERROR,
    SOCIAL_LINK: `${STATE.SOCIAL}.${STATE.LINK}`,
    SOCIAL_REAUTHENTICATE: `${STATE.REAUTHENTICATE}`,
    SOCIAL_COMPLETE_REGISTRATION: `${STATE.SOCIAL}.${STATE.COMPLETE_REGISTRATION}`,
    SOCIAL_LOADING: `${STATE.SOCIAL}.${STATE.LOADING}`,
    SOCIAL_REDIRECT: `${STATE.REDIRECT}`,
};

/**
 * @type {Object}
 * These are the labels that will be passed in to analytic events based on the current 'view' state.
 * DO NOT ALTER THESE VALUE unless you need to update the strings for all analytic events.
 */
export const ANALYTIC_STATE_LABELS = {
    [STATE.SIGN_UP]: 'sign up',
    [STATE.LOGIN]: 'log in',
    [STATE.REGISTER]: 'registration',
    [STATE.RETURNING]: 'returning social user',
    [STATE.LINK]: 'link account',
    [STATE.REAUTHENTICATE]: 'provide email prompt',
    [STATE.REDIRECT]: 'email not available',
    [STATE.COMPLETE_REGISTRATION]: 'complete profile',
    [STATE.GENERIC_ERROR]: 'general error',
    [STATE.LOADING]: 'loading',
};

export const ANALYTIC_FLOW_LABELS = {
    [STATE.SIGN_UP]: 'sign up',
    [STATE.LOGIN]: 'log in',
    [STATE.REGISTER]: 'sign up',
    [STATE.RETURNING]: 'log in',
    [STATE.LINK]: 'sign up',
    [STATE.REAUTHENTICATE]: 'sign up',
    [STATE.REDIRECT]: 'sign up',
    [STATE.COMPLETE_REGISTRATION]: 'sign up',
    [STATE.GENERIC_ERROR]: 'sign up',
    [STATE.LOADING]: 'sign up',
};

export const INITIAL_STATES = Object.keys(statechart.states);
