import StateController from '../../../../core/scripts/lib/wirey/state/StateController';
import SavedSearchModel from '../../../../core/scripts/models/SavedSearchModel';

class SavedSearchUIStateController extends StateController {
    static IS_SAVING_PATH = 'isSaving';

    static SAVED_SEARCH_MODAL_VISIBILITY_PATH = 'savedSearchModalVisible';
    static SOCIAL_LOGIN_MODAL_VISIBILITY_PATH = 'socialLoginModalVisible';

    static PROGRESSION_STEP_PATH = 'progressionStep';

    static PROGRESSION_STEPS = {
        INITIAL: 'INITIAL',
        SAVE_PROMPT: 'SAVE_PROMPT',
        CONFIRMATION: 'CONFIRMATION',
    };

    static SEARCH_URL = 'searchUrl';
    static GUEST_COUNTRY_PATH = 'guestCountry';
    static GUEST_EMAIL_PATH = 'guestEmail';
    static OPTIN_VALUES_PATH = 'optinValues';

    static NOTIFY_ON_NEW_MATCHES = 'notifyOnNewMatches';

    static SAVED_SEARCH_MODEL_DATA_PATH = 'savedSearchModelData';

    get defaultState() {
        return {
            [SavedSearchUIStateController.IS_SAVING_PATH]: false,
            [SavedSearchUIStateController.SAVED_SEARCH_MODAL_VISIBILITY_PATH]: false,
            [SavedSearchUIStateController.SOCIAL_LOGIN_MODAL_VISIBILITY_PATH]: false,
            [SavedSearchUIStateController.PROGRESSION_STEP_PATH]:
                SavedSearchUIStateController.PROGRESSION_STEPS.INITIAL,

            [SavedSearchUIStateController.SEARCH_URL]: '',
            [SavedSearchUIStateController.GUEST_COUNTRY_PATH]: 'US',
            [SavedSearchUIStateController.GUEST_EMAIL_PATH]: '',
            [SavedSearchUIStateController.OPTIN_VALUES_PATH]: {},

            [SavedSearchUIStateController.NOTIFY_ON_NEW_MATCHES]: true,

            [SavedSearchUIStateController.SAVED_SEARCH_MODEL_DATA_PATH]: new SavedSearchModel()
                .raw,
        };
    }

    closeModalAndReset() {
        this.setDefaultStateAndBroadcast();
    }

    /**
     * Loading
     */

    get isSaving() {
        return this.state[SavedSearchUIStateController.IS_SAVING_PATH];
    }

    set isSaving(value) {
        this.setStateAtAddress(
            Boolean(value),
            SavedSearchUIStateController.IS_SAVING_PATH
        );
    }

    /**
     * User form data
     */

    set searchUrl(value) {
        this.setStateAtAddress(value, SavedSearchUIStateController.SEARCH_URL);
    }

    get searchUrl() {
        return this.state[SavedSearchUIStateController.SEARCH_URL];
    }

    set guestCountry(value) {
        this.setStateAtAddress(
            value,
            SavedSearchUIStateController.GUEST_COUNTRY_PATH
        );
    }

    get guestCountry() {
        return this.state[SavedSearchUIStateController.GUEST_COUNTRY_PATH];
    }

    set guestEmail(value) {
        this.setStateAtAddress(
            value,
            SavedSearchUIStateController.GUEST_EMAIL_PATH
        );
    }

    get guestEmail() {
        return this.state[SavedSearchUIStateController.GUEST_EMAIL_PATH];
    }

    set optinValues(value) {
        this.setStateAtAddress(
            value,
            SavedSearchUIStateController.OPTIN_VALUES_PATH
        );
    }

    get optinValues() {
        return this.state[SavedSearchUIStateController.OPTIN_VALUES_PATH];
    }

    set notifyOnNewMatches(value) {
        this.setStateAtAddress(
            value,
            SavedSearchUIStateController.NOTIFY_ON_NEW_MATCHES
        );
    }

    get notifyOnNewMatches() {
        return this.state[SavedSearchUIStateController.NOTIFY_ON_NEW_MATCHES];
    }

    set savedSearchResponseData(value) {
        this.setStateAtAddress(
            SavedSearchModel.fromSavedSearchAPIData(value).raw,
            SavedSearchUIStateController.SAVED_SEARCH_MODEL_DATA_PATH
        );
    }

    get savedSearchModelData() {
        return this.state[
            SavedSearchUIStateController.SAVED_SEARCH_MODEL_DATA_PATH
        ];
    }

    /**
     * Modal visibility
     */

    get isSavedSearchModalVisible() {
        return this.state[
            SavedSearchUIStateController.SAVED_SEARCH_MODAL_VISIBILITY_PATH
        ];
    }

    set isSavedSearchModalVisible(value) {
        this.setStateAtAddress(
            Boolean(value),
            SavedSearchUIStateController.SAVED_SEARCH_MODAL_VISIBILITY_PATH
        );
    }

    onModalVisibilityChange(callback) {
        return this.subscribe(
            callback,
            SavedSearchUIStateController.SAVED_SEARCH_MODAL_VISIBILITY_PATH
        );
    }

    /**
     * State getters
     */

    get _currentProgressionStep() {
        return this.state[SavedSearchUIStateController.PROGRESSION_STEP_PATH];
    }

    /**
     * @readonly
     * @returns {boolean}
     */
    get isInitialStep() {
        return (
            this._currentProgressionStep ===
            SavedSearchUIStateController.PROGRESSION_STEPS.INITIAL
        );
    }

    /**
     * @readonly
     * @returns {boolean}
     */
    get isSavePromptStep() {
        return (
            this._currentProgressionStep ===
            SavedSearchUIStateController.PROGRESSION_STEPS.SAVE_PROMPT
        );
    }

    /**
     * @readonly
     * @returns {boolean}
     */
    get isConfirmationStep() {
        return (
            this._currentProgressionStep ===
            SavedSearchUIStateController.PROGRESSION_STEPS.CONFIRMATION
        );
    }

    /**
     * Screen progression
     */

    transitionToInitialStep() {
        this.setStateAtAddress(
            SavedSearchUIStateController.PROGRESSION_STEPS.INITIAL,
            SavedSearchUIStateController.PROGRESSION_STEP_PATH
        );
    }

    transitionToSavePromptStep() {
        this.setStateAtAddress(
            SavedSearchUIStateController.PROGRESSION_STEPS.SAVE_PROMPT,
            SavedSearchUIStateController.PROGRESSION_STEP_PATH
        );
    }

    transitionToConfirmationStep() {
        this.setStateAtAddress(
            SavedSearchUIStateController.PROGRESSION_STEPS.CONFIRMATION,
            SavedSearchUIStateController.PROGRESSION_STEP_PATH
        );
    }

    onSavedSearchProgressionChange(callback) {
        return this.subscribe(
            callback,
            SavedSearchUIStateController.PROGRESSION_STEP_PATH
        );
    }
}

export default new SavedSearchUIStateController();
