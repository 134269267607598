import React from 'react';

import {
    COUNTRY_NOT_LISTED_HEADER,
    COUNTRY_NOT_LISTED_BODY,
} from '../../../../../core/scripts/constants/geography';

import AlertBox from '../AlertBox';

/**
 * Renders the country not listed alert box
 * @returns {string}
 */
const AlertBoxCountryNotListed = () => {
    return (
        <AlertBox type="info">
            <div tabIndex="0">
                <h5 className="txt m-txt_heavy m-txt_colorPrimaryS1">
                    {COUNTRY_NOT_LISTED_HEADER}
                </h5>
                <p className="txt m-txt_colorPrimaryS1">
                    {COUNTRY_NOT_LISTED_BODY}
                </p>
            </div>
        </AlertBox>
    );
};

export default AlertBoxCountryNotListed;
