import BaseModel from '../../models/BaseModel';
import _get from 'lodash/get';

export default class BaseAnalyticPropertiesModel extends BaseModel {
    constructor(data, nullText = '') {
        super(data);
        this._nullText = nullText;
    }

    get nullText() {
        return this._nullText;
    }

    /**
     * _buildProperty
     *
     * @private
     * @param {string} dataLayerKey
     * @param {number} dimensionKey
     * @param {mixed} analyticValue
     * @param {string} secondaryDataLayerKey
     *
     * @returns {Object}
     */
    _buildProperty(
        dataLayerKey,
        dimensionKey,
        analyticValue,
        secondaryDataLayerKey = false
    ) {
        const property = {
            dataLayerKey,
            dimensionKey,
            secondaryDataLayerKey,
            analyticValue,
        };

        return property;
    }
}
