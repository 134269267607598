import BaseAnalyticEventModel from '../BaseAnalyticEventModel';
import AnimalPropertiesModel from './AnimalPropertiesModel';

export default class AnimalEventModel extends BaseAnalyticEventModel {
    get defaultAnalyticProperties() {
        return {
            organizationDisplayId: true,
            organizationName: true,
            arrivalDate: true,
            type: true,
            secondaryBreed: true,
            attributes: true,
            postalCode: true,
            photoCount: true,
            videoService: true,
            species: true,
            primaryBreed: true,
            age: true,
            sex: true,
            size: true,
            id: true,
            name: true,
            primaryColor: true,
            adoptionStatus: true,
            coatLength: true,
            adoptionFee: true,
            goodWith: true,
            mixedBreed: true,
            videoPresent: true,
            petMatchType: true,
        };
    }

    /**
     * prepareEvent
     *
     * @static
     * @param {Object} eventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {AnimalEventModel}
     */
    static prepareEvent(
        eventData,
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const model = new AnimalEventModel(
            this._mapEventData(eventData),
            analyticProperties,
            overrideAnalyticProperties
        );
        model.analyticObjects = eventData;

        return model;
    }

    /**
     * fromAnimalModel
     *
     * @static
     * @param {Object} animal
     * @param {Object} additionalEventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {AnimalEventModel}
     */
    static fromAnimalModel(
        animal,
        additionalEventData = {},
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const eventData = {
            ...this._mapAnimalModel(animal),
            ...additionalEventData,
        };
        return this.prepareEvent(
            eventData,
            analyticProperties,
            overrideAnalyticProperties
        );
    }

    /**
     * _mapAnimalModel
     *
     * @param {Object} animal
     *
     * @return {Object}
     */
    static _mapAnimalModel = animal => {
        return {
            organizationDisplayId: animal.organizationId,
            organizationName: animal.organizationName,
            arrivalDate: animal.arrivalDate,
            type: animal.typeName,
            secondaryBreed: animal.secondaryBreed,
            attributes: animal.attributes,
            postalCode: animal.postalCode,
            photoCount: animal.numberOfPhotos,
            videoService: animal.videoType,
            species: animal.species,
            primaryBreed: animal.primaryBreed,
            age: animal.age,
            sex: animal.sex,
            size: animal.size,
            id: animal.id,
            name: animal.name,
            primaryColor: animal.primaryColor,
            adoptionStatus: animal.adoptionStatus,
            coatLength: animal.coatLength,
            adoptionFee: animal.adoptionFee,
            goodWith: animal.goodWith,
            mixedBreed: animal.mixedBreed,
            videoPresent: animal.numberOfVideos,
            petMatchType: animal.petMatchType,
        };
    };

    _buildProperties(analytics, nullText) {
        return new AnimalPropertiesModel(analytics, nullText);
    }
}
