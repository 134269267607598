import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { analyticsConsumer010 } from '../../../../../analytics/dotcom';

class SocialLinkLayout extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        email: PropTypes.string,
        provider: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        provider: '',
    };

    /**
     * @param {Event} ev
     */
    handleForgotPasswordClick = ev => {
        ev.preventDefault();
        this.props.onForgotPasswordClick(analyticsConsumer010);
    };

    get linkMessage() {
        return (
            <Fragment>
                <div className="u-vr4x">
                    An account already exists with the email address{' '}
                    <span className="u-bold">{this.props.email}</span>. Let's
                    connect your{' '}
                    <span className="u-caps">{this.props.provider}</span>{' '}
                    account with your Petfinder account.
                </div>
                <div className="u-vr2x">
                    If you don't have a password, you can create one by
                    following the link below.
                </div>
            </Fragment>
        );
    }

    render() {
        return (
            <div className="loginModal">
                <div className="loginModal-section">
                    <h2 className="loginModal-section-hdg u-vr6x" tabIndex="-1">
                        {'Welcome back!'}
                    </h2>
                    <div className="loginModal-section">{this.linkMessage}</div>
                </div>
                <div className="loginModal-section">{this.props.children}</div>
                <div className="loginModal-section u-textCenter">
                    <a
                        href={this.props.forgotPasswordHref}
                        onClick={this.handleForgotPasswordClick}
                        className="txt txt_link m-txt_lg m-txt_underline u-textCenter"
                    >
                        {'Update or create password'}
                    </a>
                </div>
            </div>
        );
    }
}

export default SocialLinkLayout;
