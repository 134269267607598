import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import TemplateRegistry from '../../../../core/scripts/lib/wirey/TemplateRegistry';

// Configurable attributes so we can exclude these from mirrored attrs
const CONFIG_ATTRS = ['element', 'template'];

// Allowed element tag names for the element attr
const ALLOWED_ELEMENT_ENUM = ['iframe', 'img'];

// Default intersection observer options
const DEFAULT_OBSERVER_OPTIONS = {
    root: null,
    rootMargin: '0px 0px 250px 0px', // intersection occurs 250px below visible window
    threshold: 0,
};

/**
 * This custom element can be used to "lazily" load an element (will mirror non config attributes)
 * or provided template function. Include template attribute to lazily load template. Template
 * attribute must be provided a valid app registered template string func id.
 *
 * <pfdc-lazy-load
 *     element="iframe" // [iframe, img]
 *     template="registeredTemplateFunc" // Valid app registered template string func id
 *     allowfullscreen
 *     title="Map"
 *     height="200"
 *     frameborder="0"
 *     style="display: block; border:0; width:100%;"
 *     src="{{ memberDetail.mapPlaceUrl | raw }}"
 * ></pfdc-lazy-load>
 *
 * @class PFDCLazyLoadElement
 * @extends PFElement
 */
export class PFDCLazyLoadElement extends PFElement {
    get element() {
        if (!this.hasAttribute('element')) {
            throw new Error(
                'PFDCLazyLoadElement: You need to provide an element attribute'
            );
        }

        const elAttrVal = this.getAttribute('element');

        if (!ALLOWED_ELEMENT_ENUM.includes(elAttrVal)) {
            throw new Error(
                'PFDCLazyLoadElement: A valid element attr name must be provided'
            );
        }

        return elAttrVal;
    }

    get template() {
        if (!this.hasAttribute('template')) {
            return false;
        }

        const templateAttrVal = this.getAttribute('template');

        if (!TemplateRegistry.has(templateAttrVal)) {
            throw new Error(
                'PFDCLazyLoadElement: The template id you provided does not ' +
                    'exist in the template registry.'
            );
        }

        return TemplateRegistry.get(templateAttrVal);
    }

    get nonConfigAttributes() {
        return Array.from(this.attributes).filter(
            item => !CONFIG_ATTRS.includes(item.name)
        );
    }

    onInit() {
        this.onImageLoadedHandler = this.onImageLoaded.bind(this);
        this.onImageLoadedAnimationEndHandler = this.onImageLoadedAnimationEnd.bind(
            this
        );
        this.onObservedHandler = this.onObserved.bind(this);

        this.observer = new IntersectionObserver(
            this.onObservedHandler,
            DEFAULT_OBSERVER_OPTIONS
        );
        this.observer.observe(this);

        this.image = null;
    }

    disconnectedCallback() {
        this.onImageLoadedHandler = null;
        this.onImageLoadedAnimationEndHandler = null;
        this.onObservedHandler = null;

        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    /**
     * Render template or element
     */
    render() {
        if (this.template) {
            const vm = {
                _attributes: this.attributes,
            };

            this.innerHTML = this.template(vm);
            return;
        }

        // Check for various other element types
        if (this.element === 'img') {
            this.loadImage();
            return;
        }

        const el = document.createElement(this.element);

        // Grab attributes from element and mirror to new element
        this.nonConfigAttributes.forEach(attribute => {
            const { name, value } = attribute;
            el.setAttribute(name, value);
        });

        this.appendChild(el);
    }

    loadImage() {
        if (
            !this.nonConfigAttributes.some(
                attribute => attribute.name === 'src'
            )
        ) {
            throw new Error(
                'PFDCLazyLoadElement: You must provide a src attribute for an img ' +
                    'type lazy load element.'
            );
        }

        const ignoreAttrs = ['class'];

        this.image = new Image();

        this.nonConfigAttributes.forEach(attribute => {
            const { name, value } = attribute;
            if (ignoreAttrs.includes(name)) {
                return;
            }

            this.image.setAttribute(name.replace('img-', ''), value.trim());
        });

        if (this.image.hasAttribute('animation-class')) {
            this.image.classList.add(
                this.image.getAttribute('animation-class')
            );
        }

        if (this.image.complete) {
            this.onImageLoadedHandler();
        } else {
            this.image.addEventListener('load', this.onImageLoadedHandler);
        }
    }

    onImageLoaded() {
        this.appendChild(this.image).addEventListener(
            'animationend',
            this.onImageLoadedAnimationEndHandler
        );

        if (window.objectFitImages) {
            objectFitImages(this.image);
        }

        this.image.removeEventListener('load', this.onImageLoadedHandler);
    }

    onImageLoadedAnimationEnd() {
        this.image.classList.remove(this.image.getAttribute('animation-class'));
        this.removeEventListener(
            'animationend',
            this.onImageLoadedAnimationEndHandler
        );
    }

    onObserved(entries, observer) {
        // Check if entry has isIntersecting property, because on S6 at time of this comment
        // that is not a thing...
        if ('isIntersecting' in entries[0]) {
            if (!entries[0].isIntersecting) {
                return;
            }
        }

        this.render();
        observer.disconnect();
    }
}

export default PFDCLazyLoadElement;
