import React from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import savedSearchService from '../../../../../core/scripts/services/savedSearchService';
import StateController from '../../generics/StateController';
import userMeStateController from '../../../state-controllers/userMeStateController';

export default function SavedSearchBreedsDetailButton(props) {
    /**
     * Adds the users location slug and a default distance to the saved search url.
     *
     * @private
     * @returns {string}
     */
    function _getUpdatedSearchUrl() {
        const defaultSearchDistance = '&distance=100';
        const searchUrl = new URL(props.url);
        searchUrl.pathname = `${searchUrl.pathname}${userMeStateController.state.location.slug}`;
        searchUrl.search = `${searchUrl.search}${defaultSearchDistance}`;
        return searchUrl.href;
    }

    /* *******************************************
     * Events
     ********************************************/
    /**
     *
     * @param {MouseEvent} e
     * @private
     */
    const _onSaveSearchButtonClick = e => {
        savedSearchService.launchSaveSearchModal({
            focusTarget: e.currentTarget,
            analyticTriggerLabel: 'saveSearch',
            searchUrl: _getUpdatedSearchUrl(),
            name: props.name,
            filtersSummaryDescription: props.filtersSummaryDescription,
        });
    };

    return (
        <StateController
            stateLocation="ui.savedSearch.isSaving"
            onStateUpdated={stateControllerState => (
                <span onClick={_onSaveSearchButtonClick}>
                    <span
                        className={buildClassNames({
                            ['txt']: true,
                            ['txt_loader']: true,
                            ['m-txt_lg']: true,
                            ['m-txt_underline']: true,
                            ['u-pointer']: true,
                            ['u-isActive']: stateControllerState,
                        })}
                    >
                        Save search for breed
                    </span>
                    <svg
                        role="presentation"
                        focusable="false"
                        viewBox="25 25 50 50"
                    >
                        <circle
                            className="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            strokeWidth="5"
                            stroke="#4d4751"
                            strokeDasharray="39.25, 117.75"
                        />
                    </svg>
                </span>
            )}
        />
    );
}

SavedSearchBreedsDetailButton.propTypes = {
    url: PropTypes.string,
    name: PropTypes.string,
    filtersSummaryDescription: PropTypes.string,
};
