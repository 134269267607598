import React, { Component } from 'react';
import { withStateMachine } from 'react-automata';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _debounce from 'lodash/debounce';

import { isFocusWithinContainer } from '../../../../../core/scripts/util/react';
import { focusFirstFocusable } from '../../../../../core/scripts/lib/focusManager/index';
import { offset } from '../../../../../core/scripts/util/dom';

import { statechart, STATE, EVENT, ACTION } from './statechart';

/**
 * @type {Object}
 */
const DEFAULT_OFFSCREEN = {
    toLeft: false,
    toRight: false,
};

/**
 * @class Tooltip
 * @extends Component
 */
export class Tooltip extends Component {
    /**
     * @type {Object}
     */
    state = {
        isOffscreen: DEFAULT_OFFSCREEN,
    };

    /**
     * @type {Object}
     */
    tooltipContainer = React.createRef();

    /**
     * @type {Object}
     */
    tooltipBodyContainer = React.createRef();

    /**
     * @type {function}
     */
    debouncedResizeHandler = _debounce(this.handleResize.bind(this), 250);

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        children: PropTypes.func.isRequired,
        extensionClassNames: PropTypes.objectOf(PropTypes.bool),
        renderBody: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
            .isRequired,
    };

    get isShowing() {
        return this.props.machineState.value === STATE.ON;
    }

    get rootClassNames() {
        return buildClassNames({
            ['tooltip']: true,
            ['s-tooltip_on']: this.isShowing,
            ['s-tooltip_offscreenLeft']: this.state.isOffscreen.toLeft,
            ...this.props.extensionClassNames,
        });
    }

    get handlers() {
        return {
            handleToggle: this.handleToggle,
            handleOpen: this.handleOpen,
            handleBlur: this.handleBlur,
            handleClose: this.handleClose,
        };
    }

    componentDidMount() {
        this.checkIfTooltipBodyOffscreen();
        window.addEventListener('resize', this.debouncedResizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedResizeHandler);
    }

    /**
     * @private
     */
    [ACTION.FOCUS_FIRST_FOCUSABLE]() {
        focusFirstFocusable(this.tooltipBodyContainer.current);
    }

    /**
     * Check if we should put various offscreen state classes on
     * @private
     */
    checkIfTooltipBodyOffscreen() {
        const bodyOffset = offset(this.tooltipBodyContainer.current);

        this.setState(prevState => {
            const isOffscreen = Object.assign({}, prevState.isOffscreen);
            isOffscreen.toLeft = bodyOffset.left < 0;

            return { isOffscreen };
        });
    }

    /**
     * @param {Event} ev
     * @private
     */
    handleResize(ev) {
        this.setState({ isOffscreen: DEFAULT_OFFSCREEN }, () => {
            this.checkIfTooltipBodyOffscreen();
        });
    }

    /**
     * @param {Event} ev
     * @private
     */
    handleToggle = ev => {
        this.props.transition(EVENT.TOGGLE);
    };

    /**
     * @param {Event} ev
     * @private
     */
    handleOpen = ev => {
        this.props.transition(EVENT.OPEN);
    };

    /**
     * @param {Event} ev
     * @private
     */
    handleClose = ev => {
        this.props.transition(EVENT.CLOSE);
    };

    /**
     * @param {Event} ev
     * @private
     */
    handleBlur = ev => {
        isFocusWithinContainer(this.tooltipContainer.current, ev, () =>
            this.props.transition(EVENT.CLOSE)
        );
    };

    renderBody() {
        // Render function that gets handlers
        if (typeof this.props.renderBody === 'function') {
            return this.props.renderBody({
                ...this.handlers,
            });
        }

        // Output individual items in divs
        if (Array.isArray(this.props.renderBody)) {
            return this.props.renderBody.map((item, index) => (
                <div key={index}>{item}</div>
            ));
        }

        return this.props.renderBody;
    }

    render() {
        return (
            <div ref={this.tooltipContainer} className={this.rootClassNames}>
                <div className="tooltip-trigger">
                    {this.props.children({ ...this.handlers })}
                </div>
                <div ref={this.tooltipBodyContainer} className="tooltip-body">
                    <div
                        className="tooltip-body-content"
                        tabIndex={
                            // This makes the close handler related target work
                            this.isShowing ? '0' : null
                        }
                        onBlur={this.handleBlur}
                    >
                        {this.renderBody()}
                    </div>
                </div>
            </div>
        );
    }
}

export default withStateMachine(statechart)(Tooltip);
export const Cooltip = withStateMachine(statechart)(Tooltip);
