import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import renderHelloBar from './hello-bar.html';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-hello-bar';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    CONTENT: `[${ELEMENT_TAG_NAME}-content]`,
    CTA_LINK: `[${ELEMENT_TAG_NAME}-cta]`,
    BANNER_MESSAGE: `[${ELEMENT_TAG_NAME}-banner-message]`,
    BANNER_CTA: `[${ELEMENT_TAG_NAME}-banner-cta]`,
    SKIP_LINK: `[${ELEMENT_TAG_NAME}-skip]`,
};

/**
 * A bar that pops out of the top of the page with some marketing text and cta
 *
 * REQUIRED BASE MARKUP:
 * ---
 * <pfdc-hello-bar></pfdc-hello-bar>
 * ---
 *
 * @extends PFElement
 */
export class PFDCHelloBarElement extends PFElement {
    /**
     * An instance of the element is created or upgraded. Useful for initializing state,
     * settings up event listeners, or creating shadow dom.
     */
    constructor() {
        super();

        this.addEventListener('click', this.onClicked);
    }

    /**
     * Initialize this component
     */
    async onInit() {
        if (!this.isRendered) {
            this.render();
        }
    }

    /**
     * Renders the hello bar and caches some elements from within
     */
    render() {
        this.innerHTML = renderHelloBar();
        this.isRendered = true;

        /**
         * Reference to the inner content of the hello bar
         * @type {element}
         */
        this.content = this.querySelector(ELEMENT_SELECTORS.CONTENT);

        /**
         * Reference to the hello bar cta link
         * @type {element}
         */
        this.ctaLink = this.querySelector(ELEMENT_SELECTORS.CTA_LINK);

        /**
         * Reference to the skip to content link
         * @type {element}
         */
        this.skipLink = this.querySelector(ELEMENT_SELECTORS.SKIP_LINK);

        /**
         * Reference to the purina banner message in small screen
         * @type {element}
         */

        this.bannerCtas = this.querySelectorAll(ELEMENT_SELECTORS.BANNER_CTA);

        /**
         * Reference to the purina banner message in small screen
         * @type {element}
         */

        this.bannerMessage = this.querySelector(ELEMENT_SELECTORS.BANNER_MESSAGE);
    }

    /**
     * Handles click events
     * @param {Object} ev the event object
     */
    onClicked(ev) {
        if (ev.target === this.skipLink) {
            this.dispatchAction('analytics', { eventId: 'Consumer423' });
        }
        if (ev.target === this.ctaLink) {
            this.updateCookie();
            this.dispatchAction('analytics', { eventId: 'Consumer089' });
        }
        if (this.accordionIcon.contains(ev.target)) {
            this.dispatchAction('analytics', { eventId: 'Consumer090' });
            this.toggleBannerMessage();
        }
    }
}

export default PFDCHelloBarElement;
