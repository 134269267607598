import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import Cookies from 'js-cookie';
import ensightenTracking from '../../../../core/scripts/elements/pf-app/ensighten/ensighten';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-pet-card';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    LINKS: `[${ELEMENT_TAG_NAME}-link]`,
    MAIN_LINK: '.petCard-link',
};

/**
 * Various specific component classes as strings
 * @type {Object}
 */
const CLASSES = {
    HAS_FOCUS_INSIDE: 'petCard_focusInside',
    MAIN_LINK_FOCUS: 'petCard_linkFocus',
};

/**
 * Attribute names
 * @type {Object}
 */
const ATTRIBUTES = {
    PET_ID: 'pet-id',
    PET_MATCH_TYPE: 'pet-match-type',
    LINK: `${ELEMENT_TAG_NAME}-link`,
    RECOMMENDATION: `${ELEMENT_TAG_NAME}-recommendation`,
    LAST_PET_COOKIE: 'set-last-pet-cookie',
    OFFSET: 'offset',
    PET_SPECIES: 'pet-species',
    ORGANIZATION_ID: 'org-id',
    ORGANIZATION_NAME: 'org-name',
    COMPONENT: 'component',
};

/**
 * Name for the cookie
 * @type {string}
 */
const COOKIE_NAME = 'last_pet_search';

/**
 *
 * @extends PFElement
 */
export class PFDCPetCardElement extends PFElement {
    /**
     * Static reference to this elements' tag name
     * @type {string}
     */
    static get is() {
        return ELEMENT_TAG_NAME;
    }

    get petId() {
        return this.getAttribute(ATTRIBUTES.PET_ID);
    }

    get petMatchType() {
        return this.getAttribute(ATTRIBUTES.PET_MATCH_TYPE);
    }

    get offset() {
        return parseInt(this.getAttribute(ATTRIBUTES.OFFSET), 10);
    }

    get species() {
        return this.getAttribute(ATTRIBUTES.PET_SPECIES);
    }

    onLinkClickedHandler = this.onLinkClicked.bind(this);

    onInit() {
        this.mainLink = this.querySelector(ELEMENT_SELECTORS.MAIN_LINK);

        this.addEventListener('click', this.onClicked);

        this.setupFocusHandlers();

        // Mouseenter to add z-index to fix safari intersection observer hover
        // issue with lazy loaded thumbnail images
        this.addEventListener('mouseenter', () => {
            window.requestAnimationFrame(() => {
                this.style.zIndex = 1;
            });
        });
    }

    /**
     * Sets up some focus in and out handlers on this component
     */
    setupFocusHandlers() {
        this.addEventListener('focusin', ev => {
            this.classList.add(CLASSES.HAS_FOCUS_INSIDE);
        });
        this.addEventListener('focusout', ev => {
            this.classList.remove(CLASSES.HAS_FOCUS_INSIDE);
        });

        this.addEventListener(
            'focus',
            ev => {
                if (ev.target === this.mainLink) {
                    this.classList.add(CLASSES.MAIN_LINK_FOCUS);
                }
            },
            true
        );
        this.addEventListener(
            'blur',
            ev => {
                if (ev.target === this.mainLink) {
                    this.classList.remove(CLASSES.MAIN_LINK_FOCUS);
                }
            },
            true
        );
    }

    onLinkClicked() {
        // special case for member profile pages
        const component = this.getAttribute(ATTRIBUTES.COMPONENT);
        const componentIsMemberProfile = component === 'member_one' || component === 'member_two';
        if (componentIsMemberProfile) {
            /* eslint-disable camelcase, no-undefined */
            window.dataLayer.push(
                {
                    event: 'view_pet',
                    ga4: true,
                    event_params: {
                        method: 'member profile pages',
                        page_type: 'member profile pages',
                        pet_species: this.species ? this.species.toLowerCase() : undefined,
                    },
                },
                { event_params: undefined }
            );
        } else {
            // normal pet card click event
            ensightenTracking.eventConsumer068(this.petId, this.petMatchType, this.species);

            // if recommendation module, we need to trigger additional analytics
            if (this.hasAttribute(ATTRIBUTES.RECOMMENDATION)) {
                ensightenTracking.eventConsumer029({ trigger: this });
                ensightenTracking.eventConsumer030({ trigger: this });
            }

            if (this.hasAttribute(ATTRIBUTES.LAST_PET_COOKIE)) {
                const offset = this.offset;
                const url = new URL(window.location.href);
                if (offset) {
                    url.searchParams.append('o', offset);
                    Cookies.set(COOKIE_NAME, url.toString(), { path: '/' });
                }
            }
        }
    }

    /**
     * Set URL for Pet Card Clicked
     * Add the pet card offset as a query param to the url and
     * replace the most recent entry in the history stack.
     * @private
     */
    _setUrlForPetCardClicked() {
        const offset = this.offset;
        const url = new URL(window.location.href);
        if (offset) {
            url.searchParams.append('o', offset);
            history.replaceState(null, null, url.href);
        }
    }

    /**
     * Handles all click events
     * @param  {Object} ev event object
     */
    onClicked(ev) {
        // main link click
        if (this.mainLink.contains(ev.target) || ev.target.hasAttribute(ATTRIBUTES.LINK)) {
            this.onLinkClicked();
            this._setUrlForPetCardClicked();
        }
    }
}

export default PFDCPetCardElement;
