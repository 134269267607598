import Modernizr from 'modernizr';

/**
 * Test for modern dom features
 * @return {boolean}
 */
function detectDomFeatures() {
    if (
        'remove' in Element.prototype &&
        'matches' in Element.prototype &&
        'contains' in Element.prototype &&
        'closest' in Element.prototype
    ) {
        return true;
    }
    return false;
}

function detectCustomElements() {
    return window.customElements;
}

/**
 * Test for Interesection Observer
 * @return {boolean}
 */
function detectIntersectionObserver() {
    // Exits early if all IntersectionObserver and IntersectionObserverEntry
    // features are natively supported.
    if (
        'IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
        // Minimal polyfill for Edge 15's lack of `isIntersecting`
        // See: https://github.com/w3c/IntersectionObserver/issues/211
        if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
            Object.defineProperty(
                window.IntersectionObserverEntry.prototype,
                'isIntersecting',
                {
                    get: function() {
                        // eslint-disable-line object-shorthand
                        return this.intersectionRatio > 0;
                    },
                }
            );
        }
        return true;
    }
    return false;
}

/**
 * Main detection function that checks if all modernizr tests and
 * manual test functions pass
 * @returns {boolean}
 */
function detectFeatures() {
    const manualTests = [
        detectCustomElements,
        detectDomFeatures,
        detectIntersectionObserver,
    ];

    const modernizrTestResults = Object.keys(Modernizr).every(test => test);

    const manualTestResults = manualTests
        .map(test => test())
        .every(result => result);

    return modernizrTestResults && manualTestResults;
}

export default detectFeatures();
