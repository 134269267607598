import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders container with various live region properties
 * @param {Object} props
 * @returns {string}
 */
function FormErrorSummaryContainer(props) {
    return (
        <div aria-live="assertive" aria-atomic="true">
            {props.hasError && props.children}
        </div>
    );
}

FormErrorSummaryContainer.propTypes = {
    children: PropTypes.node.isRequired,
    hasError: PropTypes.bool.isRequired,
};

export default FormErrorSummaryContainer;
