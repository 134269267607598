/**
 * # Event Constants
 */

/**
 * ## General
 */

// Fired when fonts are loaded
export const EV_APP_READY = 'ready';

// Fired when an input element is autofilled by the browser
export const EV_AUTOFILL = 'autofill';

// Fired when there has been an analytics event
export const EV_ANALYTICS = 'analytics';

// Fired when a window resize has completed
export const EV_RESIZE_COMPLETE = 'resize.complete';
export const EV_RESIZE_WIDTH_COMPLETE = 'resize.width.complete';
export const EV_RESIZE_HEIGHT_COMPLETE = 'resize.height.complete';

// Fired when a resize has caused a change in monitored breakpoints
export const EV_RESIZE_BREAKPOINT_CHANGE = 'resize.breakpoint-change';

/**
 * ## UI Activation
 */

// Fired when a component must be activated
export const EV_UI_ACTIVATE = 'ui.activate';

// Fired when a component must be deactivated
export const EV_UI_DEACTIVATE = 'ui.deactivate';

// Fired when all ui components must be deactivated
export const EV_UI_DEACTIVATE_ALL = 'ui.deactivate-all';

// Fired when a component must be toggled
export const EV_UI_TOGGLE_ACTIVATION = 'ui.toggle-activation';

// Fired when a component's data needs to be sorted
export const EV_UI_SORT = 'ui.sort';

// Fired when a component's date range changes
export const EV_UI_DATE_RANGE_CHANGE = 'ui.date-change';

// Fired when an animal's adoption status changes
export const EV_UI_ADOPT_STATUS_CHANGE = 'ui.status-change';

// Fired when the adoption status change modal is canceled/closed
export const EV_UI_STATUS_CHANGE_CANCEL = 'ui.status-change-cancel';

// Enables or disables listening for events in a component
export const EV_DISABLE = 'ui.disable';
export const EV_ENABLE = 'ui.enable';

/**
 * ## Data
 */

// Fired when shared component data updates
export const EV_DATA_UPDATE = 'data.update';

// Tracks asynchronous actions for showing loading indicators, disabling inputs, etc.
export const EV_ASYNC_START = 'async.start';
export const EV_ASYNC_END = 'async.end';

/**
 * ## Component Specific events
 */

// pf-autocomplete
export const EV_AUTOCOMPLETE_SELECTION = 'autocomplete.selection';
export const EV_AUTOCOMPLETE_CANCELED = 'autocomplete.canceled';

// pfdc-carousel-nav
export const EV_CAROUSEL_NAV_CLICK = 'carousel-nav.click';

// pf-clipboard
export const EV_CLIPBOARD_COMMAND = 'clipboard.command';

// pf-render
export const EV_RENDER_TEMPLATE_COMPLETE = 'render-template.complete';

// pfdc-modal
export const EV_MODAL_DID_ACTIVATE = 'ui.ev.modal.did.activate';
export const EV_MODAL_DID_DEACTIVATE = 'ui.ev.modal.did.deactivate';

// pfdc-quickview
export const EV_QUICKVIEW_SELECT_SLIDE = 'quickview.set-selected';

// pfdc-form
export const EV_FORM_VAULES_UPDATE = 'form.values.update';
export const EV_FORM_STATUS_UPDATE = 'form.status.update';

// pfdc-hello-bar
export const EV_HELLO_BAR_SHOW = 'hello-bar.show';
export const EV_HELLO_BAR_HIDE = 'hello-bar.hide';

// pdc-postal-code-input
export const EV_COUNTRY_CHANGE = 'country.change';

// pfdc-fap-controller
export const EV_FAP_SET_ANIMAL = 'fap.set-animal';
export const EV_FAP_SET_LOCATION = 'fap.set-location';
export const EV_FAP_SEARCH = 'fap.search';

// pfdc-fap-location-modal
export const EV_FAP_LOCATION_MODAL_ACTIVATE = 'fap-location-modal.activate';

// pfdc-saved-search-list
export const EV_SAVED_SEARCH_LIST_LOADED = 'saved-search-list.loaded';

// pfdc-tooltip-controller
export const EV_TOOLTIP_SHOW = 'tooltip.show';
export const EV_TOOLTIP_HIDE = 'tooltip.hide';

//pfdc-sticky
export const EV_STICKY_DEACTIVATE = 'sticky.deactivate';

// ShelterSearchEvents
export const EV_SHELTERSEARCH_NEXTPAGE = 'sheltersearch.next';
export const EV_SHELTERSEARCH_PREVPAGE = 'sheltersearch.previous';
export const EV_SHELTERSEARCH_RENDERED = 'sheltersearch.rendered';
