import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import {
    KEY_LEFT,
    KEY_ARROW_LEFT,
    KEY_RIGHT,
    KEY_ARROW_RIGHT,
} from '../../constants/keyboard';

import Flickity from 'flickity';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-fullscreen-carousel';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    CAROUSEL: `${ELEMENT_TAG_NAME}-body`,
};

/**
 * State classes for CSS
 * @const
 * @type {string}
 */
const CLASSES = {
    PRE_JS: 'petCarousel_preJs',
};

const SWIPE_DIR_LEFT = 'left';
const SWIPE_DIR_RIGHT = 'right';

/**
 * @class PFDCFullscreenCarouselElement
 * @extends PFElement
 */
export class PFDCFullscreenCarouselElement extends PFElement {
    /**
     * slide elements
     * @type {Array}
     */
    get flickitySlides() {
        return this.flickity.getCellElements();
    }

    /**
     * An instance of the element is created or upgraded. Useful for initializing state,
     * settings up event listeners, or creating shadow dom.
     */
    constructor() {
        super();

        /**
         * Flickity instance.
         *
         * @property flickity
         * @type {HTMLElement}
         */
        this.flickity = null;

        /**
         * Carousel container element passed to flickity.
         *
         * @property carousel
         * @type {HTMLElement}
         */
        this.carousel = null;

        this.currentIndex = null;
        this.swipeDirection = null;
    }

    onInit() {
        this.carousel = this.querySelector(`[${ELEMENT_SELECTORS.CAROUSEL}]`);
        this.alertContainer = this.querySelector('[pfdc-pet-carousel-alert]');

        // analytic events triggered during swiping are queued here until onSettled resolves them
        this.analyticQueue = [];

        const options = {
            imagesLoaded: true,
            percentPosition: true,
            wrapAround: true,
            friction: 0.5,
            selectedAttraction: 0.08,
            prevNextButtons: true,
            lazyLoad: 1,
            pageDots: false,
            accessibility: true, // because flickity safari arrows keys not working
        };

        this.flickity = new Flickity(this.carousel, options);

        window.f = this.flickity;

        this.flickity.on('staticClick', this.onStaticClicked.bind(this));
        this.flickity.on('select', this.onSelected.bind(this));
        this.flickity.on('settle', this.onSettled.bind(this));
        this.flickity.on('dragMove', this.onDragMove.bind(this));
        this.flickity.on('dragEnd', this.onDragEnd.bind(this));

        this.addEventListener('click', this.onClicked);

        this.onKeyDownHandler = this.onKeyDown.bind(this);
    }

    addKeydownEventListener() {
        window.addEventListener('keydown', this.onKeyDownHandler);
    }

    removeKeydownEventListener() {
        window.removeEventListener('keydown', this.onKeyDownHandler);
    }

    /**
     * Sets the alert container text to the current slide img or video alt text
     */
    setAlertContainerTextToSelectedImg() {
        if (this.alertContainer) {
            if (this.flickity.selectedElement.getAttribute('alt') !== null) {
                this.alertContainer.innerText = this.flickity.selectedElement.getAttribute(
                    'alt'
                );
            } else {
                this.alertContainer.innerText = 'Changed slide';
            }
        }
    }

    setAriaActive() {
        this.flickity.cells.forEach(cell => {
            cell.element.setAttribute('aria-hidden', 'true');
            const iframe = cell.element.querySelector('iframe');
            if (iframe) {
                iframe.style.display = 'none';
            }
        });
        if (this.flickity.selectedElement.classList.contains('is-selected')) {
            this.flickity.selectedElement.setAttribute('aria-hidden', 'false');
            const iframe = this.flickity.selectedElement.querySelector(
                'iframe'
            );
            if (iframe) {
                iframe.style.display = 'block';
            }
        }
    }

    /**
     * On click of next/previous arrow buttons, trigger an event for analytics. Triggered on
     * all clicks to the element, so triaging based on whether it was next or prev btn.
     *
     * @method triggerFullscreenAnalytics
     * @param {Event} ev
     */
    triggerDirectionSelectionAnalytics(ev) {
        const nextButton =
            ev.target.classList.contains('flickity-prev-next-button') &&
            ev.target.classList.contains('next');
        const previousButton =
            ev.target.classList.contains('flickity-prev-next-button') &&
            ev.target.classList.contains('previous');
        if (!nextButton && !previousButton) {
            return;
        }
        const eventId = nextButton
            ? 'Consumer259_261_263'
            : 'Consumer260_262_264';
        this.dispatchAction('analytics', {
            eventId,
            animalId: this.getAttribute('animal-id'),
            member: this.hasAttribute('analytics-member'),
        });
    }

    /**
     * Determines which direction was swiped based off of flickity move vector on drag events
     * @param {number} moveVector
     * @returns {string} left or right
     */
    getSwipeDirection(moveVector) {
        let swipeDirection = null;
        if (moveVector.x > 0) {
            swipeDirection = SWIPE_DIR_RIGHT;
        } else {
            swipeDirection = SWIPE_DIR_LEFT;
        }
        return swipeDirection;
    }

    /**
     * Go to previous slide
     * @method prevSlide
     */
    prevSlide() {
        this.flickity.previous(true, false);
        this.prevSlideAnalytics();
    }

    /**
     * Go to previous slide
     * @method nextSlide
     */
    nextSlide() {
        this.flickity.next(true, false);
        this.nextSlideAnalytics();
    }

    /**
     * Analytics to fire on prev slide action
     * @method prevSlideAnalytics
     */
    prevSlideAnalytics() {
        this.dispatchAction('analytics', {
            eventId: 'Consumer260_262_264',
            animalId: this.getAttribute('animal-id'),
            member: this.hasAttribute('analytics-member'),
        });
    }

    /**
     * Analytics to fire on next slide action
     * @method nextSlideAnalytics
     */
    nextSlideAnalytics() {
        this.dispatchAction('analytics', {
            eventId: 'Consumer259_261_263',
            animalId: this.getAttribute('animal-id'),
            member: this.hasAttribute('analytics-member'),
        });
    }

    /**
     * Handler for keydown events
     * @param {Event} ev
     */
    onKeyDown(ev) {
        const prevKeys = [KEY_ARROW_LEFT, KEY_LEFT];
        const nextKeys = [KEY_ARROW_RIGHT, KEY_RIGHT];
        if (prevKeys.includes(ev.key)) {
            ev.preventDefault();
            this.prevSlide();
            this.setAlertContainerTextToSelectedImg();
        }
        if (nextKeys.includes(ev.key)) {
            ev.preventDefault();
            this.nextSlide();
            this.setAlertContainerTextToSelectedImg();
        }
    }

    /**
     * Handler for drag movement events
     * @param {Event} ev
     * @param {Event} pointer
     * @param {Object} moveVector
     */
    onDragMove(ev, pointer, moveVector) {
        this.currentIndex = this.flickity.selectedIndex;
        this.swipeDirection = this.getSwipeDirection(moveVector);
    }

    /**
     * Handler for drag end events
     * @param {Event} ev
     * @param {Event} pointer
     */
    onDragEnd(ev, pointer) {
        const swipeDirection = this.swipeDirection;

        const queuedEvent = () => {
            if (this.flickity.selectedIndex !== this.currentIndex) {
                if (swipeDirection === SWIPE_DIR_LEFT) {
                    this.nextSlideAnalytics();
                } else {
                    this.prevSlideAnalytics();
                }
            }
        };

        this.analyticQueue.push(queuedEvent);
    }

    /**
     * Handler for standard click events
     * @param {Event} ev
     */
    onClicked(ev) {
        this.triggerDirectionSelectionAnalytics(ev);
    }

    /**
     * Handler for flickity `staticClick` events.
     *
     * @method onStaticClicked
     * @param {Event} ev
     * @param {Integer} pointer
     * @param {HTMLElement} cellElement
     * @param {Integer} cellIndex
     */
    onStaticClicked(ev, pointer, cellElement, cellIndex) {
        this.flickity.select(cellIndex);
    }

    /**
     * Handler for flickity select events
     */
    onSelected() {
        this.setAlertContainerTextToSelectedImg();
        this.setAriaActive();
    }

    /**
     * Handler for flickity settle events
     */
    onSettled() {
        this.analyticQueue.forEach(func => func());
        this.analyticQueue = [];

        this.dispatchAction('pet-carousel.slide-settle', {
            imgIndex: this.flickity.selectedIndex,
        });
    }

    /**
     * Handles update events from the app.
     *
     * @method onUpdated
     * @param  {Object} ev - event object
     */
    onUpdated(ev) {
        const { detail } = ev;
        switch (detail.type) {
            case 'fullscreen-carousel.open':
                this.addKeydownEventListener();
                requestAnimationFrame(() => {
                    this.flickity.resize();
                    this.flickity.select(detail.imgIndex, true, true);
                });
                break;
            case 'fullscreen-carousel.close':
                this.removeKeydownEventListener();
                this.dispatchAction('pet-carousel.slide-change', {
                    imgIndex: this.flickity.selectedIndex,
                });
                break;
            default:
                break;
        }
    }
}
