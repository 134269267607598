import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _upperFirst from 'lodash/upperFirst';

import OptinsConfigService from '../../../config-services/optinsConfigService';

import Checkbox from '../../breeds/specifics/Checkbox';

/**
 * Handles rendering of the optin checkboxes this component. This component uses the
 * "fully uncontrolled component with a key" approach to resetting state
 * for the checkboxes. This component needs a unique key when used so that React will
 * create a new component instance rather than update the current one.
 * For more info read here:
 * https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-controlled-component
 * @class Optins
 * @extends React.Component
 */
class Optins extends Component {
    /**
     * @type {Object}
     */
    state = {
        checkboxes: {},
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        corporation: PropTypes.arrayOf(PropTypes.string).isRequired,
        checkboxNames: PropTypes.arrayOf(PropTypes.string).isRequired,
        formId: PropTypes.string.isRequired,
        selectedCountryCode: PropTypes.string,
        theme: PropTypes.string,
        onChange: PropTypes.func,
        dataTestPrepend: PropTypes.string,
        errors: PropTypes.object,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        selectedCountryCode: '',
        theme: 'primary',
        onChange: null,
        dataTestPrepend: '',
        errors: null,
    };

    get optinRules() {
        const rules = OptinsConfigService.getOptinRulesFromConfig({
            formId: this.props.formId,
            country: this.props.selectedCountryCode,
            corporation: this.props.corporation,
        });
        return rules;
    }

    componentDidMount() {
        this.setInitialCheckboxState();
    }

    /**
     * This sets the optin checkboxes to the proper state based on optin rules pre_check_checkbox
     */
    setInitialCheckboxState() {
        const { checkboxNames } = this.props;

        this.setState(prevState => {
            const checkboxes = {};

            if (Array.isArray(this.optinRules)) {
                this.optinRules.forEach((rule, index) => {
                    checkboxes[checkboxNames[index]] = rule['pre_check_checkbox'];
                });
            }

            if (typeof this.props.onChange === 'function') {
                this.props.onChange(checkboxes);
            }

            return { ...prevState, checkboxes };
        });
    }

    getClassNames() {
        const { theme } = this.props;

        return buildClassNames({
            ['optins']: true,
            [`optins_theme${_upperFirst(theme)}`]: true,
        });
    }

    /**
     * Gets an object with the true/false value of each checkbox
     * @returns {Object}
     */
    getValue() {
        const { checkboxes } = this.state;
        return checkboxes;
    }

    /**
     * Main concern is updating the internal state for controlled checkboxes
     * @param {Event} ev
     */
    handleCheckboxChange = ev => {
        const checkboxName = ev.target.name;

        this.setState(prevState => {
            const nextState = {
                ...prevState,
                checkboxes: {
                    ...prevState.checkboxes,
                    [`${checkboxName}`]: !prevState.checkboxes[checkboxName],
                },
            };

            if (typeof this.props.onChange === 'function') {
                this.props.onChange(nextState.checkboxes);
            }

            return nextState;
        });
    };

    render() {
        const { checkboxes } = this.state;
        const { checkboxNames, theme, corporation, dataTestPrepend } = this.props;

        return (
            <div className={this.getClassNames()}>
                {Array.isArray(this.optinRules) &&
                    this.optinRules.map((rule, index) => {
                        const { display_checkbox: displayCheckbox, display_copy: label } = rule;
                        if (label.length > 0) {
                            return (
                                <div key={checkboxNames[index]} className="u-vr6x">
                                    <Checkbox
                                        displayCheckbox={displayCheckbox}
                                        id={`Optin_${index}`}
                                        isChecked={checkboxes[checkboxNames[index]]}
                                        label={label}
                                        name={checkboxNames[index]}
                                        round={true}
                                        theme={theme}
                                        onChange={this.handleCheckboxChange}
                                        dataTestValue={`${dataTestPrepend}_${corporation[index]}_Optin_Checkbox`}
                                    />
                                    {this.props.errors && this.props.errors[checkboxNames[index]] && (
                                        <div className="field-extras-errors">
                                            {this.props.errors[checkboxNames[index]]}
                                        </div>
                                    )}
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
            </div>
        );
    }
}

export default Optins;
