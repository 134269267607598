import React, { Fragment } from 'react';

import {
    COUNTRY_CHOOSE_LABEL,
    COUNTRY_NOT_LISTED_VALUE,
} from '../../../../../core/scripts/constants/geography';

import AlertBoxCountryNotListed from '../AlertBoxCountryNotListed';

import { FIELDS } from './formType';

/**
 * @type {string}
 */
export const ZIP_CODE = 'ZIP code';

/**
 * @type {Object}
 */
export default {
    fields: {
        [FIELDS.COUNTRY]: {
            label: 'Country',
            nullOption: { value: '', text: COUNTRY_CHOOSE_LABEL },
        },
        [FIELDS.POSTAL_CODE]: {
            label: 'Postal code',
        },
        [FIELDS.NEXT]: {
            type: 'hidden',
        },
        [FIELDS.NEXT_PARAMS]: {
            type: 'hidden',
        },
        [FIELDS.TOKEN]: {
            type: 'hidden',
        },
        [FIELDS.PROVIDER]: {
            type: 'hidden',
        },
        [FIELDS.FIRST_NAME]: {
            type: 'hidden',
        },
        [FIELDS.LAST_NAME]: {
            type: 'hidden',
        },
    },
    template: locals => {
        const countryNotListedSelected =
            locals.value[FIELDS.COUNTRY] === COUNTRY_NOT_LISTED_VALUE;

        return (
            <Fragment>
                <div className="vrArray m-vrArray_4x">
                    {locals.hasError && <div>{locals.error}</div>}
                    <div>
                        {locals.inputs[FIELDS.COUNTRY]}
                        {countryNotListedSelected && (
                            <div className="u-vrTop2x">
                                <AlertBoxCountryNotListed />
                            </div>
                        )}
                    </div>
                    <div>{locals.inputs[FIELDS.POSTAL_CODE]}</div>
                </div>
                {locals.inputs[FIELDS.FIRST_NAME]}
                {locals.inputs[FIELDS.LAST_NAME]}
                {locals.inputs[FIELDS.NEXT]}
                {locals.inputs[FIELDS.NEXT_PARAMS]}
                {locals.inputs[FIELDS.TOKEN]}
                {locals.inputs[FIELDS.PROVIDER]}
            </Fragment>
        );
    },
};
