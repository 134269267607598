import AutowiredElement from './AutowiredElement';
import Utils from './Utils';

const SPACE_SPLIT_REGEX = / *, */;

export default class ObserveStateElement extends AutowiredElement {
    _unsubscribeFunctions = [];

    /**
     * @type {StateController}
     */
    get stateController() {
        throw new Error('Not implemented');
    }

    get observedStateAddress() {
        return this.getAttribute('observe-state');
    }

    get observedState() {
        const stateAddr = this.observedStateAddress;

        if (!stateAddr) {
            return null;
        }

        const observedState = (this.isAdvancedObserve(stateAddr))
            ? this.getAdvancedObservedState(stateAddr)
            : this.getBasicObservedState(stateAddr);

        return observedState;
    }

    get renderOnStateChangeAddresses() {
        const addressesStr = this.getAttribute('render-on-state-change') || '';

        if (!addressesStr) {
            return [];
        }

        const addresses = addressesStr.split(SPACE_SPLIT_REGEX);
        return addresses;
    }

    get disableObservedStateRendering() {
        return this.hasAttribute('disable-observed-state-rendering');
    }

    getBasicObservedState(stateAddr) {
        return Utils.ObjectUtils.access(stateAddr, this.stateController.state);
    }

    getAdvancedObservedState(stateAddr) {
        const statesToObserve = Utils.StringUtils.parseAttributeValue(stateAddr);

        const observedState = {};
        // eslint-disable-next-line
        for (const id in statesToObserve) {
            const address = statesToObserve[id];
            const state = this.stateController.state;
            observedState[id] = Utils.ObjectUtils.access(address, state);
        }

        return observedState;
    }

    get viewModel() {
        // observed state is variable, and could be something like an array
        // we must be careful in how we put this together
        const base = Utils.ArrayUtils.isArray(this.observedState)
            ? []
            : {};

        return Object.assign(base, {
            ...super.viewModel,
            observedState: this.observedState,
            observedStateAddress: this.observedStateAddress,
        });
    }

    onConnected() {
        super.onConnected();

        this._checkStateIntegration();
    }

    onDisconnected() {
        this._unsubscribeFunctions.forEach(fn => fn());
        super.onDisconnected();
    }

    // TODO: this function is not really a part of the 'observe' state ecosystem...
    // this file has grown beyond that and thus should probably be renamed accordingly
    subscribe(callback, whatToObserve) {
        this._unsubscribeFunctions.push(this.stateController.subscribe(callback, whatToObserve));
    }

    setObservedState(modifications) {
        this.stateController.setStateAtAddress(modifications, this.observedStateAddress);
    }

    setObservedStateAtAddress(modifications, address) {
        this.stateController.setStateAtAddress(
            modifications,
            `${this.observedStateAddress}.${address}`
        );
    }

    patchObservedState(modifications) {
        this.stateController.patchState(this._buildFullyQualifiedModifications(modifications));
    }

    _buildFullyQualifiedModifications(modifications) {
        const observedStateAddress = this.observedStateAddress;
        if (!observedStateAddress) {
            throw new Error('Trying to patch without an observe-state attribute provided');
        }

        return Utils.ObjectUtils.prependStructureWithAddress(observedStateAddress, modifications);
    }

    isAdvancedObserve(stateAddr) {
        // TODO: find a better way to determine this
        return stateAddr.indexOf(':') !== -1;
    }

    // TODO: categorize
    _checkStateIntegration() {
        this._initObserving();
        this._initStateChangeRendering();
    }

    _initObserving() {
        const stateAddr = this.observedStateAddress;

        if (!stateAddr) {
            return;
        }

        // determine our observe approach
        if (this.isAdvancedObserve(stateAddr)) {
            this._initAdvancedObserve(stateAddr);
        } else {
            this._initBasicObserve(stateAddr);
        }
    }

    _initBasicObserve(stateAddr) {
        if (typeof this.stateController.stateAt(stateAddr) === 'undefined') {
            const errMsg = `Unable to observe state at "${stateAddr}"; nothing found at this address within the state tree`; // eslint-disable-line
            throw new Error(errMsg);
        }

        this.subscribe(this._onObservedStateChange.bind(this), stateAddr);
    }

    _initAdvancedObserve(stateAddr) {
        const statesToObserve = Utils.StringUtils.parseAttributeValue(stateAddr);

        // eslint-disable-next-line
        for (const identifier in statesToObserve) {
            const address = statesToObserve[identifier];
            this._initBasicObserve(address);
        }
    }

    _onObservedStateChange(payload) {
        const observedModifications = Utils.ObjectUtils.access(
            this.observedStateAddress,
            payload.modifications
        );

        this.onObservedStateChange({
            ...payload,
            observedModifications,
            flattenedObservedModifications: Utils.ObjectUtils.flatten(observedModifications),
        });
    }

    onObservedStateChange(payload) {
        if (this.disableObservedStateRendering) {
            return;
        }

        this.render();
    }

    _initStateChangeRendering() {
        const renderOnStateChangeAddresses = this.renderOnStateChangeAddresses;

        if (!renderOnStateChangeAddresses.length) {
            return;
        }

        this.subscribe(() => this.render(), renderOnStateChangeAddresses);
    }
}
