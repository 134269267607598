import { FIELDS } from './formType';

/**
 * @type {Object}
 */
export default {
    fields: {
        [FIELDS.USERNAME]: {
            type: 'hidden',
        },
        [FIELDS.PASSWORD]: {
            type: 'password',
            label: 'Petfinder account password',
        },
        [FIELDS.NEXT]: {
            type: 'hidden',
        },
        [FIELDS.NEXT_PARAMS]: {
            type: 'hidden',
        },
        [FIELDS.LOGIN_PATH]: {
            type: 'hidden',
        },
        [FIELDS.TOKEN]: {
            type: 'hidden',
        },
        [FIELDS.PROVIDER]: {
            type: 'hidden',
        },
    },
};
