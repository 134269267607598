/**
 * @type {Object}
 */
export const STATE = {
    IDLE: 'idle',
    OPEN_MODAL: 'openModal',
    OPEN_IDLE: 'openModalIdle',
    OPEN_NO_CLOSE: 'openNoClose',
};

/**
 * @type {Object}
 */
export const EVENT = {
    OPEN: 'open',
    CLOSE: 'close',
    TOGGLE_CLOSE: 'toggle',
};

/**
 * @type {Object}
 */
export const ACTION = {
    REFOCUS_TARGET: ['refocusTarget'],
};

/**
 * @type {Object}
 */
export const statechart = {
    initial: STATE.IDLE,
    states: {
        [STATE.IDLE]: {
            on: {
                [EVENT.OPEN]: STATE.OPEN_MODAL,
            },
        },
        [STATE.OPEN_MODAL]: {
            initial: STATE.OPEN_IDLE,
            on: {
                [EVENT.CLOSE]: {
                    [STATE.IDLE]: {
                        actions: ACTION.REFOCUS_TARGET,
                    },
                },
            },
            states: {
                [STATE.OPEN_IDLE]: {
                    on: {
                        [EVENT.TOGGLE_CLOSE]: STATE.OPEN_NO_CLOSE,
                    },
                },
                [STATE.OPEN_NO_CLOSE]: {
                    on: {
                        [EVENT.TOGGLE_CLOSE]: STATE.OPEN_IDLE,
                    },
                },
            },
        },
    },
};
