/**
 * # Template Registry
 *
 * Registers templates that can be consumed by JS.
 */

// Please keep alphabetized by template name
import renderContentToutsTwoUp from '../templates/contentToutsTwoUp.html';
import renderSavedSearchList from '../templates/savedSearchList.html';
import renderRecommendationsFullRow from '../templates/recommendationsFullRowTemplate.html';
import renderRecommendationsFullRowPlaceholder from '../templates/recommendationsFullRowPlaceholderTemplate.html';
import renderRecommendationsTwoRow from '../templates/recommendationsTwoRowTemplate.html';
import renderRecommendationsTwoRowPlaceholder from '../templates/recommendationsTwoRowPlaceholderTemplate.html';
import renderRecommendationsTwoRowSearch from '../templates/recommendationsTwoRowSearchTemplate.html';
import renderRecommendationsTwoRowSearchPlaceholder from '../templates/recommendationsTwoRowSearchPlaceholderTemplate.html';
import renderRecommendationsTwoThirdsRow from '../templates/recommendationsTwoThirdsRowTemplate.html';
import renderRecommendationsTwoThirdsRowPlaceholder from '../templates/recommendationsTwoThirdsRowPlaceholderTemplate.html';
import renderOrganizationSearchPagination from '../templates/organizationSearchPagination.html';
import renderOptinsDefault from '../templates/optinsDefault.html';

export default class TemplateRegistry {
    static get contentToutsTwoUp() {
        return renderContentToutsTwoUp;
    }

    static get savedSearchList() {
        return renderSavedSearchList;
    }

    static get recommendationsTwoRow() {
        return renderRecommendationsTwoRow;
    }

    static get recommendationsTwoRowPlaceholder() {
        return renderRecommendationsTwoRowPlaceholder;
    }

    static get recommendationsTwoRowSearch() {
        return renderRecommendationsTwoRowSearch;
    }

    static get recommendationsTwoRowSearchPlaceholder() {
        return renderRecommendationsTwoRowSearchPlaceholder;
    }

    static get recommendationsFullRow() {
        return renderRecommendationsFullRow;
    }

    static get recommendationsFullRowPlaceholder() {
        return renderRecommendationsFullRowPlaceholder;
    }

    static get recommendationsTwoThirdsRow() {
        return renderRecommendationsTwoThirdsRow;
    }

    static get recommendationsTwoThirdsRowPlaceholder() {
        return renderRecommendationsTwoThirdsRowPlaceholder;
    }

    static get renderOrganizationSearchPagination() {
        return renderOrganizationSearchPagination;
    }

    static get renderOptinsDefault() {
        return renderOptinsDefault;
    }
}
