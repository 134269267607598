import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';
import { offset } from '../../../../core/scripts/util/dom';
import { CLASS_IS_HIDDEN, CLASS_IS_ACTIVE } from '../../constants/classes';
import {
    EV_RESIZE_COMPLETE,
    EV_TOOLTIP_SHOW,
    EV_TOOLTIP_HIDE,
} from '../../constants/events';
import renderTooltip from '../../templates/globalTooltip.html';

/**
 * Reference to the selector for the generated tooltip
 * @const
 * @type {string}
 */
const TOOLTIP_SELECTOR = '[pf-tooltip]';

/**
 * Reference to the selector used on clickable elements to generate and show a tooltip
 * @type {string}
 */
const TOOLTIP_TRIGGER_ATTRIBUTE = 'tooltip';

/**
 * Available position values
 * @type {Object}
 */
const POSITIONS = {
    TOP_CENTER: 'top center',
    BOTTOM_CENTER: 'bottom center',
    TOP: 'top',
    BOTTOM: 'bottom',
    LEFT: 'left',
    RIGHT: 'right',
};

/**
 * Error messages for this component
 * @type {Object}
 */
const ERROR_MSG = {
    NO_POSITION_CONTEXT_SELECTOR: `PFDCTooltipElement: you need to supply
        "positioning-context-selector"`,
    INCORRECT_SELECTOR: `PFDCTooltipElement: you need to supply a proper selector to
        "positioning-context-selector"`,
    INCORRECT_POSITON: `PFDCTooltipElement: you need to supply a proper position value to
        position"`,
};

/**
 * Controls a tooltip that is moved around the page and positioned base on a click event trigger
 * with the below configuration attributes
 *
 * TODO: Deprecate this...
 *
 * REQUIRED MARKUP
 * ---
 * On a clickable block or inline block element.. add these attributes
 *     tooltip
 *     tooltip-text="Link Copied"
 *     tooltip-hide-after="3000"
 *     tooltip-position="bottom center"
 *     tooltip-x-offset=""
 *     tooltip-y-offset=""
 *     tooltip-z-index=""
 * ---
 *
 * @extends PFElement
 */
export class PFDCTooltipControllerElement extends PFElement {
    /**
     * Initialize this component
     */
    onInit() {
        /**
         * Reference to the tooltip text value
         * @type {string}
         */
        this.tooltipText = '';

        this.onClickHandler = this.onClicked.bind(this);
        document.addEventListener('click', this.onClickHandler);

        // Initial render
        this.renderTooltip();
    }

    /**
     * Render the tooltip template
     */
    renderTooltip() {
        if (this.tooltip) {
            this.tooltip.remove();
        }
        document.body.insertAdjacentHTML(
            'beforeend',
            renderTooltip({ text: this.tooltipText })
        );
        this.tooltip = document.querySelector(TOOLTIP_SELECTOR);
        this.tooltip.classList.add(CLASS_IS_HIDDEN);
        this.tooltip.style.display = 'block';
        this.tooltip.style.position = 'absolute';
        this.tooltip.style.top = '0';
        this.tooltip.style.left = '0';
        this.tooltip.style.zIndex = '4';
    }

    /**
     * Position the tooltip based off the current event triggering element
     * @param {Object} trigger the trigger event
     */
    positionTooltip(trigger = this.currentTrigger) {
        if (!trigger) {
            return;
        }
        const pos =
            trigger.getAttribute(`${TOOLTIP_TRIGGER_ATTRIBUTE}-position`) ||
            POSITIONS.BOTTOM_CENTER;
        const yo =
            Number(
                trigger.getAttribute(`${TOOLTIP_TRIGGER_ATTRIBUTE}-y-offset`)
            ) || 0;
        const xo =
            Number(
                trigger.getAttribute(`${TOOLTIP_TRIGGER_ATTRIBUTE}-x-offset`)
            ) || 0;
        const z =
            Number(
                trigger.getAttribute(`${TOOLTIP_TRIGGER_ATTRIBUTE}-z-index`)
            ) || 4;

        const co = offset(trigger);
        const cw = trigger.offsetWidth;
        const th = this.tooltip.offsetHeight;
        const tw = this.tooltip.offsetWidth;
        let topPos = `${co.top + yo}px`;
        let leftPos = `${co.left + xo}px`;

        if (pos && Object.values(POSITIONS).find(item => item === pos)) {
            switch (pos) {
                case POSITIONS.BOTTOM_CENTER:
                    topPos = `${co.top + th + yo}px`;
                    leftPos = `${co.left + cw / 2 - tw / 2 + xo}px`;
                    break;
                case POSITIONS.BOTTOM:
                    topPos = `${co.top + th + yo}px`;
                    break;
                case POSITIONS.TOP_CENTER:
                    topPos = `${co.top - th - yo}px`;
                    leftPos = `${co.left + cw / 2 - tw / 2 + xo}px`;
                    break;
                case POSITIONS.TOP:
                    topPos = `${co.top - th - yo}px`;
                    break;
                case POSITIONS.LEFT:
                    leftPos = `${co.left - tw - xo}px`;
                    break;
                case POSITIONS.RIGHT:
                    leftPos = `${co.left + cw + xo}px`;
                    break;
                default:
                    break;
            }
        } else {
            throw new Error(ERROR_MSG.INCORRECT_POSITON);
        }

        this.tooltip.style.top = topPos;
        this.tooltip.style.left = leftPos;
        this.tooltip.style.zIndex = z;
    }

    /**
     * Flash the tooltip, determines if the trigger has the hide after prop and sets up a timer
     * @param {Object} trigger the trigger event
     */
    flashTooltip(trigger = this.currentTrigger) {
        if (!trigger) {
            return;
        }
        const hideDelay = Number(
            this.currentTrigger.getAttribute(
                `${TOOLTIP_TRIGGER_ATTRIBUTE}-hide-after`
            )
        );

        this.showTooltip();

        if (hideDelay) {
            this.hideDelayTimer = setTimeout(() => {
                this.hideTooltip();
            }, hideDelay);
        }
    }

    /**
     * Show tooltip
     */
    showTooltip() {
        this.tooltip.classList.remove(CLASS_IS_HIDDEN);
        this.tooltip.classList.add(CLASS_IS_ACTIVE);

        // Remove the active class for animation...
        setTimeout(() => {
            this.tooltip.classList.remove(CLASS_IS_ACTIVE);
        }, 10);

        scheduleMicrotask(() => {
            // Use app status text for tooltip
            this.dispatchAction('', { statusText: this.tooltipText });
        });
    }

    /**
     * Hide tooltip
     */
    hideTooltip() {
        this.tooltip.classList.add(CLASS_IS_HIDDEN);
    }

    /**
     * Handles click events, checks if the event has the tooltip trigger attr
     * @param {Object} ev
     */
    onClicked(ev) {
        if (ev.target.hasAttribute(TOOLTIP_TRIGGER_ATTRIBUTE)) {
            this.currentTrigger = ev.target;

            if (
                this.currentTrigger.hasAttribute(
                    `${TOOLTIP_TRIGGER_ATTRIBUTE}-text`
                )
            ) {
                this.tooltipText = this.currentTrigger.getAttribute(
                    `${TOOLTIP_TRIGGER_ATTRIBUTE}-text`
                );
                this.renderTooltip();
            }

            if (this.hideDelayTimer) {
                window.clearTimeout(this.hideDelayTimer);
            }

            this.flashTooltip();
            this.positionTooltip();
        }
    }

    /**
     * Handles update events from the app level
     * @param {Object} ev
     */
    onUpdated(ev) {
        const { detail } = ev;
        if (detail.type === EV_RESIZE_COMPLETE) {
            this.positionTooltip();
        }
        if (detail.type === EV_TOOLTIP_SHOW) {
            this.currentTrigger =
                document.querySelector(detail.selector) || false;
            if (!this.currentTrigger) {
                return;
            }

            if (detail.hideAfter) {
                this.currentTrigger.setAttribute(
                    'tooltip-hide-after',
                    detail.hideAfter
                );
            }

            if (detail.position) {
                this.currentTrigger.setAttribute(
                    'tooltip-position',
                    detail.position
                );
            }

            if (detail.yOffset) {
                this.currentTrigger.setAttribute(
                    'tooltip-y-offset',
                    detail.yOffset
                );
            }

            if (detail.xOffset) {
                this.currentTrigger.setAttribute(
                    'tooltip-x-offset',
                    detail.xOffset
                );
            }

            if (!detail.text) {
                return;
            }
            this.tooltipText = detail.text;
            this.renderTooltip();

            if (this.hideDelayTimer) {
                window.clearTimeout(this.hideDelayTimer);
            }

            this.flashTooltip();
            this.positionTooltip();
        }
        if (detail.type === EV_TOOLTIP_HIDE) {
            this.hideTooltip();
        }
    }
}

export default PFDCTooltipControllerElement;
