import StateController from '../../../../core/scripts/lib/wirey/state/StateController';
import locationCookieService from '../../../../core/scripts/services/locationCookieService';

export const USER_ANIMAL_FILTERS = [
    'age',
    'attribute',
    'breed',
    'coatLength',
    'color',
    'daysOnPetfinder',
    'distance',
    'gender',
    'household',
    'size',
    'species',
    'animalName',
    'shelter',
    'transportable',
];

export class AnimalSearchFiltersStateController extends StateController {
    get defaultState() {
        return {
            daysOnPetfinder: {
                value: [],
                options: [],
            },

            distance: {
                value: [],
                options: [],
            },

            animalType: {
                value: [],
                defaultLabel: null,
                options: [],
            },

            breed: {
                value: [],
                options: [],
                counts: {},
                filtering: {
                    searchText: '',
                    filteredOptions: [],
                },
            },

            age: {
                value: [],
                options: [],
                counts: {},
            },

            gender: {
                value: [],
                options: [],
                counts: {},
            },

            sortBy: {
                value: [],
                options: [],
            },

            resultsPerPage: {
                value: [],
                options: [],
            },

            attribute: {
                value: [],
                options: [],
                counts: {},
            },

            coatLength: {
                value: [],
                options: [],
                counts: {},
            },

            color: {
                value: [],
                options: [],
                counts: {},
                // TODO: remove -- just for testing
                filtering: {
                    searchText: '',
                    filteredOptions: [],
                },
            },

            size: {
                value: [],
                options: [],
                counts: {},
            },

            species: {
                value: [],
                options: [],
                counts: {},
            },

            shelter: {
                value: [],
                options: [],
                selectedOptionsCache: [],
            },

            location: {
                value: [],
                options: [],
                isLoading: false,
            },

            animalName: {
                value: '',
            },

            household: {
                value: [],
                options: [],
                counts: {},
            },

            recentlyViewedPets: {
                value: [],
            },

            transportable: {
                value: false,
            },
        };
    }

    get state() {
        const state = super.state;

        return {
            ...state,
            appliedFilters: this.getAppliedFilters(state),
        };
    }

    /**
     * Helpers
     */

    _copyCountsToOptions(options, counts) {
        return options.map(option => {
            const valueKey =
                'facet_value' in option ? option.facet_value : option.value;
            const facetItem = counts[valueKey];
            return {
                ...option,
                count: facetItem ? facetItem.count : 0,
            };
        });
    }

    /*
     * Gets any defined missing parameters.
     *
     * @returns {Array}
     */
    get missingRequiredParams() {
        const missingParams = [];

        if (!this.state.location.value.length) {
            missingParams.push('location');
        }

        if (!this.state.animalType.value.length) {
            missingParams.push('animalType');
        }
        return missingParams;
    }

    /**
     * Derived values
     */

    /**
     * Provides an array of objects containing the value and additional metadata from the option for the value
     *
     * @param {String} filterName
     * @returns {[{ value: String, label: String, longLabel: String}]}
     * @memberof AnimalSearchFiltersStateController
     */
    _getGenericExpandedFilterValues(filterName) {
        const filterInfo = this.state[filterName];
        return filterInfo.value
            .map(value => {
                const option = this.findFilterOptionByValue(filterName, value);
                if (!option) {
                    return null;
                }

                return {
                    filterName,
                    value,
                    label: option.label,
                    longLabel: option.long_label,
                };
            })
            .filter(item => item !== null);
    }

    findFilterOptionByValue(filterName, filterValue) {
        const filterInfo = this.state[filterName];
        const item = filterInfo.options.find(
            option => option.value === filterValue
        );

        return item || null;
    }

    get appliedFilters() {
        return this.getAppliedFilters(this.state);
    }

    get numFiltersApplied() {
        this.appliedFilters.length;
    }

    // TODO: this should be named 'appliedFilterTypes' or something as it's providing the names of the filters that are currently applied
    getAppliedFilters(filtersState) {
        const activeFilters = [];

        for (const key in filtersState) {
            if (USER_ANIMAL_FILTERS.includes(key)) {
                const value = filtersState[key].value;
                if (Array.isArray(value)) {
                    if (value.length) {
                        activeFilters.push(key);
                    }
                } else {
                    if (value) {
                        activeFilters.push(key);
                    }
                }
            }
        }
        return activeFilters;
    }

    /*
     * Call when a filter tag is to be removed from the active filters list.
     *
     * @param {*} key - the filter type (e.g. age, size, gender, etc)
     * @param {*} value - the filter value (e.g. senior, small, male, etc)
     * @return {function} - key specific return method
     * @memberof AnimalSearchFiltersStateController
     */

    // prettier-ignore
    removeFilterValue(key, value) {
        switch (key) {
            case 'species': return this.removeSpeciesValue(value);
            case 'breed': return this.removeBreedValue(value);
            case 'age': return this.removeAgeValue(value);
            case 'distance': return this.removeDistanceValue();
            case 'size': return this.removeSizeValue(value);
            case 'gender': return this.removeGenderValue(value);
            case 'household': return this.removeHouseholdValue(value);
            case 'attribute': return this.removeAttributeValue(value);
            case 'coatLength': return this.removeCoatLengthValue(value);
            case 'color': return this.removeColorValue(value);
            case 'daysOnPetfinder': return this.removeDaysOnPetfinderValue(value);
            case 'shelter': return this.removeShelterValue(value);
            case 'animalName': return this.removeAnimalNameValue(value);
            case 'transportable': return this.removeTransportableValue();

            default:
                throw new Error(
                    'You are trying to remove a filter that is not typically shown as a filter tag.'
                );
        }
    }

    /**
     * This list of applied filters is used for the display of the applied filters on pet search ie
     * the "chicklet" purple boxes used to additionally show selected filters to user
     * @readonly
     * @memberof AnimalSearchFiltersStateController
     * @returns {Array}
     */
    get expandedAppliedFilters() {
        const expandedAppliedFilters = [
            ...this.daysOnPetfinderExpandedValues,
            ...this.breedExpandedValues,
            ...this.ageExpandedValues,
            ...this.genderExpandedValues,
            ...this.attributeExpandedValues,
            ...this.coatLengthExpandedValues,
            ...this.colorExpandedValues,
            ...this.sizeExpandedValues,
            ...this.speciesExpandedValues,
            ...this.shelterExpandedValues,
            ...this.householdExpandedValues,
        ];

        if (!this.isDefaultDistance()) {
            expandedAppliedFilters.push(...this.distanceExpandedValues);
        }

        if (this.animalName) {
            expandedAppliedFilters.push({
                filterName: 'animalName',
                value: this.animalName,
                label: this.animalName,
                longLabel: this.animalName,
            });
        }

        // We are inverting the display of the transportable so that the "chicklet" shows when the
        // checkbox is in the "not checked" false state
        // TODO: This is currently coupled to the animalSearch checkbox. Note that on breeds pages,
        // we don't need to show this value.
        if (!this.transportable) {
            expandedAppliedFilters.push({
                filterName: 'transportable',
                value: this.transportable,
                label: 'Local pets only',
                longLabel: 'Local pets only',
            });
        }

        return expandedAppliedFilters;
    }

    get completeExpandedAppliedFilters() {
        return [...this.expandedAppliedFilters, ...this.distanceExpandedValues];
    }

    /**
     * Clear all currently applied user pet search filters
     *
     * @memberof AnimalSearchFiltersStateController
     */
    clearAllUserFilters() {
        const appliedFilters = this.appliedFilters;
        for (let i = 0; i < appliedFilters.length; i++) {
            const filterName = appliedFilters[i];
            // use the named filter setter for each iteration
            this[filterName] = null;
        }

        // Reset transportable
        this.transportable = true;
    }

    /* *******************************************
     * Days on Petfinder
     ********************************************/

    /**
     * Gets the currently applied filters for daysOnPetfinder
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get daysOnPetfinder() {
        return this.state.daysOnPetfinder.value;
    }

    /**
     * Gets the expanded values for applied daysOnPetfinder filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get daysOnPetfinderExpandedValues() {
        return this._getGenericExpandedFilterValues('daysOnPetfinder');
    }

    /**
     * Sets daysOnPetfinder filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set daysOnPetfinder(value) {
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'daysOnPetfinder.value');
    }

    set daysOnPetfinderOptions(options) {
        this.setStateAtAddress(options, 'daysOnPetfinder.options');
    }

    /**
     * Removes an individual daysOnPetfinder filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeDaysOnPetfinderValue(value) {
        const values = this.daysOnPetfinder;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.daysOnPetfinder = values;
    }

    /* *******************************************
     * Distance
     ********************************************/
    /**
     * Gets the currently applied filters for distance
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get distance() {
        return this.state.distance.value;
    }

    /**
     * Gets the expanded values for applied distance filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get distanceExpandedValues() {
        return this._getGenericExpandedFilterValues('distance');
    }

    /**
     * @returns {Array<string>}
     */
    get distanceOptions() {
        return this.state.distance.options.slice(0);
    }

    /**
     * Sets distance filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set distance(value) {
        if (Array.isArray(value)) {
            value = value[0];
        }

        let constrainedValue;
        if (value === 'Anywhere') {
            constrainedValue = value;
        } else if (value === null || value <= 0) {
            constrainedValue = this.defaultDistanceValue;
        } else {
            constrainedValue =
                value <= 10
                    ? '10'
                    : value <= 25
                    ? '25'
                    : value <= 50
                    ? '50'
                    : value <= 100
                    ? '100'
                    : value;
        }

        return this.setStateAtAddress([constrainedValue], 'distance.value');
    }

    /**
     * Removes distance value
     * Removal of distance is setting distance to default
     */
    removeDistanceValue() {
        this.distance = this.defaultDistanceValue;
    }

    /**
     * Check to see if the currently set distance is the default (currently 100 mi.)
     * NOTE: Default distance is set as a boolean property on all available distance
     * options in the xxx endpoint response.
     *
     * @param {Number} [value=null]
     * @returns {Boolean}
     */
    isDefaultDistance(value = null) {
        const testVal = value ? value : this.distance[0];
        return testVal === this.defaultDistanceValue;
    }

    get defaultDistanceValue() {
        const defaultDistanceItem = this.distanceOptions.find(
            x => x.default === true
        );

        return defaultDistanceItem ? defaultDistanceItem.value : -1;
    }

    set distanceOptions(options) {
        this.setStateAtAddress(options, 'distance.options');
    }

    /* *******************************************
     * Animal Type
     ********************************************/

    /**
     * Gets the currently applied animalTypes
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get animalType() {
        return this.state.animalType.value;
    }

    /*
     * Animal type
     * @property animalType
     * @param {string|Array<string>} value
     */
    set animalType(value) {
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'animalType.value');
    }

    set animalTypeOptions(options) {
        this.setStateAtAddress(options, 'animalType.options');
    }

    get animalTypeExpandedValues() {
        return this._getGenericExpandedFilterValues('animalType');
    }

    /* *******************************************
     * Breed
     ********************************************/

    /**
     * Gets the currently applied filters for breed
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get breed() {
        return this.state.breed.value;
    }

    /**
     * Gets the available breed options
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get breedOptions() {
        return this.state.breed.options;
    }

    /**
     * Gets the expanded values for applied breed filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get breedExpandedValues() {
        return this._getGenericExpandedFilterValues('breed');
    }

    get breedCounts() {
        return this.state.breed.counts;
    }

    /*
     * Sets breed filter value
     * @property breed
     * @param {string|Array<string>} value
     */
    set breed(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'breed.value');
    }

    set breedOptions(options) {
        const optionsWithCounts = this._copyCountsToOptions(
            options,
            this.breedCounts
        );
        this.setStateAtAddress(optionsWithCounts, 'breed.options');
    }

    set breedCounts(counts) {
        this.setStateAtAddress(counts, 'breed.counts');
        this.breedOptions = this.breedOptions;
    }

    get breedCounts() {
        return this.state.breed.counts;
    }

    /**
     * Removes an individual breed filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeBreedValue(value) {
        const values = this.breed;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.breed = values;
    }

    /* *******************************************
     * Age
     ********************************************/

    /**
     * Gets the currently applied filters for age
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get age() {
        return this.state.age.value;
    }

    /**
     * Gets the expanded values for applied age filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get ageExpandedValues() {
        return this._getGenericExpandedFilterValues('age');
    }

    /**
     * Sets age filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set age(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'age.value');
    }

    set ageOptions(options) {
        const optionsWithCounts = this._copyCountsToOptions(
            options,
            this.ageCounts
        );
        this.setStateAtAddress(optionsWithCounts, 'age.options');
    }

    get ageOptions() {
        return this.state.age.options;
    }

    set ageCounts(counts) {
        this.setStateAtAddress(counts, 'age.counts');
        this.ageOptions = this.ageOptions;
    }

    get ageCounts() {
        return this.state.age.counts;
    }

    /**
     * Removes an individual age filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeAgeValue(value) {
        const values = this.age;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.age = values;
    }

    /* *******************************************
     * Gender
     ********************************************/

    /**
     * Gets the currently applied filters for gender
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get gender() {
        return this.state.gender.value;
    }

    /**
     * Gets the expanded values for applied gender filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get genderExpandedValues() {
        return this._getGenericExpandedFilterValues('gender');
    }

    /**
     * Sets gender filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set gender(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'gender.value');
    }

    set genderOptions(options) {
        const optionsWithCounts = this._copyCountsToOptions(
            options,
            this.genderCounts
        );
        this.setStateAtAddress(optionsWithCounts, 'gender.options');
    }

    get genderOptions() {
        return this.state.gender.options;
    }

    set genderCounts(counts) {
        this.setStateAtAddress(counts, 'gender.counts');
        this.genderOptions = this.genderOptions;
    }

    get genderCounts() {
        return this.state.gender.counts;
    }

    /**
     * Removes an individual gender filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeGenderValue(value) {
        const values = this.gender;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.gender = values;
    }

    /* *******************************************
     * Sort by
     ********************************************/

    get sortBy() {
        return this.state.sortBy.value;
    }

    /*
     * Sort by
     * @property sortBy
     * @param {string|Array<string>} value
     */
    set sortBy(value) {
        if (value === null || (Array.isArray(value) && !value.length)) {
            value = [this.defaultSortByValue];
        }

        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }

        return this.setStateAtAddress(value, 'sortBy.value');
    }

    set sortByOptions(options) {
        this.setStateAtAddress(options, 'sortBy.options');
    }

    get defaultSortByValue() {
        const defaultSortByItem = this.sortByOptions.find(
            x => x.default === true
        );
        return defaultSortByItem ? defaultSortByItem.value : -1;
    }

    /**
     * @returns {Array<string>}
     */
    get sortByOptions() {
        return this.state.sortBy.options.slice(0);
    }

    /**
     * Results per page
     * @property resultsPerPage
     * @param {Number} value
     */
    // TODO: this could probably be removed, and users could instead use pagination.countPerPage
    set resultsPerPage(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'resultsPerPage.value');
    }

    /* *******************************************
     * Results per page
     ********************************************/

    get resultsPerPage() {
        return this.state.resultsPerPage.value;
    }

    set resultsPerPageOptions(options) {
        this.setStateAtAddress(options, 'resultsPerPage.options');
    }

    /* *******************************************
     * Attribute
     ********************************************/

    /**
     * Gets the currently applied filters for attribute
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get attribute() {
        return this.state.attribute.value;
    }

    /**
     * Gets the expanded values for applied attribute filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get attributeExpandedValues() {
        return this._getGenericExpandedFilterValues('attribute');
    }

    /**
     * Sets attribute filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set attribute(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'attribute.value');
    }

    set attributeOptions(options) {
        const optionsWithCounts = this._copyCountsToOptions(
            options,
            this.attributeCounts
        );
        this.setStateAtAddress(optionsWithCounts, 'attribute.options');
    }

    get attributeOptions() {
        return this.state.attribute.options;
    }

    set attributeCounts(counts) {
        this.setStateAtAddress(counts, 'attribute.counts');
        this.attributeOptions = this.attributeOptions;
    }

    get attributeCounts() {
        return this.state.attribute.counts;
    }

    /**
     * Removes an individual attribute filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeAttributeValue(value) {
        const values = this.attribute;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.attribute = values;
    }

    /* *******************************************
     * Coat length
     ********************************************/

    /**
     * Gets the currently applied filters for coatLength
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get coatLength() {
        return this.state.coatLength.value;
    }

    /**
     * Gets the expanded values for applied coatLength filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get coatLengthExpandedValues() {
        return this._getGenericExpandedFilterValues('coatLength');
    }

    /**
     * Sets coatLength filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set coatLength(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'coatLength.value');
    }

    set coatLengthOptions(options) {
        this.setStateAtAddress(options, 'coatLength.options');
    }

    /**
     * Removes an individual coatLength filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeCoatLengthValue(value) {
        const values = this.coatLength;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.coatLength = values;
    }

    set coatLengthOptions(options) {
        const optionsWithCounts = this._copyCountsToOptions(
            options,
            this.coatLengthCounts
        );
        this.setStateAtAddress(optionsWithCounts, 'coatLength.options');
    }

    get coatLengthOptions() {
        return this.state.coatLength.options;
    }

    set coatLengthCounts(counts) {
        this.setStateAtAddress(counts, 'coatLength.counts');
        this.coatLengthOptions = this.coatLengthOptions;
    }

    get coatLengthCounts() {
        return this.state.coatLength.counts;
    }

    /* *******************************************
     * Color
     ********************************************/

    /**
     * Gets the currently applied filters for color
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get color() {
        return this.state.color.value;
    }

    /**
     * Gets the expanded values for applied color filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get colorExpandedValues() {
        return this._getGenericExpandedFilterValues('color');
    }

    /**
     * Sets color filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set color(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'color.value');
    }

    set colorOptions(options) {
        const optionsWithCounts = this._copyCountsToOptions(
            options,
            this.colorCounts
        );
        this.setStateAtAddress(optionsWithCounts, 'color.options');
    }

    get colorOptions() {
        return this.state.color.options;
    }

    set colorCounts(counts) {
        this.setStateAtAddress(counts, 'color.counts');
        this.colorOptions = this.colorOptions;
    }

    get colorCounts() {
        return this.state.color.counts;
    }

    /**
     * Removes an individual color filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeColorValue(value) {
        const values = this.color;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.color = values;
    }

    /* *******************************************
     * Size
     ********************************************/

    /**
     * Gets the currently applied filters for size
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get size() {
        return this.state.size.value;
    }

    /**
     * Gets the expanded values for applied size filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get sizeExpandedValues() {
        return this._getGenericExpandedFilterValues('size');
    }

    /**
     * Sets size filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set size(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'size.value');
    }

    set sizeOptions(options) {
        const optionsWithCounts = this._copyCountsToOptions(
            options,
            this.sizeCounts
        );
        this.setStateAtAddress(optionsWithCounts, 'size.options');
    }

    get sizeOptions() {
        return this.state.size.options;
    }

    set sizeCounts(counts) {
        this.setStateAtAddress(counts, 'size.counts');
        this.sizeOptions = this.sizeOptions;
    }

    get sizeCounts() {
        return this.state.size.counts;
    }

    /**
     * Removes an individual size filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeSizeValue(value) {
        const values = this.size;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.size = values;
    }

    /* *******************************************
     * Species
     ********************************************/

    /**
     * Gets the currently applied filters for species
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get species() {
        return this.state.species.value;
    }

    /**
     * Gets the expanded values for applied species filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get speciesExpandedValues() {
        return this._getGenericExpandedFilterValues('species');
    }

    /**
     * Sets species filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set species(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'species.value');
    }

    get speciesOptions() {
        return this.state.species.options;
    }

    set speciesOptions(options) {
        const optionsWithCounts = this._copyCountsToOptions(
            options,
            this.speciesCounts
        );
        this.setStateAtAddress(optionsWithCounts, 'species.options');
    }

    set speciesCounts(counts) {
        this.setStateAtAddress(counts, 'species.counts');
        this.speciesOptions = this.speciesOptions;
    }

    get speciesCounts() {
        return this.state.species.counts;
    }

    /**
     * Removes an individual species filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeSpeciesValue(value) {
        const values = this.species;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.species = values;
    }

    /* *******************************************
     * Shelter
     ********************************************/

    /**
     * Gets the currently applied filters for shelter
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get shelter() {
        return this.state.shelter.value;
    }

    /**
     * Gets the expanded values for applied shelter filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get shelterExpandedValues() {
        const values = this.shelter;
        return values.map(value => {
            const optionItem = this._getShelterOptionCacheItemByValue(value);
            return {
                filterName: 'shelter',
                value,
                label: optionItem.label,
                longLabel: optionItem.label,
            };
        });
    }

    get shelterOptions() {
        return this.state.shelter.options;
    }

    /**
     * Gets the values for the shelter options cache
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get shelterOptionsCache() {
        return this.state.shelter.selectedOptionsCache;
    }

    /**
     * Sets shelter filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set shelter(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }
        return this.setStateAtAddress(value, 'shelter.value');
    }

    // NOTE: even though shelter options are provided via API, we're using this
    // for populating of shelters that are desired to be filtered via query
    // string args
    set shelterOptions(options) {
        this.setStateAtAddress(options, 'shelter.options');
    }

    set shelterOptionsCache(options) {
        this.setStateAtAddress(options, 'shelter.selectedOptionsCache');
    }

    /**
     * @param {String} value
     * @param {Object} item
     * @returns {Object}
     * @memberof AnimalSearchFiltersStateController
     */
    _getShelterOptionCacheItemByValue(value) {
        const item = this.shelterOptionsCache.find(item => {
            return item.value === value;
        });

        if (item) {
            return item;
        }
        // This was added as value above started returning as undefined when selected from the filter list.  The shelterOptionsCache has the proper values if page is loaded with values already selected as well as during the AJAX reload.
        return this.shelterOptions.find(item => {
            return item.value === value;
        });
    }

    /**
     * Removes an individual shelter filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeShelterValue(value) {
        const values = this.shelter;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.shelter = values;
    }

    /* *******************************************
     * Location
     ********************************************/

    /**
     * Gets the currently applied filters for location
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get location() {
        return this.state.location.value;
    }

    /*
     * Location
     * @property location
     * @param {string|Array<string>} value
     */
    set location(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }

        // update location cookie any time a valid location is set
        if (value.length) {
            locationCookieService.setCookie(value[0]);
        }

        return this.setStateAtAddress(value, 'location.value');
    }

    /* *******************************************
     * Household
     ********************************************/

    /**
     * Gets the currently applied filters for household
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get household() {
        return this.state.household.value;
    }

    /**
     * Gets the expanded values for applied household filters
     * @readonly
     * @returns {Array<Object>}
     * @memberof AnimalSearchFiltersStateController
     */
    get householdExpandedValues() {
        return this._getGenericExpandedFilterValues('household');
    }

    /**
     * Sets household filter value
     * @param {string|Array<string>} value
     * @returns {Array}
     */
    set household(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }

        return this.setStateAtAddress(value, 'household.value');
    }

    set householdOptions(options) {
        const optionsWithCounts = this._copyCountsToOptions(
            options,
            this.householdCounts
        );
        this.setStateAtAddress(optionsWithCounts, 'household.options');
    }

    get householdOptions() {
        return this.state.household.options;
    }

    set householdCounts(counts) {
        this.setStateAtAddress(counts, 'household.counts');
        this.householdOptions = this.householdOptions;
    }

    get householdCounts() {
        return this.state.household.counts;
    }

    /**
     * Removes an individual household filter value
     *
     * @param {String} value
     * @memberof AnimalSearchFiltersStateController
     */
    removeHouseholdValue(value) {
        const values = this.household;
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        values.splice(index, 1);
        this.household = values;
    }

    /* *******************************************
     * Animal name
     ********************************************/

    /**
     * Gets the currently applied filters for animalName
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get animalName() {
        return this.state.animalName.value;
    }

    /*
     * name
     * @property animalName
     * @param {string} value
     */
    set animalName(value) {
        if (typeof value !== 'string') {
            value = '';
        }
        return this.setStateAtAddress(value, 'animalName.value');
    }

    /**
     * Removes the animalName filter value
     *
     * @memberof AnimalSearchFiltersStateController
     */
    removeAnimalNameValue() {
        this.animalName = '';
    }

    /**
     * Gets the currently applied filters for transportable
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get transportable() {
        return this.state.transportable.value;
    }

    set transportable(value) {
        return this.setStateAtAddress(Boolean(value), 'transportable.value');
    }

    /**
     * Removes the transportable filter value
     *
     * @memberof AnimalSearchFiltersStateController
     */
    removeTransportableValue() {
        // This goes back to true when the filter is removed because of the inversion of displaying
        // the transportable property
        this.transportable = true;
    }

    /* *******************************************
     * Recently viewed pets
     ********************************************/

    /**
     * Gets recentlyViewedPets
     * @readonly
     * @returns {Array<String>}
     * @memberof AnimalSearchFiltersStateController
     */
    get recentlyViewedPets() {
        return this.state.recentlyViewedPets.value;
    }

    /*
     * recentlyViewedPets
     * @property recentlyViewedPets
     * @param {Number|Array<string>} value
     */
    set recentlyViewedPets(value) {
        // coerce into array if necessary
        if (!Array.isArray(value)) {
            value = value ? [value] : [];
        }

        return this.setStateAtAddress(value, 'recentlyViewedPets.value');
    }

    /*
     * Find label associated with value.
     * @property selectedLabelsFor
     * @param {String} filterKey
     * @returns {Array}
     */
    selectedLabelsFor(filterKey) {
        // input: array of string values
        // output: array of labels corresponding to values (as found from within options)

        const options = this.state[filterKey].options;
        const values = this.state[filterKey].value;
        const optionsByValueMap = {};
        for (let i = 0; i < options.length; i++) {
            optionsByValueMap[options[i].value] = options[i];
        }

        const retArr = [];
        for (let i = 0; i < values.length; i++) {
            const item = optionsByValueMap[values[i]];
            if (item && 'label' in item) {
                retArr.push(item.label);
            }
        }

        return retArr;
    }

    /*
     * @property selectedLabelsOrDefaultFor
     * @param {String} filterKey
     * @returns {Array}
     */
    selectedLabelsOrDefaultFor(filterKey) {
        const selected = this.selectedLabelsFor(filterKey);
        return selected.length
            ? selected
            : [this.state[filterKey].defaultLabel];
    }
}

const animalSearchFiltersStateController = new AnimalSearchFiltersStateController();
export default animalSearchFiltersStateController;
