import Mixins from '../Mixins';
import Utils from '../Utils';

export default class MixinBase {
    /**
     * Static members
     */

    static _attributeName = null;

    static get attributeName() {
        if (this._attributeName === null) {
            throw new Error('Attribute name not set (_attributeName === null)');
        }

        return this._attributeName;
    }

    static set attributeName(value) {
        this._attributeName = value;
    }

    static resolve(str, mixinEle) {
        for (let i = 0; i < this._mixinResolvers.length; i++) {
            const resolvedValue = this._mixinResolvers[i](str, mixinEle);

            if (resolvedValue !== void 0) {
                return resolvedValue;
            }
        }

        return void 0;
    }
    
    static _mixinResolvers = [
        (str, ele) => {
            const result = Utils.HTMLElementUtils.findElementContainingAddress(str, ele, true);

            if (result.element) {
                return result.value;
            }
        },
    ]

    get attributeName() {
        return Object.getPrototypeOf(this).constructor.attributeName;
    }


    /**
     * Instance members
     */

    /**
     * @type {HTMLElement}
     */
    _ele = null;

    constructor(ele, value) {
        this._ele = ele;

        // store a reference to this script on the element
        Mixins.setMixinOnElement(this, this._ele);
    }
}
