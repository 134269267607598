import MixinBase from './MixinBase';
import LogicParser from '../../../../../dotcom/scripts/lib/LogicParser';

export default class ShowMixin extends MixinBase {
    static _attributeName = 'mix-show';

    constructor(ele, value) {
        super(ele, value);

        const result = LogicParser.evaluateLogicString(value, str => MixinBase.resolve(str, ele));
        if (!result) {
            this._ele.innerHTML = '';
        }
    }
}
