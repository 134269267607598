import { html } from '../../../core/scripts/util/util';
import { helpers } from '../../../core/scripts/util/helpers';
import renderAnimalCard from './animalCard.html';

export default vm => {
    return html`
        <div class="recommendations-header u-vr5x">
            <div class="tier">
                <a href="${vm.url}" class="u-displayBlock">
                    <h2 class="txt txt_h1 m-txt_tight m-txt_colorPrimary m-txt_alignCenter">
                        $${vm.heading}
                    </h2>
                </a>
                <p class="txt m-txt_bold m-txt_colorPrimaryS1 m-txt_alignCenter u-vr4x">$${vm.subheading}</p>
            </div>
        </div>
        <div class="recommendations-body">
            <div class="recommendations-body-inner">
                <div pfdc-recommendations-results class="grid grid_gutterLg m-grid_stretch">
                    ${vm.result.animals.map(animal => html`
                        <div class="grid-col grid-col_1/4 grid-col_1/3@minMd grid-col_1/4@minLg grid-col_1/5@minXl">
                            ${renderAnimalCard(animal, vm)}
                        </div>
                    `)}
                    <div class="grid-col grid-col_1/4 grid-col_1/3@minMd grid-col_1/4@minLg grid-col_1/5@minXl">
                        <div class="actionCard m-actionCard_overflowVisible">
                            <pf-ensighten on-click="Consumer036" class="actionCard-overlay" org-id="${vm.orgDisplayId}" org-name="${vm.orgName}" component="${vm.component}">
                                <a href="$${vm.url}" class="actionCard-overlay actionCard-overlay_link" aria-label="${vm.content ? vm.content : vm.heading}: ${vm.callToAction}"></a>
                            </pf-ensighten>
                            <div class="actionCard-body actionCard-body_split">
                                <div class="actionCard-body-icon">
                                    <span class="icon icon_xxl m-icon_colorWhite">
                                        <svg role="img" focusable="false" aria-hidden="true">
                                            <use xlink:href="#icon-strokedPaw" />
                                        </svg>
                                    </span>
                                </div>
                                ${vm.content ? html`
                                    <p pfdc-recommendations-content-card-txt class="txt m-txt_colorWhite u-widthFull" aria-hidden="true">$${vm.content}</p>
                                ` : ''}
                            </div>
                            <div class="actionCard-footer">
                                <div class="actionCard-footer-cta">
                                    <span aria-hidden="true">$${vm.callToAction}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;
};
