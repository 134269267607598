export const SM_BREAKPOINT = 'sm';
export const MD_BREAKPOINT = 'md';
export const LG_BREAKPOINT = 'lg';
export const XL_BREAKPOINT = 'xl';

export const BREAKPOINT_WIDTHS = {
    SM: 360,
    MD: 668,
    LG: 1024,
    XL: 1440,
};
