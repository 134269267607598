import ActionParser from '../ActionParser';
import ObjectExtensions from '../ObjectExtensions';
import Utils from '../Utils';
import EventMixinBase from './EventMixinBase';

export default class EventActionMixinBase extends EventMixinBase {
    _actions = null;

    constructor(ele, value) {
        super(ele, value);

        if (!value) {
            throw new Error(`${this.attributeName} element found with no action provided: (${Utils.HTMLElementUtils.getElementShortHtml(ele)})`);
        }

        this._actions = ActionParser.evalActionString(value);
    }
}
