import _get from 'lodash/get';

export function pollUntilConditionMet(
    predicate,
    delay = 0,
    iterations = Infinity
) {
    return new Promise((resolve, reject) => {
        if (predicate()) {
            resolve();
        }
        let i = 1;
        const interval = setInterval(() => {
            if (i === iterations) {
                clearInterval(interval);
                reject();
            }
            if (predicate()) {
                clearInterval(interval);
                resolve();
            }
            i++;
        }, delay);
    });
}

/**
 * # JavaScript Template Literal Tags
 */

/**
 * @method htmlEscape
 * @param {String} str
 * @return {String}
 */
export function htmlEscape(str) {
    return String(str || '')
        .replace(/&/g, '&amp;') // First!
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/`/g, '&#96;');
}

/**
 * @method html
 * @param {Array.<String>} literalSections
 * @return {String}
 */
export function html(literalSections, ...values) {
    const raw = literalSections.raw;
    let result = '';

    values.forEach((value, i) => {
        let localValue = value;
        let lit = raw[i];

        if (Array.isArray(localValue)) {
            localValue = localValue.join('');
        }

        if (localValue == null || typeof localValue === 'boolean') {
            localValue = '';
        }

        if (lit.endsWith('$')) {
            localValue = htmlEscape(localValue);
            lit = lit.slice(0, -1);
        }

        result += lit;
        result += localValue;
    });

    result += raw[raw.length - 1];

    return result;
}

/**
 * Converts string to html.
 * Useful in tandem with our parser: toHtml(html`...`)
 * @method toHtml
 * @param {String} htmlString
 * @return {HTMLElement}
 */
export function toHtml(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    const html = div.firstElementChild;
    return html;
}

/**
 * @method sortDesc
 * @param {String} a
 * @param {String} b
 * @return {Number}
 */
export function sortAsc(a, b) {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }

    return 0;
}

/**
 * @method sortDesc
 * @param {String} a
 * @param {String} b
 * @return {Number}
 */
export function sortDesc(a, b) {
    if (a < b) {
        return 1;
    }
    if (a > b) {
        return -1;
    }

    return 0;
}

/**
 * @method getEarliestDateFromArray
 * @param {Array} dates
 * @return {String}
 */
export function getEarliestDateFromArray(dates) {
    return dates.sort(sortAsc)[0];
}

/**
 * @method getLatestDateFromArray
 * @param {Array} dates
 * @return {String}
 */
export function getLatestDateFromArray(dates) {
    return dates.sort(sortDesc)[0];
}

/**
 * @method areObjectsEqual
 * @param {Object} a
 * @param {Object} b
 * @return {Bool}
 */
export function areObjectsEqual(a, b) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];

        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
}

/**
 * If key has value, returns new singleton object of property.
 * Otherwise returns empty object.
 *
 * @func maybeProp
 * @param {Object} - object
 * @param {String} - key
 * @returns {Object}
 */
export function maybeProp(obj, key) {
    const newObj = {};
    if (obj[key]) newObj[key] = obj[key];
    return newObj;
}

/**
 * Generate a random string.
 * @func uid
 * @return {String}
 */
export function uid() {
    return Math.random()
        .toString(36)
        .substr(2, 36);
}

/**
 * Throws a function at the end of the current tick in the event loop
 * @func scheduleMicrotask
 * @param {Function}
 */
export function scheduleMicrotask(fn) {
    return setTimeout(fn, 0);
}

/**
 * Use with await
 * @func wait
 * @param {Number} ms number of milliseconds
 */
export function wait(ms) {
    return new Promise(r => setTimeout(r, ms));
}

/**
 * True if user is on a mobile device. IEMobile not included.
 * @func isMobileUser
 * @return {Boolean}
 */
export function isMobileUser() {
    return Boolean(navigator.userAgent.match(/iPad|iPhone|Android/i));
}

/**
 * Capitalize the first letter of a string
 * @param {string} string
 */
export function titleCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Returns an array of random items from another array
 * @function getRandomItemsFromArray
 * @param  {array} array to choose random items from
 * @param  {Number} numItems number of random items to get
 * @return {array}
 */
export function getRandomItemsFromArray(array, numItems) {
    const temp = [];
    let i;
    for (i = 0; i < numItems; i++) {
        const randIndex = Math.floor(Math.random() * array.length);
        const randomItem = array[randIndex];
        temp.unshift(randomItem);
        array.splice(randIndex, 1);
    }
    return temp;
}

/**
 * Waits for a period of time.  Returns a promise.  Useful in conjunction with await in order
 * to pause execution for a period of time.
 *
 * @function sleep
 * @param  {Number} ms to wait
 * @return {Promise} resolved Promise
 */
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Waits a tick to the end of the event loop.  Good in conjunction with await
 * for ensuring processes in the current tick to complete before executing something.
 * @function endOfEventLoop
 * @return {Promise} resolved Promise
 */
export function endOfEventLoop() {
    return sleep(0);
}

export const ifVal = (val, propStr) => {
    return val != null ? val : '';
};

/**
 * Value or empty string.
 * @function ifVal
 * @param {} Value
 * @param {Value} Value
 * @return {Value|String}
 */
export const ifTruthy = (condition, ifTruthy = '', ifFalsy = '') => {
    return condition ? ifTruthy : ifFalsy;
};

/**
 * A function that does nothing.
 * @function noop
 */
export const noop = function noop() {};

/**
 * Goes thru an array of replacement rules to replace things in translated strings
 * @param {string} str
 * @returns {string}
 */
export function processTranslationString(str) {
    let newStr = str;
    const rules = [
        {
            replace: '&#039;',
            with: "'", // eslint-disable-line
        },
    ];
    rules.forEach(rule => {
        newStr = newStr.replace(rule.replace, rule.with);
    });
    return newStr;
}

/**
 * Helper to compare a value in two objects by key
 * @param {string} key for the value
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {boolean}
 */
export function isValueForKeyDifferentBetween(key, obj1, obj2) {
    const a = _get(obj1, `${key}`, null);
    const b = _get(obj2, `${key}`, null);
    return a !== b;
}

/**
 * Takes a string, tries to match if it already has https:// or http://
 * if it does not it prepends the given protocol https:// by default
 * @param {string} str
 * @param {string} protocol
 * @returns {string}
 */
export function prependProtocolToString(str, protocol = 'https://') {
    const pattern = /^(h|ht|htt|http|https|https:|https:\/|https:\/\/|http:|http:\/|http:\/\/)$/gm;

    if (
        str &&
        !str.match(pattern) &&
        (!str.startsWith('https://') && !str.startsWith('http://'))
    ) {
        return `${protocol}${str}`;
    }

    return str;
}
