import BaseModel from './BaseModel';

export default class BaseCollection {
    _items = null;

    constructor(items) {
        this._items = items
            // shallow clone of items, for a bit of extra safety; careful when making collections of objects
            ? items.slice(0)
            : [];
    }

    get collection() {
        return this._items;
    }

    get raw() {
        return this._items.map(item => {
            return (item instanceof BaseModel)
                ? item.raw
                : item;
        });
    }
}
