import t from 'tcomb';
import { Country } from './Country';
import { PostalCode } from './PostalCode';
import { State } from './State';

export const UserLocation = t.struct({
    administrative_area: t.maybe(t.String),
    city: t.maybe(t.String),
    country: Country,
    display_name: t.maybe(t.String),
    lat: t.maybe(t.Number),
    lng: t.maybe(t.Number),
    postal_code: t.maybe(PostalCode),
    slug: t.String,
    state: State,
}, 'Location');
