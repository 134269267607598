import Repository from '../repository/repository';
import { ERROR_API } from '../../constants/errors';
import axios from 'axios';

/**
 * Geography Repository
 * @extends Repository
 */
class GeographyRepository extends Repository {
    async searchForLocations(q, lat, lng) {
        const baseUrl = '/v2/geography/search/';

        let params = {
            q,
        };

        if (lat && lng) {
            params = Object.assign({}, params, {
                lat,
                lng,
            });
        }

        const url = this.buildUrlWithQuery(baseUrl, params);
        const response = await axios(url, {
            method: 'GET',
            headers: {
                // Need this content-type here
                'X-Requested-With': 'XMLHttpRequest',
            },
            credentials: 'same-origin',
        });

        if (response.status === 400) {
            // For parsing error response and getting redirect
            return Promise.reject(response.data);
        }

        if (this.wasRequestSuccessful(response)) {
            return Promise.resolve(response.data);
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }

    async findAPlace(lat, lng) {
        const baseUrl = '/v2/geography/find/';

        const params = {
            lat,
            lng,
        };

        const url = this.buildUrlWithQuery(baseUrl, params);
        const response = await axios(url, {
            method: 'GET',
            headers: {
                // Need this content-type here
                'X-Requested-With': 'XMLHttpRequest',
            },
            credentials: 'same-origin',
        });

        if (response.status === 400) {
            // For parsing error response and getting redirect
            return Promise.reject(response.data);
        }

        if (this.wasRequestSuccessful(response)) {
            return Promise.resolve(response.data);
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }
}

const geographyRepository = new GeographyRepository();
export default geographyRepository;
