import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {Object} props
 * @returns {string}
 */
function Svg(props) {
    const svgProps = Object.assign({}, props);
    const xlinkHref = svgProps.xlinkHref;
    delete svgProps.xlinkHref;

    return (
        <svg role="presentation" focusable="false" {...svgProps}>
            <use xlinkHref={`#${xlinkHref}`} />
        </svg>
    );
}

Svg.propTypes = {
    xlinkHref: PropTypes.string.isRequired,
};

export default Svg;
