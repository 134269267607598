/**
 * # FormParser
 */
export class FormParser {
    constructor() {
        /**
         * Data prototype containing the animal id input field
         *
         * @default null
         * @property prototype
         * @type {Object}
         */
        this.prototype = null;

        /**
         * Items to use in creating and populating form
         *
         * @default []
         * @property items
         * @type {Array}
         */
        this.items = [];

        /**
         * Parent html container to which form fields can be appended
         *
         * @default document.createElement('div')
         * @property fieldHtml
         * @type {Object}
         */
        this.fieldHtml = document.createElement('div');
    }

    /**
     * ## Methods
     */
    createForm(options) {
        this.prototypeContainer = document.querySelector(options.prototypeSelector);
        this.items = options.items;
        this.prototype = this.prototypeContainer.dataset.prototype;
        this.fieldHtml.innerHTML = '';

        this.items.forEach((item, index) => {
            this.setupFields(item.fields, index);
        });

        return this.fieldHtml;
    }


    // Parse and populate html form inputs
    setupFields(fields, index) {
        const parser = new DOMParser();
        const newPrototype = this.prototype.replace(/__name__/g, index);
        const parsedPrototype = parser.parseFromString(newPrototype, 'text/html');

        fields.map((field) => {
            const fieldSelector = field.fieldSelector.replace(/__name__/g, index);
            const element = parsedPrototype.querySelector(fieldSelector);

            element.setAttribute('value', field.valueToSet);
            this.appendInputs(element);

            return field;
        });
    }

    appendInputs(element) {
        this.fieldHtml.appendChild(element);
    }

}
