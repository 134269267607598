import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { analyticsConsumer002 } from '../../../../analytics/dotcom';

class ReturningLayout extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        onLoginClick: PropTypes.func.isRequired,
        onSignOut: PropTypes.func,
        returningUserData: PropTypes.object,
        returningUsers: PropTypes.object,
        renderFacebookButton: PropTypes.func,
        renderGoogleButton: PropTypes.func,
    };

    get userName() {
        if (this.props.returningUserData.firstName !== '') {
            return `, ${this.props.returningUserData.firstName}`;
        } else {
            return '';
        }
    }

    get notYouText() {
        // Try to use first name, if unavailable, try falling back on full name
        const name =
            this.props.returningUserData.firstName !== ''
                ? this.props.returningUserData.firstName
                : this.props.returningUserData.name;

        return `Not ${name !== '' ? name : 'you'}?`;
    }

    get profilePicture() {
        return this.props.returningUserData.image;
    }

    render() {
        return (
            <div className="loginModal">
                <div className="loginModal-section u-textCenter">
                    <h2
                        className="loginModal-section-hdg u-vr10x"
                        tabIndex="-1"
                    >
                        {`Welcome back${this.userName}`}
                    </h2>
                    {this.profilePicture ? (
                        <div className="imgMask imgMask_socialProfile m-imgMask_center u-vr6x">
                            <img
                                src={this.profilePicture}
                                alt="Your profile picture"
                            />
                        </div>
                    ) : (
                        <div className="imgMask imgMask_socialProfile m-imgMask_placeholder m-imgMask_center u-vr6x" />
                    )}
                    <div>
                        {this.props.returningUsers.facebook && (
                            <div className="u-vr3x">
                                {this.props.renderFacebookButton({
                                    onSocialClick: params => {
                                        analyticsConsumer002(params);
                                    },
                                })}
                            </div>
                        )}
                        {this.props.returningUsers.google &&
                            this.props.renderGoogleButton({
                                onSocialClick: params => {
                                    analyticsConsumer002(params);
                                },
                            })}
                    </div>
                </div>
                <div className="loginModal-section loginModal-section_footer">
                    <div>
                        <p className="txt m-txt_lg m-txt_bold u-vr1x">
                            {this.notYouText}
                        </p>
                        <button
                            type="button"
                            onClick={this.props.onSignOut}
                            className="txt txt_link m-txt_bold m-txt_xl"
                        >
                            {'Log into a different account'}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReturningLayout;
