const NO_VALUE_SET = '___NO_VALUE_SET___';

export default class CacheService {
    _map = new Map();

    _generateKey(key, subKey = null) {
        let fullKey = key;
        if (subKey) {
            fullKey += `+${subKey}`;
        }

        return fullKey;
    }

    has(key, subKey) {
        const fullKey = this._generateKey(key, subKey);

        return this._map.has(fullKey);
    }

    add(key, subKeyOrValue, value = NO_VALUE_SET) {
        if (value === NO_VALUE_SET) {
            this._map.set(this._generateKey(key), subKeyOrValue);
        } else {
            this._map.set(this._generateKey(key, subKeyOrValue), value);
        }
    }

    remove(key, subKey) {
        const fullKey = this._generateKey(key, subKey);

        this._map.delete(fullKey);
    }

    get(key, subKey) {
        const fullKey = this._generateKey(key, subKey);

        if (!this._map.has(key, subKey)) {
            throw new Error(`CacheService.get(): no entry found with key: "${fullKey}"`);
        }

        return this.getSafe(key, subKey);
    }

    getSafe(key, subKey) {
        const fullKey = this._generateKey(key, subKey);

        return this._map.get(fullKey);
    }
}
