/**
 * Create temporary DOM element to determine width of scrollbar
 * and set width value as native CSS var on <html>
 * @method setScrollbarWidth
 */
function setScrollbarWidth() {
    const scrollDiv = document.createElement('div');
    document.body.appendChild(scrollDiv);
    scrollDiv.style.width = '100px';
    scrollDiv.style.height = '100px';
    scrollDiv.style.overflow = 'scroll';
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-100px';

    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);

    document.documentElement.style.setProperty(
        '--scrollbarWidth',
        `${scrollbarWidth}px`
    );
}

export default function onLoadCssHelpers() {
    setScrollbarWidth();
}
