import React from 'react';

import { COUNTRY_CHOOSE_LABEL, COUNTRY_NOT_LISTED_VALUE } from '../../../../../core/scripts/constants/geography';

import buildTcombPasswordValidationField from '../../tcomb/passwordValidationField';

import AlertBoxCountryNotListed from '../AlertBoxCountryNotListed';

import { FIELDS } from './formType';

/**
 * @type {string}
 */
export const ZIP_CODE = 'ZIP code';

/**
 * @type {Object}
 */
export default {
    fields: {
        [FIELDS.FIRST_NAME]: {
            attrs: {
                dataTestIds: {
                    field: 'User_Registration_Modal_First_Name_Input',
                    error: 'User_Registration_Modal_First_Name_Error_Message',
                },
            },
            label: 'First name',
        },
        [FIELDS.LAST_NAME]: {
            attrs: {
                dataTestIds: {
                    field: 'User_Registration_Modal_Last_Name_Input',
                    error: 'User_Registration_Modal_Last_Name_Error_Message',
                },
            },
            label: 'Last name',
        },
        [FIELDS.COUNTRY]: {
            attrs: {
                dataTestIds: {
                    field: 'User_Registration_Modal_Country_Dropdown',
                    error: 'User_Registration_Modal_Country_Error_Message',
                },
            },
            label: 'Country of Residence',
            nullOption: { value: '', text: COUNTRY_CHOOSE_LABEL },
        },
        [FIELDS.POSTAL_CODE]: {
            attrs: {
                dataTestIds: {
                    field: 'User_Registration_Modal_Postal_Code_Input',
                    error: 'User_Registration_Modal_Postal_Code_Error_Message',
                },
            },
            label: 'Postal code',
        },
        [FIELDS.EMAIL]: {
            attrs: {
                ['aria-describedby']: 'registration-email-description',
                dataTestIds: {
                    field: 'User_Registration_Modal_Email_Input',
                    error: 'User_Registration_Modal_Email_Error_Message',
                },
            },
            label: 'Email',
            type: 'email',
        },
        [FIELDS.PASSWORD_FIRST]: {
            attrs: {
                dataTestIds: {
                    field: 'User_Registration_Modal_Password_Input',
                    error: 'User_Registration_Modal_Password_Error_Message',
                },
            },
            label: 'Password',
            type: 'password',
            template: buildTcombPasswordValidationField,
        },
        [FIELDS.PASSWORD_SECOND]: {
            attrs: {
                dataTestIds: {
                    field: 'User_Registration_Modal_Confirm_Password_Input',
                    error: 'User_Registration_Modal_Confirm_Password_Error_Message',
                },
            },
            label: 'Confirm password',
            type: 'password',
        },
        [FIELDS.NEXT]: {
            type: 'hidden',
        },
        [FIELDS.NEXT_PARAMS]: {
            type: 'hidden',
        },
        [FIELDS.SOURCE]: {
            type: 'hidden',
        },
    },
    template: locals => {
        const countryNotListedSelected = locals.value[FIELDS.COUNTRY] === COUNTRY_NOT_LISTED_VALUE;

        return ((
            <div className="grid grid_gutterLg u-vr6x">
                {locals.hasError && <div className="grid-col grid-col_1/1">{locals.error}</div>}
                <div className="grid-col grid-col_1/2@minLg">{locals.inputs[FIELDS.FIRST_NAME]}</div>
                <div className="grid-col grid-col_1/2@minLg">{locals.inputs[FIELDS.LAST_NAME]}</div>
                <div className="grid-col grid-col_1/2@minLg">
                    {locals.inputs[FIELDS.COUNTRY]}
                    {countryNotListedSelected && (
                        <div className="u-vrTop2x">
                            <AlertBoxCountryNotListed />
                        </div>
                    )}
                </div>
                <div className="grid-col grid-col_1/2@minLg">{locals.inputs[FIELDS.POSTAL_CODE]}</div>
                <div className="grid-col grid-col_1/2@minLg">
                    <div className="txt m-txt_italic m-txt_alignRight u-vr2x u-isHidden@minLg">
                        You will use your email address to log in
                    </div>
                    {locals.inputs[FIELDS.EMAIL]}
                </div>
                <div id="registration-email-description" className="grid-col grid-col_1/2@minLg u-isHidden@maxLg">
                    <div className="txt m-txt_italic u-vr2x">
                        You will use your email
                        <br /> address to log in
                    </div>
                </div>
                <div className="grid-col grid-col_1/2@minLg">{locals.inputs[FIELDS.PASSWORD_FIRST]}</div>
                <div className="grid-col grid-col_1/2@minLg">{locals.inputs[FIELDS.PASSWORD_SECOND]}</div>
                {locals.inputs[FIELDS.NEXT]}
                {locals.inputs[FIELDS.NEXT_PARAMS]}
                {locals.inputs[FIELDS.SOURCE]}
            </div>
        ));
    },
};
