import t from 'tcomb-form';

import { defaultValidation } from '../react-components/tcomb/errors';

import {
    COUNTRY_NOT_LISTED,
    COUNTRY_NOT_LISTED_VALUE,
} from '../constants/geography';

/**
 * @type {string}
 */
const ERROR_MSG_GENERIC = 'Password does not meet minimum requirements.';

/**
 * @type {string}
 */
const ERROR_MSG_SHORT = 'Password must have 8 characters or more.';

/**
 * @type {number}
 */
const REQUIRED_PASSWORD_LENGTH = 8;

/**
 * @type {number}
 */
const NUM_OF_VALIDATION_RULES_TO_PASS = 3;

/**
 * @type {Object}
 */
const passwordValidationRules = {
    digit: {
        regex: /.*\d/,
    },
    lowercase: {
        regex: /.*[a-z]/,
    },
    uppercase: {
        regex: /.*[A-Z]/,
    },
    specialChar: {
        regex: /.*[-`~!@#$%^&*()_=+{}\[\]|\;:'\",.<>\\/?]/,
    },
};

/**
 * Helper for extending an existing tcomb struct with a country enum. This will
 * take the country array from our pageConfig and help extend it onto the passed struct.
 * @param {Struct} Struct a tcomb struct to extend
 * @param {Array} countries an array of country objects
 * @param {string} key the key for countries the extended struct
 * @param {func} getValidationErrorMessage a tcomb form validation error message function
 * @returns {Struct}
 */
export function extendTcombStructWithCountriesEnum(
    Struct,
    countries,
    key = 'country',
    getValidationErrorMessage = defaultValidation
) {
    if (!Array.isArray(countries)) {
        throw new Error(
            'extendTcombStructWithCountriesEnum: countries is not an array'
        );
    }

    const obj = {};

    countries.forEach(country => {
        obj[country.code] = country.name;
    });
    obj[COUNTRY_NOT_LISTED_VALUE] = COUNTRY_NOT_LISTED;

    const Countries = t.enums(obj);

    if (typeof getValidationErrorMessage === 'function') {
        Countries.getValidationErrorMessage = getValidationErrorMessage;
    }

    return Struct.extend({
        [key]: Countries,
    });
}

/**
 * Takes password string value and creates an object with password validation
 * rule keys, the values are whether the regex test passed for each
 * @param {string} value
 * @returns {Object}
 */
export function buildPasswordValidationRulesObject(value) {
    const validationObject = {};
    validationObject.length = value.length >= REQUIRED_PASSWORD_LENGTH;
    Object.keys(passwordValidationRules).forEach(key => {
        validationObject[key] = passwordValidationRules[key].regex.test(value);
    });
    return validationObject;
}

/**
 * Checks password string value agains passwordValidationRules
 * @param {string} value
 * @returns {boolean}
 */
export function isPasswordValid(value) {
    const numValidationRulesPassed = Object.keys(passwordValidationRules)
        .map(key => passwordValidationRules[key].regex.test(value))
        .filter(bool => bool).length;

    return (
        value.length >= REQUIRED_PASSWORD_LENGTH &&
        numValidationRulesPassed >= NUM_OF_VALIDATION_RULES_TO_PASS
    );
}

/**
 * @type {Object}
 */
export const Password = t.refinement(t.String, isPasswordValid);

Password.getValidationErrorMessage = value => {
    let errorMessage = ERROR_MSG_GENERIC;

    if (value.length < REQUIRED_PASSWORD_LENGTH) {
        errorMessage = ERROR_MSG_SHORT;
    }

    return errorMessage;
};
