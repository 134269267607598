import BaseModel from './BaseModel';
import _get from 'lodash/get';

export default class CountryModel extends BaseModel {
    get defaultData() {
        return {
            code: '',
            name: '',
        };
    }

    /**
     * @static
     * @param {object} data json from server
     * @return {CountryModel}
     */
    static fromServer(data) {
        if (!data) {
            return null;
        }

        return new CountryModel(this._mapServerDataToClientside(data));
    }

    static _mapServerDataToClientside(data) {
        return {
            code: _get(data, 'code', ''),
            name: _get(data, 'name', ''),
        };
    }

    get code() {
        return this._data.code;
    }

    get name() {
        return this._data.name;
    }
}
