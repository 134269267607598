import React from 'react';
import ReactDOM from 'react-dom';

import FindAPetMenu from './react-components/specifics/FindAPetMenu';
import SavedSearchAnimalSearch from './react-components/saved-search/SavedSearchAnimalSearch';
import SavedSearchBreedsDetailButton from './react-components/saved-search/SavedSearchBreedsDetailButton';
import SavedSearchBreedsCollectionButton from './react-components/saved-search/SavedSearchBreedsCollectionButton';
import SavedSearchModal from './react-components/saved-search/SavedSearchModal';
import SavedSearchMobileStickyButton from './react-components/saved-search/SavedSearchMobileStickyButton';
import PetSearchFilterList from './react-components/specifics/PetSearchFilterList';

/**
 * Add your React componets to this object and they'll get\
 * rendered into their target container.
 *
 * @type {Object}
 */
const REACT_COMPONENT_BOOTSTRAP = [
    // find a pet menu
    {
        component: <FindAPetMenu />,
        container: Array.from(document.querySelectorAll('.findAPetMenu')),
    },
    {
        component: <SavedSearchAnimalSearch />,
        container: document.getElementById('Saved_Search_Animal_Search'),
    },
    {
        component: <SavedSearchBreedsDetailButton />,
        container: document.getElementById('Saved_Search_Breeds_Detail_Button'),
    },
    {
        component: <SavedSearchBreedsCollectionButton />,
        container: document.getElementById('Saved_Search_Breeds_Collection_Button'),
    },
    {
        component: <SavedSearchMobileStickyButton />,
        container: document.getElementById('Mobile_Save_Search_Button'),
    },
    // saved search confirmation modal
    {
        component: <SavedSearchModal />,
        container: document.getElementById('Saved_Search_Confirmation_Modal'),
    },
    {
        component: <PetSearchFilterList />,
        container: document.getElementById('Pet_Search_Applied_Filters'),
    },
];

export default class ReactApp {
    static init() {
        try {
            REACT_COMPONENT_BOOTSTRAP.forEach(item => {
                const { component, container } = item;

                if (!container) {
                    return;
                }

                if (!Array.isArray(container)) {
                    ReactDOM.render(component, container);
                } else {
                    container.forEach(container => {
                        ReactDOM.render(component, container);
                    });
                }
            });
        } catch (err) {
            console.warn(err);
        }
    }
}
