import React, { Component, Fragment } from 'react';
import buildClassNames from 'classnames';
import userMeStateController from '../../../state-controllers/userMeStateController';
import SvgIcon from '../../breeds/specifics/SvgIcon';
import savedSearchService from '../../../../../core/scripts/services/savedSearchService';
import siteStateController from '../../../state-controllers/siteStateController';
import SavedSearchAnimalSearchButton from '../SavedSearchAnimalSearchButton';
import StateController from '../../generics/StateController';
import userMeAPIService from '../../../../../core/scripts/services/userMeAPIService';
import { isFocusWithinContainer } from '../../../../../core/scripts/util/react';
import { analyticsConsumer341_342, analyticsConsumer343, analyticsConsumer344 } from '../../../analytics/dotcom';

class SavedSearchAnimalSearch extends Component {
    /* *******************************************
     * Class Properties
     ********************************************/
    state = {
        dropdownIsOpen: false,
        savedSearches: [],
        isLoggedIn: false,
        isSaving: siteStateController.ui.savedSearch.isSaving,
    };

    _wrapperRef = React.createRef();

    /* *******************************************
     * Lifecycle
     ********************************************/
    async componentDidMount() {
        this._initSavedSearchSubscription();
        document.addEventListener('mousedown', this._handleClickOutsideDropdown);
        this.setState({ isLoggedIn: await userMeAPIService.isLoggedIn });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._handleClickOutsideDropdown);
        this._unsubscribeFunction();
    }

    /* *******************************************
     * Methods
     ********************************************/
    /**
     * Gets any class names to be applied to the component instance.
     *
     * @readonly
     */
    get componentClassNames() {
        return buildClassNames({
            ['savedSearchAnimalSearch-wrap']: true,
            ['u-isHidden']: !this.state.dropdownIsOpen,
            ['u-isOpen']: this.state.dropdownIsOpen,
        });
    }

    /**
     * Initalize unsubscribe method
     *
     * @private
     * @memberof PetSearchFilterList
     */
    _unsubscribeFunction = null;

    /**
     * Watches and receives updates when search filters change.
     *
     * @private
     */
    _initSavedSearchSubscription() {
        this._unsubscribeFunction = userMeStateController.subscribe(payload => {
            this._getSavedSearches();
        });
    }

    /**
     * Gets all previously saved searches a user has saved.
     *
     * @private
     * @memberof PetSearchFilterList
     */
    async _getSavedSearches() {
        this.setState({
            savedSearches: userMeStateController.state.savedSearches,
        });
    }

    /**
     * Method that fires upon user clicking an already saved search item in the dropdown.
     *
     * @param {String} searchName
     * @returns {function}
     */
    _onLoadSavedSearchClick(searchName) {
        return e => {
            analyticsConsumer343(searchName);
        };
    }

    /**
     * Method that fires upon user clicking the 'Edit Saved Searches' button in the dropdown.
     * @param {MouseEvent} e
     */
    _onEditSavedSearchesBtnClick = e => {
        analyticsConsumer344();
    };

    /* *******************************************
     * Events
     ********************************************/
    /**
     * Handles a click on the saved searches button
     *
     * @param {MouseEvent} e
     * @private
     */

    _handleSavedSearchDropdownClick = e => {
        const nextVal = !this.state.dropdownIsOpen;

        this.setState({ dropdownIsOpen: nextVal });
        analyticsConsumer341_342(nextVal);
    };

    /**
     * Handles a click outside of the saved search tools
     *
     * @param {MouseEvent} e
     */
    _handleClickOutsideDropdown = e => {
        if (!this._wrapperRef.current.contains(e.target) && this.state.dropdownIsOpen) {
            this.setState({ dropdownIsOpen: false });
            analyticsConsumer341_342(this.state.dropdownIsOpen);
        }
    };

    /**
     * @param {Event} ev
     * @private
     */
    _handleBlur = ev => {
        isFocusWithinContainer(this._wrapperRef.current, ev, () => {
            this.setState({ dropdownIsOpen: false });
        });
    };

    /* *******************************************
     * Render
     ********************************************/
    /**
     * Renders the contents of the saved search drop down list
     *
     * @returns {ReactElement} markup
     * @private
     */
    _renderSavedSearchList() {
        return (
            <ul className="savedSearchAnimalSearch-list" aria-expanded={this.state.dropdownIsOpen}>
                {this.state.savedSearches.map(savedSearch => (
                    <li key={savedSearch.id}>
                        <a
                            className="u-displayBlock"
                            href={savedSearch.url}
                            onClick={this._onLoadSavedSearchClick(savedSearch.name)}
                        >
                            <div className="txt m-txt_xl m-txt_ellipsisOverflow">{savedSearch.name}</div>
                            <div className="txt txt_md m-txt_ellipsisOverflow">{savedSearch.filterString}</div>
                        </a>
                    </li>
                ))}
            </ul>
        );
    }

    /**
     * Renders the edit saved searches button
     *
     * @returns {ReactElement} markup
     * @private
     */
    _renderSavedSearchEditBtn() {
        return (
            <a
                href="/user/search-alerts"
                onClick={this._onEditSavedSearchesBtnClick}
                className="savedSearchAnimalSearch-editBtn"
            >
                <div className="txt m-txt_lg">Edit saved searches</div>
                <SvgIcon size="sm" svgId="chevronRight" />
            </a>
        );
    }

    /**
     * Renders the desktop saved search tools
     * Note that that the 'Save Search' button should always be visible on desktop
     *
     * @returns {ReactElement} markup
     * @private
     */
    _renderDesktopTools() {
        if (this.state.isLoggedIn) {
            return (
                <div className="u-isHidden@maxLg">
                    <div className="hrArray m-hrArray_6x">
                        <SavedSearchAnimalSearchButton isLoggedIn={true} />
                        {this.state.savedSearches.length > 0 && (
                            <div>
                                <button
                                    onClick={this._handleSavedSearchDropdownClick}
                                    className={`savedSearchAnimalSearch-dropdownBtn ${
                                        this.state.dropdownIsOpen ? 'u-isOpen' : ''
                                    }`}
                                >
                                    <span className="txt m-txt_colorWhite">Saved Searches</span>
                                </button>
                                <div className={this.componentClassNames}>
                                    {this._renderSavedSearchList()}
                                    {this._renderSavedSearchEditBtn()}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="u-isHidden@maxLg">
                    <SavedSearchAnimalSearchButton isLoggedIn={false} />
                </div>
            );
        }
    }

    /**
     * Renders the mobile version of the list of saved searches.
     * Note: the div.savedSearchAnimalSearch-wrap needs to be before
     * button.savedSearch-btn in the source for css to rotate the icon
     *
     * @returns {ReactElement} markup
     */
    _renderMobileTools() {
        // Only render mobile saved search list if the user is logged in AND there are existing saved searches
        if (this.state.savedSearches.length > 0 && this.state.isLoggedIn) {
            return (
                <div className="u-isHidden@minLg">
                    <button
                        onClick={this._handleSavedSearchDropdownClick}
                        className="savedSearchAnimalSearch-dropdownBtn"
                    >
                        <span className="txt m-txt_colorWhite">Saved Searches</span>
                    </button>
                    <div className={this.componentClassNames}>
                        {this._renderSavedSearchList()}
                        {this._renderSavedSearchEditBtn()}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div ref={this._wrapperRef} onBlur={this._handleBlur}>
                {this._renderDesktopTools()}
                {this._renderMobileTools()}
            </div>
        );
    }
}

export default SavedSearchAnimalSearch;
