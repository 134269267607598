import { trackEvent } from '../../../core/scripts/analytics';
import UserInfoEventModel from '../../../core/scripts/analytics/models/user-info/UserInfoEventModel';
import userMeStateController from '../../scripts/state-controllers/userMeStateController';
import _get from 'lodash/get';

const HAPPY_TAILS_CATEGORY = 'happy tails';

/**
 * Petfinder Consumer 392
 * Page: Happy Tails landing/entry page
 * Action: User clicks, 'Get Started' from entry page of Happy Tails experience
 *
 * @public
 * @event PetfinderConsumer392
 */
export function analyticsHappyTails392() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'get started',
        label: 'initiate',
        event: 'Petfinder Consumer 392 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 393
 * Page: Social login page: User signs up to complete their "Happy Tails' story
 * Action: User clicks "Sign up with Facebook, Google or Email" on Create your Happy tail page
 *
 * No event for Petfinder Consumer 394 - should fire SocialLogin002
 */

/**
 * Petfinder Consumer 394
 * Page: Social login page: User logs in to complete their "Happy Tails' story
 * Action: User clicks "Log in" on Create your Happy Tail page
 *
 * No event for Petfinder Consumer 394 - should fire SocialLogin006
 */

/**
 * Petfinder Consumer 395
 * Page: N/A
 * Action N/A
 *
 * No event for Petfinder Consumer 395
 */

/**
 * Petfinder Consumer 396
 * Page: Social Login page:  User clicks the 'Cancel' button
 * Action: User clicks 'Cancel' on Create your Happy Tail page
 *
 * @public
 * @event PetfinderConsumer396
 */
export function analyticsHappyTails396() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'cancel',
        label: 'sign up or log in',
        event: 'Petfinder Consumer 396 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 397
 * Page: Happy Tails: Tell Us about your pet
 * Action: User clicks 'Cancel' on Tell us about your Pet
 *
 * @public
 * @event PetfinderConsumer397
 */
export function analyticsHappyTails397() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'cancel',
        label: 'pet information',
        event: 'Petfinder Consumer 397 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 398
 * Page: Happy Tails: Tell Us about your pet
 * Action: Fire upon successful submission of form (after clicking 'next')
 *
 * @public
 * @event PetfinderConsumer398
 * @param {string} petName
 */
export function analyticsHappyTails398(petName) {
    const analytics = UserInfoEventModel.prepareEvent({
        petName,
    });

    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: 'pet name',
        event: 'Petfinder Consumer 398 - Happy Tails',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 399
 * Page: Happy Tails: Tell Us about your pet
 * Action: Fire upon successful submission of form (after clicking 'next') - Tell us About Your Pet page
 *
 * @public
 * @event PetfinderConsumer399
 * @param {string} petType
 */
export function analyticsHappyTails399(petType) {
    const analytics = UserInfoEventModel.prepareEvent({
        petType,
    });

    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: 'type of pet',
        event: 'Petfinder Consumer 399 - Happy Tails',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 400
 * Page: Happy Tails: Tell Us about your pet
 * Action: Fire upon successful submission of form (after clicking 'next') - Tell us About Your Pet page
 *
 * @public
 * @event PetfinderConsumer400
 * @param {string} adoptionDate
 */
export function analyticsHappyTails400(adoptionDate) {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: `date adopted: ${adoptionDate}`,
        event: 'Petfinder Consumer 400 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 401
 * Page: Happy Tails: Tell Us about your pet
 * Action: Fire upon successful submission of form (after clicking 'next') - Tell us About Your Pet page
 *
 * @public
 * @event PetfinderConsumer401
 * @param {string} petBreed
 */
export function analyticsHappyTails401(petBreed) {
    const analytics = UserInfoEventModel.prepareEvent({
        petBreed,
    });
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: 'primary breed',
        event: 'Petfinder Consumer 401 - Happy Tails',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 402
 * Page: Happy Tails: Tell Us about your pet
 * Action: Fire upon successful submission of form (after clicking 'next') - Tell us About Your Pet page
 *
 * @public
 * @event PetfinderConsumer402
 * @param {string} petSecondaryBreed
 */
export function analyticsHappyTails402(petSecondaryBreed) {
    const analytics = UserInfoEventModel.prepareEvent({ petSecondaryBreed });

    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: 'secondary breed',
        event: 'Petfinder Consumer 402 - Happy Tails',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 403
 * Page: Happy Tails: Tell Us about your pet
 * Action: Fire upon successful submission of form (after clicking 'next') - Tell us About Your Pet page
 *
 * @public
 * @event PetfinderConsumer403
 * @param {bool} foundOnPetfinder
 */
export function analyticsHappyTails403(foundOnPetfinder) {
    // TODO: Confirm foundOnPetfinder is boolean
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: `found on petfinder: ${foundOnPetfinder ? 'yes' : 'no'}`,
        event: 'Petfinder Consumer 403 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 404
 * Page: Happy Tails: Tell Us about your pet
 * Action: Fire upon successful submission of form (after clicking 'next') - Tell us About Your Pet page
 *
 * @public
 * @event PetfinderConsumer404
 */
export function analyticsHappyTails404() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: 'photo added',
        event: 'Petfinder Consumer 404 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 405
 * Page: Happy Tails: Tell Us about your pet
 * Action: Fire this event when a user receives an error
 *
 * @public
 * @event PetfinderConsumer405
 * @param {string} errorMessage
 */
export function analyticsHappyTails405(errorMessage) {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'error',
        label: errorMessage,
        event: 'Petfinder Consumer 405 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 406
 * Page: Happy Tails: Tell Us about your pet
 * Action: User successfully clicks "Next" on Tell us about your pet page
 *
 * @public
 * @event PetfinderConsumer406
 */
export function analyticsHappyTails406() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: 'step 1: complete',
        event: 'Petfinder Consumer 406 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 407
 * Removed
 */

/**
 * Petfinder Consumer 408
 * Page: Happy Tails: Tell Us about your pet
 * Action: User clicks "X" on photo to remove on Tell Us about Your Pet page
 *
 * @public
 * @event PetfinderConsumer408
 */
export function analyticsHappyTails408() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: 'photo upload: remove photo',
        event: 'Petfinder Consumer 408 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 409
 * Page: Happy Tails: Tell Us about your pet
 * Action: User clicks "cancel" on delete photo/video page
 *
 * @public
 * @event PetfinderConsumer409
 */
export function analyticsHappyTails409() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: 'photo upload: cancel',
        event: 'Petfinder Consumer 409 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 410
 * Page: Happy Tails: Tell Us about your pet
 * Action: User clicks 'delete' on delete photo/video page
 *
 * @public
 * @event PetfinderConsumer410
 */
export function analyticsHappyTails410() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'pet information',
        label: 'photo upload: delete',
        event: 'Petfinder Consumer 410 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 411
 * Page: Happy Tails: Where did you adopt page
 * Action: Fire upon successful submission of form (after clicking 'next') - Where Did You Adopt page
 *
 * @public
 * @event PetfinderConsumer411
 * @param {string} location
 */
export function analyticsHappyTails411(location) {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'adoption location',
        label: location ? `state: ${location}` : 'state: not selected',
        event: 'Petfinder Consumer 411 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 412
 * Page: Happy Tails: Where did you adopt page
 * Action: Fire upon successful submission of form (after clicking 'next') - Where Did You Adopt page
 *
 * @public
 * @event PetfinderConsumer412
 * @param {Object} shelter
 */
export function analyticsHappyTails412(shelter) {
    const analytics = UserInfoEventModel.prepareEvent({
        petShelterId: shelter.id,
        petShelterName: shelter.name,
    });

    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'adoption location',
        label: `org: ${shelter.id || 'not found'}`,
        event: 'Petfinder Consumer 412 - Happy Tails',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 413
 * Page: Happy Tails: Where did you adopt page
 * Action: User clicks 'cancel' from the "where did you adopt" page
 *
 * @public
 * @event PetfinderConsumer413
 */
export function analyticsHappyTails413() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'cancel',
        label: 'adoption location',
        event: 'Petfinder Consumer 413 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 414
 * Page: Happy Tails: Where did you adopt page
 * Action: User clicks 'previous' from the "where did you adopt" page
 *
 * @public
 * @event PetfinderConsumer414
 */
export function analyticsHappyTails414() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'adoption location',
        label: 'previous page',
        event: 'Petfinder Consumer 414 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 415
 * Page: Happy Tails: Where did you adopt page
 * Action: Fire this event when a User successfully clicks 'next' from the "where did you adopt" page
 *
 * @public
 * @event PetfinderConsumer415
 */
export function analyticsHappyTails415() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'adoption location',
        label: 'step 2: complete',
        event: 'Petfinder Consumer 415 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 416
 * Page: Happy Tails: Adoption Story page
 * Action: Upon submission, Fire total character count from adoption story page
 *
 * @public
 * @event PetfinderConsumer416
 * @param {string} story
 */
export function analyticsHappyTails416(story) {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'adoption story',
        label: `character count: ${story.length}`,
        event: 'Petfinder Consumer 416 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 417
 * Page: Happy Tails: Adoption Story page
 * Action: Upon successful submission, fire this event when the User clicked the "Opt In" box
 *
 * @public
 * @event PetfinderConsumer417
 * @param {bool} boxSelected
 */
export function analyticsHappyTails417(boxSelected) {
    // TODO: Confirm boxSelected is boolean param
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'adoption story',
        label: `petfinder opt in: ${boxSelected ? 'yes' : 'no'}`,
        event: 'Petfinder Consumer 417 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 418
 * Page: Happy Tails: Adoption Story page
 * Action: User clicks 'cancel' from Adoption Story page
 *
 * @public
 * @event PetfinderConsumer418
 */
export function analyticsHappyTails418() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'cancel',
        label: 'adoption story',
        event: 'Petfinder Consumer 418 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 419
 * Page: Happy Tails: Adoption Story page
 * Action: User clicks 'previous page' on Adoption Story page
 *
 * @public
 * @event PetfinderConsumer419
 */
export function analyticsHappyTails419() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'adoption story',
        label: 'previous page',
        event: 'Petfinder Consumer 419 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 420
 * Page: Happy Tails: Adoption Story page
 * Action: User clicks 'submit' on Adoption Story page
 *
 * @public
 * @event PetfinderConsumer420
 * @param {Object} state
 */
export function analyticsHappyTails420(state) {
    const { petInformation, shelterInformation } = state;
    const analytics = UserInfoEventModel.prepareEvent({
        petName: _get(petInformation, 'formData.name'),
        petType: _get(petInformation, 'formData.type'),
        petBreed: _get(petInformation, 'formData.primary'),
        petSecondaryBreed: _get(petInformation, 'formData.secondary'),
        petShelterId: _get(shelterInformation, 'formData.organization'),
        petShelterName: _get(shelterInformation, 'displayAttrs.label'),
        city: _get(userMeStateController, 'state.user.city'),
        state: _get(userMeStateController, 'state.user.state'),
        postalCode: _get(userMeStateController, 'state.user.postalCode'),
        country: _get(userMeStateController, 'state.user.country'),
    });
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'adoption story',
        label: 'step 3: submit',
        event: 'Petfinder Consumer 420 - Happy Tails',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 421
 * Page: Happy Tails: Successful submission page
 * Action: Page loads, "Thanks for submitting your Happy Tail"
 *
 * @public
 * @event PetfinderConsumer421
 */
export function analyticsHappyTails421() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'successful submission',
        label: '',
        event: 'Petfinder Consumer 421 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 426
 * Page: Happy Tails-Stories Collection Landing page (a.k.a., Happy Tails Listing page)
 * Action: User clicks 'Read More' on Story tile
 *
 * @public
 * @event PetfinderConsumer426
 * @param {string} storyURL
 */
export function analyticsHappyTails426(storyURL) {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'story main',
        label: `select story: ${storyURL}`,
        event: 'Petfinder Consumer 426 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 427
 * Page: Happy Tails-Stories Collection Landing page (a.k.a., Happy Tails Listing page)
 * Action: User clicks 'Tell Us Your Story' on Create Your Happy Tail from the Stories collection landing page
 *
 * @public
 * @event PetfinderConsumer427
 */
export function analyticsHappyTails427() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'story main',
        label: 'create your happy tail',
        event: 'Petfinder Consumer 427 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 428
 * Page: Happy Tails-Stories Collection Landing page (a.k.a., Happy Tails Listing page)
 * Action: User navigates to a different page of Happy Tails stories
 *
 * @public
 * @event PetfinderConsumer428
 * @param {string} pageNum
 */
export function analyticsHappyTails428(pageNum) {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'story main',
        label: `Page ${pageNum}`,
        event: 'Petfinder Consumer 428 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 430
 * Page: Happy Tails-detail page
 * Action: User clicks arrow to share on social
 *
 * @public
 * @event PetfinderConsumer430
 */
export function analyticsHappyTails430() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'clicks to share',
        label: 'story detail page',
        event: 'Petfinder Consumer 430 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 431
 * Page: Happy Tails-detail page
 * Action: User Clicks "Animal Shelter link" from the 'Adopted From' section of the page
 *
 * @public
 * @event PetfinderConsumer431
 * @param {string} shelterId
 */
export function analyticsHappyTails431(shelterId) {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'story detail page',
        label: `adopted from: ${shelterId}`,
        event: 'Petfinder Consumer 431 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 432
 * Page: Happy Tails-detail page
 * Action: User clicks 'Tell Us Your Story' on Create Your Happy Tail
 *
 * @public
 * @event PetfinderConsumer432
 */
export function analyticsHappyTails432() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'story detail page',
        label: 'create your happy tail',
        event: 'Petfinder Consumer 432 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 433
 * Page: Happy Tails-detail page
 * Action: User clicks 'Read More" on story detail page
 *
 * @public
 * @event PetfinderConsumer433
 * @param {string} happyTailURL
 */
export function analyticsHappyTails433(happyTailURL) {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'story detail page',
        label: `read more: ${happyTailURL}`,
        event: 'Petfinder Consumer 433 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 434
 * Page: Happy Tails-detail page
 * Action: User clicks 'View Pet Adoption Stories' on story detail page
 *
 * @public
 * @event PetfinderConsumer434
 */
export function analyticsHappyTails434() {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: 'story detail page',
        label: 'View Pet Adoption Stories',
        event: 'Petfinder Consumer 434 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 435
 * Page: Happy Tails-detail page
 * Action: User clicks Email icon to share story
 *
 * @public
 * @event PetfinderConsumer435
 */
export function analyticsHappyTails435() {
    trackEvent({
        category: 'email share',
        action: 'happy tails',
        label: `story: ${window.location.href}`,
        event: 'Petfinder Consumer 435 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 436
 * Page: Happy Tails-detail page
 * Action: User clicks Link icon to share story
 *
 * @public
 * @event PetfinderConsumer436
 */
export function analyticsHappyTails436() {
    trackEvent({
        category: 'link share',
        action: 'happy tails',
        label: `story: ${window.location.href}`,
        event: 'Petfinder Consumer 436 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 437
 * Page: Happy Tails-detail page
 * Action: Shares Any Happy Tail (social only)
 *
 * Social Share - client is handling all social interactions and dataLayer interactions
 */

/**
 * Petfinder Consumer 442
 * Page: Happy Tails- pet detail page
 * Action: User clicks 'Tell Us Your Story' on Create Your Happy Tail from PDP
 *
 * @public
 * @event PetfinderConsumer442
 * @param {string} feature initiating feature for event
 */
export function analyticsHappyTails442(feature = 'pet detail page') {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: feature,
        label: 'create your happy tail',
        event: 'Petfinder Consumer 442 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 443
 * Page: Happy Tails- pet detail page
 * Action: User clicks 'Read More" on story detail page from PDP
 *
 * @public
 * @event PetfinderConsumer443
 * @param {string} storyURL
 * @param {string} feature initiating feature for event
 */
export function analyticsHappyTails443(storyURL, feature = 'pet detail page') {
    trackEvent({
        category: HAPPY_TAILS_CATEGORY,
        action: feature,
        label: `read more: ${storyURL}`,
        event: 'Petfinder Consumer 443 - Happy Tails',
        dimensions: {},
        additional: {},
    });
}

/**
 * analyticsFilterHappyTailFormCancel
 * Page: N/A - Happy Tails Submission Form
 * Action: User clicks 'Cancel' on Happy Tails Submission Form
 *
 * @public
 * @fires PetfinderConsumer396
 * @fires PetfinderConsumer397
 * @fires PetfinderConsumer413
 * @fires PetfinderConsumer418
 *
 * @event analyticsFilterHappyTailFormCancel
 * @param {Object} filters
 */
export function analyticsFilterHappyTailFormCancel(filters) {
    const { isLoggedIn, isAuthorized, currentStepNum } = filters;
    if (isLoggedIn || isAuthorized) {
        switch (currentStepNum) {
            case 1:
                analyticsHappyTails397();
                break;
            case 2:
                analyticsHappyTails413();
                break;
            case 3:
                analyticsHappyTails418();
                break;
        }
    } else {
        analyticsHappyTails396();
    }
}

/**
 * analyticsFilterHappyTailFormPrevious
 * Page: N/A - Happy Tails Submission Form
 * Action: User clicks 'Previous Page' on Happy Tails Submission Form
 *
 * @public
 * @fires PetfinderConsumer414
 * @fires PetfinderConsumer419
 *
 * @event analyticsFilterHappyTailFormPrevious
 * @param {Object} filters
 */
export function analyticsFilterHappyTailFormPrevious(filters) {
    const { currentStepNum } = filters;
    switch (currentStepNum) {
        case 2:
            analyticsHappyTails414();
            break;
        case 3:
            analyticsHappyTails419();
            break;
    }
}

/**
 * analyticsFilterHappyTailSocialShare
 * Page: Happy Tails-detail page
 * Action: User clicks social share icon button
 *
 * @public
 * @fires PetfinderConsumer435
 * @fires PetfinderConsumer436
 *
 * @event analyticsFilterHappyTailSocialShare
 * @param {string} filter
 */
export function analyticsFilterHappyTailSocialShare(filter) {
    switch (filter) {
        case 'email':
            analyticsHappyTails435();
            break;
        case 'link':
            analyticsHappyTails436();
            break;
    }
}

/**
 * Happy Tail Collection Form Next 1
 * Page: Happy Tails: Tell Us about your pet
 * Action: User successfully clicks "Next" on Tell us about your pet page
 *
 * @public
 * @fires PetfinderConsumer398
 * @fires PetfinderConsumer399
 * @fires PetfinderConsumer400
 * @fires PetfinderConsumer401
 * @fires PetfinderConsumer402
 * @fires PetfinderConsumer403
 * @fires PetfinderConsumer404
 * @fires PetfinderConsumer406
 *
 * @event analyticsCollectionHappyTailFormNext1
 * @param {Object} formData
 */
export function analyticsCollectionHappyTailFormNext1(formData) {
    // This is NOT A FILTER, this is a COLLECTION. Recommend combining these events to 1 single event(Petfinder Consumer 406) if possible. This does NOT analytically paint an accurate picture.
    analyticsHappyTails398(formData.name);
    analyticsHappyTails399(formData.type);
    analyticsHappyTails400(formData['adoption_date']);
    analyticsHappyTails401(formData.primary);
    analyticsHappyTails402(formData.secondary);
    analyticsHappyTails403(formData['found_on_petfinder']);
    analyticsHappyTails404();
    analyticsHappyTails406(); // Step 1 successful
}

/**
 * Happy Tail Collection Form Next 2
 * Page: Happy Tails: Where did you adopt page
 * Action: Fire upon successful submission of form (after clicking 'next') - Where Did You Adopt page
 *
 * @public
 * @fires PetfinderConsumer411
 * @fires PetfinderConsumer412
 * @fires PetfinderConsumer415
 *
 * @event analyticsCollectionHappyTailFormNext2
 * @param {Object} formData
 */
export function analyticsCollectionHappyTailFormNext2(formData) {
    const { displayAttrs, value } = formData;
    const organization = {
        id: value.organization || '',
        name: displayAttrs.label || '',
    };

    // This is NOT A FILTER, this is a COLLECTION. Recommend combining these events to 1 single event(Petfinder Consumer 415) if possible. This does NOT analytically paint an accurate picture.
    analyticsHappyTails411(value.stateProvince);
    analyticsHappyTails412(organization);
    analyticsHappyTails415(); // Step 2 successful
}

/**
 * Happy Tail Collection Form Submit
 * Page: Happy Tails: Adoption Story page
 * Action: User clicks 'submit' on Adoption Story page
 *
 * @public
 * @fires PetfinderConsumer416
 * @fires PetfinderConsumer417
 *
 * @event analyticsCollectionHappyTailFormSubmit
 * @param {Object} formData
 */
export function analyticsCollectionHappyTailFormSubmit(formData) {
    // This is NOT A FILTER, this is a COLLECTION. Recommend combining these events to 1 single event(Petfinder Consumer 420) if possible. This does NOT analytically paint an accurate picture.
    analyticsHappyTails416(formData.story);
    analyticsHappyTails417(formData['is_public']);
}

/**
 * Happy Tail Collection Form Validation Error
 * Page: N/A - Happy Tails Submission Form
 * Action: User clicks 'Previous Page' on Happy Tails Submission Form
 *
 * @public
 * @fires PetfinderConsumer405
 *
 * @event analyticsCollectionHappyTailFormValidationError
 * @param {Object} validationData
 */
export function analyticsCollectionHappyTailFormValidationError(
    validationData
) {
    // This is NOT A FILTER, this is a COLLECTION. Recommend combining these events to 1 single event(Petfinder Consumer 405) if possible. This does NOT analytically paint an accurate picture.
    const { errors } = validationData;
    errors.forEach(error => {
        const { message, path } = error;
        analyticsHappyTails405(
            `${path[0]}: ${
                message.includes('Invalid value null supplied')
                    ? 'Invalid value null supplied.'
                    : message
            }`
        );
    });
}
