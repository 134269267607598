import t from 'tcomb-form';

/**
 * @type {Object}
 */
export const FIELDS = {
    USERNAME: 'username',
    PASSWORD: 'password',
    NEXT: 'next',
    NEXT_PARAMS: 'next_params',
    LOGIN_PATH: 'login_path',
    TOKEN: 'token',
    PROVIDER: 'provider',
};

/**
 * @type {Object}
 */
export default t.struct({
    [FIELDS.USERNAME]: t.String,
    [FIELDS.PASSWORD]: t.String,
    [FIELDS.NEXT]: t.maybe(t.String),
    [FIELDS.NEXT_PARAMS]: t.maybe(t.String),
    [FIELDS.LOGIN_PATH]: t.maybe(t.String),
    [FIELDS.TOKEN]: t.String,
    [FIELDS.PROVIDER]: t.String,
});
