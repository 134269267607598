import { html } from '../../../core/scripts/util/util.js';
import { helpers } from  '../../../core/scripts/util/helpers';

export default vm => html`
    <ul class="savedSearchList">
        ${helpers.if(
            vm.savedSearches,
            vm.savedSearches.map(item => html`
                <li class="savedSearchList-item">
                    <pf-ensighten on-click="Consumer047" search-name="$${item.name}">
                        <a class="savedSearchList-item-link" href="$${item.url}" data-action="view results" data-label="$${item.name}">
                            <div class="split">
                                <div class="split-item">
                                    <span class="txt">&ldquo;$${item.name}&rdquo;</span>
                                </div>
                                <div class="split-item">
                                    <span class="icon icon_xs m-icon_colorDark">
                                        <svg role="img">
                                            <use xlink:href="#icon-chevronRight" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </a>
                    </pf-ensighten>
                </li>
            `)
        )}
    </ul>
`;
