/**
 * # App Level Status Text Message Constants
 */

/**
 * ## Media Upload
 */
export const STATUS_MEDIA_UPLOAD_UPLOADING = 'Uploading media...';
export const STATUS_MEDIA_UPLOAD_COMPLETE = 'Upload complete';
export const STATUS_MEDIA_DELETION_COMPLETE = 'Media deletion complete';

/**
 * ## Notifications List
 */
export const STATUS_NOTIFICATION_DISMISSED = 'Notification dismissed';
export const STATUS_NOTIFICATION_ALL_DISMISSED = 'All notifications dismissed';

/**
 * ## Password Validator
 */
export const STATUS_PASSWORD_VALID = 'Valid: Password requirements met';
export const STATUS_PASSWORD_INVALID = 'Invalid: Password requirements NOT met';

/**
 * ## Section Expand
 */
export const STATUS_SECTION_EXPANDED = 'The section has been expanded';
export const STATUS_SECTION_COLLAPSED = 'The section has been collapsed';

/**
 * ## Templates List
 */
export const STATUS_TEMPLATES_LIST_LOADING = 'Loading templates.';
export const STATUS_TEMPLATES_LIST_NONE_FOUND = 'No templates found.';
export const STATUS_TEMPLATES_LIST_LOADED = 'Templates loaded.';
export const STATUS_TEMPLATES_LIST_LOAD_ERROR = 'Error loading templates.';
