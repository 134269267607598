import BaseCollection from './BaseCollection';
import CountryModel from './CountryModel';

export default class CountryCollection extends BaseCollection {
    /**
     * @param {array} data array of country objects
     * @returns {CountryCollection}
     */
    static fromServer(data) {
        if (!data || data.length == null) {
            return new CountryCollection();
        }

        return new CountryCollection(
            data.map(country => CountryModel.fromServer(country)));
    }
}
