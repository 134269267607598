import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { CLASS_IS_HIDDEN, CLASS_IS_VISUALLY_HIDDEN } from '../../constants/classes';
import { EV_HELLO_BAR_SHOW } from '../../constants/events';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';
import { ERROR_API } from '../../constants/errors';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-saved-search-list-controller';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    // INSERTION_POINT:            `[${ELEMENT_TAG_NAME}-insertion]`,
};

/**
 * Reference to the element or selector name to query for the cards
 * @type {string}
 */
const SAVED_SEARCH_CARD_SELECTOR = 'pfdc-saved-search-card';

/**
 * This controller component listens for updates from various filter/sort components to update
 * the saved search list view. It also handles the editing and deletion of the saved search cards.
 *
 * REQUIRED BASE MARKUP:
 * ---
 * <pfdc-saved-search-list-controller></pfdc-saved-search-list-controller>
 * ---
 *
 * @extends PFElement
 */
export class PFDCSavedSearchListControllerElement extends PFElement {
    /**
     * Get all the card elements
     * @return {Array}
     */
    get cards() {
        return Array.from(this.querySelectorAll(SAVED_SEARCH_CARD_SELECTOR));
    }

    /**
     * Send a Google Analytics event to the document to update the filter state
     * @param {string} filterName the name of the filter
     * @return {void}
     */
    fireGa4FilterChangeEvent(filterName) {
        /* eslint-disable camelcase, no-undefined */
        window.dataLayer.push(
            {
                event: 'filter_applied',
                ga4: true,
                event_params: {
                    filter_name: 'species',
                    filter_value: filterName ? filterName.toLowerCase() : '',
                    page_type: 'search alerts',
                },
            },
            { event_params: undefined }
        );
    }

    /**
     * Filter the list of pet saved search cards by id
     * @param {number} id pet type id
     * @param {string} name filter type name
     */
    filterListByTypeId(id, name) {
        this.fireGa4FilterChangeEvent(name);
        if (id === undefined) {
            this.cards.forEach(card => {
                card.classList.remove(CLASS_IS_HIDDEN);
            });
            return;
        } else {
            this.cards.forEach(card => {
                card.classList.add(CLASS_IS_HIDDEN);
            });

            const filteredCards = this.cards.filter(card => card.getAttribute('type-id') === id);

            filteredCards.forEach(card => {
                card.classList.remove(CLASS_IS_HIDDEN);
            });
        }
    }

    /**
     * Remove a card from the list
     * @param {element} cardElement the card element to be removed
     */
    removeCard(cardElement) {
        if (this.cards.includes(cardElement)) {
            const index = this.cards.indexOf(cardElement);
            this.cards[index].remove();

            this.dispatchAction('saved-search-list.card.delete-success', {
                target: cardElement,
            });

            // Show the hello bar
            this.dispatchAction(EV_HELLO_BAR_SHOW);
        }
    }

    /**
     * Handles update actions from cards
     * @param {Object} detail
     */
    async updateSavedSearchItem(detail) {
        const { uuid, formAction, formData, cardElement } = detail;
        if (!uuid || !formAction || !formData || !cardElement) {
            return;
        }
        try {
            this.dispatchAction('saved-search-list.card.update', {
                target: cardElement,
            });
            const response = await this.userSavedSearchRepository.update(uuid, formAction, formData);

            if (cardElement.subscribeCheckbox) {
                // Show hello bar on unsubscribe
                if (!cardElement.subscribeCheckbox.checked) {
                    this.dispatchAction(EV_HELLO_BAR_SHOW);
                }
            }

            this.dispatchAction('saved-search-list.card.update-success', {
                target: cardElement,
            });
        } catch (error) {
            let errors;
            try {
                errors = error.errors;
            } catch (error) {
                errors = [{ global: ERROR_API }];
            }
            this.dispatchAction('saved-search-list.card.update-error', {
                errors,
                target: cardElement,
            });
        }
    }

    /**
     * Handles delete actions from cards
     * @param {Object} detail
     */
    async deleteSavedSearchItem(detail) {
        const { uuid, formAction, formData, cardElement } = detail;
        if (!uuid || !formAction || !formData || !cardElement) {
            return;
        }
        try {
            this.dispatchAction('saved-search-list.card.delete', {
                target: cardElement,
            });
            const response = await this.userSavedSearchRepository.delete(uuid, formAction, formData);
            this.removeCard(cardElement);
            this.dispatchAction('analytics', {
                eventId: 'Consumer112',
                savedSearchTitle: cardElement.displayNameValue,
            });
        } catch (error) {
            let errors;
            try {
                errors = error.errors;
            } catch (error) {
                errors = [{ global: ERROR_API }];
            }
            this.dispatchAction('saved-search-list.card.delete-error', {
                errors,
                target: cardElement,
            });
        }
    }

    /**
     * Handles update and delete events from the app level
     * @param {Object} ev event object
     */
    onUpdated(ev) {
        const { detail } = ev;
        if (detail.type === 'saved-search-list.carousel-nav.select') {
            this.filterListByTypeId(detail.navItemElement.dataset.typeId, detail.navItemElement.dataset.typeName);
        }
        if (detail.type === 'saved-search-list.card.change-submit') {
            this.updateSavedSearchItem(detail);
        }
        if (detail.type === 'saved-search-list.card.delete-submit') {
            this.deleteSavedSearchItem(detail);
        }
    }
}

export default PFDCSavedSearchListControllerElement;
