import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { EV_UI_DEACTIVATE } from '../../constants/events';
import { CLASS_IS_OPEN } from '../../constants/classes';
import { KEY_ESCAPE, KEY_ESC } from '../../constants/keyboard';

/**
 * Mediates focus/activation states of the header dropdowns menus.
 */
export class PFDCHeaderDropdownElement extends PFElement {
    /**
     * @property {string|null} bindActiveClassTo
     */
    get bindActiveClassTo() {
        return this.getAttribute('bind-active-class-to') || null;
    }

    /* *******************************************
     * Lifecycle
     ********************************************/

    constructor() {
        super();

        /**
         * @property elementsBoundToActiveClass
         * @type {Array} HTMLElement
         */
        this.elementsBoundToActiveClass = null;

        this._uiGroup = 'global';

        this.refocusTriggerOnDeactivate = true;
        this.focusFirstFocusable = true;

        this.onDocumentClickedHandler = this.onDocumentClicked.bind(this);
    }

    onInit() {
        // override default 'u-isActive' value
        this.dataset.activeClass = this.dataset.activeClass || CLASS_IS_OPEN;

        this.elementsBoundToActiveClass = this.bindActiveClassTo
            ? Array.from(document.querySelectorAll(this.bindActiveClassTo))
            : null;

        this.fapMobileModal = document.querySelector(
            '[pfdc-fap-mobile-select-modal]'
        );

        this.findOtherPetsMenuContainer = this.querySelector(
            '.fapMenu-inner-section_select'
        );

        this.addEventListener('keydown', this.onKeyDowned);
    }

    async activate() {
        await super.activate();

        setTimeout(() => {
            this.classList.add('u-animateIn');
        }, 250);

        if (!this.hasAttribute('pfdc-header-profilemenu')) {
            this.removeAttribute('aria-hidden');
        }

        setTimeout(() => {
            if (this.elementsBoundToActiveClass) {
                this.elementsBoundToActiveClass.forEach(el => {
                    el.classList.add(this.activeClass);
                    el.setAttribute('aria-expanded', 'true');
                });
            }
        }, 0);
        document.addEventListener('click', this.onDocumentClickedHandler, true);
    }

    async deactivate() {
        await super.deactivate();

        this.classList.remove('u-animateIn');

        if (!this.hasAttribute('pfdc-header-profilemenu')) {
            this.setAttribute('aria-hidden', 'true');
        }

        if (this.elementsBoundToActiveClass) {
            this.elementsBoundToActiveClass.forEach(el => {
                el.classList.remove(this.activeClass);
                el.setAttribute('aria-expanded', 'false');
            });
        }

        if (this.findOtherPetsMenuContainer) {
            this.findOtherPetsMenuContainer.classList.remove(CLASS_IS_OPEN);
        }
        document.removeEventListener('click', this.onDocumentClickedHandler, true);
    }

    /* *******************************************
     * Events
     ********************************************/

    /**
     * Handles keydown events
     *
     * @method  onKeyDowned
     * @param  {Object} event
     */
    onKeyDowned(event) {
        switch (event.key) {
            case KEY_ESCAPE:
            case KEY_ESC:
                this.dispatchAction(EV_UI_DEACTIVATE);
                break;
        }
    }

    /**
     * Handles click events
     *
     * @method  onDocumentClicked
     * @param  {Object} event
     */
    onDocumentClicked(event) {
        const { target } = event;
    }
}

export default PFDCHeaderDropdownElement;
