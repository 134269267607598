import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-saved-search-card';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    CHANGE_ACTIVATE_BTN: `[${ELEMENT_TAG_NAME}-activate-change]`,
    CHANGE_FORM: `[${ELEMENT_TAG_NAME}-change-form]`,
    CHANGE_FORM_SUBMIT_BTN: `[${ELEMENT_TAG_NAME}-change-form-submit]`,
    DELETE_ACTIVATE_BTN: `[${ELEMENT_TAG_NAME}-activate-delete]`,
    DELETE_FORM: `[${ELEMENT_TAG_NAME}-delete-form]`,
    DELETE_FORM_SUBMIT_BTN: `[${ELEMENT_TAG_NAME}-delete-form-submit]`,
    DELETE_FORM_CANCEL_BTN: `[${ELEMENT_TAG_NAME}-delete-form-cancel]`,
    DISPLAY_NAME: `[${ELEMENT_TAG_NAME}-display-name]`,
    DISPLAY_NAME_TEXTAREA: `[${ELEMENT_TAG_NAME}-display-name-textarea]`,
    SUBSCRIBE_CHECKBOX: `[${ELEMENT_TAG_NAME}-subscribe-checkbox]`,
    SEARCH_LINK: `[${ELEMENT_TAG_NAME}-search-link]`,
    ERRORS_CONTAINER: `[${ELEMENT_TAG_NAME}-errors]`,
};

const ATTRIBUTES = {
    TYPE_ID: 'type-id',
    SEARCH_ID: 'search-id',
    SPECIES_ID: 'species-id',
    ADOPTION_STATUS: 'adoption-status',
    NAME: 'name',
    BREED_IDS: 'breed-ids',
    AGES: 'ages',
    SEXES: 'sexes',
    SIZES: 'sizes',
    COLORS: 'colors',
    COAT_LENGTHS: 'coat-lengths',
    ATTRIBUTES_LIST: 'attributes-list',
    ORGANIZATION_IDS: 'organization-ids',
    ANIMAL_IDS: 'animal-ids',
    LOCATION_WITHN_MILES: 'location-within-miles',
    BEHAVIOR_DOGS: 'behavior-dogs',
    BEHAVIOR_CATS: 'behavior-cats',
    BEHAVIOR_OTHER_ANIMALS: 'behavior-other-animals',
    BEHAVIOR_CHILDREN: 'behavior-children',
};

const ERROR_ATTRIBUTE_NAME = 'pf-saved-search-card-error';

const LOADING_CLASS = 'savedSearchCard_loading';

/**
 * Handles all the js states and eventing for interactions on a saved search card.
 *
 * @extends PFElement
 */
export class PFDCSavedSearchCardElement extends PFElement {
    get state() {
        return this.getAttribute('state');
    }

    get typeId() {
        return this.getAttribute('type-id');
    }

    get searchId() {
        return this.getAttribute('search-id');
    }

    /**
     * Initialize this component
     */
    onInit() {
        this.changeActivateBtn = this.querySelector(ELEMENT_SELECTORS.CHANGE_ACTIVATE_BTN);
        this.changeForm = this.querySelector(ELEMENT_SELECTORS.CHANGE_FORM);
        this.changeFormSubmitBtn = this.querySelector(ELEMENT_SELECTORS.CHANGE_FORM_SUBMIT_BTN);
        this.subscribeCheckbox = this.querySelector(ELEMENT_SELECTORS.SUBSCRIBE_CHECKBOX);

        this.displayName = this.querySelector(ELEMENT_SELECTORS.DISPLAY_NAME);
        this.displayNameTextarea = this.querySelector(ELEMENT_SELECTORS.DISPLAY_NAME_TEXTAREA);
        this.displayNameValue = this.displayNameTextarea.value;

        this.deleteActivateBtn = this.querySelector(ELEMENT_SELECTORS.DELETE_ACTIVATE_BTN);
        this.deleteForm = this.querySelector(ELEMENT_SELECTORS.DELETE_FORM);
        this.deleteFormSubmitBtn = this.querySelector(ELEMENT_SELECTORS.DELETE_FORM_SUBMIT_BTN);
        this.deleteCancelBtn = this.querySelector(ELEMENT_SELECTORS.DELETE_FORM_CANCEL_BTN);

        this.searchLink = this.querySelector(ELEMENT_SELECTORS.SEARCH_LINK);

        this.errorsContainer = this.querySelector(ELEMENT_SELECTORS.ERRORS_CONTAINER);

        this.addEventListener('click', this.onClicked);
        this.addEventListener('change', this.onChanged);
        this.addEventListener('submit', this.onSubmit);

        this.setState('init');
    }

    /**
     * Hides and shows various elements of the initial state
     */
    renderInitState() {
        // Make sure display name text area is set to original name
        this.displayNameTextarea.value = this.displayNameValue;

        this.changeActivateBtn.classList.remove('u-isActive');

        // Toggle label after delay
        if (this.changeActivateBtn.getAttribute('aria-label').includes('Cancel ')) {
            setTimeout(() => {
                this.changeActivateBtn.setAttribute(
                    'aria-label',
                    `${this.changeActivateBtn.getAttribute('aria-label').replace('Cancel ', '')}`
                );
            }, 250);
        }

        this.deleteActivateBtn.classList.remove('u-isActive');
        this.deleteActivateBtn.setAttribute('aria-pressed', 'false');

        // Hide
        this.displayNameTextarea.classList.add('u-isHidden');
        this.changeFormSubmitBtn.classList.add('u-isHidden');
        this.deleteForm.classList.add('u-isHidden');

        // Show
        this.changeForm.classList.remove('u-isHidden');
        this.displayName.classList.remove('u-isHidden');
        this.searchLink.classList.remove('u-isHidden');
    }

    /**
     * Hides and shows various elements of change state
     */
    renderChangeState() {
        this.changeActivateBtn.classList.add('u-isActive');
        this.changeActivateBtn.setAttribute(
            'aria-label',
            `Cancel ${this.changeActivateBtn.getAttribute('aria-label')}`
        );

        // Hide
        this.displayName.classList.add('u-isHidden');
        this.searchLink.classList.add('u-isHidden');

        // Show
        this.displayNameTextarea.classList.remove('u-isHidden');
        this.changeFormSubmitBtn.classList.remove('u-isHidden');

        scheduleMicrotask(() => {
            this.focusManager.focusElement(this.displayNameTextarea);
        });
    }

    /**
     * Hides and shows various elements of delete state
     */
    renderDeleteState() {
        this.deleteActivateBtn.classList.add('u-isActive');
        this.deleteActivateBtn.setAttribute('aria-pressed', 'true');

        // Hide
        this.changeForm.classList.add('u-isHidden');

        // Show
        this.deleteForm.classList.remove('u-isHidden');

        scheduleMicrotask(() => {
            this.focusManager.focusFirstFocusable(this.deleteForm);
        });
    }

    /**
     * Switches the cards state
     * @param {string} state string name of state to switch to
     */
    setState(state) {
        if (state) {
            this.setAttribute('state', state);
            switch (state) {
                case 'init':
                    this.renderInitState();
                    break;
                case 'change':
                    this.renderChangeState();
                    break;
                case 'delete':
                    this.renderDeleteState();
                    break;
                default:
                    break;
            }
        } else {
            this.removeAttribute('state');
        }
    }

    /**
     * Trigger change form submit action
     */
    triggerChangeFormSubmit() {
        if (this.displayNameTextarea.value === '') {
            this.displayNameTextarea.value = this.displayNameValue;
            this.displayNameTextarea.select();
            return;
        }
        this.dispatchAction('saved-search-list.card.change-submit', {
            cardElement: this,
            uuid: this.searchId,
            formAction: this.changeForm.action,
            formData: new FormData(this.changeForm),
        });
    }

    /**
     * Trigger delete form submit action
     */
    triggerDeleteFormSubmit() {
        this.fireGa4DeleteEvent();
        this.dispatchAction('saved-search-list.card.delete-submit', {
            cardElement: this,
            uuid: this.searchId,
            formAction: this.deleteForm.action,
            formData: new FormData(this.deleteForm),
        });
    }

    /**
     * Handles click events
     * @param {Object} ev event object
     */
    onClicked(ev) {
        if (ev.target === this.searchLink) {
            const searchName = this.searchLink.dataset.searchName;
            this.dispatchAction('analytics', {
                eventId: 'Consumer113',
                searchName,
            });
            this.fireGa4SearchEvent();
        }
        if (ev.target === this.changeActivateBtn || this.changeActivateBtn.contains(ev.target)) {
            this.state !== 'change' ? this.setState('change') : this.setState('init');
        }
        if (ev.target === this.deleteActivateBtn || this.deleteActivateBtn.contains(ev.target)) {
            this.state !== 'delete' ? this.setState('delete') : this.setState('init');
        }
        if (ev.target === this.deleteCancelBtn) {
            this.setState('init');

            scheduleMicrotask(() => {
                this.focusManager.focusElement(this.deleteActivateBtn);
            });
        }

        this.removeCardErrors();
    }

    /**
     * Handles change events
     * @param {Object} ev event object
     */
    onChanged(ev) {
        if (ev.target === this.subscribeCheckbox) {
            const checked = ev.target.checked ? 'yes' : 'no';
            this.dispatchAction('analytics', {
                eventId: 'Consumer111',
                savedSearchTitle: this.displayNameTextarea.value,
                checked,
            });
            this.triggerChangeFormSubmit();
        }
    }

    /**
     * Handles form submission events
     * @param {Object} ev event object
     */
    onSubmit(ev) {
        ev.preventDefault();
        if (ev.target === this.changeForm) {
            this.triggerChangeFormSubmit();
        }
        if (ev.target === this.deleteForm) {
            this.triggerDeleteFormSubmit();
        }
    }

    /**
     * Render errors from updating or deleting
     * @param {Array} errors of error strings
     */
    renderErrors(errors) {
        this.removeCardErrors();

        let errorStr = '';
        let errorArray = [];
        if (errors.hasOwnProperty('global')) {
            errorArray = errorArray.concat(errors.global);
        }

        if (errors.hasOwnProperty('fields')) {
            const fieldKeys = Object.keys(errors.fields);
            fieldKeys.forEach(key => {
                errorArray = errorArray.concat(errors.fields[key]);
            });
        }

        errorStr = errorArray.join(' ');

        this.errorsContainer.innerText = errorStr;
    }

    /**
     * Removes errors
     */
    removeCardErrors() {
        if (this.errorsContainer) {
            this.errorsContainer.innerHTML = '';
        }
    }

    /**
     * Handles when the card update button has been triggered
     * @param {Object} detail event object
     */
    handleCardUpdateStart(detail) {
        this.classList.add(LOADING_CLASS);
    }

    /**
     * Handles when the card update has been successfull
     * @param {Object} detail event object
     */
    handleCardUpdateSucess(detail) {
        this.classList.remove(LOADING_CLASS);

        // Check if the original value is different from submitted
        if (this.displayNameTextarea.value !== this.displayNameValue) {
            this.dispatchAction('analytics', {
                eventId: 'Consumer110',
                savedSearchTitle: this.displayNameTextarea.value,
            });
        }

        // Update the displayName prop to new value
        this.displayNameValue = this.displayNameTextarea.value;
        this.displayName.innerText = `"${this.displayNameValue}"`;
        this.setState('init');

        this.dispatchAction('', { statusText: 'Search item updated' });

        scheduleMicrotask(() => {
            this.focusManager.focusElement(this.displayName);
        });
    }

    /**
     * Handles when the card update has returned an error
     * @param {Object} detail event object
     */
    handleCardUpdateError(detail) {
        this.renderErrors(detail.errors);
        this.classList.remove(LOADING_CLASS);
    }

    /**
     * Removes this card from DOM
     */
    handleCardDeleteSuccess() {
        this.classList.remove(LOADING_CLASS);
    }

    /**
     * Handles when the card delete action has returned an error
     * @param {Object} detail event object
     */
    handleCardDeleteError(detail) {
        this.renderErrors(detail.errors);
        this.classList.remove(LOADING_CLASS);
    }

    /**
     * Handles update events from the app level
     * @param {Object} ev event object
     */
    onUpdated(ev) {
        const { detail } = ev;
        // Reset card to init state when nav selection occurs
        if (detail.type === 'saved-search-list.carousel-nav.select') {
            this.setState('init');
        }

        if (detail.target && detail.target === this) {
            if (detail.type === 'saved-search-list.card.update') {
                this.handleCardUpdateStart(detail);
            }
            if (detail.type === 'saved-search-list.card.update-success') {
                this.handleCardUpdateSucess(detail);
            }
            if (detail.type === 'saved-search-list.card.update-error') {
                this.handleCardUpdateError(detail);
            }
            if (detail.type === 'saved-search-list.card.delete') {
                this.handleCardUpdateStart(detail);
            }
            if (detail.type === 'saved-search-list.card.delete-success') {
                this.handleCardDeleteSuccess();
            }
            if (detail.type === 'saved-search-list.card.delete-error') {
                this.handleCardDeleteError(detail);
            }
        }
    }

    /**
     * Takes in a string of comma separated values, lowercases them, sorts them alphabetically, and returns a new comma separated string
     * @param {string} str comma separated string
     * @returns {string} alphabetized and lowercase comma separated string
     */
    alphabetizeAndLowercaseCommaSeparatedList(str) {
        if (!str) {
            return '';
        }
        return str
            .split(',')
            .map(s => s.trim().toLowerCase())
            .sort()
            .join(', ');
    }

    getEventParamsForSearch() {
        const behavior = [
            this.getAttribute(ATTRIBUTES.BEHAVIOR_DOGS) === 'yes' ? 'good with dogs' : '',
            this.getAttribute(ATTRIBUTES.BEHAVIOR_CATS) === 'yes' ? 'good with cats' : '',
            this.getAttribute(ATTRIBUTES.BEHAVIOR_OTHER_ANIMALS) === 'yes' ? 'good with other animals' : '',
            this.getAttribute(ATTRIBUTES.BEHAVIOR_CHILDREN) === 'yes' ? 'good with children' : '',
        ]
            .filter(Boolean)
            .map(s => s.trim().toLowerCase())
            .sort()
            .join(', ');

        /* eslint-disable camelcase, no-undefined */
        const eventParams = {
            search_category: 'pet',
            method: 'search alerts',
            pet_species: this.getAttribute(ATTRIBUTES.TYPE_ID),
            pet_age: this.alphabetizeAndLowercaseCommaSeparatedList(this.getAttribute(ATTRIBUTES.AGES)),
            pet_breed_1: this.alphabetizeAndLowercaseCommaSeparatedList(this.getAttribute(ATTRIBUTES.BREED_IDS)),
            pet_gender: this.alphabetizeAndLowercaseCommaSeparatedList(this.getAttribute(ATTRIBUTES.SEXES)),
            pet_distance:
                this.alphabetizeAndLowercaseCommaSeparatedList(this.getAttribute(ATTRIBUTES.LOCATION_WITHN_MILES)) ||
                'anywhere',
            pet_size: this.alphabetizeAndLowercaseCommaSeparatedList(this.getAttribute(ATTRIBUTES.SIZES)),
            pet_color: this.alphabetizeAndLowercaseCommaSeparatedList(this.getAttribute(ATTRIBUTES.COLORS)),
            pet_household: this.alphabetizeAndLowercaseCommaSeparatedList(
                this.getAttribute(ATTRIBUTES.ATTRIBUTES_LIST)
            ),
            pet_coat_length: this.alphabetizeAndLowercaseCommaSeparatedList(this.getAttribute(ATTRIBUTES.COAT_LENGTHS)),
            pet_health_behavior: behavior,
            pet_name: this.getAttribute(ATTRIBUTES.ANIMAL_IDS) ? 'yes' : 'no',
            shelter_id: this.alphabetizeAndLowercaseCommaSeparatedList(this.getAttribute(ATTRIBUTES.ORGANIZATION_IDS)),
        };

        return eventParams;
    }

    fireGa4DeleteEvent() {
        /* eslint-disable camelcase, no-undefined */
        window.dataLayer.push(
            {
                event: 'save_search_delete',
                ga4: true,
                event_params: {
                    pet_species: this.getAttribute(ATTRIBUTES.TYPE_ID), // TODO: do we have a way to get this as human readable?
                },
            },
            { event_params: undefined }
        );
    }

    fireGa4SearchEvent() {
        /* eslint-disable camelcase, no-undefined */
        window.dataLayer.push(
            {
                event: 'search',
                ga4: true,
                event_params: this.getEventParamsForSearch(),
            },
            { event_params: undefined }
        );
    }
}

export default PFDCSavedSearchCardElement;
