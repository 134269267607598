import { saveSearchLogInFooter, nullFooter } from '../../../saved-search/SavedSearchModal/templates/signInFooter';

/*
 * Map for contextual modal copy. A change to the copy on the sign up view
 * when clicking from a favorite button is the only known exception at this time,
 * but future exceptions have been discussed.
 */

export const CONTEXT = {
    GENERIC: 'generic',
    FAVORITES: 'favorites',
    SAVE_SEARCH: 'save-search',
    AIF: 'aif',
    PET_DETAIL: 'pet-detail',
    DYA: 'dya',
    LOGIN: 'login',
    REGISTER: 'register',
    SEARCH_AND_MATCH: 'search-and-match',
    HAPPY_TAILS_SUBMISSION: 'happy-tail-submission',
    HOME_HERO: 'home-hero',
    PROFILE_LANDING_PAGE: 'profile-landing-page',
    BEST_MATCH: 'best-match',
};

export const ERROR_TYPES = {
    GLOBAL: 'global',
    VALIDATION: 'validation',
};

export const ERROR_TYPES_MESSAGE_DICTIONARY = {
    [ERROR_TYPES.GLOBAL]: 'unknown error from authentication',
    [ERROR_TYPES.VALIDATION]: 'there was a validation error',
};

/* IMPORTANT: these context strings are being used as analytic values.
   Do not change unless all analytic values will shift.

   Used for dimension79 [initiating site feature] aka initiatingFeature aka initiatingSite
*/
export const CONTEXT_NAMES_FOR_ANALYTICS = {
    [CONTEXT.GENERIC]: 'global nav',
    [CONTEXT.FAVORITES]: 'favorites',
    [CONTEXT.SAVE_SEARCH]: 'save search',
    [CONTEXT.AIF]: 'aif',
    [CONTEXT.PET_DETAIL]: 'pet detail',
    [CONTEXT.DYA]: 'dya',
    [CONTEXT.LOGIN]: 'log in page',
    [CONTEXT.REGISTER]: 'registration page',
    [CONTEXT.SEARCH_AND_MATCH]: 'search and match quiz page',
    [CONTEXT.HAPPY_TAILS_SUBMISSION]: 'happy tails submission page',
    [CONTEXT.HOME_HERO]: 'home hero',
    [CONTEXT.PROFILE_LANDING_PAGE]: 'landing page',
};

/**
 * Used for dimension80 [initiating UI Element]
 */
export const TRIGGER_ELEMENTS = {
    headerSignUp: 'global nav sign up link',
    headerSignUpMobile: 'global nav mobile sign up link',
    headerLogIn: 'global nav log in link',
    headerLogInMobile: 'global nav mobile log in link',
    aif: 'aif continue with email button',
    aifFB: 'aif continue with facebook button',
    aifGoogle: 'aif continue with google button',
    aifMobile: 'aif continue with email button mobile',
    aifFBMobile: 'aif continue with facebook button mobile',
    aifGoogleMobile: 'aif continue with google button mobile',
    dya: 'dya continue with email button',
    dyaFB: 'dya continue with facebook button',
    dyaGoogle: 'dya continue with google button',
    loginFacebook: 'log in page facebook link',
    loginGoogle: 'log in page google link',
    loginEmail: 'log in page email link',
    registrationFacebook: 'registration page facebook link',
    registrationGoogle: 'registration page google link',
    inlineFB: 'facebook button',
    inlineGoogle: 'google button',
    saveSearch: 'save search button',
    saveSearchCard: 'save search card',
    saveSearchMobile: 'save search mobile button',
    searchAndMatch: 'search and match quiz log in link',
    searchAndMatchEmail: 'search and match quiz email button',
    searchAndMatchFacebook: 'search and match quiz facebook button',
    searchAndMatchGoogle: 'search and match quiz google button',
    favoriteButtonPetCard: 'pet card favorite button',
    favoriteButtonPetDetail: 'pet detail favorite button',
    happyTailsSubmission: 'happy tails submission log in link',
    happyTailsSubmissionEmail: 'happy tails submission email button',
    happyTailsSubmissionFacebook: 'happy tails submission facebook button',
    happyTailsSubmissionGoogle: 'happy tails submission google button',
    homeHeroCreateAccount: 'create account link',
    introduceMyselfCta: 'introduce myself cta',
};

export const CONTEXT_NAMES = Object.values(CONTEXT);

export const SIGN_UP_COPY = {
    [CONTEXT.FAVORITES]: {
        heading: 'Join Petfinder to favorite pets',
        cta: 'Continue with ',
    },
    [CONTEXT.GENERIC]: {
        heading: 'Sign up',
        cta: 'Sign up with ',
    },
    [CONTEXT.SAVE_SEARCH]: {
        heading: 'Save your search',
        description: 'Save to your account:',
        cta: 'Continue with ',
        altEmailBtn: true, // takes the user to the 'log in' view, rather than the registration view
        footer: nullFooter,
    },
    [CONTEXT.PET_DETAIL]: {
        heading: 'Sign Up',
        cta: 'Sign up with ',
        description: 'Create an account so the shelter or rescue can contact you directly',
    },
    [CONTEXT.BEST_MATCH]: {
        heading: 'Log in to find your best match',
        description: 'Create a profile and get matched with your ideal pet',
        cta: 'Continue with ',
        altEmailBtn: true,
        footer: nullFooter,
    },
};

/**
 * @type {Object}
 * @property *.heading
 * @property *.subheading
 * @property *.footer { jsx: <>, onClick, <>}
 */
export const LOG_IN_COPY = {
    [CONTEXT.GENERIC]: {
        heading: 'Log in',
    },
    [CONTEXT.SAVE_SEARCH]: {
        footer: {
            jsx: saveSearchLogInFooter.jsx,
            onClick: 'handleRegisterClick',
        },
        heading: 'Save your search',
        subheading: 'Log in',
    },
};
