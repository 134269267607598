import APIService from '../services/APIService';

class SessionAPIService extends APIService {
    static url = '/user/session/';

    /**
     * Get the time to live from the session endpoint
     * @returns {Promise<Number>} ttl in ms
     */
    async getTTL() {
        const options = {
            urlAppend: 'timeout/',
        };

        const apiResponse = await this.queryAndProcess(options);
        return apiResponse.data.ttl * 1000;
    }
}

const sessionApiService = new SessionAPIService();
export default sessionApiService;
