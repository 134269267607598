import t from 'tcomb-form/lib';
import { FORM_FIELD_REQUIRED_ERROR_MESSAGE } from '../../constants/i18n';

export function defaultValidation(value, path, context) {
    if (!value) {
        return FORM_FIELD_REQUIRED_ERROR_MESSAGE;
    }
}

t.Boolean.getValidationErrorMessage = defaultValidation;
t.Date.getValidationErrorMessage = defaultValidation;
t.Number.getValidationErrorMessage = defaultValidation;
t.String.getValidationErrorMessage = defaultValidation;
