/* eslint-disable indent */
import { html, ifTruthy } from '../../../core/scripts/util/util';
import { getCurrentBreakpoint } from '../../../core/scripts/util/dom';
import parseDate from 'date-fns/parse';
import isValid from 'date-fns/is_valid';
import isAfter from 'date-fns/is_after';
import isEqual from 'date-fns/is_equal';

import AnimalModel from '../models/AnimalModel';

const DEFAULT_ANIMAL_CARD_VM = {
    attributeDisplay: {
        gender: true,
        age: true,
    },
};

const distanceMessage = animalDistance => {
    let message = '';

    if (typeof animalDistance === 'number') {
        message = animalDistance > 1 ? `${animalDistance} miles away` : '1 mile away';
    }

    return message;
};

const distanceDetail = animalDistance => {
    return typeof animalDistance === 'number'
        ? html`
              <li>
                  <div class="txt m-txt_colorPrimaryS2 m-txt_ellipsisOverflow">
                      ${distanceMessage(animalDistance)}
                  </div>
              </li>
          `
        : '';
};

export default (vm, recommendationVm) => {
    // combine defaults with the provided view model
    vm = Object.assign({}, DEFAULT_ANIMAL_CARD_VM, vm);

    const animalModel = AnimalModel.fromSearchResults(vm);
    vm.animalModel = animalModel;

    const animalIndex = vm.index;
    const animalDistance = vm.distance;

    const publishedAt = parseDate(vm.animalModel.publishedAt);
    const isNewIfPublishedAfter = vm.isNewIfPublishedAfter;

    // we need to pull out species too
    const species = vm.animalModel.species;

    const isNew = () => {
        return (
            isNewIfPublishedAfter !== null &&
            isValid(publishedAt) &&
            isValid(parseDate(isNewIfPublishedAfter)) &&
            (isEqual(publishedAt, parseDate(isNewIfPublishedAfter)) ||
                isAfter(publishedAt, parseDate(isNewIfPublishedAfter)))
        );
    };

    // status flag
    const shouldDisplayFlag =
        vm.animalModel.profileMatchScore > 0 ||
        isNew() ||
        vm.animalModel.adoptionStatus === 'adopted' ||
        vm.animalModel.adoptionStatus === 'found' ||
        vm.animalModel.isTransport;

    let flagExtensionClass = '';
    let flagIcon = '';
    let flagLabel = '';
    const petMatchType = vm.animalModel.petMatchType;

    if (petMatchType === 'best match') {
        flagExtensionClass = 'petCard-status-flag_strongMatch';
        flagIcon = 'icon-strongMatch';
        flagLabel = 'Strong Match';
    } else if (petMatchType === 'good match') {
        flagExtensionClass = 'petCard-status-flag_goodMatch';
        flagIcon = 'icon-goodMatch';
        flagLabel = 'Good Match';
    } else if (vm.animalModel.adoptionStatus === 'adopted') {
        flagExtensionClass = 'petCard-status-flag_alt';
        flagLabel = vm.animalModel.adoptionStatus;
    } else if (vm.animalModel.adoptionStatus === 'found') {
        flagLabel = vm.animalModel.adoptionStatus;
    } else if (vm.animalModel.isTransport) {
        flagExtensionClass = 'petCard-status-flag_dark';
        flagLabel = 'Out-of-town pet';
    } else if (isNew()) {
        flagLabel = 'new';
    }

    // Setup some breakpoint vars for non search cards
    let currentBreakpoint;
    if (!vm.isPetSearchResult) {
        currentBreakpoint = getCurrentBreakpoint();
    }

    const ariaLabel = () => {
        let label = `${vm.animalModel.name},`;

        if (isNew()) {
            label += ` Recently added ${vm.animalModel.species},`;
        } else {
            label += ` ${vm.animalModel.adoptionStatus} ${vm.animalModel.species},`;
        }

        label += ` ${vm.animalModel.typeSpecificAge} ${vm.animalModel.sex} ${vm.animalModel.data.animal.breeds_label}`;

        if (vm.isPetSearchResult) {
            label += `, ${distanceMessage(animalDistance)}`;
        } else {
            label += `, ${vm.animalModel.city}, ${vm.animalModel.state}`;
        }

        if (vm.animalModel.hasVideo) {
            label += ', has video';
        }

        if (shouldDisplayFlag) {
            label += `, ${flagLabel}`;
        }

        label += '.';

        return label;
    };

    const component = recommendationVm ? recommendationVm.component : '';

    return html`
        ${
            vm.isPetSearchResult && vm.paginationOffset !== undefined
                ? html`
                      <pfdc-pet-card
                          class="petCard petCard_searchResult ${ifTruthy(
                              vm.animalModel.hasVideo,
                              's-petCard_hasVideo'
                          )}"
                          set-last-pet-cookie
                          offset="${vm.paginationOffset + vm.index}"
                          pet-id="${vm.animalModel.id}"
                          pet-match-type="${petMatchType}"
                          pet-species="${species}"
                          id="${`petId_${vm.animalModel.id}`}"
                          component="${component}"
                          data-test="Search_Results_Pet_Card_List"
                      >
                  `
                : html`
                      <pfdc-pet-card
                          class="petCard"
                          pfdc-pet-card-recommendation
                          recommendation-heading="${recommendationVm.heading}"
                          recommendation-position="${recommendationVm.component}"
                          pet-id="${vm.animalModel.id}"
                          component="${component}"
                          pet-species="${species}"
                          data-test="Recommendation_Pet_Card_List"
                      >
                  `
        }
            <a pfdc-pet-card-link
                class="petCard-link"
                href="$${vm.animalModel.detailUrl}"
                aria-label="$${ariaLabel()}"
            >
                ${
                    vm.animalModel.photoUrlCropped
                        ? html`
                              <pfdc-lazy-load
                                  element="img"
                                  alt="$${ariaLabel()}"
                                  class="petCard-media"
                                  img-class="petCard-media-img"
                                  img-animation-class="m-petCard-media-img_reveal"
                                  src="$${vm.animalModel.photoUrlCropped}&width=300"
                                  sizes="300px"
                                  srcset="
                                $${vm.animalModel.photoUrlCropped}&width=300 300w,
                                $${vm.animalModel.photoUrlCropped}&width=450 450w,
                                $${vm.animalModel.photoUrlCropped}&width=600 600w"
                                  aria-hidden="true"
                              ></pfdc-lazy-load>
                          `
                        : html`
                              <div class="petCard-media"></div>
                          `
                }
                <div class="petCard-body">
                    <div class="petCard-body-details">
                        <div class="petCard-body-details-hdg">
                            <span aria-hidden="true" data-test="Pet_Card_Pet_Details_List">$${
                                vm.animalModel.name
                            }</span>
                            <span class="u-isVisuallyHidden">$${ariaLabel()}</span>
                            ${ifTruthy(
                                vm.animalModel.hasVideo,
                                html`
                                    <div class="petCard-body-details-hdg-videoIcon" title="Has video"></div>
                                `
                            )}
                        </div>
                        ${
                            vm.isPetSearchResult
                                ? html`
                                      <div class="txt m-txt_colorPrimaryS2">
                                          <ul>
                                              <li>
                                                  <ul class="hrArray hrArray_bulletDividedSingleLine m-hrArray_center">
                                                      ${ifTruthy(
                                                          vm.attributeDisplay.age,
                                                          html`
                                                              <li>$${vm.animalModel.typeSpecificAge}</li>
                                                          `
                                                      )}
                                                      <li>
                                                          <pf-truncate line-count="1" class="txt m-txt_colorPrimaryS2"
                                                              >$${vm.animalModel.data.animal.breeds_label}</pf-truncate
                                                          >
                                                      </li>
                                                  </ul>
                                              </li>
                                              ${distanceDetail(animalDistance)}
                                          </ul>
                                      </div>
                                  `
                                : ''
                        }
                    </div>
                </div>
                ${ifTruthy(
                    shouldDisplayFlag,
                    html`
                        <div class="petCard-status">
                            <div class="petCard-status-flag ${flagExtensionClass}">
                                ${ifTruthy(
                                    flagIcon,
                                    html`<span class="icon m-icon_colorAltS1"><svg focusable="false" role="img" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#$${flagIcon}"></use></span>`
                                )}$${flagLabel}
                            </div>
                        </div>
                    `
                )}
            </a>
            ${
                vm.animalModel.adoptionStatus === 'adoptable'
                    ? html`
                    <pfdc-favorite-btn
                        class="petCard-favoriteBtn"
                        pet-name="$${vm.animalModel.name}"
                        pet-id="${vm.animalModel.id}"
                        pet-index="${animalIndex}"
                        component="${component}"
                        ${
                            vm.isPetSearchResult
                                ? 'search-result'
                                : html`
                                      on-success-redirect="$${vm.animalModel.detailUrl}"
                                  `
                        }
                    /></pfdc-favorite-btn>`
                    : ''
            }
        </pfdc-pet-card>
    `;
};
