/**
 * # Event Constants
 */


/**
 * ## General
 */

// Fired when fonts are loaded
export const EV_APP_READY = 'ready';

// Fired after app is initialized
export const EV_APP_INIT = 'init';


// Fired when an input element is autofilled by the browser
export const EV_AUTOFILL = 'autofill';

// Fired when a window resize has completed
export const EV_RESIZE_COMPLETE = 'resize.complete';
export const EV_RESIZE_WIDTH_COMPLETE = 'resize.width.complete';
export const EV_RESIZE_HEIGHT_COMPLETE = 'resize.height.complete';

// Fired when a resize has caused a change in monitored breakpoints
export const EV_RESIZE_BREAKPOINT_CHANGE = 'resize.breakpoint-change';

/**
 * ## UI Activation
 */

// Fired when a component must be activated
export const EV_UI_ACTIVATE = 'ui.activate';

// Fired when a component must be deactivated
export const EV_UI_DEACTIVATE = 'ui.deactivate';

// Fired when all ui components must be deactivated
export const EV_UI_DEACTIVATE_ALL = 'ui.deactivate-all';

// Fired when a component must be toggled
export const EV_UI_TOGGLE_ACTIVATION = 'ui.toggle-activation';

// Fired when a component's data needs to be sorted
export const EV_UI_SORT = 'ui.sort';

// Fired when a component's date range changes
export const EV_UI_DATE_RANGE_CHANGE = 'ui.date-change';

// Fired when an animal's adoption status changes
export const EV_UI_ADOPT_STATUS_CHANGE = 'ui.status-change';
// Fired when the adoption status change modal is canceled/closed
export const EV_UI_STATUS_CHANGE_CANCEL = 'ui.status-change-cancel';

// Enables or disables listening for events in a component
export const EV_DISABLE = 'ui.disable';
export const EV_ENABLE = 'ui.enable';


/**
 * ## Data
 */

// Fired when shared component data updates
export const EV_DATA_UPDATE = 'data.update';

// Tracks asynchronous actions for showing loading indicators, disabling inputs, etc.
export const EV_ASYNC_START = 'async.start';
export const EV_ASYNC_END = 'async.end';

/**
 * ## Dynamic Activation
 */

// Event for activating multiple components at once
export const EV_DYNAMIC_ACTIVATE = 'dynamic.activate';
export const EV_DYNAMIC_DEACTIVATE = 'dynamic.deactivate';

/**
 * ## Component Specific events
 */

// pf-card-dropdown
export const EV_CARD_DROPDOWN_HIDE = 'card-dropdown.hide';

// pf-carousel
export const EV_CAROUSEL_SHOW = 'carousel.show';
export const EV_CAROUSEL_VIDEO_RENDER = 'carousel.video.render';
export const EV_CAROUSEL_VIDEO_DERENDER = 'carousel.video.derender';

// pf-ck-editor
export const EV_CKEDITOR_CHANGE = 'ckeditor.change';

// pf-checkbox - determines whether to check or not.
export const EV_CHECKBOX_CHANGE = 'checkbox.change';

// pf-dynamic-input, pf-dynamic-input-state - checking country input change and loading states.
export const EV_DYNAMIC_INPUT_CHANGE = 'dynamic-input.change';
export const EV_DYNAMIC_INPUT_LOAD = 'dynamic-input.load';
export const EV_DYNAMIC_INPUT_STATES_READY = 'dynamic-input.states.ready';
export const EV_DYNAMIC_INPUT_STATES_CHANGE = 'dynamic-input.states.change';

// pf-filters
export const EV_FILTERS_TOGGLE_SHOW = 'filters.show';
export const EV_FILTERS_TOGGLE_HIDE = 'filters.hide';

// pf-form
export const EV_FORM_SUBMIT = 'form.submit';
export const EV_FORM_ENABLE_SUBMIT = 'form.submit.enable';
export const EV_FORM_DISABLE_SUBMIT = 'form.submit.disable';

// pf-email-statistics
export const EV_EMAIL_STATS_TOTAL = 'email-stats.total-stats';
export const EV_EMAIL_STATS_ANIMAL = 'email-stats.animal-stats';

// pf-events
export const EV_EVENTS_EXISTING_CONTACT_CHANGE = 'events.existing-contact.change';
export const EV_EVENTS_EXISTING_LOCATION_CHANGE = 'events.existing-location.change';
export const EV_EVENTS_EXISTING_LOCATION_LOAD = 'events.existing-location.load';

// pf-pet-search-link-generator
export const EV_PET_SEARCH_LINK_GEN_STATUS_CHANGE = 'pet-search-link-generator.status-change';
export const EV_PET_SEARCH_LINK_GEN_TYPE_CHANGE = 'pet-search-link-generator.type-change';

// pf-org
export const EV_ORG_URL_AVAILABILITY_VALUE_RESET = 'org.url-availability.value-reset';
export const EV_ORG_URL_ALIAS_CHANGE_CONFIRM = 'org.url-alias-change.confirm';
export const EV_ORG_URL_ALIAS_RESET_CONFIRM = 'org.url-alias-reset.confirm';
export const EV_ORG_URL_ALIAS_CHANGE_RENDER_URL_CHANGE_MODAL =
    'org.url-alias-change.render-url-change';

// pf-toaster-dynamic
export const EV_TOASTER_DYNAMIC_NOTIFICATION = 'toaster-dynamic.notification';

// pf-render
export const EV_RENDER_TEMPLATE_COMPLETE = 'render-template.complete';

// Event for loading templates for a specific species
export const EV_SPECIES_LOAD_TEMPLATES_FOR_SPECIES = 'speciesTemplates.load';

// Event for sorting species templates
export const EV_SPECIES_SORT_SPECIES_TEMPLATES = 'speciesTemplates.sort';

// Event for animal selection change
export const EV_UI_ANIMAL_SELECTION_CHANGE = 'animalSelection.change';
export const EV_UI_BULK_ANIMAL_SELECTION_CHANGE = 'animalSelection.bulkChange';

// Media upload events
export const EV_MEDIA_CONFIRM_DELETE = 'media.confirm-delete';
export const EV_MEDIA_CONFIRM_EDIT = 'media.confirm-edit';
export const EV_MEDIA_CONFIRM_EMBED = 'media.confirm-embed';

export const EV_MEDIA_EDIT_INITIALIZE = 'media-edit.initialize';
export const EV_MEDIA_EMBED_INITIALIZE = 'media-embed.initialize';
export const EV_MEDIA_VIEW_INITIALIZE = 'media-view.initialize';

export const EV_MEDIA_CHANGE = 'media.change';

// Pet List events
export const EV_PET_LIST_SEARCH_CHANGE = 'pet.list.search-change';
export const EV_PET_LIST_MOBILE_STATUS_SELECTED = 'pet.list.mobile.status-selected';

// Loader events
export const EV_UI_LOADER_HIDDEN = 'loader.hidden';

// Tray events
export const EV_TRAY_CONTENT_REQUESTED = 'tray.contentRequested';
export const EV_TRAY_CONTENT_LOADED = 'tray.contentLoaded';
export const EV_TRAY_CONTENT_VISIBLE = 'tray.contentVisible';
export const EV_TRAY_STICKY_LAYOUT = 'tray.stickyLayout';
export const EV_TRAY_ACTIVATE = 'tray.activate';
export const EV_TRAY_DEACTIVATE = 'tray.deactivate';

// Analytics tracking (Ensighten)
export const EV_ANALYTICS = 'analytics';

// Pet Status Change Events
export const EV_SELECTED_PETS_CHANGE = 'status.selectedPetsChange';
export const EV_STATUS_ADOPT_DATE_CHANGE = 'status.adoptionDateUpdate';
export const EV_STATUS_NOTES_CHANGE = 'status.notesUpdate';

// pf-analytics
export const EV_ANALYTICS_DATA_LOAD_START = 'analytics.data.load.start';
export const EV_ANALYTICS_DATA_LOAD_END = 'analytics.data.load.end';
export const EV_ANALYTICS_STATS_DATA_LOAD_START = 'analytics.stats.data.load.start';
export const EV_ANALYTICS_STATS_DATA_LOAD_END = 'analytics.stats.data.load.end';

// Autosave Events
export const EV_AUTOSAVE = 'autosave';
export const EV_AUTOSAVE_MEDIA = 'autosave.media';
export const EV_AUTOSAVE_START = 'autosave.start';
export const EV_AUTOSAVE_END = 'autosave.end';


// Recaptcha Events
export const EV_RECAPTCHA_INITIALIZED = 'recaptcha.initialized';
export const EV_RECAPTCHA_EXECUTE = 'recaptcha.execute';
export const EV_RECAPTCHA_EXECUTED = 'recaptcha.executed';
export const EV_RECAPTCHA_VALIDATION = 'recaptcha.validation';

