import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import {
    scheduleMicrotask,
    getRandomItemsFromArray,
} from '../../../../core/scripts/util/util';
import renderContentToutsTwoUp from '../../templates/contentToutsTwoUp.html';

import { CLASS_NOT_OBSERVED } from '../../constants/classes';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-content-touts';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    // INSERTION_POINT:            `[${ELEMENT_TAG_NAME}-insertion]`,
};

/**
 * Error messages for this component
 * @type {Object}
 */
const ERROR_MSG = {
    NO_TEMPLATE:
        'PFDCContentToutsElement: No "template" render function string provided.',
};

const INIT_PER_PAGE = 50;

/**
 * This component gets content from wordpress api and renders it into a template. You can also
 * use the below attributes to customize the api params sent to wordpress.
 *
 * REQUIRED BASE MARKUP:
 * ---
 * <pfdc-content-touts
 *     feature-type="homepage-feature"
 *     animal-type="dogs"
 *     per-page="2"
 *     template="contentToutsTwoUp">
 *     ... placeholder content that shows initially
 * </pfdc-content-touts>
 * ---
 *
 * @extends PFElement
 */
export class PFDCContentToutsElement extends PFElement {
    /**
     * Get the feature type slug for filtering the request
     * @return {string}
     */
    get featureType() {
        return this.getAttribute('feature-type') || '';
    }

    /**
     * Get the animal type slug for filtering the request
     * @return {string}
     */
    get animalType() {
        return this.getAttribute('animal-type') || '';
    }

    /**
     * Get the number of touts that should be requested
     * @return {number}
     */
    get perPage() {
        return Number(this.getAttribute('per-page')) || INIT_PER_PAGE;
    }

    /**
     * Get the number of touts that should be shown
     * @return {number}
     */
    get numToutsToShow() {
        return Number(this.getAttribute('touts')) || 1;
    }

    /**
     * Load content touts randomly
     * @returns {string}
     */
    get loadRandom() {
        return this.getAttribute('load-random') || '';
    }

    /**
     * Get the template function as a string
     * @return {string}
     */
    get template() {
        if (this.hasAttribute('template')) {
            return this.getAttribute('template');
        } else {
            throw new Error(ERROR_MSG.NO_TEMPLATE);
        }
    }

    /**
     * Initialize this component
     */
    onInit() {
        // Setup intersection observer for "lazy loading"
        this.observer = new IntersectionObserver(this.onObserved.bind(this));
        this.observer.observe(this);
    }

    /**
     * Get the params
     * @return {Object} [description]
     */
    getParams() {
        let obj = {};
        if (this.featureType) {
            obj = Object.assign(obj, {
                'filter[feature-type]': this.featureType,
            });
        }
        if (this.animalType) {
            obj = Object.assign(obj, {
                'filter[pet-type]': this.animalType,
            });
        }

        if (this.loadRandom) {
            obj = Object.assign(obj, {
                'filter[orderby]': 'rand',
            });
        }

        // Num of results to get
        obj = Object.assign(obj, {
            per_page: INIT_PER_PAGE, // eslint-disable-line camelcase
        });

        return obj;
    }

    /**
     * Handler for intersection observations on this component, triggers the data load
     * when this component is in view
     * @param {IntersectionObserverEntry} entries
     * @param {IntersectionObserver} observer
     */
    onObserved(entries, observer) {
        if (this.isLoading) {
            return;
        }
        // Check if entry has isIntersecting property, because on S6 at time of this comment
        // that is not a thing...
        if ('isIntersecting' in entries[0]) {
            if (!entries[0].isIntersecting) {
                return;
            }
        }

        this.classList.remove(CLASS_NOT_OBSERVED);
        this.getContent();
    }

    /**
     * Get content with the configurable attributes as params
     */
    async getContent() {
        const toutParams = this.getParams();

        try {
            this.isLoading = true;
            const touts = await this.wordpressRepository.getContentTouts(
                toutParams
            );

            const randomTouts = getRandomItemsFromArray(
                touts,
                this.numToutsToShow
            );
            this.handleSuccess(randomTouts);
        } catch (error) {
            this.handleError(error);
        } finally {
            this.isLoading = false;
            if (this.observer) {
                this.observer.disconnect();
            }
        }
    }

    /**
     * Handles a successful api response, render the content touts with the data from the request
     * @param {Array} data
     */
    handleSuccess(data) {
        const vm = {
            touts: [...data],
        };

        // Force repaint in safari
        const display = this.style.display;
        this.style.display = 'none';
        this.offsetHeight;

        // Render template with content
        this.innerHTML = renderContentToutsTwoUp(vm);

        setTimeout(() => {
            this.style.display = display;
        }, 0);
    }

    /**
     * Handle an error api response
     * @param {Object} error from the endpoint
     */
    handleError(error) {
        this.remove();
    }
}

export default PFDCContentToutsElement;
