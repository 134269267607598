import platform from 'platform';

/**
 * Class applied to page
 * @type {string}
 */
const SCROLL_LOCK_CLASSNAME = 'scrollLock';

/**
 * Class applied to scrollbar messuring element
 * @type {string}
 */
const SCROLL_DIV_CLASSNAME = 'u-scrollbarMeasure';

/**
 * Refrence to selector for the global site wrapping element
 * @type {string}
 */
const GLOBAL_SITE_ELEMENT_CLASSNAME = 'site';

/**
 * Handles locking and unlocking the scrolling of the page, mostly used in modals and selects.
 */
export class ScrollLock {
    constructor() {
        /**
         * Reference to all the elements we need to apply x offset too.
         *
         * @property offsetElements
         * @type {Array} merge consts above into one array
         */
        this.offsetElements = [
            document.querySelector('#Header'),
            document.querySelector('#Main'),
            document.querySelector('#Footer'),
        ];

        /**
         * Reference to site container element.
         *
         * @property siteElement
         * @type {Element}
         */
        this.siteElement = document.querySelector(
            `.${GLOBAL_SITE_ELEMENT_CLASSNAME}`
        );

        /**
         * Reference to current scroll bar width.
         *
         * @property scrollBarWidth
         * @type {Number}
         */
        this.scrollBarWidth = this.getScrollbarWidth();

        /**
         * Reference to original scroll top position.
         *
         * @property originalScrollTop
         * @type {Number}
         */
        this.originalScrollTop = 0;
    }

    /**
     * ## Methods
     */

    /**
     * Adds a test element to test the width of the current browser scrollbar.
     *
     * @method getScrollbarWidth
     * @private
     * @return {Number} scrollbar width so we can offset x
     */
    getScrollbarWidth() {
        // .u-scrollbarMeasure {
        //     width: 100px;
        //     height: 100px;
        //     overflow: scroll;
        //     position: absolute;
        //     top: -9999px;
        // }

        const scrollDiv = document.createElement('div');
        document.body.appendChild(scrollDiv);
        scrollDiv.style.width = '100px';
        scrollDiv.style.height = '100px';
        scrollDiv.style.overflow = 'scroll';
        scrollDiv.style.position = 'absolute';
        scrollDiv.style.top = '-9999px';

        const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);

        return scrollbarWidth;
    }

    /**
     * Adds scrollLock class to element to stop page scrolling.
     *
     * @method applyYAxisOverflow
     * @param {Element} element usually the html element
     * @private
     */
    applyYAxisOverflow(element) {
        element.classList.add(SCROLL_LOCK_CLASSNAME);
    }

    /**
     * Removes scrollLock class to element to stop page scrolling.
     *
     * @method removeYAxisOverflow
     * @param {Element} element usually the html element
     * @private
     */
    removeYAxisOverflow(element) {
        element.classList.remove(SCROLL_LOCK_CLASSNAME);
    }

    /**
     * Applies padding to various elements to counter the jump of scroll bars disapearing
     *
     * @method applyXAxisOffset
     * @param {Element} element usually the html element
     * @private
     */
    applyXAxisOffset(element) {
        if (element === document.documentElement) {
            this.offsetElements.forEach(el => {
                if (!el) {
                    return;
                }
                el.style.paddingRight = `${this.scrollBarWidth}px`;
            });
        } else {
            element.style.paddingRight = `${this.scrollBarWidth}px`;
        }
    }

    /**
     * Removes padding to various elements to counter the jump of scroll bars disapearing
     *
     * @method removeXAxisOffset
     * @param {Element} element usually the html element
     * @private
     */
    removeXAxisOffset(element) {
        if (element === document.documentElement) {
            this.offsetElements.forEach(el => {
                if (!el) {
                    return;
                }
                el.style.paddingRight = null;
            });
        } else {
            element.style.paddingRight = null;
        }
    }

    /**
     * Locks the page scroll.
     *
     * @method lockScroll
     * @param {Element} element usually the html element
     * @public
     */
    lockScroll(element = document.documentElement) {
        // !!!
        // iOS 11 input caret render bug fix
        // TODO: remove this when the bug is fixed
        if (platform.os.toString().includes('iOS 11')) {
            document.body.style.position = 'fixed';
        }
        // !!!

        this.originalScrollTop = window.pageYOffset;
        this.applyYAxisOverflow(element);
        this.applyXAxisOffset(element);
    }

    /**
     * Unlocks the page scroll.
     *
     * @method unlockScroll
     * @param {Element} element usually the html element
     * @public
     */
    unlockScroll(element = document.documentElement) {
        // !!!
        // iOS 11 input caret render bug fix
        // TODO: remove this when the bug is fixed
        if (platform.os.toString().includes('iOS 11')) {
            document.body.style.position = 'static';
        }
        // !!!

        this.removeYAxisOverflow(element);
        this.removeXAxisOffset(element);
        window.scrollTo(0, this.originalScrollTop);
    }
}

export const scrollLockSingleton = new ScrollLock();
