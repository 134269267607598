import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import templates from '../../../../dotcom/scripts/util/TemplateRegistry';
import Config from '../../../../core/scripts/lib/Config';
import _get from 'lodash/get';

import { CLASS_NOT_OBSERVED } from '../../constants/classes';

import favoriteStateController from '../../state-controllers/favoritesStateController';
import recoShelfStateController from '../../state-controllers/recoShelfStateController';
import AnimalModel from '../../models/AnimalModel';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-recommendations';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    // INPUT: `[${ELEMENT_TAG_NAME}-input]`,
};

/**
 * Error messages for this component
 * @type {Object}
 */
const ERROR_MSG = {
    NO_COMPONENT_ATTR: 'PFDCRecommendationsElement: No "component" id string provided.',
    NO_TEMPLATE: 'PFDCRecommendationsElement: No "template" render function string provided.',
    NO_PLACEHOLDER_TEMPLATE: `PFDCRecommendationsElement: No "placeholder-template" render function
        string provided`,
};

/**
 * Retrieves and displays recommended pets based off provided attr below. A "component" name is
 * required as the api will need this to give you the right recommendations.
 *
 * REQUIRED BASE MARKUP:
 * ---
 * <pfdc-recommendations
 *     class="recommendations m-recommendations_singleRow" // remove single row class for multi row
 *     component="home_one" // required!!
 *     org-display-id=""
 *     show-placeholder // show placeholder will render placeholder cards
 *     lazy-load> // setup intersection observer to load content
 * </pfdc-recommendations>
 * ---
 *
 * @extends PFElement
 */
export class PFDCRecommendationsElement extends PFElement {
    /**
     * Get the component string from the element attr
     * @return {string}
     */
    get component() {
        if (this.hasAttribute('component')) {
            return this.getAttribute('component');
        } else {
            throw new Error(ERROR_MSG.NO_COMPONENT_ATTR);
        }
    }

    /**
     * Get the animal type string from the element attr, this can be an id or slug
     * @return {string}
     */
    get animalType() {
        if (this.hasAttribute('animal-type')) {
            return this.getAttribute('animal-type');
        } else {
            return '';
        }
    }

    /**
     * Get the animal type string from the element attr, this can be an id or slug
     * @return {string}
     */
    get excludedAnimalIds() {
        if (this.hasAttribute('excluded-animal-ids')) {
            const ids = this.getAttribute('excluded-animal-ids').split(',');
            return ids;
        } else {
            return '';
        }
    }

    /**
     * Get the org display id string from the element attr
     * @return {string}
     */
    get orgDisplayId() {
        if (this.hasAttribute('org-display-id')) {
            return this.getAttribute('org-display-id');
        } else {
            return '';
        }
    }

    /**
     * Get the org name id string from the element attr
     * @return {string}
     */
    get orgName() {
        if (this.hasAttribute('org-name')) {
            return this.getAttribute('org-name');
        } else {
            return '';
        }
    }

    /**
     * Should this component show the placeholder template, only components that will always get
     * results should use this
     * @return {boolean}
     */
    get showPlaceholder() {
        return this.hasAttribute('show-placeholder');
    }

    /**
     * If lazy load is true the component will load its content on scroll
     * into the viewport via an intersection observer
     * @return {boolean}
     */
    get isLazyLoad() {
        return this.hasAttribute('lazy-load');
    }

    /**
     * Get the template function as a string
     * @return {string}
     */
    get template() {
        if (this.hasAttribute('template')) {
            return this.getAttribute('template');
        } else {
            throw new Error(ERROR_MSG.NO_TEMPLATE);
        }
    }

    /**
     * Get the placeholder template function as a string
     * @return {string}
     */
    get placeholderTemplate() {
        if (this.hasAttribute('placeholder-template')) {
            return this.getAttribute('placeholder-template');
        } else {
            throw new Error(ERROR_MSG.NO_PLACEHOLDER_TEMPLATE);
        }
    }

    /**
     * Initialize this component
     */
    onInit() {
        if (this.showPlaceholder) {
            // Render placeholder template
            const placeholderCards = new Array(10).fill('card');
            this.innerHTML = templates[`${this.placeholderTemplate}`](placeholderCards);
        }

        if (this.isLazyLoad) {
            // Setup intersection observer for "lazy loading"
            const options = { threshold: 0.1 };
            this.observer = new IntersectionObserver(this.onObserved.bind(this), options);
            this.observer.observe(this);
        } else {
            this.getPetRecommendations();
        }
    }

    /**
     * Handler for intersection observations on this component, triggers the data load
     * when this component is in view
     * @param {IntersectionObserverEntry} entries
     * @param {IntersectionObserver} observer
     */
    onObserved(entries, observer) {
        if (this.isLoading) {
            return;
        }
        // Check if entry has isIntersecting property, because on S6 at time of this comment
        // that is not a thing...
        if ('isIntersecting' in entries[0]) {
            if (!entries[0].isIntersecting) {
                return;
            }
        }

        this.classList.remove(CLASS_NOT_OBSERVED);
        this.getPetRecommendations();
    }

    /**
     * Get pet recommendations
     */
    async getPetRecommendations() {
        let data;
        let userToken;
        try {
            this.isLoading = true;
            userToken = await Config.userToken;
            data = await this.searchRepository.getPetRecommendations(
                this.component,
                this.animalType,
                this.orgDisplayId,
                this.excludedAnimalIds,
                userToken
            );
            this.handleSuccess(data);
        } catch (error) {
            this.handleError(error);
        } finally {
            this.isLoading = false;
            if (this.observer) {
                this.observer.disconnect();
            }
        }
    }

    /**
     * Handles a successful api response, render the recommendations
     * @param {Object} data from the endpoint
     */
    handleSuccess(data) {
        this.vm = data.recommendation_modules[this.component];
        // pass in props for use in ensighten in pet cards
        this.vm.component = this.component;
        this.vm.orgDisplayId = this.orgDisplayId;
        this.vm.orgName = this.orgName;

        // Handle successful response but no matchedModule
        if (!this.vm.matchedModule) {
            this.removeScreenReaderHeading();
            this.remove();
            return;
        }

        this.innerHTML = templates[`${this.template}`](this.vm);

        // Trigger a first update for the card content
        window.requestAnimationFrame(() => {
            this.updateContentCard();
        });

        this.updateFavorites(data.metadata.favorite_pets || []);

        recoShelfStateController.clearShelf(this.component);
        for (const animal of this.vm.result.animals) {
            const animalModel = AnimalModel.fromSearchResults(animal);
            recoShelfStateController.addAnimal(this.component, animal.animal.id, animalModel);
        }
    }

    /**
     * Figures out the number of cards shown and retrieves the proper content string
     * @returns {string}
     */
    getContentByNumberShownStr() {
        const contentByNumberShown = _get(this.vm, 'contentByNumberShown', false);

        if (!contentByNumberShown) {
            return;
        }

        const resultsEl = this.querySelector('[pfdc-recommendations-results]');
        const numCurrentlyShownCards = Array.from(resultsEl.children).filter(child => child.offsetHeight).length - 1;

        return contentByNumberShown[numCurrentlyShownCards];
    }

    /**
     * Change the content card text
     */
    updateContentCard() {
        const contentEl = this.querySelector('[pfdc-recommendations-content-card-txt]');
        if (!contentEl) {
            return;
        }
        contentEl.textContent = this.getContentByNumberShownStr();
    }

    onUpdated(ev) {
        const { detail } = ev;

        if (detail.type === 'resize.breakpoint-change') {
            // Trigger resize update
            this.updateContentCard();
        }
    }

    /**
     * Handle an error api response
     * @param {Object} error from the endpoint
     */
    handleError(error) {
        this.removeScreenReaderHeading();
        this.remove();
        throw new Error(error);
    }

    /**
     * Remove the screen reader heading before the reco module if it exists
     */
    removeScreenReaderHeading() {
        const screenReaderHeading = this.previousElementSibling;
        if (screenReaderHeading && screenReaderHeading.hasAttribute('pf-recommendations-screen-reader-header')) {
            screenReaderHeading.remove();
        }
    }

    /**
     * Updates favorites state controller with data pulled from reco module endpoint
     * @param {Array} animalIdArray
     */
    updateFavorites(animalIdArray) {
        favoriteStateController.addFavorites(animalIdArray);
    }
}

export default PFDCRecommendationsElement;
