import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _noop from 'lodash/noop';

class LoaderButton extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        extensionClassNames: PropTypes.objectOf(PropTypes.bool),
        label: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        type: PropTypes.oneOf(['button', 'submit']).isRequired,
        ['data-test']: PropTypes.string,
        onClick: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        extensionClassNames: {},
        ['data-test']: null,
        onClick: _noop,
    };

    get rootClassNames() {
        return buildClassNames({
            ['btn']: true,
            ['btn_loader']: true,
            ['s-btn_active']: this.props.active,
            ...this.props.extensionClassNames,
        });
    }

    render() {
        return (
            <button
                className={this.rootClassNames}
                type={this.props.type}
                data-test={this.props['data-test']}
                onClick={this.props.onClick}
            >
                {this.props.label}
                <svg
                    className="btn_loader-icon"
                    role="presentation"
                    focusable="false"
                    viewBox="25 25 50 50"
                >
                    <circle
                        className="path"
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        strokeWidth="5"
                        stroke="#fff"
                        strokeDasharray="39.25, 117.75"
                    />
                </svg>
            </button>
        );
    }
}

export default LoaderButton;
