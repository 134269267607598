import BaseAnalyticPropertiesModel from '../BaseAnalyticPropertiesModel';

export default class AnimalBreedsPropertiesModel extends BaseAnalyticPropertiesModel {
    get defaultData() {
        return {
            friendliness: '',
            groomingRequirements: '',
            vocality: '',
        };
    }

    /**
     * Checks filter value type and returns proper values for data points
     * @method filterToValue
     * @param {Mixed} filter
     * @param {string} nullText
     *
     * @returns {Mixed}
     * @private
     */
    _filterToValue(filter, nullText) {
        if (typeof filter === 'string') {
            return filter !== '' ? filter.toLowerCase() : nullText;
        }

        // Get what keys are set to true
        const valuesArray = Object.keys(filter).filter(key => {
            return filter[key];
        });

        if (Array.isArray(valuesArray)) {
            if (valuesArray[0] === 'undefined') {
                return nullText;
            }

            return valuesArray.length
                ? valuesArray.join(',').toLowerCase()
                : nullText;
        }

        return nullText;
    }

    /**
     * Build animal breeds property data for friendliness
     * @method friendliness
     *
     * @returns {Object}
     */
    friendliness() {
        return this._buildProperty(
            'breedSearchFriendliness',
            61,
            this._filterToValue(this._data.friendliness, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for grooming requirements
     * @method groomingRequirements
     *
     * @returns {Object}
     */
    groomingRequirements() {
        return this._buildProperty(
            'breedSearchGrooming',
            63,
            this._filterToValue(this._data.groomingRequirements, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for vocality
     * @method vocality
     *
     * @returns {Object}
     */
    vocality() {
        return this._buildProperty(
            'breedSearchVocality',
            64,
            this._filterToValue(this._data.vocality, this.nullText)
        );
    }
}
