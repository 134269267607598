/**
 * Exports constants related to internationalization - translated strings.
 */

const I18nData = require('../data/i18nJSON.json');

/**
 * Global
 */
export const FORM_FIELD_REQUIRED_ERROR_MESSAGE =
    I18nData['user.form.field_required_error_message'];

/**
 * Staff Tools
 */

// Page
export const STAFF_SIGNOUT = I18nData['staff.page.signout'];
export const STAFF_LOGO_ARIA_LABEL = I18nData['staff.page.logo_aria_label'];
export const STAFF_NAV_ARIA_LABEL = I18nData['staff.page.nav_aria_label'];
export const STAFF_NAV_SELECTED_ARIA_LABEL =
    I18nData['staff.page.nav_currently_selected_aria_label'];

// Staff Tools Dashboard Page

// Api Keyholder Index Page
export const STAFF_API_INDEX_SEARCH_INPUT_PLACEHOLDER =
    I18nData['staff.page.api_keyholder_index.search_input.placeholder'];
export const STAFF_API_INDEX_SEARCH_INPUT_ARIA_LABEL =
    I18nData['staff.page.api_keyholder_index.search_input.aria_label'];
export const STAFF_API_INDEX_NUM_RESULTS_ARIA_LABEL =
    I18nData['staff.page.api_keyholder_index.num_results.aria_label'];
export const STAFF_API_INDEX_SORY_BY_ARIA_LABEL =
    I18nData['staff.page.api_keyholder_index.sort_by.aria_label'];
export const STAFF_API_INDEX_PAGINATION_PAGE =
    I18nData['staff.page.api_keyholder_index.pagination.page'];
export const STAFF_API_INDEX_PAGINATION_OF =
    I18nData['staff.page.api_keyholder_index.pagination.of'];
export const STAFF_API_INDEX_RESULTS_NO_RESULTS_MATCHING =
    I18nData['staff.page.api_keyholder_index.results.no_results_matching'];
export const STAFF_API_INDEX_RESULTS_NO_RESULTS =
    I18nData['staff.page.api_keyholder_index.results.no_results'];

export const STAFF_API_INDEX_RESULTS_VIEW_API_REQUEST_BUTTON_LABEL =
    I18nData[
        'staff.page.api_keyholder_index.results.view_api_request_button_label'
    ];
export const STAFF_API_INDEX_RESULTS_EXPAND_LABEL =
    I18nData['staff.page.api_keyholder_index.results.expand_label'];
export const STAFF_API_INDEX_RESULTS_COLLAPSE_LABEL =
    I18nData['staff.page.api_keyholder_index.results.collapse_label'];
export const STAFF_API_INDEX_RESULTS_LOCKED_LABEL =
    I18nData['staff.page.api_keyholder_index.results.locked_label'];
export const STAFF_API_INDEX_FIELD_LABEL_PRIMARY_PHONE =
    I18nData[
        'staff.page.api_keyholder_index.results.field_label.primary_phone'
    ];
export const STAFF_API_INDEX_FIELD_LABEL_APPLICATION_NAME =
    I18nData[
        'staff.page.api_keyholder_index.results.field_label.application_name'
    ];
export const STAFF_API_INDEX_FIELD_LABEL_APPLICATION_URL =
    I18nData[
        'staff.page.api_keyholder_index.results.field_label.application_url'
    ];

// Api Keyholder Detail Page
// Page level content
export const STAFF_DETAIL_TOAST_SUCCESS =
    I18nData['staff.page.api_keyholder_detail.toast_success'];
export const STAFF_DETAIL_HEADING_BACK_CTA =
    I18nData['staff.page.api_keyholder_detail.heading.back_cta'];
export const STAFF_DETAIL_EDIT_LABEL =
    I18nData['staff.page.api_keyholder_detail.editable_card.edit_label'];
export const STAFF_DETAIL_ADD_LABEL =
    I18nData['staff.page.api_keyholder_detail.editable_card.add_label'];
export const STAFF_DETAIL_CONSUMER_CONTACT_WARNING =
    I18nData['staff.page.api_keyholder_detail.consumer_contact.warning'];
// Status panel content
export const STAFF_DETAIL_STATUS_PANEL_HEADING =
    I18nData['staff.page.api_keyholder_detail.status_panel.heading'];
export const STAFF_DETAIL_STATUS_PANEL_API_LEVEL_LABEL =
    I18nData['staff.page.api_keyholder_detail.status_panel.api_level_label'];
export const STAFF_DETAIL_STATUS_PANEL_API_LEVEL_CTA =
    I18nData['staff.page.api_keyholder_detail.status_panel.api_level_cta'];
export const STAFF_DETAIL_STATUS_PANEL_EMAIL_ALERT =
    I18nData['staff.page.api_keyholder_detail.status_panel.email_alert'];
export const STAFF_DETAIL_STATUS_PANEL_API_ACCESS_LABEL =
    I18nData['staff.page.api_keyholder_detail.status_panel.api_access.label'];
export const STAFF_DETAIL_STATUS_PANEL_API_ACCESS_DENIED_LABEL =
    I18nData[
        'staff.page.api_keyholder_detail.status_panel.api_access.denied_label'
    ];
export const STAFF_DETAIL_STATUS_PANEL_API_ACCESS_GRANTED_LABEL =
    I18nData[
        'staff.page.api_keyholder_detail.status_panel.api_access.granted_label'
    ];
export const STAFF_DETAIL_STATUS_PANEL_API_ACCESS_CTA_LOCK_LABEL =
    I18nData[
        'staff.page.api_keyholder_detail.status_panel.api_access.cta_lock_label'
    ];
export const STAFF_DETAIL_STATUS_PANEL_API_ACCESS_CTA_UNLOCK_LABEL =
    I18nData[
        'staff.page.api_keyholder_detail.status_panel.api_access.cta_unlock_label'
    ];
export const STAFF_DETAIL_STATUS_PANEL_API_CONSUMER_LABEL =
    I18nData['staff.page.api_keyholder_detail.status_panel.api_consumer.label'];
export const STAFF_DETAIL_STATUS_PANEL_API_CONSUMER_ACTIVE_LABEL =
    I18nData[
        'staff.page.api_keyholder_detail.status_panel.api_consumer.active_label'
    ];
export const STAFF_DETAIL_STATUS_PANEL_API_CONSUMER_INACTIVE_LABEL =
    I18nData[
        'staff.page.api_keyholder_detail.status_panel.api_consumer.inactive_label'
    ];
export const STAFF_DETAIL_STATUS_PANEL_API_CONSUMER_CTA_LABEL =
    I18nData[
        'staff.page.api_keyholder_detail.status_panel.api_consumer.cta_label'
    ];
export const STAFF_DETAIL_LOCK_MODAL_LOCK_HEADING =
    I18nData['staff.page.api_keyholder_detail.lock_modal.lock_heading'];
export const STAFF_DETAIL_LOCK_MODAL_UNLOCK_HEADING =
    I18nData['staff.page.api_keyholder_detail.lock_modal.unlock_heading'];
export const STAFF_DETAIL_LOCK_MODAL_LOCK_DESCRIPTION =
    I18nData['staff.page.api_keyholder_detail.lock_modal.lock_description'];
export const STAFF_DETAIL_LOCK_MODAL_UNLOCK_DESCRIPTION =
    I18nData['staff.page.api_keyholder_detail.lock_modal.unlock_description'];
export const STAFF_DETAIL_LOCK_MODAL_UNLOCK_CTA_LABEL =
    I18nData['staff.page.api_keyholder_detail.lock_modal.unlock_cta_label'];
export const STAFF_DETAIL_LOCK_MODAL_LOCK_CTA_LABEL =
    I18nData['staff.page.api_keyholder_detail.lock_modal.lock_cta_label'];
export const STAFF_DETAIL_RESET_SECRET_MODAL_HEADING =
    I18nData['staff.page.api_keyholder_detail.reset_secret_modal.heading'];
export const STAFF_DETAIL_RESET_SECRET_MODAL_CANCEL_LABEL =
    I18nData['staff.page.api_keyholder_detail.reset_secret_modal.cancel_label'];
export const STAFF_DETAIL_RESET_SECRET_MODAL_CONFIRM_LABEL =
    I18nData[
        'staff.page.api_keyholder_detail.reset_secret_modal.confirm_label'
    ];
export const STAFF_DETAIL_API_ACCESS_MODAL_HEADING =
    I18nData['staff.page.api_keyholder_detail.api_access_modal.heading'];
export const STAFF_DETAIL_API_ACCESS_MODAL_SUBMIT_LABEL =
    I18nData['staff.page.api_keyholder_detail.api_access_modal.submit_label'];
export const STAFF_DETAIL_API_ACCESS_CARD_USAGE_LIMIT_LABEL =
    I18nData[
        'staff.page.api_keyholder_detail.api_access_card.usage_limit_label'
    ];
export const STAFF_DETAIL_FORM_CANCEL_LABEL =
    I18nData['staff.page.api_keyholder_detail.form.cancel_label'];
export const STAFF_DETAIL_FORM_SAVE_LABEL =
    I18nData['staff.page.api_keyholder_detail.form.save_label'];

// Api Elevated Access Form
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_SOFTWARE_APPLICATION_HEADER =
    I18nData[
        'form.public_api.elevate_access_request.label.application.subheading'
    ];
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_CONTACT_INFORMATION_HEADER =
    I18nData['form.public_api.elevate_access_request.label.contact.subheading'];
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_PRIMARY_CONTACT =
    I18nData['form.public_api.elevate_access_request.label.primary_contact'];
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_TECHNICAL_CONTACT =
    I18nData['form.public_api.elevate_access_request.label.technical_contact'];
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_ALTERNATIVE_TECHNICAL_CONTACT =
    I18nData[
        'form.public_api.elevate_access_request.label.alternative_technical_contact'
    ];
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_TECHNICAL_CONTACT_CHECKBOX_LABEL =
    I18nData[
        'form.public_api.elevate_access_request.label.show_technical_contact'
    ];
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_ALTERNATIVE_TECHNICAL_CONTACT_CHECKBOX_LABEL =
    I18nData[
        'form.public_api.elevate_access_request.label.show_alternative_technical_contact'
    ];

// Api Elevated Access Form Cancel Modal
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_CANCEL_MODAL_HEADING =
    I18nData['staff.api.modal.cancel_access_change.heading'];
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_CANCEL_MODAL_BODY =
    I18nData['staff.api.modal.cancel_access_change.body'];
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_CANCEL_MODAL_CANCEL_LABEL =
    I18nData['staff.api.modal.cancel_access_change.cancel_label'];
export const STAFF_ELEVATED_ACCESS_APPLICATION_FORM_CANCEL_MODAL_CONTINUE_LABEL =
    I18nData['staff.api.modal.cancel_access_change.continue_editing_label'];

// Api Elevated Access Request Page
export const STAFF_GRANT_ACCESS_APPLICATION_FORM_SOFTWARE_APPLICATION_HEADER =
    I18nData[
        'staff.grant_api_access.application_form.software_application.header'
    ];
export const STAFF_GRANT_ACCESS_APPLICATION_FORM_CONTACT_INFORMATION_HEADER =
    I18nData[
        'staff.grant_api_access.application_form.contact_information.header'
    ];
export const STAFF_GRANT_ACCESS_APPLICATION_FORM_EDIT_LABEL =
    I18nData['staff.grant_api_access.application_form.edit_label'];
export const STAFF_GRANT_ACCESS_APPLICATION_FORM_CANCEL_LABEL =
    I18nData['staff.grant_api_access.application_form.cancel_label'];
export const STAFF_GRANT_ACCESS_APPLICATION_FORM_EDITING_WARNING =
    I18nData['staff.grant_api_access.editing_warning'];
export const STAFF_GRANT_ACCESS_APPLICATION_FORM_UPDATE_SUCCESS_TOAST =
    I18nData['staff.grant_api_access.application_form.update_success_toast'];
export const STAFF_GRANT_ACCESS_APPLICATION_USAGE_PLAN_HELP_LABEL =
    I18nData['staff.grant_api_access.usage_plan_help.label'];

export const STAFF_GRANT_API_ACCESS_MODAL_DENY_HEADING =
    I18nData['staff.grant_api_access_modal.deny.heading'];
export const STAFF_GRANT_API_ACCESS_MODAL_DENY_BODY =
    I18nData['staff.grant_api_access_modal.deny.body'];
export const STAFF_GRANT_API_ACCESS_MODAL_DENY_BODY_2 =
    I18nData['staff.grant_api_access_modal.deny.body_2'];
export const STAFF_GRANT_API_ACCESS_MODAL_DENY_SUBMIT_BUTTON_LABEL =
    I18nData['staff.grant_api_access_modal.deny.submit_button_label'];
export const STAFF_GRANT_API_ACCESS_MODAL_DENY_CANCEL_BUTTON_LABEL =
    I18nData['staff.grant_api_access_modal.deny.cancel_button_label'];

export const STAFF_GRANT_API_ACCESS_MODAL_GRANT_HEADING =
    I18nData['staff.grant_api_access_modal.grant.heading'];
export const STAFF_GRANT_API_ACCESS_MODAL_GRANT_HEADING_TOKEN = ':accessLevel';
export const STAFF_GRANT_API_ACCESS_MODAL_GRANT_BODY =
    I18nData['staff.grant_api_access_modal.grant.body'];
export const STAFF_GRANT_API_ACCESS_MODAL_GRANT_BODY_TOKEN = ':emailOrEmails';
export const STAFF_GRANT_API_ACCESS_MODAL_GRANT_BODY_AND =
    I18nData['staff.grant_api_access_modal.grant.body_and'];
export const STAFF_GRANT_API_ACCESS_MODAL_GRANT_BODY_2 =
    I18nData['staff.grant_api_access_modal.grant.body_2'];
export const STAFF_GRANT_API_ACCESS_MODAL_GRANT_SUBMIT_BUTTON_LABEL =
    I18nData['staff.grant_api_access_modal.grant.submit_button_label'];
export const STAFF_GRANT_API_ACCESS_MODAL_GRANT_CANCEL_BUTTON_LABEL =
    I18nData['staff.grant_api_access_modal.grant.cancel_button_label'];

export const STAFF_MODAL_USAGE_PLANS_HEADING =
    I18nData['staff.modal.usage_plans.heading'];
