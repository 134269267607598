import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _noop from 'lodash/noop';
import _uniquedId from 'lodash/uniqueId';

/**
 * Radio component for generic radio input
 * @class Radio
 * @extends React.Component
 */
class Radio extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        ['aria-label']: PropTypes.string,
        id: PropTypes.string.isRequired,
        isChecked: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
            .isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        required: PropTypes.bool,
        onChange: PropTypes.func,
        ['aria-describedby']: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        ['aria-label']: null,
        isChecked: false,
        required: null,
        onChange: _noop,
        ['aria-describedby']: null,
    };

    get classNames() {
        return buildClassNames({
            ['radio']: true,
        });
    }

    /**
     * @param {Event} ev
     * @private
     */
    handleChange = ev => {
        this.props.onChange(this.props.value, this.props.name);
    };

    /**
     * @returns {string}
     */
    render() {
        const { id, isChecked, label, name, value, required } = this.props;

        return (
            <label className={this.classNames} htmlFor={id} data-test={id}>
                <input
                    id={id}
                    className="radio-input"
                    type="radio"
                    name={name}
                    checked={isChecked}
                    value={value}
                    required={required}
                    aria-label={this.props['aria-label']}
                    onChange={this.handleChange}
                    aria-describedby={this.props['aria-describedby']}
                />
                <span className="radio-btn" />
                <span className="radio-label">{label}</span>
            </label>
        );
    }
}

export default Radio;
