import React, { Component } from 'react';
import { State } from 'react-automata';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _startCase from 'lodash/startCase';

import { Config } from '../../../../../../../core/scripts/lib/Config';
import LiveRegionAlert from '../../../../../../../core/scripts/react-components/specifics/LiveRegionAlert';

import {
    PETS,
    LOCATION_INPUT_PLACEHOLDER,
    LOCATION_ARIA_DESCRIPTION,
    BUSY_STATES,
    getAlertWithKey,
} from '../../constants';

import Loader from '../../../Loader';
import Modal from '../../../Modal';
import { CLOSE_BTN_LABEL } from '../../../Modal/constants';
import SavedSearchList from '../SavedSearchList';

/**
 * Modal component child component for find a pet menu mobile location view
 * @class LocationModal
 * @extends React.Component
 */
class LocationModal extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        locationInputValue: PropTypes.string.isRequired,
        locationSuggestionItems: PropTypes.arrayOf(PropTypes.element),
        machineState: PropTypes.object.isRequired,
        petPropertiesIndex: PropTypes.number,
        petTypeSlug: PropTypes.string,
        savedSearches: PropTypes.arrayOf(PropTypes.object),
        onClose: PropTypes.func.isRequired,
        onInputChange: PropTypes.func.isRequired,
        onInputFocus: PropTypes.func.isRequired,
        onSuggestionsListBlur: PropTypes.func.isRequired,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        locationInputValue: '',
        locationSuggestionItems: [],
    };

    modalInput = React.createRef();

    geoLocationRef = React.createRef();

    /**
     * Build classnames for modal
     * @method getModalClassNames
     * @private
     * @returns {string}
     */
    getModalClassNames() {
        const petTypeSlugStr = _startCase(this.props.petTypeSlug).replace(
            /\s+/g,
            ''
        );

        return {
            ['modal_findAPetLocations']: true,
            [`modal_bg${petTypeSlugStr}`]: true,
        };
    }

    /**
     * Build classnames for header text
     * @method getHeaderClassNames
     * @private
     * @returns {string}
     */
    getHeaderClassNames() {
        return buildClassNames({
            ['txt']: true,
            ['txt_h2']: true,
            ['m-txt_colorWhite']: true,
            ['m-txt_shadowDarker']: true,
            ['u-posRelative']: true,
            ['u-vr2x']: true,
            ['u-z1']: true,
        });
    }

    /**
     * Build classnames for field
     * @method getFieldClassNames
     * @private
     * @returns {string}
     */
    getFieldClassNames() {
        return buildClassNames({
            ['field']: true,
            ['field_icon']: true,
            ['m-field_shadowedHeavy']: true,
            ['m-field_noFocus']: true,
            ['m-field_colorPrimary']: true,
            ['m-field_bold']: true,
            ['m-field_iconLeft']: true,
            ['m-field_iconLocation']: true,
            ['m-field_noLabel']: true,
        });
    }

    /**
     * Build classnames for input field input
     * @method getFieldInputClassNames
     * @private
     * @returns {string}
     */
    getFieldInputClassNames() {
        const stateValue = this.props.machineState.value.main.mobile;
        const dropdownStateValues = ['results', 'noResults'];

        return buildClassNames({
            ['field-input']: true,
            ['m-field-input_hasDropdown']: dropdownStateValues.some(
                value => stateValue === value
            ),
        });
    }

    /**
     * Build classnames for saved search component header
     * @method getFieldInputClassNames
     * @private
     * @returns {string}
     */
    getSavedSearchHeaderClassNames() {
        return buildClassNames({
            ['txt']: true,
            ['m-txt_heavy']: true,
            ['m-txt_uppercase']: true,
            ['m-txt_colorWhite']: true,
            ['m-txt_alignCenter']: true,
            ['u-vr4x']: true,
        });
    }

    /**
     * Get alert message with a state key and location
     * @method getAlert
     * @private
     * @returns {string}
     */
    getAlert() {
        const stateKey = this.props.machineState.value.main.mobile
            .isHandlingSearch;

        return getAlertWithKey(stateKey, this.props.locationSuggestionItems);
    }

    /**
     * Get header text from pet properties
     * @method getHeaderText
     * @private
     * @returns {string}
     */
    getHeaderText() {
        return PETS.properties[this.props.petPropertiesIndex].modalHeaderText;
    }

    /**
     * @method handleInputChange
     * @private
     * @param {Event} ev
     */
    handleInputChange = ev => {
        this.props.onInputChange(ev);
    };

    /**
     * @method handleInputFocus
     * @private
     * @param {Event} ev
     */
    handleInputFocus = ev => {
        this.props.onInputFocus(ev);
    };

    /**
     * @method handleSuggestionListBlur
     * @private
     * @param {Event} ev
     */
    handleSuggestionListBlur = ev => {
        this.props.onSuggestionsListBlur(ev);
    };

    handleMyLocationClick = ev => {
        this.props.onLocationSearchClick(ev);
    };

    handleLocationSuggestionClick = ev => {
        this.props.onLocationSuggestionClick(this.props);
    };

    renderLocationIcon() {
        return (
            <span className="icon icon_smmd m-icon_colorPrimary">
                <svg role="img" focusable="false">
                    <use xlinkHref="#icon-locationArrow" />
                </svg>
            </span>
        );
    }

    renderGeoLocationSuggestion() {
        return (
            <li className="findAPetMenu-locationSuggestionListItemGeoLocation">
                <a
                    href="#"
                    aria-label="Use my location for search"
                    className="findAPetMenu-geoLocationSuggestionListItemButton"
                    onClick={this.handleMyLocationClick}
                >
                    {this.renderLocationIcon()}
                    <span className="findAPetMenu-geoLocationSuggestionListItemButtonLabel">
                        Use my location
                    </span>
                </a>
            </li>
        );
    }

    renderGeoLocationSearching() {
        return (
            <li className="findAPetMenu-locationSuggestionListItemGeoLocation">
                <span
                    className="findAPetMenu-geoLocationSuggestionListItemButton"
                    tabIndex="0"
                    ref={this.geoLocationRef}
                >
                    {this.renderLocationIcon()}
                    <span className="findAPetMenu-geoLocationSuggestionListItemButtonLabel">
                        Finding your location...
                    </span>
                </span>
                <Loader />
            </li>
        );
    }

    renderGeoLocationError() {
        return (
            <li className="findAPetMenu-locationSuggestionListItemGeoLocation">
                <State is={'main.mobile.isCheckingLocation.active.geoErrored'}>
                    <button
                        className="findAPetMenu-geoLocationSuggestionListItemButton"
                        ref={this.geoLocationRef}
                        onClick={this.handleMyLocationClick}
                        aria-label="We couldn't find your location. Retry?"
                    >
                        {this.renderLocationIcon()}
                        <span className="findAPetMenu-geoLocationSuggestionListItemButtonLabel">
                            {/* Device location failure, encourage retry */}
                            We couldn't find your location.{' '}
                            <span className="txt txt_link">Retry?</span>
                        </span>
                    </button>
                </State>
                <State
                    is={[
                        'main.mobile.isCheckingLocation.active.geoDisabledMessage',
                        'main.mobile.isCheckingLocation.geoDenied',
                        'main.mobile.isCheckingLocation.active.locationErrored',
                    ]}
                >
                    <span className="findAPetMenu-geoLocationSuggestionListItemButton">
                        {this.renderLocationIcon()}
                        <span
                            tabIndex="0"
                            ref={this.geoLocationRef}
                            className="findAPetMenu-geoLocationSuggestionListItemButtonLabel"
                        >
                            <State
                                is={[
                                    'main.mobile.isCheckingLocation.active.geoDisabledMessage',
                                ]}
                            >
                                Geolocation is not supported by your browser.
                            </State>
                            <State
                                is={[
                                    'main.mobile.isCheckingLocation.geoDenied',
                                ]}
                            >
                                {/* Refused to turn on location services */}
                                We couldn't access your device's location, but
                                you can type your location instead.
                            </State>
                            <State
                                is={[
                                    'main.mobile.isCheckingLocation.active.locationErrored',
                                ]}
                            >
                                {/* Could not match user coordinates to our DB of locations */}
                                Something went wrong on our end.
                            </State>
                        </span>
                    </span>
                </State>
            </li>
        );
    }

    /**
     * @method renderGeoLocationFragment
     * @private
     * @returns {string}
     */
    renderGeoLocationFragment() {
        return (
            <React.Fragment>
                <State is={['main.mobile.isCheckingLocation.active.ready']}>
                    {this.renderGeoLocationSuggestion()}
                </State>
                <State
                    is={[
                        'main.mobile.isCheckingLocation.active.gettingLocation',
                        'main.mobile.isCheckingLocation.active.gettingCoordinates',
                        'main.mobile.isCheckingLocation.active.checkingAuth',
                    ]}
                >
                    {this.renderGeoLocationSearching()}
                </State>
                <State
                    is={[
                        'main.mobile.isCheckingLocation.geoDenied',
                        'main.mobile.isCheckingLocation.active.geoErrored',
                        'main.mobile.isCheckingLocation.active.locationErrored',
                        'main.mobile.isCheckingLocation.active.geoDisabledMessage',
                    ]}
                >
                    {this.renderGeoLocationError()}
                </State>
            </React.Fragment>
        );
    }

    /**
     * @method handleCloseClick
     * @private
     * @param {Event} ev
     */
    handleCloseClick = () => {
        this.props.onClose();
    };

    /**
     * Handles building location input and current location items
     * @method renderLocationInput
     * @private
     * @return {string}
     */
    renderLocationInput() {
        const { locationInputValue, locationSuggestionItems } = this.props;

        return (
            <div className={this.getFieldClassNames()}>
                <State is={['main.mobile.isHandlingSearch.loading']}>
                    <Loader />
                </State>
                <p
                    id="FindAPetMenuModal_Location_Input_Description"
                    className="u-isVisuallyHidden"
                    aria-hidden="true"
                >
                    {LOCATION_ARIA_DESCRIPTION}
                </p>
                <input
                    className={this.getFieldInputClassNames()}
                    placeholder={LOCATION_INPUT_PLACEHOLDER}
                    type="text"
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    value={locationInputValue}
                    aria-label="Search Near"
                    aria-describedby="FindAPetMenuModal_Location_Input_Description"
                    ref={this.modalInput}
                />
                <State
                    is={[
                        'main.mobile.isHandlingSearch.results',
                        'main.mobile.isHandlingSearch.noResults',
                        'main.mobile.isCheckingLocation.active.*',
                        'main.mobile.isCheckingLocation.geoDenied',
                    ]}
                >
                    <ul
                        className="findAPetMenu-locationModalSuggestionList"
                        onBlur={this.handleSuggestionListBlur}
                        role="listbox"
                    >
                        {this.renderGeoLocationFragment()}
                        {locationSuggestionItems}
                    </ul>
                </State>
            </div>
        );
    }

    /**
     * Handles rendering of the saved search component if saved searches are passed into props
     * @method renderSavedSearchContainer
     * @private
     * @return {string}
     */
    renderSavedSearchContainer() {
        const { savedSearches } = this.props;

        if (!Config.userAuthed) {
            return;
        }

        return (
            <div className="savedSearchContainer savedSearchContainer_modal">
                <div className="savedSearchContainer-inner">
                    <div className="savedSearchContainer-header">
                        <h3 className={this.getSavedSearchHeaderClassNames()}>
                            {'Previous Searches'}
                        </h3>
                    </div>
                    <div className="savedSearchContainer-body">
                        {savedSearches && (
                            <SavedSearchList savedSearches={savedSearches} />
                        )}
                        {!savedSearches && <Loader />}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const busy = BUSY_STATES.includes(
            this.props.machineState.value.main.mobile.isHandlingSearch
        );

        return (
            <Modal extensionClasses={this.getModalClassNames()}>
                <div className="modal-header" />
                <div className="modal-content">
                    <div className="findAPetMenu-locationModal">
                        <h2
                            className={this.getHeaderClassNames()}
                            tabIndex="-1"
                        >
                            {'Search near'}
                        </h2>
                        {this.renderLocationInput()}
                        <LiveRegionAlert alert={this.getAlert()} busy={busy} />
                    </div>
                </div>
                <button
                    className="modal-closeBtn"
                    onClick={this.handleCloseClick}
                    type="button"
                    aria-label={CLOSE_BTN_LABEL}
                >
                    {CLOSE_BTN_LABEL}
                </button>
                {this.renderSavedSearchContainer()}
            </Modal>
        );
    }
}

export default LocationModal;
