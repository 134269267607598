class TemplateRegistry {
    static _registeredTemplates = {};

    static register(id, template) {
        this._registeredTemplates[id] = template;
    }

    static has(id) {
        return id in this._registeredTemplates;
    }

    static get(id) {
        return this._registeredTemplates[id];
    }
}

export default TemplateRegistry;
