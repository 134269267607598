export const SSO_MODAL_ACTIONS = {
    open: 'open',
    close: 'close',
};

export const handleSSOModal = (action, location) => {
    // location is used strictly for analytics, fun!

    if (action === SSO_MODAL_ACTIONS.open) {
        window.isModalOpen = true;
        window.ssoModalLocation = location;
        document.dispatchEvent(new Event('ssoModalEvent'));
    } else if (action === SSO_MODAL_ACTIONS.close) {
        window.isModalOpen = false;
        window.ssoModalLocation = location;
        document.dispatchEvent(new Event('ssoModalEvent'));
    }
};
