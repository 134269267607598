import Mixins from '../Mixins';
import MixinBase from './MixinBase';
import ActionParser from '../ActionParser';
import { offset } from '../../../util/dom';

export default class ScrollToOnRender extends MixinBase {
    static _attributeName = 'mix-scroll-to-on-render';

    constructor(ele, value) {
        super(ele, value);
        
        const elOffset = offset(ele);
        window.scrollTo(0, elOffset.top);
    }
}
