/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../../../breeds/specifics/SvgIcon';

import GoogleAPIService from '../../services/GoogleAPIService';

import buildClassNames from 'classnames';
/**
 * Google sign in button
 * Requires that the gapi AUTH2 object has already been initialized on the window
 * @returns {string}
 */
class GoogleButton extends Component {
    /* *******************************************
     * Class Properties
     ********************************************/

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        cta: PropTypes.string,
        next: PropTypes.string,
        next_params: PropTypes.array,
        type: PropTypes.string,
        text: PropTypes.bool,
        handleSocialClick: PropTypes.func,
        ready: PropTypes.bool,
        id: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        cta: 'Continue with ',
        type: 'full',
        text: true,
        ready: false,
        id: null,
    };

    /**
     * @type {Ref}
     */
    button = React.createRef();

    getRootClassNames() {
        return buildClassNames({
            btn: true,
            btn_googleLogin: true,
            btn_googleLogin_inline: this.props.type === 'inline',
            'm-btn_iconLeft': true,
            'm-btn_normalCase': true,
            'm-btn_full': true,
            's-btn_contentLoading': !this.props.ready,
        });
    }

    get buttonText() {
        let text = '';
        if (this.props.text) {
            text = `${text}${this.props.cta} `;
        }
        text = `${text}Google`;
        return text;
    }

    get buttonLabel() {
        return this.buttonText ? this.buttonText : 'Google';
    }

    /* *******************************************
     * Lifecycle
     ********************************************/
    componentDidMount() {
        if (this.props.ready) {
            this.attachClickHandler();
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.ready !== prevProps.ready && this.props.ready) {
            this.attachClickHandler();
        }
    }

    /* *******************************************
     * Methods
     ********************************************/
    /**
     * @type {Function}
     * Attach click handler, pass in configuration values
     */
    attachClickHandler = () => {
        GoogleAPIService.attachClickHandler({
            button: this.button.current,
            context: this.props.context,
            next: this.props.next,
            next_params: this.props.next_params,
        });
    };

    /* *******************************************
     * Events
     ********************************************/

    /**
     * @type {Function}
     * Hook for analytic event, passes in provider
     */
    handleSocialClick = () => {
        this.props.handleSocialClick('google');
    };

    /* *******************************************
     * Render
     ********************************************/

    renderFunctionalButton = () => {
        return (
            <button
                ref={this.button}
                onClick={this.handleSocialClick}
                aria-label={this.buttonLabel}
                type="button"
                className={this.getRootClassNames()}
                id={this.props.id}
            >
                <span className="btn-icon">
                    <SvgIcon svgId="googleLogo" size="18" />
                </span>
                {this.buttonText}
            </button>
        );
    };

    renderPendingButton = () => {
        // TODO_HM: Pending styles currently in UX review
        return (
            <div
                aria-label="Google authentication is loading..."
                className={this.getRootClassNames()}
            >
                <span className="btn-icon" />
            </div>
        );
    };

    render() {
        return (
            <Fragment>
                {!this.props.ready
                    ? this.renderPendingButton()
                    : this.renderFunctionalButton()}
            </Fragment>
        );
    }
}

export default GoogleButton;
