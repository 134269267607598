// TODO: this file is to be deprecated once UserSavedSearchRepository is removed

// TODO: this is no longer being used; use it or lose it?
import { Me } from '../types/Me';
import axios from 'axios';
/**
 * User/Me Repository
 * @extends Repository
 */
export default class UserMeRepository {
    /**
     * Returns full User/Me data object from webfront
     * @return {Promise}
     */
    static async getUserMe() {
        const response = await axios('/user/me/', {
            method: 'GET',
            headers: {
                // Need this content-type here
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
        return response.data;
    }

    /**
     * Returns an "awaitable" value for whether or not the user is logged in
     * @returns {Promise}
     */
    static get isLoggedIn() {
        return this.getUserMe().then(data => {
            return data.user != null;
        });
    }
}
