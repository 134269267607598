import siteStateController from '../../../dotcom/scripts/state-controllers/siteStateController';
import socialLoginStateController from '../../../dotcom/scripts/react-components/specifics/SocialLogin/controller/SocialLoginStateController';
import { savedSearchAPIService } from '../../../dotcom/scripts/api-services/savedSearchAPIService';
import userMeAPIService from '../../../core/scripts/services/userMeAPIService';
import { parse } from 'query-string';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import { SSO_MODAL_ACTIONS, handleSSOModal } from '../../../dotcom/scripts/util/ssoModalHelpers';

class SavedSearchService {
    /**
     * Returns the species name.
     * If no species available, returns the animal type.
     * If no animal type set, returns the default.
     *
     * @readonly
     * @returns {String}
     */
    // prettier-ignore
    get formattedSpeciesOrAnimalType() {
        let formattedSpeciesOrAnimalType = 'Pets'; // default value
        if (siteStateController.animalSearch.filters.speciesExpandedValues.length) {
            formattedSpeciesOrAnimalType = siteStateController.animalSearch.filters.speciesExpandedValues[0].label;
        } else if (siteStateController.animalSearch.filters.animalTypeExpandedValues.length) {
            formattedSpeciesOrAnimalType = siteStateController.animalSearch.filters.animalTypeExpandedValues[0].label;
        }
        return formattedSpeciesOrAnimalType;
    }

    /**
     * Returns the formatted location name.
     * If no location set, returns empty string.
     * Format will always be: 'country/state/city'
     *
     * @readonly
     * @returns {String}
     */
    get formattedLocation() {
        if (!siteStateController.animalSearch.filters.location.length) {
            return '';
        }

        const splitLocation = (siteStateController.animalSearch.filters.location[0] || '').split('/');

        // attempt to parse state off the location and set all letters to uppercase
        // Note that the state will always be in the 2nd array position after the split
        const formattedState = splitLocation[1].toUpperCase();

        // attempt to parse city off of the location and uppercase the first letter
        let formattedCity = splitLocation[2] || '';
        if (typeof formattedCity !== 'string') {
            formattedCity = String(formattedCity);
        }
        formattedCity = _upperFirst(formattedCity);

        const formattedLocation = formattedCity ? `${formattedCity}, ${formattedState} |` : '';
        return formattedLocation;
    }

    /**
     * Returns the formatted search distance.
     * If no distance set, returns the default.
     *
     * @readonly
     * @returns {String}
     */
    // prettier-ignore
    get formattedDistance() {
        let formattedDistance = 'everywhere'; // default value
        if (siteStateController.animalSearch.filters.location.length) {
            formattedDistance = siteStateController.animalSearch.filters.distanceExpandedValues[0].label;
        }
        return formattedDistance;
    }

    /**
     * Returns the saved search generated nickname.
     *
     * @readonly
     * @returns {String}
     */
    get currentFiltersNickname() {
        return `${this.formattedSpeciesOrAnimalType} | ${this.formattedLocation} ${this.formattedDistance}`;
    }

    get currentFiltersSummaryDescription() {
        return siteStateController.animalSearch.filters.expandedAppliedFilters.map(item => item.longLabel).join(', ');
    }

    /**
     * Checks for sessionStorage saved search data, if it exists continues to get and parse
     * the query string. Passes data to launch the saved search confirmed modal. Finally,
     * removes the sessionStorage saved search data.
     */
    checkAutomaticLaunchOnLoad() {
        const href = sessionStorage.getItem('savedSearchHref');

        if (!href) {
            return;
        }

        const queryString = sessionStorage.getItem('savedSearchQueryString');
        const parsedQueryParams = parse(queryString, { arrayFormat: 'index' });
        const savedSearchData = {
            searchUrl: href,
            ...parsedQueryParams,
        };

        if (sessionStorage.getItem('name')) {
            savedSearchData.name = sessionStorage.getItem('name');
            sessionStorage.removeItem('name');
        }

        this.launchSaveSearchModal(savedSearchData);

        sessionStorage.removeItem('savedSearchHref');
        sessionStorage.removeItem('savedSearchQueryString');
    }

    /**
     * @param {{ focusTarget: Object, analyticTriggerLabel: String, searchUrl: String, }} launchData
     */
    async launchSaveSearchModal(launchData = {}) {
        siteStateController.ui.savedSearch.modalProps = {
            name: _get(launchData, 'name', null),
            filtersSummaryDescription: _get(launchData, 'filtersSummaryDescription', null),
        };

        const isLoggedIn = await userMeAPIService.isLoggedIn;
        if (isLoggedIn) {
            this._performLoggedInSaveSearch(launchData);
        } else {
            this._launchLoginSignupModal(launchData);
        }
    }

    async _performLoggedInSaveSearch(supplementalSavedSearchData = {}) {
        await this.saveUserSearch(supplementalSavedSearchData);

        siteStateController.ui.savedSearch.transitionToConfirmationStep();
        siteStateController.ui.savedSearch.isSavedSearchModalVisible = true;
    }

    _setSessionStorage(launchData) {
        const url = new URL(launchData.searchUrl);
        sessionStorage.setItem('savedSearchHref', url.href);
        sessionStorage.setItem('savedSearchQueryString', url.search);
        if (launchData.name) {
            sessionStorage.setItem('name', launchData.name);
        }
    }

    /**
     * @param {{focusTarget: Object, analyticTriggerLabel: String }} launchData
     */
    // prettier-ignore
    _launchLoginSignupModal(launchData) {
        siteStateController.ui.savedSearch.searchUrl = launchData.searchUrl;

        if (window.ssoFlagIsActive) {
            this._setSessionStorage(launchData);
            handleSSOModal(SSO_MODAL_ACTIONS.open, 'save search');
        } else {
            // OLD LOGIN MODAL - saving in case of rollback
            socialLoginStateController.openLoginModal({
                analyticTriggerLabel: launchData.analyticTriggerLabel,
                display: 'signUp',
                context: 'save-search',
                refocusTarget: launchData.focusTarget,
                onSuccess: () => { // Use arrow function to avoid binding issues
                    this._setSessionStorage(launchData);
                    location.reload();
                },
            });
        }
    }

    /**
     * @param {{ searchUrl: String, }} inSavedSearchData
     */
    async saveUserSearch(inSavedSearchData = {}) {
        // The searchUrl key is required, check first to see if it is present.
        if (!inSavedSearchData.searchUrl) {
            throw new Error(
                `The argument provided does not include the searchUrl key. Value is currently: ${inSavedSearchData.searchUrl}.`
            );
        }

        const userMeModel = await userMeAPIService.getUserMe();
        const token = userMeModel.savedSearchToken;
        const savedSearchData = {
            token, // general information
            ...inSavedSearchData, // include anything additionally provided
        };

        // turn on saving flag
        siteStateController.ui.savedSearch.isSaving = true;

        // perform save
        const savedSearchResponse = await savedSearchAPIService.create(savedSearchData);

        // turn off saving flag
        siteStateController.ui.savedSearch.isSaving = false;

        if (!savedSearchResponse.error) {
            // store updated data
            siteStateController.ui.savedSearch.savedSearchResponseData = savedSearchResponse.data;

            // update saved searches
            this._triggerSavedSearchListUpdate();
        }

        // TODO: what should we be returning here?
        return savedSearchResponse;
    }

    /**
     * A method to update a logged in user's preference regarding the reception of daily emails
     *
     * @param {Boolean} value
     */
    async setRequestDailyEmails(value) {
        const savedSearchData = siteStateController.ui.savedSearch.savedSearchModelData;
        const userMeModel = await userMeAPIService.getUserMe();
        const token = userMeModel.savedSearchToken;

        return savedSearchAPIService.update(savedSearchData.id, {
            name: savedSearchData.name,
            token,
            subscribed: value,
        });
    }

    _triggerSavedSearchListUpdate() {
        // refresh user/me to get new saved search list
        siteStateController.userMe.refreshUserMeData();

        // once siteStateController.userMe.refreshUserMeData() completes, it will update the state accordingly
    }
}

const savedSearchService = new SavedSearchService();

export default savedSearchService;
