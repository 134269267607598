import Repository from '../repository/repository';
import { ERROR_API } from '../../constants/errors';

const BASE_URL = '/wp-json/wp/v2/';

/**
 * Wordpress Repository
 * @extends Repository
 */
export default class WordpressRepository extends Repository {
    /**
     * Get content touts from wordpress api
     * @param {Object} params to pass to the content tout api
     * @return {Promise}
     */
    async getContentTouts(params) {
        const apiUrl = `${BASE_URL}content-touts/`;
        const url = this.buildUrlWithQuery(apiUrl, params);
        const response = await fetch(
            url,
            {
                method: 'GET',
                headers: {
                    // Need this content-type here
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'same-origin',
            }
        );

        if (response.status === 200) {
            const data = await response.json();
            return data['content_touts'];
        } else {
            // TODO
        }
    }

    /**
     * A generic get posts method to load wordpress data from wordpress ajax url
     */
    async getPosts(ajaxUrl, params) {
        const url = this.buildUrlWithQuery(ajaxUrl, params);
        const response = await fetch(
            url,
            {
                method: 'GET',
                credentials: 'same-origin',
            }
        );

        if (response.status === 200) {
            return await response.text();
        } else {
            // TODO
        }
    }
}
