import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { CLASS_IS_ERRORED, CLASS_IS_FOCUSED } from '../../constants/classes';
import { watchForMutations } from '../../../../core/scripts/util/dom';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-input';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    INPUT: `${ELEMENT_TAG_NAME}-input`,
};

/**
 * Reference to a mixin for the error state of a field
 * @const
 * @type {string}
 */
const FIELD_ERROR_CLASS = 'm-field_error';

/**
 * This element is used as a wrapper to a label and input markup pair so we can animate the
 * label in different component states. Please reference _field.css for styles.
 *
 * REQUIRED BASE MARKUP:
 * ---
 * <pfdc-input class="field">
 *     <label
 *         for="Field"
 *         class="field-label">Field Label</label>
 *     <input pf-input-input
 *         id="Field"
 *         class="field-input"
 *         type="text" />
 * </pfdc-input>
 * ---
 *
 * @extends PFElement
 */
export class PFDCInputElement extends PFElement {
    /**
     * Set this component to errored state
     * @param {boolean} val
     */
    set isErrored(val) {
        if (val) {
            this.setAttribute('is-errored', '');
            this.classList.add(CLASS_IS_ERRORED);
        } else {
            this.removeAttribute('is-errored');
            this.classList.remove(CLASS_IS_ERRORED);
        }
    }

    get setValueFromParam() {
        return this.dataset.setValueFromParam;
    }

    get isPasswordField() {
        return this.hasAttribute('is-password');
    }

    /**
     * Initialize this component
     */
    onInit() {
        /**
         * Reference to the input element
         * @type {HTMLElement}
         */
        this.input = this.querySelector(`[${ELEMENT_SELECTORS.INPUT}]`);
        if (this.isDynamic) {
            const config = { attributes: true };
            this.inputObserver = watchForMutations(
                this.input,
                this.onInputMutations.bind(this),
                config
            );
        }

        if (this.setValueFromParam) {
            const params = new URLSearchParams(window.location.search);
            this.input.value = params.get(this.setValueFromParam);
        }

        this.addEventListener('focus', this.onFocused, true);
        this.addEventListener('blur', this.onBlurred, true);
    }

    /**
     * Handles mutations to the input
     * @param {Array} mutations array of MutationRecords
     */
    onInputMutations(mutations) {
        mutations.forEach(mutation => {
            if (mutation.attributeName === 'class') {
                this.isErrored =
                    mutation.target.classList.contains(CLASS_IS_ERRORED) ||
                    mutation.target.classList.contains(FIELD_ERROR_CLASS);
            }
        });
    }

    /**
     * Handles focus events
     * @param {Object} ev event object
     */
    onFocused(ev) {
        if (ev.target === this.input) {
            this.classList.add(CLASS_IS_FOCUSED);
        }
    }

    /**
     * Handles blur events
     * @param {Object} ev event object
     */
    onBlurred(ev) {
        if (ev.target === this.input) {
            this.classList.remove(CLASS_IS_FOCUSED);
        }
    }
}

export default PFDCInputElement;
