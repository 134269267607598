import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _noop from 'lodash/noop';

import {
    KEY_ARROW_DOWN,
    KEY_DOWN,
    KEY_ARROW_UP,
    KEY_UP,
    KEY_ENTER,
    KEY_SPACE,
    KEY_SPACE_BAR,
} from '../../../constants/keyboard';

const RefElement = typeof Element === 'undefined' ? function () {} : Element;

/**
 * Renders an input and listbox with a list of options, provides render props to customize
 */
export default class ComboBox extends Component {
    /**
     * @type {Object}
     */
    state = {
        focusedItemIndex: 0,
        listBoxVisible: this.props.alwaysShowListBox,
        query: this.props.initialQuery,
        selected: this.props.initialSelections,
    };

    /**
     * @static
     * @type {Object}
     */
    static propTypes = {
        alwaysShowListBox: PropTypes.bool,
        buildOptionIdString: PropTypes.func,
        extensionClassNames: PropTypes.objectOf(PropTypes.bool),
        id: PropTypes.string.isRequired,
        initialQuery: PropTypes.string,
        inputRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({
                current: PropTypes.instanceOf(RefElement),
            }),
        ]),
        label: PropTypes.string.isRequired,
        listBoxExtensionClassNames: PropTypes.objectOf(PropTypes.bool),
        multiSelect: PropTypes.bool,
        name: PropTypes.string.isRequired,
        initialSelections: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            })
        ),
        list: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            })
        ).isRequired,
        placeholder: PropTypes.string,
        renderListBoxOptionLabel: PropTypes.func,
        renderInput: PropTypes.func,
        renderListBox: PropTypes.func,
        required: PropTypes.bool,
        showListBoxOnInputFocus: PropTypes.bool,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onSelection: PropTypes.func,
        onQueryChange: PropTypes.func,
    };

    /**
     * @static
     * @type {Object}
     */
    static defaultProps = {
        alwaysShowListBox: false,
        buildOptionIdString: (id, index) => `${id}-option-${index}`,
        extensionClassNames: {},
        initialQuery: '',
        initialSelections: [],
        inputRef: React.createRef(),
        listBoxExtensionClassNames: {},
        multiSelect: false,
        placeholder: null,
        renderListBoxOptionLabel: null,
        renderInput: null,
        renderListBox: null,
        required: false,
        showListBoxOnInputFocus: false,
        onBlur: _noop,
        onFocus: _noop,
        onSelection: _noop,
        onQueryChange: _noop,
    };

    /**
     * Timer reference for blur and focus
     * @type {number}
     */
    _blurTimeoutId = null;

    componentDidUpdate(prevProps, prevState) {
        if (this.state.listBoxVisible &&
            (prevState.focusedItemIndex !== this.state.focusedItemIndex)) {
            this._scrollFocusedListBoxOptionIntoView();
        }

        if (prevProps.initialSelections !== this.props.initialSelections ||
            prevProps.initialQuery !== this.props.initialQuery) {
            this.setState({
                query: this.props.initialQuery,
                selected: this.props.initialSelections,
            });
        }
    }

    get rootClassNames() {
        return buildClassNames({
            comboBox: true,
            's-comboBox_listBoxVisible': this.state.listBoxVisible,
            ...this.props.extensionClassNames,
        });
    }

    get labelClassNames() {
        return buildClassNames({
            'u-isVisuallyHidden': true,
        });
    }

    get inputClassNames() {
        return buildClassNames({
            'comboBox-input': true,
        });
    }

    get listBoxId() {
        return `${this.props.id}-listBox`;
    }

    get activeDescendantId() {
        // If there are no list options to render, there is not an activeDescendant
        if (!this.props.list.length) {
            return null;
        }

        return this.props.buildOptionIdString(
            this.props.id,
            this.state.focusedItemIndex
        );
    }

    get selectedValues() {
        return this.state.selected.map(selectedOption => selectedOption.value);
    }

    /**
     * Scroll focused option into view if its in a list that has height and scrollable
     */
    _scrollFocusedListBoxOptionIntoView() {
        const focusedOption = document.getElementById(this.activeDescendantId);
        focusedOption.scrollIntoView({ block: 'nearest' });
    }

    /**
     * Handle incrementing the focused index by passed number
     * @param {number} increment
     */
    _incrementFocusedIndex(increment) {
        this.setState(prevState => {
            const nextFocusedItemIndex = prevState.focusedItemIndex + increment;

            if (nextFocusedItemIndex < 0) {
                return { focusedItemIndex: prevState.focusedItemIndex };
            }

            if (nextFocusedItemIndex > this.props.list.length - 1) {
                return { focusedItemIndex: this.props.list.length - 1 };
            }

            return { focusedItemIndex: nextFocusedItemIndex };
        });
    }

    /**
     * Refocus the input, fire selection prop func to alert other components of value change
     */
    _handleSelectionChange() {
        this.props.onSelection(
            this.selectedValues,
            this.props.name,
            this.state.selected,
        );
    }

    /**
     * Reset the selected list options
     */
    _handleResetSelected = () => {
        this.setState({ selected: [] }, () => {
            this.props.inputRef.current.focus();
            this._handleSelectionChange();
        });
    };

    /**
     * @param {Event} event
     */
    _handleQueryChange = event => {
        const nextQuery = event.target.value;
        const nextState = _cloneDeep(this.state);
        nextState.query = nextQuery;
        nextState.listBoxVisible = true;

        this.setState(nextState, () =>
            this.props.onQueryChange(this.state.query)
        );
    };

    /**
     * Set selected options if multiselect, if not set query to label of selected option
     * @param {Object} option
     * @param {boolean} focusInputAfterSelection
     */
    _handleOptionClick = (option, focusInputAfterSelection = true) => {
        const nextState = _cloneDeep(this.state);

        if (this.props.multiSelect) {
            if (
                nextState.selected.some(
                    selectedOption => selectedOption.value === option.value
                )
            ) {
                nextState.selected.splice(
                    nextState.selected.findIndex(
                        selectedOption => selectedOption.value === option.value
                    ),
                    1
                );
            } else {
                nextState.selected.push(option);
            }
        } else {
            if (!this.props.alwaysShowListBox) {
                nextState.listBoxVisible = false;
            }

            nextState.selected[0] = option;
            nextState.query = option.label;
            nextState.focusedItemIndex = 0;
        }

        this.setState(nextState, () => {
            if (!this.props.multiSelect && focusInputAfterSelection) {
                this.props.inputRef.current.focus();

                // always force listBox close on option click, even if showListBoxOnInputFocus=true
                if (this.props.showListBoxOnInputFocus && !this.props.alwaysShowListBox) {
                    setTimeout(() => {
                        this.setState({ listBoxVisible: false });
                    });
                }
            }

            this._handleSelectionChange();
        });
    };

    /**
     * @param {Event} event
     */
    _handleInputKeyDown = event => {
        if (!this.props.list.length) {
            return;
        }

        switch (event.key) {
            case KEY_ARROW_DOWN:
            case KEY_DOWN:
                event.preventDefault();
                if (!this.state.listBoxVisible) {
                    this.setState({ listBoxVisible: true });
                    break;
                }
                this._incrementFocusedIndex(1);
                break;
            case KEY_ARROW_UP:
            case KEY_UP:
                event.preventDefault();
                this._incrementFocusedIndex(-1);
                break;
            case KEY_ENTER:
                event.preventDefault();
                this._handleOptionClick(
                    this.props.list[this.state.focusedItemIndex]
                );
                break;
        }
    };

    /**
     * @param {Event} event
     */
    _handleInputClick = event => {
        this.setState({ listBoxVisible: true });
    };

    /**
     * @param {Event} event
     */
    _handleBlur = event => {
        if (this.props.alwaysShowListBox) {
            return;
        }

        this._blurTimeoutId = setTimeout(() => {
            this.setState({ listBoxVisible: false },
                this.props.onBlur(this._handleOptionClick)
            );
        });
    };

    /**
     * @param {Event} event
     */
    _handleFocus = event => {
        clearTimeout(this._blurTimeoutId);

        this.props.onFocus(event);

        if (this.props.showListBoxOnInputFocus) {
            this.setState({ listBoxVisible: true });
        }
    };

    /**
     * Build all the props that get put onto the input, doing this so that render props can use these
     * @returns {Object}
     */
    _buildInputProps() {
        return {
            ref: this.props.inputRef,
            id: this.props.id,
            name: this.props.name,
            className: this.inputClassNames,
            placeholder: this.props.placeholder,
            autoComplete: 'off',
            spellCheck: 'off',
            type: 'text',
            required: this.props.required,
            value: this.state.query,
            'aria-autocomplete': 'list',
            'aria-activedescendant': this.activeDescendantId,
            'aria-controls': this.listBoxId,
            onChange: this._handleQueryChange,
            onKeyDown: this._handleInputKeyDown,
            onClick: this._handleInputClick,
        };
    }

    /**
     * Build props for ListBox
     * @returns {Object}
     */
    _buildListBoxProps() {
        return {
            id: this.listBoxId,
            extensionClassNames: {
                ['s-listBox_visible']: this.state.listBoxVisible,
                ...this.props.listBoxExtensionClassNames,
            },
            multiSelect: this.props.multiSelect,
        };
    }

    /**
     * Builds an object with a function that can be called to render the listbox option label
     * Gets access to the query from the combo box input
     * @returns {Object}
     */
    _buildRenderListBoxOptionLabel() {
        if (typeof this.props.renderListBoxOptionLabel !== 'function') {
            return {};
        }

        return {
            renderOptionLabel: option => {
                return this.props.renderListBoxOptionLabel(
                    option,
                    this.state.query
                );
            },
        };
    }

    /**
     * Build props for ListBoxOption
     * @param {Object} option
     * @param {number} index
     * @returns {Object}
     */
    _buildListBoxOptionProps(option, index) {
        const isSelected = this.state.selected.some(
            selectedOption => selectedOption.value === option.value
        );
        const isFocused = index === this.state.focusedItemIndex;

        return {
            option,
            index,
            key: option.value,
            id: this.props.buildOptionIdString(this.props.id, index),
            tabIndex: this.props.showListBoxOnInputFocus ? -1 : 0,
            setSize: this.props.list.length || null,
            isSelected,
            isFocused,
            onOptionClick: this._handleOptionClick,
            ...this._buildRenderListBoxOptionLabel(),
        };
    }

    _renderLabel() {
        return (
            <label className={this.labelClassNames} htmlFor={this.props.id}>
                {this.props.label}
            </label>
        );
    }

    _renderInput() {
        if (typeof this.props.renderInput === 'function') {
            return this.props.renderInput({
                props: this._buildInputProps(),
            });
        }

        return (
            <input {...this._buildInputProps()} />
        );
    }

    _renderListBox() {
        const options = this.props.list.map((option, index) => (
            <ListBoxOption
                key={option.value}
                {...this._buildListBoxOptionProps(option, index)}
            />
        ));

        if (typeof this.props.renderListBox === 'function') {
            return this.props.renderListBox({
                list: this.props.list,
                isVisible: this.state.listBoxVisible,
                props: this._buildListBoxProps(),
                options,
                selectOption: this._handleOptionClick,
            });
        }

        // The ListBox wraps the options (options hide and show) for a11y element linking reasons
        return (
            <ListBox {...this._buildListBoxProps()}>
                {this.state.listBoxVisible && options}
            </ListBox>
        );
    }

    _renderComboBox() {
        // Need this container to setup a container for blur and focus handlers
        // also adding position rel className so that css rules can absolutely position
        // the rendered list box as needed
        return (
            <div
                className="u-posRel"
                onBlur={this._handleBlur}
                onFocus={this._handleFocus}
            >
                {this._renderLabel()}
                <div
                    className={this.rootClassNames}
                    role="combobox"
                    aria-expanded={this.state.listBoxVisible}
                    aria-haspopup="listbox"
                    aria-owns={this.listBoxId}
                >
                    {this._renderInput()}
                </div>
                {this._renderListBox()}
            </div>
        );
    }

    render() {
        if (typeof this.props.children === 'function') {
            return this.props.children({
                comboBox: this._renderComboBox(),
                list: this.props.list,
                query: this.state.query,
                selected: this.state.selected,
                label: this.props.label,
                onOptionClick: this._handleOptionClick,
                resetSelected: this._handleResetSelected,
            });
        }

        return this._renderComboBox();
    }
}

/**
 * @param {Object} props
 * @returns {string}
 */
export function ListBox(props) {
    return (
        <ul
            id={props.id}
            className={buildClassNames({
                listBox: true,
                ['listBox_multiSelect']: props.multiSelect,
                ...props.extensionClassNames,
            })}
            role="listbox"
            aria-multiselectable={props.multiSelect}
            data-test={props.dataTestId}
        >
            {props.children}
        </ul>
    );
}

ListBox.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string.isRequired,
    extensionClassNames: PropTypes.objectOf(PropTypes.bool),
    multiSelect: PropTypes.bool,
    dataTestId: PropTypes.string,
};

ListBox.defaultProps = {
    extensionClassNames: {},
    multiSelect: false,
};

/**
 * @param {Object} props
 * @returns {string}
 */
export function ListBoxOption(props) {
    return (
        <li
            id={props.id}
            className={buildClassNames({
                ['listBox-option']: true,
                ['s-listBox-option_selected']: props.isSelected,
                ['s-listBox-option_focused']: props.isFocused,
                ...props.extensionClassNames,
            })}
            role="option"
            tabIndex={props.tabIndex}
            aria-setsize={props.setSize}
            aria-posinset={props.index + 1}
            aria-selected={props.isSelected}
            onClick={event => props.onOptionClick(props.option)}
            onKeyDown={event => {
                if (event.key === KEY_SPACE ||
                    event.key === KEY_SPACE_BAR ||
                    event.key === KEY_ENTER) {
                    event.preventDefault();
                    // Do not focus input here because someone has probably tab focused the option
                    props.onOptionClick(props.option, false);
                }
            }}
        >
            {props.renderOptionLabel(props.option)}

            {/* Because some screen readers do not care about aria-selected... */}
            <span className="u-isVisuallyHidden">{props.isSelected && 'Selected'}</span>
        </li>
    );
}

ListBoxOption.propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number,
    isSelected: PropTypes.bool,
    isFocused: PropTypes.bool,
    extensionClassNames: PropTypes.objectOf(PropTypes.bool),
    setSize: PropTypes.number,
    tabIndex: PropTypes.number,
    option: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }).isRequired,
    renderOptionLabel: PropTypes.func,
    onOptionClick: PropTypes.func,
};

ListBoxOption.defaultProps = {
    extensionClassNames: {},
    index: null,
    isSelected: null,
    isFocused: null,
    setSize: -1,
    tabIndex: -1,
    renderOptionLabel: option => option.label,
    onOptionClick: _noop,
};
