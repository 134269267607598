/* eslint-disable camelcase */

import StateController from '../../../../../../core/scripts/lib/wirey/state/StateController';
import _get from 'lodash/get';

import { eventDispatch } from '../controller/EventDispatchController';

import { analyticsTrigger } from '../../../../analytics/dotcom';

class SocialLoginState extends StateController {
    _authenticationSuccessHandler = null;
    _modalCloseHandler = () => {};
    _authenticationSuccessParams = {};
    _refocusTarget = null;

    // /* *******************************************
    //  * Getters
    //  ********************************************/

    // These values are reset when the LoginLauncher unmounts
    get defaultUserState() {
        return {
            user: {
                firstName: null,
                lastName: null,
                email: null,
            },
            modal: {
                alreadyOpen: false,
                triggerOpen: false,
                triggerClose: false,
                modalState: {
                    contextName: '',
                    display: 'social',
                    formData: {},
                },
            },
            step: null,
            token: null,
            activeProvider: 'petfinder',
            onAuthenticationSuccess: this.handleAuthenticationSuccess,
            error: null,
        };
    }

    get defaultState() {
        return {
            ...this.defaultUserState,
            auth: {
                facebook: {},
                google: {},
            },
            returningUsers: {
                facebook: false,
                google: false,
            },
            APIReady: {
                facebook: false,
                google: false,
            },
            userData: {
                facebook: {},
                google: {},
            },
            authStatus: {
                isLoggedIn: false,
                redirect: null,
            },
        };
    }

    get googleAuth() {
        if (!this.state.APIReady.google) {
            return {};
        }
        return window.gapi.auth2.getAuthInstance();
    }

    get state() {
        const state = super.state;

        return {
            ...state,
        };
    }

    get auth() {
        return this.state.auth;
    }

    get hasReturningUser() {
        if (
            this.state.returningUsers.google ||
            this.state.returningUsers.facebook
        ) {
            return true;
        }
        return false;
    }

    /**
     * Get name and url for retuning user profile picture.
     * Per UX: prioritize FB data if user is signed in accross multiple accounts.
     * @return {String}
     */
    get returningUserData() {
        if (
            this.state.returningUsers.facebook &&
            this.state.userData.facebook.profileData
        ) {
            if (
                this.state.returningUsers.google &&
                !this.state.userData.facebook.profileData.image
            ) {
                return this.state.userData.google.profileData;
            }
            return this.state.userData.facebook.profileData;
        } else if (
            this.state.returningUsers.google &&
            this.state.userData.google.profileData
        ) {
            return this.state.userData.google.profileData;
        }
    }

    get refocusTarget() {
        return this._refocusTarget;
    }

    get modalCloseHandler() {
        return this._modalCloseHandler;
    }

    // /* *******************************************
    //  * Setters
    //  ********************************************/

    set facebookAuth(value) {
        this.state.auth.facebook = value;
    }

    set googleAuth(value) {
        this.state.auth.google = value;
    }

    set authenticationSuccessHandler(fn) {
        this._authenticationSuccessHandler = fn;
    }

    set authenticationSuccessParameters(params) {
        this._authenticationSuccessParams = params;
    }

    set activeProvider(provider) {
        this.patchState({
            activeProvider: provider,
        });
    }

    set authStatus(status) {
        this.patchState({
            authStatus: {
                isLoggedIn: status.isLoggedIn,
                redirect:
                    status.redirect || this._authenticationSuccessParams.next,
            },
        });
    }

    set refocusTarget(target) {
        if (typeof target === 'string') {
            this._refocusTarget = document.querySelector(target);
            return;
        }
        this._refocusTarget = target;
    }

    set modalCloseHandler(fn) {
        this._modalCloseHandler = fn;
    }

    // /* *******************************************
    //  * Methods
    //  ********************************************/

    returningUserSignOut = {
        facebook: () => {
            window.FB.logout();
            this.patchState({
                returningUsers: {
                    facebook: false,
                },
            });
        },
        google: () => {
            this.googleAuth.signOut();
            this.patchState({
                returningUsers: {
                    google: false,
                },
            });
        },
    };

    reset = () => {
        this._authenticationSuccessHandler = null;
        this._authenticationSuccessParams = {};
        this._refocusTarget = null;
        this._modalCloseHandler = () => {};
        this.patchState(this.defaultUserState);
    };

    /**
     * @type {Function}
     * @param {String} redirect
     * Either triggers custom callback, or utilizes default success logic
     */
    handleAuthenticationSuccess = redirect => {
        const handlerFunction = this._authenticationSuccessHandler
            ? this._authenticationSuccessHandler
            : this._handleDefaultSignInSuccess;

        handlerFunction(redirect);
    };

    /**
     * @type {Function}
     * @param {String} redirect
     * By default, after sign in the page is refreshed or redirected to the provided URL
     */
    _handleDefaultSignInSuccess = redirect => {
        const reroute = redirect || this._authenticationSuccessParams.next;
        if (reroute) {
            window.location = reroute;
        } else {
            location.reload();
        }
    };

    /**
     * @type {Function}
     * @param {String} data
     * Opens the modal and sets contextual state from trigger
     */
    openLoginModal = data => {
        if (data.analyticTriggerLabel) {
            // sets the 'initiatingUIElement' dimension
            analyticsTrigger({ trigger: data.analyticTriggerLabel });
        }
        // TODO: make more flexible/replace logic with integration to siteStateController
        // Doesn't need to fire on desktop

        if (
            this.state.modal.modalState &&
            this.state.modal.modalState.contextName === 'aif'
        ) {
            eventDispatch.aifMobile.close();
        }

        // fires for social buttons inside of the social login modal
        if (!this.state.modal.alreadyOpen) {
            // Will be newly opening the modal
            if (data.refocusTarget) {
                this.refocusTarget = data.refocusTarget;
            }
            if (data.onSuccess) {
                this.authenticationSuccessHandler = data.onSuccess;
            }
            this.modalCloseHandler = data.onModalClose
                ? data.onModalClose
                : () => {};

            // login success values necessary for default successful authentication handling (redirect)
            if (_get(data, 'formData.loginForm.next', null)) {
                this.authenticationSuccessParameters = {
                    next: data.formData.loginForm.next,
                    nextParams: data.formData.loginForm.next_params,
                };
            }

            this.patchState({
                modal: {
                    modalState: {
                        contextName: data.context,
                        display: data.display,
                    },
                    triggerOpen: true,
                },
            });
        }
    };
}

const socialLoginStateController = new SocialLoginState();

export default socialLoginStateController;
