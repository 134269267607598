// Site colors
export const COLOR_PRIMARY = '#6504b5'; /* brand purple */
export const COLOR_PRIMARY_T1 = '#ddb4ff'; /* brand purple lighter */
export const COLOR_PRIMARY_T2 = '#ecd7fd'; /* brand purple lightest */
export const COLOR_PRIMARY_S1 = '#410078'; /* brand purple darker */
export const COLOR_PRIMARY_S2 = '#2e0152'; /* brand purple darkest */
export const COLOR_ALT = '#20ccf8'; /* cyan */
export const COLOR_ALT_S1 = '#004c81'; /* cyan dark */
export const COLOR_ALT_T1 = '#b0e9f8'; /* cyan light */
export const COLOR_DARK = '#4d4751'; /* default text color */
export const COLOR_BG = '#efeef1'; /* site background */
export const COLOR_STROKE = '#d2d1d3'; /* borders and dividers */

// Status colors
export const COLOR_NOTICE = '#ffea3b'; /* yellow */
export const COLOR_ERROR = '#cc1010'; /* red */
export const COLOR_ERROR_LIGHT = '#ffeded'; /* light red error box background */
export const COLOR_POSITIVE = '#18a718'; /* green */
export const COLOR_CAUTION = '#ffa500'; /* orange */

export const FULL_COLOR_LIST_HEX = {
    primary: '#6504b5',
    primaryT1: '#ddb4ff',
    primaryT2: '#ecd7fd',
    primaryS1: '#410078',
    primaryS2: '#2e0152',
    alt: '#20ccf8',
    altS1: '#004c81',
    altT1: '#b0e9f8',
    dark: '#4d4751',
    bg: '#efeef1',
    stroke: '#d2d1d3',
    notice: '#ffea3b',
    error: '#cc1010',
    errorLight: '#ffeded',
    positive: '#18a718',
    caution: '#ffa500',
    white: '#ffffff',
};

export const FULL_COLOR_LIST = Object.keys(FULL_COLOR_LIST_HEX);
