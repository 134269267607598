import React, { Fragment } from 'react';

/* eslint-disable max-len */
import { buildTcombField } from '../../../../../core/scripts/react-components/tcomb/templates';
import { buildFieldIdFromTcombLocals } from '../../../../../core/scripts/react-components/tcomb/util';
import {
    buildPasswordValidationRulesObject,
    isPasswordValid,
} from '../../../../../core/scripts/util/tcomb';
import LiveRegionAlert from '../../../../../core/scripts/react-components/specifics/LiveRegionAlert';
/* eslint-enable max-len */

import PasswordValidationPopup from '../../specifics/PasswordValidationPopup';

/**
 * @type {string}
 */
const VALID_PASSWORD_ARIA_LIVE_MSG = 'Valid: Password requirements met';

/**
 * @type {string}
 */
const INVALID_PASSWORD_ARIA_LIVE_MSG = 'Invalid: Password requirements not met';

/**
 * Get aria alert for password field
 * @param {string} value
 * @returns {string}
 */
function getAriaAlert(value) {
    let ariaAlert = '';

    if (value) {
        if (isPasswordValid(value)) {
            ariaAlert = VALID_PASSWORD_ARIA_LIVE_MSG;
        } else {
            ariaAlert = INVALID_PASSWORD_ARIA_LIVE_MSG;
        }
    }

    return ariaAlert;
}

/**
 * Build password validation field
 * @param {Object} locals
 * @returns {string}
 */
export default function buildTcombPasswordValidationField(locals) {
    const descriptionId = `${buildFieldIdFromTcombLocals(
        locals
    )}-password-description`;

    return (
        <Fragment>
            <div className="u-isVisuallyHidden" id={descriptionId}>
                To keep your account secure, your new password must be at least
                8 characters long and contain a minimum of 3 of the following: 1
                number, 1 lowercase letter, 1 uppercase letter or 1 special
                character.
            </div>
            <PasswordValidationPopup
                passwordIsValid={isPasswordValid(locals.value)}
                indicators={buildPasswordValidationRulesObject(locals.value)}
            >
                {({ togglePopup }) => (
                    <Fragment>
                        {buildTcombField(
                            locals,
                            {
                                onBlur: togglePopup,
                                onFocus: togglePopup,
                            },
                            {
                                ariaDescribedBy: [descriptionId],
                            }
                        )}
                    </Fragment>
                )}
            </PasswordValidationPopup>
            <LiveRegionAlert alert={getAriaAlert(locals.value)} />
        </Fragment>
    );
}
