import React, { Fragment } from 'react';
import SvgIcon from '../../../breeds/specifics/SvgIcon';
import siteStateController from '../../../../state-controllers/siteStateController';

/**
 * @type {Object}
 * @property jsx
 * @property onClick
 */
export const nullFooter = {
    /**
     * This markup is empty for when we don't want the default footer markup.
     * @type {Function}
     * @returns {jsx} jsx
     */
    jsx() {
        return null;
    },
};

/**
 * @type {Object}
 * @property jsx
 */
export const saveSearchLogInFooter = {
    jsx(handleRegisterClick) {
        return (
            <Fragment>
                <div className="hrTitle u-vr5x">
                    <div className="hrTitle-body">
                        <span className="txt m-txt_lg">{'or'}</span>
                    </div>
                </div>
                <p className="txt m-txt_xl u-vr4x u-textCenter">Create an account</p>
                <button
                    type="button"
                    onClick={handleRegisterClick}
                    className="btn btn_borderPrimary m-btn_iconLeft m-btn_normalCase m-btn_full"
                >
                    <span className="btn-icon">
                        <SvgIcon svgId="email" size="18" color="primary" />
                    </span>
                    Sign up with Email
                </button>
            </Fragment>
        );
    },
};
