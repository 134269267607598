import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { debounce, escapeSelector, parseParamsStringToObject, offset } from '../../../../core/scripts/util/dom';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';

import { CLASS_IS_ACTIVE, CLASS_IS_ERRORED, CLASS_IS_HIDDEN } from '../../constants/classes';
import {
    EV_UI_DEACTIVATE,
    EV_ASYNC_START,
    EV_ASYNC_END,
    EV_FORM_VAULES_UPDATE,
    EV_FORM_STATUS_UPDATE,
} from '../../constants/events';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-form';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    STATUS_TEXT: `[${ELEMENT_TAG_NAME}-status]`,
    GLOBAL_ERROR: `[${ELEMENT_TAG_NAME}-global-error]`,
    GLOBAL_ERROR_CONTENT: `[${ELEMENT_TAG_NAME}-global-error-content]`,
    GLOBAL_ERROR_LIST: `[${ELEMENT_TAG_NAME}-global-error-list]`,
    ERROR_LABEL: `[${ELEMENT_TAG_NAME}-error-label]`,
};

/**
 * Custom element base class that sets up some of the basic ajax form functionality and error
 * handling. You will have to create another form class that extends this class to setup
 * your api method and overwrite methods from this base element.
 *
 * See pfdc-registration-form for an example.
 *
 * REQUIRED BASE MARKUP:
 * ---
 * <pfdc-registration-form>
 *     <!-- Used for aria alert status messages -->
 *     <p pf-form-status class="u-isVisuallyHidden" role="alert" aria-live="polite"></p>
 *     <form id="Form" action="/action" method="post" novalidate>
 *         <div pf-form-global-error>
 *             <div pf-form-global-error-content
 *                 class="txt m-txt_colorError"
 *                 role="alert"
 *                 aria-live="assertive"></div>
 *         </div>
 *         <!-- Your fields go here... -->
 *         <!-- Add form-error-label after your fields -->
 *         <div pf-form-error-label class="txt txt_error m-txt_alignRight"></div>
 *         <button type="submit">Submit</button>
 *     </form>
 * </pfdc-registration-form>
 *
 * <pfdc-form sub-components no-ajax>...</pfdc-form>
 * [sub-components] sets up mutation observer, [no-ajax] don't add any of the field listeners
 * ---
 *
 * @extends PFElement
 */
export class PFDCFormElement extends PFElement {
    /**
     * Get all the control elements of this form
     * @return {Array} of the form elements
     */
    get controls() {
        return Array.from(this.form.elements).filter(control => control !== this.submitBtn);
    }

    /**
     * Get FormData instance of the data in this form
     * @return {FormData}
     */
    get formData() {
        return new FormData(this.form);
    }

    /**
     * Get the event string this form should use for reset
     * @return {boolean}
     */
    get resetOnEvent() {
        if (this.hasAttribute('reset-on') && this.getAttribute('reset-on') !== '') {
            return this.getAttribute('reset-on');
        }
    }

    get prependIdString() {
        const attr = this.getAttribute('prepend-id');
        return attr ? `${attr}_` : '';
    }

    /**
     * Initialize this component
     */
    onInit() {
        /**
         * Reference to form element, there can be only one
         * @type {element}
         */
        this.form = this.querySelector('form');

        console.log('this.form', this.form);

        /**
         * Reference to the form submit button
         * @type {element}
         */
        this.submitBtn = this.querySelector('button[type="submit"]') || this.querySelector('input[type="submit"]');

        console.log('this.submitBtn', this.submitBtn);

        /**
         * Reference to status text element
         * @type {element}
         */
        this.statusText = this.querySelector(ELEMENT_SELECTORS.STATUS_TEXT);

        /**
         * Reference to global error container element
         * @type {element}
         */
        this.globalError = this.querySelector(ELEMENT_SELECTORS.GLOBAL_ERROR);

        /**
         * Reference to global error content element
         * @type {element}
         */
        this.globalErrorContent = this.querySelector(ELEMENT_SELECTORS.GLOBAL_ERROR_CONTENT);

        /**
         * Reference to array of error label elements
         * @type {Array}
         */
        this.errorLabels = Array.from(this.querySelectorAll(ELEMENT_SELECTORS.ERROR_LABEL));

        if (this.globalError) {
            this.globalError.classList.add(CLASS_IS_HIDDEN);
        }

        // Reference to controls that are currently in some sort of async process
        this.activeAsyncComponents = [];

        // Setup listeners for ajax/fetch functionality of the form
        if (!this.hasAttribute('non-ajax')) {
            this.addEventListener('submit', this.onSubmit);
            this.addEventListener('change', this.onChanged);
            this.addEventListener('input', debounce(this.onInput.bind(this), 500));
        }
    }

    /**
     * Checks if there are subcomponents currently processing a request and disableds the form.
     */
    subComponentLoading() {
        if (this.activeAsyncComponents.length) {
            // A sub component is loading...
            this.disableForm();
        } else {
            // No sub components are loading...
            this.enableForm();
        }
    }

    /**
     * Disable form controls and special custom controls
     */
    disableForm() {
        if (this.hasAttribute('loading')) {
            return;
        }
        this.setAttribute('loading', '');
        this.setAttribute('aria-busy', 'true');
        this.submitBtn.setAttribute('disabled', '');
    }

    /**
     * Enable form controls and special custom controls
     */
    enableForm() {
        this.removeAttribute('loading');
        this.setAttribute('aria-busy', 'false');
        this.submitBtn.removeAttribute('disabled');
    }

    /**
     * Checks event detail object trigger to see if it has the right attributes
     * @param {Object} detail event detail object
     * @return {boolean}
     */
    canUpdateValues(detail) {
        return (
            detail.trigger.hasAttribute('data-form-values') &&
            detail.trigger.hasAttribute('data-form-target') &&
            detail.trigger.getAttribute('data-form-target') === `#${this.form.id}`
        );
    }

    /**
     * Finds fields that match trigger form values and updates their values
     * @param {Object} detail event detail object used to update form values
     */
    updateValues(detail) {
        if (!detail.trigger.hasAttribute('data-form-values')) {
            return;
        }
        const newValuesString = detail.trigger.getAttribute('data-form-values');
        const newValues = parseParamsStringToObject(newValuesString);
        Object.keys(newValues).forEach(key => {
            const field = this.form.querySelector(`[name="${key}"]`);
            field.value = newValues[key];
        });
    }

    /**
     * Updates the screen reader status text
     * @param {string} txt
     */
    updateStatus(txt) {
        try {
            this.statusText.innerText = txt;
        } catch (e) {
            return;
        }
    }

    /**
     * Handles the displaying of global error from response and builds a list of linked error items
     * @param {string} error
     * @param {Array} fields field data from a response
     */
    displayGlobalError(error, fields) {
        this.globalError.classList.add(CLASS_IS_HIDDEN);
        this.globalErrorContent.innerHTML = '';
        this.globalErrorContent.innerHTML = `<h3 tabindex="-1">${error}</h3>`;

        // Because ios is really really bad at focusing things
        this.globalError.classList.remove(CLASS_IS_HIDDEN);

        // Scroll to and focus errors
        setTimeout(() => {
            const elOffset = offset(this.globalErrorContent);
            window.scrollTo(0, elOffset.top);
            this.focusManager.focusFirstFocusable(this.globalErrorContent, false);
        }, 1000);

        this.globalError.classList.remove(CLASS_IS_HIDDEN);

        if (!fields) {
            return;
        }

        if (this.globalErrorList) {
            this.globalErrorList.innerHTML = '';
        } else {
            const ul = `<ul ${ELEMENT_SELECTORS.GLOBAL_ERROR_LIST.replace(/[[\]]/g, '')}></ul>`;
            this.globalErrorContent.insertAdjacentHTML('afterend', ul);
            this.globalErrorList = this.globalError.querySelector(ELEMENT_SELECTORS.GLOBAL_ERROR_LIST);
        }

        Object.keys(fields).forEach(key => {
            const field = this.querySelector(`[name="${key}"]`);

            // if the form element is a radio
            if (field.getAttribute('type') === 'radio') {
                // The form input must have an id without brackets
                const replaceNameAttr = field
                    .getAttribute('name')
                    .replace(/\[/g, '_')
                    .replace(/\]/g, '');
                const legendId = field.getAttribute('data-legend-id');
                const legend = this.form.querySelector(`#${legendId}`);
                const li = `
                <li id="${this.prependIdString}${key}_Error_Item">
                    <a href="#${legendId}" class="txt m-txt_colorError">
                        <span class="txt m-txt_underline m-txt_colorError">
                            ${legend.innerText}:
                        </span>
                        ${fields[key].join(' ')}
                    </a>
                </li>`;
                this.globalErrorList.insertAdjacentHTML('beforeend', li);
                return;
            }

            const id = field.id;
            const selector = `[for="${escapeSelector(id)}"]`;
            const label = this.form.querySelector(selector);
            const li = `
                <li id="${this.prependIdString}${key}_Error_Item">
                    <a href="#${id}" class="txt m-txt_colorError">
                        <span class="txt m-txt_underline m-txt_colorError">
                            ${label.innerText}:
                        </span>
                        ${fields[key].join(' ')}
                    </a>
                </li>`;
            this.globalErrorList.insertAdjacentHTML('beforeend', li);
        });
    }

    /**
     * Handles adding error state attributes and labels to controls in this form
     * @param {Object} fields field data object from a response or elsewhere
     */
    displayFieldErrors(fields) {
        // If there are no fields passed to this method run a manual check
        if (!fields) {
            this.checkFieldValidity();
            return;
        }

        Object.keys(fields).forEach(key => {
            const field = this.form.querySelector(`[name="${escapeSelector(key)}"]`);

            // if the form element is a radio
            if (field.getAttribute('type') === 'radio') {
                const fieldsetId = this.form.querySelector(`#${field.getAttribute('data-fieldset-id')}`);
                const errorLabel = fieldsetId.querySelector(`${ELEMENT_SELECTORS.ERROR_LABEL}`);
                fieldsetId.setAttribute('aria-invalid', true);
                fieldsetId.classList.add(CLASS_IS_ERRORED);
                errorLabel.innerText = fields[key].join(' ');
                return;
            }

            const errorSelector = `[name="${escapeSelector(key)}"] ~ ${ELEMENT_SELECTORS.ERROR_LABEL}`;
            const errorLabel = this.form.querySelector(errorSelector);
            field.setAttribute('aria-invalid', true);
            field.classList.add(CLASS_IS_ERRORED);
            errorLabel.innerText = fields[key].join(' ');
        });
    }

    /**
     * Remove error state from given field
     * @param {element} field
     */
    removeFieldError(field) {
        // if the form element is a radio
        if (field.getAttribute('type') === 'radio') {
            const fieldsetId = this.form.querySelector(`#${field.getAttribute('data-fieldset-id')}`);
            const errorLabel = fieldsetId.querySelector(`${ELEMENT_SELECTORS.ERROR_LABEL}`);

            // If the radio group does not contain the u-isErrored class
            if (!fieldsetId.classList.contains(CLASS_IS_ERRORED)) {
                return;
            }

            fieldsetId.setAttribute('aria-invalid', false);
            fieldsetId.classList.remove(CLASS_IS_ERRORED);
            errorLabel.innerHTML = '';

            if (this.globalErrorList && this.globalErrorList.children.length) {
                this.removeGlobalErrorListItem(field);
            }

            return;
        }

        if (!field.classList.contains(CLASS_IS_ERRORED)) {
            return;
        }

        const fieldName = field.getAttribute('name');
        const errorSelector = `[name="${escapeSelector(fieldName)}"] ~ ${ELEMENT_SELECTORS.ERROR_LABEL}`;
        const errorLabel = this.form.querySelector(errorSelector);
        errorLabel.innerHTML = '';

        field.removeAttribute('aria-invalid', true);
        field.classList.remove(CLASS_IS_ERRORED);

        if (this.globalErrorList && this.globalErrorList.children.length) {
            this.removeGlobalErrorListItem(field);
        }
    }

    /**
     * Remove global error state list items for a given field or hide global error
     * @param {element} field
     */
    removeGlobalErrorListItem(field) {
        if (!field) {
            return;
        }

        const fieldName = field.getAttribute('name');
        const selector = `#${this.prependIdString}${escapeSelector(fieldName)}_Error_Item`;
        const errorItem = this.globalErrorList.querySelector(selector);
        errorItem.remove();

        if (!this.globalErrorList.children.length) {
            this.removeGlobalError();
        }
    }

    /**
     * Remove global error
     */
    removeGlobalError() {
        this.globalError.classList.add(CLASS_IS_HIDDEN);
    }

    /**
     * Reset the form
     */
    resetForm() {
        this.form.reset();
        this.controls.forEach(control => {
            if (control.classList.contains(CLASS_IS_ERRORED)) {
                this.removeFieldError(control);
            }
            if (control.tagName === 'INPUT') {
                control.setAttribute('value', '');
            }
        });
    }

    /**
     * Run various client side checks on this forms fields
     */
    checkFieldValidity() {
        this.fieldErrors = {};
        this.checkRequiredFields();
        this.displayFieldErrors(this.fieldErrors);
    }

    /**
     * Process required fields, add to fieldErrors object
     */
    checkRequiredFields() {
        const requiredErrorTxt = 'This value is required.';

        //log out each control
        console.log({ controls: this.controls });

        const requiredFields = this.controls.filter(control => control.required === true && control.value === '');
        requiredFields.forEach(field => {
            this.fieldErrors[field.name] = [requiredErrorTxt];
        });
    }

    /**
     * Triggered before the form data is submitted
     */
    async beforeSubmission() {
        this.submitBtn.disabled = true;
        this.submitBtn.classList.add(CLASS_IS_ACTIVE);
    }

    /**
     * Triggered after the form data is submitted
     */
    afterSubmission() {
        this.submitBtn.disabled = false;
        this.submitBtn.classList.remove(CLASS_IS_ACTIVE);
    }

    /**
     * Handles successful response
     * @param {Object} response from response
     */
    handleSubmissionSuccess(response) {
        if (response.redirect) {
            window.location.replace(response.redirect);
            return;
        }

        if (response.success) {
            window.location.reload();
        }
    }

    /**
     * Handles an error response
     * @param {Object} response
     */
    handleSubmissionError(response) {
        if (response.redirect) {
            window.location = response.redirect;
            return;
        }

        this.form.setAttribute('aria-invalid', true);
        if (response.errors) {
            this.displayGlobalError(response.errors.global[0], response.errors.fields);
            this.displayFieldErrors(response.errors.fields);
        }
    }

    submitToEndpoint() {
        throw new Error('Subclass must define a submitToEndpoint method');
    }

    /**
     * Handles the submission of the form with some hooks
     * @param {Object} ev event object
     */
    async onSubmit(ev) {
        ev.preventDefault();
        await this.beforeSubmission();
        try {
            const response = await this.submitToEndpoint(this.formData);
            response.formData = this.formData;
            this.handleSubmissionSuccess(response);
        } catch (error) {
            this.handleSubmissionError(error);
        }
        this.afterSubmission();
    }

    /**
     * Handles change events
     * @param {Object} ev event object
     */
    onChanged(ev) {
        this.removeFieldError(ev.target);
    }

    /**
     * Handles input events
     * @param {Object} ev event object
     */
    onInput(ev) {
        this.removeFieldError(ev.target);
    }

    /**
     * Handles update events from the app level
     * @param {Object} ev event object
     */
    onUpdated(ev) {
        const { detail } = ev;
        if (detail.type === EV_FORM_VAULES_UPDATE && this.canUpdateValues(detail)) {
            this.updateValues(detail);
        }
        if (detail.type === EV_FORM_STATUS_UPDATE && detail.statusText !== '') {
            this.updateStatus(detail.statusText);
        }
        if (detail.type === this.resetOnEvent && this.resetOnEvent !== undefined) {
            this.resetForm();
        }
        if (detail.type === 'form.async.component.start') {
            if (detail.form === this.form) {
                const detailProps = ['asyncComponent', 'triggeringComponent'];
                let i = 0;
                for (i; i < detailProps.length; i++) {
                    const el = detail[detailProps[i]];
                    if (el) {
                        this.activeAsyncComponents.push(el);
                        if (el.tagName.endsWith('-SELECT')) {
                            el.setAttribute('pf-disabled', '');
                        } else {
                            el.setAttribute('disabled', '');
                        }
                    }
                }
                this.subComponentLoading();
            }
        }
        if (detail.type === 'form.async.component.end') {
            if (detail.form === this.form) {
                const detailProps = ['asyncComponent', 'triggeringComponent'];
                let i = 0;
                for (i; i < detailProps.length; i++) {
                    const el = detail[detailProps[i]];
                    if (el && this.activeAsyncComponents.includes(el)) {
                        this.activeAsyncComponents.splice(this.activeAsyncComponents.indexOf(el), 1);
                        if (!this.activeAsyncComponents.includes(el)) {
                            if (el.tagName.endsWith('-SELECT')) {
                                el.removeAttribute('pf-disabled');
                            } else {
                                el.removeAttribute('disabled');
                            }
                        }
                    }
                }
                this.subComponentLoading();
            }
        }
    }
}

export default PFDCFormElement;
