/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import { withStateMachine, State } from 'react-automata';
import { statechart, EVENT, ACTION, INITIAL_STATES, STATE_PATHS } from './statechart';
import PropTypes from 'prop-types';

// State controller for social APIs
import socialLoginStateController from '../SocialLogin/controller/SocialLoginStateController';

// Forms
import LoginForm from '../LoginForm';
import RegistrationForm from '../RegistrationForm';
import { FIELDS as REGISTRATION_FIELDS } from '../RegistrationForm/formType';
import SocialRegistrationForm from '../SocialRegistrationForm';
import PasswordConfirmationForm from '../PasswordConfirmationForm';

// Views
import SignUpLayout from './layouts/signUp';
import LoginLayout from './layouts/login';
import RegistrationLayout from './layouts/registration';
import ReturningLayout from './layouts/returning';
import GenericErrorLayout from './layouts/genericError';
import SocialLinkLayout from './layouts/social/link';
import SocialReauthenticateLayout from './layouts/social/reauthenticate';
import SocialRedirectLayout from './layouts/social/redirect';
import SocialCompleteRegistrationLayout from './layouts/social/completeRegistration';
import SocialLoading from './layouts/social/loading';

// Constants
import { CONTEXT, CONTEXT_NAMES, SIGN_UP_COPY, TRIGGER_ELEMENTS } from './utilities/copyMap';

// Components
import SocialSignInButton from '../SocialLogin';

// Analytics
import { analyticsConsumer007, analyticsConsumer008, analyticsConsumer014 } from '../../../analytics/dotcom';

// Methods
import { focusFirstFocusable } from '../../../../../core/scripts/lib/focusManager/index';

/**
 * @class LoginLauncher
 * @extends Component
 */
class LoginLauncher extends Component {
    /* *******************************************
     * Class Properties
     ********************************************/

    _composeStateObject() {
        return {
            activeUserData: socialLoginStateController.state.user,
            socialAuthenticationStep: socialLoginStateController.state.step,
            provider: socialLoginStateController.state.activeProvider,
            token: socialLoginStateController.state.token,
            hasReturningUser: socialLoginStateController.hasReturningUser,
            returningUserSignOut: socialLoginStateController.returningUserSignOut,
            returningUsers: socialLoginStateController.state.returningUsers,
            returningUserData: socialLoginStateController.returningUserData,
        };
    }

    /**
     * @type {Object}
     */
    state = this._composeStateObject();

    _updateState() {
        this.setState(this._composeStateObject());
    }

    _unsubscribeFunctions = [];

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        displayInitial: PropTypes.oneOf(INITIAL_STATES),
        isModal: PropTypes.bool,
        loginForm: PropTypes.object,
        registrationForm: PropTypes.object,
        contextName: PropTypes.oneOf(CONTEXT_NAMES),
        toggleNoClose: PropTypes.func,
        countries: PropTypes.array,
        userCountryCode: PropTypes.string,
        forgotPasswordUrl: PropTypes.string,
        onSignInSuccess: PropTypes.func,
        onCancel: PropTypes.func,
        analyticTriggerLabel: PropTypes.oneOf(Object.keys(TRIGGER_ELEMENTS)),
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        displayInitial: EVENT.LOG_IN,
        contextName: CONTEXT.GENERIC,
        toggleNoClose: () => {},
        onSignInSuccess: redirect => {
            socialLoginStateController.handleAuthenticationSuccess(redirect);
        },
    };

    /**
     * @type {Ref}
     */
    googleButton = React.createRef();
    facebookButton = React.createRef();

    /**
     * @type {Object}
     */
    componentView = React.createRef();

    /* *******************************************
     * Getters
     ********************************************/

    get loginFormProps() {
        if (!this.props.loginForm) {
            return {
                forgotPasswordHref: '',
                value: {
                    login_path: '',
                    next: '',
                    next_params: '',
                },
            };
        }
        const { ['forgot_password_url']: forgotPasswordHref, login_path, next, next_params } = this.props.loginForm;

        return {
            forgotPasswordHref,
            value: {
                login_path,
                next,
                next_params,
            },
        };
    }

    get registrationFormProps() {
        if (!this.props.registrationForm) {
            return {
                countries: this.props.countries,
                userCountryCode: this.props.userCountryCode,
                source: this.props.contextName,
                value: {
                    [REGISTRATION_FIELDS.NEXT]: null,
                    [REGISTRATION_FIELDS.NEXT_PARAMS]: null,
                },
            };
        }

        const { next, next_params } = this.props.registrationForm;

        return {
            countries: this.props.countries,
            userCountryCode: this.props.userCountryCode,
            source: this.props.contextName,
            value: {
                [REGISTRATION_FIELDS.NEXT]: next,
                [REGISTRATION_FIELDS.NEXT_PARAMS]: next_params,
            },
        };
    }

    get socialRegistrationProps() {
        return {
            ...this.state.activeUserData,
            token: this.state.token,
            provider: this.state.provider,
            source: this.props.contextName,
        };
    }

    get socialLinkFormProps() {
        return {
            ...this.state.activeUserData,
            token: this.state.token,
            provider: this.state.provider,
        };
    }

    get machineStateLabel() {
        let currentMachineState = this.props.machineState.value;

        if (typeof currentMachineState === 'object') {
            // Social substate
            currentMachineState = currentMachineState.social;
        }
        return currentMachineState;
    }

    get analyticParams() {
        const view = this.machineStateLabel;
        const provider = this.state.provider;
        const context = this.props.contextName;
        const analyticTriggerLabel = this.props.analyticTriggerLabel;

        return {
            provider,
            view,
            context,
        };
    }

    /* *******************************************
     * Lifecycle
     ********************************************/

    componentDidMount() {
        // subscribe to all SocialLoginStateController state changes to keep current
        this._unsubscribeFunctions.push(
            socialLoginStateController.subscribe(payload => {
                this._updateState();
            })
        );

        // subscribe to step changes: indicates what action needs to be taken to complete social login/registration
        this._unsubscribeFunctions.push(
            socialLoginStateController.subscribe(
                payload => {
                    if (socialLoginStateController.state.step !== null) {
                        this.handleStepChange(socialLoginStateController.state.step);
                    }
                },
                ['step']
            )
        );

        // subscribe to successful authentication event
        this._unsubscribeFunctions.push(
            socialLoginStateController.subscribe(
                payload => {
                    this.handleAuthenticationSuccess({}, { redirect: payload.modifications.authStatus.redirect });
                },
                ['authStatus']
            )
        );

        // Returning user logic
        if (this.state.hasReturningUser && this.state.returningUserData && this.props.contextName !== 'save-search') {
            let returningProvider;

            // If a returing user has both accounts associated,
            // UX favors FB for name and img src
            if (this.state.returningUsers.facebook) {
                returningProvider = 'facebook';
            } else if (this.state.returningUsers.google) {
                returningProvider = 'google';
            }
            socialLoginStateController.activeProvider = returningProvider;

            this.props.transition(EVENT.RETURNING);
        } else {
            this.props.transition(this.props.displayInitial);
        }
    }

    componentWillUnmount() {
        this._unsubscribeFunctions.forEach(unsubscribeFn => unsubscribeFn());
        socialLoginStateController.reset();
    }

    /* *******************************************
     * Events
     ********************************************/

    /**
     * Routes UI behavior when the SocialLoginStateController 'step' value progresses
     * @param {String} step
     */
    handleStepChange = step => {
        switch (step) {
            case 'register':
                this.handleCompleteRegistration();
                break;
            case 'redirect':
                this.handleSocialRedirect();
                break;
            case 'reauthenticate':
                this.handleSocialReauthenticate();
                break;
            case 'link':
                this.handleSocialLink();
                break;
            case 'loading':
                this.handleSocialPromptClose();
                break;
            default:
                this.handleGenericError(socialLoginStateController.error);
        }
    };

    /**
     * 'Go to registration view' event
     * @param {Function} analyticsEvent
     */
    handleRegistrationClick = analyticsEvent => {
        // Analytics: sign up with email prompt
        analyticsEvent({
            provider: 'petfinder',
            ...this.analyticParams,
        });
        setTimeout(() => {
            this.props.transition(EVENT.REGISTER);
        }, 0);
    };

    /**
     * 'Go to sign up view' event
     * @param {Function} analyticsEvent
     */
    handleSignUpClick = analyticsEvent => {
        analyticsEvent({
            provider: 'petfinder',
            ...this.analyticParams,
        });

        setTimeout(() => {
            /**
             * If altEmailBtn = true,
             *  the initial email button on SIGN_UP state takes user to LOGIN instead of REGISTER.
             *
             * Therefore, here we make the Sign Up button on LOGIN state take user to REGISTER instead of to SIGN_UP.
             *
             * Otherwise, in the altEmailBtn = true scenario, users will have no way to get to REGISTER -  they will keep cycling between LOGIN and the altEmailBtn version of SIGN_UP, (neither offers a path to REGISTER).
             */
            if (SIGN_UP_COPY[this.props.contextName] && SIGN_UP_COPY[this.props.contextName].altEmailBtn) {
                this.props.transition(EVENT.REGISTER);
            } else {
                this.props.transition(EVENT.SIGN_UP);
            }
        }, 0);
    };

    /**
     * 'Go to log in view' event
     * @param {Function} analyticsEvent
     */
    handleLoginClick = analyticsEvent => {
        analyticsEvent({
            provider: 'petfinder',
            ...this.analyticParams,
        });
        setTimeout(() => {
            this.props.transition(EVENT.LOG_IN);
        });
    };

    /**
     * 'Prompt FB user with no email (mobile phone only) to use a different method' event
     */
    handleSocialRedirect = () => {
        this.props.transition(EVENT.REDIRECT);
    };

    /**
     * 'Prompt FB user with no email (denied permission) to give us access
     */
    handleSocialReauthenticate = () => {
        this.props.transition(EVENT.REAUTHENTICATE);
    };

    /**
     * 'Prompt social user to link PF account' event
     */
    handleSocialLink = () => {
        this.props.transition(EVENT.LINK);
    };

    /**
     * 'Third-party social window closed by third-party' event
     */
    handleSocialPromptClose = () => {
        this.props.transition(EVENT.SOCIAL);
    };

    /**
     * Provide form details for 'complete social registration'
     */
    handleCompleteRegistration = () => {
        this.props.transition(EVENT.COMPLETE_REGISTRATION);
    };

    /**
     * 'Something non-specific went wrong with the APIs' event
     *  @param {Object} customError
     */
    handleGenericError = customError => {
        const provider = this.state.provider;

        analyticsConsumer008({
            provider,
            customError,
            ...this.analyticParams,
        });

        // analytic event gets lost without this
        setTimeout(() => {
            this.props.transition(EVENT.GENERIC_ERROR);
        });
    };

    /**
     * Social sign out event
     */
    handleSignOut = () => {
        analyticsConsumer014({
            ...this.analyticParams,
        });
        setTimeout(() => {
            this.props.transition(EVENT.LOG_IN);

            if (this.state.returningUsers.facebook) {
                this.state.returningUserSignOut.facebook();
            }

            if (this.state.returningUsers.google) {
                this.state.returningUserSignOut.google();
            }
        }, 0);
    };

    /**
     * Successfully linked accounts
     * @param {Object} value
     * @param {Object} props
     */
    handleLinkingSuccess = (value, props) => {
        const provider = this.state.provider;

        analyticsConsumer007({
            ...this.analyticParams,
            provider,
        });

        setTimeout(() => {
            this.props.onSignInSuccess(props.redirect);
        }, 0);
    };

    /**
     * Successfully signed in
     * @param {Object} value
     * @param {Object} props
     */
    handleAuthenticationSuccess = (value, props) => {
        // analaytics
        const provider = this.state.provider;

        analyticsConsumer007({
            ...this.analyticParams,
            provider,
            flow: 'log in',
        });

        // analytic event gets lost without this
        setTimeout(() => {
            this.props.onSignInSuccess(props.redirect);
        }, 0);
    };

    /**
     * Successfully registered a new account (social or email)
     * @param {Object} value
     * @param {Object} props
     */
    handleRegistrationSuccess = (value, props) => {
        const provider = this.state.provider;

        analyticsConsumer007({
            ...this.analyticParams,
            provider,
            flow: 'sign up',
        });

        // analytic event gets lost without this
        setTimeout(() => {
            this.props.onSignInSuccess(props.redirect);
        });
    };

    /**
     * Clicks forgot password
     * @param {Function} analyticsEvent
     */
    handleForgotPasswordClick = analyticsEvent => {
        analyticsEvent({
            ...this.analyticParams,
        });

        // analytic event gets lost without this
        setTimeout(() => {
            window.open(this.props.forgotPasswordUrl); // open link in new window
        }, 0);
    };

    /**
     * Form errored
     * @param {String} errors
     */
    handleFormError = errors => {
        const provider = this.state.provider;

        analyticsConsumer008({
            provider,
            errors,
            ...this.analyticParams,
        });
    };

    /**
     * Toggle whether you can exit the modal or not
     */
    toggleNoClose = () => {
        if (!this.props.isModal) {
            return;
        }
        this.props.toggleNoClose();
    };

    /* *******************************************
     * Actions
     ********************************************/
    /**
     * @private
     */
    [ACTION.FOCUS_FIRST] = () => {
        setTimeout(() => {
            // Focus the first focusable element
            focusFirstFocusable(this.componentView.current);
        }, 500);
    };

    /* *******************************************
     * Render Methods
     ********************************************/

    /**
     * Render google button
     * @param {Object} props
     * @returns {GoogleButton}
     */
    renderGoogleButton = props => {
        return (
            <SocialSignInButton
                provider="google"
                onSignInSuccess={this.handleAuthenticationSuccess}
                onLinkAccount={this.handleSocialLink}
                onSocialPromptClose={this.handleSocialPromptClose}
                onCompleteRegistration={this.handleCompleteRegistration}
                onGenericError={this.handleGenericError}
                ref={this.googleButton}
                analyticParams={this.analyticParams}
                {...props}
            />
        );
    };

    /**
     * Render facebook button
     * @param {Object} props
     * @returns {FacebookButton}
     */
    renderFacebookButton = props => {
        return (
            <SocialSignInButton
                {...props}
                provider="facebook"
                onSignInSuccess={this.handleAuthenticationSuccess}
                onLinkAccount={this.handleSocialLink}
                onSocialPromptClose={this.handleSocialPromptClose}
                onCompleteRegistration={this.handleCompleteRegistration}
                onGenericError={this.handleGenericError}
                ref={this.facebookButton}
                onEmailDeny={this.handleSocialReauthenticate}
                onEmailMissing={this.handleSocialRedirect}
                analyticParams={this.analyticParams}
            >
                {props && props.customStyle ? props.customStyle() : null}
            </SocialSignInButton>
        );
    };

    render() {
        const {
            SIGN_UP,
            LOGIN,
            REGISTER,
            RETURNING,
            GENERIC_ERROR,
            SOCIAL_LOADING,
            SOCIAL_LINK,
            SOCIAL_REAUTHENTICATE,
            SOCIAL_REDIRECT,
            SOCIAL_COMPLETE_REGISTRATION,
        } = STATE_PATHS; // Render value paths from statechart

        return (
            <div ref={this.componentView}>
                {/* Initial States */}
                <State is={SIGN_UP}>
                    <SignUpLayout
                        onLoginClick={this.handleLoginClick}
                        onRegistrationClick={this.handleRegistrationClick}
                        renderGoogleButton={this.renderGoogleButton}
                        renderFacebookButton={this.renderFacebookButton}
                        contextName={this.props.contextName}
                        onCancel={this.props.onCancel}
                    />
                </State>
                <State is={LOGIN}>
                    <LoginLayout
                        onSignUpClick={this.handleSignUpClick}
                        onRegistrationClick={this.handleRegistrationClick}
                        forgotPasswordHref={this.props.forgotPasswordUrl}
                        onForgotPasswordClick={this.handleForgotPasswordClick}
                        renderGoogleButton={this.renderGoogleButton}
                        contextName={this.props.contextName}
                        renderFacebookButton={this.renderFacebookButton}
                    >
                        <LoginForm
                            {...this.loginFormProps}
                            onSubmissionSuccess={this.handleAuthenticationSuccess}
                            onSubmissionError={this.handleFormError}
                            forgotPasswordHref={this.props.forgotPasswordUrl}
                        />
                    </LoginLayout>
                </State>
                <State is={REGISTER}>
                    <RegistrationLayout
                        renderGoogleButton={this.renderGoogleButton}
                        renderFacebookButton={this.renderFacebookButton}
                    >
                        <Fragment>
                            <RegistrationForm
                                {...this.registrationFormProps}
                                onLoginClick={this.handleLoginClick}
                                onSubmissionSuccess={this.handleRegistrationSuccess}
                                onSubmissionError={this.handleFormError}
                            />
                        </Fragment>
                    </RegistrationLayout>
                </State>
                <State is={RETURNING}>
                    <ReturningLayout
                        onLoginClick={this.handleLoginClick}
                        onRegistrationClick={this.handleRegistrationClick}
                        returningUserData={this.state.returningUserData}
                        renderGoogleButton={this.renderGoogleButton}
                        renderFacebookButton={this.renderFacebookButton}
                        returningUsers={this.state.returningUsers}
                        onSignOut={this.handleSignOut}
                    />
                </State>

                {/* Social States */}
                <State is={SOCIAL_LOADING}>
                    <SocialLoading />
                </State>
                <State is={SOCIAL_LINK}>
                    <SocialLinkLayout
                        email={this.state.activeUserData.email}
                        provider={this.state.provider}
                        forgotPasswordHref={this.props.forgotPasswordUrl}
                        onForgotPasswordClick={this.handleForgotPasswordClick}
                    >
                        <PasswordConfirmationForm
                            {...this.socialLinkFormProps}
                            submitLabel="Link my accounts"
                            forgotPasswordHref={this.props.forgotPasswordUrl}
                            onSubmissionSuccess={this.handleLinkingSuccess}
                            onSubmissionError={this.handleFormError}
                        />
                    </SocialLinkLayout>
                </State>
                <State is={SOCIAL_REAUTHENTICATE}>
                    <SocialReauthenticateLayout
                        onCancel={this.props.onCancel}
                        onSocialReauthenticate={this.handleSocialReauthenticate}
                        renderFacebookButton={this.renderFacebookButton}
                    />
                </State>
                <State is={SOCIAL_REDIRECT}>
                    <SocialRedirectLayout
                        renderGoogleButton={this.renderGoogleButton}
                        onRegisterClick={this.handleRegistrationClick}
                        renderFacebookButton={this.renderFacebookButton}
                    />
                </State>
                <State is={SOCIAL_COMPLETE_REGISTRATION}>
                    <SocialCompleteRegistrationLayout>
                        <Fragment>
                            <SocialRegistrationForm
                                {...this.registrationFormProps}
                                {...this.socialRegistrationProps}
                                onSubmissionSuccess={this.handleRegistrationSuccess}
                                onSubmissionError={this.handleFormError}
                            />
                        </Fragment>
                    </SocialCompleteRegistrationLayout>
                </State>
                <State is={GENERIC_ERROR}>
                    <GenericErrorLayout onBackToLogin={this.handleLoginClick} />
                </State>
            </div>
        );
    }
}

export default withStateMachine(statechart)(LoginLauncher);
