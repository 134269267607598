import AnimalBreedsPropertiesModel from '../AnimalBreedsPropertiesModel';

export default class CatBreedPropertiesModel extends AnimalBreedsPropertiesModel {
    get defaultData() {
        return {
            friendliness: '',
            groomingRequirements: '',
            vocality: '',
            playfulness: '',
            activityLevel: '',
        };
    }

    /**
     * Build animal breeds property data for playfulness
     * @method playfulness
     *
     * @returns {Object}
     */
    playfulness() {
        return this._buildProperty(
            'breedSearchPlayfulness',
            69,
            this._filterToValue(this._data.playfulness, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for activity level
     * @method activityLevel
     *
     * @returns {Object}
     */
    activityLevel() {
        return this._buildProperty(
            'breedSearchActivity',
            70,
            this._filterToValue(this._data.activityLevel, this.nullText)
        );
    }
}
