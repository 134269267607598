import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../breeds/specifics/SvgIcon';

class FilterTag extends Component {
    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        mixClass: '',
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        item: PropTypes.shape({
            filterName: PropTypes.string,
            label: PropTypes.string,
            longLabel: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        }).isRequired,
        mixClass: PropTypes.string,
        onFilterRemove: PropTypes.func.isRequired,
    };

    /**
     * Handles a click on an individual search filter delete button.
     *
     * @param {*} e - Event
     * @private
     * @memberof PetSearchFilterList
     */
    _handleFilterRemove = e => {
        const { filterName, value } = this.props.item;

        this.props.onFilterRemove(filterName, value);
    };

    render() {
        return (
            <div className={`filterTag ${this.props.mixClass}`}>
                <span
                    className="filterTag-title"
                    data-test="Search_Applied_Filter_List"
                >
                    {this.props.item.longLabel}
                </span>
                <button
                    className="filterTag-removeBtn"
                    aria-label={`Remove filter ${this.props.item.filterName}: ${
                        this.props.item.value
                    }`}
                    onClick={this._handleFilterRemove}
                >
                    Remove filter
                    <SvgIcon color="white" size="xs" svgId="close" />
                </button>
            </div>
        );
    }
}

export default FilterTag;
