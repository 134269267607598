
import Mixins from '../Mixins';
import MixinBase from './MixinBase';
import Utils from '../Utils';

export default class HideMixin extends MixinBase {
    static _attributeName = 'mix-hide';

    constructor(ele, value) {
        super(ele, value);

        const result = Utils.HTMLElementUtils.findElementContainingAddress(value, ele, true);

        if (result.element && result.value) {
            this._ele.innerHTML = '';
        }
    }
}
