import BaseAnalyticPropertiesModel from '../BaseAnalyticPropertiesModel';

export default class UserLoginRegistrationPropertiesModel extends BaseAnalyticPropertiesModel {
    get defaultData() {
        return {
            authenticationStatus: '',
            loginProvider: '',
            initiatingSite: '',
            initiatingUI: '',
            pointOfAbandonment: '',
            errorMessage: '',
            currentScreen: '',
        };
    }

    /**
     * Build user login and registration property for authentication status
     *
     * @returns {Object}
     */
    authenticationStatus() {
        return this._buildProperty(
            'authenticationStatus',
            41,
            this._data.authenticationStatus || this.nullText
        );
    }

    /**
     * Build user login and registration property for login provider
     *
     * @returns {Object}
     */
    loginProvider() {
        return this._buildProperty(
            'loginProvider',
            78,
            this._data.loginProvider || this.nullText
        );
    }

    /**
     * Build user login and registration property for initiating site feature
     *
     * @returns {Object}
     */
    initiatingSite() {
        return this._buildProperty(
            'initiatingSiteFeature',
            79,
            this._data.initiatingSite || this.nullText
        );
    }

    /**
     * Build user login and registration property for initiating point of abandonment
     *
     * @returns {Object}
     */
    initiatingUI() {
        return this._buildProperty(
            'initiatingUIElement',
            80,
            this._data.initiatingUI || this.nullText
        );
    }

    /**
     * Build user login and registration property for initiating point of abandonment
     *
     * @returns {Object}
     */
    pointOfAbandonment() {
        return this._buildProperty(
            'pointOfAbandonment',
            81,
            this._data.pointOfAbandonment || this.nullText
        );
    }

    /**
     * Build user login and registration property for error message
     *
     * @returns {Object}
     */
    errorMessage() {
        return this._buildProperty(
            'errorMessage',
            82,
            this._data.errorMessage || this.nullText
        );
    }

    /**
     * Build user login and registration property for current screen
     *
     * @returns {Object}
     */
    currentScreen() {
        return this._buildProperty(
            'currentScreen',
            83,
            this._data.currentScreen || this.nullText
        );
    }
}
