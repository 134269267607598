import APIService from './APIService';
import userMeAPIService from './userMeAPIService';
import CountryCollection from '../models/CountryCollection';
import LocationCollection from '../models/LocationCollection';
import LocationModel from '../models/LocationModel';
import StateCollection from '../models/StateCollection';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

class GeographyAPIService extends APIService {
    static url = '/v2/geography/';

    _cachedCountries = null;
    _cachedStatesKeyedByCountryCode = null;

    /**
     * @param {string} query
     * @returns {LocationCollection}
     */
    async searchForLocations(query) {
        const userMe = await userMeAPIService.getUserMe();
        const userMeLocation = userMe.location;

        const options = {
            params: {
                q: query,
                lat: userMeLocation.lat,
                lng: userMeLocation.lng,
            },
            method: 'GET',
            urlAppend: 'search/',
            lateResponseCancelling: true,
        };

        try {
            const apiResponse = await this.queryAndProcess(options);
            return LocationCollection.fromServer(apiResponse.data.locations);
        } catch (error) {
            throw error;
        }
    }

    async findAPlace(lat, lng) {
        const options = {
            params: { lat, lng },
            method: 'GET',
            urlAppend: 'find/',
            lateResponseCancelling: true,
        };

        try {
            const apiResponse = await this.queryAndProcess(options);
            return LocationModel.fromServer(apiResponse.data.location);
        } catch (error) {
            throw error;
        }
    }

    /**
     * @throws {APIResponse}
     * @returns {CountryCollection}
     */
    async getCountries() {
        if (!_isEmpty(this._cachedCountries)) {
            return this._cachedCountries;
        }

        const options = {
            method: 'GET',
            urlAppend: 'countries/',
        };

        try {
            const response = await this.queryAndProcess(options);

            if (response.error) {
                throw response;
            }

            const countryCollection = new CountryCollection(response.data.countries);

            this._cachedCountries = countryCollection;
            return countryCollection;
        } catch (error) {
            throw error;
        }
    }

    /**
     * @param {string} countryCode
     * @throws {APIResponse}
     * @returns {Object}
     */
    async getStates(countryCode) {
        const cachedStates = _get(this._cachedStatesKeyedByCountryCode, `${countryCode}`, false);

        if (cachedStates) {
            return {
                countryCode,
                states: cachedStates,
            };
        }

        const options = {
            method: 'GET',
            urlAppend: `countries/${countryCode}/states/`,
            params: {
                limit: '200', // Set this high so that we get everything, this endpoint is limited
            },
        };

        try {
            const response = await this.queryAndProcess(options);

            if (response.error) {
                throw response;
            }

            const states = new StateCollection(response.data.states);

            return {
                countryCode, // Sending country code back so things can be done with it
                states,
            };
        } catch (error) {
            throw error;
        }
    }

    /**
     * @throws {APIResponse}
     * @returns {Object}
     */
    async getStatesKeyedByCountryCode() {
        if (!_isEmpty(this._cachedStatesKeyedByCountryCode)) {
            return this._cachedStatesKeyedByCountryCode;
        }

        try {
            const statesKeyedByCountryCode = {};
            const countries = await this.getCountries();
            await Promise.all(countries.collection.map(country => this.getStates(country.code)))
                .then(responses => {
                    responses.forEach(response => {
                        statesKeyedByCountryCode[response.countryCode] = response.states;
                    });
                });
            this._cachedStatesKeyedByCountryCode = statesKeyedByCountryCode;
            return statesKeyedByCountryCode;
        } catch (error) {
            throw error;
        }
    }
}

const geographyAPIService = new GeographyAPIService();
export default geographyAPIService;
