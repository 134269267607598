import AnimalBreedsEventModel from '../AnimalBreedsEventModel';
import DogBreedPropertiesModel from './DogBreedPropertiesModel';
import _get from 'lodash/get';

export default class DogBreedEventModel extends AnimalBreedsEventModel {
    get defaultAnalyticProperties() {
        return {
            size: true,
            energyLevel: true,
            friendliness: true,
            easeOfTraining: true,
            groomingRequirements: true,
            vocality: true,
            exerciseRequirements: true,
            affectionNeeds: true,
        };
    }

    /**
     * prepareEvent
     *
     * @static
     * @param {Object} eventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {DogBreedEventModel}
     */
    static prepareEvent(
        eventData,
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const model = new DogBreedEventModel(
            this._mapEventData(eventData),
            analyticProperties,
            overrideAnalyticProperties
        );
        model.analyticObjects = eventData;

        return model;
    }

    /**
     * _mapAnimalBreedFilters
     *
     * @param {Object} filters
     *
     * @return {Object}
     */
    static _mapAnimalBreedFilters = filters => {
        return {
            size: _get(filters, 'size', ''),
            energyLevel: _get(filters, 'energyLevel', ''),
            friendliness: _get(filters, 'friendliness', ''),
            easeOfTraining: _get(filters, 'easeOfTraining', ''),
            groomingRequirements: _get(filters, 'groomingRequirements', ''),
            vocality: _get(filters, 'vocality', ''),
            exerciseRequirements: _get(filters, 'exerciseRequirements', ''),
            affectionNeeds: _get(filters, 'affectionNeeds', ''),
        };
    };

    _buildProperties(analytics, nullText) {
        return new DogBreedPropertiesModel(analytics, nullText);
    }
}
