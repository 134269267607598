import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../../breeds/specifics/SvgIcon';

import {
    analyticsConsumer002,
    analyticsConsumer014,
} from '../../../../analytics/dotcom';

import { CONTEXT_NAMES, CONTEXT, SIGN_UP_COPY } from '../utilities/copyMap';

class SignUpLayout extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        contextName: PropTypes.oneOf(CONTEXT_NAMES),
        onSocialClick: PropTypes.func,
        onLoginClick: PropTypes.func,
        onCancel: PropTypes.func,
        onRegistrationClick: PropTypes.func,
        renderGoogleButton: PropTypes.func,
        renderFacebookButton: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        contextName: CONTEXT.GENERIC,
    };

    /* *******************************************
     * Events
     ********************************************/
    /**
     * @param {Event} ev
     */
    handleSocialClick = ev => {
        ev.preventDefault();
        this.props.onSocialClick();
    };

    /**
     * @param {Event} ev
     */
    handleLoginClick = ev => {
        ev.preventDefault();
        this.props.onLoginClick(analyticsConsumer014);
    };

    /**
     * @param {Event} ev
     */
    handleRegisterClick = ev => {
        ev.preventDefault();
        this.props.onRegistrationClick(analyticsConsumer002);
    };

    handleAltFooterClick = () => {
        // Closes the login modal without triggering unnecessary analytics
        this.props.onCancel(true);

        SIGN_UP_COPY[this.props.contextName].footer.onClick();
    };

    get contextCopy() {
        if (SIGN_UP_COPY[this.props.contextName]) {
            return SIGN_UP_COPY[this.props.contextName];
        } else {
            return SIGN_UP_COPY[CONTEXT.GENERIC];
        }
    }

    /* *******************************************
     * Render
     ********************************************/

    render() {
        return (
            <div className="loginModal">
                <div className="loginModal-section u-textCenter">
                    <h2 className="loginModal-section-hdg u-vr6x" tabIndex="-1">
                        {this.contextCopy.heading}
                    </h2>

                    <p className="txt m-txt_xl u-vr4x">
                        {this.contextCopy.description
                            ? this.contextCopy.description
                            : 'Create an account'}
                    </p>

                    <div className="u-vr3x">
                        {this.props.renderFacebookButton({
                            cta: this.contextCopy.cta,
                            onSocialClick: params => {
                                analyticsConsumer002(params);
                            },
                        })}
                    </div>
                    <div className="u-vr3x">
                        {this.props.renderGoogleButton({
                            cta: this.contextCopy.cta,
                            onSocialClick: params => {
                                analyticsConsumer002(params);
                            },
                        })}
                    </div>
                    {SIGN_UP_COPY[this.props.contextName] &&
                    SIGN_UP_COPY[this.props.contextName].altEmailBtn ? (
                        <button
                            type="button"
                            onClick={this.handleLoginClick}
                            className="btn btn_borderPrimary m-btn_iconLeft m-btn_normalCase m-btn_full"
                            data-test="Sign_Up_With_Email_Button"
                        >
                            Log in or sign up with email
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={this.handleRegisterClick}
                            className="btn btn_borderPrimary m-btn_iconLeft m-btn_normalCase m-btn_full"
                            data-test="Sign_Up_With_Email_Button"
                        >
                            <span className="btn-icon">
                                <SvgIcon
                                    svgId="email"
                                    size="18"
                                    color="primary"
                                />
                            </span>
                            {`${this.contextCopy.cta}email`}
                        </button>
                    )}

                    {SIGN_UP_COPY[this.props.contextName] &&
                    SIGN_UP_COPY[this.props.contextName].footer
                        ? SIGN_UP_COPY[this.props.contextName].footer.jsx(
                              this.handleAltFooterClick
                          )
                        : null}
                </div>

                {!SIGN_UP_COPY[this.props.contextName] ||
                !SIGN_UP_COPY[this.props.contextName].footer ? (
                    <div>
                        <p className="txt m-txt_lg u-vr2x">
                            {'Already have an account? '}
                            <button
                                type="button"
                                onClick={this.handleLoginClick}
                                className="txt txt_link m-txt_lg m-txt_underline"
                            >
                                Log in
                            </button>
                        </p>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default SignUpLayout;
