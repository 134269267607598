import BaseCollection from './BaseCollection';
import LocationModel from './LocationModel';

export default class LocationCollection extends BaseCollection {
    static fromServer(rawLocations) {
        if (!rawLocations || rawLocations.length == null) {
            return new LocationCollection();
        }

        return new LocationCollection(rawLocations.map(item => LocationModel.fromServer(item)));
    }
}
