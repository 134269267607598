import BaseAnalyticEventModel from '../BaseAnalyticEventModel';
import SearchAndMatchPropertiesModel from './SearchAndMatchPropertiesModel';

export default class SearchAndMatchEventModel extends BaseAnalyticEventModel {
    get defaultAnalyticProperties() {
        return {
            step: true,
        };
    }

    /**
     * prepareEvent
     *
     * @static
     * @param {Object} eventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {SearchAndMatchEventModel}
     */
    static prepareEvent(
        eventData,
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const model = new SearchAndMatchEventModel(
            this._mapEventData(eventData),
            analyticProperties,
            overrideAnalyticProperties
        );
        model.analyticObjects = eventData;

        return model;
    }

    _buildProperties(analytics, nullText) {
        return new SearchAndMatchPropertiesModel(analytics, nullText);
    }
}
