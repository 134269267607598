import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import {
    EV_STICKY_DEACTIVATE,
    EV_RESIZE_BREAKPOINT_CHANGE,
} from '../../constants/events';
import contextContainer from '../../../../core/scripts/context';
import { CLASS_IS_STICKY, CLASS_IS_VISIBLE } from '../../constants/classes';
import { debounce } from '../../util/dom';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-sticky';

/**
 * Data attributes for options
 * @const
 * @type {string}
 */
const ATTR = {
    LATCH_BOTTOM: 'data-latch-bottom',
    SHOW_POSITION: 'show-position',
    MANAGE_FOCUS: 'manage-focus-overlay',
};

/**
 * @class PFDCStickyElement
 * @extends PFElement
 */
export class PFDCStickyElement extends PFElement {
    /**
     * @memberof PFDCStickyElement
     */
    backToTopButton = null;

    /**
     * Boolean to track if focus should be observed
     * @type {boolean}
     * @memberof PFDCStickyElement
     */
    focusObserverEnabled = false;

    /**
     * manageFocusOverlay
     * @type {boolean}
     */
    get manageFocusOverlay() {
        return this.hasAttribute(ATTR.MANAGE_FOCUS);
    }

    /**
     * Checks bounds of element relative to scroll positions and window
     *
     * @method boundsCheck
     * @returns {boolean}
     */
    get boundsCheck() {
        if (this.hasAttribute(ATTR.LATCH_BOTTOM)) {
            return this.getBoundingClientRect().bottom > window.innerHeight;
        } else {
            return this.getBoundingClientRect().top < 0;
        }
    }

    /**
     * Determines if this attribute is present on the element.
     *
     * @method scrollListener
     * @readonly
     * @returns {boolean}
     * @memberof PFDCStickyElement
     */
    get scrollListener() {
        return this.hasAttribute('show-after-scroll');
    }

    /**
     * An instance of the element is created or upgraded. Useful for initializing state,
     * settings up event listeners, or creating shadow dom.
     */
    constructor() {
        super();

        this.options = {
            rootMargin: '0px',
            threshold: 1,
        };
    }

    /**
     * Called every time the element is inserted into the DOM. Useful for running setup code,
     * such as fetching resources or rendering. Generally, you should try to delay work until
     * this time.
     */
    onInit() {
        super.onInit();

        this.backToTopButton = this.querySelector('[pfdc-back-to-top]');

        if (this.scrollListener) {
            document.addEventListener(
                'scroll',
                debounce(this.positionCheck.bind(this), 20)
            );
        }

        this.observer = new IntersectionObserver(
            this.onIntersected.bind(this),
            this.options
        );

        this.observer.observe(this);

        this.setHeight();
        this.onIntersected();
    }

    enableFocusObserver() {
        if (this.focusObserverEnabled) {
            return;
        }

        document.addEventListener('focus', this.onFocusChange.bind(this), true);
        this.focusObserverEnabled = true;
    }

    disableFocusObserver() {
        if (!this.focusObserverEnabled) {
            return;
        }

        document.removeEventListener(
            'focus',
            this.onFocusChange.bind(this),
            true
        );
        this.focusObserverEnabled = false;
    }

    /**
     * Checks the position of the backToTopButton and shows or hides it
     * based on viewport height vs scroll position.
     *
     * @method positionCheck
     * @memberof PFDCStickyElement
     */
    positionCheck() {
        const scrollTop = window.scrollY;
        const viewHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight || 0
        );

        if (scrollTop > viewHeight) {
            this.backToTopButton.classList.add(CLASS_IS_VISIBLE);
        } else {
            this.backToTopButton.classList.remove(CLASS_IS_VISIBLE);
        }
    }

    /**
     * Checks if focused element is obscured by the fixed element
     * and shifts window scroll position if necessary
     *
     * @method checkForFocusOverlap
     * @param {HTMLElement} element
     */
    checkForFocusOverlap(element) {
        if (!contextContainer.focusManager.isElementTabbable(element)) {
            return;
        }

        const bottomOfFocusElement = element.getBoundingClientRect().bottom;
        const topOfStickyElement = window.innerHeight - this.offsetHeight;

        if (bottomOfFocusElement > topOfStickyElement) {
            document.documentElement.scrollTop =
                document.documentElement.scrollTop + this.offsetHeight;
        }
    }

    /**
     * Sets explicit height of element to prevent reflow when fixed positioning child
     *
     * @method setHeight
     */
    setHeight() {
        this.style.minHeight = '1px';
        this.style.height = `${this.offsetHeight}px`;
    }

    /**
     * Sets height on element back to auto to keep content naturally in page flow
     *
     * @method resetHeight
     */
    resetHeight() {
        this.style.height = 'auto';
    }

    /**
     * Handles observed intersections
     *
     * @method onIntersected
     */
    onIntersected() {
        if (this.boundsCheck) {
            this.setHeight();
            this.classList.add(CLASS_IS_STICKY);
        } else {
            this.classList.remove(CLASS_IS_STICKY);
            this.resetHeight();
        }
    }

    /**
     * Handles focus events
     *
     * @method onFocusChange
     * @param {Object} ev
     */
    onFocusChange(ev) {
        this.checkForFocusOverlap(ev.target);
    }

    /**
     * Handles update events from the app.
     * @method onUpdated
     * @param  {Object} ev - event object
     */
    async onUpdated(ev) {
        const { detail } = ev;

        if (this.manageFocusOverlay) {
            const currentBreakpoint = detail.currentBreakpoint
                ? detail.currentBreakpoint
                : '';
            switch (currentBreakpoint) {
                case 'lg':
                case 'xl':
                    this.enableFocusObserver();
                    break;
                case 'sm':
                case 'md':
                    this.disableFocusObserver();
                    break;
                default:
                    break;
            }
        }
    }
}

export default PFDCStickyElement;
