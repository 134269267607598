import t from 'tcomb-form/lib';
import i18n from 'tcomb-form/lib/i18n/en';
import '../../../../core/scripts/react-components/tcomb/errors';
import templates from '../../../../core/scripts/react-components/tcomb/templates';

export default function initTcombForm() {
    // Sets up custom template components with tcomb
    t.form.Form.templates = templates;
    t.form.Form.i18n = i18n;
}
