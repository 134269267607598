/**
 * @type {Object}
 */
export const STATE = {
    IDLE: 'idle',
    SUBMITTING: 'submitting',
    SERVER_ERRORS: 'serverErrors',
};

/**
 * @type {Object}
 */
export const EVENT = {
    SUBMIT: 'submit',
    SUCCESS: 'success',
    ERROR: 'error',
    VALUE_UPDATE_SUBMIT: 'valueUpdateSubmit',
};

/**
 * @type {Object}
 */
export const ACTION = {
    DEBOUNCED_SUBMIT_FORM: '_debouncedSubmitForm',
    SUBMIT_FORM: '_submitForm',
    SHOW_SERVER_ERRORS: '_showServerError',
    ON_SUBMISSION_SUCCESS: '_onSubmissionSuccess',
    ON_SUBMISSION_ERROR: '_onSubmissionError',
};

/**
 * @type {Object}
 */
export const statechart = {
    initial: STATE.IDLE,
    states: {
        [STATE.IDLE]: {
            on: {
                [EVENT.SUBMIT]: STATE.SUBMITTING,
                [EVENT.VALUE_UPDATE_SUBMIT]: STATE.SUBMITTING,
            },
        },
        [STATE.SUBMITTING]: {
            onEntry: [ACTION.DEBOUNCED_SUBMIT_FORM],
            on: {
                [EVENT.SUCCESS]: {
                    [STATE.IDLE]: {
                        actions: [ACTION.ON_SUBMISSION_SUCCESS],
                    },
                },
                [EVENT.ERROR]: {
                    [STATE.SERVER_ERRORS]: {
                        actions: [ACTION.ON_SUBMISSION_ERROR],
                    },
                },
                [EVENT.VALUE_UPDATE_SUBMIT]: STATE.SUBMITTING,
            },
        },
        [STATE.SERVER_ERRORS]: {
            onEntry: [ACTION.SHOW_SERVER_ERRORS],
            on: {
                [EVENT.SUBMIT]: STATE.SUBMITTING,
            },
        },
    },
};
