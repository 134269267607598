import StateController from '../../../../core/scripts/lib/wirey/state/StateController';

export default class PaginationStateController extends StateController {
    // NOTE: overriding the default getter here so we can modify the default offset in the constructor
    _defaultState = {
        offset: 0,
        currentPage: 0,
        currentPageDisplay: 1,
        countPerPage: 10,
        // set high to prevent initial state from limiting current page setting
        totalPages: Infinity,

        // for generic select pagination
        value: [],
        options: [],
    };

    get defaultState() {
        return this._defaultState;
    }

    get offset() {
        return this.state.offset;
    }

    set offset(value) {
        const newCurrentPage = this.getPageFromOffset(value);

        this.patchState({
            offset: value,
            currentPage: newCurrentPage,
            currentPageDisplay: newCurrentPage + 1,
            value: [newCurrentPage],
        });
    }

    get countPerPage() {
        return this.state.countPerPage;
    }

    set countPerPage(value) {
        this.patchState({
            countPerPage: value || this.defaultState.countPerPage,
        });

        // now that we've changed the count per page, we need to update the offset, page number, etc.
        // lets do this by simply setting the offset to the current offset
        this.offset = this.offset;
    }

    set currentPage(value) {
        this.offset = value * this.countPerPage;
    }

    get currentPage() {
        return this.getPageFromOffset(this.offset);
    }

    getPageFromOffset(offset) {
        return Math.min(
            Math.floor(offset / this.countPerPage),
            this.state.totalPages
        );
    }

    set totalPages(value) {
        this.patchState({
            totalPages: value,
        });
    }

    get totalPages() {
        return this.state.totalPages;
    }

    constructor(defaultPage = null) {
        super();

        // set default page
        if (defaultPage !== null) {
            this._defaultState.offset =
                this.defaultState.countPerPage * defaultPage;
        }
    }

    relativePage(pageOffset) {
        this.offset = this.state.offset + this.state.countPerPage * pageOffset;
    }

    goToPreviousPage() {
        this.goToPage(this.currentPage - 1);
    }

    goToNextPage() {
        this.goToPage(this.currentPage + 1);
    }

    goToPage(newPage) {
        // need to parse due to newPage coming in as a string
        let parsedNewPage = parseInt(newPage, 10) || 0;
        parsedNewPage = Math.max(parsedNewPage, 0);

        const newOffset = this.state.countPerPage * parsedNewPage;
        if (newOffset === this.offset) {
            return false;
        }

        this.offset = newOffset;
    }
}
