import { html } from '../../../core/scripts/util/util';
import { helpers } from '../../../core/scripts/util/helpers';

export default placeholderCards => html`
    <div class="recommendations-header u-vr5x">
        <div class="tier">
            <h2 class="txt txt_h1 m-txt_tight m-txt_colorPrimary m-txt_alignCenter" aria-hidden="true">
                Finding pets for you&hellip;
            </h2>
        </div>
    </div>
    <div class="recommendations-body">
        <div class="grid grid_gutterLg m-grid_stretch">
            ${placeholderCards.map(card => html`
                <div class="grid-col grid-col_1/2 grid-col_1/3@minMd grid-col_1/4@minXl">
                    <div class="petCard">
                        <div class="petCard-media"></div>
                        <div class="petCard-body">
                            <div class="petCard-body-details">
                                <div class="blockTxt"></div>
                            </div>
                        </div>
                    </div>
                </div>
            `)}
            <div class="grid-col grid-col_1/2 grid-col_1/3@minMd grid-col_1/4@minXl">
                <div class="actionCard">
                    <div class="actionCard-body actionCard-body_split">
                        <div class="actionCard-body-icon">
                            <span class="icon icon_xxl m-icon_colorWhite">
                                <svg role="img">
                                    <use xlink:href="#icon-strokedPaw" />
                                </svg>
                            </span>
                        </div>
                        <div class="blockTxt m-blockText_colorWhite"></div>
                    </div>
                    <div class="actionCard-footer">
                        <a href="#" class="actionCard-footer-cta" tabindex="-1" aria-hidden="true">
                            <div class="blockTxt m-blockText_colorWhite"></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;
