import t from 'tcomb';

import { User } from './User';
import { HelloBar } from './HelloBar';
import { UserLocation } from './UserLocation';
import { SavedSearches } from './SavedSearches';
import { Alerts } from './Alerts';

export const Me = t.struct(
    {
        user: t.maybe(User),
        location: t.maybe(UserLocation),
        did_you_adopt_details: HelloBar, // eslint-disable-line camelcase
        saved_search_token: t.maybe(t.Str),
        saved_searches: t.maybe(SavedSearches), // eslint-disable-line camelcase
        alerts: Alerts,
        token: t.maybe(t.Str),
    },
    'Me',
);
