import { PFDCHeaderDropdownElement } from '../../../../dotcom/scripts/elements/pfdc-header-dropdown/element';
import { CLASS_CHILD_IS_FOCUSED } from '../../../../core/scripts/constants/classes';
import { KEY_ESCAPE, KEY_ESC } from '../../constants/keyboard';

export class PFDCHeaderDropdownResourcesElement extends PFDCHeaderDropdownElement {
    /* *******************************************
     * Class Properties
     ********************************************/

    /**
     * A property to track the current timeout ID to determine when to blur.
     */
    _blurTimeoutId = null;

    /**
     * A property to track which main navigation item is currently active.
     * Property is updated with current target menu each time a subnav is opened.
     */
    _targetMenu = null;

    /* *******************************************
     * Lifecycle
     ********************************************/

    constructor() {
        super();

        /**
         * An array of all top level, main navigation items.
         *
         * Each object in the returned array contains a reference to the containing <li>,
         * the hidden button trigger, and an array of the links in the dropdown sub menu.
         *
         * @readonly
         * @returns {Array<Object>}
         */
        this.navElements = Array.from(this.querySelectorAll('[pf-root-item]')).map(container => {
            const trigger = container.querySelector('[pf-root-item-button]');
            const subLinksContainer = container.querySelector('[pf-sub-list]');
            const subLinks = Array.from(container.querySelectorAll('[pf-sub-item] > a'));

            return {
                container,
                trigger,
                subLinksContainer,
                subLinks,
            };
        });
    }

    async activate() {
        await super.activate();

        this.addEventListener('click', this._onHiddenButtonClick);
        this.addEventListener('focusin', this._onSubNavFocusin);
        this.addEventListener('focusout', this._onSubNavFocusout);
        this.addEventListener('keydown', this._onKeyDown);
    }

    async deactivate() {
        await super.deactivate();

        this.removeEventListener('click', this._onHiddenButtonClick);
        this.removeEventListener('focusin', this._onSubNavFocusin);
        this.removeEventListener('focusout', this._onSubNavFocusout);
        this.removeEventListener('keydown', this._onKeyDown);
    }

    /* *******************************************
     * Methods
     ********************************************/

    /**
     * Opens the main navigation dropdown for the currently active item.
     *
     * @param {Object} targetMenu Currently active main nav item details.
     */
    _openDropdown(targetMenu) {
        targetMenu.container.classList.add(CLASS_CHILD_IS_FOCUSED);
        targetMenu.trigger.setAttribute('aria-expanded', 'true');
        targetMenu.subLinks.map(subLink => {
            subLink.removeAttribute('aria-hidden');
            subLink.removeAttribute('tabindex');
        });
        targetMenu.subLinks[0].focus();
    }

    /**
     * Closes the main navigation dropdown for the currently active item.
     *
     * @param {Object} targetMenu Currently active main nav item details.
     */
    _closeDropdown(targetMenu) {
        this._blurTimeoutId = setTimeout(() => {
            targetMenu.trigger.setAttribute('aria-expanded', 'false');
            targetMenu.subLinks.map(subLink => {
                subLink.setAttribute('aria-hidden', '');
                subLink.setAttribute('tabindex', '-1');
            });
            targetMenu.container.classList.remove(CLASS_CHILD_IS_FOCUSED);
        });
    }

    /* *******************************************
     * Events
     ********************************************/

    /**
     * Event handler for clicks on the hidden button trigger.
     * @param {Object} event
     */
    _onHiddenButtonClick = event => {
        this.targetMenu = this.navElements.filter(nav => nav.trigger === event.target)[0];
        if (this.targetMenu) {
            this._openDropdown(this.targetMenu);
        }
    };

    /**
     * Event handler for focus in to the dropdown sub nav links.
     * @param {Object} event
     */
    _onSubNavFocusin = event => {
        this.targetMenu = this.navElements.find(nav => nav.subLinks.includes(event.target));
        if (this.targetMenu) {
            clearTimeout(this._blurTimeoutId);
        }
    };

    /**
     * Event handler for focus out of the dropdown sub nav links.
     * @param {Object} event
     */
    _onSubNavFocusout = event => {
        this.targetMenu = this.navElements.find(nav => nav.subLinks.includes(event.target));
        if (this.targetMenu) {
            this._closeDropdown(this.targetMenu);
        }
    };

    /**
     * Event handler for key down presses which closes the open dropdown.
     * @param {Object} event
     */
    _onKeyDown = event => {
        switch (event.key) {
            case KEY_ESCAPE:
            case KEY_ESC:
                this._closeDropdown(this.targetMenu);
                break;
        }
    };
}

export default PFDCHeaderDropdownResourcesElement;
