import EventEmitter from 'wolfy87-eventemitter';

// internal
import { Animate } from './lib/Animate';
import { Config } from './lib/Config';
import FocusManager from './lib/FocusManager';
import { FormParser } from './lib/FormParser';
// TODO: marked for deprecation; remove if no issues are noticed in its absence
// import { ScrollLock } from './lib/ScrollLock';
import CountryRepository from './repositories/countries/countries';
import GeographyRepository from './repositories/geography/geography';
import SearchRepository from './repositories/search/search';
import SessionRepository from './repositories/session/session';
import UserRepository from './repositories/users/users';
import UserSavedSearchRepository from './repositories/UserSavedSearchRepository';
const { translations } = require('../../core/scripts/data/i18nJSON.json');
import WordpressRepository from './repositories/wp/wp';
import EventsRepository from './repositories/events/events';

class ContextContainer {
    static instances = new Map();

    static getInstance(Name) {
        if (!this.instances.get(Name)) {
            this.instances.set(Name, new Name(this));
        }

        return this.instances.get(Name);
    }

    /**
     * @returns {EventEmitter}
     */
    static get eventEmitter() {
        return this.getInstance(EventEmitter);
    }

    /**
     * @returns {FocusManager}
     */
    static get focusManager() {
        return this.getInstance(FocusManager);
    }

    /**
     * @returns {FormParser}
     */
    static get formParser() {
        return this.getInstance(FormParser);
    }

    /**
     * @returns {Animate}
     */
    static get animationEngine() {
        return this.getInstance(Animate);
    }

    /**
     */
    static get scrollLock() {
        // TODO: if we don't see this error anywhere, we can safely remove this getter and remove the import above; ScrollLock is still in use on pro-dash though.
        throw new Error(
            'ScrollLock was assumed unused and deprecated; this indicates something is still attempting to make use of it'
        );
        // return this.getInstance(ScrollLock);
    }

    /**
     * @returns {CountryRepository}
     */
    static get countryRepository() {
        return this.getInstance(CountryRepository);
    }

    /**
     * @returns {SearchRepository}
     */
    static get searchRepository() {
        return this.getInstance(SearchRepository);
    }

    /**
     * @returns {SessionRepository}
     */
    static get sessionRepository() {
        return this.getInstance(SessionRepository);
    }

    /**
     * @returns {UserRepository}
     */
    static get userRepository() {
        return this.getInstance(UserRepository);
    }

    /**
     * @returns {UserSavedSearchRepository}
     */
    static get userSavedSearchRepository() {
        return this.getInstance(UserSavedSearchRepository);
    }

    /**
     * @returns {WordpressRepository}
     */
    static get wordpressRepository() {
        return this.getInstance(WordpressRepository);
    }

    /**
     * @returns {EventsRepository}
     */
    static get eventsRepository() {
        return this.getInstance(EventsRepository);
    }

    static get translations() {
        if (!this._translations) {
            this._translations = translations;
        }
        return this._translations;
    }
}

export default ContextContainer;
