import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _debounce from 'lodash/debounce';

import userMeAPIService from '../../../../../core/scripts/services/userMeAPIService';
import ComboBox, { ListBox, ListBoxOption } from '../../generics/ComboBox';
import geographyAPIService from '../../../services/geographyAPIService';

const UI_STATE = {
    IDLE: 'idle',
    LOADING: 'loading',
    GEO_BLOCKED: 'geoBlocked',
    RESULTS: 'results',
    NO_RESULTS: 'noResults',
    SELECTION: 'selection',
};

/**
 * Renders a specialized ComboBox that handles building a list of options from an async request
 * also has some other props to allow validation
 */
export default class GeoSearchComboBox extends Component {
    /**
     * @type {Object}
     */
    state = {
        geoShouldPrompt: true, // if Geo API supported and not previously blocked
        geoIsBlocked: false, // Geo API blocked within session
        queryIsValid: false,
        initialQuery: '',
        initialSelections: [],
        list: [],
        selectionDisplayName: '',
        UIState: UI_STATE.IDLE,
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        id: PropTypes.string.isRequired,
        inputProps: PropTypes.object,
        label: PropTypes.string,
        loadingLabel: PropTypes.string,
        noResultsLabel: PropTypes.string,
        dataTestInputId: PropTypes.string,
        dataTestListId: PropTypes.string,
        invalidQueryLabel: PropTypes.string,
        placeholder: PropTypes.string,
        minQueryLength: PropTypes.number,
        listBoxExtensionClassNames: PropTypes.objectOf(PropTypes.bool),
        onSelection: PropTypes.func,
        onError: PropTypes.func,
        onUseLocationClick: PropTypes.func,
        onFetchingSuggestions: PropTypes.func,
        onReturnedSuggestions: PropTypes.func,
        useDefaultLocation: PropTypes.bool,
        validateQuery: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        inputProps: {},
        label: 'Enter City, State, or ZIP location',
        loadingLabel: 'Loading...',
        noResultsLabel: 'No locations found',
        invalidQueryLabel: 'Use Current Location',
        placeholder: 'Enter City, State, or ZIP',
        listBoxExtensionClassNames: {},
        minQueryLength: 3,
        onSelection: _noop,
        onError: _noop,
        onUseLocationClick: _noop,
        onFetchingSuggestions: _noop,
        onReturnedSuggestions: _noop,
        useDefaultLocation: false,
        validateQuery: query => true,
    };

    componentDidMount() {
        this._updateAuthStatus();
        this._setDefaultLocation();
    }

    /**
     * @type {function}
     */
    _debouncedSearch = _debounce(this._search, 500);

    /**
     * Input ref
     * @type {Object}
     */
    _inputRef = React.createRef();

    /**
     * @return {String}
     */
    get selectionCopy() {
        let selectedValue;

        if (this.state.selectionDisplayName.length) {
            selectedValue = `${this.state.selectionDisplayName} selected`;
        }

        return selectedValue;
    }

    /**
     * @return {String}
     */
    get statusCopy() {
        let statusCopy;

        switch (this.state.UIState) {
            case UI_STATE.LOADING:
                statusCopy = 'Searching, please wait';
                break;
            case UI_STATE.GEO_BLOCKED:
                statusCopy = 'We were unable to detect your location';
                break;
            case UI_STATE.RESULTS:
                statusCopy = `${this.state.list.length} results match your query`;
                break;
            case UI_STATE.NO_RESULTS:
            case UI_STATE.IDLE:
                statusCopy = 'No results match your query';
                break;
            default:
                break;
        }

        return statusCopy;
    }

    /**
     * Validate query, call query change prop func
     * @param {string} query
     */
    _handleQueryChange = query => {
        const queryIsValid = this._validateQuery(query);

        this.setState(
            {
                queryIsValid,
                list: [],
                UIState: queryIsValid ? UI_STATE.LOADING : UI_STATE.IDLE,
            },
            () => {
                this._debouncedSearch(query);

                if (queryIsValid) {
                    this.props.onFetchingSuggestions();
                }
            }
        );
    };

    /**
     * Calls the search func which should return the new list for the ComboBox component
     * @param {string} query
     */
    async _search(query) {
        let nextList = [];
        let UIState = UI_STATE.IDLE;

        if (this.state.queryIsValid) {
            const locations = await geographyAPIService.searchForLocations(query);

            // check query validity again since it could have changed during await
            if (this.state.queryIsValid) {
                nextList = locations.raw.map(location => ({
                    ...location,
                    label: location.displayName,
                    value: location.slug,
                }));
                UIState = nextList.length ? UI_STATE.RESULTS : UI_STATE.NO_RESULTS;
            }
        }

        this.props.onReturnedSuggestions();

        // empty selection is returned when query is modified
        this.props.onSelection('', '');

        this.setState({
            selectionDisplayName: '',
            list: nextList || [],
            UIState,
        });
    }

    /**
     * @param {string} query
     * @returns {Boolean}
     */
    _validateQuery(query) {
        return (
            this.props.validateQuery(query) &&
            query.length >= this.props.minQueryLength
        );
    }

    async _setDefaultLocation() {
        this.setState({
            UIState: UI_STATE.LOADING,
        });

        const userMe = await userMeAPIService.getUserMe();
        const location = userMe.location;
        const isDefaultSelection = true;

        if (location.source === 'cookie' && location.displayName && location.slug) {
            const selections = [{
                label: location.displayName,
                value: location.slug,
            }];

            this.setState({
                queryIsValid: this._validateQuery(location.displayName),
                initialQuery: location.displayName,
                initialSelections: selections,
                UIState: UI_STATE.SELECTION,
            }, () => {
                this.props.onSelection(location.slug, location.displayName, isDefaultSelection);
            });

            return;
        }

        this.setState({
            UIState: UI_STATE.IDLE,
        }, () => {
            this.props.onSelection('', '', isDefaultSelection);
        });
    }

    /**
     * Update the auth status of the geolocation API
     */
    async _updateAuthStatus() {
        let auth = null;

        if (navigator.geolocation) {
            // safari supports geolocation but not permissions so we
            // fallback to 'prompt' if permissions isn't supported
            if (navigator.permissions) {
                await navigator.permissions.query({ name: 'geolocation' })
                    .then(status => {
                        auth = status.state;
                    });
            } else {
                auth = 'prompt';
            }
        }

        this.setState({
            geoShouldPrompt: auth === 'granted' || auth === 'prompt',
        });
    }

    /**
     * Click handler for "use current location" option
     * kicks off geo location API request
     * @param {function} selectOption reference to comboBox handleOptionClick
     */
    _handleCurrentLocationClick(selectOption) {
        this.props.onUseLocationClick();
        this.props.onFetchingSuggestions();

        this.setState({
            UIState: UI_STATE.LOADING,
        });

        navigator.geolocation.getCurrentPosition(
            position => this._handleGeoSuccess(position, selectOption),
            () => this._handleGeoFail()
        );
    }

    /**
     * Success callback for navigator.geolocation.getCurrentPosition
     * @param {object} position https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPosition
     * @param {function} selectOption reference to comboBox handleOptionClick
     */
    async _handleGeoSuccess(position, selectOption) {
        this._inputRef.current.focus();

        const nextList = await this._getMatchedGeoLocation(position);

        const nextState = {
            list: [],
            UIState: nextList.length > 0 ? UI_STATE.SELECTION : UI_STATE.NO_RESULTS,
        };

        if (nextList.length > 0) {
            const option = nextList[0];

            nextState.selectionDisplayName = option.label;

            selectOption(option);
        }

        this.props.onReturnedSuggestions();

        this.setState(nextState);
    }

    /**
     * Error callback for navigator.geolocation.getCurrentPosition
     */
    async _handleGeoFail() {
        this.props.onError('geolocation');

        this._inputRef.current.focus();

        await this._updateAuthStatus();

        this.props.onReturnedSuggestions();

        this.setState({
            geoIsBlocked: !this.state.geoShouldPrompt,
            UIState: UI_STATE.GEO_BLOCKED,
        });
    }

    /**
     * Takes position coords returned from the Geolocation API and
     * passes to geographyAPIService which returns a location
     * @param {object} position https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPosition
     * @param {function} selectOption reference to comboBox handleOptionClick
     * @returns {array} array of single returned location
     */
    async _getMatchedGeoLocation(position) {
        let nextList = [];

        try {
            const location = await geographyAPIService.findAPlace(
                position.coords.latitude,
                position.coords.longitude
            );

            const option = {
                label: location.displayName,
                value: location.slug,
            };

            nextList = [option];
        } catch (err) {
            this.props.onError('APIService');
        }

        return nextList;
    }

    /**
     * Update state with display name of selected location for the sake of aria alerts
     * @param {array} values array of selection values
     * @param {string} name id of component
     * @param {array} selection array of location objects
     */
    _handleOnSelection = (values, name, selection) => {
        const label = selection[0].label || '';
        const value = selection[0].value || '';
        const isDefaultSelection = false;

        this.props.onSelection(value, label, isDefaultSelection);

        this.setState({
            selectionDisplayName: label,
            UIState: UI_STATE.SELECTION,
            list: [],
        });
    };

    /**
     * Selects best result from list if blurred before selection
     * @param {function} selectOption reference to comboBox handleOptionClick
     */
    _handleBlur = selectOption => {
        const hasSelection = this.state.selectionDisplayName !== '';
        const hasListResults = this.state.list.length;

        if (!hasSelection && hasListResults) {
            selectOption(this.state.list[0], false);
        }
    };

    /**
     * @param {event} event
     */
    _handleFocus = event => {
        const input = event.target;

        if (input === this._inputRef.current) {
            input.select();
        }
    };

    /**
     * @return {HTMLElement}
     * @param {function} selectOption reference to comboBox handleOptionClick
     */
    _renderAuxiliaryOption(selectOption) {
        let option;

        switch (this.state.UIState) {
            case UI_STATE.LOADING:
                option = this._renderLoadingOption();
                break;
            case UI_STATE.NO_RESULTS:
                option = this._renderNoResultsOption();
                break;
            case UI_STATE.GEO_BLOCKED:
                option = this._renderGeoErrorOption();
                break;
            case UI_STATE.IDLE:
                option = this.state.geoShouldPrompt
                    ? this._renderCurrentLocationOption(selectOption)
                    : this._renderNoResultsOption();
                break;
            default:
                break;
        }

        return option;
    }

    /**
     * @return {HTMLElement}
     */
    _renderLoadingOption() {
        return (
            <ListBoxOption
                id={`${this.props.id}-loading`}
                extensionClassNames={{ ['listBox-option_loading']: true }}
                option={{ label: this.props.loadingLabel }}
            />
        );
    }

    /**
     * @return {HTMLElement}
     */
    _renderGeoErrorOption() {
        return (
            <ListBoxOption
                id={`${this.props.id}-error`}
                extensionClassNames={{ ['listBox-option_error']: true }}
                option={{ label: 'We were unable to detect your location, but you may manually enter it instead.' }}
            />
        );
    }

    /**
     * @return {HTMLElement}
     */
    _renderNoResultsOption() {
        return (
            <ListBoxOption
                id={`${this.props.id}-noResults`}
                extensionClassNames={{ ['listBox-option_noResults']: true }}
                option={{ label: this.props.noResultsLabel }}
            />
        );
    }

    /**
     * @return {HTMLElement}
     * @param {function} selectOption reference to comboBox handleOptionClick
     */
    _renderCurrentLocationOption(selectOption) {
        return (
            <ListBoxOption
                id={`${this.props.id}-invalidQuery`}
                extensionClassNames={{ ['listBox-option_geoLocation']: true }}
                option={{ label: this.props.invalidQueryLabel }}
                onOptionClick={() => this._handleCurrentLocationClick(selectOption)}
                tabIndex={0}
            />
        );
    }

    /**
     * @param {string} copy
     * @return {HTMLElement}
     */
    _renderAlertRegion(copy) {
        return (
            <div
                className="u-isVisuallyHidden"
                role="status"
                aria-live="polite"
                aria-atomic="true"
            >
                {copy}
            </div>
        );
    }

    render() {
        return (
            <ComboBox
                renderInput={input => (
                    <Fragment>
                        {/* Specific usage instructions */}
                        <div
                            id={`${this.props.id}-description`}
                            className="u-isVisuallyHidden"
                            aria-hidden="true"
                        >
                            This is a search field. A list of options may be retrieved based on your typed query.
                            If options are retrieved, use up and down arrow keys or mobile controls to navigate the list.
                        </div>

                        {/* Alerts for selection and UI status */}
                        {this._renderAlertRegion(this.selectionCopy)}
                        {this._renderAlertRegion(this.statusCopy)}

                        <input
                            {...input.props}
                            {...this.props.inputProps}
                            aria-describedby={`${this.props.id}-description`}
                            data-test={this.props.dataTestInputId}
                        />
                    </Fragment>
                )}
                renderListBox={listBox => (
                    <ListBox
                        {...listBox.props}
                        extensionClassNames={{
                            ['s-listBox_visible']: listBox.isVisible && this.state.UIState !== UI_STATE.SELECTION,
                            ...this.props.listBoxExtensionClassNames,
                        }}
                        dataTestId={this.props.dataTestListId}
                    >
                        {listBox.isVisible && (
                            <Fragment>
                                {this._renderAuxiliaryOption(listBox.selectOption)}
                                {listBox.options}
                            </Fragment>
                        )}
                    </ListBox>
                )}
                {...this.props}
                list={this.state.list}
                multiSelect={false}
                onBlur={this._handleBlur}
                onFocus={this._handleFocus}
                onQueryChange={this._handleQueryChange}
                onSelection={this._handleOnSelection}
                initialQuery={this.state.initialQuery}
                initialSelections={this.state.initialSelections}
                inputRef={this._inputRef}
                showListBoxOnInputFocus={true}
            />
        );
    }
}
