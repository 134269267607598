import MixinBase from './MixinBase';
import Utils from '../Utils';
import LogicParser from '../../../../../dotcom/scripts/lib/LogicParser';

const ITEM_SPLIT_REGEX = / *, */;
const PIECES_SPLIT_REGEX = / *: */;

window.LogicParser = LogicParser;

export default class ClassMixin extends MixinBase {
    static _attributeName = 'mix-class';

    constructor(ele, value) {
        super(ele, value);

        const classAddressMap = Utils.StringUtils.parseAttributeValue(value);

        for (const className in classAddressMap) {
            const address = classAddressMap[className];

            const result = LogicParser.evaluateLogicString(address, str => MixinBase.resolve(str, ele));

            if (result) {
                ele.classList.add(className);
            } else {
                ele.classList.remove(className);
            }
        }
    }
}
