import { PFElement } from '../pf-element/element';
import {
    EV_RESIZE_BREAKPOINT_CHANGE,
    EV_RESIZE_WIDTH_COMPLETE,
} from '../../constants/events';
import { AD_CONFIG } from './config';
import { debounce } from '../../util/dom';
import { pollUntilConditionMet, noop } from '../../util/util';
import cookie from 'js-cookie';
import { ADS } from '../../constants/ads';

const BREAKPOINT_WIDTHS = {
    sm: 0,
    md: 660,
    mdlg: 768,
    lg: 1024,
    xl: 1440,
};

const DEFAULT_OBSERVER_OPTIONS = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
};

// Private Symbols
const initiateAd = Symbol(initiateAd);
const refreshAd = Symbol(refreshAd);

const AD_SLOT_LOADED_CLASSNAME = 'ad_slotLoaded';

/**
 * # `pf-ad` Element
 * <pf-ad></pf-ad>
 *
 * The pf-ad element displays a Google DFP Ad. The configuration of the ad is done by giving an ID
 * which matches up with an entry in the imported ad config file. The ad config defines the ad size,
 * any responsive mapping - certain ad sizes at specific breakpoints - and if the ad should hide at
 * any breakpoint.  The ad campaign can be overridden via the data-campaign attribute for things
 * like test ads or special campaigns.
 *
 * @class PFAd
 * @extends PFElement
 */
export class PFAdElement extends PFElement {
    get targeting() {
        try {
            return JSON.parse(this.getAttribute('targeting'));
        } catch (ex) {
            return '';
        }
    }

    get lazyload() {
        return this.hasAttribute('lazy-load') || false;
    }

    get overrideAdId() {
        return this.getAttribute('override-ad-id');
    }

    get positionTargetId() {
        return this.getAttribute('position-target-id');
    }

    get sizeOverrideEnabled() {
        return this.overrideAdId && this.overrideAdId in ADS;
    }

    /**
     * Handles iframe focus in events upon window blur.
     */
    onWindowBlur = () => {
        setTimeout(() => {
            if (this.contains(document.activeElement)) {
                this.classList.add('focus-visible');
            }
        });
    };

    /**
     * Handles iframe focus out events upon window focus.
     */
    onWindowFocus = () => {
        this.classList.remove('focus-visible');
    };

    /**
     * @method onInit
     */
    async onInit() {
        super.onInit();

        this.observerHandler = this.onObserved.bind(this);

        // Create window focus handlers to detect focus/blur on ad iframes
        window.addEventListener('focus', this.onWindowFocus);
        window.addEventListener('blur', this.onWindowBlur);

        if (this.lazyload) {
            await pollUntilConditionMet(() => {
                return window.googletag && window.googletag.apiReady && window.googletag.pubadsReady;
            }, 100);

            window.googletag.pubads().disableInitialLoad();

            // Setup intersection observer for lazy load
            this.observer = new IntersectionObserver(this.observerHandler, DEFAULT_OBSERVER_OPTIONS);

            // Observe this component and return early
            this.observer.observe(this);
            return;
        }

        this[initiateAd]();
    }

    disconnectedCallback() {
        const self = this;

        clearTimeout(this.refreshTo);

        if (this.builtSlot) {
            /* eslint-disable */
            window.googletag.cmd.push(function(ctx = self) {
                window.googletag.destroySlots([ctx.builtSlot]);
            });
            /* eslint-enable */
        }

        this.builtSlot = undefined;
        this.onUpdated = noop;
        this[initiateAd] = noop;

        // Disconnect observer
        if (this.observer) {
            this.observer.disconnect();
        }

        // Remove window focus handlers
        window.removeEventListener('focus', this.onWindowFocus);
        window.removeEventListener('blur', this.onWindowBlur);
    }

    /**
     * Designes and enabled the ad slot.
     * @method initiateAd
     */
    async [initiateAd]() {
        /* eslint-disable */
        window.googletag.cmd.push(function() {
            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();
        });
        /* eslint-enable */

        await pollUntilConditionMet(() => {
            return (
                window.googletag &&
                window.googletag.apiReady &&
                window.googletag.pubadsReady
            );
        }, 100);

        const id = this.id;
        const currentAdConfig = AD_CONFIG[id];
        const targeting = this.targeting;
        let breakpoint = this.getCurrentBreakpoint();

        if (window.innerWidth > 728 && window.innerWidth < 1024) {
            breakpoint = 'mdlg';
        }

        const self = this;
        const cookieImpressions = cookie.get('impressions');

        /* eslint-disable */
        window.googletag.cmd.push(function(ctx = self) {
            // Remove the ad_slotRendered class
            ctx.classList.remove(AD_SLOT_LOADED_CLASSNAME);

            const mapping = window.googletag.sizeMapping(Symbol());

            const currentBreakpointSize =
                currentAdConfig.responsiveSizes[breakpoint];

            if (!self.sizeOverrideEnabled && !currentBreakpointSize) {
                return;
            }

            const slotSize = self.sizeOverrideEnabled
                ? ADS[self.overrideAdId].size
                : currentBreakpointSize.size;

            if (!ctx.getAttribute('non-responsive')) {
                Object.keys(currentAdConfig.responsiveSizes).forEach(key => {
                    mapping.addSize(
                        [BREAKPOINT_WIDTHS[key], 0],
                        currentAdConfig.responsiveSizes[key].size
                    );
                });
            }

            mapping.build();

            const adSlotId = `/46267414/Petfinder/${currentAdConfig.id}`;

            // Define the slot
            ctx.builtSlot = window.googletag.defineSlot(adSlotId, slotSize, id);

            if (!ctx.builtSlot) {
                return;
            }

            // Setup slot redered event to add ad_slotLoaded class
            window.googletag.pubads().addEventListener('slotOnload', ev => {
                const evSlotId = ev.slot.getSlotId();

                if (adSlotId === evSlotId.getName()) {
                    ctx.classList.add(AD_SLOT_LOADED_CLASSNAME);
                }
            });

            if (self.sizeOverrideEnabled && self.positionTargetId) {
                const positionTarget = `${self.positionTargetId}_${
                    slotSize[0]
                }x${slotSize[1]}`;
                ctx.builtSlot.setTargeting('pos', positionTarget);
            } else if (currentAdConfig.positionTargets) {
                if (currentAdConfig.positionTargets[breakpoint]) {
                    ctx.builtSlot.setTargeting(
                        'pos',
                        currentAdConfig.positionTargets[breakpoint]
                    );
                }
            }

            if (!cookieImpressions || cookieImpressions === '1') {
                ctx.builtSlot.setTargeting('newsession', '1');
            }
            ctx.builtSlot.setTargeting('location.href', window.location.href);
            ctx.builtSlot.setTargeting('location.host', window.location.host);
            ctx.builtSlot.setTargeting(
                'location.pathname',
                window.location.pathname
            );

            if (targeting) {
                Object.keys(targeting).forEach(key => {
                    ctx.builtSlot.setTargeting(
                        key,
                        decodeURIComponent(targeting[key])
                    );
                });
            }

            ctx.builtSlot
                .defineSizeMapping(mapping.j)
                .setCollapseEmptyDiv(true)
                .addService(window.googletag.pubads());

            window.googletag
                .pubads()
                .refresh([ctx.builtSlot], { changeCorrelator: false });
            window.googletag.pubads().enableAsyncRendering();
        });
        /* eslint-enable */
    }

    onObserved(entries, observer) {
        // Check if entry has isIntersecting property, because on S6 at time of this comment
        // that is not a thing...
        if ('isIntersecting' in entries[0]) {
            if (!entries[0].isIntersecting) {
                return;
            }
        }

        this[initiateAd]();
        observer.disconnect();
    }

    /**
     * Refreshes the slot to show a new size
     * @method refreshAd
     */
    [refreshAd]() {
        const self = this;

        /* eslint-disable */
        window.googletag.cmd.push(function(ctx = self) {
            window.googletag.destroySlots([ctx.builtSlot]);
        });
        /* eslint-enable */

        this[initiateAd]();
    }

    /**
     * Refreshes the slot on a breakpoint change to show the new size
     * @method onUpdated
     * @param {Object} ev
     */
    onUpdated(ev) {
        // if we're overriding the size of the ad, there is no reason to have any breakpoint responsiveness
        if (this.sizeOverrideEnabled) {
            return;
        }

        if (ev.detail.type === EV_RESIZE_BREAKPOINT_CHANGE) {
            this[refreshAd]();
        }

        if (ev.detail.type === EV_RESIZE_WIDTH_COMPLETE) {
            if (ev.detail.innerWidth > 668 && ev.detail.innerWidth < 1024) {
                this[refreshAd]();
            }
        }
    }
}

export default PFAdElement;
