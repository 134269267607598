import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import {
    EV_UI_ACTIVATE,
    EV_UI_DEACTIVATE,
    EV_MODAL_DID_ACTIVATE,
    EV_MODAL_DID_DEACTIVATE,
    EV_ASYNC_START,
    EV_ASYNC_END,
    EV_RESIZE_BREAKPOINT_CHANGE,
} from '../../constants/events';
import { KEY_ESCAPE, KEY_ESC } from '../../constants/keyboard';
import { offset } from '../../../../core/scripts/util/dom';
import platform from 'platform';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-modal';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    // INPUT: `${ELEMENT_TAG_NAME}-input`,
    MODAL_CONTENT: `[${ELEMENT_TAG_NAME}-content]`,
    CLOSE_BTN: `[${ELEMENT_TAG_NAME}-close-btn]`,
};

/**
 * Custom element for a basic modal.
 *
 * REQUIRED BASE MARKUP:
 * ---
 * <pfdc-modal
 *     id="Modal"
 *     class="modal"
 *     role="dialog"
 *     aria-labelledby="Modal_Header"
 *     style="display: none;">
 *         <div pf-modal-content class="modal-content">
 *             <!-- Your markup/content here -->
 *             <pf-element on-click="ui.deactivate" data-target="#Modal">
 *                 <button class="modal-closeBtn" type="button" aria-label="Close this dialog">
 *                     Close this dialog</button>
 *             </pf-element>
 *         </div>
 * </pfdc-modal>
 * ---
 *
 * @extends PFElement
 */
export class PFDCModalElement extends PFElement {
    /**
     * Whether element has 'open' attribute.
     * Set this attribute if you want the modal to open on init.
     * @return {boolean}
     */
    get isOpen() {
        return this.hasAttribute('open');
    }

    /**
     * Whether element has 'open' attribute.
     * Set this attribute if you want the modal to open on init.
     * @return {boolean}
     */
    get openOnHash() {
        if (!this.hasAttribute('open-hash')) {
            return false;
        }

        if (this.getAttribute('open-hash') === window.location.hash.substr(1)) {
            return true;
        }

        return false;
    }

    /**
     * Optionally set the desired display type when animating.
     * Flex by default.
     * @return {String}
     */
    get displayType() {
        return this.getAttribute('display-type') || 'flex';
    }

    /**
     * Optionally deactivate at a given breakpoint.
     * @return {String}
     */
    get deactivateAtBreakpoint() {
        return this.getAttribute('deactivate-at-breakpoint') || null;
    }

    get onHide() {
        return this.getAttribute('on-hide');
    }

    get shouldFocusFirstFocusable() {
        if (this.hasAttribute('focus-first-focusable')) {
            return this.getAttribute('focus-first-focusable') === 'false'
                ? false
                : true;
        }

        return true;
    }

    /**
     * An instance of the element is created or upgraded. Useful for initializing state,
     * settings up event listeners, or creating shadow dom.
     */
    constructor() {
        super();

        /**
         * Elements to trap focus within when this element is activated
         * @type {Array}
         */
        this.lockFocusWithin = [this];

        /**
         * Lock scrolling of the page behind the modal
         * @type {boolean}
         */
        this.shouldLockScroll = true;

        /**
         * Focus first element inside this element when activated
         * @type {boolean}
         */
        this.focusFirstFocusable = true;

        /**
         * Refocus the triggering element when this element is deactivated
         * @type {boolean}
         */
        this.refocusTriggerOnDeactivate = true;

        /**
         * Reference to class name applied to body on activation
         * @type {string}
         */
        this.activeModalClass = 'modal_isActive';

        /**
         * autoOpen is used to track the data-auto-open attribute on the element
         * @type {boolean}
         */
        this.autoOpen = false;

        /**
         * Used by app to close elements by ui group
         * @type {string}
         */
        this._uiGroup = 'global';

        this.addEventListener('keydown', this.onKeyDowned);
    }

    /**
     * Initialize this component
     */
    onInit() {
        /**
         * Reference to the content container
         * @type {element}
         */
        this.contentContainer = this.querySelector(
            ELEMENT_SELECTORS.MODAL_CONTENT
        );

        this.focusFirstFocusable = this.shouldFocusFirstFocusable;

        this.defineAnimations();

        /**
         * Reference to the close button
         * @type {element}
         */
        this.closeBtn = this.querySelector(ELEMENT_SELECTORS.CLOSE_BTN);

        if (this.isOpen || this.openOnHash) {
            this.dispatchAction(EV_UI_ACTIVATE, { target: this });
        } else {
            this.contentContainer.style.display = 'none';
            // this.style.opacity = 0;
            // Something somewhere is applying an inline style (opacity: 1) to the modals,
            // which causes a flickering effect the first time you open the modal.
            // TODO: find what is adding opacity: 1 to pfdc-modal.
        }
    }

    /**
     * App calls activate to show this element
     */
    async activate() {
        await super.activate();

        await this.show();
        this.dispatchAction(EV_MODAL_DID_ACTIVATE);
    }

    /**
     * App calls deactivate to hide this element
     */
    async deactivate() {
        await super.deactivate();

        await this.hide();
        this.dispatchAction(EV_MODAL_DID_DEACTIVATE);
    }

    /**
     * Shows the modal
     */
    async show() {
        this.style.display = this.displayType;
        document.body.classList.add(this.activeModalClass);

        // !!!
        // iOS 11 input caret render bug fix
        // TODO: remove this when the bug is fixed
        if (platform.os.toString().includes('iOS 11')) {
            document.body.style.position = 'fixed';
        }
        // !!!

        await this.animationEngine.runAnimation(this.fadeInTimeline);

        setTimeout(() => {
            this.classList.add('u-overflowScrollTouch');
        }, 0);
    }

    /**
     * Hide the modal
     */
    async hide() {
        document.body.classList.remove(this.activeModalClass);
        this.classList.remove('u-overflowScrollTouch');
        // !!!
        // iOS 11 input caret render bug fix
        // TODO: remove this when the bug is fixed
        if (platform.os.toString().includes('iOS 11')) {
            document.body.style.position = 'static';
        }
        // !!!

        await this.animationEngine.runAnimation(this.fadeOutModal);

        if (this.onHide) {
            this.dispatchAction(this.onHide);
        }
    }

    /**
     * Handles update events from the app level
     * @param {Object} ev event object
     */
    onUpdated(ev) {
        const { detail } = ev;
        if (detail.type === EV_ASYNC_START) {
            this.closeBtn.disabled = true;
            return;
        }
        if (detail.type === EV_ASYNC_END) {
            this.closeBtn.disabled = false;
            return;
        }
        if (detail.type === EV_RESIZE_BREAKPOINT_CHANGE) {
            if (
                this.deactivateAtBreakpoint &&
                this.deactivateAtBreakpoint === detail.currentBreakpoint
            ) {
                this.dispatchAction(EV_UI_DEACTIVATE, { target: this });
            }
            return;
        }
    }

    /**
     * Handles keydown events
     * @param {Object} ev event object
     */
    onKeyDowned(ev) {
        switch (ev.key) {
            case KEY_ESC:
            case KEY_ESCAPE:
                this.handleEscape();
                break;
            default:
                break;
        }
    }

    /**
     * Handles escape key events
     */
    handleEscape() {
        this.dispatchAction(EV_UI_DEACTIVATE, { target: this });
    }

    defineAnimations() {
        this.fadeInTimeline = this.animationEngine.defineTimeline(
            [
                {
                    type: 'fadeIn',
                    element: this,
                    opts: {
                        valueBefore: [
                            { display: this.displayType },
                            { opacity: 0 },
                        ],
                    },
                },
                {
                    type: 'fadeIn',
                    element: this.contentContainer,
                },
            ],
            {
                easing: [0.25, 0.46, 0.45, 0.94],
            }
        );

        this.fadeOutModal = this.animationEngine.defineAnimation({
            type: 'fadeOut',
            element: [this, this.contentContainer],
            opts: {
                easing: [0.55, 0.085, 0.68, 0.53],
            },
        });
    }
}

export default PFDCModalElement;
