import SVGNames from '../../../../data/svg-list.json';

/**
 * @type {Object}
 */
export const ICON_COLORS_CLASS_MAP = {
    primary: 'm-icon_colorPrimary',
    primaryS1: 'm-icon_colorPrimaryS1',
    primaryS2: 'm-icon_colorPrimaryS2',
    primaryT1: 'm-icon_colorPrimaryT1',
    dark: 'm-icon_colorDark',
    stroke: 'm-icon_colorStroke',
    white: 'm-icon_colorWhite',
    alt: 'm-icon_colorAlt',
    altS1: 'm-icon_colorAltS1',
    error: 'm-icon_colorError',
    transparent: 'm-icon_colorTransparent',
};

/**
 * @type {Object}
 */
export const ICON_SIZES_CLASS_MAP = {
    xs: 'icon_xs',
    sm: 'icon_sm',
    md: 'icon_md',
    lg: 'icon_lg',
    xl: 'icon_xl',
    xxl: 'icon_xxl',
    ['10']: 'icon_s10',
    ['14']: 'icon_s14',
    ['18']: 'icon_s18',
    ['22']: 'icon_s22',
    ['40']: 'icon_s40',
    ['45']: 'icon_s45',
    ['60']: 'icon_s60',
    ['72']: 'icon_s72',
};

/**
 * @type {Object}
 */
export const ICON_VERTICAL_ALIGN_CLASS_MAP = {
    middle: 'm-icon_alignMiddle',
};

export const ICON_NAMES_LIST = SVGNames.iconNames;
