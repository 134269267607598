const optinsDef = require('../../../core/scripts/data/opt-in-rules.json');

class OptinsConfigService {
    /**
     * Fetch optins based on provided params
     *
     * @param {{ formId: string, corporation: string|Array<string>, country: string }} params
     */
    getOptinRulesFromConfig(params) {
        const allRules = [];

        const corporations = Array.isArray(params.corporation)
            ? params.corporation
            : params.corporation.split(',');

        for (let i = 0; i < corporations.length; i++) {
            // normalize data to match expected structure from optinsDef
            const corporation = corporations[i].toUpperCase();
            const country = params.country.toUpperCase();

            try {
                const countryOptions = optinsDef[params.formId][corporation];
                const rules =
                    country in countryOptions
                        ? countryOptions[country]
                        : countryOptions._default;

                allRules.push(rules);
            } catch (err) {}
        }

        return allRules;
    }
}

const instance = new OptinsConfigService();
export default instance;
