import { pollUntilConditionMet } from '../util/util';
import _mapValues from 'lodash/mapValues';
import _isString from 'lodash/isString';
import _isEmpty from 'lodash/isEmpty';

function _mapObjectsToLowerCase(obj) {
    if (_isEmpty(obj)) {
        return {};
    }

    return _mapValues(obj, value => {
        return _isString(value) ? value.toLowerCase() : value;
    });
}

export async function trackEvent(components) {
    const mappedComponents = {
        eventCategory: components.category,
        eventAction: components.action,
        eventLabel: components.label,
        dimensions: !_isEmpty(components.dimensions) ? _mapObjectsToLowerCase(components.dimensions) : {},
        metrics: components.metrics,
    };

    const additionalAnalytics = _mapObjectsToLowerCase(components.additional);
    const eventName = components.event;

    await pollUntilConditionMet(
        () => {
            return typeof window.dataLayer !== 'undefined';
        },
        1000,
        20
    );
    try {
        const analytics = Object.assign({}, mappedComponents, additionalAnalytics);
        // Allows for client side analytics debugging -- add 'purDebug = true' to console
        if (typeof window.purDebug !== 'undefined' && typeof window.dataLayer !== 'undefined') {
            console.group(
                `%cEvent: ${eventName || 'No Event Name Set'}`,
                'background: purple; color: white; font-size:14px;'
            );
            if (!eventName) {
                console.groupCollapsed('Trace');
                console.trace();
                console.groupEnd();
            }
            console.groupCollapsed('dataLayer');
            console.table(additionalAnalytics);
            console.groupEnd();
            console.groupCollapsed('dimensions');
            console.table(analytics.dimensions);
            console.groupEnd();
            if (window.purinaTrackEvent) {
                // Override window.purinaTrackEvent function once
                if (typeof window.debugSet === 'undefined') {
                    window.debugSet = true;
                    const debugPurina = window.purinaTrackEvent;
                    window.purinaTrackEvent = args => {
                        console.group('Purina Track Event');
                        console.group('Analytics');
                        console.log(JSON.stringify(args, 0, 2));
                        console.groupEnd();
                        console.groupCollapsed('metrics');
                        console.table(args.metrics);
                        console.groupEnd();
                        console.groupEnd();
                        console.groupEnd();
                        return debugPurina(args);
                    };
                }
            } else {
                console.groupCollapsed('Analytics');
                console.log(JSON.stringify(analytics, 0, 2));
                console.groupEnd();
                console.groupEnd();
            }
        }
        window.purinaTrackEvent(analytics);
    } catch (e) {
        if (window.PF && window.PF.debugAnalytics) {
            console.log(e);
        }
        return;
    }
}
