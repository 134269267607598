import sessionApiService from './sessionAPIService';

/**
 * Some extra delay in ms
 * @type {number}
 */
const DELAY_BUFFER = 5 * 60 * 1000; // 5 minutes to ms

/**
 * Force a page reload the page after set amount of time
 */
export default class AutoLogout {
    static async init() {
        const sessionTTL = await sessionApiService.getTTL();

        if (!sessionTTL) {
            return;
        }

        setTimeout(() => {
            location.reload();
        }, (sessionTTL + DELAY_BUFFER));
    }
};
