import React, { Component } from 'react';

import SvgIcon from '../../../../breeds/specifics/SvgIcon';

import { analyticsConsumer002 } from '../../../../../analytics/dotcom';

class SocialRedirectLayout extends Component {
    /**
     * @param {Event} ev
     */
    handleRegisterClick = () => {
        this.props.onRegisterClick(analyticsConsumer002);
    };

    render() {
        return (
            <div className="loginModal">
                <div className="loginModal-section">
                    <h2 className="loginModal-section-hdg u-vr6x" tabIndex="-1">
                        {/* eslint-disable-next-line quotes */}
                        {"We couldn't get your email address from Facebook"}
                    </h2>
                    <p className="txt m-txt_lg u-vr4x">
                        If you still want to use Facebook:
                    </p>
                    <ol className="numList">
                        <li>
                            <span className="txt m-txt_lg">
                                Go to Facebook and{' '}
                            </span>
                            <a
                                href="https://www.facebook.com/settings?tab=account&section=email&view"
                                className="txt txt_link m-txt_lg m-txt_underline"
                                target="_blank"
                            >
                                {'add your email address'}
                            </a>
                        </li>
                        <li>
                            <span className="txt m-txt_lg">Retry </span>
                            {this.props.renderFacebookButton({
                                customStyle: () => {
                                    return (
                                        <button className="txt txt_link m-txt_lg m-txt_underline">
                                            {'signup with Facebook'}
                                        </button>
                                    );
                                },
                                onSocialClick: params => {
                                    analyticsConsumer002(params);
                                },
                            })}
                        </li>
                    </ol>
                </div>
                <div className="loginModal-section loginModal-section_footer">
                    <div className="u-vr3x">
                        {this.props.renderGoogleButton({
                            cta: 'Sign up with ',
                            onSocialClick: params => {
                                analyticsConsumer002(params);
                            },
                        })}
                    </div>
                    <button
                        type="button"
                        onClick={this.handleRegisterClick}
                        className="btn btn_borderPrimary m-btn_iconLeft m-btn_normalCase m-btn_full"
                        data-test="Sign_Up_With_Email_Button"
                    >
                        <span className="btn-icon">
                            <SvgIcon svgId="email" size="18" color="primary" />
                        </span>
                        {'Sign up with email'}
                    </button>
                </div>
            </div>
        );
    }
}

export default SocialRedirectLayout;
