import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../../breeds/specifics/SvgIcon';

import {
    analyticsConsumer019,
    analyticsConsumer002,
    analyticsConsumer010,
} from '../../../../analytics/dotcom';

import { CONTEXT_NAMES, CONTEXT, LOG_IN_COPY } from '../utilities/copyMap';

class LoginLayout extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        contextName: PropTypes.oneOf(CONTEXT_NAMES),
        children: PropTypes.element,
        forgotPasswordHref: PropTypes.string.isRequired,
        onSignUpClick: PropTypes.func.isRequired,
        renderGoogleButton: PropTypes.func,
        renderFacebookButton: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        contextName: CONTEXT.GENERIC,
    };

    /* *******************************************
     * Events
     ********************************************/

    /**
     * @param {Event} ev
     */
    handleSignUpClick = ev => {
        ev.preventDefault();
        this.props.onSignUpClick(analyticsConsumer019);
    };

    /**
     * @param {Event} ev
     */
    handleForgotPasswordClick = ev => {
        ev.preventDefault();
        this.props.onForgotPasswordClick(analyticsConsumer010);
    };

    /**
     * @param {Event} ev
     */
    handleRegisterClick = ev => {
        ev.preventDefault();
        this.props.onRegistrationClick(analyticsConsumer002);
    };

    get contextCopy() {
        if (LOG_IN_COPY[this.props.contextName]) {
            return LOG_IN_COPY[this.props.contextName];
        } else {
            return LOG_IN_COPY[CONTEXT.GENERIC];
        }
    }

    /* *******************************************
     * Render
     ********************************************/

    render() {
        return (
            <div className="loginModal">
                <div className="loginModal-section">
                    <div className="u-vr4x">
                        <h2
                            className="loginModal-section-hdg u-vr4x u-textCenter"
                            tabIndex="-1"
                        >
                            {this.contextCopy.heading}
                        </h2>
                        {this.contextCopy.subheading ? (
                            <p className="txt m-txt_xl u-vr4x u-textCenter">
                                {this.contextCopy.subheading}
                            </p>
                        ) : null}
                        <div className="u-vr3x">{this.props.children}</div>
                        <div className="u-textCenter">
                            <a
                                href={this.props.forgotPasswordHref}
                                onClick={this.handleForgotPasswordClick}
                                className="txt txt_link m-txt_lg m-txt_underline"
                            >
                                {'Forgot password?'}
                            </a>
                        </div>
                    </div>
                    {LOG_IN_COPY[this.props.contextName] &&
                    LOG_IN_COPY[this.props.contextName].footer ? (
                        LOG_IN_COPY[this.props.contextName].footer.jsx(
                            this[
                                LOG_IN_COPY[this.props.contextName].footer
                                    .onClick
                            ]
                        )
                    ) : (
                        <Fragment>
                            <div className="hrTitle u-vr5x">
                                <div className="hrTitle-body">
                                    <span className="txt m-txt_lg">
                                        {'or log in with'}
                                    </span>
                                </div>
                            </div>

                            <div className="grid grid_gutter">
                                <div className="grid-col grid-col_1/2@minLg">
                                    {this.props.renderFacebookButton({
                                        text: false,
                                        onSocialClick: params => {
                                            analyticsConsumer002(params);
                                        },
                                    })}
                                </div>
                                <div className="grid-col grid-col_1/2@minLg">
                                    {this.props.renderGoogleButton({
                                        text: false,
                                        onSocialClick: params => {
                                            analyticsConsumer002(params);
                                        },
                                    })}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
                {LOG_IN_COPY[this.props.contextName] &&
                LOG_IN_COPY[this.props.contextName].footer ? null : (
                    <div className="loginModal-section loginModal-section_footer">
                        <div>
                            <p className="txt m-txt_lg u-vr2x">
                                {'Need an account? '}
                                <button
                                    className="txt txt_link m-txt_lg m-txt_underline"
                                    onClick={this.handleSignUpClick}
                                >
                                    {'Sign up'}
                                </button>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default LoginLayout;
