import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _noop from 'lodash/noop';

import cssHelpers from '../../../lib/GlobalCssHelpers';

import {
    focusFirstFocusable,
    trapFocus,
    forceNVDADomUpdate,
} from '../../../lib/focusManager/index';

import { KEY_ESC, KEY_ESCAPE } from '../../../constants/keyboard';

/**
 * Container id of the element to portal this modal component into
 * @type {string}
 */
export const MODAL_CONTAINER_DEFAULT_ID = 'modal-container';

/**
 * Container id of the element to portal this modal component into
 * @type {string}
 */
const MODAL_CONTAINER_SELECTOR = `#${MODAL_CONTAINER_DEFAULT_ID}`;

/**
 * Default modal close btn label
 * @type {string}
 */
const MODAL_CLOSE_BTN_LABEL = 'Close dialog';

/**
 * Scroll lock class name
 * @type {string}
 */
const SCROLL_LOCK_CLASS = 's-scrollLock';

/**
 * Sets up markup for a modal, portals it to the modal-container, optionally can pass render
 * prop to get access to finer tune control of the close button
 * @class Modal
 * @extends React.Component
 */
class Modal extends Component {
    /**
     * @type {Object}
     */
    state = {
        initialScrollPos: 0,
    };

    /**
     * @type {string}
     * @static
     */
    static _containerSelector = MODAL_CONTAINER_SELECTOR;

    /**
     * @type {string}
     * @static
     */
    static get containerSelector() {
        return this._containerSelector;
    }

    /**
     * @param {string} selector
     * @type {string}
     * @static
     */
    static set containerSelector(selector) {
        this._containerSelector = selector;
    }

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        extensionClassNames: PropTypes.objectOf(PropTypes.bool),
        labelledbyId: PropTypes.string,
        onClose: PropTypes.func,
        onEscapeKeyDown: PropTypes.func,
        noClose: PropTypes.bool,
        dataTestValue: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        extensionClassNames: {},
        labelledbyId: null,
        onClose: _noop,
        onEscapeKeyDown: _noop,
        noClose: false,
        dataTestValue: '',
    };

    /**
     * @type {Object}
     */
    modalComponent = React.createRef();

    constructor(props) {
        super(props);

        this.appModalContainer = document.querySelector(
            Modal.containerSelector
        );
    }

    /**
     * Build classnames for modal
     * @method getClassNames
     * @private
     * @returns {string}
     */
    get rootClassNames() {
        const { extensionClassNames } = this.props;

        return buildClassNames({
            modal: true,
            ...extensionClassNames,
        });
    }

    componentDidMount() {
        const modalContainer = this.modalComponent.current;

        this.lockScroll();

        requestAnimationFrame(() => {
            // Trap focus and get ref to a function to untrap focus
            this.untrapFocusHandle = trapFocus(modalContainer);

            setTimeout(() => {
                // Focus the first focusable element
                focusFirstFocusable(modalContainer);
            }, 500);
        });
    }

    componentWillUnmount() {
        // Untrap focus
        if (this.untrapFocusHandle) {
            this.untrapFocusHandle();
        }

        forceNVDADomUpdate();

        this.unlockScroll();
    }

    /**
     * Lock scroll and set scrollTop and scrollbar offsets
     * @method lockScroll
     * @private
     */
    lockScroll() {
        requestAnimationFrame(() => {
            this.setState({ initialScrollPos: window.pageYOffset });
            cssHelpers.setScrollTop('scrollTop');
            document.documentElement.classList.add(SCROLL_LOCK_CLASS);
        });
    }

    /**
     * Unlock scroll and unset scrollTop and scrollbar offsets
     * @method unlockScroll
     * @private
     */
    unlockScroll() {
        requestAnimationFrame(() => {
            cssHelpers.removeProp('scrollTop');
            document.documentElement.classList.remove(SCROLL_LOCK_CLASS);
            window.scrollTo(0, this.state.initialScrollPos);
        });
    }

    /**
     * @private
     * @param {Event} ev
     */
    handleCloseClick = ev => {
        this.props.onClose();
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleKeyDown = ev => {
        if (this.props.noClose) {
            return;
        }
        if ([KEY_ESC, KEY_ESCAPE].includes(ev.key)) {
            this.props.onEscapeKeyDown();
        }
    };

    renderCloseButton() {
        return (
            <button
                data-test="Modal_Close_Button"
                className="modal-closeBtn"
                type="button"
                aria-label={MODAL_CLOSE_BTN_LABEL}
                onClick={this.handleCloseClick}
            >
                {MODAL_CLOSE_BTN_LABEL}
            </button>
        );
    }

    render() {
        const modal = (
            <div
                ref={this.modalComponent}
                className={this.rootClassNames}
                role="dialog"
                aria-labelledby={this.props.labelledbyId}
                onKeyDown={this.handleKeyDown}
                data-test={this.props.dataTestValue}
            >
                <div className="modal-content">
                    {typeof this.props.children === 'function' ? (
                        this.props.children({
                            closeBtn: this.renderCloseButton(),
                        })
                    ) : (
                        <Fragment>
                            {this.props.children}
                            {!this.props.noClose && this.renderCloseButton()}
                        </Fragment>
                    )}
                </div>
            </div>
        );

        return ReactDOM.createPortal(modal, this.appModalContainer);
    }
}

export default Modal;
