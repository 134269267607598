import { FIELDS } from './formType';

/**
 * @type {Object}
 */
export default {
    fields: {
        [FIELDS.USERNAME]: {
            label: 'Email',
            type: 'email',
            attrs: {
                'dataTestIds': {
                    field: 'Login_Username_Input',
                    error: 'Login_Username_Error_Message',
                    help: '',
                },
            },
        },
        [FIELDS.PASSWORD]: {
            type: 'password',
            attrs: {
                'dataTestIds': {
                    field: 'Login_Password_Input',
                    error: 'Login_Password_Error_Message',
                    help: '',
                },
            },
        },
        [FIELDS.NEXT]: {
            type: 'hidden',
        },
        [FIELDS.NEXT_PARAMS]: {
            type: 'hidden',
        },
        [FIELDS.LOGIN_PATH]: {
            type: 'hidden',
        },
    },
};
