import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { EV_UI_DEACTIVATE } from '../../../../core/scripts/constants/events';
import { SSO_MODAL_ACTIONS, handleSSOModal } from '../../util/ssoModalHelpers';
import socialLoginStateController from '../../react-components/specifics/SocialLogin/controller/SocialLoginStateController';
/**
 * # `pfdc-login-launcher` Element
 *
 * Wraps sign-in, register, or favorite buttons to prompt for login or registration
 *
 * <pfdc-login-launcher></pfdc-login-launcher>
 *
 * @class PFDCLoginLauncher
 * @extends PFDCElement
 *
 */
export default class PFDCLoginLauncher extends PFElement {
    ATTRIBUTES = {
        ANALYTIC_TRIGGER_LABEL: 'analytic-trigger-label',
        CONTEXT: 'launch-context',
        DISPLAY: 'launch-display',
        MOBILE: 'launch-mobile',
        FORM_DATA: 'form-data',
    };

    /**
     * Check for analytic trigger label (triggering ui element)
     */
    get analyticTriggerLabel() {
        return this.getAttribute(this.ATTRIBUTES.ANALYTIC_TRIGGER_LABEL);
    }

    /**
     * Check for context trigger (triggering page/section)
     */
    get launchContext() {
        return this.getAttribute(this.ATTRIBUTES.CONTEXT);
    }

    /**
     * Check for initial display value
     */
    get launchDisplay() {
        return this.getAttribute(this.ATTRIBUTES.DISPLAY);
    }

    /**
     * Check for mobile trigger
     * Only set if need to deactivate mobile menu
     */
    get launchMobile() {
        return this.getAttribute(this.ATTRIBUTES.MOBILE);
    }

    /**
     * Collect form data for the login and registration forms
     */
    get formData() {
        return JSON.parse(this.getAttribute(this.ATTRIBUTES.FORM_DATA));
    }

    /**
     * ## Lifecycle
     */

    onInit() {
        super.onInit();
        this.trigger = this.querySelector('button') || this.querySelector('a');
        this.trigger.addEventListener('click', this.onClick.bind(this));
    }

    onClick(ev) {
        ev.preventDefault();
        return this.launch(ev);
    }

    /**
     * Sends alert that field is now valid.
     *
     * @method alertValidField
     * @private
     */
    launch() {
        // Closes the mobile side menu
        if (this.launchMobile === 'true') {
            this.dispatchAction(EV_UI_DEACTIVATE, {
                trigger: this,
                target: '#Mobile_Menu',
            });
        }

        if (window.ssoFlagIsActive) {
            handleSSOModal(SSO_MODAL_ACTIONS.open, '');
        } else {
            // OLD Signin Modal
            socialLoginStateController.openLoginModal({
                analyticTriggerLabel: this.analyticTriggerLabel,
                context: this.launchContext,
                display: this.launchDisplay,
                formData: this.formData,
                refocusTarget: this.trigger,
            });
        }
    }
}
