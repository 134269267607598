import APIResponse from './APIResponse';
import axios from 'axios';
import _get from 'lodash/get';

export default class APIService {
    /**
     * Static Members
     */

    static MESSAGES = {
        GENERIC_ERROR: 'There was an error processing the request.',
    };

    static _registeredServices = {};

    static register(id, instance) {
        this._registeredServices[id] = instance;
    }

    static get(id) {
        return this._registeredServices[id];
    }

    static URL_PARSE_REGEX = /\{([^}]+)\}/g;
    static CLOSING_SLASH_REGEX = /([^:])\/\/+/g;

    static formDataFromObject(obj) {
        const formData = new FormData();
        // eslint-disable-next-line
        for (const key in obj) {
            formData.append(key, obj[key]);
        }

        return formData;
    }

    _pendingRequests = {};

    /**
     * Instance Members
     */

    get url() {
        return Object.getPrototypeOf(this).constructor.url || '';
    }

    _buildQueryStringBracketFormat(params) {
        return Object.entries(params)
            .map(item => {
                const val = item[1];

                // empty value filter
                if (!val || (Array.isArray(val) && val.length === 0)) {
                    return null;
                }

                const key = item[0];

                return Array.isArray(val)
                    ? val
                          .map(x => `${key}[]=${encodeURIComponent(x)}`)
                          .join('&')
                    : `${key}=${encodeURIComponent(val)}`;
            })
            .filter(x => x !== null)
            .join('&');
    }

    getUrl(options = { urlParams: {}, params: {} }) {
        const urlObj = {
            address: '',
            qsParams: '',
            fullUrl: '',
        };

        urlObj.address = options.urlAppend
            ? `${this.url}/${options.urlAppend}`
            : this.url;

        urlObj.address = urlObj.address
            // substitute in url params
            .replace(APIService.URL_PARSE_REGEX, (matchedString, urlParam) => {
                return options.urlParams[urlParam] || '';
            })
            // ensure only one trailing slash
            .replace(
                APIService.CLOSING_SLASH_REGEX,
                (matchedString, variableChar) => {
                    return `${variableChar}/`;
                }
            );

        if (options.params) {
            urlObj.qsParams = this._buildQueryStringBracketFormat(
                options.params
            );
        }

        urlObj.fullUrl = urlObj.qsParams
            ? `${urlObj.address}?${urlObj.qsParams}`
            : urlObj.address;

        return urlObj;
    }

    /**
     * @param {{ urlAppend: String, method: String, headers: Object, data: Object, urlParams: Object, params: Object } options
     */
    // TODO: query() should probably not be public, or at least should be named something different
    // TODO: implementers of this service should naturally go toward queryAndProcess(), and only opt to go straight to query() if specifically desired
    async query(options = { lateResponseCancelling: false }, requestInterceptor) {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
        };

        if (options.headers) {
            Object.assign(headers, options.headers);
        }

        const fetchOptions = {
            method: options.method || 'GET',
            headers,
            data: options.data || null,
        };

        const identifier = `${Date.now()}-${Math.random()}`;
        const urlObj = this.getUrl(options);

        this._pendingRequests[urlObj.address] = identifier;

        if (requestInterceptor) {
            requestInterceptor(urlObj.fullUrl);
        }

        const response = await axios(urlObj.fullUrl, fetchOptions);
        response.json = () => {
            return Promise.resolve(response.data);
        };

        if (
            options.lateResponseCancelling &&
            this._pendingRequests[urlObj.address] !== identifier
        ) {
            // return custom response
            return {
                async json() {
                    return {
                        customResponse: true,
                        responseCancelled: true,
                    };
                },
            };
        }

        return response;
    }

    async queryAndProcess(options, requestInterceptor) {
        try {
            const response = await this.query(options, requestInterceptor);
            return this._processResponse(response);
        } catch (err) {
            return this._processError(err);
        }
    }

    /**
     * Response Handling
     */

    /**
     * @param {Response}
     * @returns {APIResponse}
     */
    async _processResponse(response) {
        const responseData = await response.json();

        return (
            // custom response handling (internally set by APIService functionality)
            this._processInternalResponses(responseData) ||
            // server-set
            this._processServerResponses(responseData) ||
            // if no errors occurred, return a successful response
            new APIResponse(this._extractDataFromServerResponse(responseData))
        );
    }

    /**
     * Default function used to extract data out of the server's response
     *
     * NOTE: ideally, the server would be responding in a uniform way for all API requests.
     * It currently does not; we can override on a case by case basis using this function.
     *
     * @param {Object} responseData
     */
    _extractDataFromServerResponse(responseData) {
        return responseData;
    }

    /**
     * @param {Error} err
     * @returns {APIResponse}
     */
    _processError(err) {
        return APIResponse.errored(
            err.message,
            _get(err, 'response.data', null)
        );
    }

    /**
     * Internal Response Processing
     */

    _processInternalResponses(responseData) {
        if (!responseData.customResponse) {
            return null;
        }

        return this._checkResponseCancelled(responseData) || null;
    }

    _checkResponseCancelled(responseData) {
        if (responseData.responseCancelled) {
            return APIResponse.cancelled;
        }

        return null;
    }

    /**
     * Server Response Processing
     */

    _processServerResponses(responseData) {
        return this._checkServerError(responseData) || null;
    }

    // TODO: are there any more ways the server can indicate an error?
    // if so, it would be ideal to normalize them so we can have consistent error handling
    _checkServerError(responseData) {
        // TODO: determine what to do with 204 response
        if (!responseData) {
            return null;
        }

        if ('success' in responseData && !responseData.success) {
            return new APIResponse(responseData, 'API Error');
        }

        if ('error' in responseData && responseData.error) {
            return new APIResponse(responseData, 'API Error');
        }

        return null;
    }
}
