import Utils from '../Utils';

/**
 * Enum for state update mode.
 *
 * @readonly
 * @enum {string}
*/
const STATE_UPDATE_MODE = {
    SET: 'SET',
    PATCH: 'PATCH',
    TARGET_SET: 'TARGET_SET',
};

/**
 * A state management library
 *
 * Provides functionality for retrieving, setting, and
 * patching a state tree, as well as nested sub-states.
 */
export default class StateController {
    /**
     * @private
     *
     * @property {bool} _debugEnabled
     */
    _debugEnabled = false;

    /**
     * @private
     *
     * @property {object} _defaultState
     */
    _defaultState = {};

    /**
     * Returns private _defaultState
     *
     * @property {object} defaultState
     */
    get defaultState() {
        return this._defaultState;
    }

    /**
     * @private
     *
     * @property {object} _subStates
     */
    _subStates = {};

    /**
     * @private
     *
     * @property {object} _state
     */
    _state = null;

    /**
     * @private
     *
     * @property {object} _cachedState
     */
    _cachedState = null;

    /**
     * @private
     *
     * @property {array} _subscriptions
     */
    _subscriptions = [];


    /**
     * Initializes the state controller
     *
     * @param {object} defaultState (optional) Default state override
     */
    constructor(defaultState) {
        if (defaultState) {
            this._defaultState = defaultState;
        }
    }


    /**
     * Outputs provided debugging information if debugging is enabled
     *
     * @param {...any} args
     */
    debug(...args) {
        if (!this._debugEnabled) {
            return;
        }

        console.info(...args);
    }


    /**
     * Getter that recursively constructs a state object for use
     *
     * If caching is enabled, this will return a cached version of
     * the state.
     */
    get state() {
        if (this._cachedState) {
            // NOTE: this could lead to unexpected behavior if
            // a consumer of this state modifies the state object
            // they receive.  This previously was "safe" due to cloning,
            // but in an effort to improve performance, cloning was
            // removed.  Revisit if this becomes problematic.

            // TODO: try returning a deep cloned cached state and measure performance;
            // TODO: this would defeat part of the purpose for caching the state, but
            // would remedy the NOTE concerns above.
            return this._cachedState;
        }

        if (this._state === null) {
            this.setDefaultState();
        }

        const state = Utils.ObjectUtils.cloneDeep(this._state);

        // eslint-disable-next-line
        for (const key in this._subStates) {
            state[key] = this._subStates[key].state;
        }

        this._cachedState = state;

        return this._cachedState;
    }


    /**
     * Returns a portion of the state object found at the provided `address`
     *
     * Example: If the state object is as follows:
     *     { a: { b: { c: 4 } } }
     *
     * stateControllerInstance.stateAt('a.b.c') will return 4
     *
     * @param {string} address Location relative to the root of this state controller
     *
     * @returns {any}
     */
    stateAt(address) {
        return Utils.ObjectUtils.access(address, this.state);
    }


    /**
     * Adds an instance of another StateController as a child of this StateController
     *
     * @param {string} id Substate id
     * @param {StateController} stateController An instance of the StateController to set
     */
    addSubstate(id, stateController) {
        this._subStates[id] = stateController;

        stateController.subscribe(payload => {
            const modifications = {
                [id]: Utils.ObjectUtils.cloneDeep(payload.modifications),
            };

            if (this._cachedState) {
                // update cache
                this._cachedState[id] = stateController.state;
            }

            this.debug('\n(/|/) |_ Subscription triggered (parent state observe)');

            this._broadcastChanges(payload.stateUpdateMode, modifications, payload.options);
        });
    }


    /**
     * Provides a way to listen for state changes.  Optionally, a specific
     * location within the state can be observed if an address is provided.
     *
     * @param {function} callbackFn Function to execute upon state changing
     * @param {string} observeAddress (optional) Address of location within the state to observe
     *
     * @returns {function} Unsubscribe function
     */
    subscribe(callbackFn, observeAddress = null) {
        if (typeof callbackFn !== 'function') {
            throw new TypeError(
                'StateController.subscribe(): invalid argument passed as callbackFn'
            );
        }

        let normalizedWhatToObserve = null;

        if (observeAddress) {
            normalizedWhatToObserve = (Array.isArray(observeAddress))
                ? observeAddress
                : [observeAddress];

            for (let i = 0; i < normalizedWhatToObserve.length; i++) {
                normalizedWhatToObserve[i] = normalizedWhatToObserve[i].trim();
            }
        }

        const newItem = {
            whatToObserve: normalizedWhatToObserve,
            callbackFn,
        };

        this._subscriptions.push(newItem);

        const unsubscribeFn = () => {
            this._unsubscribe(newItem);
        };

        return unsubscribeFn;
    }


    /**
     * Unsubscribe a listener
     *
     * @private
     * @param {object} item Internally created unsubscribe item
     *
     * @returns {bool}
     */
    _unsubscribe(item) {
        const index = this._subscriptions.indexOf(item);

        if (index === -1) {
            return false;
        }

        this._subscriptions.splice(index, 1);
        return true;
    }


    /**
     * Resets state to default
    */
    setDefaultState() {
        this._state = Utils.ObjectUtils.cloneDeep(this.defaultState);
        this._afterStateUpdated();
    }

    setDefaultStateAndBroadcast() {
        this.setState(Utils.ObjectUtils.cloneDeep(this.defaultState));
    }


    /**
     * Sets the state based on what is provided in `newState`
     *
     * Additionally, arbitrary data that may be useful can be provided
     * via `options`, which will then be passed to any subscribers.
     *
     * @param {any} newState New state
     * @param {object} options Object of options to be provided to listeners
     */
    setState(newState, options) {
        const reducedModifications = this._reduceAndDelegateModifications(
            STATE_UPDATE_MODE.SET,
            newState,
            options
        );

        if (Object.keys(reducedModifications).length > 0) {
            this._state = Object.assign(this.state, reducedModifications);
        }

        this.debug('\n(/|/) |_ Subscription triggered (setState)', this);

        this._broadcastChanges(STATE_UPDATE_MODE.SET, newState, options);

        this._afterStateUpdated();
    }


    /**
     * Sets the state based on what is provided in `modifications` at the
     * location provided in `address`
     *
     * Additionally, arbitrary data that may be useful can be provided
     * via `options`, which will then be passed to any subscribers.
     *
     * @param {any} modifications Changes to be stored
     * @param {string} address Location within the state object to store the changes
     * @param {object} options Object of options to be provided to listeners
     */
    setStateAtAddress(modifications, address, options) {
        const pieces = address.split('.');
        const potentialSubstateId = pieces.shift();

        // determine if we should delegate
        if (potentialSubstateId in this._subStates) {
            // delegate to substate
            this._subStates[potentialSubstateId].setStateAtAddress(modifications, pieces.join('.'));
        } else {
            // process at this state level
            this._state = Utils.ObjectUtils.setAtPath(this.state, address, modifications);
            this._afterStateUpdated();
        }

        this.debug('\n(/|/) |_ Subscription triggered (setStateAtAddress)', this);

        // prepend structure of modifications so we may broadcast to subscribers
        // of the correct path that was changed
        const fullyQualifiedModifications = Utils.ObjectUtils.prependStructureWithAddress(
            address,
            modifications
        );

        this._broadcastChanges(STATE_UPDATE_MODE.TARGET_SET, fullyQualifiedModifications, options);
    }


    /**
     * Patches the state based on what is provided in `modifications`
     *
     * That which is provided in `modifications` will be copied over that which
     * already exists within the state tree.
     *
     * Additionally, arbitrary data that may be useful can be provided
     * via `options`, which will then be passed to any subscribers.
     *
     * @param {any} modifications Changes to be stored
     * @param {object} options Object of options to be provided to listeners
     */
    patchState(modifications, options) {
        const reducedModifications = this._reduceAndDelegateModifications(
            STATE_UPDATE_MODE.PATCH,
            modifications,
            options
        );

        if (Object.keys(reducedModifications).length > 0) {
            // non-destructive copy
            this._state = Utils.ObjectUtils.copy(reducedModifications, this.state);
            this._afterStateUpdated();
        }

        this.debug('\n(/|/) |_ Subscription triggered (patchState)', this);

        this._broadcastChanges(STATE_UPDATE_MODE.PATCH, modifications, options);
    }


    /**
     * Processes `modifications`, looking for portions that should be delegated
     * to added substate controllers.
     *
     * @private
     *
     * @param {STATE_UPDATE_MODE} stateUpdateMode
     * @param {any} modifications Changes to be processed
     * @param {object} options Object of options to be provided to listeners
     *
     * @returns {object} Reduced modifications
     */
    _reduceAndDelegateModifications(stateUpdateMode, modifications, options) {
        const reducedClonedModifications = Utils.ObjectUtils.cloneDeep(modifications);
        for (const key in this._subStates) {
            if (key in reducedClonedModifications === false) {
                continue;
            }

            const substatePatch = reducedClonedModifications[key];

            if (typeof substatePatch !== 'object') {
                throw new Error(
                    'Bad patch; attempting to apply patch including substate which is not an object'
                );
            }

            switch (stateUpdateMode) {
                case STATE_UPDATE_MODE.SET:
                    this._subStates[key].setState(substatePatch, options);
                    break;
                case STATE_UPDATE_MODE.PATCH:
                    this._subStates[key].patchState(substatePatch, options);
                    break;
            }

            reducedClonedModifications[key] = null;
            delete reducedClonedModifications[key];
        }

        return reducedClonedModifications;
    }


    /**
     * Broadcast changes to subscribers
     *
     * @param {STATE_UPDATE_MODE} stateUpdateMode
     * @param {any} modifications Changes to be processed
     * @param {object} options Object of options to be provided to listeners
     */
    _broadcastChanges(stateUpdateMode, modifications, options = {}) {
        const flattenedModifications = Utils.ObjectUtils.flatten(modifications);

        const subscriptionsBroadcasted = [];

        this.debug('      |_____ Processing changes...', modifications);
        for (let i = 0; i < this._subscriptions.length; i++) {
            const subscription = this._subscriptions[i];

            const outTriggeredModifications = [];
            if (this._shouldTriggerBroadcast(
                subscription,
                flattenedModifications,
                outTriggeredModifications
            )) {
                if (subscriptionsBroadcasted.indexOf(subscription) !== -1) {
                    continue;
                }

                this.debug('          |_____ Calling callback...', subscription);
                subscription.callbackFn({
                    stateUpdateMode,
                    modifications: Utils.ObjectUtils.cloneDeep(modifications),
                    flattenedModifications: Utils.ObjectUtils.cloneShallow(flattenedModifications),
                    trigger: outTriggeredModifications,
                    options,
                });

                subscriptionsBroadcasted.push(subscription);
            }
        }
    }


    /**
     * Determine whether a broadcast should be executed
     *
     * @param {object} subscription Internally created subscription object
     * @param {object} flattenedModifications Object containing changes, keyed by the fully
     * structured address of the changes, with the value of what was changed.
     * @param {array} outTriggeredModifications An array of the items that
     * changed which triggered the broadcast
     *
     * @returns {bool}
     */
    _shouldTriggerBroadcast(subscription, flattenedModifications, outTriggeredModifications) {
        if (subscription.whatToObserve === null) {
            return true;
        }

        let shouldTrigger = false;

        for (let i = 0; i < subscription.whatToObserve.length; i++) {
            if (subscription.whatToObserve[i] in flattenedModifications) {
                shouldTrigger = true;
                outTriggeredModifications.push(subscription.whatToObserve[i]);
            }
        }

        return shouldTrigger;
    }


    /**
     * Lifecycle function called upon the event of the state changing
    */
    _afterStateUpdated() {
        this._cachedState = null;
    }
}
