import StateController from '../../../core/scripts/lib/wirey/state/StateController';
import userMeAPIService from '../../../core/scripts/services/userMeAPIService';
import UserMeModel from '../../../core/scripts/models/UserMeModel';

class UserMeStateController extends StateController {
    _defaultState = new UserMeModel().raw;

    constructor() {
        super();
        this.refreshUserMeData();
    }

    async refreshUserMeData() {
        this.setState((await userMeAPIService.refreshUserMe()).raw);
    }

    get isLoggedIn() {
        return this.state.user.isLoggedIn;
    }
}

const userMeStateController = new UserMeStateController();
export default userMeStateController;
