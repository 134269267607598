import EventActionMixinBase from './EventActionMixinBase';
import ActionParser from '../ActionParser';

export default class BlurMixin extends EventActionMixinBase {
    static _attributeName = 'mix-blur';

    onBlurEvent(e) {
        ActionParser.executeActions(this._actions, { $mixin: this, $element: this._ele, $event: e }, e);
    }
}
