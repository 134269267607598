import { PFElement } from '../pf-element/element';
import { parseJsonAttribute } from '../../util/dom';

const EVENTS = ['click', 'focusout', 'change', 'blur'];

/**
 *
 * @class PFEnsighten
 * @extends PFElement
 */
export class PFEnsightenElement extends PFElement {
    get atBreakpoint() {
        return parseJsonAttribute(this, 'at-breakpoint');
    }

    onInit() {
        EVENTS.forEach(event => {
            if (this.hasAttribute(`on-${event}`) || this.hasAttribute('hub')) {
                let useCapture = false;
                if (event === 'blur') {
                    useCapture = true;
                }
                this.addEventListener(event, this.onEvented, useCapture);
            }
        });

        if (this.hasAttribute('on-load')) {
            this.onEvented({
                type: 'load',
            });
        }
    }

    mustTriggerAtThisBreakpoint() {
        return (
            !this.hasAttribute('at-breakpoint') ||
            this.atBreakpoint.includes(this.getCurrentBreakpoint())
        );
    }

    onEvented(ev) {
        if (!this.mustTriggerAtThisBreakpoint()) {
            return;
        }
        let eventId;
        let analyticKey;
        if (this.hasAttribute('hub')) {
            if (
                ev.target.hasAttribute('pf-analytics-id') &&
                ev.type === ev.target.getAttribute('pf-analytics-type')
            ) {
                eventId = ev.target.getAttribute('pf-analytics-id');
                analyticKey = ev.target.getAttribute('pf-analytics-key') || '';
            } else {
                return;
            }
        } else {
            analyticKey = this.getAttribute('data-analytics-key') || '';
            eventId = this.getAttribute(`on-${ev.type}`);
        }
        this.dispatchAction('analytics', {
            eventId,
            srcEvent: ev,
            analyticKey,
        });
    }
}

export default PFEnsightenElement;
