import React, { Component, Fragment } from 'react';
import _mapKeys from 'lodash/mapKeys';

import { buildTcombFormComponent } from '../../../../../core/scripts/react-components/tcomb/formFactory';

import Modal from '../../../../../core/scripts/react-components/specifics/Modal';
import siteStateController from '../../../state-controllers/siteStateController';
import savedSearchService from '../../../../../core/scripts/services/savedSearchService';
import LoaderButton from '../../../../../core/scripts/react-components/specifics/LoaderButton';
import Optins from '../../specifics/Optins';
import AlertBoxCountryNotListed from '../../specifics/AlertBoxCountryNotListed';
import userMeAPIService from '../../../../../core/scripts/services/userMeAPIService';
import { analyticsConsumer336, analyticsConsumer337, analyticsConsumer438 } from '../../../analytics/dotcom';

export default class SavedSearchModal extends Component {
    _composeStateObject(supplementalStateValues = {}) {
        return {
            // state flow from state controller
            isSaving: siteStateController.ui.savedSearch.isSaving,
            isLoggedIn: siteStateController.userMe.isLoggedIn,

            isSavedSearchModalVisible: siteStateController.ui.savedSearch.isSavedSearchModalVisible,
            modalProps: siteStateController.ui.savedSearch.modalProps,

            isInitialStep: siteStateController.ui.savedSearch.isInitialStep,
            isSavePromptStep: siteStateController.ui.savedSearch.isSavePromptStep,
            isConfirmationStep: siteStateController.ui.savedSearch.isConfirmationStep,

            notifyOnNewMatches: siteStateController.ui.savedSearch.notifyOnNewMatches,

            savedSearchModelData: siteStateController.ui.savedSearch.savedSearchModelData,

            ...supplementalStateValues,
        };
    }

    _updateState(supplementalStateValues) {
        this.setState(this._composeStateObject(supplementalStateValues));
    }

    state = this._composeStateObject();

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {};

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {};

    _unsubscribeFunctions = [];

    constructor() {
        super();

        this._unsubscribeFunctions.push(siteStateController.ui.savedSearch.subscribe(payload => this._updateState()));

        this._unsubscribeFunctions.push(siteStateController.userMe.subscribe(payload => this._updateState()));
    }

    componentDidMount() {
        savedSearchService.checkAutomaticLaunchOnLoad();
    }

    componentDidUpdate(_prevProps, prevState) {
        // Only fire anaytic event if the modal was not previously visible AND if notifyOnNewMatches is checked
        if (
            this.state.isSavedSearchModalVisible !== prevState.isSavedSearchModalVisible &&
            this.state.isSavedSearchModalVisible
        ) {
            if (this.state.notifyOnNewMatches) {
                analyticsConsumer336();
            }
        }
    }

    componentWillUnmount() {
        this._unsubscribeFunctions.forEach(unsubscribeFn => unsubscribeFn());
    }

    /**
     * Condition getters
     */

    /**
     * Is closing of the modal allowed?
     *
     * @readonly
     * @returns {boolean}
     */
    get _isCloseAllowed() {
        if (this.state.isConfirmationStep) {
            if (this._userInTestGroup) {
                // The "growth experiment" requires users be able to dismiss the confirmation modal.
                // See PBT-17552
                return true;
            } else {
                return false;
            }
        }

        return true;
    }
    /**
     * Is user in the Google Optimize test group?
     *
     * @returns {boolean}
     * @readonly
     */
    get _userInTestGroup() {
        return window.GT51;
    }

    /**
     * Does user have an adopter profile?
     *
     * @returns {boolean}
     * @readonly
     */
    get _userHasAdopterProfile() {
        // if user NOT logged in we know there is no adoption profile
        if (!this.state.isLoggedIn) {
            return false;
        }

        // if user IS logged in, have they completed an adoption profile?
        if (window.PF.pageConfig.completed_adoption_profiles.length < 1) {
            return false;
        }

        return true;
    }

    /**
     * Event handlers
     */

    _onModalClose = () => {
        this._closeModal();
    };

    _onNewMatchNotificationCheckboxChange = event => {
        siteStateController.ui.savedSearch.notifyOnNewMatches = event.currentTarget.checked;
        if (event.currentTarget.checked) {
            analyticsConsumer336();
        }
    };

    _onLoggedInConfirmationModalClose = () => {
        savedSearchService.setRequestDailyEmails(this.state.notifyOnNewMatches);
        analyticsConsumer337();
        this._closeModal();
    };

    /**
     * Handler for clicking on the modal "Manage Saved Searches" link
     * @private
     */
    _handleManageSearchesClick = () => {
        savedSearchService.setRequestDailyEmails(this.state.notifyOnNewMatches);
        analyticsConsumer438();
    };

    /*
        State flow
        ====================================================================================================

                            Initial     Login Prompt        Save Prompt     Confirmation

        Guest               o---------> o-----------------> o-------------> o
        Logged In User      o---------------------------------------------> o

    */

    /**
     * State update handlers
     */

    _closeModal() {
        siteStateController.ui.savedSearch.closeModalAndReset();
    }

    _transitionToSavePromptStep() {
        siteStateController.ui.savedSearch.transitionToSavePromptStep();
    }

    _transitionToConfirmationStep() {
        siteStateController.ui.savedSearch.transitionToConfirmationStep();
    }

    render() {
        return this._mainTemplate;
    }

    /**
     * Templates
     */

    get _mainTemplate() {
        if (this.state.isSavedSearchModalVisible) {
            return this._savedSearchModal;
        }

        return '';
    }

    get _savedSearchModal() {
        return (
            <div>
                <Modal
                    extensionClassNames={{
                        ['modal_savedSearch']: this.state.isInitialStep,
                        ['modal_savedSearchConfirm']: !this.state.isInitialStep,
                    }}
                    onClose={this._onModalClose}
                    noClose={!this._isCloseAllowed}
                    onEscapeKeyDown={this._onModalClose}
                    {...this.modalProps}
                >
                    {this._savedSearchModalContent}
                </Modal>
            </div>
        );
    }

    /**
     * Get the saved search modal content for a logged in user
     *
     * @returns {jsx}
     * @readonly
     */
    get _savedSearchModalContent() {
        if (this._userInTestGroup && !this._userHasAdopterProfile) {
            return this._loggedInUserNoAdopterProfileConfirmationContent;
        }
        return this._loggedInUserConfirmationContent;
    }

    _onOptinsChange = optinValues => {
        siteStateController.ui.savedSearch.optinValues = optinValues;
    };

    get _loggedInUserConfirmationContent() {
        return (
            <Fragment>
                <div className="tier tier_modalHeader m-tier_bgPrimary u-vr6x">
                    <div className="media media_stack u-textCenter">
                        <div className="media-img m-media-img_center">
                            <span className="icon icon_lg m-icon_colorWhite">
                                <svg role="img">
                                    <use xlinkHref="#icon-circleCheckOutlined" />
                                </svg>
                            </span>
                        </div>
                        <div className="media-bd">
                            <h2 className="txt txt_h3 m-txt_colorWhite u-vr6x">Your search was saved</h2>
                            <p className="txt m-txt_lg m-txt_colorWhite m-txt_ellipsisOverflow">
                                {this.state.savedSearchModelData.filterString}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="tier u-vr8x">
                    <h3 className="u-isHidden">Saved Search Details</h3>
                    <div className="u-textCenter">
                        <p className="txt m-txt_bold m-txt_lg m-txt_uppercase u-vr2x">Search Nickname</p>
                        <p className="txt m-txt_lg u-vr8x">
                            {this.state.modalProps.name
                                ? this.state.modalProps.name
                                : this.state.savedSearchModelData.name}
                        </p>
                        <label className="checkbox u-textLeft u-vr6x" htmlFor="Email_Updates">
                            <input
                                id="Email_Updates"
                                onChange={this._onNewMatchNotificationCheckboxChange}
                                className="checkbox-input"
                                type="checkbox"
                                name=""
                                aria-label="Email me daily if there are new matches."
                                value=""
                                checked={this.state.notifyOnNewMatches}
                            />
                            <span className="checkbox-btn" />
                            <span className="checkbox-label">
                                <p>Email me daily if there are new matches.</p>
                            </span>
                        </label>
                        <button
                            type="button"
                            onClick={this._onLoggedInConfirmationModalClose}
                            className="btn btn_loader m-btn_full u-vr6x"
                        >
                            Done
                        </button>
                        <a
                            href="/user/search-alerts"
                            className="txt txt_link m-txt_lg m-txt_uppercase m-txt_bold"
                            onClick={this._handleManageSearchesClick}
                        >
                            Manage Saved Searches
                        </a>
                    </div>
                </div>
            </Fragment>
        );
    }

    get _loggedInUserNoAdopterProfileConfirmationContent() {
        return (
            <Fragment>
                <div className="tier tier_modalHeader m-tier_bgPrimary u-vr6x">
                    <div className="media media_stack u-textCenter">
                        <div className="media-img m-media-img_center">
                            <span className="icon icon_lg m-icon_colorWhite">
                                <svg role="img">
                                    <use xlinkHref="#icon-circleCheckOutlined" />
                                </svg>
                            </span>
                        </div>
                        <div className="media-bd">
                            <h2 className="txt txt_h3 m-txt_colorWhite u-vr6x">Your search was saved</h2>
                            <p className="txt m-txt_lg m-txt_colorWhite m-txt_ellipsisOverflow">
                                {this.state.savedSearchModelData.filterString}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="tier u-vr8x">
                    <h2 className="u-isHidden">Create an adoption profile</h2>
                    <div className="u-textCenter">
                        <h3 className="txt m-txt_bold m-txt_lg m-txt_uppercase u-vr2x">
                            Ready to find the perfect pet for you?
                        </h3>
                        <p className="txt m-txt_lg u-vr8x">
                            Create a profile to save your preferences and unlock your best match.
                        </p>
                        <a href="/user/profile/create/" className="btn btn_loader m-btn_full u-vr4x">
                            Get Started
                        </a>
                        <label className="checkbox u-textLeft u-vr6x" htmlFor="Email_Updates">
                            <input
                                id="Email_Updates"
                                onChange={this._onNewMatchNotificationCheckboxChange}
                                className="checkbox-input"
                                type="checkbox"
                                name=""
                                aria-label="Email me daily if there are new matches."
                                value=""
                                checked={this.state.notifyOnNewMatches}
                            />
                            <span className="checkbox-btn" />
                            <span className="checkbox-label">
                                <p>Email me daily if there are new matches.</p>
                            </span>
                        </label>
                    </div>
                </div>
            </Fragment>
        );
    }
}
