import React from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';

/**
 * Renders saved search list from props
 * @param {Object} props
 * @returns {string}
 */
const SavedSearchList = ({ savedSearches }) => {
    return (
        <ul className="savedSearchList savedSearchList_modal">
            {savedSearches &&
                savedSearches.map((item, index) => (
                    <li
                        key={_uniqueId(item.name)}
                        className="savedSearchList-item"
                    >
                        <a
                            className="savedSearchList-item-link"
                            href={item.url}
                        >
                            <div className="split">
                                <div className="split-item">
                                    <span className="txt m-txt_colorWhite">
                                        &ldquo;{item.name}&rdquo;
                                    </span>
                                </div>
                                <div className="split-item">
                                    <span className="icon icon_xs m-icon_colorWhite">
                                        <svg role="img">
                                            <use xlinkHref="#icon-chevronRight" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </a>
                    </li>
                ))}
        </ul>
    );
};

SavedSearchList.propTypes = {
    savedSearches: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SavedSearchList;
