export default class RenderManager {
    static _elementRenderQueue = [];
    static _queuedRequestAnimationFrame = null;

    static _afterNextRenderCallbacks = [];

    static queueRender(element) {
        // check for uniquity
        if (this._elementRenderQueue.indexOf(element) !== -1) {
            return;
        }

        // ensure a parenting element is not already present
        if (this._willBeRenderedByAncestor(element)) {
            return;
        }

        // remove any descendants if present
        this._cancelDescendantRendering(element);

        // ok, add the item
        this._elementRenderQueue.push(element);

        // queue an animation frame if one is not already queued
        if (!this._queuedRequestAnimationFrame) {
            this._queuedRequestAnimationFrame = requestAnimationFrame(() => {
                this._executeRender();
            });
        }
    }

    static afterNextRender(fn) {
        if (typeof fn !== 'function') {
            throw new TypeError('Invalid argument provided; expected function');
        }

        this._afterNextRenderCallbacks.push(fn);
    }

    static _executeRender() {
        let item;
        while (item = this._elementRenderQueue.shift()) {
            item.renderImmediate();
        }

        this._queuedRequestAnimationFrame = null;

        while (item = this._afterNextRenderCallbacks.shift()) {
            item();
        }
    }

    static _willBeRenderedByAncestor(element) {
        for (let i = 0; i < this._elementRenderQueue.length; i++) {
            if (this._elementRenderQueue[i].contains(element)) {
                return true;
            }
        }

        return false;
    }

    static _cancelDescendantRendering(element) {
        for (let i = this._elementRenderQueue.length - 1; i >= 0; i--) {
            if (element.contains(this._elementRenderQueue[i])) {
                this._elementRenderQueue.splice(i, 1);
            }
        }
    }
}
