import React, { Component, Fragment } from 'react';
import siteStateController from '../../../state-controllers/siteStateController';
import animalSearchFiltersStateController from '../../../state-controllers/animal-search/animalSearchFiltersStateController';
import FilterTag from '../FilterTag';
import ensightenTracking from '../../../../../core/scripts/elements/pf-app/ensighten/ensighten';

class PetSearchFilterList extends Component {
    /**
     * Initalize unsubscribe method
     *
     * @private
     * @memberof PetSearchFilterList
     */
    _unsubscribeFunction = null;

    state = {
        appliedFilters: [],
    };

    componentDidMount() {
        this._initFilterSubscription();
    }

    componentWillUnmount() {
        this._unsubscribeFunction();
    }

    /**
     * Watches and receives updates when search filters change.
     *
     * @private
     * @memberof PetSearchFilterList
     */
    _initFilterSubscription() {
        this._unsubscribeFunction = siteStateController.animalSearch.filters.subscribe(
            payload => {
                let activeFilters = [];
                activeFilters = this._getAppliedFilters();
            }
        );
    }

    /**
     * Gets all currently active pet search filters.
     *
     * @private
     * @memberof PetSearchFilterList
     */
    async _getAppliedFilters() {
        this.setState({
            appliedFilters:
                animalSearchFiltersStateController.expandedAppliedFilters,
        });
    }

    /* *******************************************
     * Handlers
     ********************************************/

    /**
     * Handles a click on the 'Clear All' filters button
     *
     * @param {*} e - Event
     * @private
     * @memberof PetSearchFilterList
     */
    _handleFilterClearAll = e => {
        animalSearchFiltersStateController.clearAllUserFilters();
        ensightenTracking.eventConsumer063({ source: 'chicklet' });
    };

    /**
     * @param {String} filterName
     * @param {String} value
     * @returns {Object}
     * @private
     * @memberof PetSearchFilterList
     */
    _handleFilterRemove = (filterName, value) => {
        const filters = animalSearchFiltersStateController.removeFilterValue(
            filterName,
            value
        );
        ensightenTracking.eventConsumer422(filterName, value);
        return filters;
    };

    /**
     * Renders out the filter tag list items
     * NOTE: The last filter item is inserted into different markup that is paired
     * with the 'Clear All' button so that they always appear on the same row.
     *
     * @returns {String} jsx
     * @private
     * @memberof PetSearchFilterList
     */
    _renderListItems() {
        // spread state.appliedFilters in variable as we don't want to modify component state
        const allAppliedFilters = [...this.state.appliedFilters];
        const lastAppliedFilterInArray = allAppliedFilters.pop();

        return (
            <Fragment>
                {allAppliedFilters.map((item, index) => (
                    <li key={item.longLabel}>
                        <FilterTag
                            item={item}
                            onFilterRemove={this._handleFilterRemove}
                        />
                    </li>
                ))}
                <li>
                    <FilterTag
                        item={lastAppliedFilterInArray}
                        mixClass="m-filterTag_last"
                        onFilterRemove={this._handleFilterRemove}
                    />
                    <button
                        data-test="Pet_Filters_Clear_All"
                        onClick={this._handleFilterClearAll}
                        className="txt txt_link m-txt_heavy u-displayInlineBlock"
                    >
                        Clear All
                    </button>
                </li>
            </Fragment>
        );
    }

    /**
     * Render the surrounding markup around the filter tag list items
     *
     * @returns {String} jsx
     * @private
     * @memberof PetSearchFilterList
     */
    _renderFilters() {
        if (this.state.appliedFilters.length > 0) {
            return (
                <Fragment>
                    <h2 className="txt m-txt_bold u-vr2x">Filters Applied</h2>
                    <div className="u-vr4x">
                        <ul className="wrapList wrapList_gutterSm m-wrapList_hScroll@maxMd">
                            {this._renderListItems()}
                        </ul>
                    </div>
                </Fragment>
            );
        }
        return null; // loading state
    }

    render() {
        return <Fragment>{this._renderFilters()}</Fragment>;
    }
}

export default PetSearchFilterList;
