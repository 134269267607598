import MixinBase from './MixinBase';
import LogicParser from '../../../../../dotcom/scripts/lib/LogicParser';

export default class IfMixin extends MixinBase {
    static _attributeName = 'mix-if';

    constructor(ele, value) {
        super(ele, value);

        const result = LogicParser.evaluateLogicString(value, str => MixinBase.resolve(str, ele));

        if (!result) {
            // thanks, IE11... (this._ele.remove())
            this._ele.parentElement.removeChild(this._ele);
        }
    }
}
