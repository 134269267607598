import React from 'react';

const SocialLoading = () => {
    return (
        <div className="loginModal">
            <div className="loginModal-section">
                <h2 className="txt txt_h2 u-vr6x" tabIndex="-1">
                    {'Fetching your data...'}
                </h2>
                <div className="winky winky_noDelay" />
            </div>
        </div>
    );
};

export default SocialLoading;
