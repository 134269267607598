import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStateMachine } from 'react-automata';
import t from 'tcomb-form';
import { stringify } from 'query-string';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _merge from 'lodash/merge';
import _debounce from 'lodash/debounce';
import _noop from 'lodash/noop';

import { focusFirstFocusable, focusAndScrollElementIntoView } from '../../../lib/focusManager/index';

import { buildFieldOptionsFromSchemas } from '../util';
import FormErrorSummary from '../components/FormErrorSummary';

import { FORM_CONFIG_DEFAULTS, FORM_OPTIONS_DEFAULTS } from './defaults';
import { STATE, EVENT, statechart } from './statechart';

/**
 * @type {string}
 */
export const FORM_ERROR_SUMMARY_FOCUS_TARGET_ID = 'formErrorSummary';

/**
 * @param {Object} obj
 * @returns {Object}
 */
function createFieldErrorsFromObj(obj) {
    const result = {};

    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (Array.isArray(value)) {
            result[key] = {
                hasError: true,
                error: value.join(', '),
            };
        } else if (typeof value === 'object') {
            result[key] = {
                fields: {
                    ...createFieldErrorsFromObj(value),
                },
            };
        }
    });

    return result;
}

/**
 * @type {Object}
 */
const Form = t.form.Form;

/**
 * @param {string} formId
 * @param {Object} tcombConfigOptions tcomb type, context, options, value, schema
 * @param {function} formConfigOptions
 * @returns {function}
 */
export function buildTcombFormComponent(formId, tcombConfigOptions = {}, formConfigOptions = {}) {
    const { type, options, value, context, jsonSchema, uiSchema } = tcombConfigOptions;

    // Merge tcomb form options with defaults
    const formOptions = _merge(
        {},
        FORM_OPTIONS_DEFAULTS,
        { fields: { ...buildFieldOptionsFromSchemas(jsonSchema, uiSchema) } },
        options
    );

    // Merge form config options with defaults
    const formConfig = _merge({}, FORM_CONFIG_DEFAULTS, formConfigOptions);

    class TcombForm extends Component {
        /**
         * @type {Object}
         */
        state = {
            type: this._getFormType(value),
            value,
            options: this._getFormOptions(value),
        };

        /**
         * @type {Object}
         * @static
         */
        static propTypes = {
            validateClientSide: PropTypes.bool,
            renderGlobalError: PropTypes.func,
            onDeriveFormTypeChange: PropTypes.func,
            onDeriveFormValueChange: PropTypes.func,
            onDeriveFormOptionsChange: PropTypes.func,
            onFormValueChange: PropTypes.func,
            onFormContainerChange: PropTypes.func,
            onFormContainerBlur: PropTypes.func,
            onSubmissionSuccess: PropTypes.func,
            onSubmissionError: PropTypes.func,
            onValidationError: PropTypes.func,
            dataTestValue: PropTypes.string,
            onValidateAdditionalValidation: PropTypes.func,
            setIsInErrorState: PropTypes.func,
        };

        /**
         * @type {Object}
         * @static
         */
        static defaultProps = {
            validateClientSide: true,
            renderGlobalError: null,
            onDeriveFormTypeChange: null,
            onDeriveFormValueChange: null,
            onDeriveFormOptionsChange: null,
            onFormValueChange: _noop,
            onFormContainerChange: _noop,
            onFormContainerBlur: _noop,
            onSubmissionSuccess: _noop,
            onSubmissionError: _noop,
            onValidationError: _noop,
            onValidateAdditionalValidation: () => true,
            setIsInErrorState: _noop,
            dataTestValue: '',
        };

        /**
         * Used to track number of requests
         * @type {number}
         */
        requestIterator = 0;

        /**
         * @type {Object}
         */
        form = React.createRef();

        /**
         * @type {Object}
         */
        formContainer = React.createRef();

        /**
         * @type {function}
         */
        onPopstate = this._handlePopstate.bind(this);

        constructor(props) {
            super(props);

            // Create debounced form submit function
            this._debouncedSubmitForm = _debounce(this._submitForm.bind(this), formConfig.debounceDuration, {
                leading: true,
            });
        }

        componentDidMount() {
            if (formConfig.keepSearchParamHistory) {
                window.addEventListener('popstate', this.onPopstate);

                // Set a history item
                this._setSearchParamHistory();
            }
        }

        componentWillUnmount() {
            if (formConfig.keepSearchParamHistory) {
                window.removeEventListener('popstate', this.onPopstate);
            }
        }

        componentDidUpdate(prevProps) {
            // If a redirect prop is added to props, redirect to url
            if (!prevProps.redirect && this.props.redirect) {
                window.location.assign(this.props.redirect);
            }
        }

        /**
         * This method is run when entering the submit state, it will run your on submit handler
         * that was provided in the form config options with the current value of the tcomb form
         * and props as arguments. The submission depends on a resolved promise with data to succeed
         * or a rejected promise with data to error and transition into the respective states.
         * @private
         */
        async _submitForm() {
            this.requestIterator++;
            const currRequestId = this.requestIterator;

            try {
                const submissionValue = _isEmpty(this.state.value) ? {} : this.state.value;

                const response = await formConfig.onSubmit(submissionValue, this.props);

                // Only take last request or return early
                if (currRequestId !== this.requestIterator) {
                    return;
                }

                if (formConfig.keepSearchParamHistory) {
                    this._setSearchParamHistory();
                }

                this.props.transition(EVENT.SUCCESS, {
                    ...response.props,
                    // reset history replacement flag
                    replaceSearchParamHistory: false,
                });
            } catch (error) {
                if (typeof error === 'string') {
                    throw error;
                }

                // Transition to error state, set error response props as server errors
                this.props.transition(EVENT.ERROR, {
                    serverErrors: error.props,
                });
            }
        }

        /**
         * Sets the tcomb options back to default formOptions and then tries to
         * merge the serverErrors prop errors from response into tcomb options
         * @private
         */
        _showServerError() {
            this.setState(
                prevState => ({ ...prevState, options: this._getFormOptions(prevState.value) }),
                () => {
                    try {
                        let fieldErrors = {};

                        if (this.props.serverErrors.errors.fields) {
                            fieldErrors = createFieldErrorsFromObj(this.props.serverErrors.errors.fields);
                        }
                        const newFormOptions = _merge({}, this.state.options, {
                            hasError: true,
                            error: this._renderGlobalError(this.props.serverErrors.errors),
                            fields: {
                                ...fieldErrors,
                            },
                        });

                        this.setState(prevState => ({
                            ...prevState,
                            options: newFormOptions,
                        }));
                    } catch (error) {
                        console.error(error);

                        throw new Error(
                            `buildTcombFormComponent._showServerErrors: Your form error response may not be correctly formed it should look like:
                            {
                                props: {
                                    errors: { global: [<string>],
                                    fields: { <fieldKey>: [<string>]
                                }
                            }
                            `
                        );
                    }
                }
            );
        }

        /**
         * Clears all global and field level errors
         * @private
         */
        _clearServerErrors() {
            const newFormFields = Object.assign({}, this.state.options.fields);

            Object.keys(newFormFields).forEach(key => {
                newFormFields[key].error = null;
                newFormFields[key].hasError = false;
            });

            const newFormOptions = _merge({}, this.state.options, {
                hasError: false,
                error: null,
                fields: newFormFields,
            });

            this.setState({ options: newFormOptions });
        }

        /**
         * @private
         */
        _onSubmissionSuccess() {
            this.props.onSubmissionSuccess(this.state.value, this.props);
        }

        /**
         * @private
         */
        _onSubmissionError() {
            this._onValidationError();
            this._findAndFocusErrors();
            this.props.onSubmissionError(this.state.value, this.props.serverErrors);
        }

        _onValidationError() {
            this.props.onValidationError(this.form.current.validate());
        }

        /**
         * Get the form tcomb type, you can also pass prop onDeriveFormTypeChange
         * that must return a struct tcomb type
         * @private
         * @param {Object} value form value
         * @returns {Struct}
         */
        _getFormType(value) {
            if (typeof this.props.onDeriveFormTypeChange === 'function') {
                return this.props.onDeriveFormTypeChange(value, type, jsonSchema);
            }

            return type;
        }

        /**
         * Change form options when value changes
         * @private
         * @param {Object} value form value
         * @returns {Struct}
         */
        _getFormOptions(value) {
            if (typeof this.props.onDeriveFormOptionsChange === 'function') {
                return this.props.onDeriveFormOptionsChange(value, formOptions, jsonSchema);
            }

            return formOptions;
        }

        /**
         * Use current form values to add browser history state push or replace current
         * @private
         */
        _setSearchParamHistory() {
            const historyState = Object.assign({}, { value: this.state.value });
            const historyUrl = `?${stringify(this.state.value, {
                arrayFormat: 'bracket',
            })}${window.location.hash}`;

            if (this.props.replaceSearchParamHistory) {
                window.history.replaceState(historyState, '', historyUrl);
            } else {
                window.history.pushState(historyState, '', historyUrl);
            }
        }

        /**
         * Given an object of key values updates the form value by spreading
         * the new values over the prev state/form values, you can also optionally
         * force the form to submit after a set state has occurred
         * @param {Object} nextValues
         * @param {boolean} submitAfterUpdate
         * @private
         */
        _updateValue = (nextValues, submitAfterUpdate = false) => {
            this.setState(
                prevState => ({
                    ...prevState,
                    value: {
                        ...prevState.value,
                        ...nextValues,
                    },
                }),
                () => {
                    if (submitAfterUpdate) {
                        this.props.transition(EVENT.VALUE_UPDATE_SUBMIT);
                    }
                }
            );
        };

        /**
         * Call getValue() on tcomb form component to cause the validation of all the fields of
         * the form, including some side effects like highlighting the errors
         * @returns {Object}
         */
        _validateForm() {
            return this.form.current.getValue();
        }

        /**
         * Return function that calls _validateForm from form context
         * @returns {function}
         */
        _buildValidateFormHandler() {
            return () => this._validateForm();
        }

        /**
         * Handles tcomb form validity check before submit
         * @param {Event} ev
         * @private
         */
        _handleSubmit = ev => {
            ev.preventDefault();

            if (this.props.validateClientSide) {
                const value = this._validateForm();

                // if there are additional validation rules, run them
                const additionalValidationSuccess = this.props.onValidateAdditionalValidation(value);

                if (additionalValidationSuccess) {
                    this.props.setIsInErrorState(false);
                }

                if (!value || !additionalValidationSuccess) {
                    this.props.setIsInErrorState(true);
                    this._clearServerErrors();
                    this._onValidationError();
                    this._findAndFocusErrors();
                    return;
                }
            }

            this.props.transition(EVENT.SUBMIT);
        };

        /**
         * Handles moving focus when the form reaches error state
         */
        _findAndFocusErrors() {
            setTimeout(() => {
                const formContainerElement = this.formContainer.current;
                const formErrorSummaryElement = formContainerElement.querySelector(
                    `[data-focus-target=${FORM_ERROR_SUMMARY_FOCUS_TARGET_ID}]`
                );
                const formElementsWithError = Array.from(
                    formContainerElement.querySelectorAll('[aria-invalid="true"]')
                );

                // Try to focus the first focusable element of the error summary component first
                if (formErrorSummaryElement) {
                    focusFirstFocusable(formErrorSummaryElement);
                    return;
                }

                // Then try to focus the first form element that has an error
                if (!formErrorSummaryElement && formElementsWithError.length > 0) {
                    focusAndScrollElementIntoView(formElementsWithError[0]);
                    return;
                }

                // Fallback, just focus the first focusable thing in the form
                focusFirstFocusable(formContainerElement);
            }, 0);
        }

        /**
         * Return function that calls _findAndFocusErrors from form context
         * @returns {function}
         */
        _buildFindAndFocusErrorsHandler() {
            return () => this._findAndFocusErrors();
        }

        /**
         * Handles tcomb form value changes, updates the state.value of our component and calls some
         * prop hooks to let you derive (alter) the form value or just listen to actual value changes
         * @param {Object} value
         * @param {Array} path
         * @private
         */
        _handleFormValueChange = (value, path) => {
            this.setState(prevState => {
                const nextValues = this.props.onDeriveFormValueChange
                    ? this.props.onDeriveFormValueChange(prevState.value, value, jsonSchema, path)
                    : value;

                this.props.onFormValueChange(prevState.value, nextValues, jsonSchema, path);

                return {
                    type: this._getFormType(nextValues),
                    value: nextValues,

                    // Need to merge state options so that errors or any other
                    // options changed by the form component persist on change
                    options: _merge({}, this.state.options, this._getFormOptions(nextValues)),
                };
            });
        };

        /**
         * Handles change events from the form container and passes on the event to a prop handler
         * this is useful for doing analytics because it isnt fired on every value change like the
         * form component _handleFormValueChange handler which acts more like an 'input' event and
         * is more for controlling the state value of the form.
         * @param {Event} ev
         * @private
         */
        _handleFormContainerChange = ev => {
            this.props.onFormContainerChange(ev);
        };

        /**
         * Handles blur events from the form container and passes on the event to a prop handler
         * this is useful for doing analytics
         * @param {Event} ev
         * @private
         */
        _handleFormContainerBlur = ev => {
            this.props.onFormContainerBlur(ev);
        };

        /**
         * Handles pop state events, usually just returning the form values to a previous state
         * @param {Event} ev
         * @private
         */
        _handlePopstate(ev) {
            const prevFilterValue = _get(ev.state, 'value', null);

            // This prevents anchor hash changes from triggering
            // the form, because they change the history state object...
            if (!prevFilterValue) {
                return;
            }

            this.setState({ value: prevFilterValue }, () => {
                this.props.transition(EVENT.VALUE_UPDATE_SUBMIT, {
                    replaceSearchParamHistory: true,
                });
            });
        }

        _renderGlobalError(errors) {
            if (typeof this.props.renderGlobalError === 'function') {
                return this.props.renderGlobalError(errors, this.state.options, jsonSchema);
            }

            return (
                <div data-focus-target={FORM_ERROR_SUMMARY_FOCUS_TARGET_ID}>
                    <FormErrorSummary
                        errors={errors}
                        options={this.state.options}
                        jsonSchema={jsonSchema}
                        iconConfig={formConfig.errors.iconConfig}
                    />
                </div>
            );
        }

        _renderForm() {
            const formProps = {
                ref: this.form,
                context: {
                    ...context,
                    formId,
                    jsonSchema,
                    uiSchema,
                },
                type: this.state.type,
                options: this.state.options,
                value: this.state.value,
                onChange: this._handleFormValueChange,
            };

            return (
                <div
                    ref={this.formContainer}
                    onChange={this._handleFormContainerChange}
                    onBlur={this._handleFormContainerBlur}
                >
                    <Form {...formProps} />
                </div>
            );
        }

        _renderDefaultForm() {
            return (
                <form id={formId} noValidate onSubmit={this._handleSubmit}>
                    {this._renderForm()}
                    <button className={formConfig.submitButton.classNames} type="submit">
                        {formConfig.submitButton.label}
                    </button>
                </form>
            );
        }

        render() {
            if (typeof this.props.children === 'function') {
                return this.props.children({
                    // The tcomb form component so you can render it where you want
                    // you need to render this to see your form
                    component: this._renderForm(),

                    // Submit handler for form element, this is here because you will need
                    // to render the form element/tag manually when using child render prop
                    handleSubmit: this._handleSubmit,

                    // The current state
                    state: this.state,

                    // The current props
                    props: this.props,

                    // Is the form component in the submitting state
                    isSubmitting: this.props.machineState.value === STATE.SUBMITTING,

                    // The current form state machine value, useful to check form state
                    machineStateValue: this.props.machineState.value,

                    // Updater for "proxy" components to use to update form value, can be passed
                    // to other components in your child render prop to update the form value
                    updateValue: this._updateValue,

                    // Function can be used to trigger client side validation
                    validateForm: this._buildValidateFormHandler(),

                    // Function can be used to focus errors if you decide to do things manually
                    focusFormErrors: this._buildFindAndFocusErrorsHandler(),
                });
            }

            return this._renderDefaultForm();
        }
    }

    TcombForm.displayName = formId;
    return withStateMachine(statechart)(TcombForm);
}
