import RenderManager from './RenderManager';
import ObjectExtensions from './ObjectExtensions';
import Mixins from './Mixins';
import IdMixin from './mixins/IdMixin';
import TemplateRegistry from './TemplateRegistry';
import Utils from './Utils';
import morphdom from 'morphdom';
import { isElement } from '../../util/dom';
import ContextContainer from '../../context';

class AutowiredElement extends HTMLElement {
    static elementId = 0;
    static template = '';
    static loadingTemplate = '';

    _numericId = -1;
    _elementTag = null;
    _initialInnerHTML = '';
    _cachedTemplate = null;
    _requireTemplate = false;

    /**
     * Static members
     */

    static get elementTag() {
        if (this._elementTag === null) {
            throw new Error('Element tag not set (_elementTag === null)');
        }

        return this._elementTag;
    }

    static watchAttribute(attr) {
        if (!this.observedAttributes) {
            this.observedAttributes = [];
        }

        if (this.observedAttributes.indexOf(attr) !== -1) {
            return;
        }

        this.observedAttributes.push(attr);
    }

    /**
     * Initialization / configuration
     */

    constructor() {
        super();

        this._assignElementId();
        this._checkTemplate();
        ContextContainer.eventEmitter.addListener(
            'update',
            this.onUpdated.bind(this)
        );
    }

    _assignElementId() {
        this._numericId = AutowiredElement.elementId++;
    }

    _checkTemplate() {
        if (this._requireTemplate && !this.hasCustomTemplate) {
            throw new Error(
                `${
                    this.elementTag
                } elements must be provided a template attribute`
            );
        }
    }

    getStaticProperty(name) {
        return Object.getPrototypeOf(this).constructor[name];
    }

    get elementId() {
        return `@AEID${this._numericId}`;
    }

    get elementTag() {
        this.getStaticProperty('elementTag');
    }

    get hasCustomTemplate() {
        return (
            this.customTemplateAttribute || this.customTemplateFunctionAttribute
        );
    }

    get customTemplateAttribute() {
        return this.getAttribute('template');
    }

    get customTemplateFunctionAttribute() {
        return this.getAttribute('template-function');
    }

    get customTemplate() {
        if (this.customTemplateAttribute) {
            return TemplateRegistry.get(this.customTemplateAttribute);
        } else if (this.customTemplateFunctionAttribute) {
            const ele = Utils.HTMLElementUtils.findElementContainingAddress(
                this.customTemplateFunctionAttribute,
                this
            );
            if (ele) {
                return Utils.ObjectUtils.access(
                    this.customTemplateFunctionAttribute,
                    ele
                );
            }
        }

        return null;
    }

    get hasTemplate() {
        return this.hasCustomTemplate || this.getStaticProperty('template');
    }

    get loadingTemplate() {
        return this.getStaticProperty('loadingTemplate');
    }

    get template() {
        if (!this._cachedTemplate) {
            this._cachedTemplate =
                this.customTemplate ||
                this.getStaticProperty('template') ||
                this._initialInnerHTML;
        }

        return this._cachedTemplate;
    }

    get shouldForceScreenreaderDomRefresh() {
        return false;
    }

    getElementByMixinId(id) {
        return IdMixin.get(id);
    }

    /**
     * Native Element Lifecycle
     */

    connectedCallback() {
        this._initialInnerHTML = this.innerHTML;

        if (this.loadingTemplate) {
            this.renderImmediate(this.loadingTemplate);
        }

        this.onConnected();

        ObjectExtensions.addEventAutoWiring(this);

        if (!this.loadingTemplate) {
            if (this.hasTemplate) {
                this.render();
            }
        }

        // TODO: stubbed in case we need it in the future
        // requestAnimationFrame(() => this.afterConnected());
    }

    disconnectedCallback() {
        this.onDisconnected();
    }

    /**
     * Implementing class lifecycle stub
     */

    onConnected() {}
    // TODO: stubbed in case we need it in the future
    // afterConnected() { }
    onDisconnected() {}
    beforeRender() {}
    afterRender() {
        if (this.shouldForceScreenreaderDomRefresh) {
            this.style.display = '';
        }
    }

    _triggerLoadingComplete() {
        this.renderImmediate();
    }

    /**
     * Custom lifecycle
     */

    get viewModel() {
        return {
            controller: this,
        };
    }

    render() {
        RenderManager.queueRender(this);
    }

    renderImmediate(optionalTemplateOverride = null) {
        this.beforeRender();
        this.renderFunction(optionalTemplateOverride);

        if (this.shouldForceScreenreaderDomRefresh) {
            this.style.display = 'none';
        }

        // give the browser a frame to finish rendering before calling
        requestAnimationFrame(() => this.afterRender());
    }

    get renderFunction() {
        return this.renderImmediateInnerHtml;
    }

    renderImmediateInnerHtml(optionalTemplateOverride = null) {
        this.innerHTML = this.getUpdatedContent(optionalTemplateOverride);

        Mixins.initWithin(this);
    }

    renderImmediateMorphdom(optionalTemplateOverride = null) {
        const div = document.createElement('div');
        div.innerHTML = this.getUpdatedContent(optionalTemplateOverride);

        morphdom(this, div, {
            onNodeAdded: el => {
                if (isElement(el)) {
                    Mixins.initOn(el);
                }
            },
            onElUpdated: el => {
                Mixins.removeMixinsFromElement(el);
                Mixins.initOn(el);
            },
            onBeforeElUpdated: (fromEl, toEl) => {
                if (toEl.hasAttribute('morphdom-dont-update')) {
                    return false;
                }
            },
            childrenOnly: true,
        });
    }

    getUpdatedContent(optionalTemplateOverride = null) {
        const template = optionalTemplateOverride || this.template;

        return typeof template === 'function'
            ? template(this.viewModel)
            : template;
    }

    onUpdated() {
        // noop
    }
}

export default AutowiredElement;
