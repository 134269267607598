import Repository from '../repository/repository';
import { ERROR_API } from '../../constants/errors';

/**
 * Search Repository
 * @extends Repository
 */
export default class SearchRepository extends Repository {
    /**
     * Get pet recommendation data from webfront
     * @param {string} component the component name, changes what the api returns
     * @param {string} animalType
     * @param {string} orgDisplayId
     * @param {Array} excludedAnimalIds
     * @param {string} userToken
     * @return {Promise}
     */
    async getPetRecommendations(component, animalType, orgDisplayId, excludedAnimalIds, userToken) {
        const baseUrl = '/search/recommended-pets/';

        /* eslint-disable camelcase */
        /* eslint-disable quote-props */
        const params = {
            'component[]': component,
            'animal_type': animalType, // from element attr
            'organization_display_id': orgDisplayId, // from element attr
            'excluded_animal_ids': excludedAnimalIds,
            'location_slug': '', // TODO ?
            '_token': userToken,
        };
        /* eslint-enable camelcase */
        /* eslint-enable quote-props */

        const url = this.buildUrlWithQuery(baseUrl, params);

        const response = await fetch(
            url,
            {
                method: 'GET',
                headers: {
                    // Need this content-type here
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'same-origin',
            }
        );

        if (response.status === 400) {
            // For parsing error response and getting redirect
            return Promise.reject(await this.readResponse(response));
        }

        if (this.wasRequestSuccessful(response)) {
            return Promise.resolve(await this.readResponse(response));
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }
}
