import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

/**
 * @class LocationSuggestionItem
 * @extends React.Component
 */
class LocationSuggestionItem extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        display_name: PropTypes.string, // eslint-disable-line
        onLocationSuggestionClick: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        display_name: 'No results found', // eslint-disable-line
        onLocationSuggestionClick: _noop,
    };

    /**
     * @method handleClick
     * @private
     * @param {Event} ev
     */
    handleClick = () => {
        this.props.onLocationSuggestionClick(this.props);
    };

    render() {
        const displayName = this.props.display_name;

        return (
            <li
                className="findAPetMenu-locationSuggestionListItem"
                role="option"
            >
                <button
                    className="findAPetMenu-locationSuggestionListItemButton"
                    type="button"
                    onClick={this.handleClick}
                    data-test="Find_A_Pet_Menu_Location_Suggestion_Button_List"
                >
                    {displayName}
                </button>
            </li>
        );
    }
}

export default LocationSuggestionItem;
