import BaseAnalyticEventModel from '../BaseAnalyticEventModel';
import AnimalBreedsPropertiesModel from './AnimalBreedsPropertiesModel';
import _get from 'lodash/get';

export default class AnimalBreedsEventModel extends BaseAnalyticEventModel {
    _defaultNullText = 'no filter applied';

    get defaultAnalyticProperties() {
        return {
            friendliness: true,
            groomingRequirements: true,
            vocality: true,
        };
    }

    /**
     * prepareEvent
     *
     * @static
     * @param {Object} eventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {AnimalBreedsEventModel}
     */
    static prepareEvent(
        eventData,
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const model = new AnimalBreedsEventModel(
            this._mapEventData(eventData),
            analyticProperties,
            overrideAnalyticProperties
        );
        model.analyticObjects = eventData;

        return model;
    }

    _buildProperties(analytics, nullText) {
        return new AnimalBreedsPropertiesModel(analytics, nullText);
    }

    /**
     * fromAnimalBreedFilters
     *
     * @static
     * @param {Object} filters
     * @param {Object} additionalEventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {AnimalBreedsEventModel}
     */
    static fromAnimalBreedFilters(
        filters,
        additionalEventData = {},
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const eventData = {
            ...this._mapAnimalBreedFilters(filters),
            nullText: 'no filter applied',
            ...additionalEventData,
        };
        return this.prepareEvent(
            eventData,
            analyticProperties,
            overrideAnalyticProperties
        );
    }

    /**
     * _mapAnimalBreedFilters
     *
     * @param {Object} filters
     *
     * @return {Object}
     */
    static _mapAnimalBreedFilters = filters => {
        return {
            friendliness: _get(filters, 'friendliness', ''),
            groomingRequirements: _get(filters, 'groomingRequirements', ''),
            vocality: _get(filters, 'vocality', ''),
        };
    };
}
