import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import {
    KEY_LEFT,
    KEY_ARROW_LEFT,
    KEY_RIGHT,
    KEY_ARROW_RIGHT,
    KEY_ENTER,
    KEY_SPACE,
    KEY_SPACE_BAR,
} from '../../constants/keyboard';
import { EV_APP_READY } from '../../constants/events';
import { isDescendant } from '../../../../core/scripts/util/dom';
import Flickity from 'flickity';
import 'flickity-imagesloaded';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-pet-carousel';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    NAV: `${ELEMENT_TAG_NAME}-nav`,
    CAROUSEL: `${ELEMENT_TAG_NAME}-body`,
    SLIDE: `${ELEMENT_TAG_NAME}-slide`,
    EXPAND: `${ELEMENT_TAG_NAME}-expand`,
};

const SWIPE_DIR_LEFT = 'left';
const SWIPE_DIR_RIGHT = 'right';

const options = {
    imagesLoaded: true,
    percentPosition: true,
    wrapAround: true,
    friction: 0.5,
    selectedAttraction: 0.08,
    prevNextButtons: true,
    pageDots: false,
    lazyLoad: 1,
    accessibility: true, // because flickity safari arrows keys not working
};

/**
 * State classes for CSS
 * @const
 * @type {string}
 */
const CLASSES = {
    PRE_JS: 'petCarousel_preJs',
};

/**
 * @class PFDCPetCarouselElement
 * @extends PFElement
 */
export class PFDCPetCarouselElement extends PFElement {
    /**
     * slide elements
     * @type {Array}
     */
    get flickitySlides() {
        return this.flickity.getCellElements();
    }

    /**
     * current slide index
     * reduces selectedIndex by increments of true slide length
     * until targetIndex is within range of nav
     *
     * @type {Number}
     */
    get actualIndex() {
        let targetIndex = this.flickity.selectedIndex;

        while (targetIndex > this.slides.length - 1) {
            targetIndex = targetIndex - this.slides.length;
        }

        return targetIndex;
    }

    /**
     * An instance of the element is created or upgraded. Useful for initializing state,
     * settings up event listeners, or creating shadow dom.
     */
    constructor() {
        super();

        /**
         * Flickity instance.
         *
         * @property flickity
         * @type {HTMLElement}
         */
        this.flickity = null;

        /**
         * Carousel container element passed to flickity.
         *
         * @property carousel
         * @type {HTMLElement}
         */
        this.carousel = null;

        this.currentIndex = null;
        this.swipeDirection = null;
    }

    async onInit() {
        this.classList.remove(CLASSES.PRE_JS);

        // analytic events triggered during swiping are queued here until onSettled resolves them
        this.analyticQueue = [];

        // child elements
        this.carousel = this.querySelector(`[${ELEMENT_SELECTORS.CAROUSEL}]`);
        this.slides = Array.from(this.querySelectorAll(`[${ELEMENT_SELECTORS.SLIDE}]`));
        this.nav = this.querySelector(`[${ELEMENT_SELECTORS.NAV}]`);
        this.expand = this.querySelector(`[${ELEMENT_SELECTORS.EXPAND}]`);
        this.alertContainer = this.querySelector('[pfdc-pet-carousel-alert]');
        this.expandPromptContainer = this.querySelector('[pfdc-pet-carousel-expand-prompt]');

        // attributes
        this.animalId = this.getAttribute('animal-id');
        this.isMemberCarousel = this.hasAttribute('analytics-member');

        // number of times to duplicate slides based on quantity of images.
        // this is a necessity due to the unusual nature of this swipe-able carousel
        this.duplicationFactor = this.slides.length < 3 ? 3 : 2;
        this.duplicateSlides();

        this.flickity = new Flickity(this.carousel, options);
        this.createNav();

        this.flickity.on('staticClick', this.onStaticClicked.bind(this));
        this.flickity.on('select', this.onSelected.bind(this));
        this.flickity.on('settle', this.onSettled.bind(this));
        this.flickity.on('dragMove', this.onDragMove.bind(this));
        this.flickity.on('dragEnd', this.onDragEnd.bind(this));

        this.addEventListener('click', this.onClicked);

        // Focusin and out handlers
        this.addEventListener('focusin', ev => {
            try {
                if (isDescendant(this, ev.target)) {
                    this.classList.add('petCarousel_focusIn');
                }
            } catch (e) {
                return;
            }
        });

        this.addEventListener('focusout', ev => {
            if (ev.relatedTarget === null) {
                return;
            }
            try {
                if (
                    !isDescendant(this, ev.relatedTarget) ||
                    ev.relatedTarget === null
                ) {
                    this.classList.remove('petCarousel_focusIn');
                }
            } catch (e) {
                return;
            }
        });

        // Carousel keydown handler
        this.addEventListener('keydown', ev => {
            const prevKeys = [KEY_ARROW_LEFT, KEY_LEFT];
            const nextKeys = [KEY_ARROW_RIGHT, KEY_RIGHT];

            if (prevKeys.includes(ev.key)) {
                ev.preventDefault();
                this.setAlertContainerTextToSelectedImg();
            } else if (nextKeys.includes(ev.key)) {
                ev.preventDefault();
                this.setAlertContainerTextToSelectedImg();
            } else if (
                ev.target === this.carousel &&
                (ev.key === KEY_ENTER ||
                    ev.key === KEY_SPACE ||
                    ev.key === KEY_SPACE_BAR)
            ) {
                ev.preventDefault();
                this.openFullscreen();
            }
        });

        // Check if the current slide is a video
        this.checkIfCurrentSlideIsVideo();
    }

    /**
     * Duplicates slides in order to have seamless carousel
     */
    duplicateSlides() {
        const slides = this.carousel.innerHTML;
        let duplicatedSlides = ''; // needed to not print 'undefined' before the slides

        for (let i = 0; i < this.duplicationFactor; i++) {
            duplicatedSlides = duplicatedSlides + slides;
        }

        this.carousel.innerHTML = duplicatedSlides;
    }

    /**
     * Generates navigation dots
     */
    createNav() {
        let navTemplate = '';
        let images = 0;
        let videos = 0;

        for (
            let i = 0;
            i < this.flickity.slides.length / this.duplicationFactor;
            i++
        ) {
            const cellElement = this.flickity.slides[i].cells[0].element;

            if (cellElement.classList.contains('petCarousel-body-videoSlide')) {
                videos++;
                navTemplate = `${navTemplate}\n
                    <button type="button" aria-label="Go to video ${videos}"></button>`;
            } else {
                images++;
                navTemplate = `${navTemplate}\n
                    <button type="button" aria-label="Go to image ${images}"></button>`;
            }
        }

        this.nav.innerHTML = navTemplate;
        this.navButtons = Array.from(this.nav.querySelectorAll('button'));

        this.updateNav();
    }

    /**
     * Updates navigation dots
     */
    updateNav() {
        const targetIndex = this.actualIndex;
        // remove active class
        for (let i = 0; i < this.navButtons.length; i++) {
            this.navButtons[i].classList.remove('isActive');
            this.navButtons[i].removeAttribute('disabled');
        }

        // add active class to correct nav dot
        this.navButtons[targetIndex].classList.add('isActive');
        this.navButtons[targetIndex].setAttribute('disabled', 'true');
    }

    /**
     * On launch of fullscreen, dispatches events for analytics tracking based on
     * whether is an image or video slide
     *
     * @method triggerFullscreenAnalytics
     */
    triggerFullscreenAnalytics() {
        const cell = this.flickity.getCellElements()[this.actualIndex];
        if (!cell) {
            return;
        }
        const eventIdData = cell.classList.contains('petCarousel-body-videoSlide')
            ? 'Consumer146_164_181'
            : 'Consumer145_163_180_253';

        this.dispatchAction('analytics', {
            eventId: eventIdData,
            animalId: this.animalId,
            member: this.isMemberCarousel,
        });
    }

    /**
     * On click of next/previous arrow buttons, trigger an event for analytics. Triggered on
     * all clicks to the element, so triaging based on whether it was next or prev btn.
     *
     * @method triggerFullscreenAnalytics
     * @param {Event} ev
     */
    triggerDirectionSelectionAnalytics(ev) {
        const nextButton =
            ev.target.classList.contains('flickity-prev-next-button') &&
            ev.target.classList.contains('next');
        const previousButton =
            ev.target.classList.contains('flickity-prev-next-button') &&
            ev.target.classList.contains('previous');
        if (!nextButton && !previousButton) {
            return;
        }
        const eventIdData = nextButton
            ? 'Consumer147_165_182_251'
            : 'Consumer148_166_183_252';
        this.dispatchAction('analytics', {
            eventId: eventIdData,
            animalId: this.animalId,
            member: this.isMemberCarousel,
        });
    }

    /**
     * launches fullscreen modal
     */
    openFullscreen() {
        this.dispatchAction('ui.activate', {
            target: '#fullscreen_carousel',
        });
        this.triggerFullscreenAnalytics();
        this.dispatchAction('fullscreen-carousel.open', {
            imgIndex: this.actualIndex,
        });
    }

    /**
     * Check if the current selected element is a video
     */
    checkIfCurrentSlideIsVideo() {
        if (
            this.flickity.selectedElement.classList.contains(
                'petCarousel-body-videoSlide'
            )
        ) {
            this.classList.add('petCarousel_videoActive');
            this.currentSlideIsVideo = true;
        } else {
            this.classList.remove('petCarousel_videoActive');
            this.currentSlideIsVideo = false;
        }
    }

    /**
     * Sets the alert container text to the current slide img or video alt text
     */
    setAlertContainerTextToSelectedImg() {
        if (this.alertContainer) {
            if (
                this.flickity.selectedElement.classList.contains(
                    'petCarousel-body-videoSlide'
                )
            ) {
                const vidName =
                    this.flickity.selectedElement.getAttribute('alt') || '';

                this.alertContainer.innerText = `${vidName}, this is a video. Click the video thumbnail or enter fullscreen mode to play.`;
            } else {
                this.alertContainer.innerText =
                    this.flickity.selectedElement.getAttribute('alt') ||
                    'pet media';
            }
        }
    }

    setAriaActive() {
        this.flickity.cells.forEach(cell => {
            cell.element.setAttribute('aria-hidden', 'true');
        });
        if (this.flickity.selectedElement.classList.contains('is-selected')) {
            this.flickity.selectedElement.setAttribute('aria-hidden', 'false');
        }
    }

    /**
     * Determines which direction was swiped based off of flickity move vector on drag events
     * @param {number} moveVector
     * @returns {string} left or right
     */
    getSwipeDirection(moveVector) {
        let swipeDirection = null;
        if (moveVector.x > 0) {
            swipeDirection = SWIPE_DIR_RIGHT;
        } else {
            swipeDirection = SWIPE_DIR_LEFT;
        }
        return swipeDirection;
    }

    /**
     * Handler for drag movement events
     * @param {Event} ev
     * @param {Event} pointer
     * @param {Object} moveVector
     */
    onDragMove(ev, pointer, moveVector) {
        this.currentIndex = this.flickity.selectedIndex;
        this.swipeDirection = this.getSwipeDirection(moveVector);
    }

    /**
     * Handler for drag end events
     * @param {Event} ev
     * @param {Event} pointer
     */
    onDragEnd(ev, pointer) {
        const swipeDirection = this.swipeDirection;
        const queuedEvent = () => {
            if (this.flickity.selectedIndex !== this.currentIndex) {
                if (swipeDirection === SWIPE_DIR_LEFT) {
                    // Left Swipe, Right Slide Movement
                    this.dispatchAction('analytics', {
                        eventId: 'Consumer147_165_182_251',
                        animalId: this.animalId,
                        member: this.isMemberCarousel,
                    });
                } else {
                    // Right Swipe, Left Slide Movement
                    this.dispatchAction('analytics', {
                        eventId: 'Consumer148_166_183_252',
                        animalId: this.animalId,
                        member: this.isMemberCarousel,
                    });
                }
            }
        };

        this.analyticQueue.push(queuedEvent);
    }

    /**
     * Handler for standard click events
     * @param {Event} ev
     */
    onClicked(ev) {
        if (isDescendant(this.expand, ev.target) || ev.target === this.expand) {
            this.openFullscreen();
        }
        this.triggerDirectionSelectionAnalytics(ev);

        switch (true) {
            case this.navButtons.includes(ev.target):
                this.flickity.select(
                    this.navButtons.indexOf(ev.target),
                    false,
                    false
                );
                this.setAlertContainerTextToSelectedImg();
                break;
            default:
                break;
        }
    }

    /**
     * Handler for flickity `staticClick` events.
     *
     * @method onStaticClicked
     * @param {Event} ev
     * @param {Integer} pointer
     * @param {HTMLElement} cellElement
     * @param {Integer} cellIndex
     */
    onStaticClicked(ev, pointer, cellElement, cellIndex) {
        if (cellIndex === this.flickity.selectedIndex) {
            this.openFullscreen();
        } else {
            this.flickity.select(cellIndex);
        }
    }

    /**
     * Handler for flickity settled events
     */
    onSettled() {
        this.analyticQueue.forEach(func => func());
        this.analyticQueue = [];

        if (!this.initialResizeComplete) {
            this.flickity.resize();
            this.initialResizeComplete = true;
        }
    }

    /**
     * Handler for flickity select events
     */
    onSelected() {
        this.updateNav();
        this.setAlertContainerTextToSelectedImg();
        this.setAriaActive();
        this.checkIfCurrentSlideIsVideo();
    }

    /**
     * Handles update events from the app.
     *
     * @method onUpdated
     * @param  {Object} ev - event object
     */
    onUpdated(ev) {
        const { detail } = ev;
        switch (detail.type) {
            case 'pet-carousel.slide-change':
                this.flickity.select(detail.imgIndex, true, true);
                break;
            case EV_APP_READY:
                this.flickity.resize();
                break;
            default:
                break;
        }
    }
}
