export const eventDispatch = {
    aifMobile: {
        close() {
            const modal = document.getElementById('Inquiry_Modal');

            if (!modal) {
                return;
            }

            const detail = Object.assign(
                {},
                {},
                {
                    type: 'ui.deactivate',
                    trigger: {
                        dataset: {
                            target: '#Inquiry_Modal',
                            focusTarget: this.focusTarget,
                        },
                    },
                }
            );
            modal.dispatchEvent(
                new CustomEvent('action', {
                    bubbles: true,
                    detail,
                })
            );
        },
        focusTarget: "button[data-test='AIF_Mobile_Sticky_Btn']",
    },
};
