import { html } from '../../../core/scripts/util/util.js';
import { helpers } from '../../../core/scripts/util/helpers';

export default vm => {
    return html`
        <div class="grid grid_gutterLg m-grid_stretch">
            ${vm.touts.map(
                (tout, i) => html`
                    <div class="grid-col grid-col_1/2@minLg">
                        <pf-ensighten
                            on-click="Consumer026_027"
                            data-title="$${tout.title.rendered}"
                            data-position="content-tout-${i + 1}"
                            data-href="$${tout.src}"
                        >
                            <a
                                class="contentCard"
                                href="$${tout.src}"
                                aria-label="Read full article $${tout.title
                                    .rendered}, $${tout.excerpt.rendered}"
                            >
                                <div class="contentCard-media">
                                    <pfdc-lazy-load
                                        element="img"
                                        class="lazyLoad"
                                        img-class="lazyLoad-img"
                                        img-animation-class="m-lazyLoad-img_reveal"
                                        alt="$${tout.large_thumbnail.alt}"
                                        src="$${tout.large_thumbnail.src}"
                                    ></pfdc-lazy-load>
                                    <div class="contentCard-media-badge">
                                        <pfdc-lazy-load
                                            element="img"
                                            class="imgMask imgMask_circleLg m-imgMask_borderWhite"
                                            img-class="lazyLoad-img"
                                            img-animation-class="m-lazyLoad-img_reveal"
                                            alt="$${tout.small_thumbnail.alt}"
                                            src="$${tout.small_thumbnail.src}"
                                        ></pfdc-lazy-load>
                                    </div>
                                </div>
                                <div class="contentCard-body">
                                    <div class="contentCard-body-details">
                                        <h3 class="txt m-txt_xl m-txt_bold u-vr1x">
                                            $${tout.title.rendered}
                                        </h3>
                                        <p class="txt">
                                            $${tout.excerpt.rendered}
                                        </p>
                                    </div>
                                </div>
                                <div class="contentCard-footer">
                                    <div class="contentCard-footer-cta">
                                        Read More
                                    </div>
                                </div>
                            </a>
                        </pf-ensighten>
                    </div>
                `
            )}
        </div>
    `;
};
