import BaseModel from './BaseModel';
import SavedSearchCollection from './SavedSearchCollection';
import LocationModel from './LocationModel';

export default class UserMeModel extends BaseModel {
    static fromServer(userMeData) {
        const model = new UserMeModel(
            this._mapServerDataToClientside(userMeData)
        );

        // TODO: this is only here to prevent backwards compatibility breakages in other places
        // TODO: anything that uses UserMe data as obtained from Config.js needs to be refactored
        // TODO: ultimately, Config.js pass-thru of UserMe data should be deprecated
        model.dataFromServer = userMeData;

        return model;
    }

    static _mapServerDataToClientside(userMeData) {
        const user = userMeData.user;

        return {
            alerts: userMeData.alerts,
            didYouAdoptDetails: {
                active: userMeData.did_you_adopt_details.active,
                helloBarBoldText:
                    userMeData.did_you_adopt_details.hello_bar_bold_text,
                helloBarLinkTargetUrl:
                    userMeData.did_you_adopt_details.hello_bar_link_target_url,
                helloBarNormalText:
                    userMeData.did_you_adopt_details.hello_bar_normal_text,
                showHelloBar: userMeData.did_you_adopt_details.show_hello_bar,
            },
            location: LocationModel.fromUserMeData(userMeData.location),
            // TODO: what is this (preferences?) getting empty array back right now
            preferences: userMeData.preferences,
            properties: {
                ansiraUUID: userMeData.properties.ansira_user_profile_uuid,
            },
            savedSearchToken: userMeData.saved_search_token,
            savedSearches: SavedSearchCollection.fromUserMeData(
                userMeData.saved_searches
            ),
            token: userMeData.token,
            apiPassthroughToken: userMeData.api_passthrough_token,
            // TODO: user model?
            user: {
                isLoggedIn: Boolean(user),
                city: user ? userMeData.user.city : '',
                state: user ? userMeData.user.state : '',
                postalCode: user ? userMeData.user.postal_code : '',
                country: user ? userMeData.user.country : '',
                firstName: user ? userMeData.user.first_name : '',
                lastName: user ? userMeData.user.last_name : '',
                email: user ? userMeData.user.email : '',
            },
        };
    }

    get defaultData() {
        // TODO: these defaults are unvalidated as most of it is unused at this point; as things port over to utilize this model, validate
        return {
            alerts: [],
            didYouAdoptDetails: {
                active: false,
                helloBarBoldText: '',
                helloBarLinkTargetUrl: '',
                helloBarNormalText: '',
                showHelloBar: true,
            },
            location: new LocationModel(),
            preferences: [],
            savedSearchToken: '',
            savedSearches: new SavedSearchCollection(),
            token: '',
            apiPassthroughToken: '',
            // TODO: user model?
            user: {
                isLoggedIn: false,
                city: '',
                state: '',
                postalCode: '',
                country: '',
                firstName: '',
                lastName: '',
                email: '',
            },
        };
    }

    get location() {
        return this._data.location;
    }

    get isLoggedIn() {
        return this._data.user.isLoggedIn;
    }

    get apiPassthroughToken() {
        return this._data.apiPassthroughToken;
    }

    get savedSearches() {
        return this._data.savedSearches;
    }

    get city() {
        return this._data.user.city || '';
    }

    get state() {
        return this._data.user.state || '';
    }

    get postalCode() {
        return this._data.user.postalCode || '';
    }

    get country() {
        return this._data.user.country || this._data.location.countryCode || '';
    }

    get ansiraUUID() {
        return this._data.properties.ansiraUUID || null;
    }

    get savedSearchToken() {
        return this._data.savedSearchToken;
    }
}
