export default class APIResponse {
    customResponse = false;
    error = false;
    errorMessage = '';
    data = null;
    _cancelled = false;

    static get cancelled() {
        const instance = new APIResponse();
        instance.customResponse = true;
        instance.wasCancelled = true;
        return instance;
    }

    static errored(errorMessage, data = null) {
        const instance = new APIResponse(data, errorMessage);
        return instance;
    }

    constructor(data = null, errorMessage) {
        this.data = data;

        if (errorMessage) {
            this.error = true;
            this.errorMessage = errorMessage;
        }
    }

    set wasCancelled(value) {
        this._cancelled = value;
    }

    get wasCancelled() {
        return this._cancelled;
    }
}
