import PFDCElementElement from '../pfdc-element/element';
import template from './template.html';

import Cookies from 'js-cookie';
import socialLoginStateController from '../../react-components/specifics/SocialLogin/controller/SocialLoginStateController';
import Config from '../../../../core/scripts/lib/Config';
import AnimalModel from '../../models/AnimalModel';
import { focusFirstFocusable } from '../../../../core/scripts/lib/focusManager/index';
import { COOKIE_NAME, COOKIE_EXPIRATION } from '../../constants/favorites';
import FavoritesAPIService from '../../api-services/FavoritesAPIService';
import favoritesStateController from '../../state-controllers/favoritesStateController';
import recoShelfStateController from '../../state-controllers/recoShelfStateController';
import { analyticsFavorites002, analyticsFavorites003, ga4FavoritesEvent } from '../../analytics/favorites';
import { SSO_MODAL_ACTIONS, handleSSOModal } from '../../util/ssoModalHelpers';

/**
 * # `pfdc-favorite-btn` Element
 *
 * Element for displaying pet's favorite status
 *
 * <pfdc-favorite-btn></pfdc-favorite-btn>
 *
 * @class PFDCFavoriteBtnElement
 * @extends PFDCElement
 *
 */
export default class PFDCFavoriteBtnElement extends PFDCElementElement {
    static _elementTag = 'pfdc-favorite-btn';

    static template = template;

    ATTRIBUTES = {
        NAME: 'pet-name',
        ID: 'pet-id',
        INDEX: 'pet-index',
        REDIRECT_URL: 'on-success-redirect',
        SEARCH_RESULT: 'search-result',
    };

    get defaultLocalState() {
        return {
            ...super.defaultLocalState,
            userInteracted: false,
            awaitingResponse: true,
        };
    }

    get petName() {
        return this.getAttribute(this.ATTRIBUTES.NAME);
    }

    get animalId() {
        return Number(this.getAttribute(this.ATTRIBUTES.ID));
    }

    get index() {
        return Number(this.getAttribute(this.ATTRIBUTES.INDEX));
    }

    get isGuest() {
        return !Config.userAuthed;
    }

    get isSearchResult() {
        return this.hasAttribute(this.ATTRIBUTES.SEARCH_RESULT);
    }

    get shouldRedirect() {
        return this.hasAttribute(this.ATTRIBUTES.REDIRECT_URL);
    }

    get redirectUrl() {
        return this.getAttribute(this.ATTRIBUTES.REDIRECT_URL);
    }

    get isFavorite() {
        if (this.isGuest) {
            return false;
        } else {
            return favoritesStateController.isFavorite(this.animalId);
        }
    }

    get ariaLabel() {
        if (this.isFavorite) {
            return `Remove ${this.petName} from your favorites`;
        } else {
            return `Favorite ${this.petName}`;
        }
    }

    get extensionClasses() {
        const classes = {
            ['favoriteBtn_searchResult']: this.isSearchResult,
            ['s-favoriteBtn_favorited']: this.isFavorite,
            ['s-favoriteBtn_awaiting']: this.localState.awaitingResponse,
            ['s-favoriteBtn_sessionFavorited']: this.localState.userInteracted && this.isFavorite,
        };

        const classesArray = [];

        for (const key in classes) {
            if (classes[key]) {
                classesArray.push(key);
            }
        }

        return classesArray.join(' ');
    }

    get viewModel() {
        return {
            ...super.viewModel,
            ariaLabel: this.ariaLabel,
            extensionClasses: this.extensionClasses,
        };
    }

    get constructAnimalModel() {
        // TODO: if we want analytics on reco modules, we'll have to find another way to pass in the model
        if (!this.isSearchResult) {
            return {};
        }

        return AnimalModel.fromSearchResults(this.stateController.animalSearch.results.state.items[this.index]);
    }

    onConnected() {
        super.onConnected();

        this.animalModel = this.constructAnimalModel;

        if (this.isGuest) {
            this.addEventListener('click', this.onGuestClick);
        } else {
            this.addEventListener('click', this.onClick);
            this.checkIfFavorite();
        }

        favoritesStateController.subscribe(payload => {
            this.render();
            this.patchLocalState({
                userInteracted: false,
            });
        });
    }

    checkIfFavorite() {
        const nextState = {
            favorited: false,
            awaitingResponse: false,
        };
        const isFavorite = this.isFavorite;
        const pendingFavorite = this.getIdFromCookie() === this.animalId;

        // already favorite, just update state
        if (isFavorite) {
            nextState.favorited = true;
        }

        // delete cookie if it was pending but already a favorite
        if (isFavorite && pendingFavorite) {
            this.deleteCookie();
            this.scrollIntoView();
            focusFirstFocusable(this, true);
        }

        // not already favorite but is pending, add to favorites
        if (!isFavorite && pendingFavorite) {
            this.addFavorite();
            this.deleteCookie();
            this.scrollIntoView();
            focusFirstFocusable(this, true);
        }

        this.patchLocalState(nextState);
    }

    async addFavorite() {
        this.analyticsFavorite();

        const response = await FavoritesAPIService.setFavorite(this.animalId);

        if (!response.error) {
            favoritesStateController.addFavorite(this.animalId);

            this.patchLocalState({
                userInteracted: true,
            });
        }
    }

    async removeFavorite() {
        this.analyticsUnfavorite();

        this.patchLocalState({
            userInteracted: false,
        });

        const response = await FavoritesAPIService.removeFavorite(this.animalId);

        if (!response.error) {
            favoritesStateController.removeFavorite(this.animalId);
        }
    }

    async toggleFavorite() {
        if (this.localState.awaitingResponse) {
            return;
        }

        this.patchLocalState({
            awaitingResponse: true,
        });

        if (this.isFavorite) {
            await this.removeFavorite();
        } else {
            await this.addFavorite();
        }

        this.patchLocalState({
            awaitingResponse: false,
        });
    }

    /**
     * analyticsFavorite
     *
     * @fires Favorites002
     */
    analyticsFavorite() {
        if (this.isSearchResult) {
            analyticsFavorites002(this.animalModel, this.isGuest);
        }

        const memberProfileComponents = ['member_one', 'member_2'];
        const component = this.getAttribute('component');
        if (memberProfileComponents.includes(component)) {
            const animalsInReco = recoShelfStateController.shelves;
            const animalData = recoShelfStateController.getAnimalDataFromId(component, this.animalId);
            ga4FavoritesEvent(animalData, this.isGuest, 'member profile pages');
        }
    }

    analyticsUnfavorite() {
        analyticsFavorites003();
    }

    getIdFromCookie() {
        const cookie = Cookies.get(COOKIE_NAME);
        let animalId = null;

        if (cookie) {
            animalId = parseInt(cookie, 10);
        }

        return animalId;
    }

    setCookie() {
        Cookies.set(COOKIE_NAME, this.animalId, {
            expires: COOKIE_EXPIRATION,
            path: '/',
        });
    }

    deleteCookie() {
        Cookies.remove(COOKIE_NAME);
    }

    onAuthenticationSuccessRedirect = () => {
        window.location = this.redirectUrl;
    };

    onClick(ev) {
        ev.preventDefault();

        this.toggleFavorite();
    }

    onGuestClick(ev) {
        ev.preventDefault();

        this.setCookie();

        this.analyticsFavorite();

        const redirectProps = this.shouldRedirect ? { onSuccess: this.onAuthenticationSuccessRedirect } : {};

        if (window.ssoFlagIsActive) {
            handleSSOModal(SSO_MODAL_ACTIONS.open, 'pet favorite');
        } else {
            socialLoginStateController.openLoginModal({
                display: 'signUp',
                context: 'favorites',
                analyticTriggerLabel: 'favoriteButtonPetCard',
                refocusTarget: ev.target,
                ...redirectProps,
            });
        }
    }
}
