/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import FacebookAPIService from '../../services/FacebookAPIService';

import buildClassNames from 'classnames';

/**
 * Facebook sign in button
 * Requires that the gapi AUTH2 object has already been initialized on the window
 */
class FacebookButton extends Component {
    /* *******************************************
     * Class Properties
     ********************************************/

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        cta: PropTypes.string,
        next: PropTypes.string,
        next_params: PropTypes.array,
        type: PropTypes.string,
        text: PropTypes.bool,
        ready: PropTypes.bool,
        reauthenticate: PropTypes.bool,
        handleSocialClick: PropTypes.func,
        id: PropTypes.string,
        alternateStyle: PropTypes.bool, // white background with blue icon and text
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        cta: 'Continue with ',
        type: 'full',
        text: true,
        ready: false,
        reauthenticate: false,
        id: null,
    };

    /**
     * @type {Ref}
     */
    button = React.createRef();

    getRootClassNames() {
        return buildClassNames({
            btn: true,
            btn_facebookLogin: !this.props.alternateStyle,
            btn_facebookLoginWhite: this.props.alternateStyle,
            btn_facebookLogin_inline: this.props.type === 'inline',
            'm-btn_iconLeft': true,
            'm-btn_normalCase': true,
            'm-btn_full': true,
            's-btn_contentLoading': !this.props.ready,
        });
    }

    get buttonText() {
        let text = '';
        if (this.props.text) {
            text = `${text}${this.props.cta} `;
        }
        text = `${text}Facebook`;
        return text;
    }

    get buttonLabel() {
        return this.buttonText ? this.buttonText : 'Facebook';
    }

    /* *******************************************
     * Lifecycle
     ********************************************/
    componentDidMount() {
        if (this.props.ready) {
            this.attachClickHandler();
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.ready !== prevProps.ready && this.props.ready) {
            this.attachClickHandler();
        }
    }

    componentWillUnmount() {
        if (!window.FB) {
            return;
        }
        window.FB.Event.unsubscribe(
            'auth.statusChange',
            this.authorizationCallback
        );
    }

    /* *******************************************
     * Methods
     ********************************************/
    /**
     * @type {Function}
     * Attach click handler, pass in configuration values
     */
    attachClickHandler = () => {
        FacebookAPIService.bindLoginClick({
            context: this.props.context,
            button: this.button.current,
            reauthenticate: this.props.reauthenticate,
            next: this.props.next,
            next_params: this.props.next_params,
        });
    };

    /* *******************************************
     * Events
     ********************************************/

    /**
     * @type {Function}
     * Hook for analytic event, passes in provider
     */
    handleSocialClick = () => {
        this.props.handleSocialClick('facebook');
    };

    /* *******************************************
     * Render
     ********************************************/

    renderFunctionalButton = () => {
        return (
            <button
                ref={this.button}
                type="button"
                aria-label={this.buttonLabel}
                onClick={this.handleSocialClick}
                className={this.getRootClassNames()}
                id={this.props.id}
            >
                <span className="btn-icon">
                    <span className="icon icon_s18">
                        <svg role="presentation" focusable="false">
                            <use xlinkHref="#icon-facebookLogo" />
                        </svg>
                    </span>
                </span>
                {this.buttonText}
            </button>
        );
    };

    renderPendingButton = () => {
        return (
            <div
                aria-label="Facebook authentication is loading..."
                className={this.getRootClassNames()}
                id={this.props.id}
            >
                <span className="btn-icon" />
            </div>
        );
    };

    renderReauthenticateButton = () => {
        return (
            <button
                ref={this.button}
                type="button"
                className="btn m-btn_full"
                id={this.props.id}
            >
                {'Provide Email'}
            </button>
        );
    };

    renderStandardButton() {
        return (
            <Fragment>
                {!this.props.ready
                    ? this.renderPendingButton()
                    : this.renderFunctionalButton()}
            </Fragment>
        );
    }

    renderLoginType() {
        return (
            <Fragment>
                {this.props.reauthenticate
                    ? this.renderReauthenticateButton()
                    : this.renderStandardButton()}
            </Fragment>
        );
    }

    renderChild() {
        const child = React.cloneElement(this.props.children, {
            ref: this.button,
        });
        return <Fragment>{child}</Fragment>;
    }

    renderButtonType() {
        return (
            <Fragment>
                {this.props.children
                    ? this.renderChild()
                    : this.renderLoginType()}
            </Fragment>
        );
    }

    render() {
        return <Fragment>{this.renderButtonType()}</Fragment>;
    }
}

export default FacebookButton;
