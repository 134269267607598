import { PFElement } from '../pf-element/element';
import { EV_RESIZE_COMPLETE } from '../../constants/events';
import lineClamp from './lineClamp_mod';
import { parseJsonAttribute } from '../../util/dom';

const moreBtnAttr = 'pf-show-more-btn';
const moreBtnHtml = `<button ${moreBtnAttr} class="txt txt_link">more...</button>`;
const moreBtnSelector = `[${moreBtnAttr}]`;

/**
 * Truncate the inner block of text to a configurable number of lines.
 *
 * No tags allowed inside, except for the 'skip-element'.
 * Wrap tags around pf-truncate instead.
 * Only suports clamping static values.
 *
 * <pf-truncate
 *     line-count="Int"         (required)
 *     tail-margin="CSS Width"  (optional)
 *     skip-element="Selector"  (optional)
 *     truncate-on="event.name" (optional)
 *     show-more="Bool">        (optional)
 *
 *   text goes here
 *
 * </pf-truncate>
 *
 * @class PFTruncateElement
 * @extends PFElement
 */
export class PFTruncateElement extends PFElement {

    /**
    * The max number of lines to show.
    * (required)
    *
    * @return {Number}
    */
    get lineCount() {
        return Number(this.getAttribute('line-count'));
    }

    /**
     * Leaves a gap of specified width at the end of the truncated text
     * to make room for appended stuff.
     * (optional)
     *
     * @return {String}
     */
    get tailMargin() {
        return this.getAttribute('tail-margin') || '0px';
    }

    /**
     * Specify a child element to be appended rather than truncated.
     * (optional)
     *
     * @return {HtmlElement}
     */
    getSkipElement() {
        return this.querySelector(this.getAttribute('skip-element'));
    }

    /**
     * Re-render on a specified event.
     *
     * @return {String} Event
     */
    get truncateOnAttribute() {
        return this.getAttribute('truncate-on') || false;
    }

    /**
     * Enable a "more" button which removes truncation on click.
     *
     * @return {Bool}
     */
    get showMore() {
        return parseJsonAttribute(this, 'show-more') || false;
    }

    /**
     * Enable a "more" button which removes truncation on click.
     *
     * @return {HtmlElement}
     */
    get moreBtnEl() {
        return this.querySelector(moreBtnSelector);
    }

    /**
     * @constructor
     */
    constructor() {
        super();

        /**
         * Caches pre-truncated text.
         *
         * @property originalHTML
         * @type {String}
         */
        this.originalHTML = null;

        /**
         * Caches skip element
         *
         * @property clonedSkipElement
         * @type {String}
         */
        this.clonedSkipElement = null;

        /**
         * Reference to container element passed to the lib to be
         * used for calculation.
         *
         * @property lineClampEl
         * @type {String}
         */
        this.lineClampEl = null;

        /**
         * Whether or not the 'more' button has been clicked.
         *
         * @property truncationIsDisabled
         * @type {Bool}
         */
        this.truncationIsDisabled = false;
    }

    onInit() {
        // Cache and remove skip-element if present, to add back later.
        const skipElement = this.getSkipElement();
        if (skipElement) {
            this.clonedSkipElement = skipElement.cloneNode(true);
            skipElement.remove();
        }

        // Cache original content
        this.originalHTML = this.innerHTML.trim();

        // Create line clamp wrapper required by lib
        this.innerHTML = `<div line-clamp> ${this.originalHTML} </div>`;
        this.lineClampEl = this.firstChild;

        // Styles required by lib
        this.lineClampEl.style.overflowWrap = 'break-word';
        this.lineClampEl.style.wordWrap = 'break-word';

        this.clamp();

        this.addEventListener('click', this.onClicked);
    }

    /**
     * The clamp routine - calculated based on the original inner HTML.
     */
    clamp() {
        if (this.originalHTML.length <= 0 || this.truncationIsDisabled) {
            return;
        }

        this.lineClampEl.innerHTML = this.originalHTML;
        this.lineClampEl.style.display = 'block';

        const didClamp = lineClamp(this.lineClampEl, {
            lineCount: this.lineCount,
            tailMargin: this.tailMargin,
        });

        this.insertShowMoreBtnIfNeeded(didClamp);

        // So you can append stuff to the text
        this.lineClampEl.style.display = 'inline';

        if (this.clonedSkipElement) {
            this.appendChild(this.clonedSkipElement);
        }
    }

    /**
     * Listen for update events.
     * @param {Event} ev
     */
    onUpdated(ev) {
        const type = ev.detail.type;

        if (type === EV_RESIZE_COMPLETE || type === this.truncateOnAttribute) {
            this.clamp();
        }
    }

    /**
     * Handle click events on various elements in the component.
     *
     * @method onClicked
     * @param {Event} ev
     */
    onClicked(ev) {
        if (ev.target.hasAttribute(moreBtnAttr)) {
            this.removeTruncate();
        }
    }

    /**
     * Show full text when 'more' is clicked.
     *
     * @method removeTruncate
     */
    removeTruncate() {
        this.lineClampEl.innerHTML = this.originalHTML;
        this.moreBtnEl.remove();
        this.truncationIsDisabled = true;
    }

    /**
     * Insert the show more button if needed.
     * @param {boolean} didClamp
     */
    insertShowMoreBtnIfNeeded(didClamp) {
        if (this.showMore !== true) {
            return;
        }

        try {
            this.moreBtnEl.remove();
        } catch (e) {
        }
        if (didClamp) {
            this.insertAdjacentHTML('beforeend', moreBtnHtml);
        }
    }
}

export default PFTruncateElement;
