import React from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../../core/scripts/react-components/specifics/LoaderButton';
import StateController from '../../generics/StateController';
import siteStateController from '../../../state-controllers/siteStateController';
import savedSearchService from '../../../../../core/scripts/services/savedSearchService';
import { analyticsConsumer340, eventClickSavedSearch } from '../../../analytics/dotcom';

function SavedSearchAnimalSearchButton({ isLoggedIn = false }) {
    /* *******************************************
     * Events
     ********************************************/
    /**
     * Handles a click on the Save Search button when logged in
     *
     * @param {MouseEvent} e
     * @fires PetfinderConsumer340
     * @private
     */
    const _onSaveSearchButtonClick = e => {
        savedSearchService.launchSaveSearchModal({
            focusTarget: e.currentTarget,
            analyticTriggerLabel: 'saveSearch',
            searchUrl: siteStateController.animalSearch.results.url,
        });
        analyticsConsumer340();
        eventClickSavedSearch({ isLoggedIn });
    };

    return (
        <StateController
            stateLocation="ui.savedSearch.isSaving"
            onStateUpdated={stateControllerState => (
                <LoaderButton
                    active={stateControllerState}
                    onClick={_onSaveSearchButtonClick}
                    extensionClassNames={{
                        ['btn_clear']: true,
                    }}
                    type="button"
                    label="Save Search"
                />
            )}
        />
    );
}

SavedSearchAnimalSearchButton.propTypes = {
    isLoggedIn: PropTypes.bool,
};
SavedSearchAnimalSearchButton.defaultProps = {
    isLoggedIn: false,
};
export default SavedSearchAnimalSearchButton;
