import BaseModel from '../../models/BaseModel';
import _get from 'lodash/get';

export default class BaseAnalyticEventModel extends BaseModel {
    _analyticProperties = this.defaultAnalyticProperties;
    _defaultNullText = '';

    get defaultData() {
        return {
            dimensions: {},
            dataLayer: {},
        };
    }

    /**
     * defaultAnalyticsProperties
     *
     * @return {Object}
     */
    get defaultAnalyticProperties() {
        return {};
    }

    constructor(
        data,
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        super(data);
        if (analyticProperties) {
            if (overrideAnalyticProperties === true) {
                this._analyticProperties = analyticProperties;
            } else {
                Object.assign(this._analyticProperties, analyticProperties);
            }
        }

        setTimeout(() => {
            this._afterAnalyticConstructor();
        }, 0);
    }

    /**
     * _afterAnalyticConstructor - hook
     */
    _afterAnalyticConstructor() {
        Object.seal(this._analyticProperties);
    }

    /**
     * _mapEventData
     *
     * @static
     * @param {Object} eventData
     *
     * @return {Object}
     */
    static _mapEventData(eventData) {
        return {
            dimensions: _get(eventData, 'dimensions', {}),
            dataLayer: _get(eventData, 'dataLayer', {}),
        };
    }

    /**
     * _buildDataLayer - build dimension and dataLayer objects
     *
     * @param {Object} analytics raw data
     * @param {null|string} nullText override default nullText
     * @param {Boolean} filterOutNullText override show null values
     * @param {Boolean} filterOutAuxillaryProperties
     * @param {Object} analyticTypes object to determine value types(dataLayer, dimensions)
     *
     * @return {Object}
     */
    _buildDataLayer(
        analytics,
        nullText,
        filterOutNullText = true,
        filterOutAuxillaryProperties = true,
        analyticTypes
    ) {
        // Gather object using properties model(returns object of information for dL, dimensions, values, secondary)
        const properties = this._buildProperties(analytics, nullText);

        // Filter properties keys based on settings, map to array
        const propertiesArray = this._buildPropertiesArray(
            analytics,
            filterOutNullText,
            filterOutAuxillaryProperties
        );

        // Map propertiesArray with proper getter values
        const analyticMap = propertiesArray.map(property => {
            try {
                return properties[property]();
            } catch (error) {
                throw new Error(
                    `An error occurred while getting user info property: ${property} - ${error}`
                );
            }
        });

        // Make analytic object based on analyticTypes(dL, secondary, dimensions)
        const analyticsObject = this._buildAnalytics(
            analyticMap,
            analyticTypes
        );

        // Filter analyticsObject based on values if filterOtNullText
        if (filterOutNullText) {
            for (const propName in analyticsObject) {
                if (
                    analyticsObject[propName] === null ||
                    analyticsObject[propName] === nullText ||
                    analyticsObject[propName] === this._defaultNullText
                ) {
                    delete analyticsObject[propName];
                }
            }
        }

        return analyticsObject;
    }

    // Filter properties object to array if values are true and eventParams have property key
    _buildPropertiesArray(
        analytics,
        filterOutNullText,
        filterOutAuxillaryProperties
    ) {
        let properties = Object.keys(this._analyticProperties).filter(
            key =>
                this._analyticProperties[key] &&
                (analytics.hasOwnProperty(key) || !filterOutNullText)
        );
        if (filterOutAuxillaryProperties) {
            properties = properties.filter(
                key =>
                    this._analyticProperties[key] &&
                    analytics.hasOwnProperty(key)
            );
        }

        return properties;
    }

    /**
     * _buildProperties - default function placeholder
     *
     * @param {Object}  analytics
     * @param {null|string}  nullText
     *
     * @return {Object}
     */
    _buildProperties(analytics, nullText) {
        return {};
    }

    /**
     * _buildAnalytics
     *
     * @param {Array} analyticsArray
     * @param {Object} analyticTypes
     *
     * @returns {Object}
     */
    _buildAnalytics(analyticsArray, analyticTypes) {
        const analytics = {};
        if (analyticsArray.length) {
            analyticsArray.forEach(data => {
                try {
                    if (typeof data.analyticValue !== 'undefined') {
                        if (
                            _get(analyticTypes, 'dimensions') &&
                            data.dimensionKey
                        ) {
                            analytics[data.dimensionKey] = data.analyticValue;
                        }

                        if (
                            _get(analyticTypes, 'dataLayer') &&
                            data.dataLayerKey
                        ) {
                            analytics[data.dataLayerKey] = data.analyticValue;
                        }
                        if (
                            _get(analyticTypes, 'secondary') &&
                            data.secondaryDataLayerKey
                        ) {
                            analytics[data.secondaryDataLayerKey] =
                                data.analyticValue;
                        }
                    }
                } catch (error) {
                    throw new Error(
                        `An error occurred while building analytics with data: ${analytics} - ${error}`
                    );
                }
            });
        }

        return analytics;
    }

    set analyticObjects(eventData) {
        const nullText = _get(eventData, 'nullText', this._defaultNullText);
        const filterOutNullText = _get(eventData, 'filterOutNullText', true);
        const filterOutAuxillaryProperties = _get(
            eventData,
            'filterOutAuxillaryProperties',
            true
        );
        const includeSecondary =
            typeof _get(eventData, 'includeSecondary') !== 'undefined'
                ? _get(eventData, 'includeSecondary')
                : true;

        // Set dimensions
        this._data.dimensions = this._buildDataLayer(
            eventData,
            nullText,
            filterOutNullText,
            filterOutAuxillaryProperties,
            {
                dimensions: true,
            }
        );

        // Set dataLayer
        this._data.dataLayer = this._buildDataLayer(
            eventData,
            nullText,
            filterOutNullText,
            filterOutAuxillaryProperties,
            {
                dataLayer: true,
                secondary: includeSecondary,
            }
        );
    }

    /**
     * dimensions
     *
     * @return {Object}
     */
    get dimensions() {
        return this._data.dimensions;
    }

    /**
     * dataLayer
     *
     * @return {Object}
     */
    get dataLayer() {
        return this._data.dataLayer;
    }
}
