import APIService from '../../../core/scripts/services/APIService';
import userMeAPIService from '../../../core/scripts/services/userMeAPIService';
import Utils from '../../../core/scripts/lib/wirey/Utils';

export default class SavedSearchAPIService extends APIService {
    static url = '/user/search-alerts/';

    static LOCAL_TO_API_PARAM_NAME_MAP = {
        name: 'saved_search[name]',
        token: 'saved_search[token]',
        searchUrl: 'saved_search[search_url]',
        email: 'saved_search[email]',
        locationSlug: 'saved_search[location_slug]',
    };

    /**
     * Creates a saved search
     * @param {object} params form data object
     * @return {Promise}
     */
    async create(params) {
        const formDataParams = Utils.ObjectUtils.mapTransform(
            params,
            {},
            SavedSearchAPIService.LOCAL_TO_API_PARAM_NAME_MAP
        );

        // selectively include parameters depending on whether or not the user is logged in
        const isLoggedIn = await userMeAPIService.isLoggedIn;

        if (!isLoggedIn) {
            if (params.country) {
                formDataParams['saved_search[country]'] = params.country;
            }

            if (params.optins) {
                // TODO: ideally the backend would accept booleans for these...
                // something to improve in the future
                const transformedOptins = {};
                // eslint-disable-next-line
                for (const key in params.optins) {
                    if (!params.optins[key]) {
                        continue;
                    }

                    transformedOptins[key] = 'on';
                }

                Object.assign(formDataParams, transformedOptins);
            }
        }

        return this.queryAndProcess({
            method: 'POST',
            headers: {
                // TODO: if this breaks in dev/prod, re-enable this; otherwise, remove
                // 'Access-Control-Allow-Origin': 'same-origin',
            },
            data: APIService.formDataFromObject(formDataParams),
        });
    }

    _extractDataFromServerResponse(responseData) {
        return responseData.data;
    }

    /**
     * This function exists only to allow an easier interface to perform an update without breaking backwards compatibility
     *
     * @param {String} uuid
     * @param {{ name: String, subscribed: Boolean, token: String }} params
     */
    async update(uuid, params) {
        const formDataParams = {
            'saved_search[name]': params.name,
            'saved_search[subscribed]': params.subscribed,
            'saved_search[token]': params.token,
        };

        return await this.queryAndProcess({
            urlAppend: `{uuid}/edit/`,
            urlParams: {
                uuid,
            },
            method: 'POST',
            headers: {
                // TODO: if this breaks in dev/prod, re-enable this; otherwise, remove
                // 'Access-Control-Allow-Origin': 'same-origin',
            },
            data: APIService.formDataFromObject(formDataParams),
        });
    }

    // TODO: to be implemented
    // /**
    //  * Deletes a saved search item
    //  * @param {string} uuid saved search id
    //  * @param {string} formAction
    //  * @param {FormData} params form data object from the delete form
    //  * @return {Promise}
    //  */
    // async delete(uuid, formAction, params) {
    //     const response = await fetch(
    //         formAction,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 // Need this content-type here
    //                 'X-Requested-With': 'XMLHttpRequest',
    //             },
    //             credentials: 'same-origin',
    //             body: params,
    //         }
    //     );

    //     if (response.status === 400) {
    //         // For parsing error response and getting redirect
    //         return Promise.reject(await this.readResponse(response));
    //     }

    //     if (this.wasRequestSuccessful(response)) {
    //         return Promise.resolve(await this.readResponse(response));
    //     }

    //     return Promise.reject({
    //         errors: [ERROR_API],
    //     });
    // }
}

export const savedSearchAPIService = new SavedSearchAPIService();
