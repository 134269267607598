import StateController from '../../../core/scripts/lib/wirey/state/StateController';

import _union from 'lodash/union';

class RecoShelfStateController extends StateController {
    get defaultState() {
        return {
            shelves: {}, // Updated to have multiple shelves, each containing multiple animals
        };
    }

    /**
     * @returns {Object}
     */
    get shelves() {
        return this.state.shelves;
    }

    /**
     * @returns {Object}
     * @param {string} recoShelfId
     * @param {string} animalId
     */
    getAnimalDataFromId(recoShelfId, animalId) {
        return this.shelves[recoShelfId] ? this.shelves[recoShelfId][animalId] : null;
    }

    /**
     * Adds an animal to the state, by shelf id and animal id
     * @param {string} recoShelfId
     * @param {string} animalId
     * @param {Object} animalData
     **/
    addAnimal(recoShelfId, animalId, animalData) {
        if (typeof recoShelfId !== 'string' || !(typeof animalId === 'string' || typeof animalId === 'number')) {
            return;
        }

        if (!this.shelves[recoShelfId]) {
            this.shelves[recoShelfId] = {};
        }

        const shelf = this.shelves[recoShelfId];
        shelf[animalId] = animalData;
        this.patchState({
            shelves: { ...this.shelves },
        });
    }

    /**
     * Clears the state for a specific shelf
     * @param {string} recoShelfId
     */
    clearShelf(recoShelfId) {
        if (this.shelves[recoShelfId]) {
            this.shelves[recoShelfId] = {};
        }
        this.patchState({
            shelves: { ...this.shelves },
        });
    }
}

export default new RecoShelfStateController();
