import Utils from './Utils';
import _kebabCase from 'lodash/kebabCase';

class ObjectExtensions {
    /**
     * Find event callback functions and bind events based on those present
     */

    static EVENT_HANDLER_REGEX = /on([A-Za-z]+)Event/;
    static ATTRIBUTE_HANDLER_REGEX = /on([A-Za-z]+)AttrChange/;

    /**
     * @param {object} obj
     * @param {RegExp} pattern
     */
    static findAttributesWithPattern(obj, pattern) {
        return Utils.ObjectUtils.getAllPropertyNames(obj).reduce(
            (foundArr, member) => {
                const result = pattern.exec(member);

                if (result) {
                    foundArr.push(result);
                }

                return foundArr;
            },
            []
        );
    }

    /**
     * Searches through the provided object for functions matching the EVENT_HANDLER_REGEX,
     * and attaches event handlers accordingly.
     *
     * This function acts off of an element where 'this' refers to the element itself.
     *
     * @param {object} obj Object to search
     * @param {object} ele Element to attach event to
     */
    static addEventAutoWiring(obj, ele = null) {
        const elementToAttachTo = ele ? ele : obj;

        const results = this.findAttributesWithPattern(
            Object.getPrototypeOf(obj),
            this.EVENT_HANDLER_REGEX
        );

        return results.map(item => {
            const fnName = item[0];
            const fn = obj[fnName];

            if (typeof fn !== 'function') {
                return;
            }

            const eventName = item[1].toLowerCase();

            const boundFn = fn.bind(obj);
            elementToAttachTo.addEventListener(eventName, boundFn);

            return {
                eventName,
                boundFn,
            };
        });
    }

    /**
     * Searches through the provided class reference for functiosn matching the ATTRIBUTE_HANDLER_REGEX,
     * and adds attribute change callbacks.
     */

    static addCustomElementAttributeAutoWiring(classRef) {
        const results = this.findAttributesWithPattern(
            classRef.prototype,
            this.ATTRIBUTE_HANDLER_REGEX
        );

        if (!classRef.observedAttributes) {
            classRef.observedAttributes = [];
        }
        const attrToCallbackNameMap = {};

        results.forEach(item => {
            const camelCasedAttributeName = item[1];
            const kebabCasedAttributeName = _kebabCase(camelCasedAttributeName);

            classRef.observedAttributes.push(kebabCasedAttributeName);
            attrToCallbackNameMap[kebabCasedAttributeName] = item[0];
        });

        // add top level callback function
        // NOTE: intentional use of function() {}
        classRef.prototype.attributeChangedCallback = function(
            attr,
            oldValue,
            newValue,
            namespace
        ) {
            const fnName = attrToCallbackNameMap[attr];

            // here, 'this' == the element containing the changed attribute
            this[fnName](oldValue, newValue, namespace);
        };
    }
}

export default ObjectExtensions;
