import MixinBase from './MixinBase';
import ActionParser from '../ActionParser';

export default class InitMixin extends MixinBase {
    static _attributeName = 'mix-init';

    constructor(ele, value) {
        super(ele, value);
        const actions = ActionParser.evalActionString(value);
        ActionParser.executeActions(actions, { $mixin: this, $element: this._ele });
    }
}
