import '../../../../dotcom/scripts/polyfills/polyfills-all';
import initLegacyPolyfills from '../utils/load-polyfills';
import initWindow from './window';
// @NOTE: Sentry Error Reporting if needed in future
// import initErrorReporting from '../../../../dotcom/scripts/lib/ErrorReporting';
import initCssHelpers from '../utils/css-helpers';
import initSocialLogin from './social-login';

export default async function initShared() {
    await initLegacyPolyfills();
    initWindow();
    // @NOTE: Sentry Error Reporting if needed in future
    // initErrorReporting();
    initCssHelpers();
    initSocialLogin();
}
