import ObservedStateElement from '../../../../core/scripts/lib/wirey/ObserveStateElement';

import siteStateController from '../../state-controllers/siteStateController';
import StateController from '../../../../core/scripts/lib/wirey/state/StateController';

export class PFDCElementElement extends ObservedStateElement {
    static _elementTag = 'pfdc-element';

    get stateController() {
        return siteStateController;
    }

    get state() {
        return this.stateController.state;
    }

    get viewModel() {
        return {
            ...super.viewModel,
            localState: this.localState,
            state: this.state,
        };
    }

    get defaultLocalState() {
        return {};
    }

    get localState() {
        return this._localStateController
            ? this._localStateController.state
            : {};
    }

    patchLocalState(obj) {
        return this._localStateController.patchState(obj);
    }

    setLocalState(obj) {
        return this._localStateController.setState(obj);
    }

    _localStateUnsubscribeFn = null;

    onConnected() {
        super.onConnected();

        this._localStateController = new StateController(
            this.defaultLocalState
        );
        this._localStateUnsubscribeFn = this._localStateController.subscribe(
            () => this.render()
        );
        this.subscribe(
            () => this.onBreakpointChanged(this.state.currentBreakpoint),
            'currentBreakpoint'
        );
    }

    /**
     * @param {string} currentBreakpoint
     */
    onBreakpointChanged(currentBreakpoint) {}

    onDisconnected() {
        super.onDisconnected();
        if (typeof this._localStateUnsubscribeFn === 'function') {
            this._localStateUnsubscribeFn();
        }
    }
}

export default PFDCElementElement;
