/**
 * @type {string}
 */
export const ERROR_API = 'There was an error processing the request.';

/**
 * @type {string}
 */
export const ERROR_NO_GLOBAL_CSRF_TOKEN =
    'A global CSRF Token is required for POSTs to Core API.';

/**
 * @type {string}
 */
export const ERROR_NO_LOCAL_CSRF_TOKEN =
    'A CSRF Token Object is required for POSTs to the Web Front API.';

/**
 * @type {string}
 */
export const ERROR_REQUIRED_FIELD = 'This value should not be empty.';

/**
 * @type {string}
 */
export const ERROR_SELECTION_REQUIRED = 'A selection is required.';
