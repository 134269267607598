/**
 * NOTE: this mixin does *NOT* currently (should it?) contain all the functionality
 * that pf-ensighten did such as "at-breakpoint" and "hub" (?) options.
 *
 * If these are needed, additional work will be needed.
 */

import ensightenTracking from '../../../../core/scripts/elements/pf-app/ensighten/ensighten';
import * as happyTailsAnalytics from '../../analytics/happy-tails';
import * as onboardingQuizAnalytics from '../../analytics/onboarding-quiz';
import * as socialLoginAnalytics from '../../analytics/social-login';
import * as searchAndMatchAnalytics from '../../analytics/search-and-match';
import * as favoritesAnalytics from '../../analytics/favorites';

class PFDCTrackingBridge extends HTMLElement {
    ensighten(eventId, refs) {
        const detail = {
            eventId,
            srcEvent: refs.$event,
            trigger: refs.$element,
            type: 'analytics',
        };
        const analyticKey = detail.trigger.getAttribute('data-analytics-key');
        if (analyticKey) {
            switch (analyticKey) {
                case 'happy-tails':
                    happyTailsAnalytics[eventId](detail);
                    break;
                case 'onboarding-quiz':
                    onboardingQuizAnalytics[eventId](detail);
                    break;
                case 'social-login':
                    socialLoginAnalytics[eventId](detail);
                case 'search-and-match':
                    searchAndMatchAnalytics[eventId](detail);
                    break;
                case 'favorites':
                    favoritesAnalytics[eventId](detail);
                    break;
            }
        } else {
            ensightenTracking[`event${eventId}`](detail);
        }
    }
}

export default PFDCTrackingBridge;

// TODO: remove once not doing named imports in element registry.. or once registry is replaced
export const PFDCTrackingBridgeElement = PFDCTrackingBridge;
