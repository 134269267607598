import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';
import templates from '../../../../dotcom/scripts/util/TemplateRegistry';
import { EV_SAVED_SEARCH_LIST_LOADED } from '../../constants/events';
import Config from '../../../../core/scripts/lib/Config';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-saved-search-list';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    INSERTION_POINT: `[${ELEMENT_TAG_NAME}-insertion]`,
};

/**
 * This component will get the saved searchs from user me and render them into a specified template
 * from the global template registry. The template attr is a template function name.
 *
 * REQUIRED BASE MARKUP:
 * ---
 * <pfdc-saved-search-list template="savedSearchList"></pfdc-saved-search-list>
 * ---
 *
 * @extends PFElement
 */
export class PFDCSavedSearchListElement extends PFElement {
    /**
     * Initialize this component
     */
    onInit() {
        this.getSavedSearches();
    }

    async getSavedSearches() {
        try {
            const savedSearches = await Config.savedSearches;
            const templateName = this.getAttribute('template');

            if (!savedSearches.length) {
                this.remove(); // Remove this module from the dom
            }

            this.innerHTML = templates[`${templateName}`]({
                savedSearches,
            });

            this.dispatchAction(EV_SAVED_SEARCH_LIST_LOADED);
        } catch (error) {
            this.remove(); // Remove this module from the dom
        }
    }
}

export default PFDCSavedSearchListElement;
