// Default DFP ID and campaign name. An alternative campaign name may be used for testing purposes
// or special ad partnerships for example.
export const AD_DFP_ID = '46267414';
export const AD_DFP_CAMPAIGN = 'Petfinder_Pro';

// Ad names from https://support.google.com/adsense/answer/6002621?hl=en
// Additional sizes must be added to this object and used in ad configuration
export const ADS = {
    NONE: {
        name: 'None',
        size: [0, 0],
    },
    MOBILE_LEADERBOARD: {
        name: 'Mobile Leaderboard',
        size: [320, 50],
    },
    LARGE_MOBILE_BANNER: {
        name: 'Large Mobile Banner',
        size: [320, 100],
    },
    SKYSCRAPER: {
        name: 'Skyscaper',
        size: [160, 600],
    },
    MEDIUM_RECTANGLE: {
        name: 'Medium Rectangle',
        size: [300, 250],
    },
    LEADERBOARD: {
        name: 'Leaderboard',
        size: [728, 90],
    },
};

export class AdProvider {
    // NOTE: all ad getters should return ads ordered by size descending
    static get adArray() {
        return Object.entries(ADS)
            .map(entry => ({ id: entry[0], ...entry[1] }));
    }

    static get horizontalAds() {
        return this.adArray
            .filter(item => item.size[0] > item.size[1])
            .sort((a, b) => {
                const widthDiff = b.size[0] - a.size[0];
                const heightDiff = b.size[1] - a.size[1];

                return widthDiff === 0
                    ? heightDiff
                    : widthDiff;
            });
    }

    static get verticalAds() {
        return this.adArray
            .filter(item => item.size[0] < item.size[1])
            .sort((a, b) => {
                const widthDiff = b.size[0] - a.size[0];
                const heightDiff = b.size[1] - a.size[1];

                return heightDiff === 0
                    ? widthDiff
                    : heightDiff;
            });
    }

    static get adsByArea() {
        return this.adArray
            .sort((a, b) => (b.size[0] * b.size[1]) - (a.size[0] * a.size[1]));
    }

    static getLargest(sourceArray, maxWidth = Number.MAX_SAFE_INTEGER, maxHeight = Number.MAX_SAFE_INTEGER, adPoolIds = null) {
        return sourceArray.find(item => {
            if (item.size[0] > maxWidth) {
                return false;
            }

            if (item.size[1] > maxHeight) {
                return false;
            }

            if (adPoolIds && adPoolIds.length && !adPoolIds.includes(item.id)) {
                return false;
            }

            return true;
        });
    }

    static getLargestHorizontal(maxWidth, maxHeight, adPoolIds) {
        return this.getLargest(this.horizontalAds, maxWidth, maxHeight, adPoolIds);
    }

    static getLargestVertical(maxWidth, maxHeight, adPoolIds) {
        return this.getLargest(this.verticalAds, maxWidth, maxHeight, adPoolIds);
    }

    static getLargestByDimensions(maxWidth, maxHeight) {
        return (maxWidth > maxHeight)
            ? this.getLargestHorizontal(maxWidth, maxHeight)
            : this.getLargestVertical(maxWidth, maxHeight);
    }

    static getLargestByArea(maxWidth, maxHeight, adPoolIds) {
        return this.getLargest(this.adsByArea, maxWidth, maxHeight, adPoolIds);
    }
}
