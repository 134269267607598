import StateController from '../../../../core/scripts/lib/wirey/state/StateController';

export default class ResultsStateController extends StateController {
    get defaultState() {
        return {
            count: -1,
            items: [],
            totalResults: -1,
            totalPages: -1,
            url: '',
            shareableUrl: '',
            name: '',
            tout: {
                title: '',
                excerpt: '',
                src: '',
                iconUrl: '',
                iconAlt: '',
            },
            searchSummary: {
                animalType: '',
                near: '',
                searchLocationName: '',
            },
        };
    }

    get count() {
        return this.state.count;
    }

    get items() {
        return this.state.results;
    }

    get totalResults() {
        return this.state.totalResults;
    }

    get roundedTotalResults() {
        const totalResults = this.totalResults;

        if (totalResults < 1000) {
            return totalResults;
        }

        return `${Math.ceil(totalResults / 100) / 10}K`;
    }

    get totalPages() {
        return this.state.totalPages;
    }

    get name() {
        return this.state.name;
    }

    get url() {
        return this.state.url;
    }

    get shareableUrl() {
        return this.state.shareableUrl;
    }

    get tout() {
        return this.state.tout;
    }

    get summary() {
        return this.state.summary;
    }

    set({
        items,
        totalResults,
        totalPages,
        url,
        shareableUrl,
        name,
        contentTout,
        summary,
    }) {
        const tout = this.buildContentToutObject(contentTout);

        this.setState({
            count: items.length,
            items,
            totalResults,
            totalPages,
            url,
            shareableUrl,
            name,
            tout,
            searchSummary: {
                animalType: summary['animal_type'],
                near: summary['near'],
                searchLocationName: summary['animal_search_location_name'],
            },
        });
    }

    buildContentToutObject(contentTout) {
        if (typeof contentTout === 'object') {
            return {
                title: contentTout.title.rendered,
                excerpt: contentTout.excerpt.rendered,
                src: contentTout.src,
                iconUrl: contentTout.icon.src,
                iconAlt: contentTout.icon.alt,
            };
        }
        return this.defaultState.tout;
    }
}
