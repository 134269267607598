import MixinBase from './MixinBase';
import Utils from '../Utils';

export default class CheckedMixin extends MixinBase {
    static _attributeName = 'mix-checked';

    constructor(ele, value) {
        super(ele, value);

        const result = Utils.HTMLElementUtils.findElementContainingAddress(value, ele, true);

        let content;

        if (result.value) {
            content = (typeof result.value === 'object')
                ? JSON.stringify(result.value)
                : result.value;
        } else {
            content = '';
        }

        this._ele.checked = content;
    }
}
