import t from 'tcomb';
import anime from 'animejs';

/**
 * # Animate
 *
 * `Animate` is an animation engine which uses Anime.js under the hood.
 *
 * Its main functions are:
 *
 * - provide a predefined set of animations to hook into from views
 *      this.animationEngine.defineAnimation({
 *          type: 'fadeOut',
 *          element: this,
 *          opts: {
 *              easing: [0.25, 0.46, 0.45, 0.94],
 *              duration: 3000,
 *          },
 *          playOnDefine: true, // necessary property to play immediately on definition, rather than calling runAnimation on a stored animation instance
 *      });
 * - provide the opportunity to create sequential animation groups. This is done by utilizing timelines.
 *      Example:
 *      this.showModal = this.animationEngine.defineTimeline([
 *          {
 *              type: 'fadeIn',
 *              element: this,
 *              opts: {
 *                  display: this.displayType,
 *                  easing: [0.25, 0.46, 0.45, 0.94], //custom bezier curves must be defined as a four-value array
 *              },
 *          },
 *          {
 *              type: 'fadeIn',
 *              element: this.contentContainer,
 *              opts: {
 *                  easing: 'linear',
 *                  offset: 1000,
 *              },
 *          },
 *      ])
 *
 *      this.animationEngine.runAnimation(this.showModal);
 */

export class Animate {

    /**
     * @class Animate
     * @constructor
     */
    constructor() {
        /**
         * Default animation configuration to use if no config is supplied
         * @type {Object} defaultOpts
         */
        this.defaultOpts = {
            easing: [0.455, 0.03, 0.515, 0.955],
            direction: 'normal',
            delay: 0,
            elasticity: 0,
            offset: 0,
            autoplay: false,
        };
    }

    /**
     * Builds an array from Elements
     *
     * @param {Array} elements
     * @return {Array}
     */
    _buildElementArray(elements) {
        if (!elements.length) {
            return [elements];
        }
        return Array.from(elements);
    }

    /**
     * Builds keyframe effects
     *
     * @param {Array} elements to animate
     * @param {Array} propertyKeys array of object properties to be animated (contains keyframes)
     * @param {Object} opts custom animation params
     * @param {Array} display starting css display values
     * @return {Object}
     */
    _animate(elements, propertyKeys, opts) {
        const elementsArr = this._buildElementArray(elements);

        const fill = opts.fill || 'none';
        const duration = t.Num.is(opts.duration) ? opts.duration : this.defaultOpts.duration;
        const easing = opts.easing || this.defaultOpts.easing;
        const direction = opts.direction || this.defaultOpts.direction;
        const delay = opts.delay || this.defaultOpts.delay;
        const elasticity = opts.elasticity || this.defaultOpts.elasticity;
        const offset = opts.offset || this.defaultOpts.offset; // used for timelines
        const autoplay = opts.autoplay || this.defaultOpts.autoplay;

        const promise = opts.promise || undefined ;

        const animationObject = {
            targets: elementsArr,
            delay,
            elasticity,
            easing,
            offset,
            autoplay,
            begin: anim => {
                if (opts.valueBefore) {
                    elementsArr.forEach(el => {
                        const newElement = el;
                        opts.valueBefore.forEach(property => {
                            const key = (Object.keys(property))[0];
                            const value = (Object.values(property))[0];
                            newElement.style[key] = value;
                        });
                        return newElement;
                    });
                }

                if (opts.classBefore) {
                    elementsArr.forEach(el => {
                        const newElement = el;
                        Array(opts.classBefore).forEach(startClass => {
                            if (newElement.classList.contains(String(startClass))) {
                                return;
                            } else {
                                newElement.classList.add(String(startClass));
                            }
                        });
                        return newElement;
                    });
                }
            },
            complete: anim => {
                if (opts.valueAfter) {
                    elementsArr.forEach(el => {
                        const newElement = el;
                        opts.valueAfter.forEach(property => {
                            const key = (Object.keys(property))[0];
                            const value = (Object.values(property))[0];
                            newElement.style[key] = value;
                        });
                        return newElement;
                    });
                }

                if (opts.classAfter) {
                    elementsArr.forEach(el => {
                        const newElement = el;
                        Array(opts.classAfter).forEach(endClass => {
                            if (newElement.classList.contains(String(endClass))) {
                                return;
                            } else {
                                newElement.classList.add(String(endClass));
                            }
                        });
                        return newElement;
                    });
                }

                if (!opts.timeline) {
                    anim.customComplete();
                };
            },
            customComplete: anim => {
                // hook for promise
            },
        };

        propertyKeys.forEach(property => {
            // each is an object with an array of values for the property

            const propNames = Object.keys(property); // Identify property key

            // Assign constructed attribute keyframes to animationObject
            animationObject[propNames[0]] = property[propNames[0]];
        });

        if (opts.timeline) {
            return animationObject;
        } else {
            return anime(animationObject);
        }
    }

    // returns anime instance
    // autoplay will run the animation at time of definition
    defineAnimation(animation) {
        let animeObject;

        if (animation.type === 'animate') {
            animeObject = this[animation.type](animation.element, animation.opts.keyframes, animation.opts);
        } else {
            animeObject = this[animation.type](animation.element, animation.opts);
        }

        if (animation.playOnDefine) {
            const promise = this.runAnimation(animeObject);
            return promise;
        } else {
            return animeObject;
        }
    }

    // returns promise
    runAnimation(animation) {
        this._createCustomPromise(animation);
        if (animation.began) {
            animation.restart();
        } else {
            animation.play();
        }
        return animation.animationComplete;
    }

    /* //////////////////////////////////////////////////////////////
    // PRE-DEFINED ANIMATIONS
    //////////////////////////////////////////////////////////////// */

    /**
     * Generic from-to animation where keyframe defs are set manually
     *
     * @param {Array} elements to animate
     * @param {Array} property/value pairs to tween
     * @param {Object} opts custom animation params
     * @return {Promise}
     */
    animate(elements, propertyKeys, opts) {
        if (!t.Arr.is(propertyKeys)) { console.warn('Type error: propertyKeys. Expected array of keyframe objects.'); }
        if (!t.Obj.is(opts)) { console.warn('Type error: opts. Expected object of options.'); }

        return this._animate(
            elements,
            propertyKeys,
            opts
        );
    }

    /**
     * Fades in an element or set of elements
     *
     * @param {Array} elements to animate
     * @param {Object} opts custom animation params
     * @return {Promise}
     */
    fadeIn(elements, opts = {}) {
        const animeOpts = opts;
        animeOpts.valueBefore = opts.valueBefore || [
            { display: 'block' },
            { opacity: 0 },
        ];

        return this._animate(
            elements,
            [
                {
                    opacity: [
                        { value: 0, duration: 0 },
                        { value: 1, duration: animeOpts.duration || 300 },
                    ],
                },
            ],
            animeOpts
        );
    }

    /**
     * Fades out an element or set of elements
     *
     * @param {Array} elements to animate
     * @param {Object} opts custom animation params
     * @return {Promise}
     */
    fadeOut(elements, opts) {
        const animeOpts = opts;
        animeOpts.valueAfter = opts.valueAfter || [
            { display: 'none' },
        ];

        return this._animate(
            elements,
            [
                {
                    opacity: [
                        { value: 1, duration: 0 },
                        { value: 0, duration: animeOpts.duration || 200 },
                    ],
                },
            ],
            animeOpts
        );
    }

    /**
     * Translates an element or set of elements to a value
     *
     * @param {Array} elements to animate
     * @param {Object} opts custom animation params
     * @return {Promise}
     */
    slideTo(elements, opts) {
        const animeOpts = opts;
        animeOpts.valueBefore = opts.valueBefore || [
            { display: 'block' },
        ];

        animeOpts.transform = opts.transform || 'translateX';
        animeOpts.distance = opts.distance || '-100%';

        return this._animate(
            elements,
            [
                {
                    [opts.transform]: [
                        { value: 0, duration: 0 },
                        { value: [animeOpts.distance], duration: animeOpts.duration || 300 },
                    ],
                },
            ],
            animeOpts
        );
    }

     /**
     * Translates an element or set of elements from a value to a translation of 0
     *
     * @param {Array} elements to animate
     * @param {Object} opts custom animation params
     * @return {Promise}
     */
    slideFrom(elements, opts) {
        const animeOpts = opts;
        animeOpts.valueAfter = opts.valueAfter || [
            { display: 'none' },
        ];
        animeOpts.transform = opts.transform || 'translateX';
        animeOpts.distance = opts.distance || '-100%';

        return this._animate(
            elements,
            [
                {
                    [animeOpts.transform] : [
                        { value: [animeOpts.distance], duration: 0 },
                        { value: 0, duration: animeOpts.duration || 200 },
                    ],
                },
            ],
            animeOpts
        );
    }


    /* //////////////////////////////////////////////////////////////
    // TIMELINES
    //////////////////////////////////////////////////////////////// */

    /**
     * Creates a timeline
     *
     * @param {Array} animations
     * @return {Promise} animation timeline finished
     */
    defineTimeline(animations, timelineOpts) {
        if (!t.Arr.is(animations)) { console.warn('Type error: animations. Expected array of animation objects.'); }

        const timelineObj = {
            autoplay: false,
            complete: anim => {
                anim.customComplete();
            },
        };

        if (timelineOpts) {
            for (const option in timelineOpts) {
                if (Object.prototype.hasOwnProperty.call(timelineOpts, option)) {
                    timelineObj[option] = timelineOpts[option];
                };
            }
        };

        const timeline = anime.timeline(timelineObj);
        timeline.customComplete = anim => {
            // hook for promise
        };

        this._addToTimeline(timeline, animations);

        if (timelineOpts && timelineOpts.playOnDefine) {
            const promise = this.runAnimation(timeline);
            return promise;
        } else {
            return timeline;
        }
    }

    /**
     * Create a new 'animationComplete' promise on the animation object
     *
     * @param {Object} animation
     */
    _createCustomPromise(animation) {
        const customPromise = animation;
        customPromise.animationComplete = new Promise(resolve => {
            customPromise.customComplete = resolve;
        });
    }

    /**
     * Add constructed anime animations to timeline
     *
     * @param {String} name camelCase name
     * @param {Array} animations
     * @return {Promise} animation timeline finished
     */

    _addToTimeline(timeline, animations) {
        animations.forEach(animation => {
            let animeOpts = animation.opts;
            if (animeOpts) {
                animeOpts.timeline = true;
            } else {
                animeOpts = {
                    timeline: true,
                };
            }
            let animeObject;

            if (animation.type === 'animate') {
                animeObject = this[animation.type](animation.element, animeOpts.keyframes, animeOpts);
            } else {
                animeObject = this[animation.type](animation.element, animeOpts);
            }

            timeline.add(animeObject);
        });
    }
}
