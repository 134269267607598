import AnimalBreedsPropertiesModel from '../AnimalBreedsPropertiesModel';

export default class DogBreedPropertiesModel extends AnimalBreedsPropertiesModel {
    get defaultData() {
        return {
            size: '',
            energyLevel: '',
            friendliness: '',
            easeOfTraining: '',
            groomingRequirements: '',
            vocality: '',
            exerciseRequirements: '',
            affectionNeeds: '',
        };
    }

    /**
     * Build animal breeds property data for size
     * @method size
     *
     * @returns {Object}
     */
    size() {
        return this._buildProperty(
            'breedSearchSize',
            59,
            this._filterToValue(this._data.size, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for energy level
     * @method energyLevel
     *
     * @returns {Object}
     */
    energyLevel() {
        return this._buildProperty(
            'breedSearchEnergy',
            60,
            this._filterToValue(this._data.energyLevel, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for friendliness
     * @method friendliness
     *
     * @returns {Object}
     */
    friendliness() {
        return this._buildProperty(
            'breedSearchFriendliness',
            61,
            this._filterToValue(this._data.friendliness, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for ease of training
     * @method easeOfTraining
     *
     * @returns {Object}
     */
    easeOfTraining() {
        return this._buildProperty(
            'breedSearchTraining',
            62,
            this._filterToValue(this._data.easeOfTraining, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for grooming requirements
     * @method groomingRequirements
     *
     * @returns {Object}
     */
    groomingRequirements() {
        return this._buildProperty(
            'breedSearchGrooming',
            63,
            this._filterToValue(this._data.groomingRequirements, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for vocality
     * @method vocality
     *
     * @returns {Object}
     */
    vocality() {
        return this._buildProperty(
            'breedSearchVocality',
            64,
            this._filterToValue(this._data.vocality, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for exercise requirements
     * @method exerciseRequirements
     *
     * @returns {Object}
     */
    exerciseRequirements() {
        return this._buildProperty(
            'breedSearchExercise',
            65,
            this._filterToValue(this._data.exerciseRequirements, this.nullText)
        );
    }

    /**
     * Build animal breeds property data for affection needs
     * @method affectionNeeds
     *
     * @returns {Object}
     */
    affectionNeeds() {
        return this._buildProperty(
            'breedSearchAffection',
            66,
            this._filterToValue(this._data.affectionNeeds, this.nullText)
        );
    }
}
