// TODO: move this somewhere more appropriate (placed here temporarily)

import EventMixinBase from '../../../../core/scripts/lib/wirey/mixins/EventMixinBase';
import { CLASS_CHILD_IS_FOCUSED } from '../../../../core/scripts/constants/classes';
import queryFocusable from 'ally.js/query/focusable';
import Mixins from '../../../../core/scripts/lib/wirey/Mixins';
import Utils from '../../../../core/scripts/lib/wirey/Utils';

/**
 * Focusing a child toggles a CSS class on this element.
 *
 *   pf-mix-add-class-on-child-focus="context: selector, class: css-class"
 *
 * attr value is an optional element selector to search on instead of `this`.
 *
 * @class PFMixAddClassOnChildFocus
 * @extends EventMixinBase
 */
export default class PFMixAddClassOnChildFocus extends EventMixinBase {
    static _attributeName = 'mix-add-class-on-child-focus';

    constructor(ele, value) {
        super(ele, value);

        this.valueParams = value
            ? Utils.StringUtils.parseAttributeValue(value)
            : {};

        // class
        this.toggleClass = this.valueParams.class || CLASS_CHILD_IS_FOCUSED;

        // context
        const contextEl = this.valueParams.context
            ? ele.querySelector(this.valueParams.context)
            : ele;

        this.focusableChildren = queryFocusable({
            context: contextEl,
            strategy: 'all',
        });
    }

    /**
     * On all focus/blur events, add class if focused element is a child,
     * otherwise remove it.
     *
     * @method onFocusChanged
     */
    onFocusChanged(target) {
        if (!this.focusableChildren.includes(target)) {
            return;
        }

        if (this._ele.contains(document.activeElement)) {
            this._ele.classList.add(this.toggleClass);
        } else {
            this._ele.classList.remove(this.toggleClass);
        }
    }

    onFocusinEvent(ev) {
        this.onFocusChanged(ev.target);
    }

    onFocusoutEvent(ev) {
        this.onFocusChanged(ev.target);
    }
}

Mixins.add(PFMixAddClassOnChildFocus);
