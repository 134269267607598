export const helpers = {
    capitalizeFirst: string => {
        return `${string.charAt(0).toUpperCase()}${string.substr(1)}`;
    },
    if: (condition, thenTemplate, elseTemplate = '') => {
        return condition ? thenTemplate : elseTemplate;
    },
    unless: (condition, thenTemplate, elseTemplate) => {
        return !condition ? thenTemplate : elseTemplate;
    },
    /*
        Use To avoid throwing errors trying to access undefined properties of an object in a
        template literal.
        @method getNestedProperty
        @param {Object} obj vm
        @param {String} key
    */
    getNestedProperty(obj, key) {
        return key.split('.').reduce((curr, prev) => {
            return curr ? curr[prev] : curr;
        }, obj);
    },
    /*
        Prepends a space to a string
        @method prependSpace
        @method {String} str
    */
    prependSpace(str) {
        return ` ${str}`;
    },
    /*
        Converts integer to delimited strings
        e.g. 123456789 => 123,456,789
        @method delimitedNumber
        @param {Number} number to be delimited
        @param {String} symbol to delimit number with
    */
    delimitedNumber(num, separator = ',') {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    },
    /*
        Iterates over an object of tokens and replaces them in a translation unit by the
        token value. Tokens are delimited by the % symbol by convention.
        @method replaceTransUnitTokens
        @param {String} transUnit
        @param {Object} tokens
    */
    replaceTransUnitTokens(transUnit, tokens) {
        let replacedTransUnit;
        Object.keys(tokens)
            .forEach(key => replacedTransUnit = transUnit.replace(`%${key}%`, tokens[key]));
        return replacedTransUnit;
    },
    registerHelper: (name, fn) => {
        helpers[name] = fn;
    },
};

helpers.registerHelper('capitalize', string => {
    return string[0].toUpperCase() + string.slice(1).toUpperCase();
});
