/**
 * # Abstract Repository
 * Must be extended.
 *
 * Public Methods:
 * - get
 * - post
 *
 */

import t from 'tcomb';
import assert from '../../lib/assert';

import { API_WEB_FRONT, API_CORE_API } from '../../constants/api';
import {
    ERROR_API,
    ERROR_NO_LOCAL_CSRF_TOKEN,
    ERROR_NO_GLOBAL_CSRF_TOKEN,
} from '../../constants/errors';
// import { SELECTOR_GLOBAL_CSRF_TOKEN } from '../../constants/selectors';

/**
 * @class Repository
 */
export default class Repository {
    /**
     * Generates a FormData object given an object
     *
     * @param {object} obj Key/value pairs to add to the formData
     * @returns {FormData}
     */
    static formDataFromObject(obj) {
        const formData = new FormData();
        // eslint-disable-next-line
        for (const key in obj) {
            formData.append(key, obj[key]);
        }

        return formData;
    }

    /**
     * CSRF Token that must be sent along in query for pushes to Core API
     * @type {String}
     * @static
     */
    get globalCsrfToken() {
        // this._csrfToken = this._csrfToken ||
        //     document.querySelector(SELECTOR_GLOBAL_CSRF_TOKEN).innerText;
        // return this._csrfToken;

        return window.PF.pageConfig.api_passthrough_token;
    }

    /**
     * @method buildUrlAndQuery
     * @private
     * @param {String} url pathname
     * @param {Object} query params
     * @param {Array} include links
     * @return {String} fullUrl with query string
     */
    buildUrlWithQuery(url, query, include) {
        if (!query && !include && !this.globalCsrfToken) { return url; }

        const fullUrl = new URL(url, window.location.origin);
        const params = new URLSearchParams(fullUrl.search);

        if (query) {
            Object.keys(query).forEach(key => params.append(key, query[key]));
        }

        if (include) {
            params.append('include', include.join(','));
        }

        if (this.globalCsrfToken) {
            params.append('token', this.globalCsrfToken);
        }

        return `${fullUrl}?${params.toString()}`;
    }

    /**
     * Takes query object and include array and builds URL
     *
     * @method readResponse
     * @private
     * @param {Object} response
     * @async
     * @return {Promise.<JSON>} response data
     */
    async readResponse(response) {
        return response.json();
    }

    /**
     * @method buildRequestBody
     * @private
     * @param {Object} params
     * @return {Object} searchParams
     */
    buildRequestBody(params) {
        const searchParams = new URLSearchParams();
        Object.keys(params).forEach(key => searchParams.set(key, params[key]));
        return searchParams;
    }

    /**
     * @method wasRequestSuccessful
     * @private
     * @param {Object} response
     * @return {Boolean}
     */
    wasRequestSuccessful(response) {
        if (response.status >= 200 && response.status < 300) {
            return true;
        }
        return false;
    }

    /**
     * @method get
     * @param {String} url pathname
     * @param {Object} query params
     * @param {Array} include links
     * @return {Promise.<JSON>}
     */
    async get(url, query = {}, include = []) {
        const response = await fetch(
            this.buildUrlWithQuery(url, query, include),
            {
                method: 'GET',
                credentials: 'same-origin',
            }
        );

        if (response.status === 400) {
            return Promise.reject(await this.readResponse(response));
        }

        if (this.wasRequestSuccessful(response)) {
            if (response.status === 204) {
                return Promise.resolve();
            }
            return await this.readResponse(response);
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }

    /**
     * @method post
     * @param {String} url pathname
     * @param {Object} params params
     * @param {String} method post or patch
     * @param {String} api core-api or web-front
     * @return {Promise}
     */
    async post(url, params, { csrfToken, method = 'POST', api = API_CORE_API } = {}) {
        let urlWithQuery = url;
        // eslint-disable-next-line
        params = params || {};
        let body = params;

        if (api === API_CORE_API) {
            assert(t.Str.is(this.globalCsrfToken), ERROR_NO_GLOBAL_CSRF_TOKEN);
            const coreParams = Object.assign({}, params, {
                token: this.globalCsrfToken,
            });
            urlWithQuery = this.buildUrlWithQuery(url, coreParams);
        }

        if (api === API_WEB_FRONT) {
            assert(t.Object.is(csrfToken), ERROR_NO_LOCAL_CSRF_TOKEN);
            body = Object.assign({}, params, csrfToken);
        }

        const response = await fetch(
            urlWithQuery,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }),
                method,
                credentials: 'same-origin',
                body: JSON.stringify(params),
            }
        );

        if (response.status === 400) {
            return Promise.reject(await this.readResponse(response));
        }

        if (this.wasRequestSuccessful(response)) {
            if (response.headers.get('content-type') === 'application/json') {
                return await this.readResponse(response);
            }
            return Promise.resolve();
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }

}
