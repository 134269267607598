import _get from 'lodash/get';
import _pull from 'lodash/pull';
import animalSearchStateController from '../../../../../dotcom/scripts/state-controllers/animal-search/animalSearchStateController';
import { analyticsFilterFavoritesGlobalNav } from '../../../../../dotcom/scripts/analytics/favorites';
import { trackEvent } from '../../../analytics';
import AnimalSearchEventModel from '../../../analytics/models/animal-search/AnimalSearchEventModel';
import UserInfoEventModel from '../../../analytics/models/user-info/UserInfoEventModel';
import UserLoginRegistrationEventModel from '../../../analytics/models/user-login-registration/UserLoginRegistrationEventModel';

const SEARCH_CATEGORY = 'search criteria';
const PET_SEARCH_CATEGORY = 'pet search';

function buildAnimalStatusString(status) {
    let statusString = '';
    if (status === 'found') {
        statusString = ' - found';
    }
    if (status === 'adopted') {
        statusString = ' - adopted';
    }
    return statusString;
}

export default class DotComTrackEvent {
    eventConsumer002() {
        trackEvent({
            category: 'global-nav',
            action: 'find-a-pet',
        });
    }

    eventConsumer003() {
        trackEvent({
            category: 'global-nav',
            action: 'resources',
            label: 'open',
        });
    }

    eventConsumer004() {
        trackEvent({
            category: 'global-nav',
            action: 'resources',
            label: 'close',
        });
    }

    eventConsumer012() {
        trackEvent({
            category: 'global-nav',
            action: 'petfinder logo',
        });
    }

    eventConsumer014() {
        trackEvent({
            category: 'sign in',
            action: 'go to login or registration modal widow',
        });
    }

    eventConsumer015() {
        trackEvent({
            category: 'sign in',
            action: 'profile button',
        });
    }

    eventConsumer016() {
        trackEvent({
            category: 'sign in',
            action: 'access account',
        });
    }

    /**
     * Petfinder Consumer 018
     * Page: Login Modal
     * Action: Login in to Site
     *
     * @public
     * @event PetfinderConsumer018
     * @param {Object} event
     */
    eventConsumer018(event) {
        const analytics = UserLoginRegistrationEventModel.prepareEvent({
            authenticationStatus: event.authenticated ? 'registered' : 'not registered',
        });
        trackEvent({
            category: 'sign in',
            action: 'login to site',
            event: 'Petfinder Consumer 018',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
        });
    }

    eventConsumer020() {
        trackEvent({
            category: 'sign in',
            action: 'forgot password',
        });
    }

    eventConsumer021() {
        trackEvent({
            category: 'sign in',
            action: 'submit password recovery form',
        });
    }

    eventConsumer022() {
        trackEvent({
            category: 'registration',
            action: 'start registration',
        });
    }

    /**
     * Petfinder Consumer 023
     * Page: Login Modal
     * Action: Login in to Site
     *
     * @public
     * @event PetfinderConsumer023
     * @param {Object} event
     */
    eventConsumer023(event) {
        const analytics = UserLoginRegistrationEventModel.prepareEvent({
            authenticationStatus: event.authenticated ? 'registered' : 'not registered',
        });
        trackEvent({
            category: 'registration',
            action: 'submit registration',
            event: 'Petfinder Consumer 023',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
        });
    }

    eventConsumer024() {
        trackEvent({
            category: 'footer',
            action: 'email sign up',
        });
    }

    eventConsumer032(detail) {
        const trigger = detail.trigger;
        const target = detail.srcEvent.target;
        const petId = trigger.getAttribute('pet-id');
        trackEvent({
            category: 'recommended pet',
            action: 'clicks to share',
            label: `/petdetail/${petId}`,
            additional: {
                petProfilePetID: petId,
            },
        });
    }

    eventConsumer034(detail) {
        const trigger = detail.trigger;
        const target = detail.srcEvent.target;
        const petId = trigger.getAttribute('pet-id');
        trackEvent({
            category: 'email share',
            action: 'recommended pet',
            label: `/petdetail/${petId}`,
            additional: {
                petProfilePetID: petId,
            },
        });
    }

    eventConsumer035(detail) {
        const trigger = detail.trigger;
        const target = detail.srcEvent.target;
        const petId = trigger.getAttribute('pet-id');
        trackEvent({
            category: 'link share',
            action: 'recommended pet',
            label: `/petdetail/${petId}`,
            additional: {
                petProfilePetID: petId,
            },
        });
    }

    eventConsumer036(detail) {
        trackEvent({
            category: 'recommended pet',
            action: 'clicks to view more',
        });

        const trigger = detail.trigger;
        const component = trigger.getAttribute('component');
        const memberProfileRecoShelfComponentIds = ['member_one', 'member_two'];
        if (memberProfileRecoShelfComponentIds.includes(component)) {
            const organizationId = trigger.getAttribute('org-id');
            const organizationName = trigger.getAttribute('org-name');

            /* eslint-disable camelcase, no-undefined */
            window.dataLayer.push(
                {
                    event: 'search',
                    ga4: true,
                    event_params: {
                        search_category: 'pet',
                        method: 'member profile pages',
                        pet_species: 'all',
                        shelter_id: organizationId ? organizationId.toLowerCase() : undefined,
                        shelter_name: organizationName ? organizationName.toLowerCase() : undefined,
                    },
                },
                { event_params: undefined }
            );
        }
    }

    eventConsumer037(event) {
        const { locationSlug, initiatingSiteFeature } = event;
        const analytics = AnimalSearchEventModel.prepareEvent({
            location: locationSlug,
            initiatingSite: initiatingSiteFeature || 'main search',
        });
        trackEvent({
            category: SEARCH_CATEGORY,
            action: 'search: location',
            label: locationSlug,
            event: 'Petfinder Consumer 037 - Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
        });
    }

    /**
     * Petfinder Consumer 038
     * Page: Any Search Experience
     * Action: N/A
     *
     * @public
     * @event PetfinderConsumer038
     * @param {Object} event
     */
    eventConsumer038(event) {
        // TODO: add filter function to incorporate Petfinder Consumer 038, 039, and 040 properly
        const { petTypeSlug, initiatingSiteFeature } = event;
        const petTypes = ['dogs', 'cats'];
        const label = petTypes.includes(petTypeSlug) ? `${petTypeSlug}` : `other: ${petTypeSlug}`;
        const analytics = AnimalSearchEventModel.prepareEvent({
            animalType: petTypeSlug,
            initiatingSite: initiatingSiteFeature || 'main search',
        });
        trackEvent({
            category: SEARCH_CATEGORY,
            action: 'search: animal',
            label,
            event: 'Petfinder Consumer 038 - Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
        });
    }

    /**
     * Petfinder Consumer 041
     * Page: Any Search Experience
     * Action: N/A
     *
     * @public
     * @event PetfinderConsumer041
     * @param {Object} event
     */
    eventConsumer041(event) {
        // TODO: Add filter event to incorporate Petfinder Consumer 041, 042, and 043 properly
        const { locationSlug, petTypeSlug } = event;
        const petTypes = ['dogs', 'cats'];
        const analytics = AnimalSearchEventModel.prepareEvent({
            animalType: petTypeSlug,
            location: locationSlug,
            distance: '100',
            filterOutAuxillaryProperties: false,
            filterOutNullText: false,
            nullText: 'no filter applied',
        });
        const label = petTypes.includes(petTypeSlug) ? `find ${petTypeSlug}` : `other: ${petTypeSlug}`;

        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'submit search criteria',
            label,
            event: 'Petfinder Consumer 041 - Pet Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    eventConsumer044(detail) {
        const articleUrl = detail.srcEvent.target.getAttribute('href');
        trackEvent({
            category: 'featured content',
            action: 'read more',
            label: articleUrl,
        });
    }

    eventConsumer026(detail) {
        const target = detail.srcEvent.target;
        const title = target.getAttribute('article-title');
        const href = target.getAttribute('href');
        trackEvent({
            category: 'articles',
            action: title,
            label: href,
        });
    }

    eventConsumer027(detail) {
        const target = detail.srcEvent.target;
        const position = target.getAttribute('article-position');
        const href = target.getAttribute('href');
        trackEvent({
            category: 'articles',
            action: position,
            label: href,
        });
    }

    eventConsumer026_027(detail) {
        const trigger = detail.trigger;
        const title = trigger.getAttribute('data-title');
        const position = trigger.getAttribute('data-position');
        const href = trigger.getAttribute('data-href');
        trackEvent({
            category: 'articles',
            action: title,
            label: href,
        });
        trackEvent({
            category: 'articles',
            action: position,
            label: href,
        });
    }

    eventConsumer029(detail) {
        const trigger = detail.trigger;
        const petId = trigger.getAttribute('pet-id');
        const heading = trigger.getAttribute('recommendation-heading').toLowerCase();
        trackEvent({
            category: 'recommended pet',
            action: heading,
            label: `/petdetail/${petId}`,
        });
    }

    eventConsumer030(detail) {
        const trigger = detail.trigger;
        const petId = trigger.getAttribute('pet-id');
        const position = trigger.getAttribute('recommendation-position').toLowerCase();
        trackEvent({
            category: 'recommended pet',
            action: position,
            label: `/petdetail/${petId}`,
        });
    }

    eventConsumer089() {
        trackEvent({
            category: 'adoption reward offer',
            action: 'open offer',
        });
    }

    eventConsumer090() {
        trackEvent({
            category: 'adoption reward offer',
            action: 'close offer',
        });
    }

    eventConsumer092() {
        trackEvent({
            category: 'my petfinder',
            action: 'click about me tab',
        });
    }

    eventConsumer093() {
        trackEvent({
            category: 'my petfinder',
            action: 'click account settings tab',
        });
    }

    eventConsumer094() {
        trackEvent({
            category: 'my petfinder',
            action: 'click email preferences tab',
        });
    }

    eventConsumer095() {
        trackEvent({
            category: 'my petfinder',
            action: 'click saved search management tab',
        });
    }

    eventConsumer092to95Select(detail) {
        const { srcEvent } = detail;
        const val = srcEvent.target.value;
        const optionLabel = srcEvent.target.querySelector(`option[value="${val}"]`).innerText.toLowerCase();
        trackEvent({
            category: 'my petfinder',
            action: `click ${optionLabel} tab`,
        });
    }

    eventConsumer103(detail) {
        trackEvent({
            category: 'account settings',
            action: 'change email address',
            label: 'successful',
        });
    }

    eventConsumer104(detail) {
        trackEvent({
            category: 'account settings',
            action: 'change email address',
            label: 'error',
        });
    }

    eventConsumer105(detail) {
        try {
            const fireEvent = detail.trigger.children[0].innerText.includes('Your password has been updated');

            if (fireEvent) {
                trackEvent({
                    category: 'account settings',
                    action: 'change password',
                    label: 'successful',
                });
            }
        } catch (e) {
            return;
        }
    }

    eventConsumer106(detail) {
        trackEvent({
            category: 'account settings',
            action: 'change password',
            label: 'unsuccessful',
        });
    }

    eventConsumer109(detail) {
        const filterName = detail.filterName;
        trackEvent({
            category: 'saved search management',
            action: 'filter',
            label: `${filterName}`,
        });
    }

    eventConsumer110(detail) {
        const title = detail.savedSearchTitle;
        trackEvent({
            category: 'saved search management',
            action: 'edit name',
            label: title,
        });
    }

    eventConsumer111(detail) {
        trackEvent({
            category: 'saved search management',
            action: 'receive alerts',
            label: `${detail.checked}: ${detail.savedSearchTitle}`,
        });
    }

    eventConsumer112(detail) {
        trackEvent({
            category: 'saved search management',
            action: 'delete saved search',
            label: detail.savedSearchTitle,
        });
    }

    eventConsumer113(detail) {
        const { searchName } = detail;
        trackEvent({
            category: 'saved search management',
            action: 'click go to search',
            label: `${searchName}`,
        });
    }

    /**
     * Petfinder Consumer 142
     * Page: Pet Detail Page
     * Action: User lands on "Oops" aka Pet Not Found
     *
     * @public
     * @event PetfinderConsumer142
     */
    eventConsumer142() {
        trackEvent({
            category: 'pet detail',
            action: 'pet not found',
            label: window.location.pathname,
            event: 'Petfinder Consumer 142 - Pet Detail Page',
            dimensions: {},
            additional: {},
        });
    }

    eventConsumer143_161_178(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        const animalStatus = trigger.getAttribute('animal-status');
        const statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
        trackEvent({
            category: statusString,
            action: 'back to results',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer144_162_179(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        const animalStatus = trigger.getAttribute('animal-status');
        const statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
        trackEvent({
            category: statusString,
            action: 'next pet',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer145_163_180_253(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        const animalStatus = trigger.getAttribute('animal-status');
        let statusString = '';
        if (detail.member) {
            statusString = 'member profile';
            trackEvent({
                category: statusString,
                action: 'enlarge photo',
                label: trigger.getAttribute('shelter-id'),
            });
        } else {
            statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
            trackEvent({
                category: statusString,
                action: 'enlarge photo',
                label: `/petdetail/${animalId}`,
            });
        }
    }

    eventConsumer146_164_181(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        const animalStatus = trigger.getAttribute('animal-status');
        const statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
        trackEvent({
            category: statusString,
            action: 'play video',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer147_165_182_251(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const animalStatus = trigger.getAttribute('animal-status');
        let statusString = '';
        if (detail.member) {
            statusString = 'member profile';
            trackEvent({
                category: statusString,
                action: 'scroll right',
                label: trigger.getAttribute('shelter-id'),
            });
        } else {
            statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
            trackEvent({
                category: statusString,
                action: 'scroll right',
                label: `/petdetail/${detail.animalId}`,
            });
        }
    }

    eventConsumer148_166_183_252(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const animalStatus = trigger.getAttribute('animal-status');
        let statusString = '';
        if (detail.member) {
            statusString = 'member profile';
            trackEvent({
                category: statusString,
                action: 'scroll left',
                label: trigger.getAttribute('shelter-id'),
            });
        } else {
            statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
            trackEvent({
                category: statusString,
                action: 'scroll left',
                label: `/petdetail/${detail.animalId}`,
            });
        }
    }

    eventConsumer149(detail) {
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        const hasAnimalTypeMismatch = trigger.getAttribute('has-animal-type-mismatch') === 'true';
        const isGuest = trigger.getAttribute('is-guest') === 'true';
        trackEvent({
            category: 'adopt me',
            action: 'open',
            label: `/petdetail/${animalId}`,
        });

        if (hasAnimalTypeMismatch) {
            /* eslint-disable camelcase, no-undefined */
            window.dataLayer.push(
                {
                    ga4: true,
                    event: 'page_view',
                    event_params: {
                        page_path: '/petdetail/aif/update-profile',
                    },
                },
                { event_params: undefined }
            );
        }

        window.dataLayer.push(
            {
                ga4: true,
                event: 'adoption_form_initiate',
                event_params: {
                    login_status: isGuest ? 'guest' : 'logged in',
                },
            },
            { event_params: undefined }
        );
    }

    eventConsumer150(detail) {
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        trackEvent({
            category: 'adopt me',
            action: 'open - footer',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer151(detail) {
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        trackEvent({
            category: 'sponsor me',
            action: 'open',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer152(detail) {
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        trackEvent({
            category: 'sponsor me',
            action: 'click to foundation',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer153(detail) {
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        trackEvent({
            category: 'pet detail',
            action: 'print',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer155_156_170_171_187_188(detail) {
        // eslint-disable-line
        const target = detail.srcEvent.target;
        if (!target.hasAttribute('pf-ensighten-id')) {
            return;
        }
        const id = target.getAttribute('pf-ensighten-id');
        const animalId = detail.trigger.getAttribute('animal-id');
        const animalStatus = detail.trigger.getAttribute('animal-status');
        if (id === 'email-share') {
            trackEvent({
                category: `email share${buildAnimalStatusString(animalStatus)}`,
                action: 'pet detail',
                label: `/petdetail/${animalId}`,
            });
        }
        if (id === 'link-share') {
            trackEvent({
                category: `link share${buildAnimalStatusString(animalStatus)}`,
                action: 'pet detail',
                label: `/petdetail/${animalId}`,
            });
        }
    }

    eventConsumer157_168_185(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const shelterId = trigger.getAttribute('shelter-id');
        const animalStatus = trigger.getAttribute('animal-status');
        const statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
        trackEvent({
            category: statusString,
            action: 'get directions',
            label: shelterId,
        });
    }

    eventConsumer158_174_228(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const shelterId = trigger.getAttribute('shelter-id');
        const animalStatus = trigger.getAttribute('animal-status');
        let statusString = '';
        if (animalStatus === 'found') {
            statusString = ' from pet detail found';
        }
        if (animalStatus === 'adopted') {
            statusString = ' from pet detail adopted';
        }
        trackEvent({
            category: 'contact organization',
            action: `click to email${statusString}`,
            label: shelterId,
        });
    }

    eventConsumer159_175_229(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const shelterId = trigger.getAttribute('shelter-id');
        const animalStatus = trigger.getAttribute('animal-status');
        let statusString = '';
        if (animalStatus === 'found') {
            statusString = ' from pet detail found';
        }
        if (animalStatus === 'adopted') {
            statusString = ' from pet detail adopted';
        }
        trackEvent({
            category: 'contact organization',
            action: `click to call${statusString}`,
            label: shelterId,
        });
    }

    eventConsumer160_176_230(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const shelterId = trigger.getAttribute('shelter-id');
        const animalStatus = trigger.getAttribute('animal-status');
        const statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
        trackEvent({
            category: statusString,
            action: 'clicks to member profile',
            label: `${shelterId}`,
        });
    }

    eventConsumer167_184(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        const animalStatus = trigger.getAttribute('animal-status');
        const statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
        trackEvent({
            category: statusString,
            action: 'clicks to did you adopt',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer172_173_189_190(detail) {
        // eslint-disable-line
        const target = detail.srcEvent.target;
        const animalId = detail.trigger.getAttribute('animal-id');
        const animalStatus = detail.trigger.getAttribute('animal-status');
        const statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
        trackEvent({
            category: statusString,
            action: 'clicks to share',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer177(detail) {
        const trigger = detail.trigger;
        const animalId = trigger.getAttribute('animal-id');
        trackEvent({
            category: 'pet detail - adopted',
            action: 'clicks to did you adopt - footer',
            label: `/petdetail/${animalId}`,
        });
    }

    eventConsumer232(detail) {
        const target = detail.srcEvent.target;
        if (target.value !== '') {
            trackEvent({
                category: 'adopt me',
                action: 'first name',
            });
        }
    }

    eventConsumer233(detail) {
        const target = detail.srcEvent.target;
        if (target.value !== '') {
            trackEvent({
                category: 'adopt me',
                action: 'last name',
            });
        }
    }

    eventConsumer234(detail) {
        const target = detail.srcEvent.target;
        if (target.value !== '') {
            trackEvent({
                category: 'adopt me',
                action: 'phone number',
            });
        }
    }

    eventConsumer235(detail) {
        const target = detail.srcEvent.target;
        if (target.value !== '') {
            trackEvent({
                category: 'adopt me',
                action: 'email',
            });
        }
    }

    eventConsumer236(detail) {
        const target = detail.srcEvent.currentTarget; // Custom select
        if (target.value !== '') {
            trackEvent({
                category: 'adopt me',
                action: 'country',
            });
        }
    }

    eventConsumer237(detail) {
        const target = detail.srcEvent.target;
        if (target.value !== '') {
            trackEvent({
                category: 'adopt me',
                action: 'zip code',
            });
        }
    }

    eventConsumer238(detail) {
        const target = detail.srcEvent.target;
        if (target.value !== '') {
            trackEvent({
                category: 'adopt me',
                action: 'password',
            });
        }
    }

    eventConsumer241() {
        trackEvent({
            category: 'adopt me',
            action: 'submission error',
            label: 'guest',
        });
    }

    eventConsumer242() {
        trackEvent({
            category: 'adopt me',
            action: 'submission error',
            label: 'logged-in user',
        });
    }

    /**
     * Consumer Analytics Event #327
     * Logged in user ERROR submitting form - details on error
     *
     * @param {Object} detail
     */
    eventConsumer327(detail) {
        const errorData = detail.errorData;
        const errorGlobal = errorData.global[0]; // there should only ever be one global error message
        const errorFields = errorData.fields;

        const errorDescription = Object.keys(errorFields).join(',');

        trackEvent({
            category: 'adopt me',
            action: 'submission error details',
            label: errorDescription,
        });
    }

    eventConsumer243(detail) {
        const trigger = detail.trigger;
        const petId = trigger.getAttribute('pet-id');

        trackEvent({
            category: 'adopt me - confirmation',
            action: 'click to open offer',
            label: `/petdetail/${petId}`,
        });
    }

    eventConsumer244() {
        trackEvent({
            category: 'adopt me - confirmation',
            action: 'create account',
            label: 'successful',
        });
    }

    eventConsumer245() {
        trackEvent({
            category: 'adopt me - confirmation',
            action: 'create account',
            label: 'error',
        });
    }

    eventConsumer246(detail) {
        const trigger = detail.trigger;
        const organizationId = trigger.getAttribute('organization-id');
        const organizationName = trigger.getAttribute('organization-name');
        trackEvent({
            category: 'member profile',
            action: 'clicks on view our pets',
            label: organizationId,
        });

        /* eslint-disable camelcase, no-undefined */
        window.dataLayer.push(
            {
                event: 'search',
                ga4: true,
                event_params: {
                    search_category: 'pet',
                    method: 'member profile pages',
                    pet_species: 'all',
                    shelter_id: organizationId ? organizationId.toLowerCase() : undefined,
                    shelter_name: organizationName ? organizationName.toLowerCase() : undefined,
                },
            },
            { event_params: undefined }
        );
    }

    eventConsumer247(detail) {
        const trigger = detail.trigger;
        const organizationId = trigger.getAttribute('organization-id');
        const organizationName = trigger.getAttribute('organization-name');
        trackEvent({
            category: 'contact organization',
            action: 'click to email from member profile',
            label: organizationId,
        });

        window.dataLayer.push(
            {
                event: 'shelter_contact',
                ga4: true,
                event_params: {
                    method: 'click to email',
                    shelter_name: organizationName ? organizationName.toLowerCase() : undefined,
                    shelter_id: organizationId ? organizationId.toLowerCase() : undefined,
                    page_type: 'member profile pages',
                },
            },
            { event_params: undefined }
        );
    }

    eventConsumer248(detail) {
        const trigger = detail.trigger;
        const organizationId = trigger.getAttribute('organization-id');
        const organizationName = trigger.getAttribute('organization-name');
        trackEvent({
            category: 'contact organization',
            action: 'click to call from member profile',
            label: organizationId,
        });

        window.dataLayer.push(
            {
                event: 'shelter_contact',
                ga4: true,
                event_params: {
                    method: 'click to call',
                    shelter_name: organizationName ? organizationName.toLowerCase() : undefined,
                    shelter_id: organizationId ? organizationId.toLowerCase() : undefined,
                    page_type: 'member profile pages',
                },
            },
            { event_params: undefined }
        );
    }

    eventConsumer250(detail) {
        const trigger = detail.trigger;
        const organizationId = trigger.getAttribute('organization-id');
        trackEvent({
            category: 'member profile',
            action: 'get directions',
            label: organizationId,
        });
    }

    eventConsumer254(detail) {
        const trigger = detail.trigger;
        const organizationId = trigger.getAttribute('organization-id');
        const organizationName = trigger.getAttribute('organization-name');

        trackEvent({
            category: 'member profile',
            action: 'download adoption application',
            label: organizationId,
        });

        window.dataLayer.push(
            {
                event: 'shelter_contact',
                ga4: true,
                event_params: {
                    method: 'adoption application',
                    shelter_name: organizationName ? organizationName.toLowerCase() : undefined,
                    shelter_id: organizationId ? organizationId.toLowerCase() : undefined,
                    page_type: 'member profile pages',
                },
            },
            { event_params: undefined }
        );
    }

    eventConsumer259_261_263(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const animalStatus = trigger.getAttribute('animal-status');
        let statusString = '';
        if (detail.member) {
            // TODO: do we need?
            // statusString = 'member profile';
            // trackEvent(statusString, 'scroll right',trigger.getAttribute('shelter-id'));
        } else {
            statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
            trackEvent({
                category: statusString,
                action: 'scroll right enlarged',
                label: `/petdetail/${detail.animalId}`,
            });
        }
    }

    eventConsumer260_262_264(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;
        const animalStatus = trigger.getAttribute('animal-status');
        let statusString = '';
        if (detail.member) {
            // TODO: do we need?
            // statusString = 'member profile';
            // trackEvent(statusString, 'scroll left',trigger.getAttribute('shelter-id'));
        } else {
            statusString = `pet detail${buildAnimalStatusString(animalStatus)}`;
            trackEvent({
                category: statusString,
                action: 'scroll left enlarged',
                label: `/petdetail/${detail.animalId}`,
            });
        }
    }

    eventConsumer125() {
        trackEvent({
            category: 'adopter registration',
            action: 'guest create account',
        });
    }

    eventConsumer126() {
        trackEvent({
            category: 'adopter registration',
            action: 'crm opt in',
            label: 'Purina',
        });
    }

    eventConsumer127() {
        trackEvent({
            category: 'adopter registration',
            action: 'crm opt in',
            label: 'Petfinder',
        });
    }

    eventConsumer130() {
        trackEvent({
            category: 'adopter registration',
            action: 'account login click',
        });
    }

    eventConsumer045_046(detail) {
        // eslint-disable-line
        const currentTarget = detail.srcEvent.currentTarget;
        if (currentTarget.getAttribute('aria-pressed') === 'false') {
            trackEvent({
                category: 'previous searches',
                action: 'expand',
            });
        } else {
            trackEvent({
                category: 'previous searches',
                action: 'hide',
            });
        }
    }

    eventConsumer047(detail) {
        const trigger = detail.trigger;
        const searchName = trigger.getAttribute('search-name');
        trackEvent({
            category: 'previous searches',
            action: 'view results',
            label: searchName,
        });
    }

    eventConsumer048() {
        trackEvent({
            category: 'previous searches',
            action: 'view all',
        });
    }

    /**
     * Petfinder Consumer 049
     * Page: Search Results Page
     * Action: Select Breed
     *
     * @public
     * @event PetfinderConsumer049
     * @param {Object} detail
     */
    eventConsumer049(detail) {
        if (!this.prevVals.searchFilters.breed) {
            this.prevVals.searchFilters.breed = [];
        }
        if (detail.breed) {
            detail.breed.forEach(breed => {
                if (this.prevVals.searchFilters.breed.includes(breed.toLowerCase())) {
                    return;
                }
                this.prevVals.searchFilters.breed.push(breed.toLowerCase());
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: breed',
                    label: breed.toLowerCase(),
                    event: 'Petfinder Consumer 049 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.dataLayer,
                    metrics: {
                        1: 1,
                    },
                });
            });
        }
    }

    /**
     * Petfinder Consumer 050
     * Page: Search Results Page
     * Action: Select Size
     *
     * @public
     * @event PetfinderConsumer050
     * @param {Object} detail
     */
    eventConsumer050(detail) {
        if (!this.prevVals.searchFilters.size) {
            this.prevVals.searchFilters.size = [];
        }
        if (detail.size) {
            detail.size.forEach(size => {
                if (this.prevVals.searchFilters.size.includes(size.toLowerCase())) {
                    return;
                }
                this.prevVals.searchFilters.size.push(size.toLowerCase());
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: size',
                    label: size.toLowerCase(),
                    event: 'Petfinder Consumer 050 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.dataLayer,
                    metrics: {
                        1: 1,
                    },
                });
            });
        }
    }

    /**
     * Petfinder Consumer 051
     * Page: Search Results Page
     * Action: Select Gender
     *
     * @public
     * @event PetfinderConsumer051
     * @param {Object} detail
     */
    eventConsumer051(detail) {
        if (!this.prevVals.searchFilters.gender) {
            this.prevVals.searchFilters.gender = [];
        }
        if (detail.gender) {
            detail.gender.forEach(gender => {
                if (this.prevVals.searchFilters.gender.includes(gender.toLowerCase())) {
                    return;
                }
                this.prevVals.searchFilters.gender.push(gender.toLowerCase());
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: gender',
                    label: gender.toLowerCase(),
                    event: 'Petfinder Consumer 051 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.dataLayer,
                    metrics: {
                        1: 1,
                    },
                });
            });
        }
    }

    /**
     * Petfinder Consumer 052
     * Page: Search Results Page
     * Action: Select Age
     *
     * @public
     * @event PetfinderConsumer052
     * @param {Object} detail
     */
    eventConsumer052(detail) {
        if (!this.prevVals.searchFilters.age) {
            this.prevVals.searchFilters.age = [];
        }
        if (detail.age) {
            detail.age.forEach(age => {
                if (this.prevVals.searchFilters.age.includes(age.toLowerCase())) {
                    return;
                }
                this.prevVals.searchFilters.age.push(age.toLowerCase());
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: age',
                    label: age.toLowerCase(),
                    event: 'Petfinder Consumer 052 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.datalayer,
                    metrics: {
                        1: 1,
                    },
                });
            });
        }
    }

    /**
     * Petfinder Consumer 053
     * Page: Search Results Page
     * Action: Select Health & Behavior
     *
     * @public
     * @event PetfinderConsumer053
     * @param {Object} detail
     */
    eventConsumer053(detail) {
        if (!this.prevVals.searchFilters.healthbehavior) {
            this.prevVals.searchFilters.healthbehavior = [];
        }
        if (detail.attribute) {
            detail.attribute.forEach(attribute => {
                if (this.prevVals.searchFilters.healthbehavior.includes(attribute.toLowerCase())) {
                    return;
                }
                this.prevVals.searchFilters.healthbehavior.push(attribute.toLowerCase());
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: health behavior',
                    label: attribute.toLowerCase(),
                    event: 'Petfinder Consumer 053 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.dataLayer,
                    metrics: {
                        1: 1,
                    },
                });
            });
        }
    }

    /**
     * Petfinder Consumer 054
     * Page: Search Results Page
     * Action: Select Coat Length
     *
     * @public
     * @event PetfinderConsumer054
     * @param {Object} detail
     */
    eventConsumer054(detail) {
        if (!this.prevVals.searchFilters.coatlength) {
            this.prevVals.searchFilters.coatlength = [];
        }
        if (detail.coatLength) {
            detail.coatLength.forEach(coatLength => {
                if (this.prevVals.searchFilters.coatlength.includes(coatLength.toLowerCase())) {
                    return;
                }
                this.prevVals.searchFilters.coatlength.push(coatLength.toLowerCase());
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: coat length',
                    label: coatLength.toLowerCase(),
                    event: 'Petfinder Consumer 054 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.dataLayer,
                    metrics: {
                        1: 1,
                    },
                });
            });
        }
    }

    /**
     * Petfinder Consumer 055
     * Page: Search Results Page
     * Action: Select Color
     *
     * @public
     * @event PetfinderConsumer055
     * @param {Object} detail
     */
    eventConsumer055(detail) {
        if (!this.prevVals.searchFilters.color) {
            this.prevVals.searchFilters.color = [];
        }
        if (detail.color) {
            detail.color.forEach(color => {
                if (this.prevVals.searchFilters.color.includes(color.toLowerCase())) {
                    return;
                }
                this.prevVals.searchFilters.color.push(color.toLowerCase());
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: color',
                    label: color.toLowerCase(),
                    event: 'Petfinder Consumer 055 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.dataLayer,
                    metrics: {
                        1: 1,
                    },
                });
            });
        }
    }

    /**
     * Petfinder Consumer 056
     * Page: Search Results Page
     * Action: Select Household
     *
     * @public
     * @event PetfinderConsumer056
     * @param {Object} detail
     */
    eventConsumer056(detail) {
        const valueMap = {
            good_with_dogs: 'dogs', // eslint-disable-line
            good_with_cats: 'cats', // eslint-disable-line
            good_with_children: 'children', // eslint-disable-line
        };
        if (!this.prevVals.searchFilters.household) {
            this.prevVals.searchFilters.household = [];
        }
        if (detail.household) {
            detail.household.forEach(household => {
                if (this.prevVals.searchFilters.household.includes(household.toLowerCase())) {
                    return;
                }
                this.prevVals.searchFilters.household.push(household.toLowerCase());
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: household',
                    label: valueMap[household],
                    event: 'Petfinder Consumer 056 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.dataLayer,
                    metrics: {
                        1: 1,
                    },
                });
            });
        }
    }

    /**
     * Petfinder Consumer 057
     * Page: Search Results Page
     * Action: Select Distance
     *
     * @public
     * @event PetfinderConsumer057
     * @param {Object} detail
     */
    eventConsumer057(detail) {
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            initiatingSite: detail.initiatingSiteFeature || 'main search',
            filterOutNullText: false,
        });
        trackEvent({
            category: SEARCH_CATEGORY,
            action: 'search: distance',
            label: detail.distance[0],
            event: 'Petfinder Consumer 057 - Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    /**
     * Petfinder Consumer 058
     * Page: Search Results Page
     * Action: Select Days on Petfinder
     *
     * @public
     * @event PetfinderConsumer058
     * @param {Object} detail
     */
    eventConsumer058(detail) {
        const newValue = detail.daysOnPetfinder[0];
        const filterValue = newValue === 'undefined' ? 'any' : newValue;
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });
        trackEvent({
            category: SEARCH_CATEGORY,
            action: 'search: days on petfinder',
            label: filterValue,
            event: 'Petfinder Consumer 058 - Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    /**
     * Petfinder Consumer 059
     * Page: Search Results Page
     * Action: Select Pet Name
     *
     * @public
     * @event PetfinderConsumer059
     * @param {Object} detail
     */
    eventConsumer059(detail) {
        if (this.prevVals.searchFilters.name === detail.name) {
            return;
        }
        this.prevVals.searchFilters.name = detail.name;
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });
        trackEvent({
            category: SEARCH_CATEGORY,
            action: 'search: pet name',
            label: detail.name,
            event: 'Petfinder Consumer 059 - Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    /**
     * Petfinder Consumer 060
     * Page: Search Results Page
     * Action: Select Shelter or Rescue
     *
     * @public
     * @event PetfinderConsumer060
     * @param {Object} detail
     */
    eventConsumer060(detail) {
        if (!this.prevVals.searchFilters.adoptionorg) {
            this.prevVals.searchFilters.adoptionorg = [];
        }
        if (detail.shelter) {
            detail.shelter.forEach(shelter => {
                if (this.prevVals.searchFilters.adoptionorg.includes(shelter.toLowerCase())) {
                    return;
                }
                this.prevVals.searchFilters.adoptionorg.push(shelter.toLowerCase());
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: adoption org',
                    // TODO: Confirm shelter should not be lowercase on label
                    label: shelter,
                    event: 'Petfinder Consumer 060 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.dataLayer,
                    metrics: {
                        1: 1,
                    },
                });
            });
        }
    }

    eventConsumer061() {
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'submit search criteria',
            label: 'mobile see pets button',
        });
    }

    eventConsumer062() {
        trackEvent({
            category: 'search alerts',
            action: 'set alert',
            label: 'mobile see pets button',
        });
    }

    /**
     * Petfinder Consumer 063
     * Page: Search Results Page
     * Action: Reset Button
     *
     * @public
     * @event PetfinderConsumer063
     * @param {Object} detail
     */
    eventConsumer063(detail) {
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'reset search criteria',
            label: detail.source,
            event: 'Petfinder Consumer 063 - Pet Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    eventConsumer064(detail) {
        const trigger = detail.trigger;
        if (!trigger.hasAttribute('is-active')) {
            trackEvent({
                category: PET_SEARCH_CATEGORY,
                action: 'more filters',
            });
        }
    }

    /**
     * Petfinder Consumer 065
     * Page: Search Results Page
     * Action: Edit Location in Nav
     *
     * @public
     * @event PetfinderConsumer065
     * @param {Object} detail
     */
    eventConsumer065(detail) {
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'edit location',
            label: detail.location,
            event: 'Petfinder Consumer 065 - Pet Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    /**
     * Petfinder Consumer 066
     * Page: Search Results Page
     * Action: Edit Pet Type in Nav
     *
     * @public
     * @event PetfinderConsumer066
     * @param {Object} detail
     */
    eventConsumer066(detail) {
        const primaryAnimalTypes = ['cats', 'dogs'];
        let type = detail.type[0];

        if (!primaryAnimalTypes.includes(type)) {
            type = `other: ${type}`;
        }

        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'edit type',
            label: type,
            event: 'Petfinder Consumer 066 - Pet Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    eventConsumer067_279_280(detail) {
        // eslint-disable-line
        const trigger = detail.trigger;

        // Breed autosuggest input blur events
        if (trigger.hasAttribute('breed-autosuggest-search-input')) {
            const trigger = detail.trigger;
            if (trigger.value === '') {
                return;
            }
            trackEvent({
                category: PET_SEARCH_CATEGORY,
                action: 'breed search',
                label: trigger.value,
            });
        }

        // Color autosuggest input blur events
        if (trigger.hasAttribute('color-autosuggest-search-input')) {
            const trigger = detail.trigger;
            if (trigger.value === '') {
                return;
            }
            trackEvent({
                category: PET_SEARCH_CATEGORY,
                action: 'color search',
                label: trigger.value,
            });
        }

        // Shelter autosuggest input blur events
        if (trigger.hasAttribute('shelter-autosuggest-search-input')) {
            const trigger = detail.trigger;
            if (trigger.value === '') {
                return;
            }
            trackEvent({
                category: PET_SEARCH_CATEGORY,
                action: 'adoption org search',
                label: trigger.value,
            });
        }
    }

    /**
     * Petfinder Consumer 068
     * Page: Search Results Page
     * Action: Click on Any Pet
     *
     * @public
     * @event PetfinderConsumer068
     * @param {string} petId
     * @param {string} petMatchType
     * @param {string} petSpecies
     */
    eventConsumer068(petId, petMatchType, petSpecies) {
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
            petMatchType,
        });
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'click to pet details page',
            label: `/petdetail/${petId}`,
            event: 'Petfinder Consumer 068 - Pet Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                2: 1,
            },
        });

        // PFPROD-4085 - GA4 Analytics - Search
        /* eslint-disable camelcase, no-undefined */
        window.dataLayer.push(
            {
                event: 'view_pet',
                ga4: true,
                event_params: {
                    method: 'search results',
                    page_type: 'search results',
                    pet_species: petSpecies ? petSpecies.toLowerCase() : undefined,
                },
            },
            { event_params: undefined }
        );
    }

    eventConsumer070() {
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'results pagination',
            label: 'next',
        });
    }

    eventConsumer071() {
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'results pagination',
            label: 'previous',
        });
    }

    eventConsumer072(detail) {
        const pageNumber = Number(_get(detail, 'srcEvent.selected'));
        if (Number.isInteger(pageNumber)) {
            trackEvent({
                category: PET_SEARCH_CATEGORY,
                action: 'results pagination - select page',
                label: (pageNumber + 1).toString(),
            });
        }
    }

    eventConsumer073() {
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'mobile quickview',
            label: 'next',
        });
    }

    eventConsumer074() {
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'mobile quickview',
            label: 'previous',
        });
    }

    /**
     * Petfinder Consumer 075
     * Page: Search Results Page
     * Action: Click More Info in Mobile Quickview
     *
     * @public
     * @event PetfinderConsumer075
     */
    eventConsumer075() {
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'mobile quickview',
            label: 'more info',
            event: 'Petfinder Consumer 075 - Pet Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                2: 1,
            },
        });
    }

    eventConsumer076(detail) {
        const trigger = detail.trigger;
        const petId = trigger.getAttribute('pet-id');
        trackEvent({
            category: 'adopt me',
            action: 'open - mobile quickview',
            label: `/petdetail/${petId}`,
        });
    }

    /**
     * Petfinder Consumer 77
     * Page: Search Results Page
     * Action: Click on Share Arrow > Select Share Option (email only)
     *
     * @public
     * @param {Object} detail
     * @event PetfinderConsumer77
     */
    eventPetfinderConsumer77(detail) {
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'clicks to share',
            label: detail.trigger.getAttribute('data-url'),
            event: 'Petfinder Consumer 77 - Pet Search',
        });
    }

    /**
     * Petfinder Consumer 78
     * Page: Click on Share Arrow > Select Share Option (social only)
     * Action: Social Share
     *
     * Social Share - client is handling all social interactions and dataLayer interactions
     */

    /**
     * Petfinder Consumer 79
     * Page: N/A
     * Action N/A
     *
     * No event for Petfinder Consumer 79
     */

    /**
     * Petfinder Consumer 80
     * Page: N/A
     * Action N/A
     *
     * No event for Petfinder Consumer 80
     */

    /**
     * Petfinder Consumer 81
     * Page: Search Results Page
     * Action: Click on Share Arrow > Select Share Option (email only)
     *
     * @public
     * @param {Object} detail
     * @event PetfinderConsumer81
     */
    eventPetfinderConsumer81(detail) {
        trackEvent({
            category: 'email share',
            action: 'pet search',
            label: detail.trigger.getAttribute('data-url'),
            event: 'Petfinder Consumer 81 - Pet Search',
        });
    }

    /**
     * Petfinder Consumer 82
     * Page: Search Results Page
     * Action: Click on Share Arrow > Select Share Option (copy link only)
     *
     * @public
     * @param {Object} detail
     * @event PetfinderConsumer82
     */
    eventPetfinderConsumer82(detail) {
        trackEvent({
            category: 'link share',
            action: 'pet search',
            label: detail.trigger.getAttribute('text'),
            event: 'Petfinder Consumer 82 - Pet Search',
        });
    }

    eventConsumer083() {
        trackEvent({
            category: 'search alerts',
            action: 'open',
        });
    }

    eventConsumer084() {
        trackEvent({
            category: 'search alerts',
            action: 'close',
        });
    }

    eventConsumer085(detail) {
        const searchName = detail.searchName;
        trackEvent({
            category: 'search alerts',
            action: 'set alert',
            label: searchName,
        });
    }

    eventConsumer086(detail) {
        const searchName = detail.searchName;
        trackEvent({
            category: 'search alerts',
            action: 'save alert: successful',
            label: searchName,
        });
    }

    eventConsumer087(detail) {
        const error = detail.error;
        trackEvent({
            category: 'search alerts',
            action: 'save alert: unsuccessful',
            label: error,
        });
    }

    eventConsumer088() {
        trackEvent({
            category: 'search alerts',
            action: 'edit alert',
        });
    }

    eventConsumer096() {
        trackEvent({
            category: 'about me',
            action: 'enter customer name',
        });
    }

    eventConsumer114() {
        trackEvent({
            category: 'adopter registration',
            action: 'begin registration',
        });
    }

    eventConsumer255() {
        trackEvent({
            category: 'page not found',
            action: 'clicks to start a new search',
        });
    }

    eventConsumer256_257(detail) {
        // eslint-disable-line
        const target = detail.srcEvent.target;
        if (!target.href) {
            return;
        }
        const href = target.getAttribute('href');
        if (href === '/') {
            trackEvent({
                category: 'page not found',
                action: 'clicks to homepage',
            });
        }
        if (href.includes('publicreceptiondesk.html#question')) {
            trackEvent({
                category: 'page not found',
                action: 'clicks to help',
            });
        }
    }

    /**
     * Petfinder Consumer 283
     * Page: Any Search Experience
     * Action: Click ANY Filter within Pet Search
     *
     * @public
     * @param {Object} detail
     * @event PetfinderConsumer283
     */
    eventConsumer283(detail) {
        if (this.prevVals.searchFilters.transportable === detail.transportable) {
            return;
        }
        this.prevVals.searchFilters.transportable = detail.transportable;

        // Return early if not checked...
        if (!detail.transportable) {
            return;
        }

        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });

        trackEvent({
            category: SEARCH_CATEGORY,
            action: 'search: include out-of-town pets',
            label: 'include',
            event: 'Petfinder Consumer 283 - Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    /**
     * Petfinder Consumer 284
     * Page: Any Search Experience
     * Action: REMOVE a filter (THIS IS NEW)
     *
     * @public
     * @param {Object} filters
     * @event PetfinderConsumer284
     */
    eventConsumer284(filters) {
        _pull(this.prevVals.searchFilters[filters.filterName.replace(/\s+/g, '')], filters.filterValue);
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });
        trackEvent({
            category: SEARCH_CATEGORY,
            action: 'filter removed',
            label: `${filters.filterName}:${filters.filterValue}`,
            event: 'Petfinder Consumer 284 - Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    /**
     * Petfinder Consumer 291
     * Page: Search Results Page
     * Action: Click on any IMAGE of the pet from search results page (Mobile)
     *
     * @public
     * @param {Object} filters
     * @event PetfinderConsumer291
     */
    eventConsumer291() {
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });
        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'mobile quickview',
            label: 'more info - photo',
            event: 'Petfinder Consumer 291 - Pet Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                2: 1,
            },
        });
    }

    /**
     * Petfinder Consumer 292
     * Page: Search Results Page
     * Action: Select Species
     *
     * @public
     * @param {Object} detail
     * @event PetfinderConsumer292
     */
    eventConsumer292(detail) {
        if (!this.prevVals.searchFilters.species) {
            this.prevVals.searchFilters.species = [];
        }
        if (detail.species) {
            detail.species.forEach(species => {
                if (this.prevVals.searchFilters.species.includes(species)) {
                    return;
                }
                this.prevVals.searchFilters.species.push(species);
                const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
                    filterOutNullText: false,
                });
                trackEvent({
                    category: SEARCH_CATEGORY,
                    action: 'search: species',
                    label: species.toLowerCase(),
                    event: 'Petfinder Consumer 292 - Search',
                    dimensions: analytics.dimensions,
                    additional: analytics.dataLayer,
                    metrics: {
                        2: 1,
                    },
                });
            });
        }
    }

    eventConsumer293(location) {
        trackEvent({
            category: SEARCH_CATEGORY,
            action: 'search: location detected',
            label: location.display_name,
        });
    }

    eventConsumer296(detail) {
        const trigger = detail.trigger;
        const value = trigger.getAttribute('data-value');
        trackEvent({
            category: 'adopt me',
            action: 'pet ownership',
            label: value,
        });
    }

    eventConsumer297(detail) {
        const trigger = detail.trigger;
        const value = trigger.getAttribute('data-value');
        trackEvent({
            category: 'adopt me',
            action: 'pet ownership',
            label: `dog count: ${value}`,
        });
    }

    eventConsumer298(detail) {
        const trigger = detail.trigger;
        const value = trigger.getAttribute('data-value');
        trackEvent({
            category: 'adopt me',
            action: 'pet ownership',
            label: `cat count: ${value}`,
        });
    }

    /**
     * Petfinder Consumer 299
     * Page: AIF - On Pet Detail Page
     * Action: Guest submits form
     *
     * @public
     * @param {Object} detail
     * @event PetfinderConsumer299
     */
    eventConsumer299(detail) {
        const formData = new FormData(detail.trigger.querySelector('form'));
        const petDetailDimensionsData = detail.petDetailAnalytics.dimensions || {};
        const petDetailAdditionalData = detail.petDetailAnalytics.additional || {};
        const petsInHomeSelected = formData.get('animal_adoption_inquiry_guest[petsInHome]') === 'Yes';

        const userLoginRegistrationAnalytics = UserLoginRegistrationEventModel.prepareEvent({
            authenticationStatus: 'not logged in',
        });
        const userInfoAnalytics = UserInfoEventModel.prepareEvent({
            country: formData.get('animal_adoption_inquiry_guest[profile][address][country]')
                ? formData.get('animal_adoption_inquiry_guest[profile][address][country]')
                : null,
            postalCode: formData.get('animal_adoption_inquiry_guest[profile][address][postalCode]')
                ? formData.get('animal_adoption_inquiry_guest[profile][address][postalCode]')
                : null,
            petsInHome: petsInHomeSelected ? 'y' : 'n',
            petNumber: petsInHomeSelected
                ? `dog:${formData.get('animal_adoption_inquiry_guest[dogsInHome]')} cat:${formData.get(
                      'animal_adoption_inquiry_guest[catsInHome]'
                  )}`
                : '(not set)',
        });

        const dimensions = Object.assign(
            {},
            petDetailDimensionsData,
            userLoginRegistrationAnalytics.dimensions,
            userInfoAnalytics.dimensions
        );
        const additional = Object.assign(
            {},
            petDetailAdditionalData,
            userLoginRegistrationAnalytics.dataLayer,
            userInfoAnalytics.dataLayer
        );

        trackEvent({
            category: 'adopt me',
            action: 'submit',
            label: 'guest',
            event: 'Petfinder Consumer 299',
            dimensions,
            additional,
        });
    }

    /**
     * Petfinder Consumer 300
     * Page: AIF - On Pet Detail Page
     * Action: Logged in user submits form
     *
     * @public
     * @param {Object} detail
     * @event PetfinderConsumer300
     */
    eventConsumer300(detail) {
        const formData = new FormData(detail.trigger.querySelector('form'));
        const petDetailDimensionsData = detail.petDetailAnalytics.dimensions || {};
        const petDetailAdditionalData = detail.petDetailAnalytics.additional || {};
        const petsInHomeSelected = formData.get('animal_adoption_inquiry_user[petsInHome]') === 'Yes';
        const userInfoAnalytics = UserInfoEventModel.prepareEvent({
            country: formData.get('animal_adoption_inquiry_user[profile][address][country]')
                ? formData.get('animal_adoption_inquiry_user[profile][address][country]')
                : null,
            postalCode: formData.get('animal_adoption_inquiry_user[profile][address][postalCode]')
                ? formData.get('animal_adoption_inquiry_user[profile][address][postalCode]')
                : null,
            petsInHome: petsInHomeSelected ? 'y' : 'n',
            petNumber: petsInHomeSelected
                ? `dog:${formData.get('animal_adoption_inquiry_user[dogsInHome]')} cat:${formData.get(
                      'animal_adoption_inquiry_user[catsInHome]'
                  )}`
                : '(not set)',
        });

        const userLoginRegistrationAnalytics = UserLoginRegistrationEventModel.prepareEvent({
            authenticationStatus: 'logged in',
        });
        const dimensions = Object.assign(
            {},
            petDetailDimensionsData,
            userLoginRegistrationAnalytics.dimensions,
            userInfoAnalytics.dimensions
        );
        const additional = Object.assign(
            {},
            petDetailAdditionalData,
            userLoginRegistrationAnalytics.dataLayer,
            userInfoAnalytics.dataLayer
        );

        trackEvent({
            category: 'adopt me',
            action: 'submit',
            label: 'logged-in user',
            event: 'Petfinder Consumer 300',
            dimensions,
            additional,
        });
    }

    eventConsumer301() {
        trackEvent({
            category: 'adopt me',
            action: 'sign in link',
        });
    }

    /**
     * Petfinder Consumer 422
     * Page: Any search experience where a filter chicklet can be removed from the nav of a search experience
     * Action: User clicks "X" to remove a filter chicklet from a search experience
     *
     * @public
     * @param {string} filter
     * @param {string} value
     * @event PetfinderConsumer422
     */
    eventConsumer422(filter, value) {
        const analytics = AnimalSearchEventModel.fromAnimalSearchFilters(animalSearchStateController.filters, {
            filterOutNullText: false,
        });
        trackEvent({
            category: SEARCH_CATEGORY,
            action: 'filter chicklet removed',
            label: `${filter}:${value.toLowerCase()}`,
            event: 'Petfinder Consumer 422 - Search',
            dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
            metrics: {
                1: 1,
            },
        });
    }

    eventConsumer423() {
        trackEvent({
            event: 'select_content',
            category: 'accessibility',
            action: 'skip to content',
            label: '',
        });
    }

    /**
     * Petfinder Consumer 484
     * Page: Search Results - Number of Search Results Populated
     * Action: Search Results - Populates how many search results load upon pageview
     *
     * @public
     * @param {string} totalResults
     * @event PetfinderConsumer484
     */
    eventConsumer484(totalResults) {
        const totalResultsNumber = parseInt(totalResults, 10);
        let totalResultsRange = '0';

        if (totalResultsNumber >= 200) {
            totalResultsRange = '200+';
        } else if (totalResultsNumber >= 100) {
            totalResultsRange = '100-200';
        } else if (totalResultsNumber >= 50) {
            totalResultsRange = '50-100';
        } else if (totalResultsNumber >= 41) {
            totalResultsRange = '41-50';
        } else if (totalResultsNumber >= 31) {
            totalResultsRange = '31-40';
        } else if (totalResultsNumber >= 21) {
            totalResultsRange = '21-30';
        } else if (totalResultsNumber >= 11) {
            totalResultsRange = '11-20';
        } else if (totalResultsNumber >= 1) {
            totalResultsRange = '1-10';
        }

        trackEvent({
            category: SEARCH_CATEGORY,
            action: `number of results: ${totalResultsRange}`,
            event: 'Petfinder Consumer 484 - Search Results - Number of Search Results Populated',
            dimensions: {
                120: totalResultsNumber,
            },
        });
    }

    /**
     * Petfinder Consumer 485
     * Page: Search Page - Sort by
     * Action: Search Page - Sort By {sort_by_type}
     *
     * @public
     * @param {object} detail
     * @event PetfinderConsumer504
     */
    eventConsumer504(detail) {
        let sortBy = 'default';

        if (detail.sortBy) {
            sortBy = detail.sortBy;
        }

        trackEvent({
            category: PET_SEARCH_CATEGORY,
            action: 'sort by',
            label: `sort by ${sortBy}`,
            event: 'Petfinder Consumer 504 - Search Page - Sort By',
        });
    }

    /**
     * public API specific analytics events
     */
    eventPublicApi002() {
        trackEvent({
            category: 'developer site',
            action: 'global nav',
            label: 'overview',
        });
    }

    eventPublicApi003() {
        trackEvent({
            category: 'developer site',
            action: 'global nav',
            label: 'documentation',
        });
    }

    eventPublicApi004() {
        trackEvent({
            category: 'developer site',
            action: 'global nav',
            label: 'support',
        });
    }

    eventPublicApi005() {
        trackEvent({
            category: 'sign in',
            action: 'go to login or registration modal widow',
        });
    }

    eventPublicApi006_007(detail) {
        // eslint-disable-line
        const label = detail.trigger.getAttribute('access-type');
        trackEvent({
            category: 'developer site',
            action: 'api key',
            label,
        });
    }

    eventPublicApi007() {
        trackEvent({
            category: 'developer site',
            action: 'api key',
            label: 'elevated access',
        });
    }

    eventPublicApi008() {
        trackEvent({
            category: 'my petfinder',
            action: 'for developers',
        });
    }

    eventPublicApi011(detail) {
        const target = detail.srcEvent.target;
        trackEvent({
            category: 'for developers',
            action: `org: ${target.value}`,
        });
    }

    eventPublicApi012(detail) {
        const target = detail.srcEvent.target;
        trackEvent({
            category: 'for developers',
            action: `app: ${target.value}`,
        });
    }

    eventPublicApi013(detail) {
        const target = detail.srcEvent.target;
        trackEvent({
            category: 'for developers',
            action: `url: ${target.value}`,
        });
    }

    eventPublicApi014() {
        trackEvent({
            category: 'for developers',
            action: 'api key',
            label: 'elevated access button',
        });
    }

    eventPublicApi015() {
        trackEvent({
            category: 'for developers',
            action: 'api key',
            label: 'elevated access link',
        });
    }

    /**
     * API Front End 016
     * Page: Developers Sign Up
     * Action: User clicks 'get a key' button
     */
    eventPublicApi016() {
        trackEvent({
            category: 'for developers',
            action: 'api key',
            label: 'get key',
        });
    }

    eventPublicApi017() {
        trackEvent({
            category: 'my petfinder',
            action: 'click for developers tab',
        });
    }

    /**
     * @fires analyticsFilterFavoritesGlobalNav
     */
    eventFavorites011_012() {
        // TODO: remove this call from templates and replace with filterFavoritesGlobalNav with data-analytics-key: 'favorites' - then remove this function entirely
        analyticsFilterFavoritesGlobalNav();
    }

    // PFPROD-4085 - GA4 Analytics - Search Performed Event
    eventPerformSearchGa4() {
        const searchEventModelData = AnimalSearchEventModel.mapAnimalSearchFilters(animalSearchStateController.filters);

        // lowercase and joined array values
        Object.keys(searchEventModelData).forEach(key => {
            if (Array.isArray(searchEventModelData[key])) {
                searchEventModelData[key] = searchEventModelData[key]
                    .sort()
                    .join(', ')
                    .toLowerCase();
            } else if (typeof searchEventModelData[key] === 'string') {
                searchEventModelData[key] = searchEventModelData[key].toLowerCase();
            }
        });

        /* eslint-disable camelcase, no-undefined */
        window.dataLayer.push(
            {
                event: 'search',
                ga4: true,
                event_params: {
                    pet_species: searchEventModelData.animalType,
                    pet_age: searchEventModelData.age,
                    pet_breed_1: searchEventModelData.breed,
                    pet_gender: searchEventModelData.gender,
                    pet_distance: searchEventModelData.distance,
                    pet_size: searchEventModelData.size,
                    pet_color: searchEventModelData.color,
                    pet_household: searchEventModelData.household,
                    pet_coat_length: searchEventModelData.coatLength,
                    pet_health_behavior: searchEventModelData.attribute,
                    pet_name: searchEventModelData.animalName ? 'yes' : 'no',
                    pet_transport: searchEventModelData.transportable,
                    pet_days_on_pf: searchEventModelData.daysOnPetfinder,
                    search_category: 'pet',
                    method: 'search filter',
                    shelter_id: searchEventModelData.shelter,
                },
            },
            { event_params: undefined }
        );
    }
}
