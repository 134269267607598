/**
 * @type {Object}
 */
export const PETS = {
    DOG: 1,
    CAT: 2,
    RABBIT: 3,
    SMALL_FURRY: 4,
    SCALES_FINS_OTHER: 5,
    BIRDS: 6,
    HORSES: 7,
    BARNYARD: 8,
    properties: {
        1: {
            buttonLabel: 'Find a dog',
            buttonAriaLabel: 'Find a dog',
            dataTestValue:'Find_A_Pet_Find_Dog_Button',
            modalHeaderText: 'Find a dog near',
            svgIconSlug: 'dog',
            typeSlug: 'dogs',
        },
        2: {
            buttonLabel: 'Find a cat',
            buttonAriaLabel: 'Find a cat',
            dataTestValue:'Find_A_Pet_Find_Cat_Button',
            modalHeaderText: 'Find a cat near',
            svgIconSlug: 'cat',
            typeSlug: 'cats',
        },
        3: {
            buttonLabel: 'Rabbits',
            buttonAriaLabel: 'Find Rabbits',
            dataTestValue:'Find_A_Pet_Find_Rabbit_Button',
            modalHeaderText: 'Find a rabbit near',
            svgIconSlug: 'rabbit',
            typeSlug: 'rabbits',
        },
        4: {
            buttonLabel: 'Small & Furry',
            buttonAriaLabel: 'Find Small and Furry Animals',
            dataTestValue:'Find_A_Pet_Find_Small_And_Furry_Button',
            modalHeaderText: 'Find a small & furry animal near',
            svgIconSlug: 'smallFurry',
            typeSlug: 'small-furry',
        },
        5: {
            buttonLabel: 'Scales, Fins, & Other',
            buttonAriaLabel: 'Find Scales, Fins and Other',
            dataTestValue:'Find_A_Pet_Find_Scales_Fins_Other_Button',
            modalHeaderText: 'Find scales, fins, & others near',
            svgIconSlug: 'fish',
            typeSlug: 'scales-fins-others',
        },
        6: {
            buttonLabel: 'Birds',
            buttonAriaLabel: 'Find Birds',
            dataTestValue:'Find_A_Pet_Find_Bird_Button',
            modalHeaderText: 'Find a bird near',
            svgIconSlug: 'bird',
            typeSlug: 'birds',
        },
        7: {
            buttonLabel: 'Horses',
            buttonAriaLabel: 'Find Horses',
            dataTestValue:'Find_A_Pet_Find_Horse_Button',
            modalHeaderText: 'Find a horse near',
            svgIconSlug: 'horse',
            typeSlug: 'horses',
        },
        8: {
            buttonLabel: 'Barnyard',
            buttonAriaLabel: 'Find Barnyard Animals',
            dataTestValue:'Find_A_Pet_Find_Barnyard_Button',
            modalHeaderText: 'Find a barnyard animal near',
            svgIconSlug: 'barnyard',
            typeSlug: 'barnyard',
        },
    },
};

/**
 * @type {array}
 */
export const OTHER_PETS = [
    'RABBIT',
    'SMALL_FURRY',
    'SCALES_FINS_OTHER',
    'BIRDS',
    'HORSES',
    'BARNYARD',
];

/**
 * @type {string}
 */
export const LOCATION_INPUT_PLACEHOLDER = 'Enter City, State or ZIP';

/**
 * @type {string}
 */
export const LOCATION_INPUT_ARIA_LABEL = 'Find a pet by location';

/**
 * @type {string}
 */
/* eslint-disable */
export const LOCATION_ARIA_DESCRIPTION =
    'This is an autocomplete field. We will populate a list of suggestions as you type. We will notify you of the number of suggestions that match your query as you type. If suggestions are found, press the tab key to focus the suggestions list. Select a suggestion item to set it as the value of this field. Press escape in the suggestions list to close the list and return to the search input field.';
/* eslint-enable */

/**
 * @type {string}
 */
export const LOCATION_NETWORK_ERROR_ALERT =
    'There has been a network error with your search, please try again later.';

/**
 * @type {array}
 */
export const BUSY_STATES = ['init', 'loading'];

/**
 * @type {Object}
 */
/* eslint-disable */
export const ALERTS_MAP = {
    loading: () => 'Loading locations...',
    results: locations =>
        `${
            locations ? locations.length : ''
        } location results found. Use tab to focus items in the list.`,
    noResults: () => 'No results found for your query. Please try again.',
    locationNetworkError: () => LOCATION_NETWORK_ERROR_ALERT,
    setLocationPrompt: () => 'Please search and select a location first.',
};
/* eslint-disable */

// TODO: Would this make more sense as a util that you pass a map obj to?
/**
 * @type {function}
 */
export const getAlertWithKey = (key, ...args) => {
    if (Object.keys(ALERTS_MAP).includes(key)) {
        return ALERTS_MAP[key](...args);
    }

    return '';
};

/**
 * @type {number}
 */
export const LOCATION_PROMPT_TIMER_DURATION = 5000;

/**
 * @type {number}
 */
export const SAVED_SEARCH_LIST_MAX_NUM_RESULTS = 10;

/**
 * @type {number}
 */
export const MIN_QUERY_VALUE_LENGTH = 3;

/**
 * @type {string}
 */
export const SEARCH_URL_BASE = `${window.location.origin}/search/`;

/**
 * @type {string}
 */
export const SEARCH_URL_PET_STATUS = '-for-adoption/';

/**
 * @type {string}
 */
export const SEARCH_URL_PARAMS = '/?distance=100';
