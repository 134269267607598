import MixinBase from './MixinBase';
import Utils from '../Utils';

export default class IdMixin extends MixinBase {
    static _attributeName = 'mix-id';

    static _idToElementMap = {};

    static register(name, element) {
        // doesn't currently exist
        if (!this._idToElementMap[name]) {
            this._idToElementMap[name] = [];
        }

        this._cleanUp(name);

        this._idToElementMap[name].push(element);
    }

    static _cleanUp(name) {
        const elements = this._idToElementMap[name];
        for (let i = elements.length - 1; i >= 0; i--) {
            if (!document.contains(elements[i])) {
                elements.splice(i, 1);
            }
        }
    }

    static get(id) {
        return this._idToElementMap[id] || [];
    }

    static dereference(path) {
        const [elementId, ...rest] = path.split('.');
        const elements = this.get(elementId);

        const dereferenced = elements.map(element => {
            return Utils.ObjectUtils.dereference(rest, element).result;
        });

        // if nothing found, return undefined
        if (dereferenced.length === 0) {
            return void (0);
        }

        // if only one item was found, do not return in array format
        if (dereferenced.length === 1) {
            return dereferenced[0];
        }

        return dereferenced;
    }

    constructor(ele, value) {
        super(ele, value);

        if (!value) {
            throw new Error(`${this.attributeName} element found with no name provided: (${Utils.HTMLElementUtils.getElementShortHtml(ele)})`);
        }

        IdMixin.register(value, ele);
    }
}
