import { pollUntilConditionMet } from '../../../core/scripts/util/util';

export function isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

export function matchesElementOrDescendant(element, target) {
    if (Array.isArray(element)) {
        const length = element.length;
        for (let i = 0; i < length; i++) {
            if (matchesElementOrDescendant(element[i], target)) {
                return true;
            }
        }
        return false;
    }
    if (element === target) {
        return true;
    }
    if (isDescendant(element, target)) {
        return true;
    }
    return false;
}

export function isElement(el = {}) {
    return el.nodeType === Node.ELEMENT_NODE;
}

export async function isElementConnected(element) {
    pollUntilConditionMet(() => element.isCustom, 0, 100);
}

export function buildCustomEvent(event, detail, bubbles = true, ...args) {
    return new CustomEvent(event, {
        bubbles,
        detail,
        args,
    });
}

export function isCustomElement(el = {}) {
    return isElement(el) && el.isCustom;
}

export function parseJsonAttribute(el, attr) {
    try {
        return JSON.parse(el.getAttribute(attr));
    } catch (e) {
        return el.getAttribute(attr);
    }
}

export function debounce(fn, wait) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => fn.apply(this, args), wait || 1);
    };
}

export function getPseudoContent(el, pseudo) {
    return window
        .getComputedStyle(el, pseudo)
        .getPropertyValue('content')
        .replace(/"/g, '');
}

export function getCurrentBreakpoint() {
    return getPseudoContent(document.body, ':after');
}

export function getGlobalHeaderHeight() {
    return document.querySelector('#Header').clientHeight;
}

export function getBodyHeight() {
    return document.querySelector('#Main').clientHeight;
}

export function getGlobalFooterHeight() {
    return document.querySelector('#Footer').clientHeight;
}

/**
 * Escapes a selector with special characters.  Useful for instances
 * in which the API returns data related to inputs which have special
 * characters in their name or id, such as edit_animal[name]
 *
 * @param {String} selector
 * @return {String} escaped selector
 */
export function escapeSelector(selector) {
    return selector.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

/**
 * Converts a NodeList returned from a querySelectorAll to an Array
 * @param {String} selector
 * @param {HTMLElement} context to query within
 * @return {Array}
 */
export function findArrayOfElementsBySelector(selector, context = document) {
    return Array.from(context.querySelectorAll(selector));
}

/**
 * Setup a MutationObserver on a given target element.
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 * @param  {[type]} target [description]
 * @param  {[type]} func   [description]
 * @param  {[type]} config [description]
 * @return {[type]}        [description]
 */
export function watchForMutations(target, func, config) {
    // if (typeof func !== 'function') {
    //     throw new Error('');
    // }
    const observer = new MutationObserver(func);
    observer.observe(target, config);
    return observer;
}
