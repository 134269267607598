/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { AVAILABLE_PROVIDERS } from './services/SocialLoginService';

import socialLoginStateController from './controller/SocialLoginStateController';

import GoogleButton from './providers/GoogleButton';
import FacebookButton from './providers/FacebookButton';

/**
 * Social sign in button
 */
class SocialSignInButton extends Component {
    /* *******************************************
     * Class Properties
     ********************************************/
    state = {
        ready: socialLoginStateController.state.APIReady[this.props.provider],
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        provider: PropTypes.oneOf(AVAILABLE_PROVIDERS).isRequired,
        cta: PropTypes.string,
        next: PropTypes.string,
        next_params: PropTypes.array,
        context: PropTypes.string,
        type: PropTypes.string,
        text: PropTypes.bool,
        onSocialClick: PropTypes.func,
        config: PropTypes.object,
        analyticParams: PropTypes.object,
        children: PropTypes.node,
        isMobile: PropTypes.bool,
        id: PropTypes.string,
        onSignInSuccess: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        cta: 'Continue with ',
        type: 'full',
        text: true,
        onSocialClick: () => {},
        config: {},
    };

    /**
     * @type {Ref}
     */
    button = React.createRef();

    /* *******************************************
     * Lifecycle
     ********************************************/

    componentDidMount() {
        if (!socialLoginStateController.state.APIReady[this.props.provider]) {
            socialLoginStateController.subscribe(
                payload => {
                    this.setState({
                        ready:
                            socialLoginStateController.state.APIReady[
                                this.props.provider
                            ],
                    });
                },
                [`APIReady.${this.props.provider}`]
            );
        }
    }
    /* *******************************************
     * Events
     ********************************************/
    /**
     * @type {Function}
     * @param {String} provider
     * Triggered on social button click
     */
    handleSocialClick = provider => {
        this.props.onSocialClick({
            ...this.props.analyticParams,
            provider,
        });
    };

    /* *******************************************
     * Render
     ********************************************/

    renderSocialLoginButton = state => ({
        google: (
            <GoogleButton
                ref={this.button}
                ready={this.state.ready}
                handleSocialClick={this.handleSocialClick}
                {...this.props}
            >
                {this.props.children ? this.props.children : null}
            </GoogleButton>
        ),
        facebook: (
            <FacebookButton
                ref={this.button}
                ready={this.state.ready}
                handleSocialClick={this.handleSocialClick}
                {...this.props}
            >
                {this.props.children ? this.props.children : null}
            </FacebookButton>
        ),
    });

    render() {
        return (
            <Fragment>
                {this.renderSocialLoginButton(this.state)[this.props.provider]}
            </Fragment>
        );
    }
}

export default SocialSignInButton;
