import BaseModel from './BaseModel';

export default class LocationModel extends BaseModel {
    get defaultData() {
        return {
            administrativeArea: '',
            // TODO: Country model
            country: {
                name: '',
                code: '',
            },
            displayName: '',
            lat: 0,
            lng: 0,
            name: '',
            postalCode: null,
            slug: '',
            source: '',
            // TODO: State model
            state: {
                name: '',
                code: '',
            },
        };
    }

    static fromServer(locationData) {
        if (!locationData) {
            return new LocationModel();
        }

        return new LocationModel(this._mapServerDataToClientside(locationData));
    }

    static fromUserMeData(locationUserMeData) {
        if (!locationUserMeData) {
            return new LocationModel();
        }

        return new LocationModel(this._mapServerDataToClientside(locationUserMeData));
    }

    static _mapServerDataToClientside(locationData) {
        return {
            administrativeArea: locationData.administrative_area,
            // TODO: Country model
            country: {
                name: locationData.country.name,
                code: locationData.country.code,
            },
            displayName: locationData.display_name,
            lat: locationData.lat,
            lng: locationData.lng,
            name: locationData.name,
            postalCode: locationData.postal_code,
            slug: locationData.slug,
            source: locationData.source,
            // TODO: State model
            state: {
                name: locationData.state.name,
                code: locationData.state.code,
            },
        };
    }

    get administrativeArea() {
        return this._data.administrativeArea;
    }

    get countryName() {
        return this._data.country.name;
    }

    get countryCode() {
        return this._data.country.code;
    }

    get displayName() {
        return this._data.displayName;
    }

    get lat() {
        return this._data.lat;
    }

    get lng() {
        return this._data.lng;
    }

    get name() {
        return this._data.name;
    }

    get postalCode() {
        return this._data.postalCode;
    }

    get slug() {
        return this._data.slug;
    }

    get source() {
        return this._data.source;
    }

    get stateName() {
        return this._data.state.name;
    }

    get stateCode() {
        return this._data.state.code;
    }
}
