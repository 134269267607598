/**
 * @type {Object}
 */
export const STATE = {
    ON: 'on',
    OFF: 'off',
};

/**
 * @type {Object}
 */
export const EVENT = {
    TOGGLE: 'toggle',
    OPEN: 'open',
    CLOSE: 'close',
};

/**
 * @type {Object}
 */
export const ACTION = {
    FOCUS_FIRST_FOCUSABLE: 'focusFirstFocusable',
};

/**
 * @type {Object}
 */
export const statechart = {
    initial: STATE.OFF,
    states: {
        [STATE.OFF]: {
            on: {
                [EVENT.TOGGLE]: STATE.ON,
                [EVENT.OPEN]: STATE.ON,
            },
        },
        [STATE.ON]: {
            onEntry: [ACTION.FOCUS_FIRST_FOCUSABLE],
            on: {
                [EVENT.TOGGLE]: STATE.OFF,
                [EVENT.CLOSE]: STATE.OFF,
            },
        },
    },
};
