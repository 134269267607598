import Repository from '../repository/repository';
import { ERROR_API } from '../../constants/errors';
import pfCacheService, { PF_CACHE_MAP } from '../../lib/pfCacheService';
import axios from 'axios';

export default class CountryRepository extends Repository {
    /**
     * Function for performing the request to the API for countries
     * @returns {Promise}
     */
    _requestCountries() {
        return fetch('/v2/geography/countries/', {
            method: 'GET',
            headers: {
                // Need this content-type here
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            credentials: 'same-origin',
        });
    }

    /**
     * Get all the countries available for petfinder
     * @returns {Promise}
     */
    async getCountries() {
        // if we already have it, return the json promise
        // if (pfCacheService.has(PF_CACHE_MAP.COUNTRY_JSON_PROMISE)) {
        //     return await pfCacheService.get(PF_CACHE_MAP.COUNTRY_JSON_PROMISE);
        // }

        // const jsonPromise = this._requestCountries().then(response => {
        //     const jsonPromise = this.readResponse(response);

        //     if (response.status === 404) {
        //         return jsonPromise.then(jsonData => {
        //             throw jsonData;
        //         });
        //     }

        //     if (!this.wasRequestSuccessful(response)) {
        //         throw { errors: [ERROR_API] };
        //     }

        //     return jsonPromise;
        // });

        // pfCacheService.add(PF_CACHE_MAP.COUNTRY_JSON_PROMISE, jsonPromise);
        // return jsonPromise;

        const response = await axios('/v2/geography/countries/', {
            method: 'GET',
            headers: {
                // Need this content-type here
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            credentials: 'same-origin',
        });
        return response.data;
    }

    /**
     * Get states for a given country
     * @param {string} countryCode
     * @returns {Promise}
     */
    async getStatesForCountry(countryCode) {
        const response = await axios(
            `/v2/geography/countries/${encodeURIComponent(
                countryCode
            )}/states/?limit=300&sort=name`,
            {
                method: 'GET',
                headers: {
                    // Need this content-type here
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'same-origin',
            }
        );

        if (response.status === 404) {
            // For parsing error response and getting redirect
            return Promise.reject(response.data);
        }

        if (this.wasRequestSuccessful(response)) {
            return Promise.resolve(response.data);
        }

        return Promise.reject({
            errors: [ERROR_API],
        });
    }
}
