const statechart = {
    parallel: true,
    states: {
        hasInput: {
            initial: 'off',
            states: {
                on: {
                    on: {
                        HAS_NO_VALUE: 'off',
                    },
                },
                off: {
                    on: {
                        HAS_VALUE: 'on',
                        HAS_FOCUS: 'on',
                    },
                },
            },
        },
        hasError: {
            initial: 'off',
            states: {
                on: {
                    on: {
                        HAS_NO_ERROR: 'off',
                    },
                },
                off: {
                    on: {
                        HAS_ERROR: 'on',
                    },
                },
            },
        },
    },
};

export default statechart;
