import { renderReact } from 'hypernova-react';

/**
 * This iterates over an object of react components {componentOne, componentTwo, componentThree} and calls renderReact to hydrate react components.
 * @param {Object} componentsObject
 */
export default componentsObject => {
    Object.keys(componentsObject).forEach(componentName => {
        renderReact(componentName, componentsObject[componentName]);
    });
};
