import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';

/**
 * @class PetTypeButton
 * @extends React.Component
 */
class PetTypeButton extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        buttonAriaLabel: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        onPetTypeClick: PropTypes.func.isRequired,
        petTypeSlug: PropTypes.string.isRequired,
        svgIconSlug: PropTypes.string.isRequired,
        refocusable: PropTypes.bool.isRequired,
        buttonDataTest: PropTypes.string,
    };

    static defaultProps = {
        buttonDataTest: null,
    }

    /**
     * @method getClassNames
     * @private
     * @returns {string}
     */
    getClassNames() {
        const { petTypeSlug } = this.props;
        return buildClassNames({
            'findAPetMenu-button': true,
            [`findAPetMenu-button_${petTypeSlug}`]: true,
        });
    }

    /**
     * @method handlePetTypeClick
     * @private
     * @param {Event} ev
     */
    handlePetTypeClick = ev => {
        this.props.onPetTypeClick(ev, this.props);
    };

    render() {
        const { buttonLabel, buttonDataTest, buttonAriaLabel, svgIconSlug } = this.props;

        return (
            <button
                className={this.getClassNames()}
                onClick={this.handlePetTypeClick}
                data-test={buttonDataTest}
                type="button"
                aria-label={buttonAriaLabel}
            >
                <span className="findAPetMenu-buttonIcon">
                    <svg role="img" focusable="false">
                        <use xlinkHref={`#icon-${svgIconSlug}`} />
                    </svg>
                </span>
                <span>{buttonLabel}</span>
            </button>
        );
    }
}

export default PetTypeButton;
