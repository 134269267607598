import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import templates from '../../../../dotcom/scripts/util/TemplateRegistry';
import OptinsConfigService from '../../../../dotcom/scripts/config-services/optinsConfigService';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-optins';

/**
 * Component that renders options from an api endpoint based on a country code
 * update or configuration.
 *
 * ** The "R10 Optin Matrix" spreadsheet is currently powering this components data api
 * speak with Webfront Backend or BA about getting access to the spreadsheet.
 *
 * <pfdc-optins
 *     class="optins"
 *     // event string (like a country change event)
 *     update-on="registration-page.country.change"
 *     // template render function, make sure you register the template
 *     template="renderOptinsDefault"
 *     // id of spreadsheet tab from R10 Optin Matrix
 *     data-form-id="Consumer Registration Page"
 *     // order matters here! required column ids from R10 Optin Matrix spreadsheet **
 *     data-corporation="pf,pu"
 *     // order matters here! optional field ids (label and input/checkboxes)
 *     data-chox-ids="id,id"
 *     // order matters here! required field names (inputs/checkboxes)
 *     data-cbox-names="name,name"
 *     // order matters here! optional on loaded checked state (used for posted forms)
 *     data-override-checked="1,0"
 *     >
 * </pfdc-optins>
 *
 * @extends PFElement
 */
export class PFDCOptinsElement extends PFElement {
    get updateOnEvent() {
        return this.getAttribute('update-on');
    }

    /**
     * Initialize this component
     */
    onInit() {
        // If country is configured get our optins
        if (this.hasAttribute('country') && this.getAttribute('country') !== '') {
            setTimeout(() => {
                this.updateOptins(this.getAttribute('country'), false);
            }, 0);
        }
    }

    /**
     * Gets optin rules from repository, lets parent form component know about loading
     * @param {string} countryCode
     */
    async updateOptins(countryCode) {
        const shouldCombineOptins = countryCode === 'US';

        // Required params
        const params = {
            formId: this.dataset.formId,
            country: countryCode,
            corporation: shouldCombineOptins ? 'pu_combined' : this.dataset.corporation,
        };

        const optinRules = OptinsConfigService.getOptinRulesFromConfig(params);

        const checkboxIds = shouldCombineOptins
            ? this.dataset.cboxIds && this.dataset.cboxIds.split(',').slice(1)
            : null;

        const checkboxNames = shouldCombineOptins
            ? this.dataset.cboxNames && this.dataset.cboxNames.split(',').slice(1)
            : null;

        const dataTestValues = shouldCombineOptins
            ? this.dataset.testValues && this.dataset.testValues.split(',').slice(1)
            : null;
        const overrideChecked = shouldCombineOptins
            ? this.dataset.overrideChecked && this.dataset.overrideChecked.split(',').slice(1)
            : null;

        const errors = shouldCombineOptins ? this.dataset.errors && this.dataset.errors.split(';').slice(1) : null;

        this.renderOptins({
            optinRules,
            countryCode,
            checkboxIds,
            checkboxNames,
            dataTestValues,
            overrideChecked,
            errors,
        });
    }

    /**
     * Handles building the view model and rendering the optins template.
     * @param {array} optinRules
     * @param {string} countryCode
     * @param {array} checkboxIds
     * @param {array} checkboxNames
     * @param {array} dataTestValues
     * @param {array} overrideChecked
     */
    renderOptins({
        optinRules,
        countryCode,
        checkboxIds = null,
        checkboxNames = null,
        dataTestValues = null,
        overrideChecked = null,
        errors = null,
    }) {
        const vm = {
            countryCode,
            checkboxIds: checkboxIds || (this.dataset.cboxIds ? this.dataset.cboxIds.split(',') : null),
            dataTestValues: dataTestValues || (this.dataset.testValues ? this.dataset.testValues.split(',') : null),
            checkboxNames: checkboxNames || (this.dataset.cboxNames ? this.dataset.cboxNames.split(',') : null),
            optinRules,
            overrideChecked:
                overrideChecked || (this.dataset.overrideChecked ? this.dataset.overrideChecked.split(',') : null),
            errors: errors || (this.dataset.errors ? this.dataset.errors.split(';') : null),
        };

        this.innerHTML = templates[`${this.getAttribute('template')}`](vm);
    }

    /**
     * Handles updates to this component. Most likely listens for a country
     * field to send a change event.
     * @param {object} ev
     */
    onUpdated(ev) {
        const { detail } = ev;

        if (detail.type === this.updateOnEvent) {
            const countrySelect = detail.srcEvent ? detail.srcEvent.target : detail.trigger;
            const countryCode = countrySelect.value;

            if (countryCode === '' || !countryCode) {
                this.innerHTML = '';
                return;
            }

            this.updateOptins(countryCode);
        }
    }
}

export default PFDCOptinsElement;
