import { trackEvent } from '../../../core/scripts/analytics';
import { analyticsFilterSocialAuthenticationInline } from './social-login';
import {
    CONTEXT,
    CONTEXT_NAMES_FOR_ANALYTICS,
    TRIGGER_ELEMENTS,
} from '../react-components/specifics/LoginLauncher/utilities/copyMap';

const ONBOARDING_QUIZ_CATEGORY = 'onboarding quiz';

/**
 * Petfinder Consumer 447
 * Page: Home hero
 * Action: User selects "CREATE PROFILE" from Hero Image on Home Page/Screen
 *
 * @public
 * @event PetfinderConsumer447
 */
export function analyticsOnboardingQuiz447() {
    trackEvent({
        category: ONBOARDING_QUIZ_CATEGORY,
        action: 'create profile',
        label: 'home hero',
        event: 'Petfinder Consumer 447 - Onboarding Quiz',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 447 AND Social Login 006
 * Page: Home hero
 * Action: User selects "CREATE PROFILE" from Hero Image on Home Page/Screen AND needs to login
 *
 * @public
 * @event analyticsOnboardingQuiz447_socialLogin006
 */
export function analyticsOnboardingQuiz447_socialLogin006() {
    analyticsOnboardingQuiz447();

    analyticsFilterSocialAuthenticationInline({
        trigger: {
            dataset: {
                provider: 'petfinder',
                feature: CONTEXT_NAMES_FOR_ANALYTICS[CONTEXT.HOME_HERO],
                flowlabel: 'log in',
                uielement: TRIGGER_ELEMENTS.homeHeroCreateAccount,
            },
        },
    });
}
