import BaseCollection from './BaseCollection';

export default class BaseModel {
    _data = this.defaultData;

    get defaultData() {
        return {};
    }

    constructor(data) {
        if (data) {
            Object.assign(this._data, data);
        }

        // TODO: how do we feel about this?
        // set a timeout so after the constructor has finished, we have a hook
        setTimeout(() => {
            this._afterConstructor();
        }, 0);
    }

    _afterConstructor() {
        Object.seal(this._data);
    }

    get raw() {
        return this._getRawCopy(this._data);
    }

    _getRawCopy(thing) {
        if (thing instanceof Array) {
            return this._getRawCopyArr(thing);
        } else if (typeof thing === 'object') {
            return this._getRawCopyObj(thing);
        }

        return thing;
    }

    _getRawCopyArr(arr) {
        return arr.map(item => this._getRawCopy(item));
    }

    _getRawCopyObj(obj) {
        if (obj instanceof BaseCollection) {
            return obj.raw;
        } else if (obj instanceof BaseModel) {
            return obj.raw;
        }

        const retObj = {};

        for (const key in obj) {
            retObj[key] = this._getRawCopy(obj[key]);
        }

        return retObj;
    }
}
