import { trackEvent } from '../../../core/scripts/analytics';
import SearchAndMatchEventModel from '../../../core/scripts/analytics/models/search-and-match/SearchAndMatchEventModel';
import AnimalSearchEventModel from '../../../core/scripts/analytics/models/animal-search/AnimalSearchEventModel';
import UserInfoEventModel from '../../../core/scripts/analytics/models/user-info/UserInfoEventModel';
import _get from 'lodash/get';

const SEARCH_AND_MATCH_CATEGORY = 'search and match';

/**
 * Petfinder Consumer 347
 * Page: Homepage
 * Action: User clicks, "Take the Quiz" from the Petfinder home page, this event will double fire with ID #345
 *
 * @public
 * @event PetfinderConsumer347
 */
export function analyticsSearchAndMatch347() {
    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: 'take the quiz',
        label: 'homepage',
        event: 'Petfinder Consumer 347 - Search and Match',
        dimensions: {},
        additional: {},
    });
}

export function analyticsFindYourBstMacthGa4(animalType) {
    // PFPROD-4085 - GA4 Analytics - Search - Find Your Best Match
    /* eslint-disable camelcase, no-undefined */
    window.dataLayer.push(
        {
            event: 'quiz_start',
            ga4: true,
            event_params: {
                method: 'search results',
                pet_species: animalType,
            },
        },
        { event_params: undefined }
    );
}

/**
 * Petfinder Consumer 348
 * Page: Dog Search Page
 * Action: User clicks, "Take the Quiz" from the dog search page, this event will double fire with ID #346
 *
 * @public
 * @event PetfinderConsumer348
 */
export function analyticsSearchAndMatch348() {
    const quizType = 'dog';
    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: 'take the quiz',
        label: `${quizType} search page`,
        event: 'Petfinder Consumer 348 - Search and Match',
        dimensions: {},
        additional: {},
    });

    analyticsFindYourBstMacthGa4(quizType);
}

/**
 * Petfinder Consumer 348 - Cat
 * Page: Cat Search Page
 * Action: User clicks, "Take the Quiz" from the cat search page, this event will double fire with ID #346
 *
 * @public
 * @event PetfinderConsumer348
 */
export function analyticsSearchAndMatch348Cat() {
    const quizType = 'cat';
    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: 'take the quiz',
        label: `${quizType} search page`,
        event: 'Petfinder Consumer 348 - Search and Match',
        dimensions: {},
        additional: {},
    });

    analyticsFindYourBstMacthGa4(quizType);
}

/**
 * Petfinder Consumer 349
 * Page: Search & Match quiz
 * Action: Fire this event when the first page of the quiz loads
 *
 * @public
 * @event PetfinderConsumer349
 * @param {string} quizType
 */
export function analyticsSearchAndMatch349(quizType = 'dog') {
    const analytics = SearchAndMatchEventModel.prepareEvent({
        step: 'search and match: step 01-initiate',
    });

    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: 'initiate',
        label: quizType,
        event: 'Petfinder Consumer 349 - Search and Match',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 350
 * Page: Search & Match quiz
 * Action: User clicks, "next question" on number of pets, children in household;  Fire individual events for each click the user makes and then a single summary event with the concatenated values
 *
 * @public
 * @event PetfinderConsumer350
 * @param {Object} analyticData
 */
export function analyticsSearchAndMatch350(analyticData) {
    const { label, dimensions, additional } = analyticData;
    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: 'number of pets and/or children',
        label,
        event: 'Petfinder Consumer 350 - Search and Match',
        dimensions,
        additional,
    });
}

/**
 * Petfinder Consumer 351
 * Page: Search & Match quiz
 * Action: Fire this event when the first page of the quiz loads
 *
 * @public
 * @event PetfinderConsumer351
 * @param {Object} analyticData
 * @param {string} quizType
 */
export function analyticsSearchAndMatch351(analyticData, quizType = 'dog') {
    const { label, dimensions, additional } = analyticData;
    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType} size`,
        label,
        event: 'Petfinder Consumer 351 - Search and Match',
        dimensions,
        additional,
    });
}

/**
 * Petfinder Consumer 352
 * Page: Search & Match quiz
 * Action: Fire this event when the first page of the quiz loads
 *
 * @public
 * @event PetfinderConsumer352
 * @param {Object} analyticData
 * @param {string} quizType
 */
export function analyticsSearchAndMatch352(analyticData, quizType = 'dog') {
    const { label, dimensions, additional } = analyticData;
    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType} age`,
        label,
        event: 'Petfinder Consumer 352 - Search and Match',
        dimensions,
        additional,
    });
}

/**
 * Petfinder Consumer 353
 * Page: Search & Match quiz
 * Action: User clicks, "next question" on gender preference page
 *
 * @public
 * @event PetfinderConsumer353
 * @param {Object} formData
 * @param {string} quizType
 */
export function analyticsSearchAndMatch353(formData, quizType = 'dog') {
    const { gender } = formData;
    const selectedGender = {
        either: 'any',
        male: 'male',
        female: 'female',
    }[gender];

    const animalSearchAnalytics = AnimalSearchEventModel.prepareEvent({
        gender: selectedGender,
    });
    const searchAndMatchAnalytics = SearchAndMatchEventModel.prepareEvent({
        step: `search and match: step 04-${quizType} gender`,
    });

    const dimensions = {
        ...animalSearchAnalytics.dimensions,
        ...searchAndMatchAnalytics.dimensions,
    };
    const additional = {
        ...animalSearchAnalytics.dataLayer,
        ...searchAndMatchAnalytics.dataLayer,
    };

    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType} gender`,
        label: selectedGender,
        event: 'Petfinder Consumer 353 - Search and Match',
        dimensions,
        additional,
    });
}

/**
 * Petfinder Consumer 354
 * Page: Search & Match quiz
 * Action: User clicks, "next question" on potty trained preference
 *
 * @public
 * @event PetfinderConsumer354
 * @param {Object} formData
 * @param {string} quizType
 */
export function analyticsSearchAndMatch354(formData, quizType = 'dog') {
    const { attribute } = formData;
    const selectedAttribute = {
        either: 'any',
        open: 'open to training',
        mustBeTrained: 'must be trained',
    }[attribute];

    const animalSearchAnalytics = AnimalSearchEventModel.prepareEvent({
        attribute: selectedAttribute,
    });
    const searchAndMatchAnalytics = SearchAndMatchEventModel.prepareEvent({
        step: `search and match: step 05-${quizType} house trained`,
    });

    const dimensions = {
        ...animalSearchAnalytics.dimensions,
        ...searchAndMatchAnalytics.dimensions,
    };
    const additional = {
        ...animalSearchAnalytics.dataLayer,
        ...searchAndMatchAnalytics.dataLayer,
    };

    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType} house trained`,
        label: selectedAttribute,
        event: 'Petfinder Consumer 354 - Search and Match',
        dimensions,
        additional,
    });
}

/**
 * Petfinder Consumer 355
 * Page: Search & Match quiz
 * Action: User clicks, "view matches" on dogs near you page
 *
 * @public
 * @event PetfinderConsumer355
 * @param {Object} formData
 * @param {string} quizType
 */
export function analyticsSearchAndMatch355(formData, quizType = 'dog') {
    const selectedLocation = _get(formData, 'location.slug', '');

    const animalSearchAnalytics = AnimalSearchEventModel.prepareEvent({
        location: selectedLocation,
        filterOutNullText: false,
    });
    const searchAndMatchAnalytics = SearchAndMatchEventModel.prepareEvent({
        step: `search and match: step 06-${quizType} location`,
    });

    const dimensions = {
        ...animalSearchAnalytics.dimensions,
        ...searchAndMatchAnalytics.dimensions,
    };
    const additional = {
        ...animalSearchAnalytics.dataLayer,
        ...searchAndMatchAnalytics.dataLayer,
    };

    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType} location`,
        label: selectedLocation,
        event: 'Petfinder Consumer 355 - Search and Match',
        dimensions,
        additional,
    });
}

/**
 * Petfinder Consumer 356
 * Page: Search & Match quiz
 * Action: User selects distance mileage from dropdown
 *
 * @public
 * @event PetfinderConsumer356
 * @param {Object} formData
 * @param {string} quizType
 */
export function analyticsSearchAndMatch356(formData, quizType = 'dog') {
    const { distance } = formData;

    const analytics = AnimalSearchEventModel.prepareEvent({
        distance,
    });

    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType} location`,
        label: distance,
        event: 'Petfinder Consumer 356 - Search and Match',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 359
 * Page: Search & Match quiz
 * Action: User clicks, "Skip and view my matches"
 *
 * @public
 * @event PetfinderConsumer359
 * @param {string} quizType
 */
export function analyticsSearchAndMatch359(quizType = 'dog') {
    const analytics = SearchAndMatchEventModel.prepareEvent({
        step: `search and match: step 07-${quizType} completed`,
    });

    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType}: skip and view matches`,
        label: 'ready to see matches',
        event: 'Petfinder Consumer 359 - Search and Match',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 360
 * Page: Search & Match quiz
 * Action: "Your search was saved" page load
 *
 * @public
 * @event PetfinderConsumer360
 * @param {string} quizType
 */
export function analyticsSearchAndMatch360() {
    const analytics = SearchAndMatchEventModel.prepareEvent({
        step: 'search and match: step 07 - dog completed',
    });

    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: 'completed',
        label: 'dog',
        event: 'Petfinder Consumer 360 - Search and Match',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Petfinder Consumer 364
 * Page: Search & Match quiz
 * Action: User clicks, "Cancel" in upper left hand corner of quiz
 *
 * @public
 * @event PetfinderConsumer364
 * @param {string} slideName
 * @param {string} quizType
 */
export function analyticsSearchAndMatch364(slideName, quizType = 'dog') {
    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType} cancel`,
        label: slideName,
        event: 'Petfinder Consumer 364 - Search and Match',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 365
 * Page: Search & Match quiz
 * Action: User clicks, "yes/no" from cancel page
 *
 * @public
 * @event PetfinderConsumer365
 * @param {string} selection
 * @param {string} quizType
 */
export function analyticsSearchAndMatch365(selection, quizType = 'dog') {
    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType} cancel`,
        label: selection,
        event: 'Petfinder Consumer 365 - Search and Match',
        dimensions: {},
        additional: {},
    });
}

/**
 * Petfinder Consumer 366
 * Page: Search & Match quiz
 * Action: User clicks "X" from cancel page
 *
 * @public
 * @event PetfinderConsumer366
 * @param {string} quizType
 */
export function analyticsSearchAndMatch366(quizType = 'dog') {
    trackEvent({
        category: SEARCH_AND_MATCH_CATEGORY,
        action: `${quizType} cancel`,
        label: 'close out X',
        event: 'Petfinder Consumer 366 - Search and Match',
        dimensions: {},
        additional: {},
    });
}

/**
 * Collection Search And Match Step 1
 * Page: Search And Match Step 1 - Household Data
 * Action: User clicks 'Next Question'
 *
 * @public
 * @event analyticsCollectionSearchAndMatchStep1
 * @fires PetfinderConsumer350
 * @param {Object} formData
 */
export function analyticsCollectionSearchAndMatchStep1(formData) {
    const { household } = formData;
    const householdKeys = Object.keys(household);
    const animalTypeKeys = ['dogs', 'cats'];

    const goodWith = householdKeys
        .filter(key => household[key] > 0)
        .map(key => `good_with_${key}`)
        .join(', ');

    const householdCount = animalTypeKeys.reduce((total, key) => {
        return total + household[key];
    }, 0);

    let petTypesInHome;
    if (household.dogs && household.cats) {
        petTypesInHome = 'both';
    } else if (household.cats) {
        petTypesInHome = 'cat';
    } else if (household.dogs) {
        petTypesInHome = 'dog';
    } else {
        petTypesInHome = 'neither';
    }

    const animalSearchAnalytics = AnimalSearchEventModel.prepareEvent({
        household: goodWith,
        filterOutNullText: false,
        nullText: '',
    });
    const userInfoAnalytics = UserInfoEventModel.prepareEvent({
        petHouseholdNumber: householdCount,
        petHouseholdDogNumber: household.dogs,
        petHouseholdCatNumber: household.cats,
        petType: petTypesInHome,
    });

    const dimensions = {
        ...animalSearchAnalytics.dimensions,
        ...userInfoAnalytics.dimensions,
    };
    const additional = {
        ...animalSearchAnalytics.dataLayer,
        ...userInfoAnalytics.dataLayer,
    };

    // Send 1 event per household key
    householdKeys.forEach(key => {
        const label = `${key} count: ${household[key]}`;
        analyticsSearchAndMatch350({ label, dimensions, additional });
    });

    // Send 1 more event with concatenated label
    const label = householdKeys.map(key => `${key} count: ${household[key]}`).join(', ');
    analyticsSearchAndMatch350({ label, dimensions, additional });
}

/**
 * Collection Search And Match Step 2
 * Page: Search And Match Step 2 - Size Data
 * Action: User clicks 'Next Question'
 *
 * @public
 * @event analyticsCollectionSearchAndMatchStep2
 * @fires PetfinderConsumer351
 * @param {Object} formData
 * @param {string} quizType
 */
export function analyticsCollectionSearchAndMatchStep2(formData, quizType = 'dog') {
    const { size } = formData;
    const sizeKeys = Object.keys(size);
    const selectedSizes = sizeKeys.filter(key => size[key] === true).join(', ');

    const animalSearchAnalytics = AnimalSearchEventModel.prepareEvent({
        size: selectedSizes,
    });
    const searchAndMatchAnalytics = SearchAndMatchEventModel.prepareEvent({
        step: `search and match: step 02-${quizType} size`,
    });

    const dimensions = {
        ...animalSearchAnalytics.dimensions,
        ...searchAndMatchAnalytics.dimensions,
    };
    const additional = {
        ...animalSearchAnalytics.dataLayer,
        ...searchAndMatchAnalytics.dataLayer,
    };

    // Send 1 event per household key
    sizeKeys.forEach(key => {
        const label = `size ${key}: ${size[key]}`;
        analyticsSearchAndMatch351({ label, dimensions, additional }, quizType);
    });

    // Send 1 more event with concatenated label
    const label = selectedSizes;
    analyticsSearchAndMatch351({ label, dimensions, additional }, quizType);
}

/**
 * Collection Search And Match Step 3
 * Page: Search And Match Step 3 - Age Data
 * Action: User clicks 'Next Question'
 *
 * @public
 * @event analyticsCollectionSearchAndMatchStep3
 * @fires PetfinderConsumer352
 * @param {Object} formData
 * @param {string} quizType
 */
export function analyticsCollectionSearchAndMatchStep3(formData, quizType = 'dog') {
    const category = 'search and match';
    const action = 'dog age';
    const { age } = formData;
    const ageKeys = Object.keys(age);
    const selectedAges = ageKeys.filter(key => age[key] === true).join(', ');

    const animalSearchAnalytics = AnimalSearchEventModel.prepareEvent({
        age: selectedAges,
    });
    const searchAndMatchAnalytics = SearchAndMatchEventModel.prepareEvent({
        step: `search and match: step 03-${quizType} age`,
    });

    const dimensions = {
        ...animalSearchAnalytics.dimensions,
        ...searchAndMatchAnalytics.dimensions,
    };
    const additional = {
        ...animalSearchAnalytics.dataLayer,
        ...searchAndMatchAnalytics.dataLayer,
    };

    // Send 1 event per household key
    ageKeys.forEach(key => {
        const label = `age ${key}: ${age[key]}`;
        analyticsSearchAndMatch352({ label, dimensions, additional }, quizType);
    });

    // Send 1 more event with concatenated label
    const label = selectedAges;
    analyticsSearchAndMatch352({ label, dimensions, additional }, quizType);
}
