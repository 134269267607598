import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../../breeds/specifics/SvgIcon';

import { analyticsConsumer011 } from '../../../../analytics/dotcom';

export default function RegistrationLayout(props) {
    /**
     * @type {Object}
     */
    const propTypes = {
        renderGoogleButton: PropTypes.func.isRequired,
        renderFacebookButton: PropTypes.func.isRequired,
        onSocialClick: PropTypes.func.isRequired,
    };

    return (
        <div className="loginModal loginModal_register" data-test="User_Registration_Modal">
            <div className="loginModal-section">
                <div className="split split_stack@maxLg">
                    <div className="split-item">
                        <h2
                            className="loginModal-section-hdg u-vr6x"
                            tabIndex="-1"
                        >
                            {'Sign up'}
                        </h2>
                    </div>
                    <div className="split-item">
                        <div className="split split_stack@maxLg split_gutterSm u-vr4x">
                            <div className="split-item split-item_grow">
                                <span className="txt m-txt_lg">
                                    {'Continue with:'}
                                </span>
                            </div>
                            <div className="split-item">
                                <div className="hrArray m-hrArray_2x m-hrArray_alignMiddle">
                                    <div>
                                        {props.renderFacebookButton({
                                            cta: '',
                                            type: 'inline',
                                            onSocialClick: params => {
                                                analyticsConsumer011(params);
                                            },
                                        })}
                                    </div>
                                    <div>
                                        {props.renderGoogleButton({
                                            cta: '',
                                            type: 'inline',
                                            onSocialClick: params => {
                                                analyticsConsumer011(params);
                                            },
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {props.children}
            </div>
        </div>
    );
}
