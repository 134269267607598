
import Mixins from '../Mixins';
import MixinBase from './MixinBase';
import Utils from '../Utils';

export default class DisabledMixin extends MixinBase {
    static _attributeName = 'mix-disabled';

    constructor(ele, value) {
        super(ele, value);

        const result = Utils.HTMLElementUtils.findElementContainingAddress(value, ele, true);

        if (result.element && result.value) {
            this._ele.setAttribute('disabled', 'disabled');
        } else {
            this._ele.removeAttribute('disabled', 'disabled');
        }
    }
}
