import t from 'tcomb-form';

/**
 * @type {Object}
 */
export const FIELDS = {
    FIRST_NAME: 'user_registration_token[firstName]',
    LAST_NAME: 'user_registration_token[lastName]',
    COUNTRY: 'user_registration_token[address][country]',
    POSTAL_CODE: 'user_registration_token[address][postalCode]',
    NEXT: 'next',
    NEXT_PARAMS: 'next_params',
    TOKEN: 'user_registration_token[token]',
    PROVIDER: 'user_registration_token[provider]',

    // Optin field names
    OPTIN_PETFINDER: 'user_registration_token[emailOptIn][petfinder]',
    OPTIN_SPONSOR: 'user_registration_token[emailOptIn][sponsor]',

    // Optional initiating source
    SOURCE: 'user_registration_token[source]',
};

/**
 * @type {Object}
 */
export default t.struct({
    [FIELDS.FIRST_NAME]: t.String,
    [FIELDS.LAST_NAME]: t.String,
    [FIELDS.POSTAL_CODE]: t.String,
    [FIELDS.NEXT]: t.maybe(t.String),
    [FIELDS.NEXT_PARAMS]: t.maybe(t.String),
    [FIELDS.TOKEN]: t.String,
    [FIELDS.PROVIDER]: t.String,
    [FIELDS.SOURCE]: t.maybe(t.String),
});
