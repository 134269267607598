
import Mixins from '../Mixins';
import MixinBase from './MixinBase';
import Utils from '../Utils';
import siteStateController from '../../../../../dotcom/scripts/state-controllers/siteStateController';


// TODO: This mixin shouldn't be used inside of elements that get re-rendered as it does not unsubscribe from the state controller
export default class StateClassMixin extends MixinBase {
    static _attributeName = 'mix-state-class';

    constructor(ele, value) {
        super(ele, value);

        const classAddressMap = Utils.StringUtils.parseAttributeValue(value);

        // eslint-disable-next-line guard-for-in
        for (const className in classAddressMap) {
            let address = classAddressMap[className];
            let invert = false;

            if (address.charAt(0) === '!') {
                invert = true;
                address = address.substring(1);
            }

            siteStateController.subscribe(payload => {
                let newValue = payload.flattenedModifications[address];

                if (invert) {
                    newValue = !newValue;
                }

                if (newValue) {
                    ele.classList.add(className);
                } else {
                    ele.classList.remove(className);
                }
            }, address);
        }
    }
}
