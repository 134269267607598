/* eslint-disable camelcase */
import { pollUntilConditionMet } from '../../../../../../core/scripts/util/util';
import socialLoginStateController from '../controller/SocialLoginStateController';
import socialLoginService from './SocialLoginService';

import Config from '../../../../../../core/scripts/lib/Config';

class GoogleAPIService {
    /* *******************************************
     * Google methods
     ********************************************/

    /**
     * Returns the GoogleAuth object after auth2 is initialized
     * @return {Object} googleAuth
     * @private
     */
    _getGoogleAuth = async () => {
        try {
            await pollUntilConditionMet(
                () => {
                    return window.gapi;
                },
                20,
                10000
            );

            if (!Config.page.google_client_id) {
                return;
            }

            const params = {
                client_id: Config.page.google_client_id,
                cookie_policy: 'single_host_origin',
                fetch_basic_profile: true,
                scope: 'profile email',
            };

            window.gapi.load('auth2', () => {
                window.gapi.auth2
                    .init({
                        client_id: params.client_id,
                        cookie_policy: params.cookie_policy,
                    })
                    .then(googleAuth => {
                        this._getGoogleUserData(googleAuth);

                        socialLoginStateController.patchState({
                            APIReady: {
                                google: true,
                            },
                        });
                    });
            });
        } catch (e) {
            console.error('gapi unavailable');
        }
    };

    /**
     * Callback for the GoogleAuthorization.
     * Prepare google user data for consumption.
     *
     * @method _getGoogleUserData
     * @param {Object} googleAuth object
     * @private
     */
    _getGoogleUserData = googleAuth => {
        const user = googleAuth.currentUser.get();
        const scopes = user.getGrantedScopes();

        if (googleAuth.isSignedIn.get()) {
            this.getBasicGoogleProfileData(user);

            // If a user is signed in and has previously granted scopes, assume returning user.
            if (scopes != null) {
                socialLoginStateController.patchState({
                    returningUsers: {
                        google: true,
                    },
                });
            }
        }
    };

    /**
     * Gets basic profile data from Google
     * @param {Object} user
     */
    getBasicGoogleProfileData = user => {
        const profile = user.getBasicProfile();

        let image = profile.getImageUrl();
        image = image.replace('s96-c', 's160-c');

        const googleUser = {
            profileData: {
                email: profile.getEmail(),
                name: profile.getName(),
                firstName: profile.getGivenName(),
                lastName: profile.getFamilyName(),
                image,
            },
        };

        socialLoginStateController.patchState({
            userData: {
                google: googleUser,
            },
        });

        return googleUser.profileData;
    };

    /**
     * @type {Function}
     * @param {Object} params
     * Attach click handler to the gapi instance, provide callbacks
     */
    attachClickHandler = params => {
        const { button, next, next_params, context } = params;
        // User will be prompted to confirm the account they'd like to use
        const options = {
            prompt: 'consent',
        };

        // Give callback to gapi signIn
        socialLoginStateController.googleAuth.attachClickHandler(
            button,
            options,
            googleUser => {
                socialLoginStateController.refocusTarget = button;
                // if next values are provided, set state
                if (next) {
                    socialLoginStateController.authenticationSuccessParameters = {
                        next,
                        nextParams: next_params,
                    };
                }

                if (context) {
                    socialLoginStateController.patchState({
                        modal: {
                            modalState: {
                                contextName: context,
                            },
                        },
                    });
                }
                this.handleGoogleSuccess(googleUser);
            },
            error => {
                socialLoginStateController.refocusTarget = button;
                if (error.error === 'popup_closed_by_user') {
                    // closed by user
                } else {
                    // opens login modal if not yet open
                    if (!socialLoginStateController.state.modal.alreadyOpen) {
                        socialLoginStateController.patchState({
                            modal: {
                                triggerOpen: true,
                            },
                        });
                    }
                    // eslint-disable-next-line quotes
                    socialLoginStateController.patchState({
                        step: 'error',
                        error: "couldn't sign in to Google",
                    });
                }
            }
        );
    };

    /**
     * @type {Function}
     * Successfully logged in to Google, attempt PF login
     * @param {Object} user
     */
    handleGoogleSuccess = user => {
        // opens login modal if not yet open
        if (!socialLoginStateController.state.modal.alreadyOpen) {
            socialLoginStateController.openLoginModal({});
        }
        socialLoginStateController.patchState({
            step: 'loading',
        });
        const id_token = user.getAuthResponse().id_token;
        const profile = this.getBasicGoogleProfileData(user);

        socialLoginStateController.patchState({
            user: profile,
            activeProvider: 'google',
        });

        socialLoginService.signInToPF(id_token, profile, 'google');
    };
}

export default new GoogleAPIService();
