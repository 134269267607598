import BaseAnalyticEventModel from '../BaseAnalyticEventModel';
import AnimalSearchPropertiesModel from './AnimalSearchPropertiesModel';

export default class AnimalSearchEventModel extends BaseAnalyticEventModel {
    _defaultNullText = 'not set';

    get defaultAnalyticProperties() {
        return {
            animalType: true,
            location: true,
            breed: true,
            age: true,
            gender: true,
            shelter: true,
            distance: true,
            size: true,
            coatLength: true,
            color: true,
            attribute: true,
            household: true,
            daysOnPetfinder: true,
            animalName: true,
            species: true,
            initiatingSite: true,
            transportable: true,
            petMatchType: true,
        };
    }

    /**
     * prepareEvent
     *
     * @static
     * @param {Object} eventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {AnimalSearchEventModel}
     */
    static prepareEvent(
        eventData,
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const model = new AnimalSearchEventModel(
            this._mapEventData(eventData),
            analyticProperties,
            overrideAnalyticProperties
        );
        model.analyticObjects = eventData;

        return model;
    }

    /**
     * fromAnimalSearchFilters
     *
     * @static
     * @param {Object} filters
     * @param {Object} additionalEventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {AnimalSearchEventModel}
     */
    static fromAnimalSearchFilters(
        filters,
        additionalEventData = {},
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const eventData = {
            ...this.mapAnimalSearchFilters(filters),
            nullText: 'no filter applied',
            ...additionalEventData,
        };
        return this.prepareEvent(
            eventData,
            analyticProperties,
            overrideAnalyticProperties
        );
    }

    /**
     * mapAnimalSearchFilters
     *
     * @param {Object} filters
     *
     * @return {Object}
     */
    static mapAnimalSearchFilters = filters => {
        return {
            animalType: filters.animalType,
            location: filters.location,
            breed: filters.breed,
            age: filters.age,
            gender: filters.gender,
            shelter: filters.shelter,
            distance: filters.distance,
            size: filters.size,
            coatLength: filters.coatLength,
            color: filters.color,
            attribute: filters.attribute,
            household: filters.household,
            daysOnPetfinder: filters.daysOnPetfinder,
            animalName: filters.animalName,
            species: filters.species,
            initiatingSite: filters.initiatingSite,
            transportable: filters.transportable,
        };
    };

    _buildProperties(analytics, nullText) {
        return new AnimalSearchPropertiesModel(analytics, nullText);
    }
}
