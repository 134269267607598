import React, { Component } from 'react';

import { analyticsConsumer018A } from '../../../../../analytics/dotcom';

class SocialReauthenticateLayout extends Component {
    /**
     * @param {Event} ev
     */
    handleSocialReauthenticate = () => {
        this.props.onSocialReauthenticate();
    };

    /**
     * @param {Event} ev
     */
    handleCancelSignUp = () => {
        this.props.onCancel();
    };

    render() {
        return (
            <div className="loginModal">
                <div className="loginModal-section">
                    <h2 className="loginModal-section-hdg u-vr6x" tabIndex="-1">
                        {'Petfinder needs your email'}
                    </h2>
                    <p className="txt m-txt_lg u-vr8x">
                        We need your email to send you account notices and
                        helpful articles, as well as any pet updates you
                        request.
                    </p>
                    {this.props.renderFacebookButton({
                        reauthenticate: true,
                        onSocialClick: params => {
                            analyticsConsumer018A(params);
                        },
                    })}
                </div>
                <div className="loginModal-section loginModal-section_footer">
                    <button
                        onClick={this.handleCancelSignUp}
                        className="txt txt_link m-txt_lg m-txt_underline"
                    >
                        {'Cancel signup'}
                    </button>
                </div>
            </div>
        );
    }
}

export default SocialReauthenticateLayout;
