import EventActionMixinBase from './EventActionMixinBase';
import ActionParser from '../ActionParser';

export default class FocusoutMixin extends EventActionMixinBase {
    static _attributeName = 'mix-focusout';

    onFocusoutEvent(e) {
        ActionParser.executeActions(this._actions, { $mixin: this, $element: this._ele, $event: e }, e);
    }
}
