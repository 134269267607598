import { trackEvent } from '../../../core/scripts/analytics';
import UserLoginRegistrationEventModel from '../../../core/scripts/analytics/models/user-login-registration/UserLoginRegistrationEventModel';

// TODO: Confirm the use/need of these constants, pulled in from dotcom.js
import {
    ANALYTIC_STATE_LABELS,
    ANALYTIC_FLOW_LABELS,
} from '../react-components/specifics/LoginLauncher/statechart';
import { CONTEXT_NAMES_FOR_ANALYTICS } from '../react-components/specifics/LoginLauncher/utilities/copyMap';

/**
 * Social Login 002
 * Page: Anywhere sign up is initiated
 * Action: Clicks 'Sign up' Button
 *
 * @public
 * @event SocialLogin002
 * @param {Object} data
 */
export function analyticsSocialLogin002(data) {
    const analytics = UserLoginRegistrationEventModel.prepareEvent(data);

    trackEvent({
        category: 'authentication',
        action: 'sign up',
        label: 'initiate',
        event: 'Social Login 002',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * Social Login 006
 * Page: Anywhere log in is initiated
 * Action: Clicks, "Login" Button
 *
 * @public
 * @event SocialLogin006
 * @param {Object} data
 */
export function analyticsSocialLogin006(data) {
    const analytics = UserLoginRegistrationEventModel.prepareEvent(data);

    trackEvent({
        category: 'authentication',
        action: 'log in',
        label: 'initiate',
        event: 'Social Login 006',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
}

/**
 * analyticsFilterSocialAuthentication
 * Page: N/A
 * Action: User clicks authentication prompt
 *
 * @public
 * @fires SocialLogin002
 * @fires SocialLogin006
 *
 * @event analyticsFilterSocialAuthentication
 * @param {Object} params
 */
export function analyticsFilterSocialAuthentication(params) {
    const { provider, view, context } = params;
    const flowlabel = ANALYTIC_FLOW_LABELS[view];
    // TEMP - Remove console warn once all Consumer002 analytics are replaced and checked
    if (!flowlabel) {
        console.warn('flowlabel not set - no analytic event will fire', params);
    }
    const data = {
        authenticationStatus: 'not logged in',
        loginProvider: provider, // 'authenticationProvider'
        initiatingSite: CONTEXT_NAMES_FOR_ANALYTICS[context],
        // TEMP - reinstate once Consumer002 analytics are replaced and checked, this isn't set properly on dotcom.js
        // initiatingUI: initiatingUIElement,
        currentScreen: `${ANALYTIC_STATE_LABELS[view] || view} modal`,
    };

    switch (flowlabel) {
        case 'sign up':
            analyticsSocialLogin002(data);
            break;
        case 'log in':
            analyticsSocialLogin006(data);
            break;
    }
}

/**
 * analyticsFilterSocialAuthenticationInline
 * Page: N/A
 * Action: User clicks inline authentication prompt
 *
 * @public
 * @fires SocialLogin002
 * @fires SocialLogin006
 *
 * @event analyticsFilterSocialAuthenticationInline
 * @param {Object} event
 */
export function analyticsFilterSocialAuthenticationInline(event) {
    const { provider, feature, flowlabel, uielement } = event.trigger.dataset;
    const data = {
        authenticationStatus: 'not logged in',
        loginProvider: provider,
        initiatingSite: feature,
        initiatingUI: uielement,
    };
    switch (flowlabel) {
        case 'sign up':
            analyticsSocialLogin002(data);
            break;
        case 'log in':
            analyticsSocialLogin006(data);
            break;
    }
}
