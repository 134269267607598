import { PFDCFormElement } from '../pfdc-form/element';
import { escapeSelector } from '../../../../core/scripts/util/dom';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-registration-form';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    // INPUT: `${ELEMENT_TAG_NAME}-input`,
};

/**
 * Custom element extension of the pfdc-form to handle the registration of users.
 *
 * @extends PFDCFormElement
 */
export class PFDCRegistrationFormElement extends PFDCFormElement {
    /**
     * Event to listen for to update optins
     */
    get updateOptinsOnEvent() {
        if (!this.hasAttribute('update-optins-on')) {
            throw new Error(`PFDCRegistrationFormElement: You need to supply an update-optins-on
                event`);
        }
        return this.getAttribute('update-optins-on');
    }

    /**
     * Initialize this component
     */
    onInit() {
        super.onInit();

        // Manually set the next url field to the current url
        this.setNextUrl();
    }

    /**
     * Manually set the next url field to the current url
     */
    setNextUrl() {
        const nextField = this.form.querySelector('[name="next"]');
        nextField.value = window.location.href;
    }

    async submitToEndpoint(formData) {
        return await this.userRepository.register(formData);
    }

    /**
     * Handles country change events from the app level
     * @param {Object} detail event object
     */
    onCountryChange(detail) {
        this.countrySelected = detail.trigger.value;
    }

    /**
     * Reset the form
     */
    resetForm() {
        super.resetForm();
    }

    /**
     * Handles an succesful response response
     * @param {Object} response
     */
    handleSubmissionSuccess(response) {
        this.dispatchAction('analytics', {
            eventId: 'Consumer023',
            authenticated: true,
        });
        super.handleSubmissionSuccess(response);
    }

    /**
     * Handles an error response
     * @param {Object} response
     */
    handleSubmissionError(response) {
        this.dispatchAction('analytics', {
            eventId: 'Consumer023',
            authenticated: false,
        });
        super.handleSubmissionError(response);
    }

    /**
     * Handles update events from the app level
     * @param {Object} ev event object
     */
    onUpdated(ev) {
        super.onUpdated(ev);
        const { detail } = ev;
        if (detail.type === this.updateOptinsOnEvent) {
            this.onCountryChange(detail);
        }
    }
}

export default PFDCRegistrationFormElement;
