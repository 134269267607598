import React from 'react';
import ReactDOM from 'react-dom';
import LoginLauncherModal from '../../react-components/specifics/LoginLauncher/modal';
import PFDCLoginLauncherElement from '../../elements/pfdc-login-launcher/element';

export default function initSocialLogin() {
    const consumerLogin = document.querySelector('.js-loginLauncher');

    if (consumerLogin) {
        customElements.define('pfdc-login-launcher', PFDCLoginLauncherElement);
        ReactDOM.render(<LoginLauncherModal />, consumerLogin);
    }
}
