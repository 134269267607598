/**
 * Core JS
 * For various polyfills and regenerator runtime
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * Normalize KeyboardEvent
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent
 */
import keyboardeventKey from 'keyboardevent-key-polyfill';
keyboardeventKey.polyfill();

/**
 * Focus Visible
 * Adds mechanism for exposing focus visible styles only when the keyboard is the user's current
 * input modality. Adds .focus-visible class to elements.
 * https://github.com/WICG/focus-visible
 */
import 'focus-visible';

/**
 * Inert
 * https://github.com/WICG/inert
 */
import 'wicg-inert';

/**
 * Custom Elements
 */
import '@webcomponents/custom-elements';
