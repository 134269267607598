/**
 * Exports class constants which are shared strings.
 */

export const CLASS_IS_ACTIVE = 'u-isActive';
export const CLASS_IS_VISIBLE = 'u-isVisible';
export const CLASS_IS_CHECKED = 'u-isChecked';
export const CLASS_IS_CURRENT = 'u-isCurrent';
export const CLASS_IS_OPEN = 'u-isOpen';
export const CLASS_IS_SELECTED = 'u-isSelected';
export const CLASS_IS_FOCUSED = 'u-isFocused';
export const CLASS_IS_DISABLED = 'u-isDisabled';
export const CLASS_IS_LOADING = 'u-isLoading';
export const CLASS_IS_TRANSITIONING = 'u-isTransitioning';
export const CLASS_CHILD_IS_FOCUSED = 'u-childIsFocused';
export const CLASS_HAS_INPUT = 'u-hasInput';
export const CLASS_IS_HIDDEN = 'u-isHidden';
export const CLASS_IS_VISUALLY_HIDDEN = 'u-isVisuallyHidden';
export const CLASS_IS_ERRORED = 'u-isErrored';
export const CLASS_FIELD_IS_ERRORED = 'm-field_error';
export const CLASS_JS_NO_OBJECT_FIT = 'js-noObjectFit';
