import React, { Component } from 'react';
import { Action, State, withStateMachine } from 'react-automata';
import buildClassNames from 'classnames';
import _debounce from 'lodash/debounce';
import _noop from 'lodash/noop';

import ensightenTracking from '../../../../../core/scripts/elements/pf-app/ensighten/ensighten';
import { endOfEventLoop } from '../../../../../core/scripts/util/util';
import Config from '../../../../../core/scripts/lib/Config';
import geographyRepository from '../../../../../core/scripts/repositories/geography/geography';

import { focusFirstFocusable } from '../../../../../core/scripts/lib/focusManager/index';
import LiveRegionAlert from '../../../../../core/scripts/react-components/specifics/LiveRegionAlert';

import {
    KEY_ESCAPE,
    KEY_ESC,
} from '../../../../../core/scripts/constants/keyboard';

import Loader from '../Loader';

import GeoSearchComboBox from '../../../../../core/scripts/react-components/specifics/GeoSearchComboBox';

import LocationSuggestionItem from './components/LocationSuggestionItem';
import PetTypeButton from './components/PetTypeButton';
import LocationModal from './components/LocationModal';
import OtherPetTypesModal from './components/OtherPetTypesModal';

import {
    analyticsConsumer445,
    analyticsConsumer446,
} from '../../../../scripts/analytics/dotcom';

import {
    PETS,
    OTHER_PETS,
    LOCATION_INPUT_PLACEHOLDER,
    LOCATION_INPUT_ARIA_LABEL,
    LOCATION_ARIA_DESCRIPTION,
    LOCATION_NETWORK_ERROR_ALERT,
    LOCATION_PROMPT_TIMER_DURATION,
    SAVED_SEARCH_LIST_MAX_NUM_RESULTS,
    BUSY_STATES,
    SEARCH_URL_BASE,
    SEARCH_URL_PET_STATUS,
    SEARCH_URL_PARAMS,
    getAlertWithKey,
} from './constants';

import statechart from './statechart';

/**
 * Container component for find a pet menu
 * @class FindAPetMenu
 * @extends React.Component
 */
class FindAPetMenu extends Component {
    /**
     * @type {Object}
     */
    state = {
        locationInputValue: '',
        locationSlug: '',
        geoLocationPermission: null,
    };

    /**
     * @type {Object}
     */
    locationInput = React.createRef();

    /**
     * @type {Object}
     */
    otherPetsBtn = React.createRef();

    /**
     * @type {Object}
     */
    otherPetsDropdown = React.createRef();

    /**
     * @type {Object}
     */
    locationModal = React.createRef();

    /**
     * @type {Object}
     */
    debouncedHandleResize = _debounce(this.handleResize.bind(this), 250);

    /**
     * @type {Object}
     */
    debouncedFetchLocations = _debounce(this.fetchLocations.bind(this), 250);

    componentDidMount() {
        window.addEventListener('resize', this.debouncedHandleResize);

        // Would usually handle this call in the statechart, but there is a bug which means we
        // can't provide an onEntry value to the initial state of a parallel machine
        this.checkIfAuthorized();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedHandleResize);
    }

    componentDidTransition(prevState, ev) {
        switch (ev) {
            case 'CLOSE':
            case 'RESULT_CLICK':
                this.props.returnFocusTarget.focus();
                break;
            default:
                break;
        }
    }

    /**
     * Use geolocation to check for location authorization
     * @async
     * @private
     * @type {Object}
     */
    checkIfAuthorized = async () => {
        // eslint-disable-line
        let auth;
        if (navigator.geolocation && navigator.permissions) {
            await navigator.permissions
                .query({ name: 'geolocation' })
                .then(status => {
                    auth = status.state;
                });
        } else {
            auth = null;
        }

        this.setState({
            geoLocationPermission: auth,
        });

        if (auth === 'denied') {
            this.props.transition('AUTH_DENIED');
        }
    };

    /**
     * Triggers an initial resize that helps the statechart get into the right
     * mobile vs desktop state
     * @method setInitBreakpoint
     * @private
     */
    setInitBreakpoint() {
        this.props.transition('RESIZE');
    }

    /**
     * Build classnames for other pets button
     * @method getOtherButtonClassNames
     * @private
     * @returns {string}
     */
    getOtherButtonClassNames() {
        return buildClassNames({
            ['findAPetMenu-button']: true,
            ['findAPetMenu-button_other']: true,
            ['m-findAPetMenu-button_dropdownVisible']:
                this.props.machineState.value.otherPets === 'show',
        });
    }

    /**
     * Gets saved searches from user/me to display in location modal
     * if the current user is authenticated
     * @method fetchSavedSearches
     * @private
     */
    async fetchSavedSearches() {
        if (!Config.userAuthed || this.props.savedSearches) {
            return;
        }

        try {
            const savedSearches = await Config.savedSearches;

            this.props.transition('USER_SAVED_SEARCHES_SUCCESS', {
                savedSearches: savedSearches.slice(
                    0,
                    SAVED_SEARCH_LIST_MAX_NUM_RESULTS
                ),
            });
        } catch (err) {
            this.props.transition('USER_SAVED_SEARCHES_ERROR');
            console.warn(err);
        }
    }

    /**
     * Updates the input display value and location slug
     * @method setUserGeoLocation
     */
    setUserGeoLocation() {
        const locationInputValue = this.state.geoLocation.display_name;
        const locationSlug = this.state.geoLocation.slug;

        this.setState({
            locationInputValue,
            locationSlug,
        });
    }

    /**
     * Get locations based on users location attributes from user/me and the current
     * query they are typing in the location input
     * @method fetchLocations
     * @private
     */
    async fetchLocations() {
        const { locationInputValue } = this.props;

        try {
            const lat = await Config.usersLatitude;
            const lng = await Config.usersLongitude;
            const response = await geographyRepository.searchForLocations(
                locationInputValue,
                lat,
                lng
            );

            const locations = response.locations;
            const eventType =
                locations.length > 0
                    ? 'LOCATIONS_SUCCESS'
                    : 'LOCATIONS_NO_RESULTS';

            this.props.transition(eventType, { locations });
        } catch (err) {
            this.props.transition('LOCATIONS_FAILURE', err);
        }
    }

    /**
     * @method handleLocationNetworkError
     * @private
     */
    handleLocationNetworkError() {
        window.alert(LOCATION_NETWORK_ERROR_ALERT);
    }

    /**
     * Handle focusing the first item in the show other pets dropdown, also do some
     * overriding of the focus class
     * @method showOtherPetsDropdown
     * @private
     */
    showOtherPetsDropdown() {
        const focusableChildren = focusFirstFocusable(
            this.otherPetsDropdown.current,
            false
        );

        // Add focus ring class to first child since focus ring does not
        // work with programattic focus, focus ring removed on blur below
        focusableChildren[0].classList.add('u-overrideFocusRing');
    }

    /**
     * @method returnFocusOtherPetsBtn
     * @private
     */
    returnFocusOtherPetsBtn() {
        this.otherPetsBtn.current.focus();
    }

    /**
     * @method returnFocusToInput
     * @private
     */
    returnFocusToInput() {
        this.locationInput.current.focus();
    }

    returnFocusToModalInput() {
        this.locationModal.current.modalInput.current.focus();
    }

    /**
     * Shifts focus to the newly rendered geolocation button
     * @method focusGeoLocation
     */

    focusGeoLocation() {
        const geoButton = this.locationModal.current.geoLocationRef.current;
        geoButton.focus();
        // TODO: do we want to include the focus ring?
        geoButton.classList.add('u-overrideFocusRing');
    }

    /**
     * @async
     * @method handleOtherPetsDropdownBlur
     * @private
     * @param {Event} ev
     */
    handleOtherPetsDropdownBlur = async ev => {
        // Cache event properties to stop react event pooling error
        const currentTarget = ev.currentTarget;
        let target = ev.relatedTarget;

        // For IE11 and Voiceover + Safari...
        if (target === null) {
            await endOfEventLoop();
            target = document.activeElement;
        }

        if (!currentTarget.contains(target)) {
            setTimeout(() => {
                this.props.transition('FOCUS_OUT');
            }, 250);
        }
    };

    /**
     * @method handleOtherPetsDropdownKeyDown
     * @private
     * @param {Event} ev
     */
    handleOtherPetsDropdownKeyDown = ev => {
        if (ev.key === KEY_ESCAPE || ev.key === KEY_ESC) {
            this.props.transition('KEY_ESCAPE');
        }
    };

    /**
     * @async
     * @method handleSuggestionsListBlur
     * @private
     * @param {Event} ev
     */
    handleSuggestionsListBlur = async ev => {
        // Cache event properties to stop react event pooling error
        const currentTarget = ev.currentTarget;
        let target = ev.relatedTarget;

        // For IE11 and Voiceover + Safari...
        if (target === null) {
            await endOfEventLoop();
            target = document.activeElement;
        }

        if (!currentTarget.contains(target)) {
            this.props.transition('FOCUS_OUT');
        }
    };

    /**
     * @method handleSuggestionsListKeyDown
     * @private
     * @param {Event} ev
     */
    handleSuggestionsListKeyDown = ev => {
        if (ev.key === KEY_ESCAPE || ev.key === KEY_ESC) {
            this.props.transition('KEY_ESCAPE');
        }
    };

    /**
     * Build url from all the parts to send user to search page
     * @method performSearch
     * @private
     */
    performSearch() {
        const { locationSlug } = this.state;
        const { petTypeSlug } = this.props;

        // Build search url and send
        const urlParts = [
            SEARCH_URL_BASE,
            petTypeSlug,
            SEARCH_URL_PET_STATUS,
            locationSlug,
            SEARCH_URL_PARAMS,
        ];

        window.location.assign(urlParts.join(''));
    }

    /**
     * @method triggerPerformSearchAnalytics
     * @private
     */
    triggerPerformSearchAnalytics() {
        const { locationSlug } = this.state;
        const { petTypeSlug } = this.props;

        ensightenTracking.eventConsumer041({ locationSlug, petTypeSlug });
    }

    /**
     * @method setToFirstSuggestionResult
     * @private
     */
    setToFirstSuggestionResult() {
        const firstLocationSuggestion = this.props.locations[0];

        // Force run the handle location suggestion click...
        this.handleLocationSuggestionClick({
            ['display_name']: firstLocationSuggestion.display_name,
            slug: firstLocationSuggestion.slug,
        });
    }

    /**
     * Handles clearing props and state values if the user resizes to mobile breakpoint
     * @method handleResizeToMobile
     * @private
     */
    handleResizeToMobile() {
        this.props.transition('', {
            locationInputValue: null,
            locationSlug: null,
            petTypeSlug: null,
        });

        this.setState({ locationInputValue: '', locationSlug: '' });
    }

    // TODO: At some point the breakpoint should be passed along by
    // a higher level component
    /**
     * Triggers an initial resize that helps the statechart get into the right
     * mobile vs desktop state
     * @method handleResize
     * @private
     */
    handleResize() {
        this.props.transition('RESIZE');
    }

    /**
     * Ensures the user's device has access to the geolocation API
     * @method checkIfGeoEnabled
     */
    checkIfGeoEnabled() {
        if (navigator.geolocation) {
            this.props.transition('GEO_ENABLED');
        } else {
            this.props.transition('GEO_DISABLED');
        }
    }

    /**
     * Timeout to show geolocation error, then hide geolocation messaging/buttons
     * and shift focus back to input
     * @method checkIfGeoEnabled
     */
    geoDisabledMessage = () => {
        window.setTimeout(() => {
            this.props.transition('GEO_DISABLED');
        }, 5000);
    };

    /**
     * Uses the geolocation API to access user coordinates
     * @method getGeoCoordinates
     */
    getGeoCoordinates() {
        navigator.geolocation.getCurrentPosition(
            position => {
                this.props.transition('COORDINATES_OBTAINED', { position });
            },
            () => {
                this.checkIfAuthorized();
                if (this.state.geoLocationPermission === 'granted') {
                    this.props.transition('COORDINATES_ERRORED');
                } else {
                    this.props.transition('COORDINATES_DENIED');
                }
            }
        );
    }

    /**
     * Matches the user's geolocation coordinates with the web-front 'geography/find' endpoint
     * @method matchGeoLocation
     */
    async matchGeoLocation() {
        try {
            const coords = this.props.position.coords;
            const location = await geographyRepository.findAPlace(
                coords.latitude,
                coords.longitude
            );

            const locationInformation = {
                geoLocation: location.location,
                locationInputValue: location.location.display_name,
                locationSlug: location.location.slug,
            };

            this.setState(locationInformation);
            sessionStorage.setItem(
                'locationInformation',
                JSON.stringify(locationInformation)
            );

            this.props.transition('LOCATION_OBTAINED');
        } catch (err) {
            this.props.transition('LOCATION_ERROR');
        }
    }

    /**
     * @private
     */
    triggerLocationObtainedAnalytics() {
        const { geoLocation } = this.state;
        ensightenTracking.eventConsumer293(geoLocation);
    }

    /**
     * Handle a pet type button click, but we need to get some props to send a long
     * @method handlePetTypeClick
     * @private
     * @param {Event} ev
     * @param {Object} btnProps
     */
    handlePetTypeClick = (ev, btnProps) => {
        const { petTypeSlug, petPropertiesIndex, refocusable } = btnProps;
        const { locationSlug } = this.state;

        const newReturnFocusTarget = refocusable
            ? ev.currentTarget
            : this.props.returnFocusTarget;

        this.props.transition('PET_TYPE_CLICK', {
            locationSlug,
            petTypeSlug,
            petPropertiesIndex,
            returnFocusTarget: newReturnFocusTarget,
        });

        // Fire analytics event, triggered anytime a pet type btn is clicked
        ensightenTracking.eventConsumer038({ petTypeSlug });
    };

    /**
     * @method handleOtherTypesClick
     * @private
     * @param {Event} ev
     */
    handleOtherTypesClick = ev => {
        const returnFocusTarget = ev.currentTarget;
        this.props.transition('OTHER_PETS_CLICK', { returnFocusTarget });
    };

    /**
     * @method handleInputChange
     * @private
     * @param {Event} ev
     */
    handleInputChange = ev => {
        const locationInputValue = ev.target.value;

        const queryEvent = {
            type: 'QUERY',
            locationInputValue,
        };

        this.props.transition(queryEvent, {
            locationInputValue,
            locationSlug: null,
            locations: null,
        });

        this.setState({ locationInputValue, locationSlug: '' });
    };

    /**
     * @method handleInputFocus
     * @private
     * @param {Event} ev
     */
    handleInputFocus = ev => {
        const { locations } = this.props;

        setTimeout(() => {
            this.props.transition('INPUT_FOCUS', { locations });
            this.props.transition('FOCUS');
        }, 250);
    };

    /**
     * Handle a location item click, set some props
     * @method handleLocationSuggestionClick
     * @private
     * @param {Event} ev
     */
    handleLocationSuggestionClick = ev => {
        let locationInputValue;
        let locationSlug;
        if (ev.geoLocation) {
            locationInputValue = ev.geoLocation.display_name;
            locationSlug = ev.geoLocation.slug;
        } else {
            locationInputValue = ev.display_name;
            locationSlug = ev.slug;
        }
        const { petTypeSlug } = this.props;
        this.props.transition('RESULT_CLICK', {
            locationInputValue,
            locationSlug,
            petTypeSlug,
            returnFocusTarget: this.locationInput.current,
            // Set locations to blank array so we don't get sent to results
            // and show the locations dropdown, the user has picked a location
            // at this point...
            locations: [],
        });

        this.setState({ locationInputValue, locationSlug });

        // if (ev.geoLocation) {
        //     window.setTimeout(() => {
        //         this.props.transition('LOCATION_SELECTED');
        //     }, 0);
        // }
    };

    handleLocationSelection = (value, label, isDefaultSelection) => {
        const locationSlug = value;
        const locationInputValue = label;

        this.props.transition('USER_LOCATION_SUCCESS', { locationSlug, locationInputValue });

        this.setState({ locationSlug, locationInputValue }, () => {
            if (locationSlug.length && !isDefaultSelection) {
                this.triggerLocationSuggestionClickAnalytics();
            }
        });
    };

    /**
     * click handler for "use current location" button
     */
    _handleUseLocationClick = () => {
        analyticsConsumer445('main search');
    };

    /**
     * error handler for location field
     * @param {string} errorSource
     */
    _handleLocationError = errorSource => {
        if (errorSource === 'geolocation') {
            analyticsConsumer446('main search');
        }
    };

    handleLocationSearchClick = ev => {
        ev.preventDefault();
        this.props.transition('CHECK_USER_LOCATION');
    };

    /**
     * @method triggerLocationSuggestionClickAnalytics
     * @private
     */
    triggerLocationSuggestionClickAnalytics() {
        const { locationSlug } = this.state;
        ensightenTracking.eventConsumer037({ locationSlug });
    }

    /**
     * @method handleModalClose
     * @private
     */
    handleModalClose = () => {
        this.props.transition('CLOSE', {
            petTypeSlug: null,
            petPropertiesIndex: null,
        });
    };

    /**
     * Build the suggestion items from locations returned or build a no result element
     * @method renderLocationSuggestionsItems
     * @private
     * @returns {array}
     */
    renderLocationSuggestionsItems() {
        const { locations } = this.props;
        if (!locations) {
            return;
        }

        let locationItems = [<LocationSuggestionItem key="no-result" />];

        if (locations.length > 0) {
            locationItems = locations.map(location => {
                return (
                    <LocationSuggestionItem
                        key={location.slug}
                        onLocationSuggestionClick={
                            this.handleLocationSuggestionClick
                        }
                        {...location}
                    />
                );
            });
        }

        return locationItems;
    }

    /**
     * Build a pet type button from a passed pets key
     * @method renderPetButton
     * @private
     * @param {string} key
     * @param {boolean} refocusable
     * @returns {string}
     */
    renderPetButton(key, refocusable = false) {
        return (
            <PetTypeButton
                key={PETS.properties[key].typeSlug}
                buttonAriaLabel={PETS.properties[key].buttonAriaLabel}
                buttonLabel={PETS.properties[key].buttonLabel}
                buttonDataTest={PETS.properties[key].dataTestValue}
                onPetTypeClick={this.handlePetTypeClick}
                petPropertiesIndex={key}
                petTypeSlug={PETS.properties[key].typeSlug}
                svgIconSlug={PETS.properties[key].svgIconSlug}
                refocusable={refocusable}
            />
        );
    }

    /**
     * Builds other pet buttons
     * @method renderOtherPetTypeButtons
     * @private
     * @returns {array}
     */
    renderOtherPetTypeButtons() {
        return OTHER_PETS.map(key => this.renderPetButton(PETS[key]));
    }

    renderLocationSearch() {
        return (
            <GeoSearchComboBox
                id="FindAPetGeoSearch"
                name="FindAPetGeoSearch"
                extensionClassNames={{ ['comboBox_findAPet']: true }}
                listBoxExtensionClassNames={{ ['listBox_findAPet']: true }}
                onSelection={this.handleLocationSelection}
                onUseLocationClick={this._handleUseLocationClick}
                onError={this._handleLocationError}
                dataTestInputId="Find_A_Pet_Menu_Location_Input"
                dataTestListId="Find_A_Pet_Menu_Location_Suggestion_Button_List"
            />
        );
    }

    render() {
        const { locationInputValue } = this.state;
        const otherPetsExpanded = this.props.machineState.value.otherPets === 'show';

        return (
            <div className="findAPetMenu-inner" data-test="Find_A_Pet_Menu">
                <div className="findAPetMenu-locationInputContainer">
                    <State is={['main.desktop.init']}>
                        <div className="u-animationFadeInDelayed">
                            <Loader />
                        </div>
                    </State>

                    <State is={[
                        'main.desktop.init',
                        'main.desktop.idle',
                        'main.desktop.resultsPetTypeClick',
                        'main.desktop.performSearch',
                    ]}>
                        {this.renderLocationSearch()}
                    </State>

                </div>

                {this.renderPetButton(PETS.DOG, true)}
                {this.renderPetButton(PETS.CAT, true)}

                <button
                    className={this.getOtherButtonClassNames()}
                    onClick={this.handleOtherTypesClick}
                    type="button"
                    ref={this.otherPetsBtn}
                    aria-expanded={otherPetsExpanded}
                    data-test="Find_A_Pet_Find_Other_Pets_Button"
                >
                    {'Find Other Pets'}
                </button>

                <State is={'otherPets.show'}>
                    <div
                        ref={this.otherPetsDropdown}
                        className="findAPetMenu-otherPetsDropdown"
                        onBlur={this.handleOtherPetsDropdownBlur}
                        onKeyDown={this.handleOtherPetsDropdownKeyDown}
                        data-test="Find_A_Pet_Other_Pets_Dropdown"
                    >
                        {this.renderOtherPetTypeButtons()}
                    </div>
                </State>
                <State is={['main.mobile.isHandlingSearch.otherPets']}>
                    <OtherPetTypesModal
                        otherPetTypeButtons={this.renderOtherPetTypeButtons()}
                        onClose={this.handleModalClose}
                    />
                </State>

                <State
                    is={[
                        'main.mobile.isHandlingSearch.location',
                        'main.mobile.isHandlingSearch.loading',
                        'main.mobile.isHandlingSearch.results',
                        'main.mobile.isHandlingSearch.noResults',
                        'main.mobile.isHandlingSearch.locationNetworkError',
                        'main.mobile.isHandlingSearch.performSearch',
                    ]}
                >
                    <LocationModal
                        ref={this.locationModal}
                        locationInputValue={locationInputValue}
                        locationSuggestionItems={this.renderLocationSuggestionsItems()}
                        machineState={this.props.machineState}
                        petPropertiesIndex={this.props.petPropertiesIndex}
                        petTypeSlug={this.props.petTypeSlug}
                        savedSearches={this.props.savedSearches}
                        onClose={this.handleModalClose}
                        onInputChange={this.handleInputChange}
                        onInputFocus={this.handleInputFocus}
                        onSuggestionsListBlur={this.handleSuggestionsListBlur}
                        onLocationSearchClick={this.handleLocationSearchClick}
                        geoLocation={this.state.geoLocation}
                        onLocationSuggestionClick={
                            this.handleLocationSuggestionClick
                        }
                    />
                </State>
            </div>
        );
    }
}

export default withStateMachine(statechart)(FindAPetMenu);
