import AnimalBreedsEventModel from '../AnimalBreedsEventModel';
import CatBreedPropertiesModel from './CatBreedPropertiesModel';
import _get from 'lodash/get';

export default class CatBreedEventModel extends AnimalBreedsEventModel {
    get defaultAnalyticProperties() {
        return {
            friendliness: true,
            groomingRequirements: true,
            vocality: true,
            playfulness: true,
            activityLevel: true,
        };
    }

    /**
     * prepareEvent
     *
     * @static
     * @param {Object} eventData
     * @param {Object} analyticProperties
     * @param {boolean} overrideAnalyticProperties
     *
     * @return {CatBreedEventModel}
     */
    static prepareEvent(
        eventData,
        analyticProperties = {},
        overrideAnalyticProperties = false
    ) {
        const model = new CatBreedEventModel(
            this._mapEventData(eventData),
            analyticProperties,
            overrideAnalyticProperties
        );
        model.analyticObjects = eventData;

        return model;
    }

    /**
     * _mapAnimalBreedFilters
     *
     * @param {Object} filters
     *
     * @return {Object}
     */
    static _mapAnimalBreedFilters = filters => {
        return {
            friendliness: _get(filters, 'friendliness', ''),
            groomingRequirements: _get(filters, 'groomingRequirements', ''),
            vocality: _get(filters, 'vocality', ''),
            playfulness: _get(filters, 'playfulness', ''),
            activityLevel: _get(filters, 'activityLevel', ''),
        };
    };

    _buildProperties(analytics, nullText) {
        return new CatBreedPropertiesModel(analytics, nullText);
    }
}
