import { html } from '../../../../core/scripts/util/util.js';

export default vm => html`
    <div pf-hello-bar-content class="helloBar">
        <div class="helloBar-inner">
            <a
                href="#Site_Main"
                class="skipToContent helloBar-skip-to-content"
                aria-label="Skip to content"
                pf-hello-bar-skip
            >
                Skip to content
            </a>
            <div pf-hello-bar-banner-cta class="helloBar-inner-footer">
                <div class="helloBar-inner-footer-actions">
                    <a
                        class="helloBar-inner-footer-actions-cta"
                        href="https://www.petfinder.com/purina-cares-partnership/"
                    >
                        <p>
                            <span class="txt txt_link m-colorPrimary m-txt_underline">Learn more</span> about how Purina
                            and Petfinder are making a difference together.
                        </p>
                        <span class="icon icon_sm m-icon_colorDark">
                            <svg role="img" focusable="false">
                                <use xlink:href="#icon-chevronRight"></use>
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
`;
