import MixinBase from './MixinBase';
import Mixins from '../Mixins';
import Utils from '../Utils';

export default class FocusOnRenderMixin extends MixinBase {
    static _attributeName = 'mix-focus-on-render';

    constructor(ele, value) {
        super(ele, value);

        if (value !== 'false') {
            ele.focus();
        }
    }
}

