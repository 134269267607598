import APIService from '../../../core/scripts/services/APIService';
import { stringify } from 'query-string';
import userMeAPIService from '../../../core/scripts/services/userMeAPIService';
import {
    SORT_OPTIONS,
    RECENTLY_SAVED,
    DEFAULT_LIMIT,
    UNAVAILABLE_MAX_DAYS,
} from '../constants/favorites';

class FavoritesAPIService extends APIService {
    static url = '/v2/me/favorites/';

    async getFavorites(
        sort = RECENTLY_SAVED,
        active = true,
        limit = DEFAULT_LIMIT,
        page = 1
    ) {
        const queryOptions = {
            include: 'animal',
            sort: active ? SORT_OPTIONS[sort] : 'created',
            active,
            ['allow_empty_embeds']: !active,
            limit,
            page: Math.max(page, 1),
        };

        if (!active) {
            queryOptions.after = UNAVAILABLE_MAX_DAYS;
        }

        const urlAppend = `?${stringify(queryOptions)}`;

        const options = {
            urlAppend,
            method: 'GET',
        };

        const response = await this.queryAndProcess(options);

        return response;
    }

    async setFavorite(petId) {
        const userMe = await userMeAPIService.getUserMe();
        const passthroughToken = userMe.apiPassthroughToken;

        const options = {
            urlAppend: `?token=${passthroughToken}`,
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
            data: JSON.stringify({ favorite: { ['animal_id']: petId } }),
        };

        const response = await this.queryAndProcess(options);

        return response;
    }

    async removeFavorite(petId) {
        const userMe = await userMeAPIService.getUserMe();
        const passthroughToken = userMe.apiPassthroughToken;

        const options = {
            urlAppend: `${petId}?token=${passthroughToken}`,
            method: 'DELETE',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
            data: JSON.stringify({ favorite: { ['animal_id']: petId } }),
        };

        const response = await this.queryAndProcess(options);

        return response;
    }

    async removeUnavailableFavorites() {
        const userMe = await userMeAPIService.getUserMe();
        const passthroughToken = userMe.apiPassthroughToken;

        const options = {
            urlAppend: `unavailable?token=${passthroughToken}`,
            method: 'DELETE',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
        };

        const response = await this.queryAndProcess(options);

        return response;
    }
}

const instance = new FavoritesAPIService();
APIService.register('favorites', instance);

export default instance;
