/* eslint-disable camelcase */
import { pollUntilConditionMet } from '../../../../../../core/scripts/util/util';
import socialLoginStateController from '../controller/SocialLoginStateController';
import socialLoginService from './SocialLoginService';

import Config from '../../../../../../core/scripts/lib/Config';

class FacebookAPIService {
    /* *******************************************
     * Facebook methods
     ********************************************/

    /**
     * Gets basic profile data from FB
     * @param {string} userID
     * @param {Boolean} forceUpdate override profile data cache after updating permissions
     * @returns {Object} this._user.facebook.profileData
     *
     */
    _getBasicFacebookProfileData = userID => {
        const fbPromise = new Promise((resolve, reject) => {
            window.FB.api(
                `/${userID}/`,
                {
                    fields:
                        'email, name, first_name, last_name, picture.type(large)',
                },
                response => {
                    if (response.error) {
                        // TODO_HM: handle promise rejection
                        reject();
                    }

                    const facebookUser = {
                        profileData: {
                            email: response.email,
                            name: response.name,
                            firstName: response.first_name,
                            lastName: response.last_name,
                            image:
                                response.picture &&
                                response.picture.data &&
                                !response.picture.data.is_silhouette
                                    ? response.picture.data.url
                                    : null,
                        },
                    };

                    socialLoginStateController.patchState({
                        userData: {
                            facebook: facebookUser,
                        },
                    });

                    resolve(facebookUser.profileData);
                }
            );
        });

        return fbPromise;
    };

    /**
     * Gets FB Permission
     * @param {string} userID
     * @returns {Object} this._user.facebook.profileData
     */
    _getFacebookPermissions = userID => {
        const fbPromise = new Promise((resolve, reject) => {
            window.FB.api(`/${userID}/permissions`, response => {
                if (response.error) {
                    // TODO_HM: handle promise rejection
                    reject();
                } else {
                    const facebookPermissions = {
                        permissions: {
                            email:
                                response.data[1].status === 'declined'
                                    ? false
                                    : true,
                        },
                    };
                    resolve(facebookPermissions.permissions);
                }
            });
        });

        return fbPromise;
    };

    /**
     * Initialize the Facebook API
     * @return {Object} googleAuth
     * @private
     */
    _getFacebookAuth = async () => {
        try {
            await pollUntilConditionMet(
                () => {
                    return window.PF.facebookInitialized;
                },
                20,
                10000
            );

            if (!Config.page.facebook_app_id) {
                return;
            }

            const params = {
                appId: Config.page.facebook_app_id,
                version: 'v2.9',
            };

            window.FB.init({
                appId: params.appId,
                version: params.version,
            });

            window.FB.getLoginStatus(response => {
                if (response.authResponse) {
                    this._getFacebookUserData(response);
                } else {
                }
                socialLoginStateController.patchState({
                    APIReady: {
                        facebook: true,
                    },
                });
            });

            socialLoginStateController.patchState({
                auth: {
                    facebook: window.FB,
                },
            });
        } catch (e) {
            // TODO_HM: third party API fail
            console.error('facebook unavailable');
        }
    };

    /**
     * Callback for the FB getLoginStatus.
     * Prepare facebook user data for consumption.
     *
     * @method _getFacebookUserData
     * @param {Object} facebookAuth object
     * @private
     */
    _getFacebookUserData = facebookAuth => {
        if (facebookAuth.status === 'connected') {
            socialLoginStateController.patchState({
                returningUsers: {
                    facebook: true,
                },
            });
        } else {
            socialLoginStateController.patchState({
                returningUsers: {
                    facebook: false,
                },
            });
        }

        this._getBasicFacebookProfileData(facebookAuth.authResponse.userID);
    };

    /**
     * @type {Function}
     * Handle change to FB authorization status
     * @param {Object} response authorization response from FB
     */
    _authorizationCallback = response => {
        if (response.status === 'unknown') {
            // Not a returning user, closed the login prompt
            return;
        }
        if (!response.authResponse) {
            this._handleFacebookError();
        } else {
            this._handleFacebookSuccess(response);
        }
    };

    /**
     * @type {Function}
     * Binds FB events to custom buttons
     * @param {Object} params button element
     */
    bindLoginClick = params => {
        const { button, reauthenticate, next, next_params, context } = params;

        const authParam = {
            scope: 'email',
        };

        if (reauthenticate) {
            authParam.auth_type = 'rerequest';
        }

        let subscribed = false; // tracks FB statusChange subscription status

        button.onclick = () => {
            socialLoginStateController.refocusTarget = button;
            // if next values are provided, set state
            if (next) {
                socialLoginStateController.authenticationSuccessParameters = {
                    next,
                    nextParams: next_params,
                };
            }

            if (context) {
                socialLoginStateController.patchState({
                    modal: {
                        modalState: {
                            contextName: context,
                        },
                    },
                });
            }

            // Try using existing auth response if already connected and doesn't need to reauthorize...

            window.FB.getLoginStatus(response => {
                if (typeof response.authResponse === 'undefined') {
                    // occurs if the 'getLoginStatus' function fails/the fetch is cancelled
                    this._handleFacebookError();
                }
                if (response.status === 'connected' && !reauthenticate) {
                    this._authorizationCallback(response);
                } else {
                    if (!subscribed) {
                        // subscribe to FB authorization change. CB will fire after successful login.
                        window.FB.Event.subscribe(
                            'auth.statusChange',
                            this._authorizationCallback
                        );
                        // only subscribe to CB once
                        subscribed = true;
                    }
                    // triggers the FB login dialog if a user is not connected
                    // will trigger the auth.statusChange CB

                    window.FB.login(response => {
                        if (reauthenticate) {
                            // need to trigger the CB manually when accessing new permissions
                            // because the user's authentication status does not change
                            this._authorizationCallback(response);
                        }
                    }, authParam);
                }
            });
        };
    };

    /**
     * @type {Function}
     * Successfully logged in to Facebook, attempt PF login
     * @param {Object} user
     */
    _handleFacebookSuccess = async user => {
        const id_token = user.authResponse.accessToken;
        const userId = user.authResponse.userID;
        const [profileData, permissions] = await Promise.all([
            this._getBasicFacebookProfileData(
                user.authResponse.userID,
                socialLoginStateController.reauthenticate
            ),
            this._getFacebookPermissions(userId),
        ]);
        const emailPermission = permissions.email;

        socialLoginStateController.patchState({
            user: profileData,
            activeProvider: 'facebook',
        });

        // opens login modal if not yet open
        if (!socialLoginStateController.state.modal.alreadyOpen) {
            socialLoginStateController.openLoginModal({});
        }

        if (!emailPermission) {
            socialLoginStateController.patchState({
                step: 'reauthenticate',
            });
            return;
        }
        if (
            (emailPermission && profileData.email === '') ||
            !profileData.email
        ) {
            socialLoginStateController.patchState({
                step: 'redirect',
            });
            return;
        }

        socialLoginStateController.patchState({
            step: 'loading',
        });

        socialLoginService.signInToPF(id_token, profileData, 'facebook');
    };

    _handleFacebookError = () => {
        // opens login modal if not yet open
        if (!socialLoginStateController.state.modal.alreadyOpen) {
            socialLoginStateController.openLoginModal({});
        }

        socialLoginStateController.patchState({
            step: 'error',
            // eslint-disable-next-line quotes
            error: "couldn't sign in to Facebook",
        });
    };
}

export default new FacebookAPIService();
