import StateController from '../../../core/scripts/lib/wirey/state/StateController';
import savedSearchUIStateController from './ui/savedSearchUIStateController';

const DEFAULT_STATE = {
    countryNotListed: false,
    resultsLoading: true,
    fundamentalFiltersLoading: true,
    searchInitPaused: false,

    filtersDisplay: {
        age: true,
        attribute: true,
        breed: true,
        coatLength: true,
        color: true,
        gender: true,
        household: true,
        size: true,
        species: true,
        distance: true,
    },

    accessibility: {
        autosuggest: {
            liveRegion: '',
        },
    },
    animalSearch: {
        isPerformingFirstLoad: true,
        locationSelect: {
            inputText: '',
            labelText: '',
            activeItemIndex: -1,
            optionsMenuOpen: false,
            optionsLoading: false,
            options: [],
        },
    },
};

class UIStateController extends StateController {
    // convenience reference
    savedSearch = savedSearchUIStateController;

    constructor(...args) {
        super(...args);

        this.addSubstate('savedSearch', savedSearchUIStateController);
    }

    get defaultState() {
        return DEFAULT_STATE;
    }

    get searchInitPaused() {
        return this.state.searchInitPaused;
    }

    set searchInitPaused(value) {
        this.patchState({
            searchInitPaused: value,
        });
    }

    set resultsLoading(value) {
        this.patchState({
            resultsLoading: value,
        });
    }

    set fundamentalFiltersLoading(value) {
        this.patchState({
            fundamentalFiltersLoading: value,
        });
    }

    set isPerformingFirstLoad(value) {
        this.patchState({
            animalSearch: {
                isPerformingFirstLoad: value,
            },
        });
    }
}

export default new UIStateController();
