import { trackEvent } from '../../../core/scripts/analytics';
import { Config } from '../../../core/scripts/lib/Config';
import AnimalEventModel from '../../../core/scripts/analytics/models/animal/AnimalEventModel';
import UserLoginRegistrationEventModel from '../../../core/scripts/analytics/models/user-login-registration/UserLoginRegistrationEventModel';
import AnimalModel from '../models/AnimalModel';

const FAVORITES_CATEGORY = 'favorites';

/**
 * _getUserAuthString
 * @private
 * @param {Boolean} isGuest
 *
 * @returns {string}
 */
function _getUserAuthString(isGuest) {
    return isGuest ? 'guest' : 'logged in';
}

/**
 * ga4FavoritesEvent
 * @public
 * @event Favorites002
 * @param {AnimalModel} animalData
 * @param {Boolean} isGuest
 * @param {string} method
 */
export function ga4FavoritesEvent(animalData, isGuest, method) {
    const authenticationStatus = _getUserAuthString(isGuest);

    const animal = AnimalModel.fromSearchResults(animalData.data);

    /* eslint-disable camelcase, no-undefined */
    const eventParams = {
        login_status: authenticationStatus,
        pet_species: animal.species,
        pet_breed_1: animal.primaryBreed,
        pet_breed_2: animal.secondaryBreed,
        pet_age: animal.age,
        pet_gender: animal.sex,
        pet_health_behavior: animal.attributes,
        pet_color: animal.primaryColor,
        pet_coat_length: animal.coatLength,
        match_type: animal.petMatchType,
        video_type: animal.videoType,
        pet_status: animal.adoptionStatus,
        shelter_name: animal.organizationName,
        shelter_id: animal.organizationId,
        method,
        photo_count: animal.numberOfPhotos
            ? `${animal.numberOfPhotos} photo${animal.numberOfPhotos !== 1 ? 's' : ''}`
            : undefined,
        pet_size: animal.size,
    };

    // for each entry in eventParams, if the value is a string, lowercase it
    Object.keys(eventParams).forEach(key => {
        if (typeof eventParams[key] === 'string') {
            eventParams[key] = eventParams[key].toLowerCase();
        }
    });

    // PFPROD-4085 - GA4 Analytics - Search Results - Pet Favorite
    window.dataLayer.push(
        {
            event: 'pet_favorite',
            ga4: true,
            event_params: eventParams,
        },
        { event_params: undefined }
    );
}

/**
 * Favorites 002
 * Page: Pet Detail Card on Search page
 * Action: User clicks 'Heart' on pet detail card from search
 *
 * @public
 * @event Favorites002
 * @param {Object} animal
 * @param {Boolean} isGuest
 */
export function analyticsFavorites002(animal, isGuest) {
    const authenticationStatus = _getUserAuthString(isGuest);
    const userLoginRegistrationAnalytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus,
    });

    const dimensions = {
        ...AnimalEventModel.fromAnimalModel(animal, {
            filterOutNullText: true,
        }).dimensions,
        ...userLoginRegistrationAnalytics.dimensions,
    };

    const additional = {
        ...AnimalEventModel.fromAnimalModel(animal).dataLayer,
        ...userLoginRegistrationAnalytics.dataLayer,
    };

    trackEvent({
        category: 'pet search',
        action: 'add to favorites',
        label: authenticationStatus,
        event: 'Favorites 002 - Favorites',
        dimensions,
        additional,
    });

    ga4FavoritesEvent(animal, isGuest, 'search results');
}

/**
 * Favorites 003
 * Page: Pet Detail Card on Search page
 * Action: User clicks 'Heart' on pet detail card from search (to unfavorite)
 *
 * @public
 * @event Favorites003
 */
export function analyticsFavorites003() {
    trackEvent({
        category: 'pet search',
        action: 'remove from favorites',
        event: 'Favorites 003 - Favorites',
    });
}

export function petDetailPageFavoriteBtnsAnalytics(isGuest) {
    window.dataLayer.push(
        {
            ga4: true,
            event: 'pet_favorite',
            event_params: {
                login_status: isGuest ? 'guest' : 'logged in',
            },
        },
        { event_params: undefined }
    );
}

/**
 * Favorites 004
 * Page: Pet Detail Page
 * Action: User selects "favorite petname" from pet detail card
 *
 * @public
 * @event Favorites004
 * @param {Object} animal
 * @param {Boolean} isGuest
 */
export function analyticsFavorites004(animal, isGuest) {
    const authenticationStatus = _getUserAuthString(isGuest);
    const animalAnalytics = AnimalEventModel.fromAnimalModel(animal, {
        filterOutNullText: true,
    });
    const userLoginRegistrationAnalytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus,
    });

    const dimensions = {
        ...AnimalEventModel.fromAnimalModel(animal, {
            filterOutNullText: true,
        }).dimensions,
        ...userLoginRegistrationAnalytics.dimensions,
    };
    const additional = {
        ...AnimalEventModel.fromAnimalModel(animal).dataLayer,
        ...userLoginRegistrationAnalytics.dataLayer,
    };

    trackEvent({
        category: 'pet detail',
        action: 'add to favorites',
        label: authenticationStatus,
        event: 'Favorites 004 - Favorites',
        dimensions,
        additional,
    });

    petDetailPageFavoriteBtnsAnalytics(isGuest);
}

/**
 * Favorites 005
 * Page: Pet Detail Page
 * Action: User selects "favorite petname" from pet detail card (footer)
 *
 * @public
 * @event Favorites005
 * @param {Object} animal
 * @param {Boolean} isGuest
 */
export function analyticsFavorites005(animal, isGuest) {
    const authenticationStatus = _getUserAuthString(isGuest);
    const userLoginRegistrationAnalytics = UserLoginRegistrationEventModel.prepareEvent({
        authenticationStatus,
    });

    const dimensions = {
        ...AnimalEventModel.fromAnimalModel(animal, {
            filterOutNullText: false,
        }).dimensions,
        ...userLoginRegistrationAnalytics.dimensions,
    };
    const additional = {
        ...AnimalEventModel.fromAnimalModel(animal).dataLayer,
        ...userLoginRegistrationAnalytics.dataLayer,
    };

    trackEvent({
        category: 'pet detail',
        action: 'add to favorites - footer',
        label: authenticationStatus,
        event: 'Favorites 005 - Favorites',
        dimensions,
        additional,
    });

    petDetailPageFavoriteBtnsAnalytics(isGuest);
}

/**
 * Favorites 006
 * Page: Favorites Page
 * Action: User selects how to Sort
 *
 * @public
 * @event Favorites006
 * @param {string} sortBy
 */
export function analyticsFavorites006(sortBy) {
    window.dataLayer.push(
        {
            ga4: true,
            event: 'filter_applied',
            event_params: {
                filter_name: 'sort',
                filter_value: sortBy,
                page_type: 'favorites',
            },
        },
        { event_params: undefined }
    );
}

/**
 * Favorites 007
 * Page: Favorites Page
 * Action: User clicks "heart" to UNFAVORITE
 *
 * @public
 * @event Favorites007
 */
export function analyticsFavorites007() {
    trackEvent({
        category: FAVORITES_CATEGORY,
        action: 'remove from favorites',
        event: 'Favorites 007 - Favorites',
    });
}

/**
 * Favorites 008
 * Page: Favorites Page
 * Action: User selects 'CLEAR UNAVAILABLE'
 *
 * @public
 * @event Favorites008
 */
export function analyticsFavorites008() {
    trackEvent({
        category: FAVORITES_CATEGORY,
        action: 'clear unavailable',
        event: 'Favorites 008 - Favorites',
    });
}

/**
 * Favorites 009
 * Page: Favorites Page
 * Action: User clicks "Ask About Me" from Favorites Page
 *
 * @public
 * @event Favorites009
 * @param {Object} favoritesModel
 * @param {string} id
 * @public
 */
export function analyticsFavorites009(favoritesModel, id) {
    window.dataLayer.push(
        {
            ga4: true,
            event: 'adoption_form_initiate',
            event_params: {
                method: 'favorites',
                page_type: 'favorites',
                pet_species: favoritesModel._data.animal.species.toLowerCase(),
            },
        },
        { event_params: undefined }
    );
}

/**
 * Favorites 010
 * Page: Favorites Page
 * Action: User clicks "Ask About Me" from Favorites Page
 *
 * @public
 * @event Favorites010
 */
export function analyticsFavorites010() {
    trackEvent({
        category: FAVORITES_CATEGORY,
        action: 'view unavailable',
        event: 'Favorites 009 - Favorites',
    });
}

/**
 * Favorites 011
 * Page: Global Nav
 * Action: Clicks 'Heart' to see list of Favorites, NOT logged in
 *
 * @public
 * @event Favorites011
 */
export function analyticsFavorites011() {
    trackEvent({
        category: 'global-nav',
        action: 'favorite',
        label: 'guest',
        event: 'Favorites 011 - Favorites',
    });
}

/**
 * Favorites 012
 * Page: Global Nav
 * Action: Clicks 'Heart' to see list of Favorites, logged in
 *
 * @public
 * @event Favorites012
 */
export function analyticsFavorites012() {
    trackEvent({
        category: 'global-nav',
        action: 'favorite',
        label: 'logged in',
        event: 'Favorites 012 - Favorites',
    });
}

/**
 * Favorites 013
 * Page: Favorites Page
 * Action: User clicks, "Search All Pets" link within their Favorites page
 *
 * @public
 * @param {string} animalType Selected animal type
 * @event Favorites013
 */
export function analyticsFavorites013(animalType) {
    trackEvent({
        category: FAVORITES_CATEGORY,
        action: 'search all pets',
        label: animalType,
        event: 'Favorites 013 - Favorites',
    });
}

/**
 * analyticsFilterFavoritesGlobalNav
 * Page: Global Nav
 * Action: Clicks 'Heart' to see list of Favorites
 *
 * @public
 * @event analyticsFilterFavoritesGlobalNav
 */
export function analyticsFilterFavoritesGlobalNav() {
    switch (_getUserAuthString(!Config.userAuthed)) {
        case 'guest':
            analyticsFavorites011();
            break;
        case 'logged in':
            analyticsFavorites012();
            break;
    }
}

// TODO: Favorites 014 - 016. While refactoring noticed new analytics not accounted for here.  002 looks to be crossed off and filtered to 014, 015, or 016.
