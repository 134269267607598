import React, { Component, Fragment } from 'react';
import { withStateMachine, State } from 'react-automata';
import { statechart, STATE, EVENT, ACTION } from './statechart';
import PropTypes from 'prop-types';

import LoginLauncher from '../index';
import Modal from '../../../../../../core/scripts/react-components/specifics/Modal';

import Config from '../../../../../../core/scripts/lib/Config';

import socialLoginStateController from '../../SocialLogin/controller/SocialLoginStateController';

import { INITIAL_STATES } from '../statechart';
import { CONTEXT } from '../utilities/copyMap';

import { analyticsConsumer005 } from '../../../../analytics/dotcom.js';

import _get from 'lodash/get';
import { SSO_MODAL_ACTIONS, handleSSOModal } from '../../../../util/ssoModalHelpers.js';
/**
 * @class LoginLauncher
 * @extends Component
 */

export class LoginLauncherModal extends Component {
    /**
     * @type {LoginLauncherModal}
     */
    static instance = null;

    _composeStateObject() {
        return {
            contextName: socialLoginStateController.state.contextName,
            formData: {},
            countries: window.PF.pageConfig.countries,
            forgotPasswordUrl: window.PF.pageConfig.forgot_password_url,
            userCountryCode: 'US',
            refocusTarget: socialLoginStateController.refocusTarget,
            onSignInSuccess: socialLoginStateController.handleAuthenticationSuccess,
            onModalClose: () => {},
        };
    }

    /**
     * @type {Object}
     */
    state = this._composeStateObject();

    _updateState() {
        this.setState(this._composeStateObject());
    }

    _unsubscribeFunctions = [];

    /**
     *
     * @param {{ display: String, context: String, onSuccess: Function, refocusTarget: HTMLElement, trigger: String }} data
     */
    static launch(data) {
        if (window.ssoFlagIsActive) {
            handleSSOModal(SSO_MODAL_ACTIONS.open, '');
        } else {
            // OLD Sign In Modal
            socialLoginStateController.openLoginModal(data);
        }
    }

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        displayInitial: PropTypes.oneOf(INITIAL_STATES),
    };

    /**
     * @type {Ref}
     */
    loginLauncher = React.createRef();

    componentDidMount() {
        LoginLauncherModal.instance = this;

        this.getContext();

        this._unsubscribeFunctions.push(
            socialLoginStateController.subscribe(
                payload => {
                    if (!socialLoginStateController.state.modal.triggerOpen) {
                        return;
                    }
                    this.onTriggerLaunch();
                },
                ['modal.triggerOpen']
            )
        );
        this._unsubscribeFunctions.push(
            socialLoginStateController.subscribe(
                payload => {
                    if (!socialLoginStateController.state.modal.triggerClose) {
                        return;
                    }
                    this.handleClose(true);
                },
                ['modal.triggerClose']
            )
        );
    }

    componentWillUnmount() {
        this._unsubscribeFunctions.forEach(unsubscribeFn => unsubscribeFn());
    }

    getContext = async () => {
        const userMe = await Config.getUserMe();

        const userCountryCode = _get(userMe, 'location.country.code', '');
        if (userCountryCode === this.state.userCountryCode || userCountryCode === '') {
            return;
        }

        this.setState({
            userCountryCode,
        });
    };

    get loginLauncherMachineState() {
        let currentMachineState = _get(this.loginLauncher, 'current.state.machineState.value', '');

        if (typeof currentMachineState === 'object') {
            // Social substate
            currentMachineState = currentMachineState.social;
        }

        return currentMachineState;
    }

    get loginLauncherComponentState() {
        return _get(this.loginLauncher, 'current.instance.current.state', {});
    }

    /* *******************************************
     * Events
     ********************************************/
    onTriggerLaunch = () => {
        const modalState = socialLoginStateController.state.modal.modalState;

        const contextName = modalState.contextName ? modalState.contextName : CONTEXT.GENERIC;

        const displayInitial = modalState.display;

        const formData = modalState.formData ? modalState.formData : {};

        // getters
        const refocusTarget = socialLoginStateController.refocusTarget;
        const onModalClose = socialLoginStateController.modalCloseHandler;

        this.setState(
            {
                contextName,
                displayInitial,
                formData,
                refocusTarget,
                onModalClose,
            },
            () => {
                socialLoginStateController.patchState({
                    modal: {
                        alreadyOpen: true,
                    },
                });
                this.props.transition(EVENT.OPEN);
            }
        );
    };

    /**
     * @param {Boolean} skipAnalytics
     * The skipAnalytics parameter is useful when we programatically close the modal
     * (Otherwise the 'user abandoned sign in flow' event is fired)
     */
    handleClose = skipAnalytics => {
        const view = this.loginLauncherMachineState;
        const provider = this.loginLauncherComponentState.provider;
        const context = this.state.contextName;

        if (!skipAnalytics) {
            // Analytics: user exited the modal
            analyticsConsumer005({
                view,
                context,
                provider,
            });
        }

        setTimeout(() => {
            this.state.onModalClose();
            this.props.transition(EVENT.CLOSE);
            socialLoginStateController.patchState({
                modal: {
                    alreadyOpen: false,
                    trigger: false,
                },
            });
        }, 0);
    };

    toggleNoClose = () => {
        // necessary to prevent automata error if these are fired in quick succession
        setTimeout(() => {
            this.props.transition(EVENT.TOGGLE_CLOSE);
        }, 0);
    };

    get noClose() {
        return this.props.machineState.value[STATE.OPEN_MODAL] === 'openNoClose';
    }

    /* *******************************************
     * Actions
     ********************************************/
    /**
     * @private
     */
    [ACTION.REFOCUS_TARGET] = () => {
        if (this.state.refocusTarget) {
            this.state.refocusTarget.focus();
        }
    };

    /* *******************************************
     * Render Methods
     ********************************************/

    render() {
        return (
            <Fragment>
                <State is={`${STATE.OPEN_MODAL}.*`}>
                    <Modal
                        extensionClassNames={{
                            ['modal_socialLogin']: true,
                        }}
                        onClose={this.handleClose}
                        noClose={this.noClose}
                        onEscapeKeyDown={this.handleClose}
                        dataTestValue="Login_Modal"
                        {...this.modalProps}
                    >
                        <LoginLauncher
                            {...this.state}
                            ref={this.loginLauncher}
                            isModal={true}
                            onCancel={this.handleClose}
                            toggleNoClose={this.toggleNoClose}
                        />
                    </Modal>
                </State>
            </Fragment>
        );
    }
}

export default withStateMachine(statechart)(LoginLauncherModal);
