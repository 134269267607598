import { PFElement } from '../pf-element/element';
import { analyticsTrackEvent as trackEvent } from './ensighten/ensighten';
import cookie from 'js-cookie';

import { analyticsBreed077 } from '../../../../dotcom/scripts/analytics/breeds';
import * as dotcomAnalytics from '../../../../dotcom/scripts/analytics/dotcom';
import * as happyTailsAnalytics from '../../../../dotcom/scripts/analytics/happy-tails';
import * as onboardingQuizAnalytics from '../../../../dotcom/scripts/analytics/onboarding-quiz';
import * as socialLoginAnalytics from '../../../../dotcom/scripts/analytics/social-login';
import * as searchAndMatchAnalytics from '../../../../dotcom/scripts/analytics/search-and-match';
import * as favoritesAnalytics from '../../../../dotcom/scripts/analytics/favorites';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function endOfEventLoop() {
    return sleep(0);
}

import {
    debounce,
    getPseudoContent,
    offset,
    isElementConnected,
    isCustomElement,
    isDescendant,
} from '../../util/dom';

import { CLASS_IS_OPEN } from '../../constants/classes';

import {
    EV_APP_INIT,
    EV_APP_READY,
    EV_UI_ACTIVATE,
    EV_UI_DEACTIVATE,
    EV_UI_DEACTIVATE_ALL,
    EV_UI_TOGGLE_ACTIVATION,
    EV_RESIZE_COMPLETE,
    EV_RESIZE_WIDTH_COMPLETE,
    EV_RESIZE_HEIGHT_COMPLETE,
    EV_RESIZE_BREAKPOINT_CHANGE,
    EV_UI_DATE_RANGE_CHANGE,
} from '../../constants/events';

const FOCUS_FORM_POST_ERROR_TIMER_DURATION = 2000;
const FOCUS_FORM_POST_ERROR_ELEMENT_SELECTOR = '[pf-app-global-form-errors]';

/**
 * The App Root Element is the top level element of the app.
 *
 * Main functions:
 *
 * - loading global data
 * - showing/hiding ui elements
 * - managing focus
 * - locking page scroll
 *
 * Public Methods: none
 *
 * @class PFAppElement
 * @extends PFElement
 */
export class PFAppElement extends PFElement {
    static get observedAttributes() {
        return ['data-fonts-loaded'];
    }

    /**
     * ## Lifecycle
     */

    /**
     * @constructor
     */
    constructor() {
        super();
        this.count = 0;
        /**
         * Tracks active ui elements
         *
         * @property ui
         * @type {Object}
         */
        this.ui = {};

        /**
         * Tracks currently active breakpoint of the window
         *
         * @property currentBreakpoint
         * @type {Object}
         */
        this.currentBreakpoint = '';

        /**
         * Caches window innerWidth
         *
         * @property currentInnerWidth
         * @type {Number}
         */
        this.currentInnerWidth = null;

        /**
         * Caches window innerHeight
         *
         * @property currentInnerHeight
         * @type {Number}
         */
        this.currentInnerHeight = null;

        /**
         * Duration of debounce to use for firing resize events in the app
         *
         * @property resizeDebounceDuration
         * @type {int}
         */
        this.resizeDebounceDuration = 250;

        /**
         * Reference to app level status message container
         *
         * @property statusMessageContainer
         * @type {HTMLElement}
         */
        this.statusMessageContainer = this.querySelector(
            '[pf-app-status-message]'
        );
        this.addEventListener('action', this.onActioned);
    }

    /**
     * This callback is invoked when the element is attached to the DOM.
     * Listens for a user defined event or a click if not defined.
     *
     * @method connectedCallback
     * @callback
     */
    async onInit() {
        this.getInitialBreakpoint();
        this.onDocumentEventHandler = this.onDocumentEvent.bind(this);
        window.addEventListener(
            'resize',
            debounce(this.onResized.bind(this), this.resizeDebounceDuration)
        );

        const cookieImpressions = Number(cookie.get('impressions'));
        if (cookieImpressions) {
            cookie.set('impressions', cookieImpressions + 1);
        } else {
            cookie.set('impressions', 1);
        }

        setTimeout(() => {
            this.dispatchUpdate({ type: EV_APP_INIT });
        }, 0);
    }

    attributeChangedCallback() {
        // TODO: Refactor into init
        setTimeout(() => {
            this.dispatchUpdate({ type: EV_APP_READY });
        }, 0);
    }

    get hasActiveUiGroup() {
        return Object.keys(this.ui).some(key => this.ui[key] !== null);
    }

    /**
     * ## Methods
     */

    /**
     * Hide a UI Element and reset states
     *
     * @method deactivateElement
     * @param {String} uiGroup member of the group the element being deactivated belongs to
     * @param {Boolean} shouldRefocusToTrigger if not transitioning to another ui element
     * @param {HTMLElement} focusTarget
     * @private
     * @async
     * @returns {Promise.<this>}
     */
    async deactivateElement(
        uiGroup,
        shouldRefocusToTrigger = true,
        focusTarget
    ) {
        if (!this.ui[uiGroup]) {
            return;
        }
        const target = this.ui[uiGroup].target;

        if (target.lockFocusWithin || target.trapTabFocusWithin) {
            this.focusManager.untrapFocus();
        }

        await target.deactivate();

        if (shouldRefocusToTrigger && target.refocusTriggerOnDeactivate) {
            this.focusManager.focusFirstFocusable(this.ui[uiGroup].trigger);
        }

        if (focusTarget) {
            focusTarget.focus();
        }

        if (target.shouldLockScroll) {
            if (target.scrollLockContextElement) {
                this.scrollLock.unlockScroll(target.scrollLockContextElement);
            } else {
                this.scrollLock.unlockScroll();
            }
        }

        this.ui[uiGroup] = null;
        this.toggleDocumentHandlers();
    }

    /**
     * Show a UI Element
     * - focus to the first focusable element within the UI Element
     * - trap focus if necesarry
     *
     * @method activateElement
     * @param {String} uiGroup member of the group the element being deactivated belongs to
     * @param {HTMLElement} target element to be activated
     * @param {HTMLElement} trigger element which triggered the activation action
     * @private
     * @async
     * @returns {Promise.<this>}
     */
    async activateElement(uiGroup, target, trigger) {
        if (target.shouldLockScroll) {
            if (target.scrollLockContextElement) {
                this.scrollLock.lockScroll(target.scrollLockContextElement);
            } else {
                this.scrollLock.lockScroll();
            }
        }

        await target.activate();
        this.ui[uiGroup] = { target, trigger };

        // Check a trigger for optional focus-first-focusable attr, if its
        // false do not run any focus management tasks
        if (
            trigger.hasAttribute('focus-first-focusable') &&
            trigger.getAttribute('focus-first-focusable') === 'false'
        ) {
            return;
        }

        if (target.lockFocusWithin) {
            this.focusManager.trapFocus(target.lockFocusWithin);
        }

        if (target.trapTabFocusWithin) {
            this.focusManager.trapTabFocus(target.trapTabFocusWithin);
        }

        if (target.focusFirstFocusable) {
            this.focusManager.focusFirstFocusable(target, false);
        }
        this.toggleDocumentHandlers();
    }

    /**
     * Update dates in the UI group
     *
     * @method changeDatesForElement
     * @param {HTMLElement} target element to be activated
     * @param {Object} the detail of the request,
     * contains the start date and end date
     * @private
     * @async
     * @returns {Promise.<this>}
     */
    async changeDatesForElement(target, detail) {
        await target.changeDates(detail);
    }

    /**
     * Dispatch the update event down to all components
     *
     * @method dispatchUpdate
     * @param {Object} detail event payload
     * @private
     */
    dispatchUpdate(detail) {
        this.eventEmitter.emit('update', { detail });
    }

    /**
     * Retrieve current breakpoint and emit an update
     *
     * @method getInitialBreakpoint
     * @private
     */
    getInitialBreakpoint() {
        this.currentBreakpoint = getPseudoContent(document.body, ':after');

        // TODO Wrapped in a timeout temporarily to some race conditions caused by custom elements
        // not being fully bootstrapped when eventing is happening.
        setTimeout(() => {
            this.dispatchUpdate({ currentBreakpoint: this.currentBreakpoint });
        }, 0);
    }

    /**
     * Sets the app level statusMessageContainer
     *
     * @method setStatusMessage
     * @param {String} msg message to insert into the statusMessageContainer
     * @private
     */
    setStatusMessage(msg) {
        this.statusMessageContainer.innerHTML = '';
        if (this.count % 2) {
            msg += '.';
        } else {
            msg.substring(0, msg.length - 1);
        }
        this.count++;
        if (msg) {
            this.statusMessageContainer.innerHTML = msg;
        }
    }

    /**
     * Checks if the target is a custom element or regular element or provides fallback element
     *
     * @method parseTarget
     * @param {string} targetSelector to check if custom or query and pass back
     * @param {HTMLElement} element fallback element, usually the triggering element
     * @private
     */
    parseTarget(targetSelector, element) {
        let target;
        if (isCustomElement(targetSelector)) {
            target = targetSelector;
        } else if (targetSelector) {
            target = document.querySelector(targetSelector);
        } else {
            target = element;
        }
        return target;
    }

    /**
     * Loops thru the ui groups to see if any are active and toggles a document listener for use
     * in deactivating elements like selects when they are clicked outside of.
     *
     * @method toggleDocumentClickHandler
     * @private
     */
    async toggleDocumentHandlers() {
        if (this.hasActiveUiGroup) {
            document.addEventListener('click', this.onDocumentEventHandler);
            document.addEventListener('focusout', this.onDocumentEventHandler);
        } else {
            document.removeEventListener('click', this.onDocumentEventHandler);
            document.removeEventListener(
                'focusout',
                this.onDocumentEventHandler
            );
        }
    }

    /**
     * ## Handlers
     */

    /**
     * Triage handler for the basic ui activation, deactivation, and toggle activation events.
     *
     * @method onUiEvent
     * @param {Object} detail event detail object passed from on actioned
     * @async
     * @private
     */
    async onUiEvent(detail) {
        const { trigger } = detail;
        const targetSelector = detail.target || trigger.dataset.target;
        const target = this.parseTarget(targetSelector, trigger);
        const focusTarget =
            detail.focusTarget ||
            document.querySelector(trigger.dataset.focusTarget) ||
            null;

        if (!target) {
            return;
        }
        const uiGroup = target.uiGroup;
        if (!uiGroup) {
            return;
        }

        if (detail.type === EV_UI_ACTIVATE) {
            await this.onUiActivate(uiGroup, target, trigger);
        }

        if (detail.type === EV_UI_DEACTIVATE) {
            await this.onUiDeactivate(uiGroup, focusTarget);
        }

        if (detail.type === EV_UI_TOGGLE_ACTIVATION) {
            await this.onUiToggleActivation(
                uiGroup,
                target,
                trigger,
                focusTarget
            );
        }
    }

    /**
     * Public method that allows direct dispatching of ui events via DOM mixins.
     *
     * @method triggerEvent
     * @param {Array|String} actionType
     * @param {String} targetSelector
     * @param {HTMLElement} triggerringElement
     * @async
     * @public
     */
    // TODO: trigger: context.$element
    async triggerEvent(actionType, targetSelector, triggerringElement) {
        // Check if window load event has occured yet otherwise
        // do not allow eventing thru triggerEvent

        this.onUiEvent({
            type: actionType,
            target: targetSelector,
            trigger: triggerringElement,
        });
    }

    /**
     * Handler for ui activation events.
     *
     * @method onUiActivate
     * @param {Array|String} uiGroup that target element belongs too
     * @param {HTMLElement} target element to affect
     * @param {HTMLElement} trigger
     * @async
     * @private
     */
    async onUiActivate(uiGroup, target, trigger) {
        if (!this.ui[uiGroup]) {
            this.activateElement(uiGroup, target, trigger);
            return;
        }

        if (this.ui[uiGroup].target === target) {
            return;
        }

        await this.deactivateElement(uiGroup);
        this.activateElement(uiGroup, target, trigger);
    }

    /**
     * Handler for ui deactivation events.
     *
     * @method onUiDeactivate
     * @param {Array|String} uiGroup event detail object passed from on actioned
     * @param {HTMLElement} focusTarget
     * @private
     */
    async onUiDeactivate(uiGroup, focusTarget) {
        this.deactivateElement(uiGroup, true, focusTarget);
    }

    /**
     * Handler for toggle activation events.
     *
     * @method onUiToggleActivation
     * @param {Array|String} uiGroup that target element belongs too
     * @param {HTMLElement} target element to affect
     * @param {HTMLElement} trigger
     * @async
     * @private
     */
    async onUiToggleActivation(uiGroup, target, trigger, focusTarget) {
        if (!this.ui[uiGroup]) {
            this.activateElement(uiGroup, target, trigger);
            return;
        }

        if (this.ui[uiGroup].target === target) {
            this.deactivateElement(uiGroup, focusTarget);
            return;
        }

        await this.deactivateElement(uiGroup);
        this.activateElement(uiGroup, target, trigger);
    }

    /**
     * Loops thru ui groups and performs actions on them.
     *
     * @method onDocumentEvent
     * @param {Object} ev ev Object
     * @private
     */
    onDocumentEvent(ev) {
        Object.keys(this.ui).forEach(key => {
            if (this.ui[key] !== null) {
                const ui = this.ui[key].target;
                if (ui.deactivateOnClickOutside && ev.type === 'click') {
                    if (!isDescendant(ui, ev.target)) {
                        this.deactivateElement(
                            key,
                            !this.focusManager.isElementFocusable(ev.target)
                        );
                    } else {
                        return;
                    }
                } else if (
                    ui.deactivateOnFocusOutside &&
                    ev.type === 'focusout'
                ) {
                    if (ev.relatedTarget === null) {
                        return;
                    }
                    if (!isDescendant(ui, ev.relatedTarget)) {
                        this.deactivateElement(key, false);
                    } else {
                        return;
                    }
                } else {
                    return;
                }
            }
        });
    }

    /**
     * Handler for change in date range
     * Force UI elements to update as needed.
     *
     * @method onDateRangeChange
     * @param {HTMLElement} trigger element
     * @async
     * @private
     * @returns {Promise.<this>}
     */
    async onDateRangeChange(detail) {
        const { trigger } = detail;
        const uiGroup = trigger.dataset.uiGroup;
        if (!uiGroup) {
            return;
        }

        const targets = Array.from(
            this.querySelectorAll(`[data-ui-group="${uiGroup}"]`)
        );

        await targets.forEach(element => {
            this.changeDatesForElement(element, detail);
        });
    }

    /**
     * Handler for analytics events.
     *
     * @method onAnalytics
     * @param {Object} detail event detail object passed from on actioned
     * @param {string} analyticsKey event key string passed from on actioned
     * @private
     */
    onAnalytics(detail) {
        if (detail.analyticKey) {
            const analyticFunction = detail.eventId;
            switch (detail.analyticKey) {
                case 'happy-tails':
                    happyTailsAnalytics[analyticFunction](detail);
                    break;
                case 'onboarding-quiz':
                    onboardingQuizAnalytics[analyticFunction](detail);
                    break;
                case 'social-login':
                    socialLoginAnalytics[analyticFunction](detail);
                case 'search-and-match':
                    searchAndMatchAnalytics[analyticFunction](detail);
                    break;
                case 'favorites':
                    favoritesAnalytics[analyticFunction](detail);
                    break;
            }
        } else {
            const eventFunctionName = `event${detail.eventId}`;
            if (trackEvent[eventFunctionName]) {
                trackEvent[eventFunctionName](detail);
            }

            const analyticsEventFunctionName = `analytics${detail.eventId}`;
            if (dotcomAnalytics[analyticsEventFunctionName]) {
                dotcomAnalytics[analyticsEventFunctionName](detail);
            }
        }
    }

    /**
     * Handler for deactivate all events.
     *
     * @method onUiDeactivateAll
     * @param {Object} detail event detail object passed from on actioned
     * @async
     * @private
     */
    async onUiDeactivateAll(detail) {
        const changeHashTo = detail.hash;
        const changeHashElement = document.querySelector(detail.hash);
        const uiGroups = Object.keys(this.ui);
        if (uiGroups.length) {
            await uiGroups.forEach(uiGroup => {
                this.deactivateElement(uiGroup, false);
            });
        }
        if (changeHashTo && changeHashElement) {
            location.hash = ''; // reset hash quick
            location.hash = changeHashTo;
            changeHashElement.focus();
        }
    }

    /**
     * Handler for receiving an action. Triage actions here and dispatch updates if necesarry.
     *
     * @method onActioned
     * @param {Object} ev action from component
     * @private
     * @returns {Promise.<this>}
     */
    onActioned(ev) {
        const { detail, analyticKey } = ev;

        switch (detail.type) {
            case EV_UI_ACTIVATE:
                this.onUiEvent(detail);
                break;
            case EV_UI_DEACTIVATE:
                this.onUiEvent(detail);
                break;
            case EV_UI_DEACTIVATE_ALL:
                this.onUiDeactivateAll(detail);
                break;
            case EV_UI_TOGGLE_ACTIVATION:
                this.onUiEvent(detail);
                break;
            case EV_UI_DATE_RANGE_CHANGE:
                this.onDateRangeChange(detail);
                break;
            case 'analytics':
                this.onAnalytics(detail, analyticKey);
                break;
            case 'recaptcha.executed':
                this.onRecaptchaExecuted(detail);
                break;
            case 'recaptcha.validation':
                this.onRecaptchaValidated(detail);
                break;
            default:
                break;
        }

        if (detail.statusText) {
            this.setStatusMessage(detail.statusText.trim());
        }

        // TODO Wrapped in a timeout temporarily to some race conditions caused by custom elements
        // not being fully bootstrapped when eventing is happening.
        setTimeout(() => {
            this.dispatchUpdate(detail);
        }, 0);
    }

    /**
     * Handler to temporarily untrap focus while recaptcha is executed
     *
     * @method onResized
     */
    onRecaptchaExecuted() {
        this.trappedElements = this.focusManager.untrapFocus();
    }

    /**
     * Handler to retrap focus after successful validation
     *
     * @method onResized
     * @private
     */
    onRecaptchaValidated() {
        if (this.trappedElements) {
            this.focusManager.trapFocus(this.trappedElementsfoo);
        }
    }

    /**
     * Handler for window resize events on the app.
     *
     * @method onResized
     * @private
     */
    onResized() {
        this.handleHeightOrWidthResize();

        const newBreakpoint = getPseudoContent(document.body, ':after');

        if (newBreakpoint === this.currentBreakpoint) {
            this.dispatchUpdate({
                type: EV_RESIZE_COMPLETE,
                currentBreakpoint: newBreakpoint,
            });
            return;
        }

        const previousBreakpoint = this.currentBreakpoint;

        this.dispatchUpdate({
            type: EV_RESIZE_BREAKPOINT_CHANGE,
            currentBreakpoint: newBreakpoint,
            previousBreakpoint,
        });
        this.currentBreakpoint = newBreakpoint;
    }

    /**
     * Handler for window height and width resize events.
     *
     * @method handleHeightOrWidthResize
     * @private
     */
    handleHeightOrWidthResize() {
        const newInnerWidth = window.innerWidth;
        const newInnerHeight = window.innerHeight;

        if (newInnerWidth !== this.currentInnerWidth) {
            this.dispatchUpdate({
                type: EV_RESIZE_WIDTH_COMPLETE,
                innerWidth: newInnerWidth,
            });
            this.currentInnerWidth = newInnerWidth;
        }

        if (newInnerHeight !== this.currentInnerHeight) {
            this.dispatchUpdate({
                type: EV_RESIZE_HEIGHT_COMPLETE,
                innerHeight: newInnerHeight,
            });
            this.currentInnerHeight = newInnerHeight;
        }
    }

    /**
     * Handler for clicks of global nav resources
     * @param {HTMLElement} element
     */
    onGlobalNavItemClick(element) {
        // Resources menu is open on homepage and we want more granular control
        // over the tracking events this makes it so we only get the track events
        // when the resources button is clicked
        if (element.hasAttribute('pfdc-header-desktopResourcesBtn')) {
            setTimeout(() => {
                element.classList.contains(CLASS_IS_OPEN)
                    ? trackEvent.eventConsumer003()
                    : trackEvent.eventConsumer004();
            }, 100);
        }

        // Breeds menu is open or closed
        if (element.hasAttribute('pfdc-header-desktopBreedsBtn')) {
            setTimeout(() => {
                analyticsBreed077(element.classList.contains(CLASS_IS_OPEN));
            }, 100);
        }
    }
}

export default PFAppElement;
