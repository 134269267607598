import EventActionMixinBase from './EventActionMixinBase';
import ActionParser from '../ActionParser';

export default class MouseDownMixin extends EventActionMixinBase {
    static _attributeName = 'mix-mousedown';

    onMouseDownEvent(e) {
        ActionParser.executeActions(this._actions, { $mixin: this, $element: this._ele, $event: e }, e);
    }
}
