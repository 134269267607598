import MixinBase from './MixinBase';
import Utils from '../Utils';

export default class AttributeMixin extends MixinBase {
    static _attributeName = 'mix-attribute';

    constructor(ele, value) {
        super(ele, value);

        const attrs = Utils.StringUtils.parseAttributeValue(value);

        for (const attr in attrs) {
            const address = attrs[attr];

            const result = Utils.HTMLElementUtils.findElementContainingAddress(address, ele, true);

            let value;
            if (result.value && typeof result.value === 'object') {
                value = JSON.stringify(result.value);
            } else {
                value = (result.value == null)
                    ? ''
                    : result.value;
            }

            ele.setAttribute(attr, value);
        }
    }
}
