import { PFElement } from '../pf-element/element';
import assert from '../../lib/assert';
import { pollUntilConditionMet, wait } from '../../util/util';

// Private Symbols
const config = Symbol('config');
const form = Symbol('form');
const widgetId = Symbol('widgetId');
const isSetup = Symbol('isSetup');
const setupCallbacks = Symbol('setupCallbacks');

const onValidate = Symbol('onValidate');
const fixCaptchaModalStyles = Symbol('fixCaptchaModalStyles');
const execute = Symbol('execute');

/**
 * This component controls a Google ReCaptcha element.  It is configured via attributes.
 * Currently submits a form after a successful challenge.  Listens for an event upon which it
 * attempts validation.  Checks if this is part of the form attempting validation in order to
 * have multiple recaptchas on a page.
 *
 * Prerequisites:
 * The Recaptcha script must be loaded.
 * https://www.google.com/recaptcha/api.js?render=explicit&onload=recaptchaFormOnload&amp;hl=en
 * An onload callback must be defined and the name added to the onload query parameter in
 * the script source.  This onload callback must be defined before the script tag is
 * included and reside in the global scope.
 *
 * Public Methods:
 * setupRecaptcha: initializes the recaptcha. Run from the onload callback.
 *
 * <pf-recaptcha></pf-recaptcha>
 *
 * @class PFRecaptchaElement
 * @extends PFElement
 *
 */
export class PFRecaptchaElement extends PFElement {
    /**
     * @constructor
     */
    constructor() {
        super();
        this[config] = {
            sitekey: this.getAttribute('sitekey'),
            callback: this[onValidate].bind(this),
            size: this.getAttribute('size') || 'invisible',
            theme: this.getAttribute('theme') || undefined,
            type: this.getAttribute('type') || undefined,
            badge: 'inline',
        };
        this[form] = this.parentForm;
        this[widgetId] = undefined;
        this[isSetup] = false;
        this[setupCallbacks] = [];
        assert(
            this[form] !== null,
            'This element must find an associated form to submit.'
        );
    }

    /**
     * @method parentForm
     * @return {HTMLElement} Parent form
     */
    get parentForm() {
        const forms = Array.from(document.querySelectorAll('form'));
        for (let i = 0; i < forms.length; i++) {
            if (forms[i].contains(this)) {
                return forms[i];
            }
        }
    }

    /**
     * Waits for window.grecaptcha to be on the window and internal parsing is complete.
     * Executes the recaptcha render function which inserts the required DOM, including
     * the recaptcha modal.
     * If an execution command was received before the async google script was loaded, executes
     * all queued commands.
     * @method setupRecaptcha
     * @public
     * @async
     * @return {Promise}
     */
    async setupRecaptcha() {
        await pollUntilConditionMet(() => window.grecaptcha !== undefined);
        // wait for element parsing to complete.  recaptcha intermittently throws an error if
        // thinking their element (inside pf-recaptcha) is not present.
        await this._isInitialized;
        this[widgetId] = window.grecaptcha.render(this, this[config]);
        this.dispatchAction('recaptcha.initialized');
        this[isSetup] = true;
        if (this[setupCallbacks].length) {
            this[setupCallbacks].forEach(callback => callback());
        }
    }

    /**
     * Presenting the challenge if appropriate.  If invisible, a visible
     * challenge may not be presented.  If the recaptcha script has not been loaded and the
     * required hooks run, queues up the execution command.
     *
     * @method execute
     * @private
     * @async
     * @return {Promise}
     */
    async [execute](...args) {
        if (!this[isSetup]) {
            this[setupCallbacks].push(() => this[execute](args));
            return;
        }
        try {
            window.grecaptcha.reset(this[widgetId]);
            window.grecaptcha.execute(this[widgetId]);
            this.dispatchAction('recaptcha.executed');
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Override in a subclass if behavior must change.
     *
     * @method onValidate
     * @private
     * @return {void}
     */
    [onValidate]() {
        this.eventEmitter.emit('recaptcha.validation', this);
        this.dispatchAction('recaptcha.validation', this);
    }

    /**
     * @method onUpdated
     * @param {Object} event
     */
    onUpdated(event) {
        if (event.detail.type === 'recaptcha.execute') {
            if (event.detail.trigger.form === this[form]) {
                this[execute]();
            }
        }
    }
}

export default PFRecaptchaElement;
