import React, { Component } from 'react';
import { withStateMachine } from 'react-automata';
import statechart from './statechart';

const DEFAULT_OBSERVER_OPTIONS = {
    root: null,
    threshold: 0.1,
};

const lazyLoad = WrappedComponent => {
    return withStateMachine(statechart)(
        class ConditionalDataLoad extends Component {
            /* *******************************************
             * Class Properties
             ********************************************/
            wrappedComponent = React.createRef();

            state = {
                observed: false,
                machineState: this.props.machineState.value,
                lazyLoadRef: this.wrappedComponent,
            };

            /* *******************************************
             * Lifecycle
             ********************************************/
            componentWillMount() {
                this.enabled();
            }

            componentDidMount() {
                setTimeout(() => this.createIntersectionObserver(), 1000);
            }

            /* *******************************************
             * Events
             ********************************************/
            onObserved = (entries, observer) => {
                if ('isIntersecting' in entries[0]) {
                    // Fail safe for missing isIntersecting property on mobile devices
                    if (!entries[0].isIntersecting) {
                        return;
                    }

                    this.setState(
                        {
                            observed: true,
                        },
                        () => {
                            this.props.transition('UPDATED');
                        }
                    );
                }
            };

            /* *******************************************
             * Methods
             ********************************************/
            enabled() {
                this.props.transition('ENABLED');
            }

            disabled() {
                this.props.transition('DISABLED');
            }

            createIntersectionObserver() {
                const element = this.wrappedComponent.current;
                this.observer = new IntersectionObserver(
                    this.onObserved,
                    DEFAULT_OBSERVER_OPTIONS
                );
                this.observer.observe(element);
            }

            /* *******************************************
             * Actions (used with x-state)
             ********************************************/
            checkIfShouldLoad = () => {
                // if intersection observer in isOnScreen state, criteria met. Else, not met.
                if (this.state.observed) {
                    this.props.transition('LAZY_LOADING_CRITERIA_MET');
                } else if (!this.state.observed) {
                    this.props.transition('LAZY_LOADING_CRITERIA_NOT_MET');
                }
            };

            disableComponent = () => {
                this.observer.disconnect();
            };

            /* *******************************************
             * Render
             ********************************************/

            render() {
                // TODO: Not sure if this div wrapper is best way to go, could cause
                // style issues later down the road, do we need to forward this ref
                // to the wrapped component?
                return (
                    <div ref={this.wrappedComponent}>
                        <WrappedComponent {...this.props} {...this.state} />
                    </div>
                );
            }
        }
    );
};

export default lazyLoad;
