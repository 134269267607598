import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';

import {
    ICON_COLORS_CLASS_MAP,
    ICON_SIZES_CLASS_MAP,
    ICON_VERTICAL_ALIGN_CLASS_MAP,
    ICON_NAMES_LIST,
} from './constants';

/**
 * Creates svg icon with configurable props
 * @class SvgIcon
 * @extends React.Component
 */
class SvgIcon extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        /** The color of the icon */
        color: PropTypes.string,
        extensionClasses: PropTypes.object,
        size: PropTypes.oneOf(Object.keys(ICON_SIZES_CLASS_MAP)),
        /** ID must match one of the icon-* svg names. ID will be prefixed with 'icon-' */
        svgId: PropTypes.oneOf(ICON_NAMES_LIST).isRequired,
        vAlign: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        color: '',
        extensionClasses: {},
        vAlign: '',
    };

    /**
     * Build classnames for svg icon
     * @method getClassNames
     * @private
     * @returns {string}
     */
    getClassNames() {
        const { color, extensionClasses, size, vAlign } = this.props;

        return buildClassNames({
            ['icon']: true,
            [ICON_SIZES_CLASS_MAP[size]]: Object.keys(
                ICON_SIZES_CLASS_MAP
            ).includes(size),
            [ICON_COLORS_CLASS_MAP[color]]: Object.keys(
                ICON_COLORS_CLASS_MAP
            ).includes(color),
            [ICON_VERTICAL_ALIGN_CLASS_MAP[vAlign]]: Object.keys(
                ICON_VERTICAL_ALIGN_CLASS_MAP
            ).includes(vAlign),
            ...extensionClasses,
        });
    }

    render() {
        const { svgId } = this.props;

        return (
            <span className={this.getClassNames()}>
                <svg focusable="false" role="presentation">
                    <use xlinkHref={`#icon-${svgId}`} />
                </svg>
            </span>
        );
    }
}

export default SvgIcon;
