const I18n = require('../../../core/scripts/data/i18nJSON.json');

// TODO: port this to extend BaseModel in the future?
export default class AnimalModel {
    // TODO: use a Proxy or something in the future to clean this up
    static fromSearchResults(animalData) {
        const instance = new AnimalModel();

        // TODO: in the future make data private/restrict access to it so raw properties aren't accessed
        instance.data = animalData;

        return instance;
    }

    /**
     * @type {{ id: Number, breeds_label: String, type: { id: Number, name: String, }, species: { id: Number, name: String, }, primary_breed: { id: Number, name: String, }, secondary_breed: { id: Number, name: String, }, is_mixed_breed: Boolean, age: String, sex: String, size: String, name: String, description: String, organization_animal_identifier: String, photo_urls: Array, videos: Array, adoption_status: String, attributes: Array, home_environment_attributes: { good_with_children: Boolean, good_with_dogs: Boolean, good_with_cats: Boolean, good_with_other_animals: Boolean }, tags: Array<String>, adoption_fee_waived: Boolean, published_at: String, social_sharing: { facebook_url: String, facebook_title: String, facebook_description: String, twitter_url: String, twitter_title: String, twitter_description: String, pinterest_url: String, pinterest_title: String, pinterest_description: String, email_url: String, email_title: String, email_description: String, }, } }
     */
    data = null;

    get adoptionFee() {
        return this.data.animal.public_adoption_fee;
    }

    get adoptionStatus() {
        return this.data.animal.adoption_status;
    }

    get age() {
        return this.data.animal.age;
    }

    get attributes() {
        return this.data.animal.attributes.join(', ');
    }

    get city() {
        return this.data.location.address.city;
    }

    get coatLength() {
        return this.data.animal.coat_length;
    }

    get contactEmail() {
        return this.data.contact.email;
    }

    get detailUrl() {
        return this.data.animal.social_sharing.email_url;
    }

    get emailMailToUrl() {
        const {
            email_description: emailDesc,
            email_title: emailTitle,
        } = this.data.animal.social_sharing;

        const subject = encodeURIComponent(emailTitle);
        const body = encodeURIComponent(emailDesc);

        return `mailto:?subject=${subject}&body=${body}`;
    }

    get hasVideo() {
        return this.data.animal.videos.length > 0;
    }

    get id() {
        return this.data.animal.id;
    }

    get isTransport() {
        return this.data.location.is_transport;
    }

    get goodWith() {
        let data = [];
        const attributes = this.data.animal.home_environment_attributes;

        if (attributes) {
            if (attributes.good_with_children) {
                data.push('Children');
            }

            if (attributes.good_with_dogs) {
                data.push('Dogs');
            }

            if (attributes.good_with_cats) {
                data.push('Cats');
            }

            if (attributes.good_with_other_animals) {
                data.push('Other Animals');
            }
        }

        data = data.join(',') || false;

        return data;
    }

    get mixedBreed() {
        return this.data.animal.is_mixed_breed;
    }

    get name() {
        return this.data.animal.name;
    }

    get numberOfPhotos() {
        return this.data.animal.photo_urls.length;
    }

    get numberOfVideos() {
        return this.data.animal.videos.length;
    }

    get organizationId() {
        return this.data.organization.display_id;
    }

    get organizationName() {
        return this.data.organization.name;
    }

    get petMatchType() {
        if (this.profileMatchScore == null) {
            return 'n/a';
        }

        if (this.profileMatchScore >= 5) {
            return 'best match';
        }

        if (this.profileMatchScore > 0) {
            return 'good match';
        }

        return 'none';
    }

    get photoUrl() {
        return this.data.animal.primary_photo_url;
    }

    get photoUrlCropped() {
        return this.data.animal.primary_photo_cropped_url;
    }

    get postalCode() {
        return this.data.location.address.postal_code;
    }

    get primaryBreed() {
        return this.data.animal.primary_breed.name;
    }

    get primaryColor() {
        return this.data.animal.primary_color;
    }

    get profileMatchScore() {
        return this.data.profile_match_score;
    }

    get publishedAt() {
        return this.data.animal.published_at;
    }

    get secondaryBreed() {
        let secondaryBreed = null;

        if (this.data.animal.secondary_breed) {
            secondaryBreed = this.data.animal.secondary_breed.name;
        }

        return secondaryBreed;
    }

    get sex() {
        return this.data.animal.sex;
    }

    get size() {
        return this.data.animal.size;
    }

    get socialSharing() {
        return this.data.animal.social_sharing;
    }

    get species() {
        return this.data.animal.species.name;
    }

    get state() {
        return this.data.location.address.state;
    }

    get typeId() {
        return this.data.animal.type.id;
    }

    get typeName() {
        return this.data.animal.type.name;
    }

    get typeSpecificAge() {
        const typeId = this.typeId;
        const age = this.age;

        // attempt specific lookup
        const specificAgeKey = `context.animal.age.${typeId}.${age}`;
        if (specificAgeKey in I18n) {
            return I18n[specificAgeKey];
        }

        // attempt default lookup
        const defaultAgeKey = `context.animal.age.default.${age}`;
        if (defaultAgeKey in I18n) {
            return I18n[defaultAgeKey];
        }

        // couldn't find anything; return what we got
        return age;
    }

    get videoType() {
        const videos = this.data.animal.videos;
        let videoType = null;

        if (videos.length) {
            videoType = videos[0].video_service;
        }

        return videoType;
    }
}
