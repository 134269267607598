import Mixins from '../Mixins';
import MixinBase from './MixinBase';
import FocusManager from '../../FocusManager';

export default class FocusFirstFocusableOnRenderMixin extends MixinBase {
    static _attributeName = 'mix-focus-first-focusable-on-render';
    focusManager = new FocusManager();

    constructor(ele, value) {
        super(ele, value);
        this.focusManager.focusFirstFocusable(ele);
    }
}
