import ActionParser from '../ActionParser';
import ObjectExtensions from '../ObjectExtensions';
import Utils from '../Utils';
import MixinBase from './MixinBase';

export default class EventMixinBase extends MixinBase {
    _boundEvents = [];

    constructor(ele, value) {
        super(ele, value);

        this._boundEvents = ObjectExtensions.addEventAutoWiring(this, ele);
    }

    destroy() {
        for (let i = 0; i < this._boundEvents.length; i++) {
            this._ele.removeEventListener(this._boundEvents[i].eventName, this._boundEvents[i].boundFn);
        }
        this._boundEvents = [];
    }
}
