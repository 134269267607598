import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * non breaking space
 * forces re-read of existing text, but isn't read itself
 * @type {string}
 */
const APPEND_CHAR = '\u00A0';

/**
 * @class LiveRegionAlert
 * @extends Component
 */
class LiveRegionAlert extends Component {
    /**
     * Used to check even number for text append in render below
     * @type {Number}
     */
    renderCounter = 0;

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        alert: PropTypes.string,
        busy: PropTypes.bool,
        politeness: PropTypes.string,
        atomic: PropTypes.bool,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        alert: '',
        busy: null,
        politeness: 'polite',
        atomic: true,
    };

    render() {
        const { alert, atomic, busy, politeness } = this.props;

        // This alternates adding a period to each even change
        const alertMsg =
            this.renderCounter % 2 ? `${alert}${APPEND_CHAR}` : alert;
        this.renderCounter++;

        return (
            <Fragment>
                <div
                    className="u-isVisuallyHidden"
                    aria-live={politeness}
                    aria-busy={busy}
                    aria-atomic={atomic}
                >
                    {alertMsg}
                </div>
            </Fragment>
        );
    }
}

export default LiveRegionAlert;
