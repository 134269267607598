const ELLIPSIS = '\u2026 ';
const WHITESPACE_REGEX = /(?=\s)/;
const TRAILING_WHITESPACE_REGEX = /\s+$/;
const TAIL_MARGIN_ID = 'tail-margin';
const TAIL_MARGIN_SEL = '#' + TAIL_MARGIN_ID;
const flatMap = (fn, a) => [].concat(...a.map(fn));
const groupBy10 = ch =>
  ch.length > 10 ?
    ch.match(/.{1,10}/g) : ch;

// Truncate the text of `element` such that it does not exceed `lineCount`.
// Return `true` if we need to truncate by character, else return `false`.
function truncateByWord(element, lineCount, tailMarginEl, comparisonBuffer) {
  const innerHTML = element.innerHTML;

  // Split the text of `element` by whitespace, then max 10 length.
  let whitespaceChunks = innerHTML.split(WHITESPACE_REGEX);
  let chunks = flatMap(groupBy10, whitespaceChunks);

  // The text does not contain whitespace; we need to attempt to truncate
  // by character.
  if (chunks.length === 1) {
    return {
      truncateCharNeeded: true,
      prevOffsetHeight: null,
      currentlineCount: 1
    };
  }

  // Loop over the chunks, and try to fit more chunks into the `element`.
  let i = -1;
  const length = chunks.length;
  let newInnerHTML = tailMarginEl;
  let prevOffsetHeight = 0;
  let offsetHeight;
  let currentlineCount = 0;
  let didBreak;

  // Count lines by checking when the new height exceeds the previous height,
  // and stop once we exceed the desired line count.
  while (++i < length) {
    newInnerHTML += chunks[i];
    element.innerHTML = newInnerHTML;
    offsetHeight = element.offsetHeight;

    didBreak = offsetHeight > (prevOffsetHeight + comparisonBuffer);
    prevOffsetHeight = offsetHeight;

    if (didBreak) {
      currentlineCount += 1;

      if (currentlineCount > lineCount) {
        element.removeChild(element.querySelector(TAIL_MARGIN_SEL));
        return {
          truncateCharNeeded: true,
          prevOffsetHeight,
          currentlineCount
        };
      }
    }
  }

  element.removeChild(element.querySelector(TAIL_MARGIN_SEL));
  return {
    truncateCharNeeded: false
  };
}

// Append `ellipsisChar` to `element`, trimming off trailing characters
// in `element` such that `element` will not exceed `lineCount`.
function truncateByCharacter(element, lineCount, tailMarginEl, comparisonBuffer, ellipsisChar, byWordState) {
  const innerHTML = tailMarginEl + element.innerHTML;
  let length = innerHTML.length;
  let prevOffsetHeight = byWordState.prevOffsetHeight;
  let offsetHeight;
  let currentlineCount = byWordState.currentlineCount;
  let didUnbreak;

  // In each iteration, we trim off one trailing character . Also trim
  // off any trailing punctuation before appending the `ellipsisChar`.
  while (length > 0) {
    element.innerHTML = innerHTML
      .substring(0, length)
      .replace(TRAILING_WHITESPACE_REGEX, '')
      + ellipsisChar;

    offsetHeight = element.offsetHeight;
    didUnbreak = offsetHeight < (prevOffsetHeight - comparisonBuffer);
    prevOffsetHeight = offsetHeight;

    if (didUnbreak) {
      currentlineCount -= 1;
      if (currentlineCount <= lineCount) {
        element.removeChild(element.querySelector(TAIL_MARGIN_SEL));
        return;
      }
    }

    length--;
  }
  element.removeChild(element.querySelector(TAIL_MARGIN_SEL));
}

function calcElementOffsetHeight(element, tailMarginEl) {
  element.insertAdjacentHTML('afterbegin', tailMarginEl);
  const offsetHeight = element.offsetHeight;
  element.removeChild(element.querySelector(TAIL_MARGIN_SEL));
  return offsetHeight;
}

export default function(element, { lineCount, ellipsisChar, tailMargin = '0px' } = {}) {

  // Temp element for reserving space at the end of the truncated text
  const tailMarginEl = `<div id="${TAIL_MARGIN_ID}" style="visibility: hidden; display: inline-block; width: ${tailMargin};"></div>`;

  // Read the `line-height` of `element`, and use it to compute the height of
  // `element` required to fit the given `lineCount`.
  const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
  const maximumHeight = lineCount * lineHeight;
  const elementOffsetHeight = calcElementOffsetHeight(element, tailMarginEl);

  // Exit if text does not overflow the `element`.
  if (elementOffsetHeight <= maximumHeight) {
    return false;
  }

  // How much change in height is tolerated before we update currentlineCount.
  // This accounts for potential variations in height of the inner content.
  const comparisonBuffer = lineHeight / 2;

  const byWordState = truncateByWord(element, lineCount, tailMarginEl, comparisonBuffer);

  if (byWordState.truncateCharNeeded === true) {
    truncateByCharacter(element, lineCount, tailMarginEl, comparisonBuffer, ellipsisChar || ELLIPSIS, byWordState);
  }

  return true;
}
