/**
 * Default limit per page for available favorites
 * @const
 * @type {Number}
 */
export const DEFAULT_LIMIT = 24;

/**
 * Max days unavailable pets will appear on favorites page
 * @const
 * @type {Number}
 */
export const UNAVAILABLE_MAX_DAYS = 30;

/**
 * The name of the session cookie
 * @const
 * @type {string}
 */
export const COOKIE_NAME = 'pending_favorite';

/**
 * Duration in days before the cookie expires
 * @const
 * @type {number}
 */
export const COOKIE_EXPIRATION = 1 / 48;

/**
 * Recently Saved - sort from most recently saved
 * @const
 * @type {String}
 */
export const RECENTLY_SAVED = 'recentlySaved';

/**
 * Alphabetical by name ascending
 * @const
 * @type {String}
 */
export const NAME_ASC = 'nameAsc';

/**
 * Alphabetical by name descending
 * @const
 * @type {String}
 */
export const NAME_DESC = 'nameDesc';

/**
 * Recently Saved - sort from most recently saved
 * @const
 * @type {String}
 */
export const TIME_ON_PETFINDER = 'timeOnPetfinder';

/**
 * Model for mapping to endpoint sort options
 * @const
 * @type {Object}
 */
export const SORT_OPTIONS = {
    [RECENTLY_SAVED]: '-created',
    [NAME_ASC]: 'name',
    [NAME_DESC]: '-name',
    [TIME_ON_PETFINDER]: 'published',
};
