import BaseAnalyticPropertiesModel from '../BaseAnalyticPropertiesModel';

export default class AnimalSearchPropertiesModel extends BaseAnalyticPropertiesModel {
    get defaultData() {
        return {
            animalType: '',
            location: '',
            breed: '',
            age: '',
            gender: '',
            shelter: '',
            distance: '',
            size: '',
            coatLength: '',
            color: '',
            attribute: '',
            household: '',
            daysOnPetfinder: '',
            animalName: '',
            species: '',
            initiatingSite: '',
            transportable: '',
            petMatchType: '',
        };
    }

    /**
     * Checks filter value type and returns proper values for data points
     * @param {Mixed} filter
     * @param {String} nullText
     *
     * @returns {Mixed}
     * @private
     */
    _filterToValue(filter, nullText) {
        if (Array.isArray(filter)) {
            if (filter[0] === 'undefined') {
                return nullText;
            }

            return filter.length ? filter.join(',').toLowerCase() : nullText;
        }

        if (typeof filter === 'string') {
            return filter !== '' ? filter.toLowerCase() : nullText;
        }
        return nullText;
    }

    /**
     * Build animal search property data for type
     *
     * @returns {Object}
     */
    animalType() {
        return this._buildProperty(
            'petSearchAnimalType',
            44,
            this._filterToValue(this._data.animalType, this.nullText)
        );
    }

    /**
     * Build animal search property data for location
     *
     * @returns {Object}
     */
    location() {
        return this._buildProperty(
            'petSearchLocation',
            45,
            this._filterToValue(this._data.location, this.nullText)
        );
    }

    /**
     * Build animal search property data for breed
     *
     * @returns {Object}
     */
    breed() {
        return this._buildProperty(
            'petSearchBreed',
            46,
            this._filterToValue(this._data.breed, this.nullText)
        );
    }

    /**
     * Build animal search property data for age
     *
     * @returns {Object}
     */
    age() {
        return this._buildProperty(
            'petSearchAge',
            47,
            this._filterToValue(this._data.age, this.nullText)
        );
    }

    /**
     * Build animal search property data for gender
     *
     * @returns {Object}
     */
    gender() {
        return this._buildProperty(
            'petSearchGender',
            48,
            this._filterToValue(this._data.gender, this.nullText)
        );
    }

    /**
     * Build animal search property data for shelter
     *
     * @returns {Object}
     */
    shelter() {
        return this._buildProperty(
            'petSearchShelter',
            49,
            this._filterToValue(this._data.shelter, this.nullText)
        );
    }

    /**
     * Build animal search property data for distance
     *
     * @returns {Object}
     */
    distance() {
        return this._buildProperty(
            'petSearchDistance',
            50,
            this._filterToValue(this._data.distance, this.nullText)
        );
    }

    /**
     * Build animal search property data for size
     *
     * @returns {Object}
     */
    size() {
        return this._buildProperty(
            'petSearchSize',
            51,
            this._filterToValue(this._data.size, this.nullText)
        );
    }

    /**
     * Build animal search property data for coat length
     *
     * @returns {Object}
     */
    coatLength() {
        return this._buildProperty(
            'petSearchCoatLength',
            52,
            this._filterToValue(this._data.coatLength, this.nullText)
        );
    }

    /**
     * Build animal search property data for color
     *
     * @returns {Object}
     */
    color() {
        return this._buildProperty(
            'petSearchColor',
            53,
            this._filterToValue(this._data.color, this.nullText)
        );
    }

    /**
     * Build animal search property data for attribute
     *
     * @returns {Object}
     */
    attribute() {
        return this._buildProperty(
            'petSearchCare',
            54,
            this._filterToValue(this._data.attribute, this.nullText)
        );
    }

    /**
     * Build animal search property data for household
     *
     * @returns {Object}
     */
    household() {
        return this._buildProperty(
            'petSearchHousehold',
            55,
            this._filterToValue(this._data.household, this.nullText)
        );
    }

    /**
     * Build animal search property data for days on Petfinder
     *
     * @returns {Object}
     */
    daysOnPetfinder() {
        return this._buildProperty(
            'petSearchDaysOnSite',
            56,
            this._filterToValue(this._data.daysOnPetfinder, this.nullText)
        );
    }

    /**
     * Build animal search property data for animal name
     *
     * @returns {Object}
     */
    animalName() {
        return this._buildProperty(
            'petSearchPetName',
            57,
            this._filterToValue(this._data.animalName, this.nullText)
        );
    }

    /**
     * Build animal search property data for animal species
     *
     * @returns {Object}
     */
    species() {
        return this._buildProperty(
            'petSearchSpecies',
            false,
            this._filterToValue(
                this._data.species,
                this._filterToValue(this._data.animalType, this.nullText)
            )
        );
    }

    /**
     * Build user login and registration property for initiating site feature
     *
     * @returns {Object}
     */
    initiatingSite() {
        return this._buildProperty(
            'initiatingSiteFeature',
            79,
            this._data.initiatingSite || ''
        );
    }

    /**
     * Build animal search property data for animal species
     *
     * @returns {Object}
     */
    transportable() {
        return this._buildProperty(
            'petSearchTransport',
            98,
            this._filterToValue(
                this._data.transportable ? 'include' : 'exclude',
                this._filterToValue(this._data.transportable, this.nullText)
            )
        );
    }

    /**
     * Build animal search property data for the pet match type
     *
     * @returns {Object}
     */
    petMatchType() {
        return this._buildProperty(
            'petMatchType',
            115,
            this._filterToValue(
                this._data.petMatchType,
                this._filterToValue(this._data.petMatchType, this.nullText)
            )
        );
    }
}
