import EventActionMixinBase from './EventActionMixinBase';
import ActionParser from '../ActionParser';

export default class InputMixin extends EventActionMixinBase {
    static _attributeName = 'mix-input';

    onInputEvent(e) {
        ActionParser.executeActions(this._actions, { $mixin: this, $element: this._ele, $event: e }, e);
    }
}
