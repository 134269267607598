import StateController from '../../../../core/scripts/lib/wirey/state/StateController';

// generics
import PaginationStateController from '../generic/PaginationStateController';
import ResultsStateController from '../generic/ResultsStateController';

// specific (singletons)
import animalSearchFiltersState from './animalSearchFiltersStateController';
import savedSearchStateController from './savedSearchStateController';

export class AnimalSearchStateController extends StateController {
    pagination = new PaginationStateController(1);
    filters = animalSearchFiltersState;
    results = new ResultsStateController();
    savedSearch = savedSearchStateController;
    constructor() {
        super();
        this.addSubstate('pagination', this.pagination);
        this.addSubstate('filters', this.filters);
        this.addSubstate('results', this.results);
        this.addSubstate('savedSearch', this.savedSearch);
    }
}

export default new AnimalSearchStateController();
