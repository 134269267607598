import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _noop from 'lodash/noop';

import { buildTcombFormComponent } from '../../../../../core/scripts/react-components/tcomb/formFactory';
import Svg from '../../../../../core/scripts/react-components/generics/Svg';
import LoaderButton from '../../../../../core/scripts/react-components/specifics/LoaderButton';
import { socialLoginLink } from '../../../../../core/scripts/repositories/users/users';

import LoginFormType, { FIELDS } from './formType';

import options from './options';

class PasswordConfirmationForm extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        extensionClassNames: PropTypes.objectOf(PropTypes.bool),
        forgotPasswordHref: PropTypes.string.isRequired,
        value: PropTypes.shape({
            login_path: PropTypes.string, // eslint-disable-line camelcase
            next: PropTypes.string,
            next_params: PropTypes.string, // eslint-disable-line camelcase
        }),
        onSubmissionSuccess: PropTypes.func,
        onSubmissionError: PropTypes.func,
        onForgotPasswordClick: PropTypes.func,
        email: PropTypes.string,
        token: PropTypes.string,
        provider: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        extensionClassNames: {},
        onSubmissionSuccess: _noop,
        onSubmissionError: _noop,
        onForgotPasswordClick: _noop,
    };

    get rootClassNames() {
        return buildClassNames({
            ['loginForm']: true,
            ...this.props.extensionClassNames,
        });
    }

    /**
     * @param {Object} value
     * @param {Object} props
     * @private
     */
    handleSubmissionSuccess = (value, props) => {
        this.props.onSubmissionSuccess(value, props);
    };

    /**
     * @param {Object} value
     * @param {Object} errors
     * @private
     */
    handleSubmissionError = (value, errors) => {
        this.props.onSubmissionError(errors);
    };

    /**
     * @param {Event} ev
     * @private
     */
    handleForgotPasswordClick = ev => {
        this.props.onForgotPasswordClick();
    };

    renderGlobalError = () => {
        return errors => {
            return (
                <div className="media m-media_gutterSm">
                    <div className="media-img">
                        <span className="icon icon_18 m-icon_block m-icon_colorError">
                            <Svg
                                aria-hidden="true"
                                xlinkHref="icon-circleAlert"
                            />
                        </span>
                    </div>
                    <div className="media-bd">
                        <h3 tabIndex="-1" className="txt m-txt_colorError">
                            There was an error logging you into your account.
                            Please try again. If you continue to have trouble
                            logging in, your account may be locked. Your account
                            is locked for 30 minutes after 5 incorrect login
                            attempts.&nbsp;
                        </h3>
                        <a
                            href={this.props.forgotPasswordHref}
                            className="txt m-txt_colorError m-txt_underline"
                            onClick={this.handleForgotPasswordClick}
                        >
                            Recover your password.
                        </a>
                    </div>
                </div>
            );
        };
    };

    render() {
        const Form = buildTcombFormComponent(
            'SocialLoginLinkForm',
            {
                type: LoginFormType,
                value: Object.assign({}, this.props.value, {
                    [FIELDS.USERNAME]: this.props.email,
                    [FIELDS.TOKEN]: this.props.token,
                    [FIELDS.PROVIDER]: this.props.provider,
                }),
                options,
            },
            {
                onSubmit: socialLoginLink,
            }
        );

        return (
            <div className={this.rootClassNames}>
                <Form
                    onSubmissionSuccess={this.handleSubmissionSuccess}
                    onSubmissionError={this.handleSubmissionError}
                    renderGlobalError={this.renderGlobalError}
                >
                    {form => (
                        <form
                            className="loginForm-form"
                            action="/user/social-login/"
                            method="post"
                            noValidate={true}
                            onSubmit={form.handleSubmit}
                        >
                            {form.component}
                            <div className="loginForm-form-submitBtn">
                                <LoaderButton
                                    active={form.isSubmitting}
                                    extensionClassNames={{
                                        ['m-btn_full']: true,
                                    }}
                                    type="submit"
                                    label={this.props.submitLabel}
                                />
                            </div>
                        </form>
                    )}
                </Form>
            </div>
        );
    }
}

export default PasswordConfirmationForm;
