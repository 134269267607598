import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../Modal';
import { CLOSE_BTN_LABEL } from '../../../Modal/constants';

/**
 * Modal component child component for find a pet menu mobile other pets view
 * @class OtherPetTypesModal
 * @extends React.Component
 */
class OtherPetTypesModal extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        otherPetTypeButtons: PropTypes.arrayOf(PropTypes.element),
        onClose: PropTypes.func.isRequired,
    };

    /**
     * Build classnames for modal
     * @method getModalClassNames
     * @private
     * @returns {string}
     */
    getModalClassNames() {
        return {
            ['modal_findAPetOtherPetTypes']: true,
        };
    }

    /**
     * @method handleClick
     * @private
     */
    handleCloseClick = () => {
        this.props.onClose();
    };

    render() {
        return (
            <Modal extensionClasses={this.getModalClassNames()}>
                <div className="modal-header" />
                <div className="modal-content">
                    <div className="findAPetMenu-modalOtherPetTypes">
                        <ul className="findAPetMenu-modalOtherPetTypesList">
                            {this.props.otherPetTypeButtons.map(
                                (option, index) => (
                                    <li
                                        key={index}
                                        className="findAPetMenu-modalOtherPetTypesListItem"
                                    >
                                        {option}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>

                    <button
                        className="modal-closeBtn"
                        onClick={this.handleCloseClick}
                        type="button"
                        aria-label={CLOSE_BTN_LABEL}
                    >
                        {CLOSE_BTN_LABEL}
                    </button>
                </div>
            </Modal>
        );
    }
}

export default OtherPetTypesModal;
