import { html } from '../../../../core/scripts/util/util';

export default vm => {
    return html`
        <button
            class="favoriteBtn ${vm.extensionClasses}"
            aria-label="$${vm.ariaLabel}"
        >
            <svg role="img">
                <use xlink:href="#icon-favorite_outline" />
            </svg>
        </button>
    `;
};
