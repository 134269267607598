import React, { Fragment } from 'react';
import _get from 'lodash/get';

import FormErrorSummaryContainer from '../components/FormErrorSummaryContainer';

/**
 * Default config options for the tcomb form factory
 * @type {Object}
 */
export const FORM_CONFIG_DEFAULTS = {
    debounceDuration: 0,
    keepSearchParamHistory: false,
    submitButton: {
        classNames: 'btn btn_solid',
        label: 'Submit',
    },
    errors: {
        iconConfig: {
            xlinkHref: 'icon-circleAlert',
        },
    },
    // This is just here to remind authors to provide an onSubmit function
    onSubmit: async () => {
        throw 'You must provide an "onSubmit" function to formConfig param of buildTcombFormComponent. It should return a resolved promise with data or true.';
    },
};

/**
 * Default options for tcomb-form used in tcomb form factory, this controls the default template of
 * the form if you do not give the form factory function an explicit template
 * @type {Object}
 */
export const FORM_OPTIONS_DEFAULTS = {
    // Default form template, this is a good starting point for a custom template
    template: locals => {
        return (
            <Fragment>
                <FormErrorSummaryContainer hasError={locals.hasError}>
                    <div className="u-vr4x">{locals.error}</div>
                </FormErrorSummaryContainer>

                <div className="grid grid_gutterLg u-vr4x">
                    {Object.keys(locals.inputs).map(key => {
                        const isHiddenField =
                            _get(
                                locals.inputs[key],
                                'props.options.type',
                                ''
                            ) === 'hidden';

                        // Do not add grid col if the field is a hidden type as this
                        // will add styles possibly
                        if (isHiddenField) {
                            return locals.inputs[key];
                        }

                        return (
                            <div key={key} className="grid-col grid-col_1/1">
                                {locals.inputs[key]}
                            </div>
                        );
                    })}
                </div>
            </Fragment>
        );
    },
};
