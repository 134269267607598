import t from 'tcomb';

export const HelloBar = t.struct(
    {
        active: t.Boolean,
        show_hello_bar: t.Boolean,
        hello_bar_normal_text: t.String,
        hello_bar_bold_text: t.String,
        hello_bar_link_target_url: t.String,
    },
    'Hello Bar'
);
