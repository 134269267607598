import React, { Component } from 'react';
import { withStateMachine } from 'react-automata';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _noop from 'lodash/noop';

import { buildDescribedById, DESCRIBED_BY_ID_ENUM } from '../../tcomb/util';

import Svg from '../Svg';
import Tooltip from '../../specifics/Tooltip';

import statechart from './statechart';

class Field extends Component {
    constructor(props) {
        super(props);

        this.state = { showPassword: false };
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        ['aria-describedby']: PropTypes.string,
        ['aria-invalid']: PropTypes.bool,
        disabled: PropTypes.bool,
        extensionClassNames: PropTypes.objectOf(PropTypes.bool),
        error: PropTypes.string,
        hasError: PropTypes.bool,
        help: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        labelTooltip: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        rows: PropTypes.string,
        step: PropTypes.string,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        inputMode: PropTypes.string,
        readonly: PropTypes.bool,
        required: PropTypes.bool,
        /**
         * The type of input that should be output
         */
        type: PropTypes.oneOf([
            'text',
            'email',
            'password',
            'number',
            'url',
            'tel',
            'hidden',
            'textarea',
        ]),
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                text: PropTypes.string,
            })
        ),
        value: PropTypes.string.isRequired,
        dataTestIds: PropTypes.objectOf(PropTypes.string),
        maxLength: PropTypes.number,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        disabled: null,
        extensionClassNames: {},
        help: null,
        label: null,
        labelTooltip: null,
        placeholder: null,
        readonly: null,
        rows: null,
        step: null,
        ['aria-describedby']: null,
        ['aria-invalid']: null,
        type: 'text',
        options: null,
        onFocus: _noop,
        onBlur: _noop,
        inputMode: null,
        dataTestIds: {
            field: null,
            error: null,
            help: null,
        },
        maxLength: null,
    };

    componentDidMount() {
        this.checkIfInputHasValue();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.hasError !== this.props.hasError) {
            if (this.props.hasError) {
                // SetTimeout protects against 'focus' and 'has_error' transitions firing at the same time
                setTimeout(() => {
                    this.props.transition('HAS_ERROR');
                }, 0);
            } else {
                this.props.transition('HAS_NO_ERROR');
            }
        }
    }

    get isSelectField() {
        return Array.isArray(this.props.options);
    }

    get isTextAreaField() {
        return this.props.type === 'textarea';
    }

    get isHiddenField() {
        return this.props.type === 'hidden';
    }

    get isPasswordRegistrationField() {
        return this.props.id === 'user_registration_password_second' ||
            this.props.id === 'user_registration_password_first';
    }

    get rootClassNames() {
        return buildClassNames({
            ['field']: true,
            ['field_select']: this.isSelectField,
            ['field_textarea']: this.isTextAreaField,
            ['field_hidden']: this.isHiddenField,
            ['s-field_hasInput']:
                this.props.machineState.value.hasInput === 'on',
            ['s-field_hasError']:
                this.props.machineState.value.hasError === 'on',
            ...this.props.extensionClassNames,
        });
    }

    get fieldClassNames() {
        return buildClassNames({
            ['field-input']: true,
        });
    }

    /**
     * Checks the current value and transitions to appropriate state
     * @private
     */
    checkIfInputHasValue() {
        if (this.props.value) {
            this.props.transition('HAS_VALUE');
        } else {
            this.props.transition('HAS_NO_VALUE');
        }
    }

    /**
     * @param {Event} ev
     * @private
     */
    handleFocus = ev => {
        this.props.transition('HAS_FOCUS');
        this.props.onFocus(ev);
    };

    /**
     * @param {Event} ev
     * @private
     */
    handleBlur = ev => {
        this.checkIfInputHasValue();
        this.props.onBlur(ev);
    };

    /**
     * @param {Event} ev
     * @private
     */
    handleChange = ev => {
        const value = ev.target.value;
        this.props.onChange(value, this.props.name);
    };

    handleHidePassword = ev => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    /**
     * Determines the type of input to render based off props
     * @returns {string}
     */
    renderFieldInput() {
        const fieldProps = {
            id: this.props.id,
            className: this.fieldClassNames,
            disabled: this.props.disabled,
            name: this.props.name,
            required: this.props.required,
            value: this.props.value,
            ['aria-describedby']: this.props['aria-describedby'],
            ['aria-invalid']: this.props['aria-invalid'],
            ['data-test']: this.props.dataTestIds.field || this.props.id,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            inputMode: this.props.inputMode,
        };

        if (Array.isArray(this.props.options)) {
            return (
                <select {...fieldProps}>
                    {this.props.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.text}
                        </option>
                    ))}
                </select>
            );
        }

        if (this.isTextAreaField) {
            return (
                <textarea
                    {...fieldProps}
                    rows={this.props.rows}
                    placeholder={this.props.placeholder}
                    readOnly={this.props.readonly}
                    maxLength={this.props.maxLength}
                />
            );
        }


        if (this.isPasswordRegistrationField) {
            return (
                <>
                    <input
                        type={this.state.showPassword ? 'text' : 'password'}
                        {...fieldProps}
                        step={this.props.step}
                        placeholder={this.props.placeholder}
                        readOnly={this.props.readonly}
                        maxLength={this.props.maxLength}
                    />
                    <button
                        className="field-input-button"
                        onClick={this.handleHidePassword}
                        type="button"
                    >
                        <span className="u-isVisuallyHidden"> Show / Hide Password </span>
                        <span className="icon icon_s22 m-icon_colorPrimary u-vrTop1x">
                            <Svg xlinkHref={this.state.showPassword ? 'icon-crossed_out_eye' : 'icon-eye'} />
                        </span>
                    </button>
                </>
            );
        }

        return (
            <input
                type={this.props.type}
                {...fieldProps}
                step={this.props.step}
                placeholder={this.props.placeholder}
                readOnly={this.props.readonly}
                maxLength={this.props.maxLength}
            />
        );
    }

    renderFieldLabelTooltip() {
        return (
            this.props.labelTooltip && (
                <span className="field-label-tooltip">
                    <Tooltip renderBody={this.props.labelTooltip}>
                        {({ handleToggle, handleBlur }) => (
                            <button
                                type="button"
                                onClick={handleToggle}
                                onBlur={handleBlur}
                            >
                                <span className="icon icon_sm m-icon_block m-icon_colorDark">
                                    <Svg xlinkHref="icon-questionCircle" />
                                </span>
                            </button>
                        )}
                    </Tooltip>
                </span>
            )
        );
    }

    render() {
        return (
            <div className={this.rootClassNames}>
                {this.props.label && !this.isHiddenField && (
                    <div className="field-label">
                        <label
                            className="field-label-text"
                            htmlFor={this.props.id}
                        >
                            {this.props.label}
                        </label>
                        {this.renderFieldLabelTooltip()}
                    </div>
                )}
                {this.renderFieldInput()}
                <div className="field-extras">
                    {this.props.hasError && (
                        <div
                            id={buildDescribedById(
                                this.props.id,
                                DESCRIBED_BY_ID_ENUM.ERRORS
                            )}
                            className="field-extras-errors"
                            data-test={
                                this.props.dataTestIds['error'] ||
                                `${this.props.id}-error`
                            }
                        >
                            {this.props.error}
                        </div>
                    )}
                    {this.props.help && (
                        <div
                            id={buildDescribedById(
                                this.props.id,
                                DESCRIBED_BY_ID_ENUM.HELP
                            )}
                            className="field-extras-help"
                            data-test={
                                this.props.dataTestIds['help'] ||
                                `${this.props.id}-help`
                            }
                        >
                            {this.props.help}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withStateMachine(statechart)(Field);
