import BaseCollection from './BaseCollection';
import SavedSearchModel from './SavedSearchModel';

export default class SavedSearchCollection extends BaseCollection {
    static fromUserMeData(rawSavedSearches) {
        if (!rawSavedSearches || rawSavedSearches.length == null) {
            return new SavedSearchCollection();
        }

        return new SavedSearchCollection(rawSavedSearches.map(item => SavedSearchModel.fromSavedSearchAPIData(item)));
    }
}
