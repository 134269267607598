import React, { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'tcomb-form';
import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { buildTcombFormComponent } from '../../../../../core/scripts/react-components/tcomb/formFactory';
import LoaderButton from '../../../../../core/scripts/react-components/specifics/LoaderButton';
import { extendTcombStructWithCountriesEnum } from '../../../../../core/scripts/util/tcomb';
import { socialRegister } from '../../../../../core/scripts/repositories/users/users';

import Optins from '../Optins';
import RegistrationFormType, { FIELDS } from './formType';
import options, { ZIP_CODE } from './options';

class SocialRegistrationForm extends Component {
    state = {
        value: {
            ...this.props.value,
            [FIELDS.FIRST_NAME]: this.props.firstName,
            [FIELDS.LAST_NAME]: this.props.lastName,
            [FIELDS.TOKEN]: this.props.token,
            [FIELDS.PROVIDER]: this.props.provider,
            [FIELDS.COUNTRY]: this.props.userCountryCode,
            [FIELDS.SOURCE]: this.props.source,
        },
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        extensionClassNames: PropTypes.objectOf(PropTypes.bool),
        value: PropTypes.shape({
            next: PropTypes.string,
            next_params: PropTypes.string, // eslint-disable-line camelcase
        }),
        countries: PropTypes.array,
        userCountryCode: PropTypes.string,
        onSubmissionSuccess: PropTypes.func,
        onSubmissionError: PropTypes.func,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        token: PropTypes.string,
        provider: PropTypes.string,
        source: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        extensionClassNames: {},
        userCountryCode: '',
        onSubmissionSuccess: _noop,
        onSubmissionError: _noop,
        source: '',
    };

    /**
     * Ref to the complete profile form optins
     * @type {Object}
     */
    optins = React.createRef();

    static getDerivedStateFromProps(props, state) {
        // If the country code hasn't changed, keep existing values
        if (props.userCountryCode === state.value[FIELDS.COUNTRY]) {
            return null;
        }

        return {
            value: {
                ...props.value,
                [FIELDS.FIRST_NAME]: this.props.firstName,
                [FIELDS.LAST_NAME]: this.props.lastName,
                [FIELDS.TOKEN]: this.props.token,
                [FIELDS.PROVIDER]: this.props.provider,
                [FIELDS.COUNTRY]: props.userCountryCode,
                [FIELDS.SOURCE]: this.props.source,
            },
        };
    }

    /**
     * @param {Object} value
     * @param {Object} props
     * @private
     */
    handleSubmissionSuccess = (value, props) => {
        this.props.onSubmissionSuccess(value, props);
    };

    /**
     * @param {Object} value
     * @param {Object} errors
     * @private
     */
    handleSubmissionError = (value, errors) => {
        this.props.onSubmissionError(errors);
        setTimeout(() => {
            // focus first errored field (iOS VoiceOver bug prevented focus)
            const firstErroredField = document.querySelector('.s-field_hasError input');
            firstErroredField.focus();
        }, 300);
    };

    /**
     * @param {Object} value
     * @param {Object} defaultOptions
     * @private
     * @returns {Object}
     */
    handleFormOptionsChange = (value, defaultOptions) => {
        if (value[FIELDS.COUNTRY] === 'US') {
            const zipOptions = t.update(defaultOptions, {
                fields: {
                    [FIELDS.POSTAL_CODE]: {
                        label: { $set: ZIP_CODE },
                    },
                },
            });
            return zipOptions;
        }
        return defaultOptions;
    };

    render() {
        const Form = buildTcombFormComponent(
            'SocialRegistrationForm',
            {
                type: extendTcombStructWithCountriesEnum(
                    RegistrationFormType,
                    this.props.countries,
                    FIELDS.COUNTRY
                ),
                value: this.state.value,
                options,
            },
            {
                onSubmit: async value => {
                    return await socialRegister(
                        Object.assign({}, value, {
                            ...this.optins.current.getValue(),
                        })
                    );
                },
                onSubmissionSuccess: this.handleSubmissionSuccess,
            }
        );

        return (
            <Form
                onSubmissionSuccess={this.handleSubmissionSuccess}
                onSubmissionError={this.handleSubmissionError}
                onDeriveFormOptionsChange={this.handleFormOptionsChange}
            >
                {form => (
                    <form
                        action="/user/register/token"
                        method="post"
                        noValidate={true}
                        onSubmit={form.handleSubmit}
                    >
                        <div className="u-vr4x">{form.component}</div>
                        <Optins
                            ref={this.optins}
                            formId="registration"
                            corporation={['PF', 'PU']}
                            checkboxNames={[
                                FIELDS.OPTIN_PETFINDER,
                                FIELDS.OPTIN_SPONSOR,
                            ]}
                            selectedCountryCode={
                                form.state.value[FIELDS.COUNTRY]
                            }
                        />

                        <LoaderButton
                            active={form.isSubmitting}
                            extensionClassNames={{
                                ['m-btn_full']: true,
                            }}
                            type="submit"
                            label="Sign Up"
                        />
                    </form>
                )}
            </Form>
        );
    }
}

export default SocialRegistrationForm;
