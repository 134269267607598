import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';

const TYPE_TO_CLASS_MAP = {
    info: 'alert_info',
    error: 'alert_error',
    success: 'alert_success',
};

/**
 * Handles rendering an alert box
 * @returns {string}
 */
class AlertBox extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        children: PropTypes.node.isRequired,
        type: PropTypes.oneOf(Object.keys(TYPE_TO_CLASS_MAP)),
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        type: 'info',
    };

    getClassNames() {
        const { type } = this.props;

        return buildClassNames({
            ['alert']: true,
            [`${TYPE_TO_CLASS_MAP[type]}`]: Object.keys(
                TYPE_TO_CLASS_MAP
            ).includes(type),
            ['m-alert_padded']: true,
        });
    }

    render() {
        return (
            <div className={this.getClassNames()}>{this.props.children}</div>
        );
    }
}

export default AlertBox;
