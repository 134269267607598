import { PFElement } from '../pf-element/element';
import { CLASS_IS_ACTIVE } from '../../constants/classes';
import { EV_DYNAMIC_ACTIVATE, EV_DYNAMIC_DEACTIVATE } from '../../constants/events';

/**
 * An element that triggers a class on itself under various conditions or events
 * @extends PFElement
 */
export class PFDynamicElement extends PFElement {
    /**
     * Get the ui group of this component
     * @return {string}
     */
    get uiGroup() {
        return this.dataset.uiGroup || this._uiGroup;
    }

    /**
     * Get the active class
     * @return {string}
     */
    get activeClass() {
        return this.dataset.activeClass || CLASS_IS_ACTIVE;
    }

    /**
     * Get the inactive class
     * @return {string}
     */
    get inactiveClass() {
        return this.dataset.inactiveClass || null;
    }

    /**
     * A value to evaluate
     * @return {string}
     */
    get showOnValue() {
        return this.dataset.showOnValue;
    }

    /**
     * Get the custom event string that should activate this component
     * @return {string}
     */
    get activateOn() {
        return this.getAttribute('activate-on') || EV_DYNAMIC_ACTIVATE;
    }

    /**
     * Get the custom event string that should deactivate this component
     * @return {string}
     */
    get deactivateOn() {
        return this.getAttribute('deactivate-on') || EV_DYNAMIC_DEACTIVATE;
    }

    /**
     * Should this element get focused on activation
     * @return {boolean}
     */
    get focusOnActivate() {
        return this.hasAttribute('focus-on-activate');
    }

    /**
     * Activate this element
     */
    async activate() {
        this.classList.add(this.activeClass);

        if (this.inactiveClass !== null) {
            this.classList.remove(this.inactiveClass);
        }
    }

    /**
     * Deactivate this element
     */
    async deactivate() {
        this.classList.remove(this.activeClass);

        if (this.inactiveClass !== null) {
            this.classList.add(this.inactiveClass);
        }
    }

    /**
     * Handles update events from the app level
     * @param {Object} ev the event object
     */
    onUpdated(ev) {
        const trigger = ev.detail.trigger;

        if (ev.detail.type === this.activateOn) {
            if (trigger.target && this.isTarget(trigger.target)) {
                if (this.showOnValue === undefined) {
                    this.activate();
                } else {
                    if (this.showOnValue === trigger.value) {
                        this.activate();
                    } else {
                        this.deactivate();
                    }
                }
            } else {
                this.deactivate();
            }

            if (!trigger.target) {
                this.activate();
            }

            if (this.focusOnActivate) {
                this.focusManager.focusFirstFocusable(this);
            }
        }

        if (ev.detail.type === this.deactivateOn) {
            if (trigger.target && this.isTarget(trigger.target)) {
                this.deactivate();
            } else {
                this.deactivate();
            }
        }
    }
}

export default PFDynamicElement;
