import MixinBase from './MixinBase';
import Utils from '../Utils';

export default class ContentMixin extends MixinBase {
    static _attributeName = 'mix-content';

    constructor(ele, value) {
        super(ele, value);

        const result = Utils.HTMLElementUtils.findElementContainingAddress(
            value,
            ele,
            true
        );
        let content;
        if (typeof result.value === 'object') {
            content = JSON.stringify(result.value);
        } else {
            content = result.value == null ? '' : result.value;
        }

        this._ele.innerHTML = content;
    }
}
