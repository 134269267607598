import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { EV_CAROUSEL_NAV_CLICK } from '../../constants/events';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';
import Flickity from 'flickity';
// import '../../../../core/scripts/vendor/flickity-mod/fizzy-ui-utils__mod';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-carousel-nav';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    CAROUSEL: `[${ELEMENT_TAG_NAME}-carousel]`,
};

/**
 * Sets up a carousel nav specific flickity slider and just toggles a class on the active item.
 * You can write a custom element that extends and overwrites the onClicked for
 * custom functionality.
 *
 * MARKUP EXAMPLE:
 * ---
 * <pfdc-carousel-nav class="carouselNav">
 *     <div pf-carousel-nav-carousel class="carouselNav-list">
 *         <div class="carouselNav-list-item">
 *             <button class="carouselNav-list-item-btn" type="button">All</button>
 *         </div>
 *         {% for typeSavedSearch in typesAndSearches %}
 *             <div class="carouselNav-list-item">
 *                 <button class="carouselNav-list-item-btn" type="button">Item...</button>
 *             </div>
 *         {% endfor %}
 *     </div>
 * </pfdc-carousel-nav>
 * ---
 *
 * @extends PFElement
 */
export class PFDCCarouselNavElement extends PFElement {
    /**
     * Get event this component should emit on selection
     * @return {string}
     */
    get onSlideSelectEvent() {
        if (
            this.hasAttribute('on-slide-select') &&
            this.getAttribute('on-slide-select') !== ''
        ) {
            return this.getAttribute('on-slide-select');
        } else {
            return EV_CAROUSEL_NAV_CLICK;
        }
    }

    /**
     * Initialize this component
     */
    onInit() {
        /**
         * Reference to the carousel initialization element
         * @type {element}
         */
        this.carousel = this.querySelector(ELEMENT_SELECTORS.CAROUSEL);

        /**
         * Flickity options
         * @type {Object}
         */
        const options = {
            contain: true,
            cellAlign: 'left',
            adaptiveHeight: true,
            percentPosition: true,
            friction: 0.5,
            selectedAttraction: 0.08,
            pageDots: false,
            prevNextButtons: true,
            freeScroll: true,
            wrapAround: false,
        };

        // Setup flickity
        this.flickity = new Flickity(this.carousel, options);

        this.onStaticClickedHandler = this.onStaticClicked.bind(this);
        this.flickity.on('staticClick', this.onStaticClickedHandler);

        this.addEventListener('click', this.onClick);

        this.onSelectHandler = this.onSelect.bind(this);
        this.flickity.on('select', this.onSelectHandler);

        scheduleMicrotask(() => {
            this.flickity.resize();
        });
    }

    /**
     * Handles flickity static click event
     * @param {Object} event
     * @param {Object} pointer
     * @param {element} cellElement flickity slide element
     * @param {number} cellIndex index of the clicked cell
     */
    onStaticClicked(event, pointer, cellElement, cellIndex) {
        if (!cellElement) {
            return;
        }
        this.flickity.select(cellIndex);
    }

    onClick(event) {
        const cellElements = this.flickity.cells.map(cell => cell.element);

        if (cellElements.includes(event.target)) {
            const cellIndex = cellElements.indexOf(event.target);
            this.flickity.select(cellIndex);
        }
    }

    /**
     * Handles flickity select events
     */
    onSelect() {
        this.dispatchAction(this.onSlideSelectEvent, {
            navItemElement: this.flickity.selectedElement,
            navItemIndex: this.flickity.selectedIndex,
        });
    }
}
