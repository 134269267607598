import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _noop from 'lodash/noop';
import _uniquedId from 'lodash/uniqueId';

import { getTextOnlyFromHTMLString } from '../../../../../../core/scripts/util/dom';

/**
 * Checkbox component for generic checkbox input
 * !!IMPORTANT !!
 * This component uses some methods to dangerously set innerHTML and also
 * does some dirty html escaping from strings. DO NOT USE this component if your label prop
 * is potentially unsafe content as it could be an XSS vulnerability.
 * @class Checkbox
 * @extends React.Component
 */
class Checkbox extends Component {
    state = {
        safeLabel: '',
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        displayCheckbox: PropTypes.bool,
        id: PropTypes.string,
        isChecked: PropTypes.bool,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        round: PropTypes.bool,
        value: PropTypes.string,
        theme: PropTypes.string,
        onChange: PropTypes.func,
        dataTestValue: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        displayCheckbox: true,
        id: _uniquedId('Checkbox_'),
        isChecked: false,
        round: false,
        value: 'on',
        theme: '',
        onChange: _noop,
        dataTestValue: null
    };

    /**
     * @static
     * @param {Object} props
     * @param {Object} state
     * @returns {Object}
     */
    static getDerivedStateFromProps(props, state) {
        /**
         * !! IMPORTANT !!
         * Danger here, this is where we try to strip some potential html from labels for a safe
         * aria label, DO NOT USE this component if your label prop is potentially unsafe content
         * as it could be an XSS vulnerability.
         */
        if (props.label !== state.safeLabel) {
            try {
                const safeLabel = getTextOnlyFromHTMLString(props.label);
                return { safeLabel };
            } catch (error) {
                return null;
            }
        }

        return null;
    }

    /**
     * Build classnames for checkbox
     * @method getClassNames
     * @private
     * @returns {string}
     */
    getClassNames() {
        return buildClassNames({
            ['checkbox']: true,
            ['checkbox_round']: this.props.round,
            ['m-checkbox_hidden']: !this.props.displayCheckbox,
            ['m-checkbox_white']: ['breedsAlertModal'].includes(
                this.props.theme
            ),
        });
    }

    /**
     * Build classnames for label
     * @method getLabelClassNames
     * @private
     * @returns {string}
     */
    getLabelClassNames() {
        return buildClassNames({
            ['checkbox-label']: true,
            ['u-textCenter']: ['breedsAlertModal'].includes(this.props.theme),
        });
    }

    /**
     * @private
     * @param {Event} ev
     */
    handleChange = ev => {
        this.props.onChange(ev);
    };

    /**
     * !! IMPORTANT !!
     * The label is set with dangerouslySetInnerHTML make sure your label
     * string is escaped properly
     * @returns {string}
     */
    render() {
        const {
            displayCheckbox,
            id,
            isChecked,
            label,
            name,
            value,
            dataTestValue,
        } = this.props;
        const { safeLabel } = this.state;

        return (
            <label className={this.getClassNames()} htmlFor={id}>
                {displayCheckbox && (
                    <input
                        id={id}
                        className="checkbox-input"
                        type="checkbox"
                        name={name}
                        checked={isChecked}
                        value={value}
                        onChange={this.handleChange}
                        aria-label={safeLabel || label}
                        data-test={dataTestValue}
                    />
                )}
                <span className="checkbox-btn" />
                <span
                    className={this.getLabelClassNames()}
                    dangerouslySetInnerHTML={{ __html: label }}
                    tabIndex={!displayCheckbox ? '0' : null}
                />
            </label>
        );
    }
}

export default Checkbox;
