import { PFDCInputElement } from '../pfdc-input/element';
import { EV_COUNTRY_CHANGE } from '../../constants/events';
import { GEOGRAPHY_COUNTRY_REQUIRES_POSTAL_CODE } from '../../../../core/scripts/constants/geography';
import eventBus from '../../lib/EventBus';

/**
 * Custom element extension of the pfdc-input to handle postal code updates.
 *
 * @extends PFDCInputElement
 */
export class PFDCPostalCodeInputElement extends PFDCInputElement {
    /**
     * Event to listen for to update optins
     */
    get updateOnEvent() {
        if (!this.hasAttribute('update-on')) {
            throw new Error(
                'PFDCPostalCodeInputElement: You need to supply an update-on event'
            );
        }
        return this.getAttribute('update-on');
    }

    get dynamicErrors() {
        return this.getAttribute('dynamic-errors');
    }

    /**
     * Initialize this component
     */
    onInit() {
        super.onInit();

        /**
         * Reference to a label element
         * @type {element}
         */
        this.label = this.querySelector('label');
    }

    /**
     * Update the label
     * @param {Object} detail the event detail object
     */
    updateLabel(detail) {
        const countryCode = detail.srcEvent
            ? detail.srcEvent.target.value
            : detail.trigger.value;

        switch (countryCode) {
            case 'us':
            case 'US':
                this.label.innerText = 'ZIP Code'; // TODO: Add translation
                break;
            default:
                this.label.innerText = 'Postal Code'; // TODO: Add translation
                break;
        }

        this.input.setAttribute('aria-label', this.label.innerText);
    }

    /**
     * Update whether the postal code input is required
     * @param {Object} detail the event detail object
     */
    setRequired(detail) {
        const countryCode = detail.srcEvent
            ? detail.srcEvent.target.value
            : detail.trigger.value;

        // conditionally requiring postal code based on country selection requires communication to
        // legacy validator system
        if (GEOGRAPHY_COUNTRY_REQUIRES_POSTAL_CODE.includes(countryCode)) {
            if (this.dynamicErrors) {
                // event to legacy validator system to conditional unlock (enable) the required
                // validator.
                eventBus.trigger('legacyConditionalUnlock', this);
            }
            this.input.setAttribute('required', '');
            this.input.setAttribute('aria-required', 'true');
        } else {
            if (this.dynamicErrors) {
                // event to legacy validator system to conditional lock (disable) the required
                // validator.
                eventBus.trigger('legacyConditionalLock', this);
            }
            this.input.removeAttribute('required');
            this.input.setAttribute('aria-required', 'false');
        }
    }

    /**
     * Handles update events from the app
     * @param {Object} ev the event object
     */
    onUpdated(ev) {
        const { detail } = ev;
        if (detail.type === this.updateOnEvent) {
            this.updateLabel(detail);
            this.setRequired(detail);
        }
    }
}

export default PFDCPostalCodeInputElement;
