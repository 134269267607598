import React from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import siteStateController from '../../../state-controllers/siteStateController';
import savedSearchService from '../../../../../core/scripts/services/savedSearchService';
import { analyticsConsumer340 } from '../../../analytics/dotcom';

export default function SaveSearchMobileStickyButton(props) {
    /* *******************************************
     * Events
     ********************************************/
    /**
     *
     * @param {MouseEvent} e
     * @private
     * @fires PetfinderConsumer340
     */
    const _onSaveSearchButtonClick = e => {
        savedSearchService.launchSaveSearchModal({
            focusTarget: e.currentTarget,
            analyticTriggerLabel: 'saveSearchMobile',
            searchUrl: siteStateController.animalSearch.results.url,
        });
        analyticsConsumer340();
    };

    return (
        <div className="split split_gutter">
            <div className="split-item split-item_grow">
                <button
                    onClick={_onSaveSearchButtonClick}
                    className={buildClassNames({
                        ['btn']: true,
                        ['m-btn_full']: true,
                    })}
                >
                    Save Search
                </button>
            </div>
            <div className="split-item u-isHidden@minMd">
                <pf-element on-click="ui.activate" data-target="#Share_Pet_Modal">
                    <button className="btn btn_slide m-btn_circleLarge">
                        <span className="icon m-icon_colorDark">
                            <svg role="img" focusable="false">
                                <use xlinkHref="#icon-share_outline" />
                            </svg>
                        </span>
                        <span className="u-isVisuallyHidden">Share</span>
                    </button>
                </pf-element>
            </div>
        </div>
    );
}

SaveSearchMobileStickyButton.propTypes = {
    searchUrl: PropTypes.string,
};
