import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { CLASS_IS_VISUALLY_HIDDEN } from '../../constants/classes';
import { EV_CLIPBOARD_COMMAND } from '../../constants/events';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';

/**
 * This component executes a clipboard command with the provided text.
 *
 * @extends PFElement
 */
export class PFDCClipboardElement extends PFElement {
    /**
     * Get the clipboard command to be run or throw an error
     * @return {string} command to be run
     */
    get command() {
        if (this.hasAttribute('command')) {
            return this.getAttribute('command');
        } else {
            throw new Error(
                'PFDCClipboardElement: needs a "command" attribute'
            );
        }
    }

    /**
     * Get custom event string to dispatch when the clipboard command is run
     * @return {string} custom event string
     */
    get onCommandEvent() {
        return this.getAttribute('on-command') || EV_CLIPBOARD_COMMAND;
    }

    /**
     * Get the text that will be used in the command
     * @return {string}
     */
    get text() {
        if (this.hasAttribute('text')) {
            return this.getAttribute('text');
        } else {
            throw new Error(
                'PFDCClipboardElement: needs a "text" value attribute'
            );
        }
    }

    /**
     * Initialize this component
     */
    onInit() {
        this.addEventListener('click', this.onClicked);
    }

    /**
     * Copy command handler, create visually hidden input with text to be copied, select the
     * inner text and execute copy command and dispatch an action to the app
     */
    executeClipboardCopy() {
        const input = document.createElement('input');
        input.value = this.text;
        this.appendChild(input);

        input.classList.add(CLASS_IS_VISUALLY_HIDDEN);

        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            input.contentEditable = true;
            input.readOnly = false;

            const range = document.createRange();
            range.selectNodeContents(input);

            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            input.setSelectionRange(0, 9999999);

            document.execCommand('copy');
        } else {
            input.select();
            document.execCommand('copy');
            this.dispatchAction(this.onCommandEvent, { command: 'copy' });
        }

        input.remove();
    }

    /**
     * Determine clipboard action and handler
     */
    executeClipboardAction() {
        switch (this.command) {
            case 'copy':
                this.executeClipboardCopy();
                break;
            default:
                break;
        }
    }

    /**
     * Handle click events on this component
     * @param {Object} ev object
     */
    onClicked(ev) {
        ev.preventDefault();
        this.executeClipboardAction();

        scheduleMicrotask(() => {
            this.focusManager.focusFirstFocusable(this, false);
        });
    }
}

export default PFDCClipboardElement;
