import DATA_TYPES from './DataTypes';
import Utils from './Utils';
import _camelCase from 'lodash/camelCase';

export default class Mixins {
    static _attributePrefix = '';
    static _mixinArr = [];

    static add(Mixin, attributeNameOverride = null) {
        if (attributeNameOverride) {
            Mixin.attributeName = attributeNameOverride; // eslint-disable-line
        }

        this._mixinArr.push(Mixin);
        return this;
    }

    static _initMixinOnElements(Mixin, eles) {
        for (let i = 0; i < eles.length; i++) {
            this._initMixinOnElement(Mixin, eles[i]);
        }
    }

    static _initMixinOnElement(Mixin, ele) {
        const attributeValue = ele.getAttribute(
            this._getFullAttributeName(Mixin.attributeName)
        );

        // if null, the attribute didn't exist... exit without initializing the mixin
        if (attributeValue === null) {
            return false;
        }

        return new Mixin(ele, attributeValue);
    }

    static _getFullAttributeName(baseAttributeName) {
        return `${this._attributePrefix}${baseAttributeName}`;
    }

    static _findElementsAndInitMixins(element) {
        for (let i = 0; i < this._mixinArr.length; i++) {
            const Mixin = this._mixinArr[i];

            const eles = element.querySelectorAll(
                `[${this._getFullAttributeName(Mixin.attributeName)}]`
            );
            if (eles.length) {
                this._initMixinOnElements(Mixin, eles);
            }
        }
    }

    static setPrefix(value) {
        this._attributePrefix = value;
    }

    static init() {
        this._findElementsAndInitMixins(document);
    }

    static initOn(ele) {
        for (let i = 0; i < this._mixinArr.length; i++) {
            const Mixin = this._mixinArr[i];

            this._initMixinOnElement(Mixin, ele);
        }
    }

    static initWithin(element) {
        return this._findElementsAndInitMixins(element);
    }

    static setMixinOnElement(mixin, element) {
        const camelCasedAttributeName = _camelCase(
            this._getFullAttributeName(mixin.attributeName)
        );

        // TODO: is this how we want to do this?
        element[camelCasedAttributeName] = mixin; // eslint-disable-line
    }

    static removeMixinsFromElement(element) {
        for (let i = 0; i < this._mixinArr.length; i++) {
            const Mixin = this._mixinArr[i];

            const camelCasedAttributeName = _camelCase(
                this._getFullAttributeName(Mixin.attributeName)
            );
            if (camelCasedAttributeName in element) {
                const elementMixin = element[camelCasedAttributeName];
                if (elementMixin.destroy) {
                    elementMixin.destroy();
                } else {
                    // console.warn('!elementMixin.destroy', elementMixin)
                }
            }
        }
    }
}

/**
 * Built-in mixins
 */

import IdMixin from './mixins/IdMixin';
import ClickMixin from './mixins/ClickMixin';
import ChangeMixin from './mixins/ChangeMixin';
import CheckedMixin from './mixins/CheckedMixin';
import MouseDownMixin from './mixins/MouseDownMixin';
import MouseUpMixin from './mixins/MouseUpMixin';
import MouseOverMixin from './mixins/MouseOverMixin';
import FocusMixin from './mixins/FocusMixin';
import FocusoutMixin from './mixins/FocusoutMixin';
import FocusinMixin from './mixins/FocusinMixin';
import BlurMixin from './mixins/BlurMixin';
import InputMixin from './mixins/InputMixin';
import KeydownMixin from './mixins/KeydownMixin';
import KeyupMixin from './mixins/KeyupMixin';
import KeypressMixin from './mixins/KeypressMixin';
import ContentMixin from './mixins/ContentMixin';
import ValueMixin from './mixins/ValueMixin';
import AttributeMixin from './mixins/AttributeMixin';
import HideMixin from './mixins/HideMixin';
import ShowMixin from './mixins/ShowMixin';
import SubmitMixin from './mixins/SubmitMixin';
import ClassMixin from './mixins/ClassMixin';
import DisabledMixin from './mixins/DisabledMixin';
import DelayedCallMixin from './mixins/DelayedCallMixin';
import InitMixin from './mixins/InitMixin';
import ScrollToOnRenderMixin from './mixins/ScrollToOnRenderMixin';
import IfMixin from './mixins/IfMixin';
import FocusOnRenderMixin from './mixins/FocusOnRenderMixin';
import StateClassMixin from './mixins/StateClassMixin';
import FocusFirstFocusableOnRenderMixin from './mixins/FocusFirstFocusableOnRenderMixin';

// core
Mixins.add(IdMixin);
Mixins.add(InitMixin);
Mixins.add(FocusFirstFocusableOnRenderMixin);
Mixins.add(ScrollToOnRenderMixin);
Mixins.add(DelayedCallMixin);
Mixins.add(ContentMixin);
Mixins.add(ValueMixin);
Mixins.add(CheckedMixin);
Mixins.add(AttributeMixin);
Mixins.add(HideMixin);
Mixins.add(ShowMixin);
Mixins.add(ClassMixin);
Mixins.add(DisabledMixin);
Mixins.add(IfMixin);
Mixins.add(StateClassMixin);

// events
Mixins.add(ClickMixin);
Mixins.add(ChangeMixin);
Mixins.add(MouseDownMixin);
Mixins.add(MouseUpMixin);
Mixins.add(MouseOverMixin);
Mixins.add(FocusMixin);
Mixins.add(FocusoutMixin);
Mixins.add(FocusinMixin);
Mixins.add(BlurMixin);
Mixins.add(InputMixin);
Mixins.add(KeydownMixin);
Mixins.add(KeyupMixin);
Mixins.add(KeypressMixin);
Mixins.add(SubmitMixin);
Mixins.add(FocusOnRenderMixin);
