import BaseCollection from './BaseCollection';
import StateModel from './StateModel';

export default class StateCollection extends BaseCollection {
    /**
     * @param {array} data array of state objects
     * @returns {StateCollection}
     */
    static fromServer(data) {
        if (!data || data.length == null) {
            return new StateCollection();
        }

        return new StateCollection(
            data.map(state => StateModel.fromServer(state)));
    }
}
