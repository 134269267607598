import React, { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'tcomb-form';
import _noop from 'lodash/noop';

import { buildTcombFormComponent } from '../../../../../core/scripts/react-components/tcomb/formFactory';
import LoaderButton from '../../../../../core/scripts/react-components/specifics/LoaderButton';
import { extendTcombStructWithCountriesEnum } from '../../../../../core/scripts/util/tcomb';
import { register } from '../../../../../core/scripts/repositories/users/users';

import Optins from '../Optins';

import RegistrationFormType, { FIELDS } from './formType';

import options, { ZIP_CODE } from './options';
import { analyticsConsumer014 } from '../../../analytics/dotcom';

const optInErrorMessage =
    'You must read and agree to the Notice at collection, Privacy Policy, and Terms and Conditions, and to receiving email and other marketing communications from Petfinder and Purina and its brands.';

class RegistrationForm extends Component {
    state = {
        value: {
            ...this.props.value,
            [FIELDS.COUNTRY]: this.props.userCountryCode,
            [FIELDS.SOURCE]: this.props.source,
        },
        shouldUseCombinedOptins: false,
        optinErrors: {},
        isInErrorState: false,
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        countries: PropTypes.arrayOf(PropTypes.object).isRequired,
        extensionClassNames: PropTypes.objectOf(PropTypes.bool),
        value: PropTypes.shape({
            next: PropTypes.string,
            next_params: PropTypes.string, // eslint-disable-line camelcase
        }),
        userCountryCode: PropTypes.string,
        onLoginClick: PropTypes.func,
        onSubmissionSuccess: PropTypes.func,
        onSubmissionError: PropTypes.func,
        source: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        extensionClassNames: {},
        userCountryCode: '',
        onSubmissionSuccess: _noop,
        onSubmissionError: _noop,
        source: '',
    };

    /**
     * @type {Object}
     */
    optins = React.createRef();

    // static getDerivedStateFromProps(props, state) {
    //     // If the country code hasn't changed, keep existing values
    //     if (props.userCountryCode === state.value[FIELDS.COUNTRY]) {
    //         return null;
    //     }

    //     return {
    //         value: {
    //             ...props.value,
    //             [FIELDS.COUNTRY]: props.userCountryCode,
    //             [FIELDS.SOURCE]: props.source,
    //         },
    //     };
    // }

    /**
     * @param {Event} ev
     */
    handleLoginClick = ev => {
        ev.preventDefault();
        this.props.onLoginClick(analyticsConsumer014);
    };

    /**
     * @param {Object} value
     * @param {Object} props
     * @private
     */
    handleSubmissionSuccess = (value, props) => {
        this.props.onSubmissionSuccess(value, props);
    };

    /**
     * @param {Object} value
     * @param {Object} errors
     * @private
     */
    handleSubmissionError = (value, errors) => {
        this.props.onSubmissionError(errors);
    };

    /**
     * @param {Object} value
     * @param {Object} defaultOptions
     * @private
     * @returns {Object}
     */
    handleFormOptionsChange = (value, defaultOptions) => {
        if (value[FIELDS.COUNTRY] === 'US') {
            const zipOptions = t.update(defaultOptions, {
                fields: {
                    [FIELDS.POSTAL_CODE]: {
                        label: { $set: ZIP_CODE },
                    },
                },
            });
            return zipOptions;
        }
        return defaultOptions;
    };

    handleFormValueChange = (prevValue, value) => {
        const isCombinedOptinsVariant = value[FIELDS.COUNTRY] === 'US';
        const optinsValue = this.optins.current.getValue();

        const newOptinsErrors = {};
        if (isCombinedOptinsVariant && !optinsValue[FIELDS.OPTIN_SPONSOR] && this.state.isInErrorState) {
            newOptinsErrors[FIELDS.OPTIN_SPONSOR] = optInErrorMessage;
        }

        this.setState(prevState => {
            return {
                ...prevState,
                value,
                shouldUseCombinedOptins: isCombinedOptinsVariant,
                optinErrors: newOptinsErrors,
            };
        });
    };

    componentDidMount() {
        this.handleFormValueChange({}, this.state.value);
    }

    recreateForm = () => {
        return buildTcombFormComponent(
            'RegistrationForm',
            {
                type: extendTcombStructWithCountriesEnum(RegistrationFormType, this.props.countries, FIELDS.COUNTRY),
                value: this.state.value,
                options,
            },
            {
                onSubmit: async value => {
                    return await register(
                        Object.assign({}, value, {
                            ...this.optins.current.getValue(),
                        })
                    );
                },
                onSubmissionSuccess: this.handleSubmissionSuccess,
            }
        );
    };

    Form = this.recreateForm();

    render() {
        const { Form } = this;

        const onAdditionalValidation = value => {
            // check the value of the optins right now
            const optinsValue = this.optins.current.getValue();

            // if we are in the opting combined optins variant, the user MUST opt in to FIELDS.OPTIN_SPONSOR
            // otherwise, add an error to the optin field
            const isCombinedOptinsVariant = this.state.value[FIELDS.COUNTRY] === 'US';
            const newOptinsErrors = {};
            if (isCombinedOptinsVariant && !optinsValue[FIELDS.OPTIN_SPONSOR]) {
                newOptinsErrors[FIELDS.OPTIN_SPONSOR] = optInErrorMessage;
            }

            this.setState(prevState => {
                return { ...prevState, optinErrors: newOptinsErrors };
            });

            // return false if there were errors or true if there were not
            return Object.keys(newOptinsErrors).length === 0;
        };

        const setIsInErrorState = isInErrorState => {
            this.setState(prevState => {
                return { ...prevState, isInErrorState };
            });
        };

        const corporation = !this.state.shouldUseCombinedOptins ? ['PF', 'PU'] : ['PU_COMBINED'];
        const checkboxNames = !this.state.shouldUseCombinedOptins
            ? [FIELDS.OPTIN_PETFINDER, FIELDS.OPTIN_SPONSOR]
            : [FIELDS.OPTIN_SPONSOR];

        return (
            <Form
                key={'form'}
                onSubmissionSuccess={this.handleSubmissionSuccess}
                onSubmissionError={this.handleSubmissionError}
                onDeriveFormOptionsChange={this.handleFormOptionsChange}
                onFormValueChange={this.handleFormValueChange}
                onValidateAdditionalValidation={onAdditionalValidation}
                setIsInErrorState={setIsInErrorState}
            >
                {form => (
                    <form action="/user/register" method="post" noValidate onSubmit={form.handleSubmit}>
                        {form.component}
                        <div className="grid grid_gutterLg">
                            <div className="grid-col grid-col_1/2@minLg">
                                <Optins
                                    key={form.state.value[FIELDS.COUNTRY]}
                                    ref={this.optins}
                                    formId="registration"
                                    corporation={corporation}
                                    checkboxNames={checkboxNames}
                                    selectedCountryCode={form.state.value[FIELDS.COUNTRY]}
                                    dataTestPrepend="User_Registration_Modal"
                                    errors={this.state.optinErrors}
                                />

                                <LoaderButton
                                    active={form.isSubmitting}
                                    extensionClassNames={{
                                        ['m-btn_full']: true,
                                    }}
                                    type="submit"
                                    label="Sign up"
                                    data-test="User_Registration_Modal_Sign_Up_Button"
                                />
                            </div>
                            <div className="grid-col grid-col_1/2@minLg">
                                <p className="txt m-txt_lg m-txt_alignCenter u-vr2x">
                                    Already have an account?{' '}
                                    <button
                                        type="button"
                                        onClick={this.handleLoginClick}
                                        className="txt txt_link m-txt_lg m-txt_underline"
                                    >
                                        Log in
                                    </button>
                                </p>
                            </div>
                        </div>
                    </form>
                )}
            </Form>
        );
    }
}

export default RegistrationForm;
