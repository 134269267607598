import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { UI_GROUP_GLOBAL } from '../../constants/input-groups';
import { EV_UI_DEACTIVATE, EV_RESIZE_BREAKPOINT_CHANGE } from '../../constants/events';
import { KEY_ESCAPE, KEY_ESC } from '../../constants/keyboard';
import ensightenTracking from '../../../../core/scripts/elements/pf-app/ensighten/ensighten';
import { getCurrentBreakpoint } from '../../../../core/scripts/util/dom';

/**
 * Element's nearest parent category-id.
 *
 * @method parentCategoryIdOf
 * @param {HTMLElement} parentElement
 * @returns {String} categoryId
 */
const parentCategoryIdOf = ({ parentElement }) =>
    (parentElement && parentElement.getAttribute('pf-category-id')) || parentCategoryIdOf(parentElement);

/**
 * # `pfdc-mobile-menu` Element
 *
 * Mobile Resources Nav Menu
 *
 * Menu categories are identified by the 'pf-category-id' attribute.
 * JS names ending in `Id` hold values of this type.
 *
 * @extends PFElement
 */
export class PFDCMobileMenuElement extends PFElement {
    /**
     * ## Lifecycle
     */

    constructor() {
        super();

        /**
         * State for which menu category is the current frame of view.
         * The root level is considered a category.
         *
         * @property currentCategoryId
         * @type {string} pf-category-id
         */
        this.currentCategoryId = null;

        /**
         * Reference to site header.
         *
         * @property siteHeader
         * @type {HTMLElement}
         */
        this.siteHeader = null;

        /**
         * Reference to sub menu container
         *
         * @property subMenuSlidingContainer
         * @type {HTMLElement}
         */
        this.subMenuSlidingContainer = null;

        /**
         * Reference to sub menu header.
         *
         * @property subHeader
         * @type {HTMLElement}
         */
        this.subHeader = null;

        /**
         * Displays currently selected category on sub header.
         *
         * @property subHeaderTitleEl
         * @type {HTMLElement}
         */
        this.subHeaderTitleEl = null;

        /**
         * Reference to root list button elements.
         *
         * @property rootItemBtns
         * @type {Array}
         */
        this.rootItemBtns = null;

        /**
         * Reference to root list link elements.
         *
         * @property rootItemLinks
         * @type {Array}
         */
        this.rootItemLinks = null;

        /**
         * Reference to sub list elements.
         *
         * @property subLists
         * @type {Array}
         */
        this.subLists = null;

        /**
         * Reference to search articles input.
         *
         * @property searchInput
         * @type {Array}
         */
        this.searchInput = null;

        /**
         * All elements that should trap focus at the root level.
         *
         * @property rootLevelFocusGroup
         * @type {Array}
         */
        this.rootLevelFocusGroup = null;

        // focus manager config
        // this.shouldTrapFocus = true;
        this.shouldLockScroll = true;
        // this.trapFocusWithinWhenActive = true;
        this.lockPageScrollWhenActive = true;
        this.focusFirstFocusable = true;
        // this.lockFocusWithin = [this, document.querySelector('[pf-site-header]')];
        this.refocusTriggerOnDeactivate = true;
        this._uiGroup = UI_GROUP_GLOBAL;
    }

    onInit() {
        this.fetchElements();
        this.setLockFocusWithin();

        this.defineAnimations();

        this.addEventListener('click', this.onClicked);
        this.addEventListener('keyup', this.onKeyUpped);
    }

    fetchElements() {
        this.rootCategoryId = 'root-main';

        this.currentCategoryId = this.rootCategoryId;

        this.siteHeader = document.querySelector('[pf-site-header]');

        this.subMenuSlidingContainer = this.querySelector('[pf-sub-sliding-container]');

        this.subHeader = this.querySelector('[pf-sub-header]');

        this.subHeaderTitleEl = this.querySelector('[pf-sub-header-title]');

        this.rootItemBtns = Array.from(this.querySelectorAll('[pf-root-item-btn]'));

        this.rootItemLinks = Array.from(this.querySelectorAll('[pf-root-item-link]'));

        this.subLists = Array.from(this.querySelectorAll('[pf-sub-list]'));

        this.menuBody = this.querySelector('[pf-mobileMenu-menuBody]');

        this.navFooter = this.querySelector('[pf-nav-footer]');

        this.searchInput = Array.from(this.querySelectorAll('[pf-mobileMenu-search-input]'));

        this.searchBtn = Array.from(this.querySelectorAll('[pf-mobileMenu-search-btn]'));

        this.closeBtnRoot = Array.from(this.querySelectorAll('[pf-header-close-btn-root]'));

        this.hiddenCloseBtnRoot = Array.from(this.querySelectorAll('[pf-hidden-close-btn-root]'));

        this.helloBar = this.querySelector('pfdc-hello-bar');
    }

    setLockFocusWithin() {
        // These elements will be focusable when the mobile menu is active
        this.rootLevelFocusGroup = this.rootItemBtns.concat(
            this.searchInput,
            this.searchBtn,
            this.closeBtnRoot,
            this.hiddenCloseBtnRoot,
            this.rootItemLinks,
            this.helloBar
        );
        this.lockFocusWithin = this.rootLevelFocusGroup;
    }

    setSlideContainerHeight() {
        if (getCurrentBreakpoint() !== 'sm') {
            return;
        }

        // Finds the tallest slide container/sub menu height
        const slideContainerHeights = this.subLists.map(list => list.offsetHeight);

        let hellobarHeight = 0;

        if (this.helloBar) {
            hellobarHeight = this.helloBar.offsetHeight;
        }

        // Finds the tallest submenu
        const heights = this.subLists.map(list => list.offsetHeight);
        const parentContainerHeight = this.navFooter.offsetHeight + this.menuBody.offsetHeight + hellobarHeight;
        const tallest = Math.max(Math.max(...slideContainerHeights), parentContainerHeight);
        const extra = 110; // extra height from css..
        const newHeight = tallest + extra;

        this.subMenuSlidingContainer.style.height = `${newHeight}px`;
    }

    /**
     * Accessibility housekeeping.
     * Hides sub nav lists and trap focus.
     *
     * @method initNavLists
     * @private
     */
    // async initAccessibility() {
    //     await this.focusManager.untrapFocus();
    //     await this.focusManager.trapFocus(this.rootLevelFocusGroup);
    //     // focus first item
    //     this.focusManager.focusElement(this.rootItemBtns[0]);
    // }

    async activate() {
        await super.activate();

        // refetch the elements, then set the focus group, since some react elements may not be rendered yet, during onInit()
        this.fetchElements();
        this.setLockFocusWithin();

        // reset to root category, no duration
        this.animateCategoryTransitionIfNeeded(this.rootCategoryId, this.currentCategoryId, { duration: 1 });

        await this.showModal();
        // await this.initAccessibility();
    }

    async deactivate() {
        await super.deactivate();

        await this.hideModal();
    }

    async showModal() {
        await this.animationEngine.runAnimation(this.fadeInMenuAnimation);

        ensightenTracking.eventConsumer003();
        this.setSlideContainerHeight();
    }

    async hideModal() {
        await this.animationEngine.runAnimation(this.fadeOutMenuAnimation);

        this.subLists.forEach(sublist => {
            sublist.style.display = 'none';
            sublist.style.transform = 'translateX(102%)';
        });
        this.subMenuSlidingContainer.style.display = 'none';
        this.subMenuSlidingContainer.style.transform = 'translateX(102%)';

        ensightenTracking.eventConsumer004();

        // prevents extra transition animation if closed while submenu is open
        this.currentCategoryId = this.rootCategoryId;
        this.style.display = 'none';
    }

    /**
     * ## Handlers
     */

    /**
     * @method onClicked
     * @param {Event} ev
     */
    onClicked(ev) {
        const { target } = ev;
        let toCategoryId;
        let fromCategoryId;

        // menu item
        if (target.hasAttribute('pf-root-item-btn')) {
            toCategoryId = parentCategoryIdOf(target);
            fromCategoryId = this.currentCategoryId;
        }
        // header and hidden back button
        else if (target.hasAttribute('pf-header-back-btn') || target.hasAttribute('pf-hidden-back-btn')) {
            toCategoryId = this.rootCategoryId;
            fromCategoryId = this.currentCategoryId;
        }

        this.animateCategoryTransitionIfNeeded(toCategoryId, fromCategoryId);
    }

    /**
     * @method onKeyUpped
     * @param {Event} ev
     */
    onKeyUpped(ev) {
        if (ev.key === KEY_ESCAPE || ev.key === KEY_ESC) {
            if (this.currentCategoryId === this.rootCategoryId) {
                this.dispatchAction(EV_UI_DEACTIVATE, { target: this });
            } else {
                this.animateCategoryTransitionIfNeeded(this.rootCategoryId, this.currentCategoryId);
            }
        }
    }

    /**
     * ## Transition Logic
     */

    /**
     * Executes the appropriate animation between two categories.
     *
     * @method animateCategoryTransitionIfNeeded
     * @param {string} toCategoryId
     * @param {string} fromCategoryId
     * @param {Object} opts - optional
     */
    animateCategoryTransitionIfNeeded(toCategoryId, fromCategoryId, opts) {
        // view change needed?
        if (toCategoryId === fromCategoryId) {
            return;
        }
        // update state
        this.currentCategoryId = toCategoryId;

        // which type of transition?
        if (fromCategoryId === this.rootCategoryId) {
            this.animateRootToSub(toCategoryId, opts);
        } else if (toCategoryId === this.rootCategoryId) {
            this.animateSubToRoot(fromCategoryId, opts);
        }
    }

    /**
     * Confusing DOM traversal goes here.
     *
     * @method categoryElements
     * @param {string} categoryId
     * @returns {Object} of HTMLElements
     */
    categoryElements(categoryId) {
        const parent = this.querySelector(`[pf-category-id="${categoryId}"]`);
        const button = parent.firstElementChild;
        const list = button.nextElementSibling;
        const titleText = button.innerText.trim();
        return { parent, button, list, titleText };
    }

    /**
     * Animates menu transition from root to sub category
     *
     * @method animateRootToSub
     * @param {string} toSubCategoryId
     * @param {Object} opts - optional
     */
    async animateRootToSub(toSubCategoryId, opts = {}) {
        const toSubCategory = this.categoryElements(toSubCategoryId);

        // add header title
        this.subHeaderTitleEl.innerHTML = toSubCategory.titleText;

        // slide sub menu left, onscreen
        await this.animationEngine.defineAnimation({
            type: 'slideFrom',
            element: [this.subMenuSlidingContainer, toSubCategory.list],
            opts: {
                distance: '102%',
                duration: 300,
                valueBefore: [{ display: 'block' }],
                valueAfter: [{ display: 'block' }],
            },
            playOnDefine: true,
        });

        this.setSlideContainerHeight();

        // trap focus
        await this.focusManager.untrapFocus();
        await this.focusManager.trapFocus([this.subHeader, toSubCategory.list]);
        // focus first in sub list
        this.focusManager.focusFirstFocusable(toSubCategory.list);
    }

    /**
     * Animates menu transition from sub to root category.
     *
     * @method animateSubToRoot
     * @param {string} fromSubCategoryId
     * @param {Object} opts - optional
     */
    async animateSubToRoot(fromSubCategoryId, opts = {}) {
        const fromSubCategory = this.categoryElements(fromSubCategoryId);

        // slide sub menu right, offscreen
        await this.animationEngine.defineAnimation({
            type: 'slideTo',
            element: [this.subMenuSlidingContainer, fromSubCategory.list],
            opts: {
                distance: '102%',
                duration: 200,
                valueAfter: [{ display: 'none' }],
            },
            playOnDefine: true,
        });

        // remove header title
        this.subHeaderTitleEl.innerHTML = '';

        // trap focus
        await this.focusManager.untrapFocus();
        await this.focusManager.trapFocus(this.rootLevelFocusGroup);
        // return focus to triggering root element
        this.focusManager.focusElement(fromSubCategory.button);
    }

    defineAnimations() {
        this.fadeInMenuAnimation = this.animationEngine.defineAnimation({
            type: 'fadeIn',
            element: this,
            opts: {
                duration: 150,
            },
        });

        this.fadeOutMenuAnimation = this.animationEngine.defineAnimation({
            type: 'fadeOut',
            element: this,
            opts: {
                duration: 150,
            },
        });
    }

    onUpdated(ev) {
        const { detail } = ev;

        if (detail.type === 'hellobar.animation-complete') {
            this.setSlideContainerHeight();
        }
    }
}

export default PFDCMobileMenuElement;
