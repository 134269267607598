import { PFDCCarouselNavElement } from '../pfdc-carousel-nav/element';

/**
 * Value to indicate first flickity event trigger on init
 * @let
 * @type {bool}
 */
let initCheck = true;

/**
 * Aria message when all alerts are shown
 * @let
 * @type {string}
 */

const NO_FILTER_MESSAGE = 'All saved searches, no filter';

/**
 * Aria message when all alerts are shown (preceeds selected filter typeName)
 * @let
 * @type {string}
 */

const FILTER_MESSAGE = 'Filtered saved searches for';

/**
 * Extend the carousel nav to do some very specific count updating and focusing
 *
 * @extends PFDCCarouselNavElement
 */
export class PFDCSavedSearchCarouselNavElement extends PFDCCarouselNavElement {
    /**
     * Initialize this component
     */
    onInit() {
        super.onInit();

        // Hide previous/next buttons from screen readers
        const buttons = this.querySelectorAll('button.flickity-prev-next-button');
        buttons.forEach(btn => {
            btn.setAttribute('aria-hidden', true);
        });

        // Ensure each nav item is visible to screen readers
        this.flickity.cells.forEach(cell => {
            cell.element.setAttribute('aria-hidden', false);
        });

        this.flickity.on('cellSelect', this.ariaAlert.bind(this));
    }

    /**
     * Set an aria alert on filter selection
     *
     * @method ariaAlert
     */
    ariaAlert() {
        // Flickity fires the 'cellSelect' event on page load.
        // Do not aria alert for this first event.
        if (!initCheck) {
            let filterMessage;

            if (this.flickity.selectedElement.dataset.typeName == null) {
                filterMessage = NO_FILTER_MESSAGE;
            } else {
                filterMessage = `${FILTER_MESSAGE} ${this.flickity.selectedElement.dataset.typeName} Quantity: ${
                    this.flickity.selectedElement.dataset.typeCount
                }`;
            }
            this.dispatchAction('', { statusText: filterMessage });
        }

        initCheck = false;
    }

    /**
     * Handles update events from the app level
     * @param {Object} ev event object
     */
    onUpdated(ev) {
        const { detail } = ev;
        if (detail.type === 'saved-search-list.card.delete-success') {
            const deletedCardTypeId = detail.target.getAttribute('type-id');
            const matchedCell = this.flickity.cells.filter(cell => {
                return cell.element.dataset.typeId === deletedCardTypeId;
            });

            if (matchedCell.length > 1) {
                return;
            }

            // Setup for the all alerts item count
            this.allAlertsCellEl = this.flickity.cells[0].element;
            this.allAlertsCellBtn = this.allAlertsCellEl.firstElementChild;
            this.allAlertsCount =
                Number(this.allAlertsCellEl.dataset.typeCount) - 1;
            this.allAlertsCellEl.dataset.typeCount = this.allAlertsCount;
            this.allAlertsName = 'All Alerts';

            // Setup for the deleted item count
            this.matchedCellEl = matchedCell[0].element;
            this.typeCount = Number(this.matchedCellEl.dataset.typeCount) - 1;
            this.matchedCellEl.dataset.typeCount = this.typeCount;
            this.typeName = this.matchedCellEl.dataset.typeName;

            this.matchedCellBtn = this.matchedCellEl.firstElementChild;

            // Set type id count
            if (this.typeCount) {
                const result = `${this.typeName} (${this.typeCount})`;
                this.matchedCellBtn.innerText = result;
                this.matchedCellEl.setAttribute('aria-label', result);
            } else {
                this.flickity.remove(this.flickity.selectedElement);
                this.flickity.select(this.flickity.selectedIndex - 1);
                this.focusManager.focusFirstFocusable(this, true);
            }

            // Set all alerts count
            this.allAlertsCellBtn.innerText = `${this.allAlertsName} (${
                this.allAlertsCount
            })`;
        }
    }

    onSelect() {
        super.onSelect();

        const type = this.flickity.selectedElement.getAttribute(
            'data-type-name'
        );

        if (type) {
            this.dispatchAction('analytics', {
                eventId: 'Consumer109',
                filterName: type.toLowerCase(),
            });
        }
    }
}

export default PFDCSavedSearchCarouselNavElement;
