import { PFElement } from '../pf-element/element';
import { scheduleMicrotask } from '../../util/util';
import Utils from '../../lib/wirey/Utils';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-accordion';

/**
 * Unique class name for open accordian panels
 * @const
 * @type {string}
 */
const ACCORDION_OPEN_PANEL_CLASS = 'u-accordion_isOpen';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    PANEL:                      `[${ELEMENT_TAG_NAME}-panel]`,
    PANEL_TOGGLE_BTN:           `[${ELEMENT_TAG_NAME}-panel-toggle-btn]`,
    PANEL_TOGGLE_BTN_LABEL:     `[${ELEMENT_TAG_NAME}-panel-toggle-btn-label]`,
    PANEL_CONTENT:              `[${ELEMENT_TAG_NAME}-panel-content]`,
    PANEL_CONTENT_INNER:        `[${ELEMENT_TAG_NAME}-panel-content-inner]`,
};

/**
 * This is a generic accordian component. Click a toggle button, open a panel. Use u-isOpen
 * class to indicated which panels are open at page load.
 *
 * <pf-accordion>
 *     <div pf-accordion-panel class="u-isOpen">
 *        <button pf-accordion-panel-toggle-btn>Toggle Button Content...</button>
 *
 *        <div pf-accordion-panel-content>
 *            Content container height is animated...
 *            <div pf-accordion-panel-content-inner>Content wrapper for fading out...</div>
 *        </div>
 *     </div>
 *     <div pf-accordion-panel>
 *        <button pf-accordion-panel-toggle-btn>Toggle Button Content...</button>
 *
 *        <div pf-accordion-panel-content>
 *            <div pf-accordion-panel-content-inner>Content wrapper for fading out...</div>
 *        </div>
 *     </div>
 *     ...
 * </pf-accordion>
 *
 * @class PFAccordionElement
 * @extends PFElement
 *
 */
export class PFAccordionElement extends PFElement {
    onInit() {
        /**
         * Reference to all the panel elements as an array
         * @type {Array}
         */
        this.panels = Array.from(this.querySelectorAll(ELEMENT_SELECTORS.PANEL));

        /**
         * Keeps reference to animating state.
         * @type {boolean}
         */
        this.isAnimating = false;

        this.observerConfig = {
            childList: true,
            subtree: true,
        };

        this.observer = new MutationObserver(this.onObserved.bind(this));
        this.observer.observe(this, this.observerConfig);

        this.setupPanels();
    }

    /**
     * Strips down the component when removed from the DOM.
     * @callback
     */
    disconnectedCallback() {
        this.panels.forEach(panel => {
            panel.removeEventListener('click', this.onPanelClicked);
        });
    }

    /**
     * Hide panels
     */
    setupPanels() {
        this.panels.forEach(panel => {
            const panelToggleBtn = panel.querySelector(ELEMENT_SELECTORS.PANEL_TOGGLE_BTN);
            const panelContent = panel.querySelector(ELEMENT_SELECTORS.PANEL_CONTENT);
            const panelContentInner =
                panel.querySelector(ELEMENT_SELECTORS.PANEL_CONTENT_INNER);

            if (panelToggleBtn) {
                if (panel.classList.contains(ACCORDION_OPEN_PANEL_CLASS)) {
                    panelToggleBtn.setAttribute('aria-pressed', 'true');
                } else {
                    panelToggleBtn.setAttribute('aria-pressed', 'false');
                }

                panelContent.dataset.contentHeight = panelContent.scrollHeight;
                panelContent.style.display =
                    panel.classList.contains(ACCORDION_OPEN_PANEL_CLASS) ? 'block' : 'none';
                panelContentInner.style.opacity =
                    panel.classList.contains(ACCORDION_OPEN_PANEL_CLASS) ? '1' : '0';
                panel.addEventListener('click', this.onPanelClicked.bind(this));
            }
        });
    }

    onObserved(mutations) {
        mutations.forEach(mutation => {
            if (mutation.type === 'childList') {
                this.updatePanelHeights();
            }
        });
    }

    updatePanelHeights() {
        this.panels.forEach(panel => {
            const panelContent = panel.querySelector(ELEMENT_SELECTORS.PANEL_CONTENT);
            panelContent.dataset.contentHeight = panelContent.scrollHeight;
        });
    }

    /**
     * Handles animations for panel expansion.
     * @param panelContent, panelContentInner
     */
    async expandPanelContent(panelContent, panelContentInner) {
        if (this.isAnimating) {
            return;
        }

        this.isAnimating = true;
        panelContent.closest(ELEMENT_SELECTORS.PANEL).classList.add(ACCORDION_OPEN_PANEL_CLASS);

        await this.animationEngine.defineTimeline(
            [
                {
                    type: 'animate',
                    element: panelContent,
                    opts: {
                        keyframes: [
                            {
                                'max-height': [{ value: '0px', duration: 0 }, { value: '132px', duration: 400 }],
                            },
                        ],
                        valueBefore: [{ display: 'block' }, { height: 'auto' }, { overflow: 'hidden' }],
                    },
                },
                {
                    type: 'fadeIn',
                    element: panelContentInner,
                    opts: {
                        duration: 300,
                    },
                },
            ],
            {
                playOnDefine: true,
            }
        );

        this.isAnimating = false;
    }

    /**
     * Handles animations for panel collapsing.
     * @param panelContent, panelContentInner
     */
    async collapsePanelContent(panelContent, panelContentInner) {
        if (this.isAnimating) {
            return;
        }
        this.isAnimating = true;
        panelContent.closest(ELEMENT_SELECTORS.PANEL).classList.remove(ACCORDION_OPEN_PANEL_CLASS);

        await this.animationEngine.defineTimeline(
            [
                {
                    type: 'animate',
                    element: panelContent,
                    opts: {
                        keyframes: [
                            {
                                'max-height': [{ value: '132px', duration: 0 }, { value: '0px', duration: 200 }],
                            },
                        ],
                        valueAfter: [{ display: 'none' }],
                    },
                },
                {
                    type: 'fadeOut',
                    element: panelContentInner,
                    opts: {
                        duration: 200,
                    },
                },
            ],
            {
                playOnDefine: true,
            }
        );

        this.isAnimating = false;
    }

    /**
     * Handles clicks on the panels.
     * @param {Object} ev
     */
    onPanelClicked(ev) {
        const foundElement = Utils.HTMLElementUtils.findElementMatchingCondition(ev.target, (element) => {
            return element.hasAttribute(`${ELEMENT_TAG_NAME}-panel-toggle-btn`);
        });

        if (foundElement) {
            const btn = ev.target;
            const panelContent = ev.currentTarget.querySelector(ELEMENT_SELECTORS.PANEL_CONTENT);
            const panelContentInner =
                ev.currentTarget.querySelector(ELEMENT_SELECTORS.PANEL_CONTENT_INNER);
    
            if (panelContent.style.display === 'none') {
                btn.setAttribute('aria-pressed', true);
                this.expandPanelContent(panelContent, panelContentInner);
    
                scheduleMicrotask(() => {
                    this.focusManager.focusFirstFocusable(panelContentInner);
                });
            } else {
                btn.setAttribute('aria-pressed', false);
                this.collapsePanelContent(panelContent, panelContentInner);

                scheduleMicrotask(() => {
                    this.focusManager.focusElement(btn);
                });
            }
        }
    }
}

export default PFAccordionElement;
