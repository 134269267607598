import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { CLASS_IS_HIDDEN } from '../../constants/classes';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-collapsable';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    TRIGGER: `[${ELEMENT_TAG_NAME}-trigger]`,
    TARGET: `[${ELEMENT_TAG_NAME}-target]`,
    ICON: `[${ELEMENT_TAG_NAME}-icon]`,
};

/**
 * @class PFDCCollapsableElement
 * @extends PFElement
 */
export class PFDCCollapsableElement extends PFElement {
    onInit() {
        /**
         * Reference to the button that expands menu
         * @type {Array}
         */
        this.trigger = this.querySelector(ELEMENT_SELECTORS.TRIGGER);

        /**
         * Reference to the expandable menu target
         * @type {Array}
         */
        this.menu = this.querySelector(ELEMENT_SELECTORS.TARGET);

        /**
         * Reference to icon to update when expanded
         * @type {Array}
         */
        this.icon = this.querySelector(ELEMENT_SELECTORS.ICON);

        this.displayType = window
            .getComputedStyle(this.menu, null)
            .getPropertyValue('display');
        this.isOpen = this.hasAttribute('collapsed') ? false : true;
        this.setHeight();

        this.addEventListener('click', this.onClicked);
        this.addEventListener('focusin', this.onFocused);
    }

    /**
     * Toggles menu
     */
    setHeight() {
        this.menu.style.height = `${this.menu.offsetHeight}px`;
        this.menu.style.transition = 'height .2s';
        this.menu.style.overflow = 'hidden';

        this.icon.style.transition = 'transform .2s';
    }

    /**
     * Toggles menu
     */
    toggleMenu() {
        if (this.isOpen) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }

    /**
     * Opens menu
     */
    openMenu() {
        if (this.isOpen) {
            return;
        }
        this.menu.style.height = 'auto';
        const height = `${this.menu.offsetHeight}px`;
        this.menu.style.height = '0';
        setTimeout(() => {
            this.menu.style.height = height;
        }, 0);
        this.icon.style.transform = 'rotate(0)';
        this.isOpen = true;
    }

    /**
     * Closes menu
     */
    closeMenu() {
        if (!this.isOpen) {
            return;
        }
        this.menu.style.height = 0;
        this.icon.style.transform = 'rotate(-180deg)';
        this.isOpen = false;
    }

    /**
     * Handles click events
     * @param  {Object} ev event object
     */
    onClicked(ev) {
        switch (true) {
            case ev.target === this.trigger || this.trigger.contains(ev.target):
                this.toggleMenu();
                break;
            default:
                break;
        }
    }

    /**
     * Handles focus events
     * @param  {Object} ev event object
     */
    onFocused(ev) {
        switch (true) {
            case this.menu.contains(ev.target):
                this.openMenu();
                break;
            default:
                break;
        }
    }
}
